export class NewSiteComponent {
    /*@ngInject*/
    constructor($timeout, $interval, $ngConfirm, $uibModal, $scope, genericMapService,usSpinnerService, siteService, privGroupService, toastr, $sanitize) {
        this.$timeout = $timeout;
        this.$interval = $interval;
        this.$ngConfirm = $ngConfirm;
        this.$uibModal = $uibModal;
        this.$scope = $scope;
        this.genericMapService = genericMapService;
        this.usSpinnerService = usSpinnerService;
		this.siteService = siteService;
		this.privGroupService = privGroupService;
		this.toastr = toastr;
		this.$sanitize = $sanitize;

    }

    $onInit() {
        let self = this;

        /**
         * When we load STEP 3 for the first time, we do so by calling readyMap.
         * If we decide to go away and visit step 3 again, it should not ready the map once more
         */
        let isReady = false;

        /**
         * siteLayers is the Object that will contain each polygon we draw.
         * We are busy defining only ONE site, but we may give this one site quite a few layers.
         * Here are a few assumptions/ideas that may be important to be aware of:
         *
         *  i) The very FIRST polygon we draw is assumed to be the 'PRIMARY-site-outline'
         *     - This means that it is drawn a bit thicker and that all other layers (should there be any) would be drawn WITHIN this one
         *     - This is important because we want to use the outline for geo-fence events.
         * ii) In order to identify our PRIMARY polygon, I give it a primaryPolygon._id = site._id property.
         *     - Therefore, whenever working with a polygon layer we can see if it is the primary if it has a ._id property (you'll see me doing this a lot)
         *     - If it doesn't have a _id, I know I'm working with a secondary layer and simply refer to its unique _leaflet_id
         *
         * self.siteLayers[siteID]  primary polygon
         * self.siteLayers[_leaflet_id] secondary polygons
         * @type {Object}
         */
        self.siteLayers = {};

        /**
         * This contains all the info in our wizard that is of import.
         * @type {Object}
         */
        self.siteDefinition = {};

        //We need to determine whether this is going to be an editModalInstance, or newModalInstance
        if(self.resolve.site) {
            self.editSite = true;
            self.siteDefinition.name = self.resolve.site.name;
            self.siteDefinition.description = self.resolve.site.description;
            self.siteDefinition._id = self.resolve.site._id;
            self.siteDefinition.address = self.resolve.site.address;
            self.siteDefinition.contacts = self.resolve.site.contacts || [];
            self.siteDefinition.notes = self.resolve.site.notes ? self.resolve.site.notes : [];
            self.siteDefinition.locs = self.resolve.site.locs;
            self.siteDefinition.groups = self.resolve.site.groups;
			self.siteDefinition.tags = self.resolve.site.tags;
			self.siteDefinition.taggingSite = self.resolve.site.taggingSite;
			self.siteDefinition.logoutSettings = self.resolve.site.logoutSettings;
			self.siteDefinition.requiresLogin = self.resolve.site.requiresLogin;
        } else {
            self.editSite = false;
            self.siteDefinition.name = '';
            self.siteDefinition.description = '';
            self.siteDefinition._id = self.generateNumber(10, 1000);
            self.siteDefinition.address = ['', '', ''];
            self.siteDefinition.contacts = [];
            self.siteDefinition.notes = [];
            self.siteDefinition.groups = [];
			self.siteDefinition.tags = [];
			self.siteDefinition.taggingSite = false;
			self.siteDefinition.requiresLogin = false;

            /**
             * locs is an array of objects which each contain:
             * .loc -> That shape's latlngs
             * color-> That shape's drawn color
             * weight-> That shape's weight
             * NOTE that I assign the ID of the layer (either ._id or ._leaflet_id) to this
             * shape, just so I can keep a connection between it and the layer
             */
            self.siteDefinition.locs = [];
        }
		if(!self.siteDefinition.logoutSettings) {
			self.siteDefinition.logoutSettings = {};
		}
		if(!self.siteDefinition.logoutSettings.siteExitLogout) {
			self.siteDefinition.logoutSettings.siteExitLogout = {
				disable:false,
				override: false,
				minutes:0
			};
		}
		if(!self.siteDefinition.logoutSettings.siteEntryLogout) {
			self.siteDefinition.logoutSettings.siteEntryLogout = {
				disable:false,
				override: false,
				minutes:0
			};
		}
        self.selectedSite;
        self.activeWizardStep = 1;

        /**
         * Currently we employ this siteTooltip when adding a note for the site.
         * But let's see how this change after we can freely add and drag labels.
         * @type {String}
         */
        self.siteTooltip = '';

        /**
         * Whether we want to display color options in the top of the screen
         * @type {Boolean}
         */
        self.choosingColor = false;

        /**
         * The default color when we start drawing our lil 'ol site
         */
        self.defaultDrawingColor = '#2C98DE';

        /**
         * The user can choose his own color to draw the site in.
         * TODO: Just figure out how this should carry over. If I draw my site in pink, should others
         * see it in pink, also? Should MY (not others') dashboard show it pink, or default?
         * @type {String}
         */
        self.chosenDrawingColor = '';

        /**
         * The stroke width in pixels of the PRIMARY-site-outline
         */
        self.defaultPrimaryWeight = 6;

        /**
         * The stroke width in pixels of a SECONDARY-site-outline
         */
        self.defaultSecondaryWeight = 2;

        /**
         * This variable is used when we click on the 'draw' button and call 'drawPolygon'
         * After drawing multiple layers, this button, when clicked, automatically activates
         * editTools for the last activated layer
         * @type {String}
         */
        self.previousSelectedSiteID = self.editSite ? self.siteDefinition._id : '';

        /**
         * Gets set to true only when I'm in drawing mode, that is, after
         * we call self.map.editTools.startPolygon();
         */
        self.isDrawing = false;

        /**
         * TODO: Change this variable-name to isActive or isEditing, or something
         * This is actually when we are in edit-mode, not when we are drawing
         */
        self.drawingPolygon = false;

        /**
         * Instead of calling self.map.getZoom() from various places, I'm setting this variable-name
         * with every zoom-action
         * TODO: Implement this variable for it's not being used eyt
         */
        self.mapZoom;

        /**
         * We have an advanced-settings-container that has an ng-class on this Boolean.
         * This value only gets changed by @see onShowAdvancedSettings();
         * @type {Boolean}
         */
        self.showAdvancedSettings = false;
        self.moveAdvancedSettingsIcon = false;

        /**
         * @see changeSitenamePopupSetting()
         * We have advanced settings where the user can choose if he wants to display a popup with the sitename:
         *  i) Permanently
         *  ii) Disabled
         *  iii) Hover
         *  iv) Click
         */
        self.sitenamePopupSetting = '';

        // L.mapbox.accessToken = 'pk.eyJ1Ijoicm9tcGVsc3RvbXBlbCIsImEiOiJjamNoaHJmOWkxaDFqMnlydnV1d202MTA2In0.vaZnBgwNagSx6wAljOUEMQ';
        // self.map = L.mapbox
        //     .map(`siteMap`,'mapbox.streets', {
        //         zoomControl:false,
        //         drawControl: false,
        //         editable: true,
        //     })
        //     // .addControl(L.Control.Zoom({position:'topright'}))
        //     .addControl(L.mapbox.geocoderControl('mapbox.places', {
        //         position:'topleft',
        //         autocomplete:true
        //     }));

        // self.map = L.map('siteMap', {
        //         zoomControl: false,
        //         drawControl:false,
        //         editable: true,
        //     });
        //
        // L.tileLayer('https://api.mapbox.com/v4/{map_id}/{z}/{x}/{y}.png?access_token={accessToken}',{
        //     attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>',
        //     map_id:'mapbox.streets',
        //     accessToken:'pk.eyJ1Ijoicm9tcGVsc3RvbXBlbCIsImEiOiJjamNoaHJmOWkxaDFqMnlydnV1d202MTA2In0.vaZnBgwNagSx6wAljOUEMQ'
        // }).addTo(self.map);
        // var geocoder = L.Control.Geocoder.mapbox('pk.eyJ1Ijoicm9tcGVsc3RvbXBlbCIsImEiOiJjamNoaHJmOWkxaDFqMnlydnV1d202MTA2In0.vaZnBgwNagSx6wAljOUEMQ');
        // L.Control.geocoder({
        //     geocoder:geocoder,
        //     position:'topleft',
        // }).addTo(self.map)

        self.map = self.genericMapService.generateMap('siteMapWizard',{
            satellite:{position:'bottomright'},
            geocoder:{position:'topleft'},
            zoomControl:{position:'topleft'},
            drawControl:false,
            editable: true,
        });

        // self.map = L.map('siteMap', {
        //     zoomControl: false,
        //     drawControl: false,
        //     //editable is for leaflet-editable and also creates self.map.editTools
        //     editable: true,
        // });

        /**
         * For now we have three layergroups that we are implementing.
         * siteOutline - The primary, outer layer of the siteOutline
         * siteDetail1 - The secondary, inner layers of the site (circles, lines, etc);
         * siteDetail2 - The terinary, innermost layers of the site (notes, labels, etc);
         */
        self.siteOutline = new L.layerGroup().addTo(self.map);
        self.siteDetail1 = new L.layerGroup();
        self.siteDetail2 = new L.layerGroup().addTo(self.map);

        self.mapMarker = L.icon({
            iconUrl: 'assets/images/markers/latearrival.png',
            iconSize: [25, 41],
            iconAnchor: [20, 20],
            popupAnchor: [0, -30]
        });
        //self.bluetoothBeaconMarker = L.icon({
            //iconUrl: 'assets/images/markers/latearrival.png',
            //iconSize: [25, 41],
            //iconAnchor: [20, 20],
            //popupAnchor: [0, -30]
        //});
		self.bluetoothBeaconMarker = L.divIcon({
			html: '<i class="fa fa-bluetooth fa-3x"></i>',
			className: 'bluetoothBeaconMarker',
            iconAnchor: [15, 18],
		});
        self.mapMarkerWithClass = L.icon({
            iconUrl: 'assets/images/markers/latearrival.png',
            iconSize: [25, 41],
            iconAnchor: [20, 20],
            popupAnchor: [0, -30],
            className: 'leaflet-note-class'
        });

        // let layer = Tangram.leafletLayer({
        //     scene: 'cinnabar-style-more-labels.zip',
        //     attribution: '<a href="https://mapzen.com/tangram" target="_blank">Tangram</a> | &copy; OSM contributors | <a href="https://mapzen.com/" target="_blank">Mapzen</a>'
        // }).addTo(self.map);

        self.map.setView([-26.6145, 27.0950], 5);

        self.$timeout(() => {

            self.map.invalidateSize();
        }, 1000);

        // L.control.geocoder('mapzen-E4UsooF').addTo(self.map);

        // self.$interval(function () {
        // }, 10);
        self.map.on('zoomend', ($event) => {
            self.mapZoom = self.map.getZoom();
            self.handleLabelClasses();
            self.handleLayerClasses();
        });

        self.map.on('editable:created', ($event) => {
        });

        self.map.on('editable:drawing:end', ($event) => {

			let layer = $event.layer;
			//At drawMarker() I give the layer a .isPolygon = true;
			//we have some ng-if's and ng-classes on self.isDrawing
			self.isDrawing = false;

			//we make sure that VERTICES/POINTS were defined - the user didn't just click on start and stop
			if(layer && (typeof layer.getLatLng === 'function' || layer.getLatLngs == 'function')) {
				let id = layer._id ? layer._id : layer._leaflet_id;
				if(layer.isMarker) {
					self.tagIdChanged(layer);
					self.updateSiteTags();
					self.setSelectedSite(layer);
				}else{
					self.selectedSite = undefined;
					self.drawingPolygon = false;
					self.previousSelectedSiteID = id;
					self.updateSiteCoordinates(layer._latlngs[0], id);
				}
				self.siteLayers[id].disableEdit();
				// if(layer._id) {
				//     self.siteLayers[layer._id].disableEdit();
				// }
				// else {
				//     self.siteLayers[layer._leaflet_id].disableEdit();
				// }

			}
		});


        self.map.on('editable:disable', ($event) => {
            self.removeShouldWaitClasses();
            if(self.choosingColor) {
                self.chooseColor();
            }
            let layer = $event.layer;
            if(layer.isMarker) {
                //do Marker stuff here
				//Move this to set selected site function
				self.tagIdChanged(layer);
				self.removeSelectedBluetoothBeacon(layer);
            }
        });

        self.map.on('editable:enable', ($event) => {
            let layer = $event.layer;
            if(layer.isMarker) {
                //do Marker stuff here
				self.tagIdChanged(layer);
				self.setSelectedBluetoothBeacon(layer);
            }

            if(layer.isPolygon) {
                //do Polygon stuff here
                self.addShouldWaitClasses();
            }
        });


		self.availableGroups = [];
		self.refreshGroups();

		self.readyMap();
        // $onInit end
    }

    /**
     * When a user is in 'edit-mode', we don't want him to simply navigate away without being
     * prompted to save his changes. In order to do so, we need to consider how the wizard-directive works.
     * It looks toward the .navi-circles>li's for 'disabled' or 'should-wait' classess
     * @see editable:enable
     */
    addShouldWaitClasses() {
        let self = this;
        let circles = angular.element(document.getElementsByClassName('navi-circles'));
        _.forEach(circles[0].children, (child) => {
            angular.element(child).addClass('should-wait');
        });
    }

    /**
     * When a user leaves 'edit-mode', we remove the 'should-wait' classess
     * @see addShouldWaitClasses()
     * @see editable:disable
     */
    removeShouldWaitClasses() {
        let self = this;
        let circles = angular.element(document.getElementsByClassName('navi-circles'));
        _.forEach(circles[0].children, (child) => {
            angular.element(child).removeClass('should-wait');
        });
    }

    /**
     * In our HTML we're putting an ng-click on our round-tabs
     */
    verifyNotEditing($event) {
        let self = this;
        if(self.drawingPolygon) {
            self.$ngConfirm(
                {
                    title: `Save site changes?`,
                    theme: 'light',
                    animation: 'top',
                    scope: self.$scope,
                    closeAnimation: 'bottom',
                    content: `You are still busy editing your site. Do you want to save its current layout?`,
                    escapeKey: true,
                    backgroundDismiss: true,
                    buttons: {
                        // long hand button definition
                        ok: {
                            text: "Save",
                            btnClass: 'btn-primary',
                            keys: ['enter'], // will trigger when enter is pressed
                            action(scope) {
                                //We firstly complete the polygon's shape
                                self.completePolygon();

                                //Then we perform a click on the round-tab that was clicked
                                self.$timeout(() => {
                                    angular.element($event.currentTarget).triggerHandler("click");
                                }, 1000);
                            }
                        },
                        close(scope) {

                        }
                    },
                }
            );
        }
    }


        /********************************PERTAINING TO STEP 1**********************************/
        newAddressLine() {
            let self = this;
            self.siteDefinition.address.push('');
        }

        /**
         * The purpose of this function is to handle any logic that coordinates
         * with the current step we're on.
         * For example, showing the correct content on the
         * <aside-hints> needs to know the current stepS
         * NOTE That I'm not using <aside-hints> so this is not needed for now
         */
        nextWizardStep() {
            let self = this;
			self.updateSiteTags();
            if(self.drawingPolygon) {

                self.$ngConfirm(
                    {
                        title: `Save site changes?`,
                        theme: 'light',
                        animation: 'top',
                        scope: self.$scope,
                        closeAnimation: 'bottom',
                        content: `You are still busy editing your site. Do you want to save its current layout?`,
                        escapeKey: true,
                        backgroundDismiss: true,
                        buttons: {
                            // long hand button definition
                            ok: {
                                text: "Save",
                                btnClass: 'btn-primary',
                                keys: ['enter'], // will trigger when enter is pressed
                                action(scope) {
                                    self.completePolygon();
                                    self.activeWizardStep++;
                                }
                            },
                            close(scope) {

                            }
                        },
                    }
                );
            }
        }

        prevWizardStep() {
            let self = this;
            if(self.drawingPolygon) {

                self.$ngConfirm(
                    {
                        title: `Save site changes?`,
                        theme: 'light',
                        animation: 'top',
                        scope: self.$scope,
                        closeAnimation: 'bottom',
                        content: `You are still busy editing your site. Do you want to save its current layout?`,
                        escapeKey: true,
                        backgroundDismiss: true,
                        buttons: {
                            // long hand button definition
                            ok: {
                                text: "Save",
                                btnClass: 'btn-primary',
                                keys: ['enter'], // will trigger when enter is pressed
                                action(scope) {
                                    self.completePolygon();
                                    if (self.activeWizardStep !== 1) {
                                        self.activeWizardStep--;
                                    }
                                }
                            },
                            close(scope) {

                            }
                        },
                    }
                );
            }
        }

        /********************************PERTAINING TO STEP 2**********************************/
        newContact(contact) {
            let self = this;

            if(contact) {
                self.siteDefinition.contacts.push(contact);
            } else {
                self.siteDefinition.contacts.push({firstname: '', lastname: '', phone: ''});
            }
        }

        removeContact(contact) {
            let self = this;
            let index = _.findIndex(self.siteDefinition.contacts, (storedContact) => {
                return storedContact.firstname === contact.firstname;
            });

            if(index !== -1) {
                self.siteDefinition.contacts.splice(index, 1);
            } else {
                //console.error("Trying to remove a contact that shouldn't exist in the first place");
            }

            if(self.siteDefinition.contacts.length === 0) {
                self.newContact();
            }
        }

        /**
         * Allows a user to specify a new type of field he desires to include for a contact
         */
        newContactField(contact) {
            let self = this;

            let index = _.findIndex(self.siteDefinition.contacts, (storedContact) => {
                return storedContact.firstname === contact.firstname;
            });

            self.$ngConfirm(
                {
                    title: `Add Contact Field`,
                    theme: 'light',
                    animation: 'top',
                    scope: self.$scope,
                    closeAnimation: 'bottom',
                    content: require('./new-contact-field.html'),
                    escapeKey: true,
                    backgroundDismiss: true,
                    buttons: {
                        // long hand button definition
                        ok: {
                            text: "Ok",
                            btnClass: 'btn-primary',
                            keys: ['enter'], // will trigger when enter is pressed
                            action(scope) {
                                let result = scope.$ctrl.selectedField === 'Custom' ? scope.$ctrl.customField : scope.$ctrl.selectedField;
                                self.siteDefinition.contacts[index][result] = '';
                            }
                        },
                        close(scope) {

                        }
                    },
                }
            );
        }

        /**
         * A function that calls on the ContactsListComponent to make contacts more generic
         */
        importContacts() {
            let self = this;
            let modalInstance = self.$uibModal.open({
    			component: 'contactslist',
    			backdrop: 'static',
                size: 'xlg',
    			resolve: {
                    definingSite: true,
                    isModal: true
                }
            });

            modalInstance.result.then((result) => {
                if(self.siteDefinition.contacts.length === 1) {
                    let contact = self.siteDefinition.contacts[0];
                    if(contact.firstname === '' || contact.firstname === '') {
                        self.siteDefinition.contacts[0] = result;
                    } else {
                        self.newContact(result);
                    }
                } else {

                    let numOfContacts = self.siteDefinition.contacts.length;
                    let lastContact = self.siteDefinition.contacts[numOfContacts - 1];
                    if(lastContact.firstname === '' || lastContact.firstname === '') {
                        self.siteDefinition.contacts[numOfContacts - 1] = result;
                    } else {
                        self.newContact(result);
                    }
                }
            });
        }


        /********************************PERTAINING TO STEP 3**********************************/

    /**
     * We call this function from the NEXT button in HTML, step 2, prior to visiting step 3.
     * We invalidateSize()
     * If we're busy with an edit, we go to the original defined site
     * NOTE: That we're calling drawSite which redraws the polygon from the location
     *       and that we are not storing the first layer-object
     */
    readyMap() {
        let self = this;
        let siteID = self.siteDefinition._id;

        self.map.invalidateSize();
        if(!self.isReady) {
            self.$timeout(() => {
                self.map.invalidateSize();

                if(self.resolve.site) {
                    // self.drawSite(self.siteDefinition.loc);
                    self.drawSite(self.siteDefinition);
                }
                self.map.invalidateSize();
            });
            self.isReady = true;
        }
        self.setGeoCoder();
    }

    /**
     * When we only want to invalidate the map, without calling readyMap()
     * @see HTML, next-button in step 2 -> step 3
     */
    invalidateMapSize() {
        let self = this;
        self.$timeout(() => {
            self.map.invalidateSize();
        });
    }

    /**
     * When we edit a site this function gets
     * called by readyMap().
     */
    drawSite(site) {
        let self = this;
        let siteID = site._id;

        self.calculateCircle(site);
        // self.drawSiteCircle(site);

        _.forEach(site.locs, (shape) => {
            if(shape.loc.length !== 0) {
                if(shape.loc.length === 1) {
                    //This means that we have a defined site with only one point. That doesn't make sense, but just in case,
                    //here goes.
                    self.map.setView(shape.loc[0], 15);
                    self.placeMarkerAtCoordinates(shape.loc[0]);
                } else {
                    self.map.fitBounds(shape.loc, {padding: [50, 50], maxZoom: 17});
                    self.drawSitePolygon(siteID, shape);
                }
            }
        });

        _.forEach(site.notes, (note) => {
            let marker = L.marker(note.loc, {opacity: 0, draggable: true, icon: self.mapMarkerWithClass});
            marker.bindTooltip(note.content, {direction: 'center', permanent: true, className: 'site-data-label', offset: [10,-5]}).addTo(self.siteDetail2);
            //Have to put this in a timeout, otherwise when Leaflet tries to add the class, it gets a classlist of undefined
            marker.on('dragend', ($event) => {
                note.loc = $event.target._latlng;
            });
        });

		_.forEach(site.tags, (tag) => {
			self.placeBluetoothMarker(tag);
		});
    }

    /**
     * If we edit a component and the map gets READY, we grab the site's location,
     * and draw its polygons
     * @see readyMap()
     * @see drawSite()
     * @param id - The siteID
     * @param shape - The shape-object containing a .loc, .color, .weight
     */
    drawSitePolygon(id, shape) {
        let self = this;
        // let id = self.siteDefinition._id;

        let polygon = L.polygon(shape.loc, {color: shape.color, weight: shape.weight}).addTo(self.map);
        polygon.isPolygon = true;

        polygon.on('click', ($event) => {
            let layer = $event.target;
            layer.toggleEdit();
            self.setSelectedSite(layer);
        });

        if(Object.keys(self.siteLayers).length === 0) {
            self.siteLayers[id] = polygon;
            // self.siteLayers[id]._id = id;
            polygon._id = id;
            shape._id = id;
        } else {
            self.siteLayers[polygon._leaflet_id] = polygon;
            shape._id = polygon._leaflet_id;
        }

        // //If this is the first polygon we're drawing, we're making this the primary.
        // if(Object.keys(self.siteLayers).length === 1) {
        //     // polygon.setStyle({weight: self.defaultPrimaryWeight});
        // }
        // else {
        //     shape._id = polygon._leaflet_id;
        // }
    }


    drawSiteCircle(site) {
        let self = this;
        if(!site || !site.centerPoint || !site.radius) {
            return;
        }
        let id = site._id;
        let drawingColor = '#9B1A1F';
        let drawingWeight = 3;
        // TODO: color  from siteDefinition
        let circle = L.circle(L.latLng(site.centerPoint.coordinates[1],site.centerPoint.coordinates[0]), {radius: site.radius,color: drawingColor, weight: drawingWeight}).addTo(self.map);
        circle.bringToBack();
        if(!self.siteCircleLayer) {
            self.siteCircleLayer = circle;
        }
        return circle;
    }

    calculateCircle(site) {
        let self = this;
        let locations = [];
        if(site && site.locs && site.locs[0] && site.locs[0].loc && site.locs[0].loc.length > 0) {

            locations = site.locs[0].loc;
            let siteBound = L.latLngBounds(locations);
            let centerPoint = L.latLng(siteBound.getCenter());
            let maxDist = 0;
            locations.forEach((loc) => {
                let dist = centerPoint.distanceTo(loc);
                if (dist > maxDist) {
                    maxDist = dist;
                }
            });
            try {
                site.centerPoint = {type:"Point",coordinates:[centerPoint.lng,centerPoint.lat]};
                site.radius = maxDist;

            } catch (e) {
                console.error(e);
            }
        }
        return site;
    }


    /**
     * In the abnormal case of a site having only a single location, we place a marker
     */
    placeMarkerAtCoordinates(coordinates) {
        let self = this;
        let marker = L.marker(coordinates, {icon: self.mapMarker}).addTo(self.map);
    }

	placeBluetoothMarker(tag) {
		let self = this;
		let latLng = L.GeoJSON.coordsToLatLng(tag.loc.coordinates);
		let marker = L.marker(latLng, {icon: self.bluetoothBeaconMarker}).addTo(self.map);
		self.siteLayers[marker._leaflet_id] = marker;
		marker._id = marker._leaflet_id;
		marker.isMarker = true;
		marker.tagId = tag.tagId;
		self.tagIdChanged(marker);
		self.updateSiteTags();
        marker.on('click', ($event) => {
            let layer = $event.target;
            layer.toggleEdit();
			//if(layer.editEnabled()){
				//self.setSelectedBluetoothBeacon(layer);
			//}else{
				//self.removeSelectedBluetoothBeacon(layer);
			//}
			self.setSelectedSite(layer);
        });
	}

	setSelectedBluetoothBeacon(layer) {
		let self = this;
		$(layer._icon).children('i')
.addClass('selectedMarker');
		self.selectedBluetoothMarker = layer;
	}

	removeSelectedBluetoothBeacon(layer) {
		let self = this;
		$(layer._icon).children('i')
.removeClass('selectedMarker');
		if(self.selectedBluetoothMarker && self.selectedBluetoothMarker._leaflet_id == layer._leaflet_id) {
			self.selectedBluetoothMarker = undefined;
		}
	}

    // /**
    //  * Calling this function from readyMap
    //  * If we are editing a component that already has layers, we
    //  * draw new polygons from these layers.
    //  */
    // drawPolygonFromLayer(layer) {
    //     let self = this;
    //     let latlngs = layer.getLatLngs()[0];
    //     let polygon = L.polygon(latlngs, {color: '#2C98DE'}).addTo(self.map);
    //     return polygon;
    // }

    // /**
    //  * Calling this function from readyMap
    //  * If we are editing a component that already has layers, and
    //  * a layer has a 'isMarker' property, we execute this function.
    //  */
    // drawMarkerFromLayer(layer) {
    //     let self = this;
    //     let latlng = layer.getLatLng();
    //     let html = layer._icon.innerHTML;
    //     let divIcon = L.divIcon({html: html});
    //     let marker = L.marker(latlng, {icon: divIcon, draggable: true}).addTo(self.map);
    //     L.DomUtil.addClass(marker._icon, 'leaflet-note-class');
    //     marker.isMarker = true;
    //     return marker;
    // }

    /**
     * We invoke this function above and we only do so if we
     * click NEXT from step 2 to toggle readyMap()
     */
    setGeoCoder() {
        let self = this;
        if(self.siteDefinition.address[0] !== '') {
            self.usSpinnerService.spin('spinner-geo');
            self.availableAddresses = [];
            self.selectedAddress = -1;
            // let map = angular.element(document.getElementById('siteMap'));
            let value = '';
            for (var i = 0; i < self.siteDefinition.address.length && i < 2; i++) {
                if(self.siteDefinition.address[i] !== '') {
                    value += `${self.siteDefinition.address[i]}, `;
                }
            }
            value = value.replace(/, $/, '');
            // control[0].value = value;
            self.genericMapService.geocoder.geocode(value,(results) => {

                self.usSpinnerService.stop('spinner-geo');
                self.availableAddresses = results;
                self.availableAddresses.splice(8);
                self.map.fitBounds(self.availableAddresses[0].bbox, {padding: [50, 50], maxZoom: 17});
                self.selectedAddress = 0;
            });
        }
    }

    selectAddress(index) {
        let self = this;

        if(self.availableAddresses && self.availableAddresses[index]) {
            self.map.fitBounds(self.availableAddresses[index].bbox, {padding: [50, 50], maxZoom: 17});
            self.selectedAddress = index;
        }

    }

    /**
     * We invoke this function from HTML by clicking the big blue button
     * We're using leaflet-editable.
     */
    drawPolygon() {
        let self = this;
        let id = self.siteDefinition._id;
        let count = self.getPolygonCount();

        if(count === 0) {
            //NO site has been placed, yet
            if(!self.drawingPolygon) {
                self.siteLayers[id] = self.map.editTools.startPolygon(null, {color: self.defaultDrawingColor, weight: self.defaultPrimaryWeight});
                self.siteLayers[id]._id = self.siteDefinition._id;
                self.siteLayers[id].isPolygon = true;

                self.selectedSite = self.siteLayers[id];
                self.previousSelectedSiteID = id;
                self.drawingPolygon = true;
                self.isDrawing = true;

                self.siteLayers[id].on('click', ($event) => {
                    let layer = $event.target;
                    layer.toggleEdit();
                    self.setSelectedSite(layer);
                });
            }
        }
        // else, there already is a self.siteLayer. Which means a polygon
        // has ALREADY been drawn or is busy being drawn.
        else if(count === 1) {
                //We have only ONE site defined, which means it is the primary one

                if(!self.siteLayers[id]) {
                    //We make this site the primary, since it used to be a secondary
                    //NOTE that if we handle deletion of sites correctly, we will
                    //     take care of this logic of there
                    //TODO : Do the above at deletion
                    let siteID = Object.keys(self.siteLayers)[0];
                    self.siteLayers[id] = self.siteLayers[siteID];
                    self.siteLayers[id]._id = id;
                    self.siteLayers[id].setStyle({weight: self.defaultPrimaryWeight});
                    self.previousSelectedSiteID = id;
                    delete self.siteLayers[siteID];
                }

                if(self.siteLayers[id]._latlngs[0].length === 0) {
                    //we drawn a site, but didn't define any points on the map
                    self.siteLayers[id].disableEdit();
                    delete self.siteLayers[id];
                    self.drawingPolygon = false;
                    self.selectedSite = undefined;
                    self.previousSelectedSiteID = '';
                    return;
                } else if(self.drawingPolygon) {
                    //busy drawing, so we assume we want to finish it
                    // self.siteLayers[id].disableEdit();
                    // self.drawingPolygon = false;
                    // self.isDrawing = false;
                    // self.selectedSite = {};
                    self.completePolygon();
                } else {
                    //we're not drawing, so we assume we want to edit it
                    self.siteLayers[id].enableEdit();
                    self.drawingPolygon = true;
                    self.selectedSite = self.siteLayers[id];
                }
            } else {
                //We have MORE than one site defined already
                if(self.drawingPolygon) {
                    //We're busy drawing, so we disable editTools
                    // let id = self.selectedSite._id ? self.selectedSite._id : self.selectedSite._leaflet_id;
                    // self.siteLayers[id].disableEdit();
                    // self.drawingPolygon = false;
                    // self.selectedSite = {};
                    // self.previousSelectedSiteID = id;
                    self.completePolygon();
                } else {
                    let prevID = self.previousSelectedSiteID;
                    //We're not drawing, so we should enable editTools
                    self.siteLayers[prevID].enableEdit();
                    self.drawingPolygon = true;
                    self.selectedSite = self.siteLayers[prevID];
                }
            }
    }

    /**
     * We call this function via a 'click' event on each layer.
     */
    setSelectedSite(layer) {
        let self = this;
        let id = layer._id ? layer._id : layer._leaflet_id;

        if(!self.selectedSite) {
            //no site is currently active
            self.selectedSite = self.siteLayers[id];
            self.drawingPolygon = true;
            self.previousSelectedSiteID = id;
			if(self.selectedSite.isMarker) {
				self.setSelectedBluetoothBeacon(self.selectedSite);
			}
        }
        //else there is an active site
        else {
            let selectedID = self.selectedSite._id ? self.selectedSite._id : self.selectedSite._leaflet_id;
            if(selectedID === id) {
                //the currentActive site is the one that was clicked
                if(self.siteLayers[id].editEnabled() === undefined && !self.siteLayers[id].isMarker) {
                    //if the layer that was clicked is NOT being edited (anymore)

                    //NOTE: When setting the selected site, we make use of this opportunity
                    //NOTE: to update the coordinates of the site that is just coming out of EDIT
                    //*************************************************************//
                    self.updateSiteCoordinates(self.siteLayers[id]._latlngs[0], id); //
                    //*************************************************************//
				}else if(self.siteLayers[id].isMarker) {
					self.updateSiteTags();
				}
				self.selectedSite = undefined;
				self.drawingPolygon = false;
            } else {
                //the site that was clicked, is different from one that is currently active
                //we set selectedSite to the one that is active
                self.selectedSite = self.siteLayers[id];
                self.previousSelectedSiteID = id;
                //we disableEdit on the previous layer that was active
                self.siteLayers[selectedID].disableEdit();
            }
        }
    }

    /**
     * A generic function to determine the amount of polygons.
     * As of writing this, a polygon can only be a primary site definition,
     * or a secondary site elaboration.
     * @returns <Number>
     */
    getPolygonCount() {
        let self = this;
        let count = 0;

        _.forEach(self.siteLayers, (layer) => {
            if(layer.isPolygon) {
                count++;
            }
        });

        return count;
    }

    increaseRadius() {
        let self = this;
        self.siteDefinition.radius += 10;
        self.siteCircleLayer.setRadius(self.siteDefinition.radius);
    }


    removeSite() {
        let self = this;
        //whether we're removing the PRIMARY-site-outline
        let primary = !!(self.selectedSite._id && !self.selectedSite.isMarker);
        let id = self.selectedSite._id ? self.selectedSite._id : self.selectedSite._leaflet_id;
		let isMarker = self.siteLayers[id].isMarker;
        self.map.removeLayer(self.siteLayers[id]);
        delete self.siteLayers[id];
        self.selectedSite = undefined;
        self.drawingPolygon = false;
		if(isMarker) {
			self.updateSiteTags();
			//If this is a bluetooth marker the rest is not needed.
		}else{
			//Because we are removing a site we want to update our siteDefinition.locs.loc
			let idx = _.findIndex(self.siteDefinition.locs, (shape) => {
				return shape._id === id;
			});
			self.siteDefinition.locs.splice(idx, 1);

			if(primary) {
				//if we have removed our primary, and still have sites remaining, we are to ascertain a NEW primary
				let remainingSites = [];
				_.forEach(self.siteLayers, (layer,id) => {
					if(!layer.isMarker) {
						remainingSites.push(id);
					}
				});
				if(remainingSites.length > 0) {
					//I arbitrarily, for no reason, choose the first polygon remaining
					self.siteLayers[id] = self.siteLayers[remainingSites[0]];
					self.siteLayers[id]._id = id;
					self.siteLayers[id].setStyle({weight: self.defaultPrimaryWeight});

					//Because we are effectively changing the ID of a remaining site's shape, it's important to
					//reflect that change in our siteDefinition.locs array
					let oldID = self.siteLayers[id]._leaflet_id;
					let index = _.findIndex(self.siteDefinition.locs, (shape) => {
						return shape._id === oldID;
					});
					self.siteDefinition.locs[index]._id = id;

					delete self.siteLayers[remainingSites[0]];
				}
			}

			if(self.previousSelectedSiteID === id) {
				self.previousSelectedSiteID = self.siteDefinition._id;
			}
			if(self.siteLayers[id] && self.siteLayers[id]._latlngs[0]) {
				self.updateSiteCoordinates(self.siteLayers[id]._latlngs[0], id);
			}
			//This is done because angular does not run a digest loop after this has changed.
			//There should be something else that is causing this but cannot find it.
			//The apply causes an error.
			//self.$scope.$apply();

		}
    }

    /**
     * When we are drawing a polygon and want to complete its shape and place it on the map
     */
    completePolygon() {
        let self = this;
        let id = self.selectedSite._id ? self.selectedSite._id : self.selectedSite._leaflet_id;
        if(self.drawingPolygon) {
            self.siteLayers[id].disableEdit();
            self.drawingPolygon = false;
            self.isDrawing = false;
            self.selectedSite = undefined;
			if(!self.siteLayers[id].isMarker) {
				self.updateSiteCoordinates(self.siteLayers[id]._latlngs[0], id);
			}
			self.updateSiteTags();
            self.previousSelectedSiteID = id;
        }
    }

	updateSiteTags() {
		let self = this;
		self.siteDefinition.tags = [];
		self.siteDefinition.taggingSite = false;
		_.forEach( self.siteLayers,(layer,id) => {
			if(layer.isMarker) {
				self.siteDefinition.tags.push({
					tagId : layer.tagId,
					loc : {
						type:'Point',
						coordinates:L.GeoJSON.latLngToCoords(layer.getLatLng())
					}
				});


			}

		} );
		if(self.siteDefinition.tags.length > 0) {
			self.siteDefinition.taggingSite = true;
		}

	}

    /**
     * We want to remove the last point we've placed (while drawing)
     * @return {[type]} [description]
     */
    removeVertex() {
        let self = this;
        let id = self.selectedSite._id ? self.selectedSite._id : self.selectedSite._leaflet_id;
        self.siteLayers[id].editor.pop();
    }

    /**
     * When we want our self.siteDefinition.{locs}.loc to reflect the true position of our layer. We can move layer, remove layer, etc
     * and would then like to have our siteDefinition reflect this.
     * @see editable:drawing:end
     * @see setSelectedSite()
     * @see removeSite()
     * @see completePolygon()
     * @param  {[type]} latlngs [description]
     * @return {[type]} id      [description]
     */
    updateSiteCoordinates(latlngs, id) {
        let self = this;
        let siteID = self.siteDefinition._id;
        let isPrimary = siteID === id;
        let weight = isPrimary ? self.defaultPrimaryWeight : self.defaultSecondaryWeight;

        //We find this shape's index in our siteDefinition
        let index = _.findIndex(self.siteDefinition.locs, (shape) => {
            return shape._id === id;
        });

        if(index === -1) {
            //This is a new shape that we've just drawn
            self.siteDefinition.locs.push({loc: [], _id: id, color: self.defaultDrawingColor, weight});
            _.forEach(latlngs, (latlng) => {
                self.siteDefinition.locs[self.siteDefinition.locs.length-1].loc.push(latlng);
            });
        } else if(self.siteLayers[id]) {
                //we clear the found shape's loc and repopulate it
                self.siteDefinition.locs[index].loc = [];
                _.forEach(latlngs, (latlng) => {
                    self.siteDefinition.locs[index].loc.push(latlng);
                });
            } else {
            }
        if(self.siteCircleLayer) {
            self.calculateCircle(self.siteDefinition);
            // self.siteCircleLayer.setLatLng(L.latLng(self.siteDefinition.centerPoint.coordinates[1],self.siteDefinition.centerPoint.coordinates[0]));
            // self.siteCircleLayer.setRadius(self.siteDefinition.radius);
        }else {

            self.calculateCircle(self.siteDefinition);
            // self.drawSiteCircle(self.siteDefinition);
        }
    }

    /**
     * This function gets called from HTML by Clicking
     * on one of the small circles
     */
    chooseColor() {
        let self = this;
        self.choosingColor = !self.choosingColor;
    }

    chooseColorOption(color) {
        let self = this;
        let id = self.selectedSite._id ? self.selectedSite._id : self.selectedSite._leaflet_id;
        if(self.choosingColor) {
            self.siteLayers[id].setStyle({color});
            self.siteLayers[id].chosenColor = color;

            //We update our siteDefinition's shape to reflect this color
            let shape = _.find(self.siteDefinition.locs, (shape) => {
                return shape._id === id;
            });
            shape.color = color;
        }

        //other options include: weight: {pixels}, opacity: {number}, fillColor, fillOpacity
    }

    /**
     * TODO
     */
    addSiteNote() {
        let self = this;
        // let note = L.divIcon();
    }

    /**
     * When we click on the "T" button in HTML to add a label to a site.
     */
    addSiteLabel() {
        let self = this;
        let id = self.selectedSite._id ? self.selectedSite._id : self.selectedSite._leaflet_id;
        let note = L.tooltip({className: 'leaflet-tooltip-class'});
        let html
        = `
            <p>Enter a brief note concerning this site-area:</p </br>
            <input type="text" ng-model="$ctrl.noteInput"></intput>
        `;
        self.$ngConfirm(
            {
                title: `Adding Note`,
                theme: 'modern',
                content: html,
                escapeKey: true,
                backgroundDismiss: true,
                buttons: {
                    // long hand button definition
                    ok: {
                        text: "Ok",
                        btnClass: 'btn-primary',
                        keys: ['enter'], // will trigger when enter is pressed
                        action(scope) {
                            let noteContent = scope.$ctrl.noteInput;
                            let bounds = self.selectedSite.getBounds().getCenter();
                            // let divIcon = L.divIcon();

                            let marker = L.marker(bounds, {opacity:0, draggable: true, icon: self.mapMarkerWithClass});
                            marker.bindTooltip(noteContent, {direction: 'center', permanent: true, className: 'site-data-label', offset: [10,-5]})
                            .addTo(self.siteDetail2);

                            marker.isMarker = true;
                            // self.siteLayers[marker._leaflet_id] = marker;

                            let noteObject = {content: noteContent, loc: bounds};
                            self.siteDefinition.notes.push(noteObject);

                            marker.on('dragend', ($event) => {
                                noteObject.loc = $event.target._latlng;
                            });

                            self.siteLayers[id].disableEdit();
                            self.drawingPolygon = false;
                            self.isDrawing = false;
                            self.selectedSite = undefined;

                            self.handleLabelClasses();
                        }
                    },
                    close(scope) {

                    }
                },
            }
        );
    }

    /**
     * No usefulness here, I've changed this to do me some logs
     * @return {[type]} [description]
     */
    editPolygon() {
        let self = this;
    }

    /**
     * When we click on the plus-button to draw another layer
     */
    addAnotherPolygon() {
        let self = this;

        if(self.selectedSite._id) {
            let id = self.selectedSite._id;
            self.siteLayers[id].disableEdit();
            self.selectedSite = undefined;
        } else if(self.selectedSite._leaflet_id) {
            let id = self.selectedSite._leaflet_id;
            self.siteLayers[id].disableEdit();
            self.selectedSite = undefined;
        }

        let weight = self.siteDefinition.locs && self.siteDefinition.locs.length > 0 ? self.defaultSecondaryWeight : self.defaultPrimaryWeight;
        let newLayer = self.map.editTools.startPolygon(null, {weight, color: self.defaultDrawingColor});
        newLayer.isPolygon = true;

        let newID = newLayer._leaflet_id;
        self.siteLayers[newID] = newLayer;
        self.selectedSite = self.siteLayers[newID];
        self.isDrawing = true;
        self.drawingPolygon = true;

        self.siteLayers[newID].on('click', ($event) => {
            let layer = $event.target;
            layer.toggleEdit();
            self.setSelectedSite(layer);
        });
    }

    /**
     * When we click on the bluetooth-button to draw add a bluetooth beacon
     */
    addBluetoothBeacon(latLng) {
        let self = this;

        //if(self.selectedSite._id) {
            //let id = self.selectedSite._id;
            //self.siteLayers[id].disableEdit();
            //self.selectedSite = undefined;
        //}
        //else if(self.selectedSite._leaflet_id) {
            //let id = self.selectedSite._leaflet_id;
            //self.siteLayers[id].disableEdit();
            //self.selectedSite = undefined;
        //}

        let weight = self.siteDefinition.locs && self.siteDefinition.locs.length > 0 ? self.defaultSecondaryWeight : self.defaultPrimaryWeight;
		let newLayer;
		if(latLng) {
			newLayer = self.map.editTools.startMarker(latLng, {weight,icon:self.bluetoothBeaconMarker});
		}else{
			newLayer = self.map.editTools.startMarker(null, {weight,icon:self.bluetoothBeaconMarker});
		}
		newLayer.isMarker = true;

        let newID = newLayer._leaflet_id;
        self.siteLayers[newID] = newLayer;
		//self.setSelectedSite(newLayer);

		self.updateSiteTags();
        self.siteLayers[newID].on('click', ($event) => {
            let layer = $event.target;
            layer.toggleEdit();
			self.tagIdChanged(layer);
            self.setSelectedSite(layer);
        });
    }

    handleLabelClasses() {
        let self = this;
        let zoom = self.map.getZoom();

        if(zoom < 15 && self.map.hasLayer(self.siteDetail2)) {
            self.map.removeLayer(self.siteDetail2);
        } else if(zoom < 16 && self.map.hasLayer(self.siteDetail2)) {
            // self.map.removeLayer(self.siteDetail2);
            self.siteDetail2.eachLayer((layer) => {
                self.ensureLayerClass(layer._tooltip._container, 'detail2-smaller2');
            });
        } else if(zoom < 17 && self.map.hasLayer(self.siteDetail2)) {
            // self.map.removeLayer(self.siteDetail2);
            self.siteDetail2.eachLayer((layer) => {
                self.ensureLayerClass(layer._tooltip._container, 'detail2-smaller');
            });
        }

        if(zoom >= 17 && !self.map.hasLayer(self.siteDetail2)) {
            self.map.addLayer(self.siteDetail2);
        }
        if(zoom > 19 && self.map.hasLayer(self.siteDetail2)) {
            self.siteDetail2.eachLayer((layer) => {
                self.ensureLayerClass(layer._tooltip._container, 'detail2-bigger2');
            });
        } else if(zoom > 18 && self.map.hasLayer(self.siteDetail2)) {
            self.siteDetail2.eachLayer((layer) => {
                self.ensureLayerClass(layer._tooltip._container, 'detail2-bigger');
            });
        } else if(zoom >= 17 && self.map.hasLayer(self.siteDetail2)) {
            self.siteDetail2.eachLayer((layer) => {
                self.ensureLayerClass(layer._tooltip._container, 'normal');
            });
        }
    }

    handleLayerClasses() {
        let self = this;
        let zoom = self.map.getZoom();

        if(zoom < 14.5 && self.map.hasLayer(self.siteOutline)) {
            self.map.removeLayer(self.siteOutline);
        } else if(zoom >= 14.5 && !self.map.hasLayer(self.siteOutline)) {
            self.map.addLayer(self.siteOutline);
        }
    }

    /**
     * Currently we employ this function on mapZoom-change.
     * We want to handle the display of added labels as we zoom in and out
     */
    ensureLayerClass(layer, classname) {
        let self = this;
        let options = ['detail2-smaller', 'detail2-smaller2', 'detail2-bigger', 'detail2-bigger2'];
        let classes = L.DomUtil.getClass(layer);

        _.forEach(options, (option) => {
            if(classes.indexOf(option) !== -1) {
                L.DomUtil.removeClass(layer, option);
            }
        });

        if(L.DomUtil.hasClass(layer, classname)) {
            return;
        } else if (classname === 'normal') {
            return;
        } else {
            L.DomUtil.addClass(layer, classname);
        }
    }

    /**
     * We call this function at STEP3 of the wizard, via ng-click
     */
    onShowAdvancedSettings() {
        let self = this;

        if(!self.showAdvancedSettings) {
            self.showAdvancedSettings = !self.showAdvancedSettings;

            self.$timeout(() => {
                self.moveAdvancedSettingsIcon = true;
            }, 200);
        } else {
            self.moveAdvancedSettingsIcon = false;
            self.$timeout(() => {
                self.showAdvancedSettings = !self.showAdvancedSettings;
            });
        }
    }

    /**
     * We access this funtion from the advanced settings section.
     * NOTE: Question -> Do we want this behavior to be activated on THIS map we're defining our site on?
     * I don't really think so, I think it should be reflected on the dashboard map alone
     * Therefore, TODO: Take this logic to the dashboard of events' map
     */
    changeSitenamePopupSetting() {
        let self = this;
        let options = ['hover', 'click', 'permanent', 'disable'];
        let option = self.sitenamePopupSetting;
        let siteID = self.siteDefinition._id ? self.siteDefinition._id : undefined;
        let id = self.siteLayers[siteID]._id ? self.siteLayers[siteID]._id : undefined;

        let config = {};

        if(siteID !== undefined && id !== undefined) {
            let siteOutlineLayer = self.siteLayers[id];

            if(siteOutlineLayer.sitePopupSetting) {
                siteOutlineLayer.closePopup();
                siteOutlineLayer.unbindPopup();

                if(siteOutlineLayer.sitePopupSetting === 'hover' && option !== 'hover') {
                    // TODO: Just make sure that this removeEventListener isn't breaking something, somewhere
                    siteOutlineLayer.removeEventListener('mouseover');
                    siteOutlineLayer.removeEventListener('mouseout');
                }
            }
            if(option === 'disable') {
 return;
}

            switch (option) {
                case 'permanent':
                    config.closeButton = false;
                    config.autoClose = false;
                    config.closeOnClick = false;
                    break;
            }

            let popup = L.popup(config);
            popup.setContent(self.$sanitize(self.siteDefinition.name));
            siteOutlineLayer.bindPopup(popup);
            siteOutlineLayer.sitePopupSetting = option;

            if(option === 'hover') {
                siteOutlineLayer.on('mouseover', ($event) => {
                    if(!siteOutlineLayer.isPopupOpen()) {
                        siteOutlineLayer.openPopup();
                    }
                });
                siteOutlineLayer.on('mouseout', ($event) => {
                    if(siteOutlineLayer.isPopupOpen()) {
                        siteOutlineLayer.closePopup();
                    }
                });
            }

            if(option === 'permanent') {
                siteOutlineLayer.openPopup();
            }
        } else {
            //console.error("We are trying to show a popup for a layer that is not defined yet");
        }
    }

    closeModal() {
        let self = this;

		let siteDefinition = _.cloneDeep(self.siteDefinition);
        let result = {};
        result.address = siteDefinition.address;
        result.contacts = siteDefinition.contacts;
        result.groups = siteDefinition.groups;
        result.logoutSettings = siteDefinition.logoutSettings;
        result.requiresLogin = siteDefinition.requiresLogin;
        if(!result.contacts) {
            result.contacts = [];
        }
        result.description = siteDefinition.description;
        result.name = siteDefinition.name;
        if(self.editSite) {
            result._id = siteDefinition._id;
        }
        result.notes = siteDefinition.notes;
		//NOTE : Ensure site circle is calculated
		self.calculateCircle(siteDefinition);
        result.centerPoint = siteDefinition.centerPoint;
        result.radius = siteDefinition.radius;
        result.locs = [];
        _.forEach(siteDefinition.locs, (shape) => {
            let temp = {};
            temp.loc = shape.loc;
            temp.color = shape.color;
            temp.weight = shape.weight;
            result.locs.push(temp);
        });
		result.taggingSite = siteDefinition.taggingSite;
		result.tags = siteDefinition.tags;
		result.tags.forEach( (tag) => {
			tag.tagType = "Bluetooth";
		} );

		if (result.contacts && result.contacts.length > 0) {
			result.contacts.forEach((contact) => {
				contact = contact._id;
			});
		} else {
			delete result.contacts;
		}
		let locations = [];
		result.locs.forEach((subSite) => {
			subSite.loc.forEach((location) => {
				locations.push(L.latLng(location));
			});
		});
		let siteBound = L.latLngBounds(locations);
		let centerPoint = L.latLng(siteBound.getCenter());
		let maxDist = 0;
		locations.forEach((loc) => {
			let dist = centerPoint.distanceTo(loc);
			if (dist > maxDist) {
				maxDist = dist;
			}
		});
		result.centerPoint = {type:"Point",coordinates:[centerPoint.lng,centerPoint.lat]};
		result.radius = maxDist;

		let promise;
		if(self.editSite) {
			promise = self.siteService.updateSite(result);
		}else{
			delete result._id;
			promise = self.siteService.saveNewSite(result);
		}
		promise.then( (result) => {
			if(result && result._id) {
				self.toastr.info("Site saved");
				self.modalInstance.close();
			}else{
				self.toastr.error(result.data,"Site save failed");
			}
		} ).catch( (err) => {
			self.toastr.error(err.data || err.message || err,"Site save failed");
		} );
    }

    dismissModal() {
        let self = this;

        if(!self.editSite) {
            self.$ngConfirm(
                {
                    title: `Are you sure?`,
                    theme: 'modern',
                    content: `Clicking <b>Discard</b> would discard all information entered.`,
                    escapeKey: true,
                    backgroundDismiss: true,
                    buttons: {
                        // long hand button definition
                        ok: {
                            text: "Discard",
                            btnClass: 'btn-primary',
                            keys: ['enter'], // will trigger when enter is pressed
                            action(scope) {
                                self.modalInstance.dismiss();
                            }
                        },
                        // short hand button definition
                        close: {
                            text:'Cancel',
                            action(scope) {
                            }
                        }
                    },
                }
            );
        } else {
            self.$ngConfirm(
                {
                    title: `Are you sure?`,
                    theme: 'modern',
                    content: `Clicking <b>Yes</b> would discard all changes just made - it will not be saved.`,
                    escapeKey: true,
                    backgroundDismiss: true,
                    buttons: {
                        // long hand button definition
                        ok: {
                            text: "Yes",
                            btnClass: 'btn-primary',
                            keys: ['enter'], // will trigger when enter is pressed
                            action(scope) {
                                self.modalInstance.dismiss();
                            }
                        },
                        // short hand button definition
                        close(scope) {

                        }
                    },
                }
            );
        }
    }

    doLog() {
        console.debug(this);
    }

    generateNumber(min,max) {
        return Math.floor(Math.random()*(max-min+1)+min);
    }

	refreshGroups() {
		let self = this;
		self.privGroupService.getGroupWithQuery().then( (groups) => {
			self.availableGroups = groups;
		})
.catch(err=>{
			console.error(err);
		});
	}

	onGroupAdded() {
		let self = this;
		let label = " (create new group)";
		let tempGroups = [];
		_.forEach(self.siteDefinition.groups, (group) => {
			if (group.slice(-label.length) === label) {
				group = group.slice(0, -label.length);
			}
			tempGroups.push(group);
		});
		self.siteDefinition.groups = tempGroups;
	}

	isValidTagId(tag) {
		let self = this;
		let duplicateIds = _.reduce(self.siteLayers, (arr,layer) => {
			if(layer.tagId && tag == layer.tagId) {
				arr.push(tag);
			}
			return arr;
		},[]);
		if(tag && tag.length > 0 && duplicateIds.length == 1) {
			return true;
		}else{
			return false;
		}

	}

	tagIdChanged(marker) {
		let self = this;
		if(self.isValidTagId(marker.tagId)) {
			self.setMarkerAsValid(marker);
		}else{
			self.setMarkerAsInvalid(marker);
		}
	}

	setMarkerAsValid(marker) {
		let self = this;
		$(marker._icon).children('i')
.removeClass('invalidBeacon');
		$(marker._icon).children('i')
.addClass('validBeacon');
	}

	setMarkerAsInvalid(marker) {
		let self = this;
		$(marker._icon).children('i')
.addClass('invalidBeacon');
		$(marker._icon).children('i')
.removeClass('validBeacon');
	}

}
export default angular.module('secutraqApp.sites')
.component('newSite', {
    template: require('./new-site.component.html'),
    controller: NewSiteComponent,
    controllerAs: "$ctrl",
    bindings: {
        modalInstance: '=',
        resolve: '<'
    }
})
.filter('isBluetoothMarkersInvalid', function($filter) {
	return function(tags) {

		let ids = [];
		let valid = true;
		_.some(tags, (tag) => {
			if(!$filter('isValidTagId')(tag.tagId)) {
				valid = false;
				return true;
			}else if(ids.includes(tag.tagId)) {
				valid = false;
				return true;
			}else{
				ids.push(tag.tagId);
				return false;
			}
		});
		return !valid;

	};
})
.filter('isValidTagId', function() {
	return function(tagId) {

		let self = this;
		if(tagId && tagId.length > 0) {
			return true;
		}else{
			return false;
		}
	};
})
.name;
