export class ChangeUnitAccountComponent {
	$stateParams;
	Restangular;
	settings;
	availableGroups;
	contact;
	Contacts;
	modalInstance;

	/*@ngInject*/
	constructor($stateParams, Restangular, Auth) {
		this.$stateParams = $stateParams;
		this.Restangular = Restangular;
		this.Auth = Auth;
	}

	$onInit() {
		let self = this;
		self.Units = self.Restangular.all('units');
		self.availableUnits = [];
		self.refreshUnits('');
		self.availableAccounts = self.Auth.getCurrentUserSync().accounts;
	}

	refreshUnits(searchVal) {
		let self = this;
		self.Units.getList({
			filter : searchVal,
			query:{$ne:"API"},
			field:"type",
			limit : 20,
		}).then((units) => {
			self.availableUnits = units;
		})
.catch((err) => {
			console.error(err);
		});
	}

	ok() {
		let self = this;
		self.Units.customPOST({toAccountId:self.selectedAccount.ref,unitIds:self.selectedUnits},'changeAccount').then((result) => {

			self.modalInstance.close();
		});
	}

	// Modal cancelled: either cancel pressed or clicked off screen
	cancel() {
		let self = this;
		self.modalInstance.dismiss('Cancel pressed');
	}

	doLog() {
		console.debug(this);
	}
}

export default angular.module('secutraqApp.units')
	.component('changeunitaccount', {
		template: require('./change-unit-account-modal.html'),
		bindings: {
			resolve: '<',
			modalInstance: '<'
		},
		controller: ChangeUnitAccountComponent,
		controllerAs: '$ctrl'
	})
.name;
