export default function($stateProvider) {
    'ngInject';
    $stateProvider
	.state('main.fieldReports',{
		abstract:true,
		url: '/fieldReports',
		data: {
			title: 'Field Reports'
		},
		authenticate: true
	})
    .state('main.fieldReports.templates', {
        url: '/templates',
        views: {
            'content@main' : {
                template: '<field-report-templates></field-report-templates>'
            }
        },
        authenticate: true,
        data: {
            title: 'Field Report Templates'
        }
    })
    .state('main.fieldReports.reports', {
        url: '/reports',
        views: {
            'content@main' : {
                template: '<field-reports></field-reports>'
            }
        },
        authenticate: true,
        data: {
            title: 'Field Reports'
        }
    })

}
