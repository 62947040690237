export class RouteComponent {

	/*@ngInject*/
	constructor(routeService, $scope) {
		this.routeService = routeService;
		this.$scope = $scope;

	}

	$onInit() {
		let self = this;
		self.routeCollections = [];
		self.routeService.registerListener(self, (event, item, array) => {
			if (event === 'deleted') {
				self.deleteRoute(item);
			} else {
				self.onReceiptOfRoute(item);
			}
		}).then((routeCollections) => {
			routeCollections.forEach((routeCollection) => {
				self.onReceiptOfRoute(routeCollection);
			});
		});

	}

	$onDestroy() {
		let self = this;
		self.routeService.unregisterListener(self);
	}

	onReceiptOfRoute(routeCollection) {
		let self = this;
		let routes = routeCollection.routes;

		//Although we may save this later-on, as I'm receiving routeCollections, I'm simply also
		//calculating the distanceToNext
		_.forEach(routes, (route) => {
			let prev;
			let next;
			for (let i = 0; i < route.path.length; i++) {
				prev = i-1;
				if(prev >= 0) {
					route.path[prev].distanceToNext = route.path[i].formattedDistance;
				}
			}
		});

		let linkedToSite = routeCollection.linkedToSite;
		let boundedSite = linkedToSite ? routeCollection.boundedSite : undefined;
		if (routeCollection.waypoints && routeCollection.waypoints.length > 0) {
			routeCollection.waypoints.forEach((waypoint) => {
				let index = _.findIndex(waypoint.shape.geometries, (item) => {
					return item.type === 'Point';
				});
				if (index >= 0) {
					waypoint.loc = L.GeoJSON.coordsToLatLng(waypoint.shape.geometries[index].coordinates);
				}
			});
		}
		let index = _.findIndex(self.routeCollections, (storedRouteCollection) => {
			return storedRouteCollection._id === routeCollection._id;
		});

		if (index === -1) {
			self.routeCollections.push(routeCollection);
		} else {
			self.routeCollections.splice(index, 1, routeCollection);
		}

	}

	deleteRoute(routeCollection) {
        let self = this;
		let index = _.findIndex(self.routeCollections, (storedRouteCollection) => {
			return storedRouteCollection._id === routeCollection._id;
		});
		if (index >= 0) {
			self.routeCollections.splice(index, 1);
		} else {
			console.error("Route cannot be deleted, not in list");
		}
	}


}

export default angular.module('secutraqApp.routes')
	.component('route', {
		template: require('./route.html'),
		controller: RouteComponent,
		controllerAs: "$ctrl"
	})
	.name;
