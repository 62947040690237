export class CreateAPIKeyModalComponent {
	$uibModalInstance;
	settings;
	modalInstance;

	/*@ngInject*/
	constructor($stateParams, Auth, userService) {
		this.Auth = Auth;
		this.$stateParams = $stateParams;
		this.userService = userService;
	}

	$onInit() {
		let self = this;
		this.$uibModalInstance = this.modalInstance;
		this.settings = this.resolve.settings;

		// Add alert data to controller
		if (this.settings.item) {
			self.apiKey = self.settings.item;
		}else {
			self.apiKey = {
				integrationPartner : this.settings.type
			};
		}
	}

	// Modal accept: send resulting object back to promise in parent controller
	ok() {
		let self = this;
		this.$uibModalInstance.close(self.apiKey);
	}

	// Modal cancelled: either cancel pressed or clicked off screen
	cancel() {
		this.$uibModalInstance.dismiss('Cancel pressed');
	}


}
export default angular.module('secutraqApp.settings')
	.component('createapikeymodal', {
		template: require('./create-api-key.modal.html'),
		bindings: {
			resolve: '<',
			close: '&',
			dismiss: '&',
			modalInstance: '<',
			settings: '<'
		},
		controller: CreateAPIKeyModalComponent,
		controllerAs: '$ctrl'
	})
	.name;
