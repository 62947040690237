import _ from 'lodash-es';

export class UnitService {

    /*@ngInject*/
    constructor(moment, Restangular, socket, Auth, $ngConfirm) {
        this.moment = moment;
        this.$ngConfirm = $ngConfirm;
        this.Restangular = Restangular;
		this.units = [];
		this.listenerMap = new Map();
		this.socket = socket;
		this.Auth = Auth;
		this.cacheTimeout = undefined;
        this.lastAccount = undefined;
		this.lastUnit = undefined;
		this.joined = false;
		this.rooms = [];
		this.throttledFunctions = {};
		this.throttledFunctionCalled = {};
    }

    //Copy
	onSocketEvent(event, item, array) {
		let self = this;
        self.units = array;
		self.listenerMap.forEach((value, key) => {
			setTimeout(() => {
value(event, item, array);
}, 0);
		});
	}

	registerListener(context, cb) {
		let self = this;
		if(!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if(self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
        if(!self.lastUser) {
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        } else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
            self.destroyService();
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        }
		let count = self.listenerMap.size;
		self.listenerMap.set(context, cb);
		// If listenermap was empty, (TODO and timer isn't running), join room, syncupdates
		if(!self.joined) {
			if(self.cacheTimeout) {
				clearTimeout(self.cacheTimeout);
				self.cacheTimeout = undefined;
			}
			return self.getUnits().then((units) => {
				self.socket.syncUpdates('unit', self.units, self.onSocketEvent.bind(self), self);
				let filter = {};
				let hasPrivilege = self.Auth.hasPrivilegeSync('event.index',undefined,filter);
				if(hasPrivilege && filter && filter.filterParam && filter.filterParam.field == 'groups') {
					filter.filterParam.data.forEach( (group) => {
						self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().ref}:*:units:${group}`);
						self.rooms.push(`${self.Auth.getCurrentAccountSync().ref}:*:units:${group}`);
					} );
				}else{
					self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().ref}:*:units`);
					self.rooms.push(`${self.Auth.getCurrentAccountSync().ref}:*:units`);
				}
				self.joined = true;
				return units;
			});
		} else {
			if(self.cacheTimeout) {
				clearTimeout(self.cacheTimeout);
				self.cacheTimeout = undefined;
			}
			return self.getUnits();
		}
	}

	destroyService() {
		let self = this;
		if(self.joined) {
			self.socket.unsyncUpdates('unit', self);
			if(self.rooms && self.rooms.length > 0) {
				self.rooms.forEach( (room) => {
					self.socket.leaveRoom(room);
				} );
				self.rooms = [];
			}
		}
		self.joined = false;
		self.units = [];
		self.listenerMap.clear();
		clearTimeout(self.cacheTimeout);
		self.cacheTimeout = undefined;
	}

	unregisterListener(context, noCache) {
		let self = this;
		if(!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if(self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
        if(!self.lastUser) {
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        } else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
            self.destroyService();
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        }
		self.listenerMap.delete(context);
		if(self.listenerMap.size === 0) {
            if(noCache) {
                self.destroyService();
            }else {
                self.cacheTimeout = setTimeout(self.destroyService.bind(self), 5000);
            }
		}
	}

	unregisterAll() {
		let self = this;
		if(!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if(self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
        if(!self.lastUser) {
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        } else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
            self.destroyService();
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        }
		self.listenerMap.clear();
		if(self.listenerMap.size === 0) {
			self.cacheTimeout = setTimeout(self.destroyService.bind(self), 5000);
		}
	}

    getUnits(query) {
		let self = this;
		if(self.units.length > 0 && !query) {
			return new Promise(function(resolve) {
				resolve(_.cloneDeep(self.units));
			});
		}
        let lastAccount = _.cloneDeep(self.lastAccount);
        let lastUser = _.cloneDeep(self.lastUser);
        return this.Restangular.all('units').getList(query)
        .then((units)=>{

            if(lastAccount == self.lastAccount && lastUser == self.lastUser) {
				if(!query) {
					self.units = this.Restangular.stripRestangular(units);
				}
                return _.cloneDeep(units);
            }else {
                return [];
            }
        });
    }

    getUnitsWithQuery(query) {
        return this.Restangular.all('units').getList(query)
        .then((units)=>{
            return units;
        });
    }

    //Custom (Change and keep if necessary)
    getUnitByID(unitID, select) {
        let self = this;
        let result = _.find(self.units, (unit) => {
            return unit._id === unitID;
        });
        if(result) {
            if(select && select.lenght > 0) {
                result = _.pick(result,select);
            }
            return Promise.resolve(_.cloneDeep(result));
        }else {
            return self.Restangular.one('units', unitID).get({select});
        }
    }

    // getUnitByIDSync(unitID) {
    //     let self = this;
    //     let result = _.find(self.units, (unit) => {
    //         return unit._id === unitID;
    //     });
    //     return _.cloneDeep(result);
    // }
    getUnitByIDSync(unitId) {
        let self = this;
        if(self.units && self.units.length > 0) {
            let index = _.findIndex(self.units,(storedUnit) => {
                return storedUnit._id === unitId;
            });

            if(index !== -1) {
                return _.cloneDeep(self.units[index]);
            }else{
                return 'No such unit!';
            }
        }else{
            return 'No such unit!';
        }
    }

    getUnitIndexByID(unitID) {
        let self = this;

        let idx = _.findIndex(self.units, (unit) => {
            return unit._id === unitID;
        });
        return idx;
    }

    getUnitIndexByIDSync(unitID) {
        let self = this;

        let idx = _.findIndex(self.units, (unit) => {
            return unit._id === unitID;
        });
        return idx;
    }


    getGroups(query) {
        let self = this;
        let groups = self.Restangular.all('units').all('groups');
        return groups.getList(query);
    }

    getVersions(query) {
        let self = this;
        let versions = self.Restangular.all('units').all('versions');
        return versions.getList(query);
    }

    softwareUpdate(query, appVersion) {
        let self = this;
		let body = {
			query,
			requiredAppVersion:{
				_id:appVersion._id,
				version:appVersion.version
			}
		};
        return self.Restangular.all('units').customPOST(body,'softwareUpdate');
    }

    softwareUpdateSingleUnit(unitId, appVersion) {
        let self = this;
		let body = {
			requiredAppVersion:{
				_id:appVersion._id,
				version:appVersion.version
			}
		};
        return self.Restangular.one('units',unitId).post('softwareUpdate',body);
    }

    /**
     * Currently I'm employing this as a GET for when I want to show
     * breadcrumb-mode for a selected event.
     * I'm a bit unfamiliar with how we treat heartbeats. Does each hb represent a 'ping' of a unit.
     * And each 'ping' contains relevant information pertaining to the unit's current status.
     * To 'generate' my own heartbeats, I have to move the the units a few times
     * @param  {[type]} unitID [description]
     * @return {[type]}        [description]
     */
    getUnitHeartbeats(unitID) {
        let self = this;
        let heartbeats = [];
        return new Promise((resolve, reject) => {
            let unit = _.cloneDeep(self.getUnitByIDSync(unitID));
            _.forEach(unit.lastHB, (lastHB) => {
                heartbeats.unshift(lastHB);
            });
            resolve(heartbeats);
        });
    }

    getUnitHeartbeatsSync(unitID) {
        let self = this;
        let heartbeats = [];
        let unit = _.cloneDeep(self.getUnitByIDSync(unitID));
        if(unit && unit.lastHB && unit.lastHB.length > 0) {
            _.forEach(unit.lastHB, (lastHB) => {
                heartbeats.unshift(lastHB);
            });
            return heartbeats;
        }else{
            //TODO: Handle appropriately
            console.error("No Heartbeats");
        }
    }

    getUnitLocationsSync(unitID) {
        let self = this;
        let locations = [];
        let unit = _.cloneDeep(self.getUnitByIDSync(unitID));
        return unit.lastLocation;
    }


    requestSnapshot(unitId) {
        let self = this;
		self.throttledFunctionCalled[unitId] = false;
		if(!self.throttledFunctions[unitId]) {
			self.throttledFunctions[unitId] = _.throttle( () => {
				self.throttledFunctionCalled[unitId] = true;
				return self.peripheralRequest(unitId,'SnapshotRequest');
			} ,10000, {trailing:false});
		}
        return self.throttledFunctions[unitId]().then( (val) => {
			return self.throttledFunctionCalled[unitId];
        } );
    }

    requestVideo(unitId) {
        let self = this;
        return self.peripheralRequest(unitId,'VideoRequest');
    }

    requestInitiateStream(unitId, peerId, camera) {
        let self = this;
        return self.peripheralRequest(unitId,'InitiateStream', {peerId, camera});
    }

    requestDestroyStream(unitId, peerId, camera) {
        let self = this;
        return self.peripheralRequest(unitId,'DestroyStream', {peerId, camera});
    }

    requestDMSCommand(unitId, command) {
        let self = this;
        return self.peripheralRequest(unitId,'DMSCommand', {command});
    }

    requestVideoWithParams(unitId,params) {
        let self = this;
        return self.peripheralRequest(unitId,'VideoRequest',params);
    }

    peripheralRequest(unitId, type, options) {
        let self = this;
        return self.Restangular.one('units',unitId).customPOST(_.merge({type},options),'peripheralRequest');
    }
    syncUnit(unitId) {
        let self = this;
        return self.Restangular.one('units',unitId).customPOST({},'syncChreosis');
    }
    changeLprAnalytics(unit,value) {
        let self = this;
        return self.Restangular.one('units',unit._id).customPATCH({lprAnalytics:value},'changeLprAnalytics');
    }
    changeAPIAuthorization(unit,value) {
        let self = this;
        return self.Restangular.one('units',unit._id).customPATCH({apiEnabled:value});
    }
    changeAnalytics(unit,value) {
        let self = this;
        return self.Restangular.one('units',unit._id).customPATCH({analytics:value},'changeAnalytics');
    }
    updateUnit(unit) {
        let self = this;
        return self.Restangular.one('units',unit._id).customPATCH(unit);
    }
    changeAllAnalytics(value) {
        let self = this;
        return self.Restangular.all('units').customPATCH({analytics:value},'changeAllAnalytics');
    }
    createUnit(unit) {
        let self = this;
        return self.Restangular.all('units').post(unit);
    }

	getUnistWithCustomQuery(query) {
		return this.Restangular.all('units').customGETLIST('custom',query)
		.then((units)=>{
			return units;
		});
	}

	countUnistWithCustomQuery(query) {
		return this.Restangular.all('units').all('countCustom')
.getList(query)
		.then((count)=>{
			return count;
		});
	}

}

export default angular.module('secutraqApp.dashboard')
.service('unitService', UnitService);
