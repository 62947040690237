import { Timeline } from 'vis-timeline';
import { DataSet } from 'vis-data';
import randomColor from 'randomcolor';
// var DataSet = require('vis/lib/DataSet');
// var Timeline = require('vis/lib/timeline/Timeline');

// TODO: rRules -> We started talking about rather using excluding instead of including types of rules.
// Before we could start implementing this we had to switch over to cameraviewer

export class VisScheduleTimelineComponent {
	/*@ngInject*/
	constructor(moment, $scope, $timeout, $compile, $uibModal, siteService, routeService, Restangular, scheduleService, toastr, shiftService, eventService, $filter, mapService, $sanitize) {
		this.moment = moment;
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.$compile = $compile;
		this.$sanitize = $sanitize;
		this.$uibModal = $uibModal;
		this.siteService = siteService;
		this.routeService = routeService;
		this.scheduleService = scheduleService;
		this.eventService = eventService;
		this.shiftService = shiftService;
		this.Restangular = Restangular;
		this.toastr = toastr;
		this.$filter = $filter;
		this.mapService = mapService;
	}

	$onInit() {
		let self = this;

		/**
		 * If we are busy defining a new schedule I'm keeping a @watch on self.isSite.
		 * isSite refers to the fact that a user is defining a schedule for a specific site, meaning
		 * we would have to retrieve all that site's routes.
		 * @type {Boolean}
		 */
		self.isSite = false;

		/**
		 * If indeed we are defining a schedule for a specific site, we store its routes here
		 * @type {Array}
		 */
		self.siteRoutes = [];

		self.tempTestShiftGroups = {};

		/**
		 * Only for CSS-purposes to display the element once schedule has been created
		 * @type {Boolean}
		 */
		self.scheduleCreated = false;

		self.dummyEvent = {
			id: 'dummyEvent',
			content: "Dummy event",
			start: self.moment(self.viewDate).add(5, 'minutes'),
			group: ['dummyGroup']
		};

		self.dummyGroup = {
			id: 'dummyGroup',
			content: 'Dummy Group',
			visible: false
		};

		let container = document.getElementById('visScheduleTimeline');
		self.data = new DataSet([self.dummyEvent]);
		self.groups = new DataSet([self.dummyGroup]);
		self.throtledRefresh = _.throttle(self.refresh, 2000);
		let options;
		self.shiftService.registerListener(self, (event, item, array) => {
			if (event === 'deleted') {
				// self.deleteRoute(item);
			} else {
				self.updateShift(item);
			}
		});

		if (self.definingSchedule) {
			options = {
				editable: {
					add: true,
					remove: true,
					updateTime: true,
					updateGroup: false,
					overrideItems: false,
				},
				stack: true,
				// groupEditable: true,
				onUpdate: self.onUpdate.bind(self),
				onAdd: self.onAddScheduleItem.bind(self),
				onMoving: self.onMovingScheduleItem.bind(self),
				onRemove: self.onRemoveScheduleItem.bind(self),
				snap(date, scale, step) {
					let duration = self.moment.duration(step,scale).asMilliseconds();
					duration = Math.max(duration, 60*1000);
					return Math.round(date/duration) * duration;

				},
				groupOrder(a, b) {
					return a.value - b.value;
				},
				groupOrderSwap(a, b, groups) {
					var v = a.value;
					a.value = b.value;
					b.value = v;
				},
				template: (item, element, data)=>{
					return self.getItemTemplate(item);
				},
				groupTemplate: (item, element, data) => {
					if (item.type == 'template') {
						//This is XSS safe because a user cannot provide custom input in any of these elements
						let el = self.$compile(
							`
								<div style='display:flex;justify-content:space-between;align-items:center;'>
									<div style='flex-grow:1;margin-right:20px;'>
										${item.content}
									</div>
									<a href="" style="margin-right:3px;" ng-click="$ctrl.deleteGroupButtonClicked('${item.id}')"><i class='fa fa-trash'></i></a>
									<a href="" ng-click="$ctrl.editTemplate('${item.id}')"><i class="fa fa-pencil" aria-hidden="true"></i></a>
								</div>
								<small style="display:flex">
									Assets : <div >{{$ctrl.tempTestShiftGroups['${item.id}'].length || 0}}</div>
								</small>
							`)(self.$scope);
						let angularElement = angular.element(element);
						angularElement.empty();
						return angularElement.append(el);
					} else {
						return self.$sanitize(item.content);
					}
				}
				// height: '100%'
			};
		} else {
			options = {
				stack: false,
				template: (item, element, data)=>{
					return self.getItemTemplate(item, element,data);
				},
				//Removing this due to XSS issues
				groupTemplate: (item, element, data) => {
					return self.$sanitize(item.content);

					if (item.type == 'schedule') {
						// NOTE: This will remove all element with the tooltip class.  This is necessary because
						//       the tooltip for the remove icon doesn't remove itself.
						$('.tooltip').remove();
						let el = self.$compile(

										//${self.$sanitize(item.content)}
							`
								<div style='display:flex;justify-content:space-between;align-items:center;'>
									<div style='flex-grow:1;margin-right:20px;'>
									</div>
									<a uib-tooltip="Long press to remove schedule." tooltip-append-to-body='true' style="margin-right:3px;" ng-click="$ctrl.deleteSchedule('${item._id}')"><i class='fa fa-trash'></i></a>
								</div>
							`)(self.$scope);
						let angularElement = angular.element(element);
						angularElement.empty();
						return angularElement.append(el);
					} else {
						return item.content;
					}
				}
			};
		}
		self.timeline = new Timeline(container, self.data, self.groups, options);

		if (!self.definingSchedule) {
			self.debouncedRefresh = _.debounce(self.refresh, 1000, {
				maxWait: 5000
			});
			self.$scope.$watch(() => {
				return self.events;
			}, (newVal, oldVal) => {
				self.debouncedRefresh();
			});
			self.timeline.on('select',() => {
				// TODO: Find a more effective way to do this.
				self.addEventStyles();
			});
		}

		/**
		 * If we are defining a schedule I'm keeping a @watch on self.isSite
		 * This is because a user can start defining a schedule and lateron decide to bind it to a site.
		 * -> We should then still retrieve the site's routes AND make sure their dragging-evet gets bounded
		 * (@see handleRoutingDragAndDrop)
		 * NOTE that self.isSite is a local variable that we set here
		 */
		if (self.definingSchedule) {
			self.$scope.$watch(() => {
				return self.scheduleDefinition.shifts;
			}, (newVal, oldVal) => {
				if (newVal !== oldVal) {
					// self.refreshSchedule();
					self.handleRoutingDragAndDrop();
					self.calculateSlots();
				}
			},true);

			self.$scope.$watch(() => {
				return self.tempTestShiftGroups;
			}, (newVal, oldVal) => {
				if (newVal !== oldVal) {
					self.timeline.redraw();
				}
			},true);

			self.$scope.$watchGroup([
				() => {
					return self.scheduleDefinition.routes;
				},
				() => {
					return self.scheduleDefinition.site;
				}
			], (newVal, oldVal) => {
				if (newVal !== oldVal) {
					self.refreshSchedule();
					// self.handleRoutingDragAndDrop();
				}
			},true);
		}


		//We emit this after calling add-schedule's component readyTimeline()
		self.$scope.$on('readyTimeline', ($event, data) => {
			self.refreshSchedule();
		});

		// $onInit() end
	}

	$onDestroy() {
		let self = this;
		self.shiftService.unregisterListener(self);
	}

	/**
	 * In our html we have <div class="vis-key-routing"></div>.
	 * We ONLY have them if a site has been selected for the schedule and that site's routes have
	 * been retrieved.
	 */
	handleRoutingDragAndDrop() {
		let self = this;
		self.$timeout(() => {
			let routing = angular.element(document.getElementsByClassName('vis-key-routing'));
			routing.on('dragstart', self.onDragRoute.bind(self));
			let roaming = angular.element(document.getElementsByClassName('vis-key-roaming'));
			roaming.on('dragstart', self.onDragRoute.bind(self));
		});
	}

	refresh() {
		let self = this;
		if (self.view == 'day' || self.view == 'hour') {
			self.data.clear();
			self.groups.clear();
			self.getAllEvents().then((events) => {
				return events;
			})
.then((events) => {

				if (events.length === 0) {
					self.data.add(self.dummyEvent);
					self.groups.add(self.dummyGroup);
					return null;
				}else {
					return events;
				}

			})
.then((events) => {
				if(!events) {
					self.moveTimeline();
					return null;
				}else {
					events.forEach((event) => {
						switch (event.mainScheduleType) {
							case 'event':
								self.formatEvent(event);
							break;
							case 'schedule':
								self.formatSchedule(event);
							break;
							case 'shift':
								self.formatShift(event);
							break;
							default:

						}

					});
					self.timeline.setData({
						groups: self.groups,
						items: self.data
					});
					self.moveTimeline();
					self.$timeout(function() {
						self.timeline.redraw();
						self.addEventStyles();
						// self.timeline.fit();
					}, 510);
				}
			})
.catch((err) => {

				console.error(err);
			});
		}
	}


	formatShift(shift) {
		let self = this;

		let assetGroups = _.groupBy(shift.slots, 'assetNum');

		// if(event.start){
		//
		// }
		let siteGroup = {};
		siteGroup.id = `${shift.schedule}:${self.moment(shift.start).unix()}`;
		if (shift.site) {
			// let site = self.siteService.getSiteByIDSync(shift.site);
			siteGroup.content = `${shift.name} - ${shift.sitename}`;
		}else {
			siteGroup.content = shift.name || 'New Schedule';
		}

		for (var asset in assetGroups) {
			if (assetGroups.hasOwnProperty(asset)) {
				let slotCounter = 0;
				let assetId = `${shift.schedule}:${self.moment(shift.start).unix()}:${asset}`;
				if (siteGroup.id) {
					if (!siteGroup.nestedGroups) {
						siteGroup.nestedGroups = [];
					}
					siteGroup.nestedGroups.push(assetId);
				}
				let content = 'Asset';
				if(assetGroups[asset][0].assetname) {
					content = assetGroups[asset][0].assetname;
				}
				self.groups.add({
					id: assetId,
					content
				});
				assetGroups[asset].forEach((slot) => {
					let item = {};
					let slotId = `${assetId}:${slotCounter}`;
					item.metadata = _.cloneDeep(slot);
					// item.id = slotId;
					if(item.metadata.type == 'Route') {
						item.subgroup = 'Route';
						let route = self.routeService.getSubRouteByIdSync(item.metadata.typeId);
						if(route.name) {
							item.content = `Route - ${route.name}`;
						}else {
							item.content = `Route`;
						}
					}else{
						item.subgroup = item.metadata.type;
						item.content = item.metadata.type;
					}
					item.start = new self.moment(shift.start).add(slot.startTime, 'seconds')
.toDate();
					item.end = new self.moment(item.start).add(slot.duration, 'seconds')
.toDate();
					item.group = assetId;
					item.className = self.getItemClassName(item);
					self.data.add(item);
					slotCounter++;
				});
			}
		}
		if (siteGroup.id) {
			self.groups.add(siteGroup);
		}
		return;
	}

	formatSchedule(schedule) {
		let self = this;
		if(self.moment.utc(schedule.startsAt) < self.moment.utc()) {
			return;
		}
		let assetGroups = _.groupBy(schedule.slots, 'assetNum');

		// if(event.start){
		//
		// }
		let siteGroup = {};
		siteGroup.id = `${schedule._id}:${self.moment(schedule.startsAt).unix()}`;
		siteGroup.type = 'schedule';
		siteGroup._id = schedule._id;
		if (schedule.site) {
			siteGroup.content = `${schedule.name} - ${schedule.sitename}`;
		}else {
			siteGroup.content = schedule.name || 'New Schedule';
		}

		for (var asset in assetGroups) {
			if (assetGroups.hasOwnProperty(asset)) {
				let slotCounter = 0;
				let assetId = `${schedule._id}:${self.moment(schedule.startsAt).unix()}:${asset}`;
				if (siteGroup.id) {
					if (!siteGroup.nestedGroups) {
						siteGroup.nestedGroups = [];
					}
					siteGroup.nestedGroups.push(assetId);
				}
				let content = 'Asset';
				if(assetGroups[asset][0].assetname) {
					content = assetGroups[asset][0].assetname;
				}
				self.groups.add({
					id: assetId,
					content
				});
				assetGroups[asset].forEach((slot) => {
					let item = {};
					let slotId = `${assetId}:${slotCounter}`;
					item.metadata = _.cloneDeep(slot);
					// item.id = slotId;
					if(item.metadata.type == 'Route') {
						item.subgroup = 'Route';
						let route = self.routeService.getSubRouteByIdSync(item.metadata.typeId);
						if(route.name) {
							item.content = `Route - ${route.name}`;
						}else {
							item.content = `Route`;
						}
					}else{
						item.subgroup = item.metadata.type;
						item.content = item.metadata.type;
					}
					item.start = new self.moment(schedule.startsAt).add(slot.startTime, 'seconds')
.toDate();
					item.end = new self.moment(item.start).add(slot.duration, 'seconds')
.toDate();
					item.group = assetId;
					item.className = self.getItemClassName(item);
					self.data.add(item);
					slotCounter++;
				});
			}
		}
		if (siteGroup.id) {
			self.groups.add(siteGroup);
		}

		return;
	}

	formatEvent(event) {
		let self = this;
		event.start = event.createdAt;
		event.title = self.createTooltip(event);
		self.data.add(event);
		return;
	}

	formatEventFromShift(shift) {
		let self = this;
		let tempEvents = _.cloneDeep(shift.events);
		tempEvents.forEach((event) => {
			event.mainScheduleType = 'event';
			event.shift = shift._id;
			let slot = _.find(shift.slots,(o) => {
				if(event.slot) {
					return o._id == event.slot;
				}else if(event.unit && o.metaData && o.metaData.assignedUnit) {
					if(event.eventType == 'Geo-fence In' || event.eventType == 'Geo-fence Out') {
						if(event.metaData && event.metaData.geoZone) {
							return o.metaData.assignedUnit == event.unit && o.type == 'Route';
						}else {
							return o.metaData.assignedUnit == event.unit && o.type == 'Roaming';
						}
					}else{
						return false;
					}
				}
			});
			if(slot) {
				event.group = `${shift.schedule}:${self.moment(shift.start).unix()}:${slot.assetNum}`;
				event.subgroup = slot.type;
			}else {
				event.group = `${shift.schedule}:${self.moment(shift.start).unix()}`;
			}
		});
		delete shift.events;
		return tempEvents;
	}

	getAllEvents() {
		let self = this;
		return self.getShifts().then((shifts) => {
			let events = _.reduce(shifts, (arr,shift) => {
				arr = arr.concat(self.formatEventFromShift(shift));
				return arr;
			},[]);


			let filteredSchedules = self.$filter('filter')(self.events,{$:self.filter});
			self.total = self.totalShifts + filteredSchedules.length;
			let filteredAndLimitedSchedules = filteredSchedules.slice(0,9);
			self.count = filteredAndLimitedSchedules.length + shifts.length;
			filteredAndLimitedSchedules.forEach((schedule) => {
				schedule.mainScheduleType = 'schedule';
			});
			shifts.forEach((shift) => {
				shift.mainScheduleType = 'shift';
			});
			let finalEvents = _.concat(filteredAndLimitedSchedules,events,shifts);
			finalEvents.sort((a,b) => {
				if(a.mainScheduleType == 'event') {
					return 1;
				}else if (b.mainScheduleType == 'event') {
					return -1;
				}
			});
			return finalEvents;
		});
	}

	refreshSchedule() {
		let self = this;
		self.data.clear();
		self.groups.clear();

		//If there is no scheduleDefinition, we simply load blank data
		if (!self.scheduleDefinition) {
			self.data.add(self.dummyEvent);
			self.groups.add(self.dummyGroup);
		} else {
			//We have a self.scheduleDefinition which means we should at least have
			// 1) a mainSchedule -> [This is the top row, we might let this fall away],
			// 2) a single item in this mainSchedule -> [This is the name of our schedule. Again, this might fall away]
			self.siteGroup = {
				nestedGroups: []
			};
			let mainSchedule = {};
			let item = {};

			/**
			 * We are binding scheduleDefinition: '<'
			 * @param  {boundedOptions} - @see getScheduleDefinition() (scheduling.modal.component)
			 */
			if (self.scheduleDefinition.site) {
				self.siteGroup.id = self.moment(self.scheduleDefinition.start).unix();
				self.siteGroup.content = self.scheduleDefinition.site.name
					? self.scheduleDefinition.site.name
					: 'SITENAME';
			}

			self.siteRoutes = _.uniq(self.scheduleDefinition.routes);
			let colors = randomColor({count:self.siteRoutes.length,luminosity:'light'});
			self.siteRoutes.forEach((route,index) => {

				route.style = {
					margin: "10px",
					// width: 25px;
					// height: 25px;
					display:"flex",
					"justify-content": "center",
					"align-items": "center",
					"background-color": `${colors[index]}`,
					"border-radius": "4px",
					"box-shadow": "0px 0px 1px 0px black",
				};
				route.tempColor = colors[index];
			});
			mainSchedule.id = "schedule";
			self.siteGroup.nestedGroups.push(mainSchedule.id);
			mainSchedule.content = "Schedule";
			self.groups.add(mainSchedule);
			if (self.scheduleDefinition.start) {
				item.content = self.scheduleDefinition.name;
				item.start = new self.moment(self.scheduleDefinition.start).startOf('minute')
.toDate();
				item.end = new self.moment(self.scheduleDefinition.end).startOf('minute')
.toDate();
				item.group = mainSchedule.id;
				item.editable = false;
				item.className = "main-schedule";
				self.data.add(item);
			}else {
				console.error('NO Start for schedule');
			}


			if (self.siteGroup.id) {
				self.groups.add(self.siteGroup);
			}

			if (!self.scheduleDefinition.slots || self.scheduleDefinition.slots.length == 0) {
				self.scheduleDefinition.shifts = [];
				if(self.scheduleDefinition && self.scheduleDefinition.site) {


					let firstShiftItem = {};
					firstShiftItem.content = "Roaming";
					firstShiftItem.start = new self.moment(self.scheduleDefinition.start).startOf('minute')
.toDate();
					firstShiftItem.end = new self.moment(self.scheduleDefinition.end).startOf('minute')
.toDate();
					firstShiftItem.duration = self.minimumDurationSeconds || 60 * 20;
					firstShiftItem.type = 'range';
					firstShiftItem.itemType = 'Roaming';
					// firstShiftItem.editable = true;
					// firstShiftItem.id = new Date(); Don't do this. it breaks things

					let firstShiftGroup = {};
					// firstShiftGroup.id = "template1";
					firstShiftGroup.content = "Template 1";
					firstShiftGroup.type = 'template';
					firstShiftGroup.slots = [];
					// firstShiftGroup.items = [firstShiftItem];

					self.addShiftGroupToSiteGroup(firstShiftGroup);
					self.addItemToShiftGroup(firstShiftGroup.id, firstShiftItem);
				}
			} else {
				//We have self.scheduleDefinition.shifts -> Let's import them

				self.scheduleDefinition.shifts = [];
				let shifts = _.groupBy(self.scheduleDefinition.slots,'priority');
				_.forEach(shifts, (shiftItems,shiftKey) => {
					let shiftGroup = {content: `Template ${shiftKey}`,value:shiftKey-1,type:'template',slots:[]};
					self.addShiftGroupToSiteGroup(shiftGroup);

					self.generateItemsAndAssets(shiftItems, shiftGroup);
				});
			}

			self.timeline.setData({
				groups: self.groups,
				items: self.data
			});

			self.timeline.fit();

			self.$timeout(() => {
				self.scheduleCreated = true;
			}, 650);
		}
	}

	generateItemsAndAssets(slots,shiftGroup) {
		let self = this;
		let assets = [];
		//Get uniq assets {unit, asset, group}
		let singleSlot = _.filter(slots,(a) => {
			return a.startTime == slots[0].startTime && a.duration == slots[0].duration && a.type == slots[0].type;
		});
		singleSlot.forEach((slot) => {
			let asset = {};
			if(slot.unit) {
				asset.unit = slot.unit;
			}
			if(slot.asset) {
				asset.asset = slot.asset;
				asset.assetname = slot.assetname;
			}
			if(slot.group) {
				asset.group = slot.group;
			}
			assets.push(asset);
		});
		// assets = _.uniqWith(assets,_.isEqual);
		slots = _.uniqWith(slots,(a,b) => {
			return a.startTime == b.startTime && a.duration == b.duration && a.type == b.type;
		});

		self.tempTestShiftGroups[shiftGroup.id] = assets;
		slots.forEach((shiftSlot) => {
			let tempItem = self.generateShiftItemFromSlot(shiftSlot);
			if(tempItem) {
				self.addItemToShiftGroup(shiftGroup.id, tempItem);
			}
		});

	}


	/**
	 * Whenever we reload our schedule, we need to make sure that correct CSS clasnames are given to each item.
	 * This depends on whether it is ROUTING or ROAMING and if routing, which route
	 * @param  {[type]} item [description]
	 * @return {[type]}      [description]
	 */
	getItemClassName(item) {
		let self = this;
		if (item.content === 'roaming' || item.content == 'Roaming') {
			return 'roaming';
		} else {
			let index = _.findIndex(self.siteRoutes, (o) => {
				return o.name === item.content;
			});
			if (index !== -1) {
				return `routing${index}`;
			}
			return `routing${1}`;
		}
	}

	getStyle(shiftItem) {
		let self = this;
		if(shiftItem.itemType == 'Route') {
			let route = _.find(self.siteRoutes,(o) => {
				return o._id == shiftItem.typeId;
			});
			return `background-color:${route.tempColor};`;
		}else {
			return '';
		}
	}

	generateShiftItemFromSlot(slot) {
		let self = this;
		let item = {};
		if(slot.type == 'Roaming') {
			if(!self.scheduleDefinition.site) {
				return null;
			}
			item.content = 'Roaming';
			item.itemType = 'Roaming';
		}else if(slot.type == 'Route') {
			let route = _.find(self.scheduleDefinition.routes,(o) => {
				return o._id == slot.typeId;
			});
			if(route) {
				item.content = route.name;
				item.itemType = 'Route';
			}else {
				return null;
			}
		}
		item.startTime = self.moment(self.scheduleDefinition.start).add(slot.startTime,'seconds');
		item.start = self.moment(self.scheduleDefinition.start).add(slot.startTime,'seconds');
		item.endTime = self.moment(item.start).add(slot.duration,'seconds');
		item.end = self.moment(item.start).add(slot.duration,'seconds');
		item.minimumDuration = slot.minimumDuration;
		item.type = 'range';
		item.typeId = slot.typeId;
		item.style = self.getStyle(item);
		return item;
	}

	/**
	 * Adds a shiftGroup [template1, template2] to our siteGroup
	 * @param {Object} shift The definition of a shift-template for our schedule. {id, content, slots}
	 * NOTE: siteGroup is our main object for the schedule that also contains an array field, nestedGroups
	 */
	addShiftGroupToSiteGroup(shift) {
		let self = this;

		//Updating our scheduleDefinition.shifts
		let shiftIndex = _.findIndex(self.scheduleDefinition.shifts, (o) => {
			return o.id === shift.id;
		});
		shift.nestedInGroup = self.siteGroup.id;
		if (typeof shift.value !== 'number') {
			if (self.scheduleDefinition.shifts && self.scheduleDefinition.shifts.length > 0) {
				shift.value = self.scheduleDefinition.shifts.length;
			} else {
				shift.value = 0;
			}
		}
		if (shiftIndex === -1) {
			//New shift is being added to siteGroup
			self.groups.add(shift);
			self.siteGroup.nestedGroups.push(shift.id);
			if(!self.scheduleDefinition.shifts) {
				self.scheduleDefinition.shifts = [];
			}
			self.scheduleDefinition.shifts.push(shift);
		} else {
			//Existing shift is being added -> refreshing schedule
			if (!shift.slots) {
				self.groups.add(shift);
				self.siteGroup.nestedGroups.push(shift.id);
			} else {
				// There are slots in this shift, we should add them as well

				// let shiftGroup = {content: shift.content, id: shift.id};
				self.groups.add(shift);
				self.siteGroup.nestedGroups.push(shift.id);
				//
				// _.forEach(shift.slots, (slot) => {
				//
				// });
			}
		}
		self.tempTestShiftGroups[shift.id] = self.tempTestShiftGroups[shift.id] || [{}];
	}

	/**
	 * Handles adding 'items', that is, roaming, routing to a specific shiftGroup
	 * @param {[type]} shiftGroupID [description]
	 * @param {[type]} item         [description]
	 *
	 *
	 *                     MAIN SCHEDULE 8AM - 5PM
	 *                     DUMMY-SITE [self.siteGroup]
	 *    Custom-Template-1 [shiftGroup for siteGroup]  - 8AM-3PM [shiftItem] roaming
	 * 													  3PM-5PM [shiftItem] specific route
	 */
	addItemToShiftGroup(shiftGroupID, item) {
		let self = this;
		// TODO: Check item's .group with shiftID

		if (!item.group) {
			item.group = shiftGroupID;
		} else if (item.group !== shiftGroupID) {
				// TODO: Behavior
				console.error("This item already has another group. Changing it");
				item.group = shiftGroupID;
			}
		let itemType = item.content == "Roaming" ? "Roaming" : "Route";
		if(itemType == 'Route' && self.scheduleDefinition && self.scheduleDefinition.routes && self.scheduleDefinition.routes.length > 0) {
			let route = _.find(self.scheduleDefinition.routes,(o) => {
				return o._id == item.typeId;
			});
			if(route) {
				 if(!route.repeatable) {
					 item.className = 'disable-resize';
				 }
			}else{
				console.error('Route not found for route item :',item, self.scheduleDefinition);
			}
		}
		item.minimumDuration = item.duration || item.minimumDuration;
		self.data.add(item);

		let itemDuration = item.duration || self.minimumDurationSeconds || 60 * 20;

		let shiftIndex = _.findIndex(self.scheduleDefinition.shifts, (storedShift) => {
			return storedShift.id === shiftGroupID;
		});

		if (shiftIndex !== -1) {

			let itemIndex = _.findIndex(self.scheduleDefinition.shifts[shiftIndex].slots, (o) => {
				return o.id === item.id;
			});

			if (itemIndex === -1) {
				//The item we're adding is unique and not already in our scheduleDefinition
				self.scheduleDefinition.shifts[shiftIndex].slots.push({
					id: item.id,
					content: item.content, //content will be thrown out because it is not part of our model, but if a user chooses to refresh a schedule, we need content
					startTime: item.start,
					endTime: item.end,
					type: itemType,
					duration: itemDuration,
					minimumDuration: itemDuration,
					typeId : item.typeId
				});
			} else {
				//The item we're adding is already in our scheduleDefinition, we're probably refreshing schedule or reopening an edit
				//TODO - consider whether we should merge, or just leave.
			}
		}
	}

	/**
	 * Creates a new shift-template that will hold roaming or routing-items
	 * @return {[type]} [description]
	 */
	newShiftInTimeline() {
		let self = this;
		let shiftNum = self.scheduleDefinition.shifts.length + 1;
		let shiftGroup = {};
		shiftGroup.content = `Template ${shiftNum}`;
		shiftGroup.type = 'template';
		self.addShiftGroupToSiteGroup(shiftGroup);
		self.calculateSlots();


	}

	minusGroupAssets(id) {
		let self = this;
		if (self.tempTestShiftGroups[id]) {
			self.tempTestShiftGroups[id].pop();
		} else {
		}
	}

	addGroupAssets(id) {
		let self = this;
		if (self.tempTestShiftGroups[id]) {
			self.tempTestShiftGroups[id].push({
				asset: undefined,
				assetGroup: undefined,
				unit: undefined,
				assetname : undefined,
			});
			let total = _.reduce(self.tempTestShiftGroups, (count, item) => {
				return count + item.length;
			}, 0);
			if (total > self.scheduleDefinition.allocatedUnits) {
				self.tempTestShiftGroups[id].pop();
			}
		} else {
		}
	}
	/**
	 * Logic that executes when we click to DELETE a shiftGroup [ie, custom-template-1]
	 * @param  {[type]} shiftNum [description]
	 */
	deleteGroupButtonClicked(shiftNum) {
		let self = this;
		// let shiftNum;
		// if($event.target) {
		// 	shiftNum = $event.target.id;
		// }
		// else {
		// 	shiftNum = $event.currentTarget.id;
		// }


		let id = `${shiftNum}`;
		delete self.tempTestShiftGroups[id];

		self.onRemoveScheduleGroup(id);
		// self.groups.remove(id);
	}


	doLog() {
		this.getShifts();
		console.debug(this);
	}

	onUpdate(item, callback) {
		let self = this;
		if(self.definingSchedule && item.itemType && (item.itemType == 'Roaming' || item.itemType == 'Route')) {
			self.editTemplate(item.group);
		}
		callback(item);
	}

	onAdd(item, callback) {
		let self = this;
		let itemsInGroup = self.data.get({
			filter: (o) => {
				return o.group === item.group && o.start >= item.start;
			}
		});
		itemsInGroup.sort((a, b) => {
			return a.start - b.start;
		});
		let addHourTime = new self.moment(item.start).add(1, 'hour')
.valueOf();
		let nextStartTime = new self.moment(itemsInGroup[0].start).valueOf();
		let endTime = Math.min(addHourTime, nextStartTime);
		item.end = new self.moment(endTime);
		callback(item);
	}

	/**
	 * Whenever an item gets added by:
	 *  1) Drag and drop NOTE that we are receiving the start date with visjs magic
	 *     Also NOTE that when we refresh a schedule, items get added from our self.scheduleDefinition.shifts.
	 *     Any custom behavior that we might add here should be added there as well. Ideally we should write
	 *     a single function to call from both places
	 *  2) ...
	 */
	onAddScheduleItem(item, callback) {
		let self = this;

		//This item has been dropped by drag/drop
		if (item.dragged) {
			let totalTime = item.totalTime || self.minimumDurationSeconds || 60 * 5;
			let end = self.moment(item.start).add(totalTime, 'seconds');
			let itemType = item.colorIndex === 'roam' ? 'Roaming' : 'Route';
			let className = item.colorIndex === "roam" ? "roaming" : `routing${item.colorIndex}`;

			// if(item.colorIndex !== 'roam') {
			// 	className = `routing${item.colorIndex}`;
			// }
			// else {
			// 	className = "roaming";
			// }

			item.end = end;
			item.className = className;
			item.type = "range";
			item.itemType = itemType;
			item.minimumDuration = item.totalTime || totalTime;
			if (item.itemType == 'Route') {
				item.typeId = item.typeId;
			}
			item.style = self.getStyle(item);


			//slotDefinition is how we like to store this new item in our scheduleDefinition.shifts[].slots
			let slotDefinition = {
				id: item.id,
				content: item.content,
				startTime: item.start,
				endTime: item.end,
				duration: totalTime,
				minimumDuration : item.minimumDuration,
				type: item.type,
				itemType,
				typeId : item.typeId,
				style: item.style,
			};
			// self.scheduleDefinition.slots

			//Finding the index of the group (in our scheduleDefinition) where this item has been dropped
			let shiftGroupIndex = _.findIndex(self.scheduleDefinition.shifts, (storedShift) => {
				return storedShift.id === item.group;
			});

			if (!self.scheduleDefinition.shifts[shiftGroupIndex].slots) {
				self.scheduleDefinition.shifts[shiftGroupIndex].slots = [];
			}

			self.scheduleDefinition.shifts[shiftGroupIndex].slots.push(slotDefinition);

			delete item.dragged;
			delete item.totalTime;
			delete item.colorIndex;
		} else {
		}
		//Check if group has roaming and route in group and if same site.
		let itemsInGroup = self.data.get({
			filter: (o) => {
				return o.group === item.group;
			}
		});
		let allow = true;
		if(item.itemType == 'Roaming') {
			let routeItems = _.filter(itemsInGroup,['itemType','Route']);
			if(routeItems && routeItems.length > 0) {
				routeItems.forEach((routeItem) => {
					let route = _.find(self.scheduleDefinition.routes, (o) => {
						return o._id == routeItem.typeId;
					});
					if(route.site && self.scheduleDefinition.site && route.site._id == self.scheduleDefinition.site._id) {
					}else {
						allow = false;
					}
				});
			}

		}else if (item.itemType == 'Route') {
			let roamingItems = _.filter(itemsInGroup,['itemType','Roaming']);
			let route = _.find(self.scheduleDefinition.routes, (o) => {
				return o._id == item.typeId;
			});
			if(roamingItems && roamingItems.length > 0) {
				roamingItems.forEach((roamingItem) => {
					if(route && route.site && self.scheduleDefinition.site && route.site._id == self.scheduleDefinition.site._id) {
					}else {
						allow = false;
					}
				});
			}
			if(route) {
				if(!route.repeatable) {
					item.className = 'disable-resize';
				}
			}else{
				console.error('Route not found for route item :',item, self.scheduleDefinition);
			}

		}
		if(allow) {
			callback(item);
			self.calculateSlots();
		}else{
			self.toastr.warning('Item is not allowed in this template, sites do not match.','Item not allowed.');
			callback(null);
		}
	}

	onMoving(item, callback, containedFlag) {
		let self = this;
		let itemBefore = self.data.get(item.id);
		let currentItemDuration = self.moment(item.end).diff(self.moment(item.start), 'seconds');
		if (item.minimumDuration && currentItemDuration < item.minimumDuration) {
			if (item.start == itemBefore.start) {
				item.end = self.moment(item.start).add(item.minimumDuration, 'seconds');
			} else {
				item.start = self.moment(item.end).subtract(item.minimumDuration, 'seconds');
			}
			return callback(item);
		}
		let itemDurationMil = self.moment(itemBefore.end).diff(self.moment(itemBefore.start));
		let itemsInGroupBefore = self.data.get({
			filter: (o) => {
				return o.group === item.group && o.start <= item.start && o.end > item.start && o.id !== item.id && o.itemType == item.itemType;
			}
		});
		itemsInGroupBefore.sort((a, b) => {
			return b.start - a.start;
		});
		let itemsInGroupAfter = self.data.get({
			filter: (o) => {
				return o.group === item.group && o.start < item.end && o.end >= item.end && o.id !== item.id && o.itemType == item.itemType;
			}
		});
		itemsInGroupAfter.sort((a, b) => {
			return a.start - b.start;
		});
		let itemsInGroupContained = self.data.get({
			filter: (o) => {
				return o.group === item.group && o.start >= item.start && o.end <= item.end && o.id !== item.id && o.itemType == item.itemType;
			}
		});
		itemsInGroupContained.sort((a, b) => {
			return a.start - b.start;
		});
		if (containedFlag == 'movingRight' || containedFlag == 'movingLeft') {
			if (containedFlag == 'movingRight') {
				if (itemsInGroupAfter && itemsInGroupAfter.length > 0 && itemsInGroupAfter[0].end > item.start) {
					item.start = itemsInGroupAfter[0].end;
					item.end = self.moment(item.start).add(itemDurationMil, 'ms');
					self.onMoving(item, (nItem) => {
						item = nItem;
					}, 'movingRight');
				} else if (itemsInGroupContained && itemsInGroupContained.length > 0) {
					item.start = itemsInGroupContained[itemsInGroupContained.length - 1].end;
					item.end = self.moment(item.start).add(itemDurationMil, 'ms')
.toDate();
					self.onMoving(item, (nItem) => {
						item = nItem;
					}, 'movingRight');

				} else if (itemsInGroupBefore && itemsInGroupBefore.length > 0 && itemsInGroupBefore[0].end > item.start) {
					item.start = itemsInGroupBefore[0].end;
					item.end = self.moment(item.start).add(itemDurationMil, 'ms');
					self.onMoving(item, (nItem) => {
						item = nItem;
					}, 'movingRight');
				}
			} else if (itemsInGroupBefore && itemsInGroupBefore.length > 0 && itemsInGroupBefore[0].end > item.start) {
					item.end = itemsInGroupBefore[0].start;
					item.start = self.moment(item.end).subtract(itemDurationMil, 'ms')
.toDate();
					self.onMoving(item, (nItem) => {
						item = nItem;
					}, 'movingLeft');
				} else if (itemsInGroupContained && itemsInGroupContained.length > 0) {
					item.end = itemsInGroupContained[0].start;
					item.start = self.moment(item.end).subtract(itemDurationMil, 'ms')
.toDate();
					self.onMoving(item, (nItem) => {
						item = nItem;
					}, 'movingLeft');

				} else if (itemsInGroupAfter && itemsInGroupAfter.length > 0 && itemsInGroupAfter[0].end > item.start) {
					item.end = itemsInGroupAfter[0].start;
					item.start = self.moment(item.end).add(itemDurationMil, 'ms');
					self.onMoving(item, (nItem) => {
						item = nItem;
					}, 'movingLeft');
				}
		} else {
			if (itemsInGroupBefore && itemsInGroupBefore.length > 0 && itemsInGroupBefore[0].end > item.start) {
				item.start = itemsInGroupBefore[0].end;
				item.end = self.moment(item.start).add(itemDurationMil, 'ms');
				self.onMoving(item, (nItem) => {
					item = nItem;
				}, 'movingRight');
			}
			if (itemsInGroupAfter && itemsInGroupAfter.length > 0 && (itemsInGroupAfter[0].start < item.end || containedFlag == 'movingLeft')) {
				item.end = itemsInGroupAfter[0].start;
				item.start = self.moment(item.end).subtract(itemDurationMil, 'ms')
.toDate();
				self.onMoving(item, (nItem) => {
					item = nItem;
				}, 'movingLeft');
			}

		}
		if (itemsInGroupContained && itemsInGroupContained.length > 0) {
			let itemsDuration = self.moment(itemsInGroupContained[itemsInGroupContained.length - 1].end).diff(self.moment(itemsInGroupContained[0].start));
			let itemsMiddle = self.moment(itemsInGroupContained[0].start).add(itemsDuration / 2, 'ms');
			let myItemMiddle = new self.moment(item.start).add(itemDurationMil / 2, 'ms');
			if (itemsMiddle < myItemMiddle || containedFlag == 'smaller') {
				item.start = itemsInGroupContained[itemsInGroupContained.length - 1].end;
				item.end = self.moment(item.start).add(itemDurationMil, 'ms')
.toDate();
				self.onMoving(item, (nItem) => {
					item = nItem;
				}, 'smaller');
			} else if (itemsMiddle >= myItemMiddle || containedFlag == 'larger') {
				item.end = itemsInGroupContained[0].start;
				item.start = self.moment(item.end).subtract(itemDurationMil, 'ms')
.toDate();
				self.onMoving(item, (nItem) => {
					item = nItem;
				}, 'larger');
			}
		}
		callback(item);
	}

	/**
	 * Whenever we move an item for a schedule being created we should keep our self.scheduleDefinition.shifts updated
	 */
	onMovingScheduleItem(item, callback) {
		let self = this;
		self.onMoving(item, (nItem) => {
			item = nItem;
		});

		let shift = item.group; //shift1, shift2, etc
		let newStart = item.start;
		let newEnd = item.end;

		//Find the index of the shift, that is, shift1, shift2, etc.
		let index = _.findIndex(self.scheduleDefinition.shifts, (storedShift) => {
			return storedShift.id === shift;
		});

		//Find the index of the slot within the shift.
		let slotIndex = _.findIndex(self.scheduleDefinition.shifts[index].slots, (storedSlot) => {
			return storedSlot.id === item.id;
		});

		//We update the start and end values
		// let movingSlot = self.scheduleDefinition.shifts[index].slots[slotIndex];
		// movingSlot.startTime = new Date(newStart);
		// movingSlot.endTime = new Date(newEnd);

		self.$timeout(function() {
			self.calculateSlots();
		},500);
		callback(item);
	}

	onRemove(item, callback) {
		let self = this;
		callback(item);
	}

	onRemoveScheduleItem(item, callback) {
		let self = this;
		self.calculateSlots();
		callback(item);
	}

	/**
	 * Whenever we want to remove a Group
	 * NOTE that I'm struggling getting this to work
	 * by setting onRemoveGroup: self.onRemoveScheduleGroup.bind(self) in options
	 */
	onRemoveScheduleGroup(id, callback) {
		let self = this;

		let index = _.findIndex(self.scheduleDefinition.shifts, (storedShift) => {
			return storedShift.id === id;
		});

		self.scheduleDefinition.shifts.splice(index, 1);
		self.groups.remove(id);
		self.calculateSlots();


		// callback(item);
	}

	onDragRoute($event) {
		let self = this;
		let target = $event.currentTarget || $event.target;
		target = angular.element(target);

		let routeTotalTime = target.attr('total-time');
		let routeName = target.attr('name');
		let routeColorIndex = target.attr('color-index');
		let typeId = target.attr('route-id');


		$event.originalEvent.dataTransfer.effectAllowed = 'move';

		var item = {
			id: new Date(),
			content: routeName,
			totalTime: routeTotalTime,
			typeId,
			colorIndex: routeColorIndex,
			dragged: true,
		};

		$event.originalEvent.dataTransfer.setData("text", JSON.stringify(item));
	}

	editTemplate(templateId) {
		let self = this;
		// let total = _.reduce(self.tempTestShiftGroups, (count, item) => {
		// 	return count + item.length;
		// }, 0);
		// let maxAssets = self.scheduleDefinition.allocatedUnits - total;
		let modalInstance = self.$uibModal.open({
			component: 'slotdefinitionmodal',
			bindToController: true,
			// size: 'md',
			backdrop: 'static',
			resolve: {
				settings() {
					return {
						edit: true,
						templateId,
						// maxAssets: maxAssets,
						// account: {ref: self.$stateParams.accountID, name: self.getAccountName(self.$stateParams.accountID)},
						allTemplates: self.tempTestShiftGroups
					};
				}
			}
		});

		modalInstance.result.then(function(result) {
			self.tempTestShiftGroups[templateId] = result;
			// self.Contacts.post(result, {accountID: self.$stateParams.accountID}).then(function(){
			// 				self.tableParams.reload();
			// });
			self.calculateSlots();
		});
	}

	calculateSlots() {
		let self = this;
		self.scheduleDefinition.slots = [];
		let assetCount = 1;
		for (var templateId1 in self.tempTestShiftGroups) {
			if (self.tempTestShiftGroups.hasOwnProperty(templateId1)) {
				let tempTemplate = self.groups.get(templateId1);
				let slots = self.data.get({
					filter: (o) => {
						return o.group === templateId1;
					}
				});
				slots.forEach((slot) => {
					self.tempTestShiftGroups[templateId1].forEach((asset, index) => {
						let assetSlot = _.cloneDeep(asset);
						assetSlot.startTime = self.moment(slot.start).diff(self.moment(self.scheduleDefinition.start), 'seconds');
						assetSlot.duration = self.moment(slot.end).diff(self.moment(slot.start), 'seconds');
						assetSlot.type = slot.itemType;
						assetSlot.priority = tempTemplate.value + 1;
						assetSlot.typeId = slot.typeId;
						assetSlot.assetNum = assetCount + index;
						assetSlot.minimumDuration = slot.minimumDuration;
						self.scheduleDefinition.slots.push(assetSlot);
					});
				});
				if(slots.length > 0) {
					assetCount += self.tempTestShiftGroups[templateId1].length;
				}
			}
		}


	}

	getItemTemplate(item, element, data) {
		let self = this;
		if(item.itemType == 'Route' || item.metadata && item.metadata.type == 'Route') {
			let currentDuration = self.moment(item.end).diff(self.moment(item.start),'seconds');
			let minimumDuration;
			if(item.metadata && item.metadata.minimumDuration) {
				minimumDuration = item.metadata.minimumDuration;
			}else{
				minimumDuration = item.minimumDuration;
			}
			let times = currentDuration / minimumDuration;
			let html = `<div>${self.$sanitize(item.content)}  : repeats ${Math.floor(times)} times</div>`;
			if(self.tempTestShiftGroups && self.tempTestShiftGroups[item.group]) {
				html += `<div>${self.tempTestShiftGroups[item.group].length} assigned asset(s)</div>`;
			}
			return html;
		}else if(item.mainScheduleType == 'event') {

			let eventIcon = 'latearrival';
			switch (data.eventType) {
				case 'Panic':
				eventIcon = 'panic';
				break;
				case 'Call Me':

				eventIcon = 'callme';
				break;
				case 'Geo-fence In':
				eventIcon = 'geofencein';
				break;
				case 'Geo-fence Out':
				eventIcon = 'geofenceout';
				break;
				case 'Photo':
				eventIcon = 'photo';
				case 'Photo Request':
				eventIcon = 'photo';
				break;
				case 'Video':
				eventIcon = 'photo';
				break;
				case 'Late Arrival':
				eventIcon = 'latearrival';
				break;
				case 'Low Battery':
				eventIcon = 'lowbattery';
				break;
				case 'Login':
				eventIcon = 'login';
				break;
				default:
					eventIcon = data.eventType.replace(/ |-/g,'').toLowerCase();
			}
			let size = 4.7;
			if(self.eventDetails[data.eventType] && self.eventDetails[data.eventType].icon) {

				size = 5.4;
				let eventDetail = self.eventDetails[data,data.eventType];
				let html = `
				<div class="eventItem" style='height:${size}em;'>
				<svg viewBox="0 0 100 160" style="height:100%;">
				<path d="M 10 50 A 1 1 0 0 1 90 50 C 90 70 70 70 50 150 C 30 70 10 70 10 50" fill="${self.$sanitize(eventDetail.color)}"/>
				</svg>
				<div class="custom-marker-icon-center-div">
				<i class="${self.$sanitize(eventDetail.icon)} custom-marker-icon" style="font-size:90%;"></i>
				</div>
				</div>
				`;
				self.$timeout( () => {
					let el = angular.element(element);
					el.html(html);
				} )
				return '';
			}else{
				let html = `<img class="eventItem" style='height:${size}em;' src='assets/images/markers/${self.$sanitize(eventIcon)}.png'></img>`;
				self.$timeout( () => {
					let el = angular.element(element);
					el.html(html);
				} )
				return '';

			}
		}else if(item.itemType == 'Roaming') {
			let html = `<div>${self.$sanitize(item.content)}</div>`;
			if(self.tempTestShiftGroups && self.tempTestShiftGroups[item.group]) {
				html += `<div>${self.tempTestShiftGroups[item.group].length} assigned asset(s)</div>`;
			}
			return html;
		}else{
			return self.$sanitize(item.content);
		}
	}


	getShifts() {
		let self = this;
		let query = {};
		let window = self.getWindow();
		let end = self.moment(window.end).utc()
.valueOf();
		let start = self.moment(window.start).utc()
.valueOf();

		query.between = {start,end};
		query.filter = self.filter;
		query.populate = 'events';
		query.activeOnly = 'true';
		query.limit = 10;
        return this.Restangular.all('shifts').customGET('',query)
        .then((results)=>{
			self.totalShifts = results.total;
            return results.data;
        });
	}

	updateShift(shift) {
		let self = this;
		let shiftId = `${shift.schedule}:${self.moment(shift.start).unix()}`;
		if(self.groups.get(shiftId)) {
			let items = self.data.get();
			let events = _.differenceWith(shift.events, items, (a,b) => {
				return a._id == b._id && a.shift == b.shift;
			});
			shift.events = events;
			events = self.formatEventFromShift(shift);
			events.forEach((event) => {
				self.formatEvent(event);
			});
			if(events.length > 0) {
				self.$timeout(() => {
					self.timeline.redraw();
					self.addEventStyles();
				},0);
			}

		}

	}

	createTooltip(event) {
		let self = this;
		let html = `
		<div class='event-timeline-tooltip'>
		<div class='row'>
		Event Type : ${self.$filter('formatEventType')(event.eventType,event,self.eventDetails[event.eventType])}
		</div>
		`;
		if (event.unit) {
			html += `
			<div class='row'>
			Unit : ${event.unitname}
			</div>
			`;
		}
		if(event.shift) {

			html += `
			<div class='row'>
				Shift : ${event.shiftname}
			</div>
			`;
		}
		if(event.asset) {
			html += `
			<div class='row'>
			Asset : ${event.assetname || 'UNASSIGNED'}
			</div>
			`;
		}
		if(event.slottype) {
			html += `
			<div class='row'>
			Slot : ${event.slottype}
			</div>
			`;
		}
		if(event.waypointname) {
			html +=`
			<div class='row'>
			Waypoint : ${event.waypointname}
			</div>`;
		}
		if(event.metaData && event.metaData.geoZone && event.metaData.geoZone.waypointname) {
			html +=`
			<div class='row'>
			Waypoint : ${event.metaData.geoZone.waypointname}
			</div>`;
			html +=`
			<div class='row'>
			Route Collection : ${event.metaData.geoZone.routename}
			</div>`;
		}else if (event.site) {
			html +=`
			<div class='row'>
			Site : ${event.sitename}
			</div>`;
		}
		if(event.activeUsername) {
			html +=`
			<div class='row'>
			Assigned to : ${event.activeUsername}
			</div>`;
		}
		html += `
		<div class='row'>
		${event.active ? 'Open' : 'Closed'}
		</div>
		`;
		html += '</div>';
		return self.$sanitize(html);
	}

	addEventStyles() {
		let self = this;
		let eventElements = document.querySelectorAll('.eventItem');
		eventElements.forEach((el) => {
			let aEl = angular.element(el);
			if(aEl) {
				let content = aEl[0].parentElement;
				if(content) {
					let item = angular.element(content.parentElement);
					item.addClass('eventItem');
				}
			}
		});
	}

	deleteSchedule(id) {
		let self = this;
		// NOTE: This will remove all element with the tooltip class.  This is necessary because
		//       the tooltip for the remove icon doesn't remove itself.
		$('.tooltip').remove();
		self.scheduleService.updateSchedule({_id:id,active:false}).then((status) => {
		})
.catch((err) => {
			console.error(err);
		});
	}

	moveTimeline() {
		let self = this;
		let window = self.getWindow();
		self.timeline.setWindow(window.start,window.end);
	}

	getWindow() {
		let self = this;
		let start = new self.moment(self.viewDate).startOf(self.view == 'agendaWeek' ? 'week' : self.view);
		let end = new self.moment(self.viewDate).endOf(self.view == 'agendaWeek' ? 'week' : self.view);
		return {start,end};
	}


}

export default angular.module('secutraqApp.scheduling')
	.component('visScheduleTimeline', {
		template: require('./vis-schedule-timeline.html'),
		controller: VisScheduleTimelineComponent,
		controllerAs: "$ctrl",
		bindings: {
			events: '<',
			eventDetails: '=',
			view: '<',
			viewDate: '<',
			viewTitle: '<',
			gridsterOptions: '<',
			startTime: '<',
			tickLenght: '<', //Minutes
			tickAmount: '<',
			definingSchedule: '<',
			scheduleDefinition: '<',
			minimumDurationSeconds: '<'
		}
	});
