// import turf from 'turf';
// let turfCircle = require('@turf/circle');

export class NumbersService {

    /*@ngInject*/
    constructor(moment) {
        this.moment = moment;

    }

    /**
     * Distance should be in meters
     */
    handleDistanceConversion(distance, specifiedDecimals) {
        let self = this;
        let meters = distance;
        let decimals = specifiedDecimals ? specifiedDecimals : 2;
        let kilometers;
        let result = '';

        if(distance > 1000) {
            kilometers = distance / 1000;
            kilometers = kilometers.toFixed(decimals);
            return `${kilometers} km`;
        } else {
            meters = meters.toFixed(decimals);
            return `${meters} m`;
        }
    }

    /**
     * @param Duration in minutes
     */
    handleMinutesConversion(duration) {
        let self = this;
        let minutes = 0;
        let minutesUnit = "minutes";
        if(duration < 60) {
            minutes = duration;
            if(minutes === 1) {
                minutesUnit = "minute";
            }
            return `${minutes} ${minutesUnit}`;
        } else {
            let hours = Math.floor(duration/60);
            let hoursUnit = "hours";
            minutes = duration % 60;
            if(minutes === 1) {
                minutesUnit = "minute";
            }
            if(hours === 1) {
                hoursUnit = "hour";
            }

            return `${hours} ${hoursUnit} ${minutes} ${minutesUnit}`;
        }

    }

    generateNumber(min,max) {
        return Math.floor(Math.random()*(max-min+1)+min);
    }
}

export default angular.module('secutraqApp.dashboard')
.service('numbersService', NumbersService);
