export class ScheduledReportsService {
	/*@ngInject*/
	constructor($uibModal, moment, Restangular, socket, Auth, toastr) {
		let self = this;
		this.$uibModal = $uibModal;

		this.scheduledReports = [];
		this.moment = moment;
		this.Restangular = Restangular;
		this.listenerMap = new Map();
		this.socket = socket;
		this.Auth = Auth;
		this.cacheTimeout = undefined;
		this.lastAccount = undefined;
		this.lastUser = undefined;
		this.joined = false;
		this.toastr = toastr;

		// self.generateRoute();

	}

	onSocketEvent(event, item, array) {
		let self = this;
		self.scheduledReports = array;
		self.listenerMap.forEach((value, key) => {
			setTimeout(() => {
				value(event, item, array);
			}, 0);
		});
	}

	// TODO: Figure out specific room joining before new scheduledReports types are added.

	registerListener(context, cb, noGet, room) {
		let self = this;
		if (!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
		if (!self.lastUser) {
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		} else if (self.lastUser !== self.Auth.getCurrentUserSync()._id) {
			self.destroyService();
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		}
		let count = self.listenerMap.size;
		self.listenerMap.set(context, cb);
		// If listenermap was empty, (TODO and timer isn't running), join room, syncupdates
		if (!self.joined) {
			if (self.cacheTimeout) {
				clearTimeout(self.cacheTimeout);
				self.cacheTimeout = undefined;
			}
			if (noGet) {
				self.socket.syncUpdates('account', self.scheduledReports, self.onSocketEvent.bind(self), self);
				self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().ref}:accounts`);
				return null;
			} else {
				return self.getScheduledReports().then((scheduledReports) => {
					self.socket.syncUpdates('account', self.scheduledReports, self.onSocketEvent.bind(self), self);
					self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().ref}:accounts`);
					self.joined = true;
					return scheduledReports;
				});
			}
		} else {
			if (self.cacheTimeout) {
				clearTimeout(self.cacheTimeout);
				self.cacheTimeout = undefined;
			}
			if (noGet) {
				return null;
			} else {
				return self.getScheduledReports();
			}
		}
	}

	destroyService() {
		let self = this;
		if (self.joined) {
			self.socket.unsyncUpdates('account', self);
			self.socket.leaveRoom(`${self.lastAccount}:accounts`);
		}
		self.joined = false;
		self.scheduledReports = [];
		self.listenerMap.clear();
		clearTimeout(self.cacheTimeout);
		self.cacheTimeout = undefined;
	}

	unregisterListener(context, cb) {
		let self = this;
		if (!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
		if (!self.lastUser) {
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		} else if (self.lastUser !== self.Auth.getCurrentUserSync()._id) {
			self.destroyService();
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		}
		self.listenerMap.delete(context);
		if (self.listenerMap.size === 0) {
			self.cacheTimeout = setTimeout(self.destroyService.bind(self), 5000);
		}
	}

	unregisterAll() {
		let self = this;
		if (!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
		if (!self.lastUser) {
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		} else if (self.lastUser !== self.Auth.getCurrentUserSync()._id) {
			self.destroyService();
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		}
		self.listenerMap.clear();
		if (self.listenerMap.size === 0) {
			self.cacheTimeout = setTimeout(self.destroyService.bind(self), 5000);
		}
	}

	getScheduledReports() {
		let self = this;
		if (self.scheduledReports.length > 0) {
			return new Promise(function(resolve) {
				resolve(_.cloneDeep(self.scheduledReports));
			});
		}
        let lastAccount = _.cloneDeep(self.lastAccount);
        let lastUser = _.cloneDeep(self.lastUser);
		return this.Restangular.one('accounts', self.Auth.getCurrentAccountSync().ref).all('scheduledReports')
.getList()
			.then((scheduledReports) => {
				if(lastAccount == self.lastAccount && lastUser == self.lastUser) {
					self.scheduledReports = scheduledReports;
					return _.cloneDeep(scheduledReports);
				}else {
					return [];
				}
			});
	}

	getScheduledReportsWithQuery(query) {
		let self = this;
		return this.Restangular.one('accounts', self.Auth.getCurrentAccountSync().ref).all('scheduledReports')
.customGET('', query)
			.then((response) => {
				return _.cloneDeep(response);
			})
.catch((err) => {
				console.error(err);
			});
	}
	removeScheduledReport(scheduledReport) {
		let self = this;
		if (typeof scheduledReport.remove === 'function') {
			return scheduledReport.remove().then((response) => {
				// self.toastr.info('ScheduledReport removed.');
				return response;
			})
.catch((err) => {
				console.error(err);
				self.toastr.error('Site remove failed.');
				return err;
			});
		} else {
			return self.Restangular.one('accounts', self.Auth.getCurrentAccountSync().ref).one('scheduledReports', scheduledReport._id)
.remove()
.then((response) => {
				// self.toastr.info('Site removed.');
				return response;
			})
.catch((err) => {
				console.error(err);
				self.toastr.error('Scheduled reports remove failed.');
				return err;
			});
		}
	}
	saveScheduledReport(scheduledReport) {
		let self = this;
		return self.Restangular.one('accounts',self.Auth.getCurrentAccountSync().ref).post('scheduledReports',scheduledReport)
.then((response) => {
			self.toastr.info('Scheduled report saved.');
			return response;
		})
.catch((err) => {
			console.error(err);
			return err;
		});
	}
	updateScheduledReport(scheduledReport) {
		let self = this;

		return self.Restangular.one('accounts',self.Auth.getCurrentAccountSync().ref).one('scheduledReports',scheduledReport._id)
.patch(scheduledReport)
.then((response) => {

			self.toastr.info('Scheduled report saved.');
			return response;
		})
.catch((err) => {
			console.error(err);
			return err;
		});
	}

}

export default angular.module('secutraqApp.dashboard')
	.service('scheduledReportService', ScheduledReportsService);
