'use strict';
import angular from 'angular';
import uiRouter from 'angular-ui-router';
import Restangular from 'restangular';
import routes from './dashboard.routes';
import moment from 'moment';
// import angularResizable from 'angular-resizable/angular-resizable.min.js';

export default angular.module('secutraqApp.dashboard', [Restangular])
  .config(routes)
  .name;

require('./dashboard.component');

require('./dummyEvent/dummy-event.component');

require('./eventmodal.component');

require('./mainheadings/mainheadings.component');

require('./subtabs/subtabs.component');

require('./tabcontent/tabcontent.component');

require('./selected-event/selected-event.component');
require('./selected-event/event-photos/event-photos.component');

require('./mapview/mapview.component');

require('./factories/report.service');
require('./factories/scheduledReport.service');
require('./factories/task.service');
require('./factories/map.service');
require('./factories/generic-map.service');
require('./factories/event-carousel.service');
require('./factories/current-event.service');
require('./factories/event.service');
require('./factories/site.service');
require('./factories/unit.service');
require('./factories/photo.service');
require('./factories/route.service');
require('./factories/schedule.service');
require('./factories/shift.service');
require('./factories/numbers.service');
require('./factories/account.service');
require('./factories/wizard.service');
require('./factories/user.service');
require('./factories/webrtc.service');
require('./factories/group.service');
require('./factories/privGroup.service');
require('./factories/appVersion.service');
require('./factories/fieldReport.service');
require('./factories/fieldReportTemplate.service');
require('./factories/customEventType.service');
require('./factories/apiKey.service');
require('./factories/live-stream.service');
