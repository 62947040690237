'use strict';
angular.module('secutraqApp.dashboard')
  .directive('movable', ['$document', '$timeout',
    function($document, $timeout) {
        return {
      restrict: 'A',
      link: function(scope, elm, attrs) {
        var startX, startY, initialMouseX, initialMouseY;
        elm.css({position: 'absolute'});

        $timeout(() => {
            let mover = elm.find('.moveable-mover');

            if(mover) {
                mover.css("cursor", "move");
                mover.bind('mousedown', function($event) {
                  startX = elm.prop('offsetLeft');
                  startY = elm.prop('offsetTop');
                  initialMouseX = $event.clientX;
                  initialMouseY = $event.clientY;
                  $document.bind('mousemove', mousemove);
                  $document.bind('mouseup', mouseup);
                  return false;
                });
            }
            else {
                elm.bind('mousedown', function($event) {
                  startX = elm.prop('offsetLeft');
                  startY = elm.prop('offsetTop');
                  initialMouseX = $event.clientX;
                  initialMouseY = $event.clientY;
                  $document.bind('mousemove', mousemove);
                  $document.bind('mouseup', mouseup);
                  return false;
                });
            }
        });

        function mousemove($event) {
          if(!elm.hasClass('moving')) {
              elm.addClass('moving');
          }
          var dx = $event.clientX - initialMouseX;
          var dy = $event.clientY - initialMouseY;
          let container = $('#main');
          let minTop = +container.css('margin-top').replace('px','') *-1;
          let minLeft = +container.css('margin-left').replace('px','') *-1;
          let top = Math.max(startY + dy,minTop);
          let left = Math.max(startX + dx,minLeft);
          elm.css({
            top:  top+ 'px',
            left: left + 'px'
          });
          return false;
        }

        function mouseup() {
          $document.unbind('mousemove', mousemove);
          $document.unbind('mouseup', mouseup);

          if(elm.hasClass('moving')) {
              $timeout(() => {
                  elm.removeClass('moving');
              },50);
          }
        }
      }
    };
  }]);
