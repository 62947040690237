'use strict';
angular.module('secutraqApp.directives')
.directive('numberSpinner', ['$document','$timeout',
function($document, $timeout) {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function(scope, el, attrs, ngModel) {
            let input = el.find('.spinner-input');

            let increment = el.find('.spinner-incrementer');
            increment.on('click', ($event) => {
                let input = getInput();
                let value = input + 1;
                ngModel.$setViewValue(value);
            });

            let decrement = el.find('.spinner-decrementer');
            decrement.on('click', ($event) => {
                let input = getInput();
                let value = input - 1;
                ngModel.$setViewValue(value);
            });

            let getInput = function() {
                let value = +input[0].value;
                return value;
            }

            let setInput = function(value) {
                input[0].value = value;
            }
        }
    };
}]);
