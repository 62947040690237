
export class AppVersionModalController {
	Restangular;
	$uibModalInstance;
	modalInstance;

	/*@ngInject*/
	constructor( $stateParams, Auth, Restangular, $scope, $timeout, moment, toastr, appVersionService, $uibModal) {
		this.$stateParams = $stateParams;
		this.Auth = Auth;
		this.Restangular = Restangular;
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.moment = moment;
		this.toastr = toastr;
		this.appVersionService = appVersionService;
	}

	$onInit() {
		let self = this;
		self.$uibModalInstance = this.modalInstance;
		self.edit = self.resolve.settings.edit;
		if(self.edit){
			self.appVersion = self.resolve.settings.appVersion;
		}else{
			self.appVersion = {};
		}
		self.uploading = false;
		self.file = undefined;
		self.allTags = ['Release','Release Candidate','Debug','Test','Dev'];
		self.currentAccount = self.Auth.getCurrentAccountSync().ref;
        self.dzCallbacks = {
            'addedfile': function(file) {
				self.file = file;
            },
			'removedfile': function(file){
				self.file = undefined;
			},
            'maxfilesexceeded': function(file) {
                self.dzMethods.removeFile(file);
                self.toastr.warning('Only one file allowed', {
                    preventOpenDuplicates: true
                });
            },
            'sending': function(file, xhr, formData) {
                self.toastr.info('Uploading, Please Wait..', {
                    preventOpenDuplicates: true
                });
				formData.append('title',self.appVersion.title);
				if(self.appVersion.description){
					formData.append('description',self.appVersion.description);
				}
				formData.append('version',self.appVersion.version);
				formData.append('tag',self.appVersion.tag);
            },
            'complete': function(res) {
				if(res.status == 'error'){
					//Format error msg
					console.error(res.xhr.response);
					let errorMsg = self.formatErrorMsg(res.xhr.response);
					//Remove File
					self.dzMethods.removeAllFiles(true);
					self.uploading = false;
					self.toastr.error(errorMsg, {
						preventOpenDuplicates: true
					});
				}else{
					self.uploading = false;
					self.toastr.success('Succesfully uploaded app.', {
						preventOpenDuplicates: true
					});
					self.$uibModalInstance.close({});

				}
            }
        };
        self.dzOptions = {
            url: '/api/appVersions',
			maxFilesize: 5000,
			timeout:0, //Disable upload timeout
            maxFiles: 1,
            headers: {
                "X-JS-ACCOUNT": self.currentAccount
            },
            autoProcessQueue: false,
            addRemoveLinks: true,
            dictDefaultMessage: 'Click to add file',
            dictRemoveFile: 'Remove file',
            dictResponseError: 'Could not upload this file'
        };
        self.dzMethods = {};
	}

    //$postLink() {
        //let self = this;
		//if(!self.edit){
			//var dz = self.dzMethods.getDropzone();
		//}
    //}

	doLog() {
		let self = this;
		console.debug(this);
	}

	// Setup all the models for display
	// Modal accept: send resulting object back to promise in parent controller
	ok() {
		let self = this;
		if(self.edit){
			self.appVersionService.updateAppVersion(self.appVersion).then( (result) => {
				self.toastr.info('App updated.');
				self.$uibModalInstance.close({});
			} ).catch( (err) => {
				self.toastr.error('Could not update app.');

			} );
		}else{
			self.uploading = true;
			self.dzMethods.processQueue();
		}
	};


	// Modal cancelled: either cancel pressed or clicked off screen
	cancel() {
		this.modalInstance.dismiss('Cancel pressed');
	};

	formatErrorMsg(msg){
		let self = this;
		return 'Upload failed, please try again.'
	}

}

export default angular.module('secutraqApp.settings')
	.component('appversionmodal', {
		template: require('./app-version.modal.html'),
		bindings: {
			resolve: '<',
			close: '&',
			dismiss: '&',
			modalInstance: '<'
		},
		controller: AppVersionModalController,
		controllerAs: '$ctrl'
	})
	.directive('version', function() {
		return {
			require: 'ngModel',
			link: function(scope, elm, attrs, ctrl) {
				ctrl.$validators.version = function(modelValue, viewValue) {
					if (ctrl.$isEmpty(modelValue)) {
						// consider empty models to be valid
						return false;
					}

					if (/^(\d+\.)(\d+\.)(\d+\.)(\d+)$/.test(viewValue)) {
						// it is valid
						return true;
					}

					// it is invalid
					return false;
				};
			}
		};
	})
	.name;
