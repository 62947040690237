'use strict';
// @flow
export class ConfigureSiteComponent {
	/*@ngInject*/
	constructor(Auth, NgTableParams, $uibModal, $scope, $ngConfirm, $stateParams, siteService, $timeout, routeService, scheduleService, shiftService, moment) {
		this.Auth = Auth;
		this.NgTableParams = NgTableParams;
		this.$uibModal = $uibModal;
		this.$scope = $scope;
		this.$ngConfirm = $ngConfirm;
		this.$stateParams = $stateParams;
		this.siteService = siteService;
		this.$timeout = $timeout;
		this.routeService = routeService;
		this.scheduleService = scheduleService;
		this.shiftService = shiftService;
		this.moment = moment;
	}

	$onInit() {
		let self = this;
		let activeSiteID = '';

		/**
		 * Giving this Object to SiteDetailComponent via bindings.
		 * @type {Object}
		 */
		self.toggledCoordinates = {};

		/**
		 * <site-detail> has an ng-if on this. [So that all maps doesn't initialize, but rather that it initializes as we opens the accordion]
		 * This is also the object we can refer to if we wish to programmatically alter the openedSiteAccordions
		 * TODO - Look into what happens when we edit an existing site. I think we should close and reopen its accordion to reInit the map
		 * @type {Object}
		 */
		self.openedSiteAccordions = {};

		self.filterSitename = '';

		self.hasApiPriv = self.Auth.hasRoleSync('apiKeys');

		/**
		 * If siteID is set in stateParams.  Find and set the specified site as selected and open the correct acordion
		 */
		if (self.$stateParams && self.$stateParams.siteID) {
			let index = _.findIndex(self.sites, (site) => {
				return site._id.toString() === self.$stateParams.siteID.toString();
			});
			if (index >= 0) {
				self.openedSiteAccordions[self.sites[index]._id] = true;
				self.onSelectedSite(self.sites[index]);
			}
		}

		self.$scope.$on('siteIDInAddressBar', ($event, data) => {
			let siteID = data.siteID;
			self.$timeout(() => {
				//adding a timeout because it seemed to make things a bit smoother
				self.openedSiteAccordions[siteID] = true;
			}, 500);

		});

	}

	onSelectedSite(selectedSite) {
		let self = this;
		self.activeSiteID = selectedSite._id;
		self.setSelectedSite({
			selectedSite
		});
	}

	/**
	 * We confirm whether the user wants to remove  the site, and calls self.removeSite, a function
	 * that we bind to that comes from SiteComponent
	 */
	onRemoveSite($event, site) {
		let self = this;
		if ($event) {
			$event.stopPropagation();
			$event.preventDefault();
		}
		let promisses = [];
		let routes = self.routeService.getRoutesWithQuery({query:site._id,field:'site'});
		let schedules = self.scheduleService.getScheduleWithQuery({query:site._id,field:'site', activeOnly: true});
		let shifts = self.shiftService.getShiftWithQuery({activeOnly : 'true', query:site._id,field:'site', between:{start:self.moment().valueOf(), end:self.moment().add(1,'hour')
.valueOf()}});
		promisses.push(routes);
		promisses.push(schedules);
		promisses.push(shifts);

		Promise.all(promisses).then((results) => {
			let disabled = false;
			let content = '';
			if(results) {
				if(results[0] && results[0].total > 0) {
					content += `<br>This site is linked to ${results[0].total} route${results[0].total > 1 ? 's' : ''}. Unlink route${results[0].total > 1 ? 's' : ''} before removing.`;
					disabled = true;
				}
				if(results[1] && results[1].total > 0) {
					content += `<br>This site is linked to ${results[1].total} schedule${results[1].total > 1 ? 's' : ''}. Unlink schedule${results[1].total > 1 ? 's' : ''} before removing.`;
					disabled = true;
				}
				if(results[2] && results[2].total > 0) {
					disabled = true;
					content = `<br>An active shift is linked to this site.  The shift needs to finish before the site can be removed.`;
				}
			}
			if(!disabled) {
				content += `Clicking <b>OK</b> would remove this site`;
			}
			self.$ngConfirm({
				title: `Remove <b>${site.name}</b>?`,
				theme: 'supervan',
				content,
				escapeKey: true,
				backgroundDismiss: true,
				buttons: {
					// long hand button definition
					ok: {
						text: "Ok",
						btnClass: `btn-primary ${disabled ? 'hidden' : ''}`,
						keys: ['enter'], // will trigger when enter is pressed
						disabled,
						action(scope) {
							self.removeSite({
								site
							});
						}
					},
					// short hand button definition
					Close(scope) {

					}
				},
			});
		});
	}

	newSite() {
		let self = this;
		self.openNewSiteModal();
	}

	/**
	 * The Globe button in HTML that we've put an ng-click on.
	 * This sends an event to all detailcomponents, who catch it, compares
	 * their siteID to this one and handles the labels if their ID's correspond
	 */
	onToggleCoordinates($event, site) {
		let self = this;
		self.$scope.$broadcast('toggleCoordinates', {
			siteID: site._id
		});
	}

	editSite($event, site) {
		let self = this;
		if ($event) {
			$event.stopPropagation();
			$event.preventDefault();
		}
		self.openEditSiteModal(site);
	}

	/**
	 * Responsible for creating a new site, but consider using a service for opening this modal like we did with routes
	 * @return {[type]} [description]
	 */
	openNewSiteModal() {
		let self = this;
		let modalInstance = self.$uibModal.open({
			component: 'newSite',
			backdrop: 'static',
			size: 'xlg',
			windowTopClass: 'newSiteModal',
			keyboard: false
		});

		modalInstance.result.then((result) => {
			// self.$scope.$emit('siteArrived', result);
				//let site = result.siteDefinition;
				//delete site._id;
				//if (site.contacts && site.contacts.length > 0) {
					//site.contacts.forEach((contact) => {
						//contact = contact._id;
					//});
				//} else {
					//delete site.contacts;
				//}
				//let locations = [];
				//site.locs.forEach((subSite) => {
					//subSite.loc.forEach((location) => {
						//locations.push(L.latLng(location));
					//});
				//});
				//let siteBound = L.latLngBounds(locations);
				//let centerPoint = L.latLng(siteBound.getCenter());
				//let maxDist = 0;
				//locations.forEach((loc) => {
					//let dist = centerPoint.distanceTo(loc);
					//if (dist > maxDist) {
						//maxDist = dist;
					//}
				//});
                //try {
                    //site.centerPoint = {type:"Point",coordinates:[centerPoint.lng,centerPoint.lat]};
                    //site.radius = maxDist;

                //} catch (e) {
                        //console.error(e);
                //}

			//self.siteService.saveNewSite(site);
		});
	}

	openEditSiteModal(site) {
		let self = this;
		let siteCopy = _.cloneDeep(site);
		let modalInstance = self.$uibModal.open({
			component: 'newSite',
			backdrop: 'static',
			size: 'xlg',
			windowTopClass: 'newSiteModal',
			keyboard: false,
			resolve: {
				site: siteCopy
			}

		});

		modalInstance.result.then((result) => {
			// self.$scope.$emit('siteArrived', result);
			//try {

				//let site = result.siteDefinition;
				//if (site.contacts && site.contacts.length > 0) {
					//site.contacts.forEach((contact) => {
						//contact = contact._id;
					//});
				//} else {
					//delete site.contacts;
				//}
                //// let locations = [];
				//// site.locs.forEach((subSite) => {
				//// 	subSite.loc.forEach((location) => {
				//// 		locations.push(L.latLng(location));
				//// 	});
				//// });
				//// let siteBound = L.latLngBounds(locations);
				//// let centerPoint = L.latLng(siteBound.getCenter());
				//// let maxDist = 0;
				//// locations.forEach((loc) => {
				//// 	let dist = centerPoint.distanceTo(loc);
				//// 	if (dist > maxDist) {
				//// 		maxDist = dist;
				//// 	}
				//// });
                //// site.centerPoint = {type:'Point',coordinates:[centerPoint.lng,centerPoint.lat]};
                //// site.radius = maxDist;
				//self.siteService.updateSite(site);
			//} catch (e) {
			//}
		});
	}

	requestSiteSnapshots($event,site) {
		let self = this;
		if ($event) {
			$event.stopPropagation();
			$event.preventDefault();
		}
		self.siteService.requestSiteSnapshots(site);
	}

	doLog() {
		console.debug(this);
	}
}


export default angular.module('secutraqApp.sites')
	.component('listSites', {
		template: require('./site-list.html'),
		controller: ConfigureSiteComponent,
		controllerAs: '$ctrl',
		bindings: {
			sites: '=',
			setSelectedSite: '&',
			removeSite: '&'
		}
	})
	.name;
