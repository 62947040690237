import moment from 'moment';

export class EventsComponent {
	/*@ngInject*/
	constructor(
		$scope,
		$timeout,
		$state,
		$stateParams,
		Restangular,
		unitService,
		siteService,
		eventService,
		Auth,
		NgTableParams,
		photoService,
		$uibModal,
		currentEventService,
		liveStreamService,
		toastr
	) {
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.$state = $state;
		this.eventService = eventService;
		this.unitService = unitService;
		this.siteService = siteService;
		this.$stateParams = $stateParams;
		this.photoService = photoService;
		this.Restangular = Restangular;
		this.NgTableParams = NgTableParams;
		this.currentEventService = currentEventService;
		this.liveStreamService = liveStreamService;
		this.Auth = Auth;
		this.toastr = toastr;
		this.$uibModal = $uibModal;
		this.limit = 10;
		this.total = 0;
		this.live = true;
		this.groupEvents = false;
		this.initialQuery = {
			active: 'Open Events',
		};
		this.query = _.cloneDeep(this.initialQuery);
		this.prevClickedEventId;

		this.photoPaging = {
			total: 0,
			page: 1,
			size: 10
		};
	}

	$onInit() {
		let self = this;
		self.events = [];
		self.eventDetails = {};
		self.viewingEventsList = {};
		self.viewingEvents = [];
		self.selectedEventsList = {};
		self.selectedEvents = [];
		self.eventSummary = {};

		self.eventCols = [{
			title: '',
			field: 'check',
			show: true
		}, {
			title: 'Event Type',
			field: 'eventType',
			sortable: 'eventType',
			show: true
		}, {
			title: 'Event Count',
			field: 'count',
			sortable: 'count',
			show: false,
			groupedColumn: true
		}, {
			title: 'Status',
			field: 'active',
			show: true
		}, {
			title: 'Created At',
			field: 'createdAt',
			sortable: 'createdAt',
			show: true
		}, {
			title: 'Last Event',
			field: 'lastEvent',
			sortable: 'lastEvent',
			show: false,
			groupedColumn: true
		}, {
			title: 'Unit',
			field: 'unitname',
			show: true
		}, {
			title: 'Site',
			field: 'sitename',
			show: true
		}, {
			title: 'Active User',
			field: 'activeUsername',
			show: true
		}, {
			title: 'Actions',
			field: 'actions',
			show: true
		}];

		self.debouncedSummaryChartUpdate = _.throttle(async () => {
			await self.updateSummaryChartData();
			//self.updateSummaryChart();
		}, 3000);

		//Set initial layout sizing
		var element = angular.element(document.querySelector('#main'));
		var mainHeight = element[0].offsetHeight;
		var mainWidth = element[0].offsetWidth;
		self.eventsWidth = mainWidth * 0.6;
		self.eventsHeight = mainHeight * 0.8;
		self.$scope.$on('angular-resizable.resizeEnd', function (event, args) {
			if (args.width) self.eventsWidth = args.width;
			if (args.height) self.eventsHeight = args.height;
		});

		self.eventService
			.registerListener(
				self,
				(event, item, array) => {
					return self.onReceiptOfEvent(event, item);
				},
				{ limit: 0 }
			)
			.catch((err) => console.error(err));

		self.eventService.getEventDetails
			.then((eventDetails) => {
				self.eventDetails = eventDetails;
				self.eventTypes = [];
				_.forEach(self.eventDetails, (details, key) => {
					if (
						!details.subEvents &&
						!details.hidden &&
						key !== 'online'
					) {
						self.eventTypes.push(key);
					}
				});

				self.updateSummaryChartData()
					.then(() => self.initializeSummaryChart())
					.catch((err) => console.error(err));

				self.hasFaceRecallPriv = false;
				if (self.Auth.hasPrivilegeSync('faceRecall')) {
					self.hasFaceRecallPriv = true;
				}

				return null;
			})
			.then(() => {
				self.tableParams = new self.NgTableParams(
					{
						page: 1, // start with first page
						count: 10, // count per page
						sorting: {
							createdAt: 'desc', // initial sorting
						},
					},
					{
						total: 0,
						getData(params) {
							let order;
							if (params && params.sorting) {
								let page = params.page();
								order = params.sorting();
								let fullQuery = {
									skip: (page - 1) * params.count(),
									// accountID: self.$stateParams.accountID,
									limit: params.count(),
									by: Object.keys(order)[0],
									order: order[Object.keys(order)[0]],
								};
								self.live = true;
								switch (self.query.active) {
								case 'Open Events': {
									fullQuery.field = 'active';
									fullQuery.query = true;
									break;
								}
								case 'Closed Events': {
									self.live = false;
									fullQuery.field = 'active';
									fullQuery.query = false;
									break;
								}
								case 'All Events': {
									self.live = false;
									break;
								}
								default: {
									fullQuery.field = 'active';
									fullQuery.query = true;
									break;
								}
								}
								if (self.query.units?.length) {
									fullQuery.units = self.query.units;
									self.live = false;
								}
								if (self.query.sites?.length) {
									fullQuery.sites = self.query.sites;
									self.live = false;
								}
								if (self.query.eventTypes?.length) {
									fullQuery.eventTypes =
										self.query.eventTypes;
									self.live = false;
								}
								if (page > 1) {
									self.live = false;
								}
								let eventPromise;
								if (self.groupEvents) {
									//Get aggregated event data
									eventPromise = self.eventService
										.getEventAggregationWithQuery(fullQuery);
								} else {
									eventPromise = self.eventService
										.getEventsWithQuery(fullQuery);
								}

								return eventPromise
									.then(function (data) {
										self.events = data.data;
										self.total = data.total;
										params.total(data.total);
										return self.events;
										// return self.formatData(units);
									})
									.catch((err) => {
										console.error(
											'Error caught when getting data for events: ',
											err.data.err
										);
									});
							}
						},
					}
				);

				this.tableParams.reload();
			})
			.catch((err) => {
				console.error('Error getting units', err);
			});
	}

	$onDestroy() {
		this.eventService.unregisterListener(this);
		if (this.summaryChart) {
			this.summaryChart.destroy();
		}
	}

	onReceiptOfEvent(socketEvent, event) {
		let self = this;
		//Check if event details has event (this should not be possible, could happen if a unit creates a report event for a field report that does not exist)
		if (!self.eventDetails[event.eventType]) {
			console.error('Event has no event details document : ', event);
			return null;
		}

		self.debouncedSummaryChartUpdate();
		// if(event.eventType == 'Late Arrival' || event.eventType == "Early Abscondment"){
		//     return;
		// }
		//
		if (
			event &&
			(event.eventType == 'Recognition' ||
				event.eventType == 'LPR Recognition') &&
			!self.hasFaceRecallPriv
		) {
			return null;
		}
		if (self.groupEvents) {
			self.handleEventForGroupedView(event);
		} else {
			self.handleEventForNormalView(event);
		}
	}

	handleEventForNormalView(event) {
		let self = this;
		//This is changes to enable dynamic grouping of events.  The rest is to support all other functionality
		let findEvent = _.find(self.events, ['_id', event._id]);
		if (findEvent) {
			if (event.active) {
				findEvent = _.mergeWith(
					findEvent,
					event,
					(objValue, srcValue, key) => {
						if (_.isArray(objValue)) {
							return srcValue;
						}
					}
				);
			} else {
				_.remove(self.events, ['_id', event._id]);
				//This is for the animation (Not Working)
				//findEvent.leave = true;
			}
		} else if (event.active) {
			if (self.live) {
				let add = true;
				if (
					self.events.length &&
					self.events[0].createdAt > event.createdAt
				) {
					add = false;
				}
				if (add) {
					self.events.unshift(event);
					if (self.events.length > self.tableParams.count())
						self.events.pop();
					self.total += 1;
					self.tableParams.total(self.total);
				}
			}
		}
	}

	handleEventForGroupedView(event) {
		let self = this;
		//Find active group of events
		let activeGroup = _.find(self.events, (e) => {
			return e.eventType == event.eventType && e.unit == event.unit && e.site == event.site && e.active === true;
		});

		if (event.active) {
			if (self.live && !activeGroup) {
				let add = true;
				if (
					self.events.length &&
					self.events[0].createdAt > event.createdAt
				) {
					add = false;
				}
				if (add) {
					event.count = 1;
					event.lastEvent = event.createdAt;
					event.eventIds = [event._id];
					event.query = {
						unit: event.unit,
						site: event.site,
						active: event.active,
						eventType: event.eventType
					};
					self.events.unshift(event);
					if (self.events.length > self.tableParams.count())
						self.events.pop();
					self.total += 1;
					self.tableParams.total(self.total);
				}
				activeGroup = event;
			}

			let inGroup = activeGroup.eventIds.includes(event._id);
			//Check if tracked in group
			if (!inGroup) {
				//Add to tracking list and increase counter

				activeGroup.eventIds.push(event._id);
				activeGroup.count++;
			}
			//Ensure photos are in list
			event.photos.forEach((photoId) => {
				if (!activeGroup.photos.includes(photoId)) {
					activeGroup.photos.unshift(photoId);
				}
			});
			if(self.viewingEventsList[activeGroup._id]){
				self.updatePopulatedViewingEvent(event);
			}
		} else {
			//The event could have been closed
			//Check the active group and remove
			if (activeGroup.eventIds.includes(event._id)) {
				activeGroup.count--;
				_.remove(activeGroup.eventIds, event._id);
				_.remove(activeGroup.photos, (id) => event.photos.includes(id));
			}

			//Check the closed group and add
			let closedGroup = _.find(self.events, (e) => {
				return e.eventType == event.eventType && e.unit == event.unit && e.site == event.site && e.active === false;
			});
			if (closedGroup) {
				//Add to closed group if not already included
				if (!closedGroup.eventIds.includes(event._id)) {
					closedGroup.count++;

					closedGroup.eventIds.push(event._id);
					closedGroup.count++;
					//Ensure photos are in list
					event.photos.forEach((photoId) => {
						if (!closedGroup.photos.includes(photoId)) {
							closedGroup.photos.unshift(photoId);
						}
					});
				}
			}
			if(self.viewingEventsList[closedGroup._id]){
				self.updatePopulatedViewingEvent(event);
			}
		}

		//Check if group in viewing list and if event in loaded photos view and update items

	}

	updatePopulatedViewingEvent(event) {
		let self = this;
		let currentEvent = _.find(self.populatedEvents, { _id: event._id });
		if (currentEvent) {
			_.mergeWith(currentEvent, event,
				(objValue, srcValue, key) => {
					if (_.isArray(objValue)) {
						return srcValue;
					}
				}
			);
		}else{
			//Check if the photo view is live and add to start
			if(self.photoPaging.page == 1){
				self.populatedEvents.unshift(event);
				self.photoPaging.total++;
				if(self.populatedEvents.length > self.photoPaging.size){
					self.populatedEvents.pop();
				}
			}
		}

	}

	refreshUnits(filter) {
		let self = this;
		self.unitService
			.getUnitsWithQuery({
				limit: 20,
				filter: filter,
			})
			.then((units) => {
				self.availableUnits = units;
			});
	}

	refreshSites(filter) {
		let self = this;
		self.siteService
			.getSitesWithQuery({
				limit: 20,
				filter: filter,
			})
			.then((sites) => {
				self.availableSites = sites;
			});
	}

	onEventClick($event, event) {
		let self = this;
		if ($event.ctrlKey) {
			//Add event
			if (!self.viewingEventsList[event._id]) {
				self.viewEvent(event);
			} else {
				self.unViewEvent(event);
			}
		} else if ($event.shiftKey && self.prevClickedEventId) {
			//Add all between
			let currentIndex = _.findIndex(self.events, ['_id', event._id]);
			let prevIndex = _.findIndex(self.events, [
				'_id',
				self.prevClickedEventId,
			]);
			let dir = 1;
			if (currentIndex < prevIndex) {
				dir = -1;
			}
			for (
				let index = prevIndex + dir * 1;
				index <= self.events.length && index !== currentIndex + dir * 1;
				index += dir * 1
			) {
				let tempEvent = self.events[index];
				if (tempEvent && !self.viewingEventsList[tempEvent._id]) {
					self.viewEvent(tempEvent);
				}
			}
		} else {
			_.forEach(self.viewingEventsList, (val, eventId) => {
				if (event._id !== eventId) self.unViewEvent({ _id: eventId });
			});
			if (!self.viewingEventsList[event._id]) {
				self.viewEvent(event);
			}
		}
		self.prevClickedEventId = event._id;
		$event.stopPropagation();
		if (self.groupEvents) {
			self.loadEventsWithPhotos();
		}
	}

	viewEvent(event) {
		let self = this;
		self.viewingEventsList[event._id] = true;
		self.viewingEvents.push(event);
	}

	unViewEvent(event) {
		let self = this;
		delete self.viewingEventsList[event._id];
		_.remove(self.viewingEvents, ['_id', event._id]);
	}

	updateLiveMonitoring() {
		let self = this;
		if (self.live) {
			this.query = _.cloneDeep(this.initialQuery);
		}
		self.tableParams.page(1);
		self.tableParams.reload();
	}

	updateGroupEvents() {
		let self = this;
		self.eventCols.forEach((col) => {
			if (col.groupedColumn) {
				col.show = self.groupEvents;
			}
		});

		self.unselectAll();
		self.unViewAll();
		self.tableParams.page(1);
		self.tableParams.reload();

		if (self.groupEvents) {
			self.loadEventsWithPhotos();
		}
	}

	onEventSelect($event, event) {
		let self = this;
		if (self.selectedEventsList[event._id]) {
			self.selectEvent(event);
		} else {
			self.unselectEvent(event);
		}
	}

	selectEvent(event) {
		let self = this;
		if (!self.selectedEventsList[event._id])
			self.selectedEventsList[event._id] = true;
		if (!_.find(self.selectedEvents, ['_id', event._id]))
			//Only pick the absolute neccesary info to be able to find SOP and action event
			self.selectedEvents.push(
				_.pick(event, ['_id', 'eventType', 'site', 'active', 'count', 'query'])
			);
	}

	unselectEvent(event) {
		let self = this;
		delete self.selectedEventsList[event._id];
		_.remove(self.selectedEvents, ['_id', event._id]);
	}

	selectAll() {
		let self = this;
		self.events.forEach((event) => {
			self.selectEvent(event);
		});
	}

	unselectAll() {
		let self = this;
		while (self.selectedEvents.length > 0) {
			self.unselectEvent(self.selectedEvents[0]);
		}
	}

	unViewAll() {
		let self = this;
		_.forEach(self.viewingEventsList, (val, eventId) => {
			self.unViewEvent({ _id: eventId });
		});
	}

	unselectPage() {
		let self = this;
		self.events.forEach((event) => {
			self.unselectEvent(event);
		});
	}

	async viewEventDetails(event) {
		let self = this;

		await self.$uibModal.open({
			component: 'eventViewModal',
			backdrop: 'static',
			size: 'lg',
			keyboard: true,
			resolve: {
				event() {
					return event;
				},
				eventDetails() {
					return self.eventDetails;
				},
			},
		});
	}

	updateLiveEvents() {
		let self = this;
		self.live = true;
		self.query = _.cloneDeep(self.initialQuery);
		self.tableParams.page(1);
		self.tableParams.sorting({ createdAt: 'desc' });
		self.tableParams.reload();
	}

	async actionMultipleEvents(events) {
		let self = this;
		let actionEvents = _.cloneDeep(events);
		self.eventService
			.actionEvents(actionEvents)
			.then(() => {
				actionEvents.forEach((event) => {
					self.unselectEvent(event);
				});
				self.tableParams.page(1);
				self.tableParams.reload();
			})
			.catch((err) => console.error(err));
	}

	initializeSummaryChart() {
		let self = this;
		self.summaryChart = c3.generate({
			bindTo: '#chart',
			data: {
				json: [self.eventSummary],
				keys: {
					value: Object.keys(self.eventSummary),
				},
				type: 'pie',
				color: function (color, d) {
					let details = self.eventDetails[d.id || d];
					return details ? details.color : color;
				},
			},
			pie: {
				label: {
					format: function (value, ratio, id) {
						return value;
					},
				},
			},
			tooltip: {
				format: {
					value: function (value, ratio, id, index) {
						return value;
					},
				},
			},
			legend: {
				show: false,
			},
		});
	}

	async updateSummaryChartData() {
		let self = this;
		let data = await self.eventService.getEventSummaryWithQuery({
			field: 'active',
			query: true,
		});
		self.eventSummary = {};
		self.totalOpenEvents = 0;
		data.forEach((eventCount) => {
			self.eventSummary[eventCount._id] = eventCount.count;
			self.totalOpenEvents += eventCount.count;
		});
		_.forEach(self.eventDetails, (eventDetail, key) => {
			if (eventDetail.subEvents && eventDetail.subEvents.length > 0) {
				self.eventSummary[key] = 0;
				eventDetail.subEvents.forEach((subEvent) => {
					self.eventSummary[key] += self.eventSummary[subEvent] || 0;
				});
			}
		});
	}

	updateSummaryChart() {
		let self = this;
		let keys = Object.keys(self.eventSummary);
		self.summaryChart.unload({
			done: () => {
				self.summaryChart.load({
					json: [self.eventSummary],
					keys: { value: keys },
				});
				self.summaryChart.flush();
			},
		});
	}

	requestSnapshot(unitId) {
		let self = this;
		self.unitService.requestSnapshot(unitId)
			.then((result) => {
				if (result) {
					self.toastr.info('Snapshot requested.');
				} else {
					self.toastr.warning('Snapshot request already pending.');
				}
			})
			.catch((err) => {
				self.toastr.error('Snapshot request failed.');
				console.error(err);
			});
	}

	requestSnapshotsForSite(siteId) {
		let self = this;
		self.siteService.requestSiteSnapshots({ _id: siteId }, self.$scope);
	}

	startLiveStream(unit) {
		let self = this;
		self.liveStreamService.addStream(unit)
			.catch((err) => {
				console.error(err);
			});

	}

	loadActionUnit(event) {
		let self = this;
		self.loadingUnitForActions = true;
		self.actionUnit = undefined;
		self.canRequestLiveView = false;
		self.canRequestSnapshot = false;
		self.canRequestSiteSnapshot = false;
		self.unitService.getUnitByID(event.unit)
			.then((unit) => {
				self.actionUnit = unit;
				if (self.actionUnit.type === 'DMS' || self.actionUnit.initiateStreamCallback) {
					self.canRequestLiveView = true;
				}
				if (self.actionUnit.type == 'API') {
					if (self.actionUnit.apiType === 'Secuvue Camera' && self.actionUnit.requestSnapshotCallback) {
						self.canRequestSnapshot = true;
					}
				} else {
					self.canRequestSnapshot = true;
				}

				if (event.site) {
					self.canRequestSiteSnapshot = true;
				}
			})
			.catch((err) => console.error(err))
			.finally(() => self.loadingUnitForActions = false);
	}

	loadEventsWithPhotos() {
		let self = this;
		if(!self.viewingEvents.length){
			self.populatedEvents = [];
			self.photoPaging.total = 0;
			return;
		}

		self.loadingPhotoEvents = true;
		let page = self.photoPaging.page;
		let count = self.photoPaging.size;

		let queries = _.map(self.viewingEvents, 'query');
		let fullQuery = {
			skip: (page - 1) * count,
			// accountID: self.$stateParams.accountID,
			limit: count,
			by: 'createdAt',
			order: 'desc',
			queries
		};
		this.eventService.getEventAggregationPopulatedWithQuery(fullQuery)
			.then((res) => {
				self.populatedEvents = res.data;
				self.photoPaging.total = res.total;
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => self.loadingPhotoEvents = false);
	}

	doLog() {
		console.debug(this);
	}
}
export default angular.module('secutraqApp.events').component('events', {
	template: require('./events.component.html'),
	controller: EventsComponent,
	controllerAs: '$ctrl',
})
	.filter('reduceCount', function () {
		return function (events) {
			return _.reduce(events, (count, event) => count + event.count, 0);
		};
	});
