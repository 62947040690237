export class EventModalComponent {


    /*@ngInject*/
    constructor(Auth, $uibModal, $http, $timeout) {
        this.Auth = Auth;
        this.$http = $http;
        this.$uibModal = $uibModal;
		this.$timeout = $timeout;
    }

    $onInit() {
        let self = this;
        this.modalEvent = this.resolve.settings.modalEvent;
        this.context = this.resolve.settings.context;


    }


    // flattenEvents(event) {
    //     let self = this;
    //     self.events.push(event);
    //
	// 	if(event.reference) {
	// 		self.$http.get('/api/snapshots/' + event.reference.snapId).then(response => {
	// 			self.events[0].referencesnap = response.data;
	// 		});
	// 	}
    //
    //     if(!event.childEvent) {
    //         return;
    //     }
    //     for (let i = 0; i < event.childEvent.length; i++) {
	// 		let idx = self.events.push(event.childEvent[i]) - 1;
	// 		if(event.childEvent[i].reference) {
	// 			self.$http.get('/api/snapshots/' + self.events[idx].reference.snapId).then(response => {
	// 				self.events[idx].referencesnap = response.data;
	// 			});
	// 		}
    //     }
    // }


    close() {
        let result = "stuff";
        this.modalInstance.close(result);
    }


    // TODO: After finishing image-modal, remember to set-up the display for each INDIVIDUAL site.
    // That is, know what site we're dealing with and displaying that site's events.
    // Also remember that video's might also come into play.
    // openImageModal(image, context) {
    //     let self = this;
    //
	// 	this.imageModalInstance = this.$uibModal.open({
	// 		animation: true,
	// 		backdrop: true,
	// 		template: require('../main/modal.html'),
	// 		controller: require('../main/main.component').SnapshotModalController,
	// 		controllerAs: '$modalctrl',
	// 		size: 'lg',
	// 		resolve: {
	// 			snapshot: function() {
	// 				return image;
	// 			}
	// 		}
	// 	}).result.then(function(){
	// 	}, function() {
	// 	});
    // };


    // actionEvent() {
    //     let self = this;
    //     let actionedEvent = self.event;
    //     let eventStack = self.context.eventStack[self.eventType];
    //
    //     let index = 0;
    //     let found = false;
    //     while (!found) {
    //         if(eventStack[index]._id = actionedEvent._id) {
    //             found = true;
    //         }
    //         else {
    //             index ++;
    //         }
    //     }
    //     eventStack.splice(index,1);
    //
	// 	self.events.forEach((event) => {
	// 		let newEvent = _.mergeWith(event, {sop: self.checkedSOP, active: false, description: self.description}, function(objValue, srcValue) {
	// 			if (_.isArray(objValue)) {
	// 				return srcValue;
	// 			}
	// 		});
	// 		self.$http.patch('/api/events/'+event._id, newEvent).then(response => {
	// 			self.close();
	// 		},(err => {if(err.status !== 401 && err.status !== 403) console.error(err)}));
	// 	});
    // }

}
export default angular.module('secutraqApp.dashboard')
.component('eventmodal', {
    template: require('./eventmodal.html'),
    bindings: {
        resolve: '<',
        modalInstance: '<'
    },
    controller: EventModalComponent,
    controllerAs: '$ctrl'
})
.name;
