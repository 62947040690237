import filterController from './filter.controller';
import fabricJS from 'fabric';
import PhotoModalController from './modal.controller';

export class ImageCollectionComponent {
    $http;
    $uibModal;
    $document;
    $rootScope;
    //initialLoad;
    Auth;
    $state;
    $scope
    snapshots = [];
    moment;
    snapshotDataSource = {};
    snapshotAdapter = {
        adapter: {
            remain: true
        }
    };
    startDT;
    lastFilter = {};
    endDT;
    paused;
    pauseBuffer;
    preBuffer;
    onlyFaces = false;
    onlyRecog = false;
    filter = [];
    self;
    $stateParams;

    /*@ngInject*/
    constructor($http, moment, $document, photoService, $scope, $rootScope, $uibModal, $state, Auth, $stateParams, toastr) {
        //angular.module('infinite-scroll').value('THROTTLE_MILLISECONDS', 500);
        this.$http = $http;
        this.moment = moment;
        this.$uibModal = $uibModal;
        //this.initialLoad = false;
        this.$rootScope = $rootScope;
        this.photoService = photoService;
        this.$document = $document;
        this.paused = false;
        this.$state = $state;
        this.snapPlaceholder = [];
        this.pauseBuffer = [];
        this.$scope = $scope;
        this.Auth = Auth;
        this.toastr = toastr;
        let self = this;
        $scope.$on('$destroy', function () {
            self.photoService.unregisterListener(self);
        });

        this.custClass = 'hidden-panel';
        this.startDT = undefined;
        this.preBuffer = [];
        this.lastFilter.startDT = undefined;
        this.endDT = undefined;
        this.lastFilter.endDT = undefined;

        this.$stateParams = $stateParams;
        this.currentRoom = '';
        //this.availableSites = [];
    }

    $onInit() {

        var self = this;
        self.snapshotDataSource.get = self.getSnapshots.bind(self);
        self.photoService.registerListener(self, (event, item, array) => {
            //
            let inPlace = false;
            let thistime = self.moment(+item.ts).utc();
            let unitIndex;
            if (self.filter.units) {
                unitIndex = _.findIndex(self.filter.units, obj => {
                    return obj === item.unit;
                });
            }
            let assetIndex;
            if (self.filter.assets) {
                assetIndex = _.findIndex(self.filter.assets, obj => {
                    return obj === item.asset;
                });
            }

            let siteIndex;
            if (self.filter.sites) {
                siteIndex = _.findIndex(self.filter.sites, obj => {
                    return obj === item.site;
                });
            }

            let tempEnd = self.endDT;
            let tempStart = self.startDT;

            if (!self.endDT) {
                tempEnd = self.moment().utc()
                    .add(1, 'y');
            }
            if (!self.startDT) {
                tempStart = self.moment(0).utc();
            }


            if (
                self.onlyFaces && !item.faces
                || self.onlyRecog && (!self.snapRecognitions || self.snapRecognitions.length <= 0)
                || thistime.isBefore(tempStart)
                || thistime.isAfter(tempEnd)
                || self.filter.sites && self.filter.sites.length > 0 && siteIndex === -1
                || self.filter.assets && self.filter.assets.length > 0 && assetIndex === -1
                || self.filter.units && self.filter.units.length > 0 && unitIndex === -1
            ) {
                return;
            }

            self.snapshotAdapter.applyUpdates(function (obj, scope, element) {
                if (obj._id.toString() === item._id.toString()) {
                    inPlace = true;
                    obj.snapRecognitions = item.snapRecognitions;
                    obj.faceRecognitions = item.faceRecognitions;
                    obj.lprResults = item.lprResults;
                }
            });

            if (event === "created" && !self.paused && !inPlace) {
                self.snapshotAdapter.prepend([item]);
                if (self.snapPlaceholder.length > 1) {
                    self.snapPlaceholder[0].first = false;
                }
                item.first = true;
                self.snapPlaceholder.unshift(item);
            } else if (!inPlace && self.paused && event === "created") {
                self.pauseBuffer.push(item);
            }
        }, false);
    }

    getSnapshots(descriptor, success) {
        // Initial load
        //
        if (!descriptor.append && !descriptor.prepend) {
            let self = this;
            this.$http.get('/api/photos/filtered', {
                params: {
                    pagesize: descriptor.count,
                    before: self.endDT
                        //? +(self.moment(self.endDT).utc())
                        ? +self.moment(self.endDT)
                        : undefined,
                    after: self.startDT
                        //? +self.moment(self.startDT).utc()
                        ? +self.moment(self.startDT)
                        : undefined,
                    sites: self.filter.sites,
                    units: self.filter.units,
                    assets: self.filter.assets,
                    onlyFaces: self.onlyFaces,
                    onlyRecog: self.onlyRecog
                }
            }).then(response => {
                self.snapPlaceholder = _.unionBy(self.snapPlaceholder, response.data, "_id");
                success(response.data);
            }, err => {
                if (err.status !== 401 && err.status !== 403) {
                    console.error(err);
                }
            });
        } else if (descriptor.append) {
            let self = this;
            //let timestamp = +self.moment(+descriptor.append.ts).utc();
            let timestamp = new Date(descriptor.append.ts).getTime();
            this.$http.get('/api/photos/filtered', {
                params: {
                    pagesize: descriptor.count,
                    after: self.startDT
                        ? +self.moment(self.startDT).utc()
                        : undefined,
                    before: timestamp,
                    sites: self.filter.sites,
                    units: self.filter.units,
                    assets: self.filter.assets,
                    onlyFaces: self.onlyFaces,
                    onlyRecog: self.onlyRecog,
                    sort: {
                        ts: -1
                    }
                }
            }).then(response => {
                self.snapPlaceholder = _.unionBy(self.snapPlaceholder, response.data, "_id");
                success(response.data);
            }, err => {
                if (err.status !== 401 && err.status !== 403) {
                    console.error(err);
                }
            });
            // Add to end of descriptor
            //} else if(false) {
        } else {
            let self = this;
            let timestamp = new Date(descriptor.prepend.ts).getTime();
            this.$http.get('/api/photos/filtered', {
                params: {
                    pagesize: descriptor.count,
                    after: timestamp,
                    before: self.endDT
                        ? +self.moment(self.endDT).utc()
                        : undefined,
                    sites: self.filter.sites,
                    units: self.filter.units,
                    assets: self.filter.assets,
                    onlyFaces: self.onlyFaces,
                    onlyRecog: self.onlyRecog,
                    sort: {
                        ts: 1
                    }
                }
            }).then(response => {
                self.snapPlaceholder = _.unionBy(response.data, self.snapPlaceholder, "_id");
                success(response.data);
            }, err => {
                if (err.status !== 401 && err.status !== 403) {
                    console.error(err);
                }
            });
            // Add to start of descriptor
        }
    }

    sortByTimestamp() {
        angular.copy(_.orderBy(this.snapshots, ['ts'], ['desc']), this.snapshots);
    }

    refreshFilter() {
        this.snapshotAdapter.reload();
    }

    formatDate(date) {
        let self = this;
        return self.moment(date).local()
            .format('ll LTS');
    }

    openSnapshot(snapshot) {
        let self = this;
        self.$uibModal.open({
            animation: true,
            backdrop: 'static',
            keyboard: true,
            template: require('./modal.html'),
            controller: PhotoModalController,
            controllerAs: '$ctrl',
            size: 'lg',
            resolve: {
                snapshot() {
                    return snapshot;
                },
                snapPlaceholder() {
                    return self.snapPlaceholder;
                }
            }
        }).result.then(function (result) {
            if (result && result.newSnap) {
                self.openSnapshot(result.newSnap);
            } else if (result && result.next) {
                //self.openNext();
            } else if (result && result.previous) {
                //self.openPrevious();
            }
        }, function () { });
    }

    clearFilter() {
        let self = this;
        self.lastFilter = { sites: undefined, units: undefined, assets: undefined, startDate: undefined, startTime: undefined, endDate: undefined, endTime: undefined, onlyRecog: undefined, onlyFaces: undefined };
        self.applyFilter(self.lastFilter);
    }

    pause() {
        let self = this;
        self.paused = true;
    }

    resume() {
        let self = this;
        for (let i = 0; i < self.pauseBuffer.length; i++) {
            self.snapshotAdapter.prepend([self.pauseBuffer[i]]);
            self.snapPlaceholder.unshift(self.pauseBuffer[i]);
        }
        self.pauseBuffer = [];
        self.paused = false;
    }


    applyFilter(filter) {
        let self = this;
        if (filter.startDate && filter.startTime) {
            self.startDT = self.moment(filter.startDate).hours(filter.startTime.getHours())
                .minutes(filter.startTime.getMinutes());
        } else if (filter.startDate) {
            self.startDT = self.moment(filter.startDate);
        } else if (filter.startTime) {
            self.startDT = undefined;
        } else {
            self.startDT = undefined;
        }

        if (filter.endDate && filter.endTime) {
            self.endDT = self.moment(filter.endDate).hours(filter.endTime.getHours())
                .minutes(filter.endTime.getMinutes());
        } else if (filter.endDate) {
            self.endDT = self.moment(filter.endDate);
        } else if (filter.endTime) {
            self.endDT = undefined;
        } else {
            self.endDT = undefined;
        }

        if (filter.sites && filter.sites.length > 0) {
            self.filter.sites = filter.sites;
        } else {
            self.filter.sites = [];
        }
        if (filter.units && filter.units.length > 0) {
            self.filter.units = filter.units;
        } else {
            self.filter.units = [];
        }
        if (filter.assets && filter.assets.length > 0) {
            self.filter.assets = filter.assets;
        } else {
            self.filter.assets = [];
        }

        self.onlyFaces = filter.onlyFaces;
        self.onlyRecog = filter.onlyRecog;
        self.refreshFilter();
    }

    doLog() {
        console.debug(this);
    }

    filterOpen() {
        let self = this;
        this.$uibModal.open({
            template: require('./filter.html'),
            placement: 'left',
            backdrop: 'static',
            keyboard: true,
            size: 'md',
            controller: filterController,
            controllerAs: '$ctrl',
            resolve: {
                lastFilter() {
                    return self.lastFilter;
                }
            }
        }).result.then(function (result) {
            self.lastFilter = result;
            self.applyFilter(result);
        });
    }

    hasMotion(snapshot) {
        return _.find(snapshot.detections, (detection) => {
            return detection.detectionType === 'Motion';
        });
    }

    hasFrontalFace(snapshot) {
        let result = false;
        if (snapshot.metaData?.secuvueData?.detections) {
            result = _.find(snapshot.metaData.secuvueData.detections, (detection) => {
                return detection.detectionType === 'FrontalFace';
            });
        }

        if (snapshot && snapshot.snapRecognitions && snapshot.snapRecognitions.length) result = true;
        return result;
    }
}

export default angular.module('secutraqApp.photoAnalytics')
    .component('imageCollection', {
        template: require('./imageCollection.html'),
        controller: ImageCollectionComponent,
        controllerAs: '$ctrl'
    })
    .filter('titleCase', function () {
        return function (input) {
            input = input || '';
            return input.replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        };
    })
    .filter('formatReasons', function (moment) {
        return function (secuvueData) {
            if(!secuvueData) return null;
            let arr = [];

            if (secuvueData && secuvueData.buddyEvents && secuvueData.buddyEvents.length > 0) {
                let buddyLineDescript = "";
                let buddyMotionDescript = "";
                secuvueData.buddyEvents.forEach(bud => {
                    if (bud.type == "Motion") {
                        buddyMotionDescript = `${buddyMotionDescript} ${bud.zoneAlias},`;
                    } else if (bud.type == "LineCross") {
                        if (bud.AToB) {
                            buddyLineDescript = `${buddyLineDescript} ${bud.lineAlias} A->B (${bud.zoneAlias}),`;
                        } else {
                            buddyLineDescript = `${buddyLineDescript} ${bud.lineAlias} B->A (${bud.zoneAlias}),`;
                        }
                    }
                });
                if (buddyMotionDescript.length > 0) {
                    buddyMotionDescript = buddyMotionDescript.slice(0, -1);
                    arr.push(buddyMotionDescript);
                }
                if (buddyLineDescript.length > 0) {
                    buddyLineDescript = buddyLineDescript.slice(0, -1);
                    arr.push(buddyLineDescript);
                }
            }
            if (secuvueData && secuvueData.accessControlEvents && secuvueData.accessControlEvents.length > 0) {
                secuvueData.accessControlEvents.forEach(accessEvent => {
                    let method = '';
                    _.forEach(accessEvent.entryMethod, (val, key) => {
                        if (val) {
                            method += ` ${key.toUpperCase()} `;
                        }
                    });
                    arr.push(`${accessEvent.entry ? "Entry" : "Exit"} ${accessEvent.allow ? "allowed" : "denied"} for ${accessEvent.username || "Unknown"}${accessEvent.deviceAlias ? ` on ${accessEvent.deviceAlias}` : ""} at ${moment.utc(accessEvent.ts).format()} using ${method}`);
                });
            }
            if (secuvueData.reason) {
                arr.push(secuvueData.reason.replace(/\B([A-Z])/g, " $1"));
            }


            arr = _.uniq(arr);
            return arr;
        };
    })
    .name;
