import { Chart, DoughnutController, ArcElement, Tooltip } from 'chart.js';
Chart.register(DoughnutController, ArcElement, Tooltip);

export class MapService {
	/*@ngInject*/
	constructor($compile, $timeout, $interval, eventCarouselService, moment, eventService, unitService, numbersService, $sanitize) {
		let self = this;
		this.$compile = $compile;
		this.$sanitize = $sanitize;
		this.$interval = $interval;
		this.moment = moment;
		this.eventService = eventService;
		this.unitService = unitService;
		this.numbersService = numbersService;
		// this.map;

		/**
		 * This Object is probably the single most important Object that there is to this
		 * component.
		 * key -- unitID
		 * value -- Another Object that stores all events pertaining to this unitID
		 * NOTE that self.markers[unitID] would also contain
		 * self.markers[unitID].online which is the first 'online' event.
		 * @type {Object}
		 */
		this.markers = {unassigned:{}};

		/**
		 * key -- eventID
		 * value -- Array of blurred layers on popupopen
		 * @see openMarkerPopup()
		 * @type {Object}
		 */
		this.blurredOnPopupOpen = {};

		/**
		 * An Object that contains all the events currently filtered.
		 * @see filterMarkers()
		 * @type {Object}
		 */
		this.filteredMarkers = {};
		this.openedMarkerPopupID = {
			eventID: '',
			unitID: ''
		};
		this.droppedMarkers = {};
		this.drawControl;
		this.$timeout = $timeout;
		this.eventCarouselService = eventCarouselService;

		this.showToolbar = false;
		this.clickedAccordionEvent = 'Tester'; //used by TabContentComponent to highlight the event in accordion
		this.metaData = {layers: {},openEventsGroupBy:'unitEventType'}; //we use this object to put metaData in. We're using an object so we can reference it

		/////////////////////////////////////////////////////
		//             this.metaData                       //
		// activeTab       --> SubtabsComponent            //
		// eventsAccordion --> TabContentComponent         //
		// inboxAccordion  --> TabContentComponent         //
		// eventsHistory   --> TabContentComponent         //
		// selectedUnit    --> TabContentComponent         //
		// layers          --> MapViewComponent            //


		this.markerPanic = L.icon({
			iconUrl: 'assets/images/markers/panic.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5, 56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});
		this.markerCallMe = L.icon({
			iconUrl: 'assets/images/markers/callme.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});
		this.markerGeoFenceIn = L.icon({
			iconUrl: 'assets/images/markers/geofencein.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});
		this.markerGeoFenceOut = L.icon({
			iconUrl: 'assets/images/markers/geofenceout.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});
		this.markerPhoto = L.icon({
			iconUrl: 'assets/images/markers/photo.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});
		this.markerPhotoRequest = L.icon({
			iconUrl: 'assets/images/markers/photorequest.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});
		this.markerVideo = L.icon({
			iconUrl: 'assets/images/markers/video.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});
		this.markerOnLine = L.icon({
			iconUrl: 'assets/images/markers/online.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});
		this.markerOnLineCamera = L.icon({
			iconUrl: 'assets/images/markers/onlinecamera.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});
		this.markerOnLineSUnit = L.icon({
			iconUrl: 'assets/images/markers/onlinesunit.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});
		this.markerBatteryLow = L.icon({
			iconUrl: 'assets/images/markers/lowbattery.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});
		this.markerNoComms = L.icon({
			iconUrl: 'assets/images/markers/nocomms.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});
		this.markerLateArrival = L.icon({
			iconUrl: 'assets/images/markers/latearrival.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});
		this.markerOffline = L.icon({
			iconUrl: 'assets/images/markers/offline.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});
		this.markerLogin = L.icon({
			iconUrl: 'assets/images/markers/login.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});
		this.earlyAbscondment = L.icon({
			iconUrl: 'assets/images/markers/earlyabscondment.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});
		this.assetNotOnSite = L.icon({
			iconUrl: 'assets/images/markers/assetnotonsite.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});
		this.assetArrivedOnSite = L.icon({
			iconUrl: 'assets/images/markers/assetonsite.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});
		this.roamingComplete = L.icon({
			iconUrl: 'assets/images/markers/roamingcomplete.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});
		this.roamingIncomplete = L.icon({
			iconUrl: 'assets/images/markers/roamingincomplete.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});
		this.waypointLate = L.icon({
			iconUrl: 'assets/images/markers/waypointlate.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});
		this.routeIncomplete = L.icon({
			iconUrl: 'assets/images/markers/routeincomplete.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});
		this.routeComplete = L.icon({
			iconUrl: 'assets/images/markers/routecomplete.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});
		this.routeNotStarted = L.icon({
			iconUrl: 'assets/images/markers/routenotstarted.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});
		this.routeStartedLate = L.icon({
			iconUrl: 'assets/images/markers/routestartedlate.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});
		this.routeStarted = L.icon({
			iconUrl: 'assets/images/markers/routestarted.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});
		this.routeMissed = L.icon({
			iconUrl: 'assets/images/markers/routemissed.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});
		this.noArrival = L.icon({
			iconUrl: 'assets/images/markers/noarrival.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});

		this.recognition = L.icon({
			iconUrl: 'assets/images/markers/recognition.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});
		this.maintenance = L.icon({
			iconUrl: 'assets/images/markers/cameradown.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});
		this.lprrecognition = L.icon({
			iconUrl: 'assets/images/markers/lprrecognition.png',
			iconSize: [33,56], // size of the icon
			iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
		});
		this.markerIcons = {
			Panic: this.markerPanic,
			'Call Me': this.markerCallMe,
			'Geo-fence Out': this.markerGeoFenceOut,
			'Geo-fence In': this.markerGeoFenceIn,
			Photo: this.markerPhoto,
			'Photo - Line Trip': this.markerPhoto,
			'Photo - Motion': this.markerPhoto,
			'Photo - External Trigger': this.markerPhoto,
			'Photo - Scheduled': this.markerPhoto,
			'Photo Request': this.markerPhotoRequest,
			Video: this.markerVideo,
			'Video Request': this.markerVideo,
			online: this.markerOnLine,
			onlineCamera: this.markerOnLineCamera,
			onlineSUnit: this.markerOnLineSUnit,
			'Low Battery': this.markerBatteryLow,
			'Camera Down': this.maintenance,
			'HDD Fail': this.maintenance,
			'No-comms': this.markerNoComms,
			latearrival: this.markerLateArrival,
			"Late Arrival": this.markerLateArrival,
			offline: this.markerOffline,
			Login : this.markerLogin,
			'No Arrival':this.noArrival,
			'Early Abscondment': this.earlyAbscondment,
			'Asset Not On Site' : this.assetNotOnSite,
			'Asset On Site' : this.assetArrivedOnSite,
			'Roaming Complete':this.roamingComplete,
			'Roaming Incomplete':this.roamingIncomplete,
			'Waypoint Late':this.waypointLate,
			'Route Incomplete':this.routeIncomplete ,
			'Route Complete':this.routeComplete,
			'Route Not Started':this.routeNotStarted,
			'Route Started Late':this.routeStartedLate,
			'Route Started':this.routeStarted,
			'Route Missed':this.routeMissed,
			Recognition:this.recognition,
			'LPR Recognition':this.lprrecognition,
			'LPR VOI':this.lprrecognition,
		};


		this.defaultMarker = this.markerLateArrival;

		/**
		 * This is the collection of all events' layers.
		 */
		this.eventsLayer = new L.layerGroup();

		/**
		 * This is the  collection of all online units layers.
		 */
		this.onlineUnitsLayer = new L.layerGroup();

		/**
		 * This is the collection of all offline units
		 */
		this.offlineUnitsLayer = new L.layerGroup();

		this.filteredMarkersLayer = new L.layerGroup();

		this.hasMap().then((value) => {
			this.map.addLayer(this.eventsLayer);
			this.map.addLayer(this.onlineUnitsLayer);
			this.map.addLayer(this.filteredMarkersLayer);
			this.map.addLayer(this.offlineUnitsLayer);
		});
		this.$timeout( () => {
			this.initEventIcons();
		});
	}

	initEventIcons() {
		let self = this;
		self.eventService.getEventDetails.then( (eventDetails) => {
			self.eventDetails = eventDetails;
			_.forEach(self.eventDetails, (eventDetail) => {
				self.setMarker(eventDetail);
			} );
		} );
	}


	updateMarker(eventDetail) {
		let self = this;
		self.setMarker(eventDetail);
		self.eventsLayer.eachLayer( (layer) => {
			if(layer.eventType == eventDetail.eventType) {
				layer.eachLayer( (marker) => {

					marker.setIcon(icon);
				} );
			}
		});
	}

	setMarker(eventDetail) {
		let self = this;
		let icon;
		if(eventDetail.icon) {
			icon = L.divIcon({
				html:`
						<svg viewBox="0 0 100 160">
						<path d="M 10 50 A 1 1 0 0 1 90 50 C 90 70 70 70 50 150 C 30 70 10 70 10 50" fill="${eventDetail.color}"/>
						</svg>
						<div class="custom-marker-icon-center-div">
						<i class="${eventDetail.icon} custom-marker-icon fa-lg"></i>
						</div>
						`,
				className: 'custom-div-icon',
				iconSize: [40,60], // size of the icon
				iconAnchor: [20, 60], // point of the icon which will correspond to marker's location
				popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
			});
		}else{
			icon = L.icon({
				iconUrl: `assets/images/markers/${eventDetail.eventType.replace(/ |-/g,'').toLowerCase()}.png`,
				iconSize: [33,56], // size of the icon
				iconAnchor: [16.5,56], // point of the icon which will correspond to marker's location
				popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
			});
		}
		self.markerIcons[eventDetail.eventType] = icon;
	}

	/**
	 * Determines whether the side-nav is shown or hidden-menu
	 * @return A boolean, true or false
	 */
	getSideNavStatus() {
		let self = this;
		let menuSideNav = document.getElementsByClassName("hidden-menu");
		if (menuSideNav.length === 0) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Gets the width of the aside
	 * @return The width in pixels.
	 */
	getSideNavWidth() {
		let self = this;
		let aside = document.getElementById("left-panel");
		if (aside) {
			return aside.clientWidth;
		} else {
			console.error("Cannot find the aside-panel in the DOM");
		}
	}

	/**
	 * We set the map from MapViewComponent and make all further adjustments
	 * on the map via this factory.
	 */
	setMap(receivedMap) {
		let self = this;
		self.map = receivedMap;

		self.eventsLayer = new L.layerGroup();
		self.onlineUnitsLayer = new L.layerGroup();
		self.offlineUnitsLayer = new L.layerGroup();
		self.filteredMarkersLayer = new L.layerGroup();

		if(self.map.enableClustering !== undefined) {
			self.clusterMarkers = L.markerClusterGroup.layerSupport({
				singleAddRemoveBufferDuration:1,
				showCoverageOnHover:false,
				//animate:false,
				iconCreateFunction(cluster) {

					self.$timeout(function() {
						if(cluster.chartIcon){
							cluster.chartIcon.destroy();
							cluster.chartIcon = undefined;
						}
						cluster.chartIcon = self.updateClusterChartIcon(cluster);
						// }
						// if(!self.allClusterMarkers){
						// 	self.allClusterMarkers = [];
						// }
						// self.allClusterMarkers.push(pie);
						// $('#myDivId'+cluster._leaflet_id).append(div);
					}, 0);
					let total = cluster.getAllChildMarkers().length;
					let textLength = `${total}`.length;

					let fontSize;
					switch (textLength) {
						case 1:
							fontSize = '1.5em';
							break;
						case 2:
							fontSize = '1.2em';
							break;
						case 3:
							fontSize = '0.9em';
							break;
						case 4:
							fontSize = '0.7em';
							break;
						default:
							fontSize = '0.7em';
							break;
					}
					return L.divIcon({className: '',html: `
					<div style="width:50px;height:50px;position:absolute;top:-20px;left:-20px;">
					<canvas id="${cluster._leaflet_id}" style="position:absolute;z-index:1000"></canvas>
					<svg height="25" width="25" style="position:absolute;top:12.5px;left:12.5px;z-index:999">
					<circle cx="12" cy="12" r="12" stroke="black" stroke-width="1" fill="#282828" />
					<text text-anchor="middle" dominant-baseline="central" x="12" y="12" fill="white" style="font-size:${fontSize}">${total}</text>
					</svg>
					</div>`});
				}
			});
			self.clusterMarkers.addTo(receivedMap);
			self.clusterMarkers.checkIn(self.eventsLayer);
			self.clusterMarkers.checkIn(self.onlineUnitsLayer);
			self.clusterMarkers.checkIn(self.offlineUnitsLayer);
			self.clusterMarkers.checkIn(self.filteredMarkersLayer);

			if(map.enableClustering) {
				receivedMap.on('zoomend',self.refreshClusters.bind(self));
			}else {
				self.clusterMarkers.disableClustering();
			}
			self.clusterMarkers.on('clusterclick',() => {

				var tooltipEl = document.getElementById('chartjs-tooltip');
				if(tooltipEl) {
					$(tooltipEl).css('opacity',0);
				}
			});
		}

		self.eventsLayer.addTo(receivedMap);
		self.onlineUnitsLayer.addTo(receivedMap);
		self.offlineUnitsLayer.addTo(receivedMap);
		self.filteredMarkersLayer.addTo(receivedMap);
	}

	//Enables turning on/off map events
	refreshClusters() {
		let self = this;
		if(self.clusterMarkers) {
			self.clusterMarkers.refreshClusters();
		}
	}

	toggleClustering(map) {
		let self = this;
		if(map.enableClustering) {
			map.enableClustering = false;
			self.clusterMarkers.disableClustering();
			map.off('zoomend',self.refreshClusters.bind(self));
		}else {
			map.enableClustering = true;
			self.clusterMarkers.enableClustering();
			self.clusterMarkers.refreshClusters();
			map.on('zoomend',self.refreshClusters.bind(self));

		}
	}

	hasMap() {
		let self = this;
		return new Promise((resolve, reject) => {
			if (self.map !== undefined) {
				resolve(true);
			} else {
				let promise = self.$interval(() => {
					if (self.map !== undefined) {
						resolve(true);
						self.$interval.cancel(promise);
					}
				}, 500, 10);

				promise.then((result) => {
					console.error("No Map was found");
					reject(false);
				});
			}
		});
	}

	getMap() {
		let self = this;
		return new Promise((resolve, reject) => {
			resolve(self.map);
		});
		// return self.map;
	}

	reloadMap() {
		let self = this;
		self.map.invalidateSize();
	}

	/**
	 * This method is due to the page rendering twice at DashboardComponent
	 * By calling this method before loading all markers, it ensures that
	 * we won't have twice the amount of events around
	 */
	clearMapMarkers() {
		let self = this;

		self.onlineUnitsLayer.clearLayers();
		self.offlineUnitsLayer.clearLayers();
		self.clearEventMarkers();
		self.markers = {unassigned:{}};
	}

	clearEventMarkers() {
		let self = this;
		self.eventsLayer.clearLayers();
		self.filteredMarkersLayer.clearLayers();
	}


	toggleSitesOnMap(map) {
		let self = this;
		let allSitesLayer = self.metaData.layers.allSitesLayer;
		let allSitesMarkers = self.metaData.layers.allSitesMarkers;
		if(map.hasLayer(allSitesMarkers) || map.hasLayer(allSitesLayer)) {
			self.removeSitesFromMap(map);
			map.showSites = false;
		}else {
			self.addSitesToMap(map);
			map.showSites = true;
		}
	}

	/**
	 * Whenever we like to show sites on our map, this function
	 * i) Determines whether we should be seeing siteMarkers, or sitePolygons
	 * ii) Adds the correct choice
	 * iii) Registers a map.zoomend to control our zooming and display
	 * calling from @see TopContentComponent
	 */
	addSitesToMap(map) {
		let self = this;
		// self.addAllSitesLayer();
		self.handleSitesWithZoomingbinded = self.handleSitesWithZooming.bind(self, map);

		let zoom = map.getZoom();
		if(zoom < 15) {
			self.addAllSitesMarkers(map);
		} else {
			self.addAllSitesLayer(map);
		}
		map.on('zoomend', self.handleSitesWithZoomingbinded);
	}

	/**
	 * Whenever we like to remove sites from our map, this function
	 *  i) Determines whether we should be removing siteMarkers or sitePolygons
	 *  ii) Removes the correct choice.
	 *  iii) Removes the map.on(zoomend)
	 * calling from @see TopContentComponent
	 */
	removeSitesFromMap(map) {
		let self = this;
		self.removeAllSitesLayer(map);
		self.removeAllSitesMarkers(map);
		self.map.off('zoomend', self.handleSitesWithZoomingbinded);
	}

	/**
	 * When we add sites on our map (@see addSitesToMap())
	 * We also add this zoomend event that will be sure to handle display
	 * based on zoomlevel
	 */
	handleSitesWithZooming(map) {
		let self = this;
		let allSitesLayer = self.metaData.layers.allSitesLayer;
		let allSitesMarkers = self.metaData.layers.allSitesMarkers;
		let zoom = map.getZoom();
		if(zoom < 15 && !map.hasLayer(allSitesMarkers)) {
			self.addAllSitesMarkers(map);
			self.removeAllSitesLayer(map);
		} else if(zoom >= 15 && !map.hasLayer(allSitesLayer)) {
			self.addAllSitesLayer(map);
			self.removeAllSitesMarkers(map);
		}
	}

	/**
	 * To remove allSitesLayer
	 */
	removeAllSitesLayer(map) {
		let self = this;
		let fadingTime = 200;
		let allSitesLayer = self.metaData.layers.allSitesLayer;

		if(map.hasLayer(allSitesLayer)) {
			allSitesLayer.eachLayer((layer) => {
				layer.setStyle({opacity: 0});

				self.$timeout(() => {
					L.DomUtil.removeClass(layer._path, 'polygon-fade-in');
					//The CSS definition of this class is within dashboard.scss
				}, fadingTime);
			});

			self.$timeout(() => {
				map.removeLayer(allSitesLayer);
			}, fadingTime);
		}
	}

	/**
	 * To Add allSitesLayer
	 */
	addAllSitesLayer(map) {
		let self = this;
		let allSitesLayer = self.metaData.layers.allSitesLayer;

		allSitesLayer.eachLayer((layer) => {
			layer.setStyle({opacity: 0});
			self.$timeout(() => {
				L.DomUtil.addClass(layer._path, 'polygon-fade-in');
			});
		});

		if(!map.hasLayer(allSitesLayer)) {
			map.addLayer(allSitesLayer);

			allSitesLayer.eachLayer((layer) => {
				self.$timeout(() => {
					layer.setStyle({opacity: 1});
				});
			});
		}
	}

	/**
	 * To add allSitesMarkers
	 */
	addAllSitesMarkers(map) {
		let self = this;
		let allSitesMarkers = self.metaData.layers.allSitesMarkers;

		allSitesMarkers.eachLayer((layer) => {
			layer.setOpacity(0);
			self.$timeout(() => {
				L.DomUtil.addClass(layer._icon, 'marker-fade-in');
			});
		});
		if(!map.hasLayer(allSitesMarkers)) {
			map.addLayer(allSitesMarkers);

			allSitesMarkers.eachLayer((layer) => {
				self.$timeout(() => {
					layer.setOpacity(1);
				});
			});
		}

	}

	/**
	 * To remove allSitesMarkers
	 */
	removeAllSitesMarkers(map) {
		let self = this;
		let fadingTime = 200;
		let allSitesMarkers = self.metaData.layers.allSitesMarkers;

		if(map.hasLayer(allSitesMarkers)) {
			allSitesMarkers.eachLayer((layer) => {
				layer.setOpacity(0);

				self.$timeout(() => {
					L.DomUtil.removeClass(layer._icon, 'marker-fade-in');
				}, fadingTime);
			});

			self.$timeout(() => {
				map.removeLayer(allSitesMarkers);
			}, fadingTime);
		}
	}

	//TODO Delete this
	removeRouteFeatureGroup() {
		let self = this;
		if (self.routesFeatureGroup) {
			self.map.removeLayer(self.routesFeatureGroup);
		}
	}

	//TODO Delete this
	addRouteFeatureGroup() {
		let self = this;
		if (self.routesFeatureGroup) {
			self.map.addLayer(self.routesFeatureGroup);
		} else {
			console.error("No routes feature-group found");
		}
	}

	/**
	 * On start of application we will do a GET for all units. Upon retrieving it we need
	 * to place all units on our map (although we're not displaying those that are online/offline immediately)
	 * But our mapservice is going to need to be aware of all the units, first.
	 *
	 * THEN afterwards, all events raised will be put under the unit it belongs to
	 */
	addUnitToMap(unit, $scope) {
		let self = this;
		let unitID = unit._id;
		let opacity = unit.online ? 1.0 : 0.3;
		let markerIcon = unit.online ? self.markerIcons.online : self.markerIcons.offline;
		if (unit.type === "API" && unit.online) {
			if (unit.apiType === "Secuvue Camera") {
				markerIcon = self.markerIcons.onlineCamera;
			} else if(unit.apiType === "Secuvue Controller") {
				markerIcon = self.markerIcons.onlineSUnit;
			} else {
				markerIcon = self.markerIcons.online;
			}
		}
		let layer = unit.online ? self.onlineUnitsLayer : self.offlineUnitsLayer;
		//creating the object that will contain the initial 'online'-event and all following events

		if (!self.markers[unitID]) {
			//We don't have this unit in our list, so let's add and draw him

			self.markers[unitID] = {};

			let addedUnit = self.markers[unitID];
			let unitIdx = self.unitService.getUnitIndexByID(unitID);

			//As self.markers[unitID] is an object containing events, this represents the initial
			//"I'm awake" event --> self.markers[unitID].online
			// addedUnit.online = L.marker([unit.loc.lat, unit.loc.lng], {
			// 		draggable: false,
			// 		opacity: opacity,
			// 		// icon: self.defaultMarker,
			// 		icon: markerIcon
			// 	})
			// 	.addTo(layer);

			let location = unit.lastLocation && unit.lastLocation.length > 0 ? unit.lastLocation[0].location: {type:"Point",coordinates:[27.909356,-26.081073]};
			addedUnit.online = L.geoJSON(
				location,
				{pointToLayer(feature,latlng) {
					return L.marker(latlng, {
						draggable: false,
						opacity,
						title: `${unit.name} - ${unit.lastAsset && unit.lastAsset.length > 0 ? `${unit.lastAsset[0].firstname} ${unit.lastAsset[0].lastname}` : 'UNASSIGNED'}`,
						// icon: self.defaultMarker,
						icon: markerIcon
					});
				}}
			).addTo(layer);

			addedUnit.online.on('popupopen', ($event) => {
				self.metaData.selectedUnit = unitID;
				self.openedMarkerPopupID.unit = unit;
				self.eventCarouselService.preliminarilySetFootagesUnit(unit, true);
				self.handleBlurring(unit);
			});
			addedUnit.online.on('popupclose', ($event) => {
				self.metaData.selectedUnit = "";
				self.openedMarkerPopupID.unit = {};
				self.handleUnBlurring(unitID);
				self.clearFootages(true);
			});
			// .addTo(self.map);

			addedUnit.online.eventID = '';
			addedUnit.online.unitID = unitID;
			addedUnit.online.eventType = 'online';
			addedUnit.online.unitNumber = unit.unitNumber;
			addedUnit.online.isOnline = unit.online;

			let html = require('./unitPopup.html');
			/**
			 * Here we're creating the popup to be appended to each guard/marker.
			 * Notice we're using autoPanPadding to ensure that when a poup gets opened
			 * it doesn't get opened behind our menu's, etc in the foreground.
			 * @type {L.Popup}
			 */
			self.$timeout(function() {
				let linkFunction = self.$compile(angular.element(html));
				let popup = L.popup({
					autoPan: true,
					autoPanPadding: [500, 50]
				}).setContent(linkFunction($scope)[0]);

				addedUnit.online.bindPopup(popup);
			}, 0);

		} else {
			//This new unit already exists in our list of units, therefore we've already added him
			//and should now update him


			//We look whether his location has changed
			//We can actually look whether his distance has moved with more than, say, 10m and
			//redraw the marker if it ha
			//TODO:Remember to change the feature as well
			/*if (self.map.distance(self.markers[unitID]['online'].getLayers()[0].getLatLng(), L.GeoJSON.coordsToLatLng(unit.lastLocation[0].location.coordinates)) < 10) {
				self.markers[unitID]['online'].getLayers()[0].setLatLng(unit.loc);
			} else {
				self.markers[unitID]['online'].getLayers()[0].setLatLng(unit.loc);
			}*/

			// TODO set features to new geojson just for completeness sake && Check if location not undefined
			let isOnline = unit.online;
			let wasOnline = self.markers[unitID] && self.markers[unitID].online && self.markers[unitID].online.isOnline;
			if (wasOnline && !isOnline) {
				self.handleUnitWentOffline(unit);
			}
			if (!wasOnline && isOnline) {
				self.handleUnitCameOnline(unit);
			}
			if(isOnline) {
				let hasLayer = self.onlineUnitsLayer.hasLayer(self.markers[unitID].online);
				if(hasLayer) {
					self.onlineUnitsLayer.removeLayer(self.markers[unitID].online);
					self.$timeout(function() {
						let location;
						if(unit.lastLocation && unit.lastLocation.length > 0) {
							location = unit.lastLocation[0].location;
						}else {
							location ={type:"Point",coordinates:[27.909356,-26.081073]};
						}
						self.markers[unitID].online.getLayers()[0].setLatLng(L.GeoJSON.coordsToLatLng(location.coordinates));
						let popup = self.markers[unitID].online.getPopup();
						if(popup) {
							popup.setLatLng(L.GeoJSON.coordsToLatLng(location.coordinates));
						}
						self.onlineUnitsLayer.addLayer(self.markers[unitID].online);
					}, 0);
				}
			}else {
				let hasLayer = self.offlineUnitsLayer.hasLayer(self.markers[unitID].online);
				if(hasLayer) {
					self.offlineUnitsLayer.removeLayer(self.markers[unitID].online);
					self.$timeout(function() {
						let location;
						if(unit.lastLocation && unit.lastLocation.length > 0) {
							location = unit.lastLocation[0].location;
						}else {
							location ={type:"Point",coordinates:[27.909356,-26.081073]};
						}
						self.markers[unitID].online.getLayers()[0].setLatLng(L.GeoJSON.coordsToLatLng(location.coordinates));
						let popup = self.markers[unitID].online.getPopup();
						if(popup) {
							popup.setLatLng(L.GeoJSON.coordsToLatLng(location.coordinates));
						}
						self.offlineUnitsLayer.addLayer(self.markers[unitID].online);
					}, 0);
				}

			}
			// if(unit.lastLocation && unit.lastLocation.length > 0){
			// 	self.markers[unitID]['online'].getLayers()[0].setLatLng(L.GeoJSON.coordsToLatLng(unit.lastLocation[0].location.coordinates));
			// }
			/**
			 * We look whether this unit has a changed online status
			 */

		}

	}

	removeUnit(unit) {

		let self = this;
	}

	/**
	 * Receives an event and adds it to the leaflet-map
	 * TODO: We want to change the usage of scope and $compile here
	 * NOTE: Events are only to be added after the map has been created
	 */
	addToMap(event, $scope, addToFiltered) {
		let self = this;
		let unitID = event.unit;
		let unit;
		if(unitID) {
			// TODO: Check if unit in account.
			if(self.markers[unitID]) {
				unit = self.markers[unitID];
			}else {
				self.markers[unitID] = {};
				unit = self.markers[unitID];
			}
		}else if(self.markers.unassigned) {
			unit = self.markers.unassigned;
		}else {
			self.markers.unassigned = {};
			unit = self.markers.unassigned;
		}

		let eventTimeAgo = self.eventService.getEventRaisedAgo(event);

		// unit[event._id] = L.marker([event.loc.lat, event.loc.lng], {
		// 		draggable: false,
		// 		// icon: self.defaultMarker,
		// 		icon: self.markerIcons[event.eventType],
		// 		title: `${event.asset.name} - SecuTRAQ Unit ${event.unit.unitNumber}`,
		// 	})
		// 	.animateDragging()
		// 	.addTo(self.eventsLayer);
		//.addTo(self.map);
		if(!unit[event._id] || !self.eventsLayer.hasLayer(unit[event._id])) {
			unit[event._id] = L.geoJSON(
				event.location && event.location.location && event.location.location.coordinates && event.location.location.coordinates.length > 0 ? event.location.location: {type:"Point",coordinates:[27.909356,-26.081073]},
				{pointToLayer(feature,latlng) {
					return L.marker(latlng, {
						draggable: false,
						title: `${event.eventType} - ${event.asset ? event.assetname : event.unitname ? event.unitname : 'UNASSIGNED'}`,
						// icon: self.defaultMarker,
						icon: self.markerIcons[event.eventType] || self.defaultMarker
					});
					// .animateDragging();
				}}
			)
				.addTo(self.eventsLayer);
			if(addToFiltered) {
				unit[event._id].addTo(self.filteredMarkersLayer);
			}
		}

		// let checkInterval = self.$interval(() => {
		//     /**
		//      * When we place the event's marker on the map, we want to display
		//      * the amount of minutes ago that it was raised when we hover over
		//      * the marker.
		//      * This is the best way I can think of (currently) to keep this value
		//      * adjusted dynamically
		//      */
		//
		//      if(self.markers[unitID]) {
		//         self.setEventTimeAgo(unit, event);
		//
		//      }
		//      else {
		//         //With our page loading twice, we have intervals defined for some units that
		//         //don't exist anymore.
		//         //If a unit gets removed, we're also going to stop checking its time ago.
		//         //TODO: When an event gets actioned and removed, we should do this also
		//         self.$interval.cancel(checkInterval);
		//      }
		// }, 10000);


		let guard = unit[event._id];
		guard.eventID = event._id;
		guard.unitID = event.unit;
		guard.eventType = event.eventType;
		//guard.on("dragend", function(e) {
		//let marker = e.target;
		//let position = marker.getLatLng();
		//// map.panTo(new L.LatLng(position.lat, position.lng));
		//});

		let html = require('./eventPopup.html');
		// 	`<div>
		//     <h4 style='text-align:center'><b> ${event.eventType}</b></h4></br>
		//     <table>
		//         <tbody>
		//             <tr>
		//                 <td>
		//                     Guard Name:
		//                 </td>
		//                 <td>
		//                     ${event.assetname ? event.assetname : 'UNASSIGNED'}
		//                 </td>
		//             </tr>
		//             <tr>
		//                 <td>
		//                     Current Status:
		//                 </td>
		//                 <td>
		//                     ${event.status ? event.status : "No status information"}
		//                 </td>
		//             </tr>
		//             <tr>
		//                 <td>
		//                    Date Raised:
		//                 </td>
		//                 <td>
		//                     ${self.moment(event.createdAt).format('dddd, Do MMMM')}
		//                 </td>
		//             </tr>
		//             <tr>
		//                 <td>
		//                    Time Raised:
		//                 </td>
		//                 <td>
		//                     ${self.moment(event.createdAt).format('kk:mm:ss')}
		//                 </td>
		//             </tr>
		//             <tr>
		//                 <td ng-if="$ctrl.showCurrentEvent === false || $ctrl.currentEvent._id !== '${event._id}'">
		//                     <button class="btn btn-sm btn-info" ng-click="$ctrl.setCurrentEvent('${event._id}', '${event.eventType}')">View Details</button
		//                 </td>
		//                 <td ng-if="$ctrl.showCurrentEvent === true && $ctrl.currentEvent._id === '${event._id}'">
		//                     <button class="btn btn-sm btn-info" ng-click="$ctrl.clearCurrentEvent()">Hide Details</button
		//                 </td>
		//             </tr>
		//         </tbody>
		//     </table>
		// </div>`;


		let linkFunction = self.$compile(angular.element(html));
		/**
		 * Here we're creating the popup to be appended to each guard/marker.
		 * Notice we're using autoPanPadding to ensure that when a poup gets opened
		 * it doesn't get opened behind our menu's, etc in the foreground.
		 * @type {L.Popup}
		 */

		let popup = L.popup({
			autoPan: true,
			autoPanPadding: [500, 50]
		}).setContent(linkFunction($scope)[0]);

		guard.bindPopup(popup);


		guard.on("popupopen", (e) => {
			if(event.status === 'UNSEEN') {
				self.eventService.getEventByID(event._id).then((cleanEvent) => {
					cleanEvent.status = 'SEEN';
					self.eventService.updateEvent(cleanEvent).then((response) => {
					});
				});
			}
			self.openedMarkerPopupID.event = event;
			if (self.openedMarkerPopupID.eventID === guard.eventID) {
				//we opened this popup via the openMarkerPopup() function
				//or we have clicked on the map, but that's the event that was last
				//opened in the accordion.
			} else {
				//This popup has been opened by clicking directly on a marker on the map
				//So we should update things
				self.openedMarkerPopupID.eventID = guard.eventID;
				self.openedMarkerPopupID.unitID = guard.unitID;
				self.metaData.clickedAccordionEvent = guard.eventID;
				self.openEventsAccordionTo('inbox');
				self.metaData.inboxAccordion[event.eventType] = true; //to have inboxAccordion open this
				self.preliminarilySetFootages(event, true);
				self.handleBlurring(event);
				// self.markers[guard.eventID]._icon.style.filter = "brightness(130%)";
				// guard.getLayers()[0]._icon.style.filter = 'brightness(130%)';


			}
		});

		guard.on("popupclose", (e) => {
			// self.$timeout(() => {
			//We're putting this in a $timeout BECAUSE of a problem when we reload the page with a popup still open
			//This popup triggers a 'popupclose' event, but only after all events were flushed
			if (self.openedMarkerPopupID.eventID === guard.eventID) {
				//a popup has been closed via interaction with the map and NOT through our code
				self.openedMarkerPopupID.unit = {};
				self.openedMarkerPopupID.event = {};
				self.openedMarkerPopupID.eventID = '';
				self.openedMarkerPopupID.unitID = '';
				self.metaData.clickedAccordionEvent = '';

				// self.markers[guard.eventID]._icon.style.filter = "";
				// guard.getLayers()[0]._icon.style.filter = '';
				self.clearFootages(true);
				self.handleUnBlurring(event._id);
			}
			// });
		});

		guard.on("click", (e) => {


		});

		// guard.bindPopup(linkFunction($scope)[0]);
		// guard.bindPopup(html);
		// guard.openPopup(); //makes the latest event that comes in be displayed.
	}

	/**
	 * We are displaying all markers with a title (basically text on hover) on the map
	 * For now, that title displays how much time ago the event took place.
	 * I need to intervally call this funtion
	 */
	setEventTimeAgo(unit, event) {
		let self = this;
		let eventTimeAgo = self.eventService.getEventRaisedAgo(event);
		unit[event._id].options.title = eventTimeAgo;
	}

	/**
	 * When a unit goes offline, we keep properly displaying its EVENTS-markers
	 * But we wish only change the appearance of the 'green' icon, because it's not
	 * online anymore.
	 * @param  {[type]} unit [description]
	 * @return {[type]}      [description]
	 */
	handleUnitWentOffline(unit) {
		let self = this;
		let unitID = unit._id;
		self.markers[unitID].online.isOnline = false;
		let layer = self.markers[unitID].online.getLayers()[0];

		self.onlineUnitsLayer.removeLayer(layer);
		self.offlineUnitsLayer.addLayer(layer);

		layer.setIcon(self.markerOffline);
		layer.setOpacity(0.3);

		// _.forEach(self.markers[unitID], (unitEvent) => {
		//     unitEvent._icon.style.opacity = 0.3;
		// });
	}

	/**
	 * Pretty self-explanatory. We're calling this from @see addUnitToMap()
	 */
	handleUnitCameOnline(unit) {
		let self = this;
		let unitID = unit._id;
		self.markers[unitID].online.isOnline = true;
		let layer = self.markers[unitID].online.getLayers()[0];

		self.onlineUnitsLayer.removeLayer(layer);
		self.offlineUnitsLayer.addLayer(layer);

		layer.setIcon(self.markerOnLine);
		layer.setOpacity(1);

		// TODO: the layers for offline/online
	}

	preliminarilySetFootages(event, toOpen) {
		let self = this;
		self.eventCarouselService.preliminarilySetFootages(event, toOpen);
	}

	clearFootages(toClose) {
		let self = this;
		self.eventCarouselService.clearFootages(toClose);
	}

	/**
	 * When a user clicks an event, the proper event-related details should
	 * be displayed.
	 * The map should be panned to where the event's coordinates and
	 * a carousel should be shown
	 */
	flyToEvent(event, $event, zoomScale, flyDuration, offsetX, offsetY) {
		let self = this;
		let zoom = zoomScale === undefined ? 17 : zoomScale;
		let duration = flyDuration === undefined ? 0.5 : flyDuration;
		let unitID = event.unit;
		let eventID = event._id;
		let layers = self.markers[unitID][eventID].getLayers();
		if(!layers) {
			console.error('Can not fly to event, layers empty : ', self.markers[unitID][eventID]);
		}
		if(layers.length <= 0) {
			console.error('Can not fly to event, layers empty : ', self.markers[unitID][eventID]);
		}
		let loc = layers[0].getLatLng();
		let alteredLocation = _.clone(loc);
		alteredLocation.lng = alteredLocation.lng - 0.003; //instead of going center-screen;
		alteredLocation.lat = alteredLocation.lat + 0.0005; //instead of going center-screen;
		self.map.flyTo(alteredLocation, zoom, {
			duration
		});
		// self.preliminarilySetFootages(event, true);

		if (self.openedMarkerPopupID.eventID !== eventID) {
			//because, if openedMarkerPopupID === eventID we are flying to an event
			//that is already selected,therefore already opened. By 'opening' it again,
			//we will in fact be closing it.
			self.openMarkerPopup(event, $event);
		}
	}

	flyToUnit(unitNumber, zoomScale, flyDuration, offsetX, offsetY) {
		let self = this;
		let unitLayer = self.markers[unitNumber].online;
		let unit = self.unitService.getUnitByIDSync(unitLayer.unitID);
		let unitID = unitLayer.unitID;
		if(self.metaData.selectedUnit === unitID) {
			self.markers[unitID].online.closePopup();
		}else {
			let zoom = zoomScale === undefined ? 17 : zoomScale;
			let duration = flyDuration === undefined ? 2 : flyDuration;

			let layers = unitLayer.getLayers();
			if(!layers) {
				console.error('Can not fly to unit, layers empty : ', unitLayer);
			}
			if(layers.length <= 0) {
				console.error('Can not fly to event, layers empty : ', unitLayer);
			}
			let loc = layers[0].getLatLng();
			let alteredLocation = _.clone(loc);
			alteredLocation.lng = alteredLocation.lng - 0.003; //instead of going center-screen;
			alteredLocation.lat = alteredLocation.lat + 0.0005; //instead of going center-screen;
			self.map.flyTo(alteredLocation, zoom, {
				//duration: duration
				animate:false
			});
			self.markers[unitID].online.openPopup();
			self.handleBlurring(unit);
		}
	}


	flyFromEvent(event, $event, zoomScale, flyDuration) {
		let self = this;
		let zoom = zoomScale === undefined ? 5 : zoomScale;
		let duration = flyDuration === undefined ? 0.5 : flyDuration;
		let eventID = event._id;
		let unitID = event.unit;
		let layers = self.markers[unitID][eventID].getLayers();
		if(!layers) {
			console.error('Can not fly from event, layers empty : ', self.markers[unitID][eventID]);
		}
		if(layers.length <= 0) {
			console.error('Can not fly  from event, layers empty : ', self.markers[unitID][eventID]);
		}
		let loc = layers[0].getLatLng();
		let alteredLocation = _.clone(loc);
		alteredLocation.lng = alteredLocation.lng + 0.02; //instead of going center-screen;
		self.map.flyTo(alteredLocation, zoom, {
			duration
		});
		self.closeMarkerPopup(eventID, unitID, $event);
	}

	/**
	 * This code gets executed whenever we wish to open a marker popup VIA CODE
	 * When directly clicking on a marker on the map, this code doensn't execute.
	 */
	openMarkerPopup(event, $event) {
		let self = this;

		let eventID = event._id;
		let unitID = event.unit;

		if (self.openedMarkerPopupID.eventID === '') { //this is the first one to open
			self.openedMarkerPopupID.eventID = eventID; //we mark this event as currently open
			self.openedMarkerPopupID.unitID = unitID;
			self.metaData.clickedAccordionEvent = eventID; //we set the correct event to be highlighed
			self.addPropertiesToSelectedMarker(eventID, unitID);

			let unit = self.unitService.getUnitByIDSync(unitID);

			self.handleBlurring(event);
			self.preliminarilySetFootages(event, true); //we associate carousel-images with popup
			self.markers[unitID][eventID].openPopup(); //we open it NOTE that this must be done last
		} else { //there already is an event open
			if (self.openedMarkerPopupID.eventID === eventID) { //this event is the one that's open
				self.closeMarkerPopup(eventID, unitID);
				self.clearFootages(true);

			} else { //the opened event is different than this one.
				self.closeMarkerPopup(self.openedMarkerPopupID.eventID, self.openedMarkerPopupID.unitID); //we close the previous one
				self.openMarkerPopup(event); // we now open this one
			}
		}
	}

	closeMarkerPopup(eventID, unitID, $event) {
		let self = this;
		let unit = self.markers[unitID];
		if (unit !== undefined) {
			let marker = unit[eventID];

			if (self.openedMarkerPopupID.eventID !== '') { //just confirming that a marker-popup is open
				self.openedMarkerPopupID.eventID = '';
				self.openedMarkerPopupID.unitID = '';
				self.metaData.clickedAccordionEvent = '';
				self.removePropertiesFromDeselectedMarker(eventID, unitID);
				marker.closePopup();
				self.handleUnBlurring(eventID);
				// self.markers[id].closePopup();
			}
		}
	}

	/**
	 * This function takes only an event as parameter and:
	 * i) Finds Relevant Events
	 * ii) Retrieves their layers
	 * iii) Retrieves the unit's layer
	 * iv) Marks these as relevant and blurs unrelevant layers on screen
	 * 5) TODO: Do this by asset, but if no human-asset is assigned, do it by unit
	 * @param  {Object} event ||  @param  {Object} unit
	 */
	handleBlurring(event) {
		let self = this;
		let eventID;
		let assetID;
		let unitID;
		if (!event.unit) {
			unitID = event._id;
		} else {
			unitID = event.unit;
			eventID = event._id;
		}
		if(event.lastAsset && event.lastAsset.length > 0) {
			assetID = event.lastAsset[0]._id;
		}else if(event.asset) {
			assetID = event.asset;
		}
		let events;
		if(assetID) {
			events = self.eventService.getEventRelatedEventsByHumanAsset(assetID);
		}else{
			events = self.eventService.getEventRelatedEventsByUnit(unitID);
		}
		let eventLayers = self.getLayersFromEvents(events);
		eventLayers.push(self.markers[unitID].online);

		let zoom = self.map.getZoom();
		let layersToBlur = [];
		let layersOnScreen = [];
		let mapBounds = self.map.getBounds();
		// if (zoom > 10) {
		self.onlineUnitsLayer.eachLayer((layer) => {
			let layers = layer.getLayers();
			layersOnScreen.push(layer);
		});
		self.eventsLayer.eachLayer((layer) => {
			layersOnScreen.push(layer);
		});

		_.forEach(layersOnScreen, (layer) => {
			if (!eventLayers.includes(layer)) {
				layersToBlur.push(layer);
			}
		});

		_.forEach(layersToBlur, (layer) => {
			self.blurLayer(layer);
		});
		if (eventID) {
			self.blurredOnPopupOpen[eventID] = layersToBlur;
		} else {
			self.blurredOnPopupOpen[unitID] = layersToBlur;
		}
		// }
	}

	handleUnBlurring(eventID) {
		let self = this;
		let blurredLayers = self.blurredOnPopupOpen[eventID];

		_.forEach(blurredLayers, (blurredLayer) => {
			self.unBlurLayer(blurredLayer);
		});
	}

	/**
	 * Currently employing this when I'm actioning an event @ SelectedEventComponent
	 */
	removeMarkerFromMap(unitID, eventID) {
		let self = this;
		try {
			// self.markers[unitID][eventID].openPopup();
			self.map.closePopup();
			self.eventsLayer.removeLayer(self.markers[unitID][eventID]);
			self.filteredMarkersLayer.removeLayer(self.markers[unitID][eventID]);
			delete self.markers[unitID][eventID];
		} catch (e) {
			console.error(`Cannot find marker with ID: ${eventID} to remove`);
		}
	}

	/**
	 * When we're clicking on one of the main headings we:
	 * BOUNCE each marker of that type
	 * FIT all markers to the map
	 */
	actionMainHeading(eventType) {
		let self = this;
		let markers = [];
		eventType.forEach((event) => {
			markers = markers.concat(self.filterMarkersByKeyValue("eventType", event));
		});
		if (markers.length !== 0) {
			// _.forEach(markers, (marker) => {
			// 	self.executeBounce(marker);
			// });
			let bounds = self.getBoundsFromMarkers(markers);
			self.fitMapToBounds(bounds);
			// if (eventType !== 'online') {
			// 	self.loadFootagesFromMarkers(markers);
			// }

		}

	}

	getCurrentMapBounds() {
		let self = this;
		if(self.map) {
			return self.map.getBounds();
		}
	}


	/**
	 * When clicking on zoom to all button:
	 * FIT all markers to the map
	 */
	zoomToAll() {
		let self = this;
		let group = new L.featureGroup();
		let events = self.eventsLayer.getLayers();
		events.forEach((event) => {
			group.addLayer(event);
		});
		let units = self.onlineUnitsLayer.getLayers();
		units.forEach((unit) => {
			group.addLayer(unit);
		});
		self.fitMapToBounds(group.getBounds());
	}


	/**
	 * A function that iterates through self.markers with _.forEach and retrieves markers.
	 * Returns an array of markers
	 * @param key - example, eventType
	 * @param value - example, 'panic'
	 */
	filterMarkersByKeyValue(key, value) {
		let self = this;
		let markers = [];
		_.forEach(self.markers, (unit) => {
			_.forEach(unit, (marker) => {
				if (marker[key] === value) {
					if(value === 'online') {
						//Remember that 'online' is not actually an event, but a UNIT
						//If the user is looking for markers that are 'online', we
						//check that unit.online.isOnline
						if(unit.online.isOnline) {
							markers.push(marker);
						}
					} else {
						markers.push(marker);
					}
				}
			});
		});
		return markers;
	}

	/**
	 * A function that receives a bunch of markers
	 * (Can be any object with ._latlng property)
	 * And pushes every point into an array
	 */
	getBoundsFromMarkers(markers) {
		let self = this;
		let result = [];
		if (markers) {
			_.forEach(markers, (marker) => {
				let layers = marker.getLayers();
				if(layers && layers.length > 0) {
					result.push(layers[0].getLatLng());
				}
			});
		}
		return result;
	}

	/**
	 * A function that receives a bunch of events
	 * (Can be any object with .loc property)
	 * And pushes every point into an array
	 */
	getBoundsFromEvents(events) {
		let self = this;
		let result = [];
		if (events) {
			_.forEach(events, (event) => {
				if(event.location && event.location.location && event.location.location.coordinates && event.location.location.coordinates.length > 0) {
					result.push(L.GeoJSON.coordsToLatLng(event.location.location.coordinates));
				}
			});
		}
		return result;
	}

	/**
	 * A piece of leaflet magic which takes valid bounds and fits the map
	 * accordingly.
	 * NOTE that we should take in consideration whether the accordion is open, widget is open, etc.
	 * Other options are:
	 * animate:boolean, duration:Number, easeLinearity:Number, noMoveStart:Boolean
	 */
	fitMapToBounds(bounds) {
		let self = this;
		if(typeof bounds.isValid === 'function' && !bounds.isValid()) {
			return null;
		}else if (bounds.length <= 0) {
			return null;
		}

		let paddingBottomRight;
		if (self.eventCarouselService.widgetIsOpen()) {
			paddingBottomRight = [0, 200];
		} else {
			paddingBottomRight = [0, 100];
		}
		self.map.flyToBounds(bounds, {
			paddingTopLeft: [450, 20],
			paddingBottomRight,
			maxZoom: 17,
			duration: 1
		});
	}

	loadFootagesFromMarkers(markers) {
		let self = this;
		self.eventCarouselService.loadFootagesFromMarkers(markers);

	}

	findMarkerByEventID(eventID) {
		let self = this;

		let unit = _.find(self.markers, (unit) => {
			return _.find(unit, (unitEvent) => {
				return unitEvent.eventID === eventID;
			});
		});
		if (unit !== undefined) {
			let marker = unit[eventID];
			return marker;
		} else {
			console.error("Could not find marker with eventID: ", eventID);
		}
	}

	/**
	 * This function gets called by pressing an action-button on TabContentComponent
	 * It handles a bounce-animation to be performed on the marker.
	 */
	swivelMarker(event, $event) {
		let self = this;
		let eventID = event._id;
		let unitID = event.unit;
		let marker = self.markers[unitID][eventID];

		self.executeBounce(marker);
	}

	addPropertiesToSelectedMarker(eventID, unitID) {
		let self = this;
		let unit = self.markers[unitID] || self.markers.unassigned;
		if (unit !== undefined) {
			let marker = unit[eventID];
			if(marker && marker.getLayers().length > 0 && marker.getLayers()[0]._icon) {
				let zIndex = parseInt(L.DomUtil.getStyle(marker.getLayers()[0]._icon, 'zIndex'));
				marker.getLayers()[0]._icon.style.filter = "brightness(130%)";
				marker.getLayers()[0]._icon.style.zIndex = zIndex + 100;
			}
		} else {
			console.error(`Cannot find marker with id: ${eventID}`);
		}
	}

	removePropertiesFromDeselectedMarker(eventID, unitID) {
		let self = this;
		let unit = self.markers[unitID];

		if (unit !== undefined) {
			let marker = unit[eventID];
			if(marker && marker.getLayers().length > 0 && marker.getLayers()[0]._icon) {
				let zIndex = parseInt(L.DomUtil.getStyle(marker.getLayers()[0]._icon, 'zIndex'));
				marker.getLayers()[0]._icon.style.filter = "";
				marker.getLayers()[0]._icon.style.zIndex = zIndex - 100;
			}
		} else {
			console.error(`Cannot find marker with id: ${eventID}`);
		}
	}

	executeBounce(marker) {
		let self = this;
		// return false;
		let icon = marker.getLayers()[0]._icon;
		if(!icon) {
			return false;
		}
		let iconMargin = parseInt(L.DomUtil.getStyle(icon, 'marginTop'));
		let zIndex = parseInt(L.DomUtil.getStyle(icon, 'zIndex'));
		icon.style.transition = "all 0.2s";
		icon.style.marginTop = `${iconMargin - 15}px`;
		icon.style.filter = "brightness(130%)";
		icon.style.zIndex = zIndex + 100;

		self.$timeout(() => {
			icon.style.marginTop = `${iconMargin}px`;
			icon.style.zIndex = zIndex;
			icon.style.filter = "";
			icon.style.transition = "";
		}, 250);
	}

	/**
	 * When a marker has been dropped onto the valid drop-area this function handles
	 * the event and placement of the marker. It considers whether the sidenav is opened
	 * because that has an influence on marker-placement.
	 * NOTE: This function would most probably also receive information pertaining to the guard
	 *       as a parameter
	 * NOTE: I'm not too sure how, what and where this will be implemented, but this is the
	 *       functionality of being able to drag and drop something onto the map
	 */
	onGuardDropped(event) {
		let self = this;
		let sideNavOpened = self.getSideNavStatus();
		let sideNavWidth = self.getSideNavWidth();
		let coordsX = sideNavOpened ? event.clientX - sideNavWidth + 10 : event.clientX;
		let coordsY = event.clientY - 220; // 20 is the half of markers height
		let point = L.point(coordsX, coordsY); // creating a Point object with the given x and y coordinates
		let markerCoords = self.map.containerPointToLatLng(point); // getting the geographical coordinates of the point
		let guard = self.droppedMarkers.length === 0 ? self.droppedMarkers[self.markers.length - 0]
			: self.droppedMarkers[self.markers.length - 1];

		guard = L.marker([markerCoords.lat, markerCoords.lng], {
			draggable: true,
			icon: self.defaultMarker
		})
			.animateDragging()
			.addTo(self.map);

		let dummyName = "Jack Johnson";
		let dummyStatus = "Active";
		let dummyContact = "086 323 1549";
		let html
			= `<b> Position ${guard._latlng}</b> </br>
		<table>
		<tbody>
		<tr>
		<td>
		Guard Name:
		</td>
		<td>
		${dummyName}
		</td>
		</tr>
		<tr>
		<td>
		Current Status:
		</td>
		<td>
		${dummyStatus}
		</td>
		</tr>
		<tr>
		<td>
		Contact:
		</td>
		<td>
		${dummyContact}
		</td>
		</tr>
		<tr>
		<td>
		<button class="btn btn-sm btn-info">Call</button
		</td>
		</tr>
		</tbody>
		</table>`;

		guard.bindPopup(html);
	}

	/**
	 * Receives a bunch of events, find their layers, and returns it
	 */
	getLayersFromEvents(events) {
		let self = this;
		let result = [];
		_.forEach(events, (event) => {
			if(self.markers[event.unit]) {
				result.push(self.markers[event.unit][event._id]);
			}else {
				result.push(self.markers.unassigned[event._id]);
			}
		});

		return result;
	}

	/**
	 * This function runs when we click the 'history-button' in TabContent HTML. It:
	 *   i) Receives a list of events.
	 *  ii) It orders them
	 * iii) Determines the unit to associate the events with
	 *  iv) Gets the layers of these events + unit
	 *   v) Calls for fitting the map to these layers
	 *  vi) Calls for blurring of unrelated layers
	 * vii) Calls for drawing relevant polylines/dots
	 * @param eventID - The ID under which we keep changes of blurred layers in self.metaData.eventsHistory[ID]
	 */
	enableBreadcrumbMode(events, unitId) {
		let self = this;
		let orderedEvents = self.eventService.orderEventsChronologically(events);
		let orderedEventsLayers = self.getLayersFromEvents(orderedEvents);
		/**
		 * For now I'm assuming that the last event's unit is the current unit being used by asset
		 */
		// let activeUnitID = orderedEvents[orderedEvents.length - 1].unit;
		let activeUnitLayer = self.markers[unitId].online;

		/**
		 * Remember that at TabContentComponent we add the eventHistory[unitId], but we leave it empty
		 * Here we recognize the empty object and know that the desired intention is to fill it with
		 * lines, details, etc.
		 */

		if (Object.keys(self.metaData.eventsHistory[unitId]).length === 0) {

			//to contain all layers that are being blurred
			self.metaData.eventsHistory[unitId].blurred = {};

			//to contain an array of layers that we want excepted from blurring
			self.metaData.eventsHistory.activeLayers[unitId] = [];

			//adding the relevant unit which we'd also not want blurred
			orderedEventsLayers.push(activeUnitLayer);

			_.forEach(orderedEventsLayers, (layer) => {
				//We are adding these events into this array to prevent others from blurring them
				self.metaData.eventsHistory.activeLayers[unitId].push(layer);

				//We are checking whether some of these layers aren't perhaps blurred. If they are, we unblur them
				if (self.isBlurredLayer(layer)) {
					self.unBlurLayer(layer);
				}
			});

			let bounds = self.getBoundsFromEvents(orderedEvents);
			let layers = activeUnitLayer.getLayers();
			if(layers && layers.length > 0) {
				bounds.push(layers[0].getLatLng());
			}
			self.fitMapToBounds(bounds);
			//blurs unrelated layers on screen
			self.blurUnrelatedLayers(orderedEventsLayers, unitId);
			self.handleBreadcrumbDrawings(unitId, unitId);

			self.handleHistoryZoomOutBinded = self.handleHistoryZoomOut.bind(self);
			self.map.on('zoomend', self.handleHistoryZoomOutBinded);
		}
	}

	handleHistoryZoomOut() {
		let self = this;
		let zoom = self.map.getZoom();

		if (zoom < 6) {
			let eventIDs = Object.keys(self.metaData.eventsHistory);
			if (eventIDs.length > 1) {
				//activeLayers can be ignored
				_.forEach(eventIDs, (eventID) => {
					if (eventID !== 'activeLayers') {
						self.disableBreadcrumbMode(eventID);
					}
				});
			}
			self.map.off('zoomend', self.handleHistoryZoomOutBinded);
		}
	}

/**
 * When we want to highlight certain events, it'll also be useful to fade away some
 * other, unrelated events.
 * @param eventLayers {Array} - all the relevant events we do NOT want blurred on screen
 * NOTE that this includes the unit's layer as well
 * @param eventID {String|Number} - An identifier we use to associate all the blurrings with
 */
blurUnrelatedLayers(eventLayers, eventID) {
	let self = this;
	// We are given eventLayers, so for each marker we find its layer
	let layersToBlur = [];
	self.map.once('zoomend', ($event) => {

		let mapBounds = self.map.getBounds();
		let layersOnScreen = [];

		self.onlineUnitsLayer.eachLayer((layer) => {
			let layers = layer.getLayers();
			if(layers && layers.length > 0) {
				let latlng = layers[0].getLatLng();
				if (mapBounds.contains(latlng)) {
					layersOnScreen.push(layer);
				}
			}
		});
		self.eventsLayer.eachLayer((layer) => {
			let layers = layer.getLayers();
			if(layers && layers.length > 0) {
				let latlng = layers[0].getLatLng();
				if (mapBounds.contains(latlng)) {
					layersOnScreen.push(layer);
				}
			}
		});

		_.forEach(layersOnScreen, (layer) => {
			if (!self.isActiveLayer(layer)) {
				if (!eventLayers.includes(layer)) {
					layersToBlur.push(layer);
				}
			}
		});

		/**
		 * Note that because we are blurring both units as well as events, I've chosen _leaflet_id
		 */
		_.forEach(layersToBlur, (layer) => {
			if (!self.isBlurredLayer(layer)) {
				//We make sure that this layer is not blurred already by another breadcrumb-mode
				self.metaData.eventsHistory[eventID].blurred[layer._leaflet_id] = layer;
				self.blurLayer(layer);
			}
		});
	});
}

handleBreadcrumbDrawings(unitID, eventID) {
	let self = this;
	let layer = new L.layerGroup().addTo(self.map);
	if (eventID) {
		self.metaData.eventsHistory[eventID].mapLayer = layer;
		self.metaData.eventsHistory[eventID].mapLayerItems = [];
	}
	//we're told what unit needs to be drawn, but now we need to retrieve a specified
	//list of heartbeats
	let locations = self.unitService.getUnitLocationsSync(unitID);
	self.map.once('zoomend', ($event) => {
		self.drawBreadcrumbLines(layer, locations);
	});
}

drawBreadcrumbLines(layer, locations) {
	let self = this;
	let first = 1;
	let second = 2;
	let length = locations.length;
	let done = false;

	if (length > 1) {

		while (!done) {
			let firstPoint = L.GeoJSON.coordsToLatLng(locations[length-first].location.coordinates);
			let secondPoint = L.GeoJSON.coordsToLatLng(locations[length-second].location.coordinates);
			let distance = self.map.distance(firstPoint, secondPoint);
			// if(distance > 10){
			self.drawLocationLine(locations[length-first], locations[length-second], layer);
			first = second;
			// }
			second++;

			if (second === length+1) {
				done = true;
			}
		}
	}
}

/**
 * Handles the drawing of polylines and placing of dots between breadcrumbs
 * Also handles the popups to display, if any.
 * @param firstHB {Object} - Object with .loc and .ts (timestamp)
 * @param layer {Object} - The layer to draw on
 */
drawLocationLine(firstLocation, secondLocation, layer) {
	let self = this;
	let firstPoint = L.GeoJSON.coordsToLatLng(firstLocation.location.coordinates);
	let secondPoint = L.GeoJSON.coordsToLatLng(secondLocation.location.coordinates);
	let distance = self.map.distance(firstPoint, secondPoint);
	distance = self.numbersService.handleDistanceConversion(distance);

	if (firstPoint != secondPoint) {

		let path = L.polyline([firstPoint, secondPoint], {
			opacity: 0.2,
		}).addTo(layer);
		path.bindTooltip(`${distance}`);

		let arrowHead = L.polylineDecorator(path, {
			patterns: [{
				offset: "100%",
				repeat: false,
				symbol: L.Symbol.arrowHead({
					pixelSize: 10,
					polygon: false,
					pathOptions: {
						stroke: true,
						opacity: 0.5
					}
				})
			}]
		}).addTo(layer);

		// if(second !== length -1) {
		let dot = L.circle(secondPoint, {
			radius: 5
		}).addTo(layer);

		let difference = self.moment.duration(self.moment.utc(firstLocation.ts).diff(self.moment.utc(secondLocation.ts)));
		// difference = self.numbersService.handleMinutesConversion(difference);
		let timeFormat = "YYYY-MM-DD HH:mm:ss";
		// let dotPopup = L.popup();
		// let html = `
		// <table>
		// <tbody>
		// <tr>
		// <td>Time : </td>
		// <td>${self.moment.utc(secondLocation.ts).format(timeFormat)}</td>
		// </tr>
		// </tbody>
		// </table>`;
		// dotPopup.setContent(html);
		// dot.bindPopup(dotPopup);
		// dot.on('mouseover', function (e) {
		// 	this.openPopup();
		// });
		// dot.on('mouseout', function (e) {
		// 	this.closePopup();
		// });
		dot.bindTooltip(`Time : ${self.moment(secondLocation.ts).format(timeFormat)}`);
		// }
	}
}

/**
 * Removes the history-display.
 * This gets called from TabContentComponent
 */
disableBreadcrumbMode(eventID) {
	let self = this;
	//pertaining to removing the display
	self.metaData.eventsHistory.activeLayers[eventID] = [];
	delete self.metaData.eventsHistory.activeLayers[eventID];
	self.unBlurLayers(eventID);
	self.removeBreadcrumbLayers(eventID);

	self.metaData.eventsHistory[eventID] = undefined;
	delete self.metaData.eventsHistory[eventID];

	/**
	 * Unbinds the $watch
	 */
	self.metaData.eventsHistoryWatches[eventID]();
}

/**
 * Upon exiting our breadcrumb-mode we are called to unblurr all layers
 */
unBlurLayers(eventID) {
	let self = this;
	_.forEach(self.metaData.eventsHistory[eventID].blurred, (layer) => {
		self.unBlurLayer(layer);
	});
}

/**
 * I'm keeping this in a function to try and keep code centralized.
 */
unBlurLayer(layer) {
	let self = this;
	if(typeof layer.setOpacity === 'function') {
		layer.setOpacity(1);
	}else if(layer.getLayers()[0] && typeof layer.getLayers()[0].setOpacity === 'function') {
		layer.getLayers()[0].setOpacity(1);
	}else{
		console.error("Error bluring layer : ",layer);
	}
}

blurLayer(layer) {
	let self = this;
	if(typeof layer.setOpacity === 'function') {
		layer.setOpacity(0.3);
	}else if(layer.getLayers()[0] && typeof layer.getLayers()[0].setOpacity === 'function') {
		layer.getLayers()[0].setOpacity(0.3);
	}else{
		console.error("Error bluring layer : ",layer);
	}
}

removeBreadcrumbLayers(eventID) {
	let self = this;
	let layer = self.metaData.eventsHistory[eventID].mapLayer;

	if (layer) {
		layer.eachLayer((layer) => {
			layer.remove();
		});
	}
}

/**
 * Using this function when drawing breadcrumb history.
 * When blurring layers, we just want to make sure that
 * they're not currently active in some other breadcrumb-mode
 */
isActiveLayer(layer) {
	let self = this;
	let activeLayers = self.metaData.eventsHistory.activeLayers;
	let isActive = false;

	if (Object.keys(activeLayers).length === 0) {
		return false;
	} else {
		_.forEach(activeLayers, (eventLayers) => {
			if (eventLayers.includes(layer)) {
				isActive = true;
				return false;
			}
		});
	}
	return isActive;
}

/**
 * Using this function when drawing breadcrumb history.
 * When start going into breadcrumb-mode we want to determine
 * if this event isn't perhaps already blurred by another breadcrumb-mode
 * @param exemptEventID - An optional parameter. When it is given, we will only
 * return true if the layer is blurred and not part of that eventID-group
 */
isBlurredLayer(layer, exemptEventID) {
	let self = this;
	let isBlurred = false;

	let eventIDs = Object.keys(self.metaData.eventsHistory);

	if (exemptEventID) {
		_.forEach(eventIDs, (eventID) => {
			if (eventID !== 'activeLayers' && eventID !== exemptEventID) {
				if (isBlurred === true) {
					return false;
				}
				let blurredLayers = self.metaData.eventsHistory[eventID].blurred;
				_.forEach(blurredLayers, (blurredLayer) => {
					if (blurredLayer._leaflet_id === layer._leaflet_id) {
						isBlurred = true;
						return false;
					}
				});
			}
		});
	} else {
		_.forEach(eventIDs, (eventID) => {
			if (eventID !== 'activeLayers') {
				if (isBlurred === true) {
					return false;
				}
				let blurredLayers = self.metaData.eventsHistory[eventID].blurred;
				_.forEach(blurredLayers, (blurredLayer) => {
					if (blurredLayer._leaflet_id === layer._leaflet_id) {
						isBlurred = true;
						return false;
					}
				});
			}
		});
	}
	return isBlurred;
}

/**
 * Currently calling this function from TabContentComponent (consider adding another component)
 * We want to display only certain markers on the map, when this is called.
 * NOTE that we only wish to do so, temporarily.
 */
addToFilteredMarkers(eventType) {
	let self = this;
	if (self.filteredMarkersLayer.getLayers().length === 0) {
		self.eventsLayer.remove();
		self.onlineUnitsLayer.remove();
		self.offlineUnitsLayer.remove();
		self.filteredMarkersLayer.addTo(self.map);
	}
	let markers = self.filterMarkersByKeyValue('eventType', eventType);
	_.forEach(markers, (marker) => {
		self.filteredMarkersLayer.addLayer(marker);
	});

	// self.map.removeLayer(self.eventsLayer);
	// self.map.removeLayer(self.onlineUnitsLayer);
}

removeFromFilteredMarkers(eventType) {
	let self = this;
	let markers = self.filterMarkersByKeyValue('eventType', eventType);
	_.forEach(markers, (marker) => {
		self.filteredMarkersLayer.removeLayer(marker);
	});
}

showAllMarkers() {
	let self = this;
	self.filteredMarkersLayer.remove();
	self.$timeout(function() {
		self.eventsLayer.addTo(self.map);
		self.onlineUnitsLayer.addTo(self.map);
		self.offlineUnitsLayer.addTo(self.map);
	}, 0);
}

/**
 * Using this function at TabContentComponent and MapViewComponent
 * Notice that we're returning an object. This is
 * to share the reference memory-address with whomever
 * is asking for this metaData so they can alter it.
 * Use responsibly!
 */
getMetaData() {
	let self = this;
	return new Promise((resolve, reject) => {
		resolve(self.metaData);
	});
}

getMetaDataSync() {
	return this.metaData;
}

openInboxAccordionTo(eventType) {
	let self = this;
	self.openSubtabsTo('Events');
	self.metaData.inboxAccordion[eventType] = true;
}

openEventsAccordionTo(option) {
	let self = this;
	self.metaData.eventsAccordion[option] = true;
}

openSubtabsTo(title) {
	let self = this;
	self.metaData.activeTab = title;
}

/**
 * Calling this function from DashboardComponent.
 * Due to a service staying alive, when we log-out and
 * log back in, the service retains the old layerGroups but is
 * given a NEW map. For this reason we clear the layers and add
 * it to the new map
 */
clearService() {
	let self = this;
	self.clearMapMarkers();
	self.eventsLayer = new L.layerGroup();
	self.onlineUnitsLayer = new L.layerGroup();
	self.offlineUnitsLayer = new L.layerGroup();
	self.filteredMarkersLayer = new L.layerGroup();

	self.hasMap().then((value) => {
		self.map.addLayer(self.eventsLayer);
		self.map.addLayer(self.onlineUnitsLayer);
		// self.map.addLayer(self.filteredMarkersLayer);
	});
}

setEventDetails(details) {
	let self = this;
	self.eventDetails = details;
}

compareGeoLocation(location1,location2) {
	if(location1.coordinates[0] == location2.coordinates[0] && location1.coordinates[1] == location2.coordinates[1]) {
		return true;
	}else{
		return false;
	}
}


doLog() {
	let self = this;
	console.debug(self);
}

updateClusterChartIcon(cluster, chart){
	let self = this;
	let element = document.getElementById(cluster._leaflet_id);
	if(!element) {
		return null;
	}
	let counter = {};
	let allMarkers = cluster.getAllChildMarkers();
	allMarkers.forEach((marker) => {
		_.forEach(marker._eventParents, (event) => {
			if(event && event.eventType) {
				let eventDetails = self.eventDetails[event.eventType];
				let eventType = event.eventType;
				if(eventDetails && eventDetails.groupUnder) {
					eventType = eventDetails.groupUnder;
				}
				if(eventType == 'online' && !event.isOnline) {
					eventType = 'offline';
				}
				if(!counter[eventType]) {
					counter[eventType] = 0;
				}
				counter[eventType] ++;
			}
		});
	});
	let data = [];
	let labels = [];
	let color = [];
	_.forEach(counter,(eventCount,eventKey) => {
		let details = self.eventDetails[eventKey];
		data.push(eventCount);
		labels.push(details.description);
		color.push(details.color);
		//total += eventCount;
	});
	let newDiv = element.getContext('2d');
	if(chart){
		//Update chart data
		chart.data = {
			datasets: [{
				data,
				backgroundColor:color,
				borderWidth:0,
				// borderColor:'black'
			}],
			// These labels appear in the legend and in the tooltips when hovering different arcs
			labels
		};
		chart.update('none');
		return chart;
	}else{
		let pie = new Chart(newDiv,{
			type:'doughnut',
			data:{
				datasets: [{
					data,
					backgroundColor:color,
					borderWidth:0,
					// borderColor:'black'
				}],

				// These labels appear in the legend and in the tooltips when hovering different arcs
				labels
			},
			options:{
				animation:{
					animateRotate:false,
					animateScale:false,
				},
				plugins:{
					tooltip:{
						// Disable the on-canvas tooltip
						enabled: false,

						external(context) {
							// Tooltip Element
							var tooltipEl = document.getElementById('chartjs-tooltip');

							// Create element on first render
							if (!tooltipEl) {
								tooltipEl = document.createElement('div');
								tooltipEl.id = 'chartjs-tooltip';
								tooltipEl.innerHTML = '<table></table>';
								document.body.appendChild(tooltipEl);
							}

							// Hide if no tooltip
							var tooltipModel = context.tooltip;
							if (tooltipModel.opacity === 0) {
								tooltipEl.style.opacity = 0;
								return;
							}

							// Set caret Position
							tooltipEl.classList.remove('above', 'below', 'no-transform');
							if (tooltipModel.yAlign) {
								tooltipEl.classList.add(tooltipModel.yAlign);
							} else {
								tooltipEl.classList.add('no-transform');
							}

							function getBody(bodyItem) {
								return bodyItem.lines;
							}

							// Set Text
							if (tooltipModel.body) {
								var titleLines = tooltipModel.title || [];
								var bodyLines = tooltipModel.body.map(getBody);

								var innerHtml = '<thead>';

								titleLines.forEach(function(title) {
									innerHtml += '<tr><th>' + self.$sanitize(title) + '</th></tr>';
								});
								innerHtml += '</thead><tbody>';

								bodyLines.forEach(function(body, i) {
									var colors = tooltipModel.labelColors[i];
									var style = 'background:' + colors.backgroundColor;
									style += '; border-color:' + colors.borderColor;
									style += '; border-style:solid; border-radius:3px; border-width: 1.5px; width:1em;height:1em;margin-right:0.5em;';
									var span = '<span style="' + style + '"></span>';
									innerHtml += '<tr><td><div style="display:flex;align-items:center;padding:0.25em 0.5em">' + span + body + '</div></td></tr>';
								});
								innerHtml += '</tbody>';

								var tableRoot = tooltipEl.querySelector('table');
								tableRoot.innerHTML = innerHtml;
							}

							var position = context.chart.canvas.getBoundingClientRect();
							//var bodyFont = Chart.helpers.toFont(tooltipModel.options.bodyFont);
							var bodyFont = tooltipModel.options.bodyFont;

							// Display, position, and set styles for font
							tooltipEl.style.opacity = 1;
							tooltipEl.style.position = 'absolute';
							tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
							tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
							tooltipEl.style.font = bodyFont.string;
							tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
							tooltipEl.style.pointerEvents = 'none';
							tooltipEl.style.zIndex = 1000;
							// Tooltip Element
							//var tooltipEl = document.getElementById('chartjs-tooltip');

							//let dashboardComponent = $('dashboard');
							//// Create element on first render
							//if (!tooltipEl) {
								//tooltipEl = document.createElement('div');
								//tooltipEl.id = 'chartjs-tooltip';
								//tooltipEl.innerHTML = "<table></table>";
								//dashboardComponent.prepend(tooltipEl);
								//// document.body.appendChild(tooltipEl);
							//}

							//// Hide if no tooltip
							//var tooltipModel = context.tooltip;
							//if (tooltipModel.opacity === 0) {
								//tooltipEl.style.opacity = 0;
								//return;
							//}

							//// Set caret Position
							//tooltipEl.classList.remove('above', 'below', 'no-transform');
							//if (tooltipModel.yAlign) {
								//tooltipEl.classList.add(tooltipModel.yAlign);
							//} else {
								//tooltipEl.classList.add('no-transform');
							//}

							//function getBody(bodyItem) {
								//return bodyItem.lines;
							//}

							//// Set Text
							//if (tooltipModel.body) {
								//var titleLines = tooltipModel.title || [];
								//var bodyLines = tooltipModel.body.map(getBody);

								//var innerHtml = '<thead>';

								//titleLines.forEach(function(title) {
									//innerHtml += `<tr><th>${title}</th></tr>`;
								//});
								//innerHtml += '</thead><tbody>';
								//bodyLines.forEach(function(body, i) {
									//var colors = tooltipModel.labelColors[i];
									//var style = `background:${colors.backgroundColor}`;
									//style += `; border-color:${colors.borderColor}`;
									//style += '; border:solid 1px';
									//style += '; width: 14px';
									//style += '; height: 14px';
									//style += '; margin-right: 4px';

									//var span = `<div style="${style}"></div>`;
									//innerHtml += `<tr><td><div style="display:flex;">${span}${body}</div></td></tr>`;
								//});
								//innerHtml += '</tbody>';

								//var tableRoot = tooltipEl.querySelector('table');
								//tableRoot.innerHTML = innerHtml;
							//}

							//// `this` will be the overall tooltip
							//var position = this._chart.canvas.getBoundingClientRect();

							//// Display, position, and set styles for font
							//// tooltipEl.style.opacity = 0;
							//let dashboardOffset = dashboardComponent.offset();
							//tooltipEl.style.opacity = 1;
							//tooltipEl.style.position = 'absolute';
							//tooltipEl.style.left = `${position.left + tooltipModel.caretX- dashboardOffset.left}px`;
							//tooltipEl.style.top = `${position.top + tooltipModel.caretY - dashboardOffset.top}px`;
							//tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
							//tooltipEl.style.fontSize = `${tooltipModel.bodyFontSize}px`;
							//tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
							//tooltipEl.style.paddingLeft = `${tooltipModel.yPadding}px `;
							//tooltipEl.style.paddingRight = `${tooltipModel.yPadding}px `;
							//tooltipEl.style.paddingTop = `${tooltipModel.xPadding}px`;
							//tooltipEl.style.paddingBottom = `${tooltipModel.xPadding-3}px`;
							//tooltipEl.style.zIndex = 1000;
						}
					}
				}

			},
		});
		return pie;
	}
}

}

export default angular.module('secutraqApp.dashboard')
	.service('mapService', MapService);
