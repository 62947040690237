
export class AccountModalController {
	Restangular;
	$uibModalInstance;
	modalInstance;

	/*@ngInject*/
	constructor($stateParams, Restangular, $scope, $timeout, moment, toastr, accountService) {
		this.$stateParams = $stateParams;
		this.Restangular = Restangular;
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.moment = moment;
		this.toastr = toastr;
		this.accountService = accountService;
	}

	$onInit() {
		let self = this;
		self.account = {};
	}

	doLog() {
		let self = this;
		console.debug(this);
	}

	// Setup all the models for display
	// Modal accept: send resulting object back to promise in parent controller
	ok() {
		let self = this;
		self.accountService.createAccount(self.account).then((result) => {

            self.toastr.info('Account created.');
			self.modalInstance.close(self.schedule);
        }).catch((err) => {
            self.toastr.error('Account create failed');
            console.error(err);
        })
	};


	// Modal cancelled: either cancel pressed or clicked off screen
	cancel() {
		this.modalInstance.dismiss('Cancel pressed');
	};


}

export default angular.module('secutraqApp.settings')
	.component('accountmodal', {
		template: require('./account.modal.html'),
		bindings: {
			resolve: '<',
			close: '&',
			dismiss: '&',
			modalInstance: '<'
		},
		controller: AccountModalController,
		controllerAs: '$ctrl'
	})
	.name;
