// import angular from 'angular';
// import LoginController from './login.controller';

import angular from 'angular';
import uiRouter from 'angular-ui-router';
import routes from './login.routes';

export default angular.module('secutraqApp.login', [uiRouter])
  .config(routes)
  .name;

require('./login.component');
