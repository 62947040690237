import {RRule,RRuleSet,rrulestr} from 'rrule';

export class SchedulingModalController {
	$stateParams;
	Restangular;
	$uibModalInstance;
	modalInstance;
	settings;

	/*@ngInject*/
	constructor($stateParams, Restangular, $scope, $timeout, moment, numbersService, toastr, wizardService, shiftService, $ngConfirm) {
		this.$stateParams = $stateParams;
		this.Restangular = Restangular;
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.$ngConfirm = $ngConfirm;
		this.moment = moment;
		this.shiftService = shiftService;
		this.toastr = toastr;
		this.calendar = undefined;
		this.wizardService = wizardService;
		this.availableEndTypes = ['Never', 'On', 'After'];
		this.uiCalendar;
		this.availableFrequencies = [
			{
				alias: 'Hour(s)',
				repeatAlias: 'hours',
				freqId: RRule.HOURLY,
				value: true
			},
			{
				alias: 'Day(s)',
				repeatAlias: 'days',
				freqId: RRule.DAILY,
				value: false
			},
			{
				alias: 'Week(s)',
				repeatAlias: 'weeks',
				freqId: RRule.WEEKLY,
				value: false
			},
			{
				alias: 'Month(s)',
				repeatAlias: 'months',
				freqId: RRule.MONTHLY,
				value: false
			},
			{
				alias: 'Year(s)',
				repeatAlias: 'years',
				freqId: RRule.YEARLY,
				value: false
			},
		];
		this.availableGranValues = {
			byminute:[],
			byhour:[],
			byweekday:[
				{alias:'Sunday',value:6, checked:false, shortAlias:'S'},
				{alias:'Monday',value:0, checked:false, shortAlias:'M'},
				{alias:'Tuesday',value:1, checked:false, shortAlias:'T'},
				{alias:'Wednesday',value:2, checked:false, shortAlias:'W'},
				{alias:'Thursday',value:3, checked:false, shortAlias:'T'},
				{alias:'Friday',value:4, checked:false, shortAlias:'F'},
				{alias:'Saturday',value:5, checked:false, shortAlias:'S'},
			],
			byweekno:[],
			bymonthday:[],
			bymonth:[],

		};
		for (var i = 0; i < 60; i++) {
			this.availableGranValues.byminute.push({alias:''+i,value:i});
			if(i < 24)
			this.availableGranValues.byhour.push({alias:''+i,value:i});
			if(i > 0 && i <= 31)
			this.availableGranValues.bymonthday.push({alias:''+i,value:i});
			if(i > 0 && i <= 12)
			this.availableGranValues.bymonth.push({alias:new this.moment().month(i-1).format('MMMM'),value:i});
			if(i > 0 && i <= 52)
			this.availableGranValues.byweekno.push({alias:''+i,value:i});
		}
		this.allExcludeOptions = [
			{alias:'Hours', id:'byhour', range:24, values:[]},
			{alias:'Weekdays', id:'byweekday', range:7, values:[]},
			{alias:'Months', id:'bymonth', range:12, values:[]}
		];
		this.availableExcludeOptions = _.cloneDeep(this.allExcludeOptions);
		// this.bindTo = this.bindToOptions[0];

		this.startsOnDateIsOpen = false;
		this.endOfToday = this.moment().endOf('day').toDate();
		this.endOfTomorrow = this.moment().add(1,'day').endOf('day').toDate();
		this.calendarOptions = {
			height:450,
			datesSet: (dateInfo) => {
				this.calculateDates({activeStart:dateInfo.start, activeEnd:dateInfo.end});
			},
			headerToolbar:{
				start: 'title', // will normally be on the left. if RTL, will be on the right
				center: '',
				end: 'today prev,next' // will normally be on the right. if RTL, will be on the left
			},
			dateClick: this.addInclusionDate.bind(this),
			eventClick: this.addExclusionDate.bind(this),

		}
	}

	$onInit() {
		let self = this;
		try {
		self.endingOptions = [
			{alias: "Never", value: true},
			{alias: "After", value: false},
			{alias: "On", value: false}
		]


		if(self.resolve.settings.edit){
			self.scheduleDefinition = self.resolve.schedule;
			// TODO: Generate repeat options from rrule
			self.convertFromRRuleSet(self.scheduleDefinition.rruleSet);
			self.repeatOptions.end = new self.moment(self.repeatOptions.start).add(self.scheduleDefinition.duration,'seconds');
			self.calculateDuration();
			self.updateQuickRepeatOptions();

		}else{
			self.scheduleDefinition = {

			};
			self.initRepeatOptions();
		}

		self.$scope.$watch(() => {
			return self.repeatOptions;
		}, () => {
			if(self.calendar){
				self.calculateDates(self.calendar.view);
			}
		},true);



		/**
		 * The amount of guards that we assign to this schedule
		 * @type {Number}
		 */
		self.allocatedUnits = 1;

		self.selectedMonths = [
			{alias:'January', value: false},
			{alias:'February', value: false},
			{alias:'March', value: false},
			{alias:'April', value: false},
			{alias:'May', value: false},
			{alias:'June', value: false},
			{alias:'July', value: false},
			{alias:'August', value: false},
			{alias:'September', value: false},
			{alias:'October', value: false},
			{alias:'November', value: false},
			{alias:'December', value: false},
		];



		self.calendarView = 'month';
		self.viewDate = new self.moment();
		self.calendarEvents = [];


		self.settings = self.resolve.settings;

		self.availableSites = self.resolve.sites;

		/**
		 * Grabbing all routes that aren't bound to site.
		 * We only want to show these when a user selects to bind to a route
		 */
		self.availableUnboundedRoutes = [];
		_.forEach(self.resolve.routes, (routeCollection) => {
			if(!routeCollection.linkedToSite) {
				_.forEach(routeCollection.routes, (route) => {
					let routeCopy = _.cloneDeep(route);
					self.availableUnboundedRoutes.push(routeCopy);
				});
			}
		});

		/**
		 * When a user selects a site to bind to, that perticular site's routes are retrieved
		 * and put in this array.
		 * This is because we want to pass these routes to VIS-SCHEDULE to do stuff with it
		 * @type {Array}
		 */
		self.availableSiteRoutes = [];

		self.availableRoutes = _.reduce(self.resolve.routes, (arr,routeCollection) => {
			routeCollection.routes.forEach((route) => {
				route.site = routeCollection.site;
				route.routeCol=routeCollection._id;
				route.routeColName = routeCollection.name;
				arr.push(route);
			});
			return arr;
		},[]);
		self.$uibModalInstance = self.modalInstance;


		// self.startingDateChanged();
		// self.changedSchedule();
		// onInit end

		} catch (e) {
			/* handle error */
			console.error(e);
		}
	}

	// siteSelected($item, $model) {
	// 	let self = this;
	// 	let siteRoutes = [];
	// 	self.bindToOptions.push('site');
	// 	self.bindToOptions = _.uniq(self.bindToOptions);
	//
	// 	_.forEach(self.resolve.routes, (storedRoute) => {
	// 		if(storedRoute.site && storedRoute.site._id === $item._id) {
	// 			_.forEach(storedRoute.routes, (route) => {
	// 				siteRoutes.push(route);
	// 			});
	// 		}
	// 	});
	//
	// 	self.availableSiteRoutes = siteRoutes;
	// }


	/**
	 * Calling this from HTML 'Previous' and 'Next' '.wizard-steps' buttons.
	 * NOTE that we're only calling this when the tab-content contains v-tabs
	 * NOTE that the 'next step' could imply going to our timeline. If that be the case,
	 * we should call readyTimeline();
	 */
	nextScheduleStep() {
		let self = this;
		self.wizardService.goToWizardStep('next');
	}

	/**
	 * Calling this from HTML 'Previous' and 'Next' '.wizard-steps' buttons.
	 * NOTE that we're only calling this when the tab-content contains v-tabs
	 */
	prevScheduleStep() {
		let self = this;

		self.wizardService.goToWizardStep('prev');
	}

	doLog() {
		console.debug(this);
	}

	// Setup all the models for display
	// Modal accept: send resulting object back to promise in parent controller
	ok() {
		let self = this;
		if(self.resolve.settings.edit){
			//Get next instance of schedule

			let next = self.schedule.rruleSet.after(self.moment.utc().toDate());
			if(!next){
				self.toastr.error("This schedule does not have any more occurrences.  Are you sure you want to save the schedule?");
				return null;
			}

			let start = self.moment.utc(next);
			let end = self.moment.utc(next).add(self.schedule.duration);


			//Check if active shift and overlapping with next

			if(!self.schedule.prevIds){
				self.schedule.prevIds = [];
			}
			let allScheduleIds = _.cloneDeep(self.schedule.prevIds);
			allScheduleIds.push(self.schedule._id);

			self.shiftService.getShiftWithQuery({query:allScheduleIds,field:'schedule',activeOnly:'true',between:{start:start.valueOf(),end:end.valueOf()}}).then( (result) => {
				let shifts = result.data;
				if(shifts && shifts.length > 0){
						//Add exclude rule
						let content = `
								<p>A schedule currently has an active shift that overlaps the new settings.<br><br>
								Would you like to : <br><br>
								delete the active shift and replace the shift with this schedule <br><b>
								OR</b><br>
								start this schedule at the next occurance after the active shift is completed.<br>
								`;
								if(self.repeatOptions.selectedEndingOption.active != 0){
									content += `
									Next occurance :
										${self.moment.utc(self.allDates[1]).format()},
									${self.allDates.length - 1} remaining`;
								}else{
									let next = self.schedule.rruleSet.after(self.moment.utc(shifts[shifts.length-1].end).toDate());
									content += `Next occurance : ${next ? self.moment.utc(next).format() : "None"}`;
								}
								content += '</p>';
						return new Promise( (res,rej) => {
							self.$ngConfirm({
								title: `<b style="text-align:center">Active shift found.</b>`,
									content: content ,
								escapeKey: true,
								backgroundDismiss: true,
								buttons: {
									// long hand button definition
									disable: {
										text: "Replace shift",
										btnClass: 'btn-primary',
										action: function(scope) {
											res({action:'disable', data:shifts});
											return true;
										}
									},
									skip: {
										text: "Skip occurance",
										btnClass: 'btn-primary',
										action: function(scope) {
											res({action:'skip', data:shifts});
											return true;
										}
									},
								},
							});

						} )
				}else{
					return false;
				}
			} ).then( (data) => {
				if(data){
					if(data.action == 'skip'){
						self.schedule.rruleSet.exdate(next);
						self.$uibModalInstance.close(self.schedule);

					}else if(data.action == 'disable'){
						let ids = _.map(data.data, '_id');
						self.shiftService.disableShifts(ids).then( (results) => {
							self.$uibModalInstance.close(self.schedule);
						} ).catch( (err) => {
							self.toastr.error('Could not disable shifts');
						} )
					}
				}else{
					self.$uibModalInstance.close(self.schedule);
				}
			} ).catch( (err) => {
				console.error(err);
			} )
		}else{
			self.$uibModalInstance.close(self.schedule);
		}
	};


	undoSiteSelect() {
		let self = this;
		_.remove(self.bindToOptions,(a) => {
			return a == 'site';
		});
		self.bindToSite = {};
	}

	changeRouteSelection(){
		let self = this;
		if(self.bindToRoutes && self.bindToRoutes.length > 0){

			self.bindToOptions = _.union(self.bindToOptions,['route']);
		}else{
			_.remove(self.bindToOptions,(a) => {
				return a == 'route';
			});
		}
	}

	undoRouteSelect() {
		let self = this;
		self.bindToRoute = {};
	}

	incrementUnits() {
		let self = this;
		self.allocatedUnits++;
	}

	decrementUnits() {
		let self = this;
		if(self.allocatedUnits > 0) {
			self.allocatedUnits--;
		}
		else {
			self.toastr.warning(`Negative values are not allowed`,  {
				closeButton: true,
				tapToDismiss: true,
			});
		}
	}

	/**
	 * When we change starting or finishing time of our schedule
	 * Also running this $onInit
	 */
	changedSchedule() {
		let self = this;
		let start = self.moment(self.startingDate);
		let end = self.moment(self.scheduleEndingTime);

		let startFormatted = self.moment(start).format('h:mm a');
		let endFormatted = self.moment(end).format('h:mm a');

		self.scheduleStartingTimeFormatted = startFormatted;
		self.scheduleEndingTimeFormatted = endFormatted;

		let diff = end.diff(start, 'seconds');

		if(diff >= 0) {
			//The end time is after starting time.
			let result = self.numbersService.handleMinutesConversion(diff/60);
			self.scheduleDuration = result;
		}
		else {
			end.add(1, 'days');
			diff = end.diff(start, 'seconds');
			let result = self.numbersService.handleMinutesConversion(diff/60);
			self.scheduleDuration = result;
		}
		self.schedule.duration = diff;
	}

	onToggleActivityIsRoute() {
		let self = this;
		let isTrue = self.activityIsRoute;

		if(isTrue) {
			self.activityIsRoam = false;
		}
	}

	onToggleActivityIsRoam() {
		let self = this;
		let isTrue = self.activityIsRoam;

		if(isTrue) {
			self.activityIsRoute = false;
		}
	}

	/**
	 *
	 */
	onToggleEndingOption(endingOption) {
		let self = this;

		let optionIndex;
		switch (endingOption.alias) {
			case 'Never':
				optionIndex = 0;
				break;

			case 'After':
				optionIndex = 1;
				break;

			case 'On':
				optionIndex = 2;
				break;

			default:
		}

		if(endingOption.value === true) {
			//The toggled endingOption was switched to be true, so we make the rest false
			_.forEach(self.endingOptions, (option) => {
				if(option.alias !== endingOption.alias) {
					option.value = false;
				}
			});
			if(self.repeatOptions){
				self.repeatOptions.selectedEndingOption.active = optionIndex;
			}
		}
		else {
			//The user switched the switch to false
			//I've made a 6th, blank page
			//default to never
			if(self.repeatOptions){
				self.repeatOptions.selectedEndingOption.active = 0;
			}
		}
	}

	addInclusionDate(info) {
		let self = this;
		let date = info.date;
		let currentDate = self.moment();
		let start = self.moment(self.repeatOptions.start);
		let chosenDate = self.moment(date).local().set('hours',start.hours()).set('minutes',start.minutes()).set('seconds',0).set('ms',0);
		if(chosenDate.diff(currentDate) < 0) {
			self.toastr.warning(`You have chosen a date already past. Please choose another`,  {
				closeButton: true,
				tapToDismiss: true,
			});
		}
		else {
			let index = _.findIndex(self.repeatOptions.specifiedExclusionDates,(o) => {
				return chosenDate.isSame(o.date);
			});
			if(index >= 0){
				self.repeatOptions.specifiedExclusionDates.splice(index,1);
			}
			let format = self.moment(chosenDate).format('dddd, Do MMM YYYY');

			self.repeatOptions.specifiedInclusionDates.push({date: chosenDate, format: format});
		}

	}
	addExclusionDate(info) {
		let self = this;
		let event = info.event;
		let date = event.start;
		let currentDate = self.moment();
		let start = self.moment(self.repeatOptions.start);
		let chosenDate = self.moment(date).local();
		if(chosenDate.diff(currentDate) < 0) {
			self.toastr.warning(`You have chosen a date already past. Please choose another`,  {
				closeButton: true,
				tapToDismiss: true,
			});
		}
		else {
			let index = _.findIndex(self.repeatOptions.specifiedInclusionDates,(o) => {
				return chosenDate.valueOf() == o.date.valueOf();
			});
			if(index >= 0){
				self.repeatOptions.specifiedInclusionDates.splice(index,1);
			}
			let format = self.moment(chosenDate).format('dddd, Do MMM YYYY');
			self.repeatOptions.specifiedExclusionDates.push({date: chosenDate, format: format});
		}

	}


	finishingDateToday() {
		let self = this;
		self.repeatOptions.endDate = self.moment().endOf('day').toDate();
	}
	finishingDateTomorrow() {
		let self = this;
		self.repeatOptions.endDate = self.moment().add(1,'day').endOf('day').toDate();
	}


	// Modal cancelled: either cancel pressed or clicked off screen
	cancel() {
		this.$uibModalInstance.dismiss('Cancel pressed');
	};


	/**
	 * This function will assume responsibility towards constructing all the necessary
	 * values for passing to VIS-SCHEDULE-TIMELINE.
	 * TODO: Toastr for minimum data not met. CONSIDER
	 */
	readyTimeline() {
		let self = this;
		// self.scheduleDefinition = self.getScheduleDefinition();
		self.scheduleDefinition.start = self.repeatOptions.start;
		self.scheduleDefinition.end = self.repeatOptions.end;

		self.$timeout(() => {
			self.$scope.$broadcast('readyTimeline');
		}, 500);
	}

	/**
	 * A function that will compile a scheduleDefinition {Object}
	 */
	getScheduleDefinition() {
		let self = this;
		let scheduleDefinition = {};

		/**
		 * Gathering the bounded option and its type
		 * @type {[type]}
		 */
		let chosenBindingChoice = self.scheduleBindingChoices[self.chosenBindingChoice].alias;
		let isSite, isRoute, isUnit, isAsset;
		let boundedOptions = {};
		switch (chosenBindingChoice) {
			case 'Site':
				if(Object.keys(self.bindToSite).length > 0) {
					chosenBindingChoice = self.bindToSite;
					boundedOptions.isSite = true;
					boundedOptions.siteRoutes = self.availableSiteRoutes;
				}
				else {
					chosenBindingChoice = {name: "NO SITE SELECTED"};
					boundedOptions.isSite = true;
				}
				break;

			case 'Route':
				if(Object.keys(self.bindToRoute).length > 0) {
					chosenBindingChoice = self.bindToRoute;
					boundedOptions.isRoute = true;
				}
				else {
					chosenBindingChoice = {name: "NO ROUTE SELECTED"};
					boundedOptions.isRoute = true;
				}
				break;

			default:
		}
		boundedOptions.value = chosenBindingChoice;
		let routes = [];
		boundedOptions.choices = self.bindToOptions;
		if(self.bindToOptions.includes('site')){
			let siteRoutes = _.filter(self.availableRoutes,(route) => {
				return route.site && route.site._id == self.bindToSite._id;
			});
			if(siteRoutes){
				routes = routes.concat(siteRoutes);
			}
		}
		if(self.bindToOptions.includes('route') && self.bindToRoutes && self.bindToRoutes.length > 0){
			routes = routes.concat(self.bindToRoutes);
		}
		routes = _.uniq(routes,(o) => {
			return o._id;
		});
		boundedOptions.routes = routes;

		self.scheduleDefinition.allocatedUnits = self.allocatedUnits;
		self.scheduleDefinition.boundedOptions = boundedOptions;
	}

	convertToRRuleSet(){
		let self = this;
		let ruleSet = new RRuleSet(true);
		ruleSet.rrule(self.convertToRRule());
		self.repeatOptions.specifiedExclusionDates.forEach((date) => {
			ruleSet.exdate(date.date.toDate());
		});
		self.repeatOptions.specifiedInclusionDates.forEach((date) => {
			ruleSet.rdate(date.date.toDate());
		});
		return ruleSet;
	}

	convertToRRule(){
		let self = this;
		if(!self.repeatOptions.start){
			console.error('No start date selected!');
			return null;
		}

		let options = {
			dtstart : self.moment(self.repeatOptions.start).toDate()
		};
		switch (self.repeatOptions.quickSelect) {
			case 'NoRepeat':
				options.count = 1;
				options.freq = RRule.DAILY;
				options.interval = 1;
				break;
			case 'daily':
				options.freq = RRule.DAILY;
				options.interval = 1;
				break;
			case 'weeklyOnDay':
				options.freq = RRule.WEEKLY;
				options.interval = 1;
				options.byweekday = [self.moment(self.repeatOptions.start).isoWeekday()-1];
				break;
			case 'monthlyOnDay':
				options.freq = RRule.MONTHLY;
				options.interval = 1;
				options.bymonthday = [self.moment(self.repeatOptions.start).date()];
				break;
			case 'weekdays':
				options.freq = RRule.WEEKLY;
				options.interval = 1;
				options.byweekday = [0,1,2,3,4];
				break;
			case 'custom':
				options.freq = self.repeatOptions.selectedFrequency.freqId;
				options.interval = self.repeatOptions.scheduleInterval;
				if(self.repeatOptions.selectedFrequency.repeatAlias == 'weeks'){
					options.byweekday = _.map(_.filter(self.repeatOptions.byweekday,{checked:true}),'value');
				}else if(self.repeatOptions.selectedFrequency.repeatAlias == 'months'){
					if(self.repeatOptions.bymonth == 'monthlyOnDay'){
						options.bymonthday = [self.moment(self.repeatOptions.start).date()];
					}else if (self.repeatOptions.bymonth == 'monthlyOnFirstWeekday') {
						options.bysetpos = 1;
						options.byweekday = [self.moment(self.repeatOptions.start).isoWeekday()-1];
					}
				}
				//Old code that allows custom inclusions/exclutions of certain repeating dates
				self.repeatOptions.exclutions.forEach((rule) => {
					if(rule.values && rule.values.length > 0){
						let excludeOption = _.find(self.allExcludeOptions,{id:rule.id});
						if(excludeOption){
							let includeValues = self.invertExclutions(excludeOption.range,rule.values);
							options[rule.id] = includeValues;
						}
					}
				});
				break;
			default:

		}
		if(self.repeatOptions.quickSelect !== 'NoRepeat'){
			switch (self.repeatOptions.selectedEndingOption.active) {
				case 0:
				break;

				case 1:
				options.count = self.repeatOptions.scheduleEndAfter >  0 ? self.repeatOptions.scheduleEndAfter : 1;
				break;

				case 2:
				options.until = self.repeatOptions.endDate;
				break;

				default:
			};
		}



		let tempRule = new RRule(
			options, true
		);
		return tempRule;
	}

	convertFromRRuleSet(ruleStr){
		let self = this;
		let rruleSet = rrulestr(ruleStr.join('\n'),{forceset:true});
		self.scheduleDefinition.rruleSet = rruleSet;
		let rules = rruleSet._rrule;
		let includedDates = rruleSet._rdate;
		let excludedDates = rruleSet._exdate;
		if(rules && rules.length > 0){
			self.repeatOptions = self.convertFromRRule(rules[0]);
		}
		if(includedDates && includedDates.length > 0){
			self.repeatOptions.specifiedInclusionDates = _.map(includedDates,(o) => {
				let format = self.moment(o).format('dddd, Do MMM YYYY');
				return {date : new self.moment(o), format: format};
			});
		}else {
			self.repeatOptions.specifiedInclusionDates = [];
		}
		if(excludedDates && excludedDates.length > 0){
			self.repeatOptions.specifiedExclusionDates = _.map(excludedDates,(o) => {
				let format = self.moment(o).format('dddd, Do MMM YYYY');
				return {date : new self.moment(o), format: format};
			});
		}else {
			self.repeatOptions.specifiedExclusionDates = [];
		}
	}

	convertFromRRule(rule){
		let self = this;
		let options = {};
		if(rule.origOptions){
			let quickSelect = 'custom';
			let origOptions = rule.origOptions;
			if(origOptions.dtstart){
				options.start = new self.moment(origOptions.dtstart).toDate();
			}
			if(origOptions.freq == 3){//Daily
				if(origOptions.count && origOptions.count == 1){
					if(origOptions.interval && origOptions.interval == 1){
						quickSelect = 'NoRepeat';
					}
				}else if(!origOptions.count && origOptions.interval == 1){
					quickSelect = 'daily';
				}
			}else if (origOptions.freq == 2) {//Weekly
				if(origOptions.interval && origOptions.interval == 1){
					if(origOptions.byweekday && origOptions.byweekday.length > 0){
						if(_.isEqualWith(origOptions.byweekday,[self.convertToRRuleWeekday(self.moment(options.start).isoWeekday()-1)],self.customEqual)){
							quickSelect = 'weeklyOnDay';
						}else if (_.isEqualWith(origOptions.byweekday,[RRule.MO,RRule.TU,RRule.WE,RRule.TH,RRule.FR],self.customEqual)) {
							quickSelect = 'weekdays';
						}
					}
				}
			}else if (origOptions.freq == 1) {//Monthly
				if(origOptions.interval == 1 && origOptions.bymonthday && _.isEqualWith(origOptions.bymonthday,[self.moment(options.start).date()],self.customEqual)){
					quickSelect = 'monthlyOnDay';
				}
			}
			if(quickSelect != 'NoRepeat'){
				if(!options.selectedEndingOption){
					options.selectedEndingOption = {};
				}
				options.selectedEndingOption.active = 0;
				if(origOptions.count && origOptions.count >= 0){
					options.selectedEndingOption.active = 1;
					options.scheduleEndAfter = origOptions.count;
					self.endingOptions[1].value = true;
					self.onToggleEndingOption(self.endingOptions[1]);
				}
				if(origOptions.until){
					options.endDate = self.moment(origOptions.until).toDate();
					options.selectedEndingOption.active = 2;
					self.endingOptions[2].value = true;
					self.onToggleEndingOption(self.endingOptions[2]);
				}
			}
			if (quickSelect == 'custom') {
				// TODO: convert custom options.
				if(origOptions.interval > 0){
					options.scheduleInterval = origOptions.interval;
				}else {
					options.scheduleInterval = 1;
				}
				options.selectedFrequency = _.find(self.availableFrequencies,{freqId:origOptions.freq});
				if(origOptions.freq == 2){//Weekly
					if(origOptions.byweekday && origOptions.byweekday.length > 0){
						options.byweekday = self.availableGranValues.byweekday;
						origOptions.byweekday.forEach((weekday) => {
							let day = _.find(options.byweekday,{value:weekday.weekday});
							day.checked = true;
						});
					}
				}else if (origOptions.freq == 1) {//Monthly
					if(origOptions.bymonthday && origOptions.bymonthday.length > 0){
						options.bymonth = 'monthlyOnDay';
					}else if (origOptions.bysetpos && origOptions.bysetpos.length > 0 && origOptions.byweekday && origOptions.byweekday.length > 0) {
						options.bymonth = 'monthlyOnFirstWeekday';
					}
				}
				if(!options.exclutions){
					options.exclutions = [];
				}
				if(origOptions.byhour && origOptions.byhour.length > 0){
					let includeValues = self.invertExclutions(24,origOptions.byhour);
					options.exclutions.push({id:'byhour',values:includeValues});
				}
				if(origOptions.byweekday && origOptions.byweekday.length > 0){
					let includeValues = self.invertExclutions(7,origOptions.byweekday);
					options.exclutions.push({id:'byweekday',values:includeValues});
				}
				if(origOptions.bymonth && origOptions.bymonth.length > 0){
					let includeValues = self.invertExclutions(12,origOptions.bymonth);
					options.exclutions.push({id:'bymonth',values:includeValues});
				}
			}
			options.quickSelect = quickSelect;
			return options;
		}else{
			console.error('No orig options for rule');
		}

	}

	invertExclutions(range,values){
		let self = this;
		let invertedValues = [];
		for (var i = 0; i < range; i++) {
			invertedValues.push(i);
		}
		invertedValues = _.difference(invertedValues,values);
		return invertedValues;
	}

	convertToFinalSchedule(){
		let self = this;
		self.readyTimeline();
		if(self.repeatOptions.selectedEndingOption.active != 0){
			self.allDates = self.scheduleDefinition.rruleSet.all();
			self.allDates = _.filter(self.allDates,(o) => {
				return self.moment() < o;
			});
			self.lastDate = self.allDates[self.allDates.length-1];
		}
		let shifts = _.groupBy(self.scheduleDefinition.slots,'priority');
		let assets = [];
		_.forEach(shifts, (shiftItems,shiftKey) => {
			//Get uniq assets {unit, asset, group}
			let singleSlot = _.filter(shiftItems,(a) => {
				return a.startTime == shiftItems[0].startTime && a.duration == shiftItems[0].duration && a.type == shiftItems[0].type;
			})
			singleSlot.forEach((slot) => {
				let asset = {};
				if(slot.unit){
					asset.unit = slot.unit;
				}
				if(slot.asset){
					asset.asset = slot.asset;
					asset.asstename = slot.assetname;
				}
				if(slot.group){
					asset.group = slot.group;
				}
				assets.push(asset);
			})
		});

		self.assets = assets;
		self.schedule = _.merge(self.schedule, self.scheduleDefinition);

	}








	calculateDuration(durationChange){
		let self = this;
		if(durationChange){
			let duration = self.moment(self.repeatOptions.duration).diff(self.moment().startOf('day'));
			if(duration < 1000*60*10){
				duration = 1000*60*10;
				self.repeatOptions.duration = new self.moment().startOf('day').add(duration);
			}
			self.repeatOptions.end = new self.moment(self.repeatOptions.start).add(duration).toDate();
		}else{
			let diffInMinutes = self.moment(self.repeatOptions.end).diff(self.moment(self.repeatOptions.start),'minutes');
			if(diffInMinutes >= 24*60){
				let minutes = self.moment(self.repeatOptions.end).minutes();
				self.repeatOptions.end = self.moment(self.repeatOptions.start).add(23,'hours').minutes(minutes).toDate();
			}else if(diffInMinutes <= 10){
				diffInMinutes = 10;
				self.repeatOptions.end = self.moment(self.repeatOptions.start).add(10,'minutes').toDate();
			}
			let duration = self.moment(self.repeatOptions.end).diff(self.moment(self.repeatOptions.start));
			self.repeatOptions.duration = new self.moment().startOf('day').add(duration);
		}
	}

	updateQuickRepeatOptions(){
		let self = this;
		//Generate repeat QuickOptions;
		self.calculateDuration(true);
		// if(self.repeatOptions.start > self.repeatOptions.end){
		// 	let duration = self.moment(self.repeatOptions.duration).diff(self.moment().startOf('day'));
		// 	self.repeatOptions.end = new self.moment(self.repeatOptions.start).add(duration);
		// }
		self.availableMonthOptions = [
			{
				alias: `Monthly on the ${self.moment(self.repeatOptions.start).format('Do')}`,
				id: 'monthlyOnDay'
			},{
				alias: `Monthly on the first ${self.moment(self.repeatOptions.start).format('dddd')}`,
				id: 'monthlyOnFirstWeekday'
			}
		];
		self.availableRepeatOptions = [
			{
				alias : 'None',
				id:'NoRepeat'
			},{
				alias : 'Daily',
				id: 'daily'
			},{
				alias: `Weekly on ${self.moment(self.repeatOptions.start).format('dddd')}`,
				id: 'weeklyOnDay'
			},{
				alias: `Monthly on the ${self.moment(self.repeatOptions.start).format('Do')}`,
				id: 'monthlyOnDay'
			},{
				alias: `Every weekday(Monday to Friday)`,
				id:'weekdays'
			},{
				alias: `Custom`,
				id: 'custom'
			}
		];
	}



	calculateDates(view,element){
		let self = this;
		if(view && view.activeStart && view.activeEnd){
			self.scheduleDefinition.rruleSet = self.convertToRRuleSet();
			let allTempDates = self.scheduleDefinition.rruleSet.between(view.activeStart,view.activeEnd);
			self.calendarEvents = [];
			allTempDates.forEach((date) => {
				if(self.moment() < date){
					self.calendarEvents.push({
						start:date,
						end: new self.moment(date).add(self.moment(self.repeatOptions.end).diff(self.repeatOptions.start)).toDate(),
						title:self.scheduleDefinition.name
					});
				}
			});
		}
	}

	convertToRRuleWeekday(num){
		let self = this;
		switch (num) {
			case 0:
				return RRule.MO;
				break;
			case 1:
				return RRule.TU;
				break;
			case 2:
				return RRule.WE;
				break;
			case 3:
				return RRule.TH;
				break;
			case 4:
				return RRule.FR;
				break;
			case 5:
				return RRule.SA;
				break;
			case 6:
				return RRule.SU;
				break;
			default:
				return RRule.MO;
		}
	}

	customEqual(o,b){
		if((o == undefined || o == null)&&(b == undefined || b == null)){
			return true;
		}
	}

	ensureInterval(){
		let self = this;
		let minInterval = 1;
		if(self.repeatOptions.selectedFrequency && self.repeatOptions.selectedFrequency.repeatAlias && self.repeatOptions.selectedFrequency.repeatAlias == 'hours'){
			minInterval = self.moment(self.repeatOptions.duration).hours()+1;
		}
		if(!self.repeatOptions.scheduleInterval || self.repeatOptions.scheduleInterval < minInterval){
			self.repeatOptions.scheduleInterval = minInterval;
		}
	}

	initRepeatOptions(){
		let self = this;
		if(!self.repeatOptions){
			self.repeatOptions = {};
		}
		self.repeatOptions.start = self.moment().startOf('hour').hour(8).toDate();
		self.repeatOptions.end = self.moment(self.repeatOptions.start).add(8,'hours');
		self.calculateDuration();
		self.updateQuickRepeatOptions();
		self.repeatOptions.quickSelect = self.availableRepeatOptions[0].id;
		self.repeatOptions.bymonth = self.availableMonthOptions[0].id;
		self.repeatOptions.specifiedInclusionDates = [];
		self.repeatOptions.specifiedExclusionDates = [];
		self.repeatOptions.exclutions = [];
		self.repeatOptions.byweekday = self.availableGranValues.byweekday;
		self.repeatOptions.scheduleInterval = 1;

		self.repeatOptions.selectedFrequency = {
			alias: 'Day(s)',
			repeatAlias: 'days',
			freqId: RRule.DAILY,
			value: false
		};
	}

	addExclution(){
		let self = this;
		if(self.availableExcludeOptions && self.availableExcludeOptions.length > 0){
			self.repeatOptions.exclutions.push({id:self.availableExcludeOptions[0].id,values:[]});
		}
		self.ensureUniqExclutions();
	}

	removeExclution(index){
		let self = this;
		self.repeatOptions.exclutions.splice(index,1);
		self.ensureUniqExclutions();
	}

	ensureUniqExclutions(){
		let self = this;
		self.$timeout(()=>{
			self.availableExcludeOptions = _.differenceBy(self.allExcludeOptions,self.repeatOptions.exclutions,'id');
			if(self.repeatOptions.selectedFrequency && self.repeatOptions.selectedFrequency.repeatAlias == 'days' ){
				let index = _.findIndex(self.availableExcludeOptions,{id:'byhour'});
				if(index >= 0){
					self.availableExcludeOptions.splice(index,1);
				}
				index = _.findIndex(self.repeatOptions.exclutions,{id:'byhour'});
				if(index >= 0){
					self.repeatOptions.exclutions.splice(index,1);
				}
			}else if(self.repeatOptions.selectedFrequency && self.repeatOptions.selectedFrequency.repeatAlias == 'months' ||  self.repeatOptions.selectedFrequency.repeatAlias == 'weeks'){
				let index = _.findIndex(self.availableExcludeOptions,{id:'byhour'});
				if(index >= 0){
					self.availableExcludeOptions.splice(index,1);
				}
				index = _.findIndex(self.availableExcludeOptions,{id:'byweekday'});
				if(index >= 0){
					self.availableExcludeOptions.splice(index,1);
				}
				index = _.findIndex(self.repeatOptions.exclutions,{id:'byhour'});
				if(index >= 0){
					self.repeatOptions.exclutions.splice(index,1);
				}
				index = _.findIndex(self.repeatOptions.exclutions,{id:'byweekday'});
				if(index >= 0){
					self.repeatOptions.exclutions.splice(index,1);
				}
			}
		}, 0);
	}


}

export default angular.module('secutraqApp.scheduling')
	.component('schedulingmodal', {
		template: require('./scheduling.modal.html'),
		bindings: {
			resolve: '<',
			close: '&',
			dismiss: '&',
			modalInstance: '<'
		},
		controller: SchedulingModalController,
		controllerAs: '$ctrl'
	})
	.name;
// .controller(ContactModalController).name;
