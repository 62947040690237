'use strict';
angular.module('secutraqApp.directives')
  .directive('growButton', ['$document',
    function($document) {
        return {
      restrict: 'A',
      link: function(scope, el, attrs) {
          el.addClass('grow-button-toggler')
          if(attrs.growButton === 'startToggled') {
              el.addClass('grow-button-grows');
          }

          el.on('click', ($event) => {

              if(!el.hasClass('grow-button-grows')) {
                  el.addClass('grow-button-grows');
              }
              else {
                  el.removeClass('grow-button-grows');
              }
          });
      }
    };
  }]);
