export class TemplatesComponent {

    /*@ngInject*/
    constructor($scope, $timeout, $state, accountService, $stateParams, Auth, fieldReportTemplateService, $uibModal, $ngConfirm) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.fieldReportTemplateService = fieldReportTemplateService;
        this.$stateParams = $stateParams;
        this.Auth = Auth;
		this.$ngConfirm = $ngConfirm;
		this.$uibModal = $uibModal;

    }

    $onInit() {
        let self = this;

        /**
         * [accounts description]
         * @type {Array}
         */
        self.templates = [];

		self.fieldReportTemplateService.registerListener(self, (event, item, array) => {
            self.onReceiptOfTemplate(item, event);
		}).then((templates) => {
            _.forEach(templates, (template) => {
                self.onReceiptOfTemplate(template);
            });
		});

	}

	$onDestroy() {
		let self = this;
		self.fieldReportTemplateService.unregisterListener(self);
	}

    onReceiptOfTemplate(template, event) {
        let self = this;

        let index = _.findIndex(self.templates, (storedTemplate) => {
            return storedTemplate._id === template._id;
        });

		if(index !== -1) {
			if(event === 'deleted') {
				self.templates.splice(index, 1);
			}else{
				self.templates.splice(index, 1, template);
			}
		} else if(event !== 'deleted') {
			self.templates.push(template);
		}
	}

    doLog() {
        console.debug(this);
    }


	newTemplate() {
		let self = this;
		let modalInstance = self.$uibModal.open({
			component: 'newFieldReportTemplate',
			backdrop: 'static',
			size: 'xlg',
			keyboard: false
		});
	}

	editTemplate(template) {
		let self = this;
		let modalInstance = self.$uibModal.open({
			component: 'newFieldReportTemplate',
			backdrop: 'static',
			size: 'xlg',
			keyboard: false,
			resolve:{
				template:_.cloneDeep(template),
				edit:true
			}
		});
	}

	removeTemplate(template) {
		let self = this;
		self.$ngConfirm({
			title:`<b>Are you sure want to delete this template?</b>`,
			content:"Field reports generated using this template will not be viewable in the dashboard after it has been deleted.",
			escapeKey:true,
			backgroundDismiss:true,
			buttons: {
				ok:{
					text:"DELETE",
					btnClass: 'btn-warning',
					action(scope) {
						self.fieldReportTemplateService.removeFieldReportTemplate(template);
					}
				},
				close: {
					text: "Cancel",
					// btnClass: 'btn-primary',
					action(scope) {
					}
				}
			}
		});
	}


}
export default angular.module('secutraqApp.fieldReports')
.component('fieldReportTemplates', {
    template: require('./templates.html'),
    controller: TemplatesComponent,
    controllerAs: "$ctrl"
});
