export class TaskService {
	/*@ngInject*/
	constructor($uibModal, moment, Restangular, socket, Auth) {
		let self = this;
		this.$uibModal = $uibModal;

		this.tasks = [];
		this.moment = moment;
		this.Restangular = Restangular;
		this.listenerMap = new Map();
		this.socket = socket;
		this.Auth = Auth;
		this.cacheTimeout = undefined;
		this.lastAccount = undefined;
		this.lastUser = undefined;
		this.joined = false;
		this.rooms = [];

		// self.generateRoute();


	}

	onSocketEvent(event, item, array) {
		let self = this;
		self.tasks = array;
		self.listenerMap.forEach((value, key) => {
			setTimeout(() => {
				value(event, item, array);
			}, 0);
		});
	}

	// TODO: Figure out specific room joining before new tasks types are added.

	registerListener(context, cb, noGet, room) {
		let self = this;
		if (!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
		if(!self.lastUser) {
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		} else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
			self.destroyService();
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		}
		let count = self.listenerMap.size;
		self.listenerMap.set(context, cb);
		// If listenermap was empty, (TODO and timer isn't running), join room, syncupdates
		if (!self.joined) {
			if (self.cacheTimeout) {
				clearTimeout(self.cacheTimeout);
				self.cacheTimeout = undefined;
			}
			if(noGet) {
				self.socket.syncUpdates('task', self.tasks, self.onSocketEvent.bind(self), self);
				let filter = {};
				let hasPrivilege = self.Auth.hasPrivilegeSync('task.index',undefined,filter);
				if(hasPrivilege && filter && filter.filterParam && filter.filterParam.field == 'user') {
					filter.filterParam.data.forEach( (group) => {
						self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().ref}:${self.Aut.getCurrentUserSync()._id}:*:tasks`);
						self.rooms.push(`${self.Auth.getCurrentAccountSync().ref}:${self.Aut.getCurrentUserSync()._id}:*:tasks`);
					} );
				}else{
					self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().ref}:*:*:tasks`);
					self.rooms.push(`${self.Auth.getCurrentAccountSync().ref}:*:*:tasks`);
				}
				return null;
			}else {
				return self.getTasks().then((tasks) => {
					self.socket.syncUpdates('task', self.tasks, self.onSocketEvent.bind(self), self);
					let filter = {};
					let hasPrivilege = self.Auth.hasPrivilegeSync('task.index',undefined,filter);
					if(hasPrivilege && filter && filter.filterParam && filter.filterParam.field == 'user') {
						self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().ref}:${filter.filterParam.data}:*:tasks`);
						self.rooms.push(`${self.Auth.getCurrentAccountSync().ref}:${filter.filterParam.data}:*:tasks`);
					}else{
						self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().ref}:*:*:tasks`);
						self.rooms.push(`${self.Auth.getCurrentAccountSync().ref}:*:*:tasks`);
					}
					self.joined = true;
					return tasks;
				});
			}
		} else {
			if(self.cacheTimeout) {
				clearTimeout(self.cacheTimeout);
				self.cacheTimeout = undefined;
			}
			if(noGet) {
				return null;
			}else {
				return self.getTasks();
			}
		}
	}

	destroyService() {
		let self = this;
		if (self.joined) {
			self.socket.unsyncUpdates('task', self);
			if(self.rooms && self.rooms.length > 0) {
				self.rooms.forEach( (room) => {
					self.socket.leaveRoom(room);
				} );
				self.rooms = [];
			}
		}
		self.joined = false;
		self.tasks = [];
		self.listenerMap.clear();
		clearTimeout(self.cacheTimeout);
		self.cacheTimeout = undefined;
	}

	unregisterListener(context, cb) {
		let self = this;
		if (!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
		if(!self.lastUser) {
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		} else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
			self.destroyService();
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		}
		self.listenerMap.delete(context);
		if (self.listenerMap.size === 0) {
			self.cacheTimeout = setTimeout(self.destroyService.bind(self), 5000);
		}
	}

	unregisterAll() {
		let self = this;
		if (!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
		if(!self.lastUser) {
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		} else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
			self.destroyService();
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		}
		self.listenerMap.clear();
		if (self.listenerMap.size === 0) {
			self.cacheTimeout = setTimeout(self.destroyService.bind(self), 5000);
		}
	}

	getTasks() {
		let self = this;
		if (self.tasks.length > 0) {
			return new Promise(function(resolve) {
				resolve(_.cloneDeep(self.tasks));
			});
		}
        let lastAccount = _.cloneDeep(self.lastAccount);
        let lastUser = _.cloneDeep(self.lastUser);
		return this.Restangular.all('tasks').getList()
			.then((tasks) => {
				if(lastAccount == self.lastAccount && lastUser == self.lastUser) {
					self.tasks = tasks;
					return _.cloneDeep(tasks);
				}else {
					return [];
				}
			});
	}

    getTasksWithQuery(query) {
        let self = this;
        return this.Restangular.all('tasks').customGET('', query)
        .then((response) => {
            return _.cloneDeep(response);
        });
    }

    countTasksWithQuery(query) {
        let self = this;
        return this.Restangular.all('tasks').customGET('count', query)
        .then((response) => {
            return _.cloneDeep(response);
        });
    }

    removeTask(task) {
        let self = this;
        if(typeof task.remove === 'function') {
            return task.remove().then((response) => {
                // self.toastr.info('Task removed.');
								return response;
            })
.catch((err) => {
                console.error(err);
                self.toastr.error('Site remove failed.');
								return err;
            });
        }else{
            return self.Restangular.one('tasks',task._id).remove()
.then((response)=>{
                // self.toastr.info('Site removed.');
								return response;
            })
.catch((err) => {
                console.error(err);
                self.toastr.error('Site remove failed.');
								return err;
            });
        }
    }

}

export default angular.module('secutraqApp.dashboard')
	.service('taskService', TaskService);
