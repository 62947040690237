import angular from 'angular';
import uiRouter from 'angular-ui-router';
import routes from './fieldReports.routes';
import uiBootstrap from 'angular-ui-bootstrap';
import NgTable from 'ng-table/bundles/ng-table';
import ngSanitize from 'angular-sanitize';


export default angular.module('secutraqApp.fieldReports', [uiRouter, 'ngTable', uiBootstrap])
  .config(routes)
  .name;
require('./reports/reports.component.js');
require('./reports/view-report/view-report.modal.component.js');
require('./templates/templates.component.js');
require('./templates/new-template/new-template.modal.component.js');
