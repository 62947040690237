export class ShiftService {
	/*@ngInject*/
	constructor($uibModal, moment, Restangular, socket, Auth) {
		let self = this;
		this.$uibModal = $uibModal;

		this.shifts = [];
		this.moment = moment;
		this.Restangular = Restangular;
		this.listenerMap = new Map();
		this.socket = socket;
		this.Auth = Auth;
		this.cacheTimeout = undefined;
		this.lastAccount = undefined;
		this.lastUser = undefined;
		this.joined = false;
		this.rooms = [];

		// self.generateRoute();


	}

	onSocketEvent(event, item, array) {
		let self = this;
		self.shifts = array;
		self.listenerMap.forEach((value, key) => {
			setTimeout(() => {
				value(event, item, array);
			}, 0);
		});
	}

	registerListener(context, cb, noGet) {
		let self = this;
		if (!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
		if(!self.lastUser) {
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		} else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
			self.destroyService();
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		}
		let count = self.listenerMap.size;
		self.listenerMap.set(context, cb);
		// If listenermap was empty, (TODO and timer isn't running), join room, syncupdates
		if (!self.joined) {
			if (self.cacheTimeout) {
				clearTimeout(self.cacheTimeout);
				self.cacheTimeout = undefined;
			}
			let filter = {};
			let hasPrivilege = self.Auth.hasPrivilegeSync('event.index',undefined,filter);
			if(noGet) {
				self.socket.syncUpdates('shift', self.shifts, self.onSocketEvent.bind(self), self);
				if(hasPrivilege && filter && filter.filterParam && filter.filterParam.field == 'groups') {
					filter.filterParam.data.forEach( (group) => {
						self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().ref}:*:shifts:${group}`);
						self.rooms.push(`${self.Auth.getCurrentAccountSync().ref}:*:shifts:${group}`);
					} );
				}else{
					self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().ref}:*:shifts`);
					self.rooms.push(`${self.Auth.getCurrentAccountSync().ref}:*:shifts:${group}`);
				}
				return null;
			}else {
				return self.getShifts().then((shifts) => {
					self.socket.syncUpdates('shift', self.shifts, self.onSocketEvent.bind(self), self);
					if(hasPrivilege && filter && filter.filterParam && filter.filterParam.field == 'groups') {
						filter.filterParam.data.forEach( (group) => {
							self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().ref}:*:shifts:${group}`);
							self.rooms.push(`${self.Auth.getCurrentAccountSync().ref}:*:shifts:${group}`);
						} );
					}else{
						self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().ref}:*:shifts`);
						self.rooms.push(`${self.Auth.getCurrentAccountSync().ref}:*:shifts`);
					}
					self.joined = true;
					return shifts;
				});
			}
		} else {
			if(self.cacheTimeout) {
				clearTimeout(self.cacheTimeout);
				self.cacheTimeout = undefined;
			}
			if(noGet) {
				return null;
			}else {
				return self.getShifts();
			}
		}
	}

	destroyService() {
		let self = this;
		if (self.joined) {
			self.socket.unsyncUpdates('shift', self);
			if(self.rooms && self.rooms.length > 0) {
				self.rooms.forEach( (room) => {
					self.socket.leaveRoom(room);
				} );
				self.rooms = [];
			}
		}
		self.joined = false;
		self.shifts = [];
		self.listenerMap.clear();
		clearTimeout(self.cacheTimeout);
		self.cacheTimeout = undefined;
	}

	unregisterListener(context, cb) {
		let self = this;
		if (!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
		if(!self.lastUser) {
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		} else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
			self.destroyService();
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		}
		self.listenerMap.delete(context);
		if (self.listenerMap.size === 0) {
			self.cacheTimeout = setTimeout(self.destroyService.bind(self), 5000);
		}
	}

	unregisterAll() {
		let self = this;
		if (!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
		if(!self.lastUser) {
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		} else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
			self.destroyService();
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		}
		self.listenerMap.clear();
		if (self.listenerMap.size === 0) {
			self.cacheTimeout = setTimeout(self.destroyService.bind(self), 5000);
		}
	}

	getShifts() {
		let self = this;
		if (self.shifts.length > 0) {
			return new Promise(function(resolve) {
				resolve(_.cloneDeep(self.shifts));
			});
		}
        let lastAccount = _.cloneDeep(self.lastAccount);
        let lastUser = _.cloneDeep(self.lastUser);
		return this.Restangular.all('shifts').getList()
			.then((shifts) => {
				if(lastAccount == self.lastAccount && lastUser == self.lastUser) {
					self.shifts = shifts;
					return _.cloneDeep(shifts);
				}else {
					return [];
				}
			});
	}

    getShiftWithQuery(query) {
        let self = this;
        return this.Restangular.all('shifts').customGET('', query)
        .then((response) => {
            return _.cloneDeep(response);
        })
.catch((err) => {
            console.error(err);
        });
    }

	disableShift(shiftId) {
        let self = this;
        return this.Restangular.one('shifts',shiftId).patch({active:false});
	}

	disableShifts(shiftIds) {
		let self = this;
		let promises = [];
		shiftIds.forEach( (shiftId) => {
			let p = self.disableShift(shiftId);
			promises.push(p);
		} );
		return Promise.all(promises);
	}

}

export default angular.module('secutraqApp.dashboard')
	.service('shiftService', ShiftService);
