export class ResetPasswordRequestComponent {
	/*@ngInject*/
	constructor($stateParams, Restangular, $http, $window) {
		this.$stateParams = $stateParams;
		this.url = this.$stateParams.next;
		this.Restangular = Restangular;
		this.$http = $http;
		this.$window = $window;
		this.requested = false;
		this.requesting = false;
		this.failed = false;
		this.email = '';
		this.changed = false;
		this.changing = false;

	}

	$onInit() {
		this.hostnameIcon = null;

		this.$http.get(`assets/images/domainicons/${this.$window.location.hostname}.png`)
			.then(data => {
				this.hostnameIcon = `assets/images/domainicons/${this.$window.location.hostname}.png`;
			})
.catch( (err) => {
				this.hostnameIcon = null;
			} );
	}

	forgotPassword(form) {
		this.requesting = true;
		this.requested = false;
		this.failed = false;
		if(form.$valid) {
			this.Restangular.all('users').get('forgot',{email:this.email})
				.then((/*value, requestHeaders*/) => {
					this.requested = true;
					this.requesting = false;
				})
.catch( () => {
					this.failed = true;
					this.requesting = false;
				} );
		}
	}
}

export default angular.module('secutraqApp.reset')
	.component('resetpasswordrequest', {
		template: require('./reset.password.request.html'),
		controller: ResetPasswordRequestComponent,
		controllerAs: '$ctrl'
	})
	.name;
