export class NewTemplateComponent {
    /*@ngInject*/
    constructor($timeout, toastr, $ngConfirm, $uibModal, $scope, eventService, moment, Auth, Restangular, fieldReportTemplateService, customEventTypeService) {
        this.$timeout = $timeout;
        this.$ngConfirm = $ngConfirm;
        this.$uibModal = $uibModal;
        this.$scope = $scope;
        this.eventService = eventService;
        this.moment = moment;
        this.Auth = Auth;
		this.Restangular = Restangular;
		this.fieldReportTemplateService = fieldReportTemplateService;
		this.toastr = toastr;
		this.customEventTypeService = customEventTypeService;

    }

    $onInit() {
        let self = this;

        /**
         * NPM-installed angular-carousel.
         * See the .html for an idea how the directive works.
         * The index of the carousel assumes an ng-model on this variable
         * @type {Number}
         */
        self.fieldsCarouselIndex = 0;

        if(self.resolve.edit) {
            self.template = _.cloneDeep(self.resolve.template);
			// TODO: Get event type for template <13-04-20, Liaan> //
			self.customEventTypeService.getCustomEventTypeByID(self.template.eventTypeRef).then( (eventType) => {
				self.customEventTypeClone = _.cloneDeep(eventType);
				self.customEventType = eventType;
				self.customEventOptions = {
					edit:true,
					report:true,
					report:self.template,
					customEventType:self.customEventType
				};
			} )
.catch( (err) => {
				console.error(err);

			} );
        } else {
			self.template = {
				type:'',
				fields:[{title: '', description: '', type: 'Text'}]
			};
			self.customEventType = {eventType:self.template.type};
			self.customEventOptions = {
				edit:false,
				report:self.template,
				customEventType:self.customEventType,
			};
        }
        self.eventTypes = self.eventService.getEventTypesSync();
		self.availableInputTypes = [
			'Text',
		   	'Check List',
		   	'Radio List',
		   	'Photos',
			//'Videos',
			   //'Audio',
			//'Date',
			//'Date Range'
		];
		self.availableListOptions = {};
	}

    newField() {
        let self = this;
        if(self.fieldsCarouselIndex + 1 === self.template.fields.length) {
            self.template.fields.push({title: '', description: '', type: 'Text'});
            self.fieldsCarouselIndex++;
        } else {
            self.template.fields.push({title: '', description: '', type: 'Text'});
        }
    }

    removeField(index) {
        let self = this;
        if(index !== -1) {
            let first = index === 0;
            let last = index === self.template.fields.length - 1;
            self.template.fields.splice(index, 1);
            if(!first) {
                self.fieldsCarouselIndex--;
            }
            if(first && last) {
                self.newField();
            }
        }
    }

    closeModal() {
        let self = this;
		// TODO: Create/update template  <31-03-20, Liaan> //
		if(self.resolve.edit) {
			let eventPromise = Promise.resolve(self.customEventType);
			//Check if title changed and create new customEventType
			self.customEventType.eventType = self.template.type;
			if(self.customEventType.eventType != self.customEventTypeClone.eventType) {
				eventPromise = new Promise( (resolve, reject) => {
					self.$ngConfirm({
						title:`<b>Change Event Type?</b>`,
						content:"Are you sure you want to change the event type accosiated with this report?  This will create a new event type and all previous reports will remain under the old event type.",
						escapeKey:true,
						backgroundDismiss:true,
						buttons: {
							ok:{
								text:"Yes",
								btnClass: 'btn-primary',
								action(scope) {
									delete self.customEventType._id;
									resolve(self.customEventTypeService.saveNewCustomEventType(self.customEventType));
								}
							},
							close: {
								text: "Cancel",
								// btnClass: 'btn-primary',
								action(scope) {
									resolve(null);
								}
							}
						}
					});
				} );
			}else if(self.customEventType.color != self.customEventTypeClone.color || self.customEventType.icon != self.customEventTypeClone.icon) {
				eventPromise = self.customEventTypeService.updateCustomEventType(self.customEventType);
			}

			//Check if customEventType changed and update customEventType

			eventPromise.then( (savedEventType) => {
				if(savedEventType) {
					self.template.eventTypeRef = savedEventType._id;
					return self.fieldReportTemplateService.updateFieldReportTemplate(self.template);
				}else{
					return null;
				}
			} )
			.then( (savedTemplate) => {
				if(savedTemplate) {
					self.toastr.info('Template updated');
					self.modalInstance.close(true);
				}
			} )
.catch( (err) => {
				self.toastr.error('Template update failed');
				console.error(err);
			} );
		}else{

			//Create new custom event type

			self.customEventOptions.customEventType.eventType = self.template.type;
			self.customEventTypeService.saveNewCustomEventType(self.customEventOptions.customEventType).then( (savedEventType) => {
				self.template.eventTypeRef = savedEventType._id;
				return self.fieldReportTemplateService.saveNewFieldReportTemplate(self.template);
			} )
			.then( () => {
				self.toastr.info('Template created');
				self.modalInstance.close(true);
			} )
.catch( (err) => {
				self.toastr.error('Template create failed');
				console.error(err);
			} );
		}
    }


    doLog() {
        console.debug(this);
    }

    dismissModal() {
        let self = this;
        self.modalInstance.dismiss();
    }

	fieldTypeChanged(field) {
		let self = this;
		switch(field.type) {
			case 'Radio List': {
				field.options = {
					listOptions:[]
				};
			}
			break;
			case 'Check List': {
				field.options = {
					min:1,
					max:undefined,
					listOptions:[]
				};
			}
			break;
			case 'Photos': {
				field.options = {
					min:1,
					max:undefined,
				};
			}
			break;
			default:
				field.options = undefined;
		}
	}

}
export default angular.module('secutraqApp.fieldReports')
.component('newFieldReportTemplate', {
    template: require('./new-template.modal.html'),
    controller: NewTemplateComponent,
    controllerAs: "$ctrl",
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
})
.name;
