export class WizardService {
	/*@ngInject*/
	constructor($timeout) {
        this.$timeout = $timeout;
	}

	/**
	 * Writing this to manipulate our current active step number.
	 * TODO: Finish this function to not only handle 'prev' and 'next'
	 */
	goToWizardStep(stepNumber) {
		let self = this;
		let elems = angular.element(document.getElementsByClassName('navi-circles'));
		let activeEl = elems.find('li.active');

		if(stepNumber === 'next') {
			let nextEl = activeEl.next();
			let roundTab = nextEl.find('.round-tab');
			self.$timeout(() => {
				roundTab.triggerHandler('click');
			});
		} else if(stepNumber === 'prev') {
			let prevElNode = activeEl[0].previousElementSibling;
			let prevEl = angular.element(prevElNode);
			self.$timeout(() => {
				let roundTab = prevEl.find('.round-tab');
				roundTab.triggerHandler('click');
			});
		}
	}


}

export default angular.module('secutraqApp.dashboard')
	.service('wizardService', WizardService);
