'use strict';

export function authInterceptor($rootScope, $q, $injector, Util) {
	'ngInject';

	var state;
	let refresh = function(Auth , toastr) {
		Auth.refreshToken();
		Auth.refreshUser().then((user) => {
			// Auth.setCurrentAccount();
			// $rootScope.$emit("accountChanged");
			if (toastr) {
				toastr.info("Your privileges have been updated. ", 'Privileges Updated',{
					preventOpenDuplicates:true,
				});
			}
		});
	};
	let refreshTh = _.throttle(refresh, 1000, { trailing: false });
	return {
		// Add authorization token to headers
		request(config) {
			let Auth = $injector.get('Auth');
			let $stateParams = $injector.get("$stateParams");

			config.headers = config.headers || {};

			// TODO: The following code might be improved slightly. Consider if doing so
			// would justify the benefit
			if($stateParams.accountID) {
				config.headers["X-JS-ACCOUNT"] = $stateParams.accountID;
			}else if (!config.headers["X-JS-ACCOUNT"]) {
				if(Auth.getCurrentAccountSync()) {
					config.headers["X-JS-ACCOUNT"] = Auth.getCurrentAccountSync().ref;
				}
			}

			return config;
		},

        response(response) {
            var Auth = $injector.get("Auth");
			var toastr = $injector.get('toastr');
			if(response.headers('X-JS-REFRESH-TOKEN') === 'true') {
				refreshTh(Auth, toastr);

			}
			return response;
		},

		/**
		 * Intercept 401s and redirect you to login
		 */
		responseError(response) {
			var Auth = $injector.get("Auth");
			var toastr = $injector.get('toastr');
			if (response.status === 401) {
		(state || (state = $injector.get('$state')))
		.go('login')
		.catch((err) => {
		});
	}else if(response.status === 403) {
		refreshTh(Auth);
		// toastr.error("You do not have authorization for this action", "Access Forbidden",{preventOpenDuplicates:true});

            } else if(response.status === 307 && response.data && response.data.mfa) {
                var $uibModal = $injector.get("$uibModal");
                var $uibModalStack = $injector.get("$uibModalStack");
                $uibModalStack.dismissAll();
                let modal = $uibModal.open({
                    component: 'totp',
                    backdrop: 'static',
                    keyboard: false,
                    resolve:{
                        mfa:{enabled:true,enroled:false},
                        modal:true,
                        forced:true
                    }
                });

            }
            return $q.reject(response);
        }
  };
}
