export class MapViewComponent {
    /*@ngInject*/
    constructor($scope, $compile, $window, $timeout, mapService, siteService, genericMapService, $localStorage) {
        this.$scope = $scope;
        this.$compile = $compile;
        this.$timeout = $timeout;
        this.mapService = mapService;
        this.siteService = siteService;
        this.genericMapService = genericMapService;
        this.$window = $window;
        this.$localStorage = $localStorage;

    }

    $onDestroy() {
        let self = this;
        $(window).off('resize.mapView');
        self.map.remove();
        self.map = undefined;
        self.siteService.unregisterListener(self);

    }

    $onInit() {
        let self = this;

        self.mapServiceMetaData = self.mapService.getMetaDataSync();

        /**
         * We registerListener @ siteService to get our list of sites
         * @type {Array}
         */
        self.sites = [];

        /**
         * This is the object that we use that will contain all the leaflet-layers
         * @type {Object}
         */
        self.siteLayers = {};
        let clusterMarkersOptions = true;
        if(self.$localStorage.enableClustering) {
            clusterMarkersOptions = {enabled : true};
        }
		let showSites = true;
		if(self.$localStorage.showSites == undefined) {
			self.$localStorage.showSites = true;
		}
        if(self.$localStorage.showSites) {
            showSites = {enabled : true};
        }

        self.map = self.genericMapService.generateMap('map',{
            zoomControl:false,
            satellite : true,
            geocoder:{position:'topright'},
            zoomFunction : self.mapService.zoomToAll.bind(self.mapService),
            showSites,
            clusterMarkers : clusterMarkersOptions,
        });
        self.map.on('baselayerchange',() => {
            let googlemutant = $(".leaflet-google-mutant")[0];
            let mapHeight = $("#map")[0].clientHeight;
            $(googlemutant).height("100%");
        });

        self.$timeout(() => {
            self.map.invalidateSize();
        });


        new L.Control.Zoom({position: 'bottomright'}).addTo(self.map);
        self.map.setView([-29.0146, 25.0950], 7);

        /**
         * This is the leafletLayer that will contain the shapes of our sites
         * @type {L}
         */
        self.allSitesLayer = new L.layerGroup();
        self.mapServiceMetaData.layers.allSitesLayer = self.allSitesLayer;

        /**
         * This is the leafletLayer that will contain each site's marker
         * @type {L}
         */
        self.allSitesMarkers= new L.layerGroup();
        self.mapServiceMetaData.layers.allSitesMarkers = self.allSitesMarkers;

        /**
         * animateDragging is our own animation that we create. It gets used when we drag markers
         * on the map. NOTE that we're currently not dragging any markers. But I'm keeping this just
         * in case we might need it sometimte
         * @return {[type]} [description]
         */
        L.Marker.prototype.animateDragging = function() {
            let iconMargin; let shadowMargin;
            this.on('dragstart', function() {
                if (!iconMargin) {
                    iconMargin = parseInt(L.DomUtil.getStyle(this._icon, 'marginTop'));
                    // shadowMargin = parseInt(L.DomUtil.getStyle(this._shadow, 'marginLeft'));
                }
                this._icon.style.marginTop = `${iconMargin - 15}px`;
                // this._shadow.style.marginLeft = (shadowMargin + 8) + 'px';
            });
            return this.on('dragend', function() {
                this._icon.style.marginTop = `${iconMargin}px`;
                // this._shadow.style.marginLeft = shadowMargin + 'px';
            });
        };

        /**
         * Here's what's going on below:
         * I need to stretch the map in its length to fill the entire screen. To do that I calculated
         * the difference in pixels from some elements (see below).
         * Since our tabs (Events, Attendance, Schedule, Filters, etc) are on the same level as our
         * map we also want to restrict their max-height to not exceed that of our map
         * @type {[type]}
         */
        self.$timeout(() => {
            $(window).on("resize.mapView", function() {
                self.map.invalidateSize();
				return null;
                let subtabsOffset = $("#subtabHeadings").offset().top;
                let subtabsHeight = document.getElementsByClassName("subtabs")[0].clientHeight;
                let footerHeight = $("#page-footer").height();
                let windowHeight = $(window).height();
                let widgetGridHeight = document.getElementById("widget-grid").clientHeight;
                let height = windowHeight - footerHeight - subtabsOffset - subtabsHeight - widgetGridHeight;

                $("#subheaderTabs").css('height', height);

                //$("#Events").css('max-height', (height-widgetGridHeight));
                //// $("#eventDetailContainer").css('max-height', (height));
                //$("#selectedEventScheduleTable").css('max-height', (height/2.5));
                //$("#Attendance").css('max-height', (height));
                //$("#Schedule").css('max-height', (height));
                //$("#Routes").css('max-height', (height));
                //$("#Photos").css('max-height', (height));
                //$("#Videos").css('max-height', (height));
                //$("#Maps").css('max-height', (height));
                //$("#Settings").css('max-height', (height));
				//$("#map").height(height);
                //self.$timeout(() => {
                    //let googlemutant = $(".leaflet-google-mutant")[0];
                    //$(googlemutant).height(height);
                //},0)

            })
.trigger("resize");
        });

        let clickedAccordionEvent = '';

        self.mapService.setMap(self.map);
        // self.mapService.setDrawControl(drawControl);
        // self.mapService.setSitesFeatureGroup(sites);
        // self.mapService.setRoutesFeatureGroup(routes);
        // **********************LEAFLET******************** //

        self.siteService.registerListener(self, (event, item, array) => {
            if (event === 'deleted') {
                self.onRemoveSite(item);
            } else {
                self.onReceiptOfSite(item);
            }
        }).then((sites) => {
            _.forEach(sites, (site) => {
                self.onReceiptOfSite(site);
            });
        });

    }

    /**
     * We registerListener for sites during $onInit
     */
    onReceiptOfSite(newSite) {
        let self = this;
        let index = _.findIndex(self.sites, (site) => {
            return newSite._id === site._id;
        });

        if(index === -1) {
            //We don't have this site so we have to draw it onto our map it
            self.sites.push(newSite);
            //draw
            self.drawSite(newSite);
        } else {
            //We have this site, but this is a new one,which means
            //Remove previous

            //Add new
            self.sites.splice(index, 1, newSite);
        }
    }

    onRemoveSite(site) {
        let self = this;

    }

    /**
     * We're calling this from two scenarios, namely
     *  1) On initial load we draw all the sites in our list of sites
     *  2) When a site has been edited or created, we are called to redraw/draw it
     */
    drawSite(site) {
        let self = this;
        let siteID = site._id;
        let primaryIsDrawn = false;
        self.siteLayers[siteID] = {marker: {}, shapes: []};
		try {
			_.forEach(site.locs, (shape) => {
				if(shape.loc.length !== 0) {
					if(shape.loc.length === 1) {
						//If the site only has one location, therefore it is a ..dot.
						//This won't be able to happen and I should remove this
						//TODO: The above comment
						// self.placeMarkerAtCoordinates(shape.loc[0], site);
					} else {
						let polygon = self.drawPolygonFromBounds(shape.loc, site, shape.color, shape.weight);
						// let circle = self.drawCircleFromSite(site);
						self.siteLayers[siteID].shapes.push(polygon);
						// self.siteLayers[siteID]['shapes'].push(circle);

						//Only giving the center-marker to the primary shape
						if(!primaryIsDrawn) {
							let center = polygon.getBounds().getCenter();
							self.siteLayers[siteID].marker = L.marker(center, {icon: self.genericMapService.getSpecifiedMarkerSync('site')}).addTo(self.allSitesMarkers);
							let centerMarker = self.siteLayers[siteID].marker;

							let html = `
							<div ng-init="site = ($ctrl.sites | filter:{_id:'${site._id}'})[0]">
							<h4 style="text-align:center;margin-bottom:0.5em">{{site.name}} </h4>
							<button style="margin-left:0.5em;" class="btn btn-sm btn-info" ng-click="$ctrl.requestSiteSnapshots('${site._id}')">
							<i class="fa fa-fw fa-camera"></i>Request Photos
							</button>
							</div>`;
							let linkFunction = self.$compile(angular.element(html));
							let element = linkFunction(self.$scope);
							let popup = L.popup().setContent(element[0]);
							centerMarker.bindPopup(popup);
							primaryIsDrawn = true;
						}
					}
				}
			});
		} catch (e) {
			/* handle error */
			console.error(e);
		}

        // //adding the notes/labels
        // _.forEach(site.notes, (note) => {
        //     let marker = L.marker(note.loc, {opacity: 0, draggable: false, icon: self.mapMarker});
        //     marker.bindTooltip(note.content, {direction: 'center', permanent: true, className: 'site-data-label', offset: [10,-5]}).addTo(self.allSitesLayer);
        //     //Have to put this in a timeout, otherwise when Leaflet tries to add the class, it gets a classlist of undefined
        //     self.$timeout(() => {
        //         L.DomUtil.addClass(marker._icon, 'leaflet-note-class');
        //     });
        // });
    }

	requestSiteSnapshots(siteId) {
		let self = this;
		self.siteService.requestSiteSnapshots({_id:siteId},self.$scope);
	}

    /**
     * This function is being called from @see drawSite()
     * when it receives a site to draw
     */
    drawPolygonFromBounds(bounds, site, color, weight) {
        let self = this;
        let id = site._id;
        let drawingColor = color ? color : '#2c09DE';
        let drawingWeight = weight ? weight : 6;
        // TODO: color  from siteDefinition
        let polygon = L.polygon(bounds, {color: drawingColor, weight: drawingWeight}).addTo(self.allSitesLayer);
		let html = `
		<div >
		<h4 style="text-align:center;margin-bottom:0.5em">{{($ctrl.sites | filter:{_id:"${site._id}"})[0].name}} </h4>
		<button style="margin-left:0.5em;" class="btn btn-sm btn-info" ng-click="$ctrl.requestSiteSnapshots('${site._id}')">
		<i class="fa fa-fw fa-camera"></i>Request Photos
		</button>
		</div>`;
		let linkFunction = self.$compile(angular.element(html));
		let element = linkFunction(self.$scope);
		let popup = L.popup().setContent(element[0]);

		polygon.bindPopup(popup);
        return polygon;
    }

	doLog() {
		console.debug(this);
	}
    /**
     * This function is being called from @see drawSite()
     * when it receives a site to draw
     */
    drawCircleFromSite(site) {
        let self = this;
        if(!site || !site.centerPoint || !site.radius) {
            return;
        }
        let id = site._id;
        let drawingColor = '#9B1A1F';
        let drawingWeight = 3;
        // TODO: color  from siteDefinition
        let circle = L.circle(L.latLng(site.centerPoint.coordinates[1],site.centerPoint.coordinates[0]), {radius: site.radius,color: drawingColor, weight: drawingWeight}).addTo(self.allSitesLayer);
        // let popup = L.popup().setContent(`<h4>${site.name}</h4>`);
        // polygon.bindPopup(popup);
        return circle;
    }

    /**
     * We currently calling this function when a site has been
     * updated --> @see $on('drawSiteEdited')
     */
    removeSite(site) {
        let self = this;
        let id = site._id;
        let siteLayers = self.layers[id].layers;

        _.forEach(siteLayers, (layer) => {
            if(self.allSitesLayer.hasLayer(layer)) {
                self.allSitesLayer.removeLayer(layer);
            }
        });

        _.forEach(self.layers[id].circles, (layer) => {
            if(self.allSitesCircles.hasLayer(layer)) {
                layer.unbindTooltip();
                self.allSitesLayer.removeLayer(layer);
            }
        });

        //TODO: be able to remove this if
        if(Object.keys(self.layers[id].marker).length !== 0) {
            if(self.allSitesMarkers.hasLayer(self.layers[id].marker)) {
                self.allSitesMarkers.removeLayer(self.layers[id].marker);
            }
        }
        delete self.layers[id];
    }

    /**
    * When a marker has been dropped onto the valid drop-area this function handles
    * the event and placement of the marker.
    * NOTE: This function would most probably also receive information pertaining to the guard
    *       as a parameter
    * NOTE: I'm not too sure how, what and where this will be implemented, but this is the
    *       functionality of being able to drag and drop something onto the map
    */
    onGuardDropped(event) {
        let self = this;
        self.mapService.onGuardDropped(event);
    }
}
export default angular.module('secutraqApp.dashboard')
.component('appMapView', {
    template: require('./mapview.component.html'),
    controller: MapViewComponent,
    controllerAs: '$ctrl',
    bindings: {

    }
});
