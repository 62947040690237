'use strict';
// @flow
export class SopListComponent {
	/*@ngInject*/
	constructor(Auth, NgTableParams, $uibModal, $ngConfirm, $stateParams, accountService, $timeout, siteService, toastr, moment) {
		this.Auth = Auth;
		this.NgTableParams = NgTableParams;
		this.$uibModal = $uibModal;
		this.$ngConfirm = $ngConfirm;
		this.$stateParams = $stateParams;
		this.accountService = accountService;
		this.$timeout = $timeout;
		this.siteService = siteService;
		this.toastr = toastr;
		this.moment = moment;
	}

	$onInit() {
		let self = this;
		self.openedSOP = {};
		self.openedSOPType = {};

		// self.currentAccount;
		self.toggledSites = {};

	}

	toggleShowSites(sop) {
		let self = this;

		if(!self.toggledSites[sop._id]) {
			self.toggledSites[sop._id] = {show: true, sites: []};
			self.siteService.getSitesWithQuery({query: sop._id, field:'sops'}).then((results) => {
				try {
					_.forEach(results, (site) => {
						self.toggledSites[sop._id].sites.push({name: site.name, _id: site._id});
					});
				} catch (e) {
					console.error(e);
				}
			});
		} else {
			self.toggledSites[sop._id].show = false;
			// TODO: Just think if this makes sense because I can't think right now
			self.$timeout(() => {
				self.toggledSites[sop._id] = undefined;
			},200);
		}
	}


	newSOP() {
		let self = this;
		self.accountService.createNewSOP()
		.then((result) => {

			//Array of IDs
			let sites = result.appliedSites;
			delete result.appliedSites;

			self.accountService.getAccountByID(self.Auth.getCurrentAccountSync().ref).then((account) => {
				if(!account.sopTemplates) {
					account.sopTemplates = [];
				}

				let eventAccountTypeSOPs = _.filter(account.sopTemplates, (sopTemplate) => {
					return sopTemplate.eventType === result.eventType;
				});

				if(eventAccountTypeSOPs.length === 0) {
					result.default = true;

	                self.toastr.info(`New <b>DEFAULT</b> SOP. created`, {
	                    closeButton: true,
	                    tapToDismiss: true,
						allowHtml: true
	                });
				} else if(result.default) {
		                self.toastr.info(`New <b>DEFAULT</b> SOP. created`, {
		                    closeButton: true,
		                    tapToDismiss: true,
							allowHtml: true
		                });

						_.forEach(account.sopTemplates, (sop) => {
							if(sop.eventType === result.eventType) {
								sop.default = false;
							}
						});
					}
				account.sopTemplates.push(result);
				self.accountService.updateAccount(account).then((newAccount) => {
					let sopID = newAccount.sopTemplates[newAccount.sopTemplates.length-1]._id;
					//update the sites
					_.forEach(sites, (siteID) => {
						self.siteService.getSiteByID(siteID).then((site) => {
		                    let siteSpecificSOPs = _.filter(newAccount.sopTemplates,(accSOP) => {
		                        let index = _.findIndex(site.sops, (storedSiteSOPId) => {
		                            return storedSiteSOPId == accSOP._id;
		                        });
		                        if(index > -1) {
		                            return true;
		                        }
		                    });

							let eventTypeSOPs = _.filter(siteSpecificSOPs, (siteSOP) => {
								return siteSOP.eventType === result.eventType;
							});

							if(eventTypeSOPs.length > 0) {
								console.error("Trying to insert an SOP for a site that already has one defined for this EVENTTYPE");
								//can't insert it -- already contains an SOP of this perticular type
							} else {
								site.sops.push(sopID);
								self.siteService.updateSite(site);
							}
						});
					});
				});
			});
		});
	}

	editSOP(sop) {
		let self = this;
		self.accountService.editSOP(sop)
		.then((result) => {
			// Array of IDs

			let removedSites = result.removedSites;
			delete result.removedSites;
			let sites = result.appliedSites;
			delete result.appliedSites;
			self.accountService.getAccountByID(self.Auth.getCurrentAccountSync().ref).then((account) => {
				if(!account.sopTemplates) {
					account.sopTemplates = [];
				}

				let eventAccountTypeSOPs = _.filter(account.sopTemplates, (sopTemplate) => {
					return sopTemplate.eventType === result.eventType;
				});

				if(eventAccountTypeSOPs.length === 0) {
					result.default = true;
				} else if(result.default) {
						_.forEach(account.sopTemplates, (sop) => {
							if(sop.eventType === result.eventType) {
								sop.default = false;
							}
						});
					} else {
						let defaultSOP = _.find(eventAccountTypeSOPs, (sop) => {
							return sop.default;
						});

						if(defaultSOP._id === result._id) {
							result.default = true;
							// TODO: ng-toastr for default NOT being changed
			                self.toastr.info(`The edited SOP. was changed to be the <b>DEFAULT </b>SOP.`, {
			                    closeButton: true,
			                    tapToDismiss: true,
								allowHtml: true
			                });
							self.$timeout(() => {
								self.toastr.info(`Please choose another SOP. to be the <b>DEFAULT </b>by either creating a new SOP. or by editing an existing one`, {
									closeButton: true,
									tapToDismiss: true,
									allowHtml: true
								});
							}, 3500);
						}


					}

				let idx = _.findIndex(account.sopTemplates, (sopTemplate) => {
					return sopTemplate._id === result._id;
				});
				account.sopTemplates.splice(idx, 1, result);
				self.accountService.updateAccount(account).then((newAccount) => {
					let sopID = newAccount.sopTemplates[newAccount.sopTemplates.length-1]._id;
					//update the sites
					_.forEach(sites, (siteID) => {
						self.siteService.getSiteByID(siteID).then((site) => {
		                    let siteSpecificSOPs = _.filter(newAccount.sopTemplates,(accSOP) => {
		                        let index = _.findIndex(site.sops, (storedSiteSOPId) => {
		                            return storedSiteSOPId == accSOP._id;
		                        });
		                        if(index > -1) {
		                            return true;
		                        }
		                    });

							let eventTypeSOPs = _.filter(siteSpecificSOPs, (siteSOP) => {
								return siteSOP.eventType === result.eventType;
							});
							if(eventTypeSOPs.length > 0) {
								//can't insert it -- already contains an SOP of this perticular type
							} else {
								site.sops.push(sopID);
								self.siteService.updateSite(site);
							}
						});
					});
					_.forEach(removedSites, (removedSite) => {
						self.siteService.getSiteByID(removedSite).then((site) => {
							let index = _.findIndex(site.sops, (sop) => {
								return sop === result._id;
							});
							if(index > -1) {
								site.sops.splice(index, 1);
								self.siteService.updateSite(site);
							} else {
								console.error("Could not find SOP in site");
							}
						});
					});
				});
			})
			.catch((err) => {
				console.error("Error: ", err);
			});
		});
	}

	removeSOP(sop) {
		let self = this;
		self.$ngConfirm({
			title: `<b>Remove this SOP.</b>?`,
			theme: 'supervan',
			content: `Clicking <b>YES</b> would remove this SOP.`,
			escapeKey: true,
			backgroundDismiss: true,
			buttons: {
				// long hand button definition
				ok: {
					text: "Yes",
					btnClass: 'btn-primary',
					keys: ['enter'], // will trigger when enter is pressed
					action(scope) {
						self.siteService.getSitesWithQuery({query: sop._id, field:"sops"}).then((results) => {
							_.forEach(results, (site) => {
								let index = _.findIndex(site.sops, (siteSOP) => {
									return siteSOP === sop._id;
								});
								if(index !== -1) {
									site.sops.splice(index, 1);
									self.siteService.updateSite(site);
								}
							});
						});
						self.accountService.getAccountByID(self.Auth.getCurrentAccountSync().ref).then((account) => {

							let index = _.findIndex(account.sopTemplates, (sopTemplate) => {
								return sopTemplate._id === sop._id;
							});

							if(index !== -1) {
								account.sopTemplates.splice(index, 1);
								if(sop.default) {
									let newDefaultSOP = _.find(account.sopTemplates, (sopTemplate) => {
										return sopTemplate.eventType === sop.eventType;
									});

									if(newDefaultSOP) {
										newDefaultSOP.default = true;
										// TODO: Consider using ng-toastr to indicate new default was chosen
										self.toastr.info(`A new <b>DEFAULT</b> SOP. was chosen`, {
											closeButton: true,
											tapToDismiss: true,
											allowHtml: true
										});
									}


								}
								self.accountService.updateAccount(account);
							}
						})
						.catch((err) => {
							console.error("Error: ", err);
						});
					}
				},
				// short hand button definition
				close: {
					text: "Cancel",
					// btnClass: 'btn-primary',
					keys: ['enter'], // will trigger when enter is pressed
					action(scope) {

					}

				}
			},
		});
	}
	manageSOPTags() {
		let self = this;
		self.accountService.manageSOPTags().then((account) => {
            self.accountService.updateAccount(account).then((newAccount) => {

            });
		});
	}

	doLog() {
		console.debug(this);
	}
}


export default angular.module('secutraqApp.sops')
	.component('listSops', {
		template: require('./sop-list.html'),
		controller: SopListComponent,
		controllerAs: '$ctrl',
		bindings: {
			currentAccount: '<'

		}
	})
	.name;
