export class ReportsScheduledComponent {
	scheduledReports = [];


	/*@ngInject*/
	constructor(scheduledReportService,$uibModal, moment) {
		this.scheduledReportService = scheduledReportService;
		this.$uibModal = $uibModal;
		this.moment = moment;
	}

	$onInit() {
		let self = this;
		self.scheduledReportService.registerListener(self,(event,item,array)=>{
			self.onReceiptOfAccount(item);
		}).then((scheduledReports) => {
			self.scheduledReports = scheduledReports;
		}).catch((err) => {

			console.error(err);
		});
	}

	$onDestroy(){
		let self = this;
		self.scheduledReportService.unregisterListener(self);
	}

	onReceiptOfAccount(acc){
		let self = this;
		self.scheduledReports = acc.scheduledReports;
	}

	openCreateModal() {
      this.modalInstance = this.$uibModal.open({
          //template: "<alertsmodals></alertsmodals>",
          component: 'newscheduledreport',
          bindToController: true,
          size: 'lg',
          backdrop: 'static',
          resolve: {
             settings: function() {
                 return {
                     edit: false,
                     create: true
                 };
             }
          }
      });


      this.modalInstance.result.then((result) => {
          //   self.Restangular.one('accounts', this.$stateParams.accountID).all('alerts').customPOST(result,'configs').then(function(){
      }).catch((err) => {
          console.error(err);
      })
	}

	onEditSchedule(scheduledReport){
      this.modalInstance = this.$uibModal.open({
          //template: "<alertsmodals></alertsmodals>",
          component: 'newscheduledreport',
          bindToController: true,
          size: 'lg',
          backdrop: 'static',
          resolve: {
             settings: function() {
                 return {
                     edit: true,
                     create: false,
										 item : _.cloneDeep(scheduledReport),
                 };
             },
          }
      });


      this.modalInstance.result.then((result) => {
          //   self.Restangular.one('accounts', this.$stateParams.accountID).all('alerts').customPOST(result,'configs').then(function(){
      }).catch((err) => {
          console.error(err);
      })

	}

	onDuplicateSchedule(scheduledReport){
		let self = this;
		this.modalInstance = this.$uibModal.open({
			//template: "<alertsmodals></alertsmodals>",
			component: 'newscheduledreport',
			bindToController: true,
			size: 'lg',
			backdrop: 'static',
			resolve: {
				settings: function() {
					return {
						edit: false,
						create: true,
						item : _.cloneDeep(_.omit(scheduledReport,['_id'])),
					};
				},
			}
		});
		this.modalInstance.result.then((result) => {
			//   self.Restangular.one('accounts', this.$stateParams.accountID).all('alerts').customPOST(result,'configs').then(function(){
		}).catch((err) => {
			console.error(err);
		})

	}

	onDisableSchedule(scheduledReport){
		let self = this;
		let tempSchedule = _.cloneDeep(scheduledReport);
		tempSchedule.active = false;
		tempSchedule.contacts = _.map(tempSchedule.contacts,'_id');
		tempSchedule.reports = _.map(tempSchedule.reports,'_id');
		self.scheduledReportService.updateScheduledReport(tempSchedule);
	}

	onEnableSchedule(scheduledReport){
		let self = this;
		let tempSchedule = _.cloneDeep(scheduledReport);
		tempSchedule.active = true;
		tempSchedule.contacts = _.map(tempSchedule.contacts,'_id');
		tempSchedule.reports = _.map(tempSchedule.reports,'_id');
		self.scheduledReportService.updateScheduledReport(tempSchedule);
	}

	onDeleteSchedule(scheduledReport){
		let self = this;
		self.scheduledReportService.removeScheduledReport(scheduledReport);
	}

}

export default angular.module('secutraqApp.reports')
.component('reportsscheduled', {
	template: require('./reports.scheduled.html'),
	controller: ReportsScheduledComponent,
	controllerAs: '$ctrl'
})
.filter('customHour',(moment) => {
	return (hour) => {
		return moment.utc().startOf('day').add(hour,'hours').local().format('h a');
	}
})
.name;
