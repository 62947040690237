import angular from 'angular';
import uiRouter from 'angular-ui-router';
import NgTable from 'ng-table/bundles/ng-table';
import ngSanitize from 'angular-sanitize';
import routes from './route.routes';
import uiBootstrap from 'angular-ui-bootstrap';

export default angular.module('secutraqApp.routes', [uiRouter, 'ngTable', uiBootstrap])
.config(routes)
.name;

require('./route.component');
require('./route-list/route-list.component');
require('./route-list/new-route/new-route.component');
require('./route-list/route-detail/route-detail.component');
