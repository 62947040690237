export default function($stateProvider) {
    'ngInject';
    $stateProvider
    .state('main.events', {
        url: '/events',
        views: {
            'content@main' : {
                template: '<events></events>'
            }
        },
        authenticate: true,
        data: {
            title: 'Event Monitoring'
        }
    })
}
