import angular from 'angular';

export class LiveStreamContainer {
	/*@ngInject*/
	constructor(liveStreamService) {
		this.liveStreamService = liveStreamService;
	}

	$onInit() {
	}

	$onDestroy() {
		this.liveStreamService.clear();
	}
}

export default angular.module('directives.liveStreamContainer', [])
	.component('liveStreamContainer', {
		template: require('./live-stream-container.html'),
		controller: LiveStreamContainer,
		controllerAs: "$ctrl",
		bindings: {
		}
	})
	.name;
