'use strict';
// @flow

export class ChangePasswordComponent {
	user = {
		oldPassword: '',
		newPassword: '',
		confirmPassword: ''
	};
	errors = {
		other: undefined
	};
	message = '';
	submitted = false;
	Auth;

	/*@ngInject*/
	constructor(Auth) {
		this.Auth = Auth;
		this.passwordValid = false;
	}

	changePassword(form) {
		this.submitted = true;

		if(form.$valid) {
			this.Auth.changePassword(this.user.oldPassword, this.user.newPassword)
				.then(() => {
					this.message = 'Password successfully changed.';
				})
				.catch(() => {
					form.password.$setValidity('mongoose', false);
					this.errors.other = 'Incorrect password';
					this.message = '';
				});
		}
	}
}

export default angular.module('secutraqApp.settings')
	.component('changepassword', {
		template: require('./settings.user.changepassword.html'),
		controller: ChangePasswordComponent,
		controllerAs: '$ctrl'
	})
	.name;
