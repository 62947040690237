'use strict';
import uiBootstrap from 'angular-ui-bootstrap';

export default class OptionsController {
    $state;
    $http;
    $scope;
    $uibModal;
    $uibModalInstance;
    Auth;
    reference;
    lastFilter;
    tempFilter={};
    allTags=[];

    /*@ngInject*/
    constructor($uibModalInstance, Auth, $http, $state, $scope, $uibModal, lastFilter) {
        this.$uibModalInstance = $uibModalInstance;
        this.Auth = Auth;
        this.$state = $state;
        this.$http = $http;
        this.$scope = $scope;
        this.provinceOptions = [
            {
                province: "KwaZulu Natal",
                region: "za-nl"
            },
            {
                province: "Gauteng",
                region: "za-gt"
            },
            {
                province: "North West Province",
                region: "za-nw"
            },
            {
                province: "Mpumalanga",
                region: "za-mp"
            },
            {
                province: "Limpopo",
                region: "za-lp"
            },
            {
                province: "Eastern Cape",
                region: "za-ec"
            },
            {
                province: "Western Cape",
                region: "za-wc"
            },
            {
                province: "Free State",
                region: "za-fs"
            },
            {
                province: "Northern Cape",
                region: "za-nc"
            },
            {
                province: "South Africa",
                region: "za"
            },
        ];
        this.$uibModal = $uibModal;
        this.lastFilter=lastFilter;
    }

    $onInit() {
        let self = this;
        self.$http.get('api/references/findTags').then((response)=>{
            self.allTags=response.data;
        });
        self.tempFilter=_.cloneDeep(self.lastFilter);
    }

    doLog() {
		console.debug(this);
    }

    close() {
        let self = this;
        self.$uibModalInstance.dismiss();
    }

    applyFilter(filter) {
        let self = this;
        self.$uibModalInstance.close({filter:self.tempFilter});
    }

    clearFilter() {
        let self = this;
        self.$uibModalInstance.close({filter:{alias:undefined,tags:undefined,description:undefined,flag:undefined, province:undefined}});
    }
}

