
import PhotoModalController from '../../photoAnalytics/imageCollection/modal.controller';

export class PhotoPanelComponent {

    /*@ngInject*/
    constructor(photoService, $uibModal, $ngConfirm, $scope) {
        this.photoService = photoService;
        this.$uibModal = $uibModal;
        this.$ngConfirm = $ngConfirm;
        this.$scope = $scope;
    }

    $onInit() {
        let self = this;
        if(!self.photo && self.photoId){
            self.photoService.getPhotoByID(self.photoId)
                .then((photo) => self.photo = photo)
        } 
    }

    openSnapshot(snapshot) {
        let self = this;
        self.$uibModal.open({
            animation: true,
            backdrop: 'static',
            keyboard: true,
            template: require('../../photoAnalytics/imageCollection/modal.html'),
            controller: PhotoModalController,
            controllerAs: '$ctrl',
            size: 'lg',
            resolve: {
                snapshot() {
                    return snapshot || self.photo;
                },
                snapPlaceholder() {
                    return self.snapPlaceholder || [];
                }
            }
        }).result.then(function(result) {
            if(result && result.newSnap) {
                self.openSnapshot(result.newSnap);
            } else if(result && result.next) {
                //self.openNext();
            } else if(result && result.previous) {
                //self.openPrevious();
            }
        }).catch((err) => console.error(err));
            
    }

    openVideo(){
        let self = this;
        self.$ngConfirm({
            title:'',
            content:    `<video controls controls style="height:auto;max-width:65vw;max-height:65vh;">
                            <source ng-src="{{$ctrl.event.metaData.videoData}}" type="video/mp4">
                        </video>`,
            scope: this.$scope,
            theme: 'supervan',
            type: 'dark',
            escapeKey: true,
            backgroundDismiss: true,
            closeIcon: true,
            columnClass:'col-sx-12',
            bgOpacity: 0.8
        });
    }

    doLog() {
        console.debug(this);
    }
}
export default angular.module('secutraqApp.events')
    .component('photoPanel', {
        template: require('./photo-panel.component.html'),
        controller: PhotoPanelComponent,
        controllerAs: "$ctrl",
        bindings: {
            photoId: "<",
            photo: "<",
            event: "<",
            video: "<"
        }
    });
