export class ReportsComponent {

	/*@ngInject*/
	constructor($stateParams, Restangular, NgTableParams, $uibModal, socket, $log, Auth, moment, fieldReportService, toastr, $timeout) {
		this.$stateParams = $stateParams;
		this.Restangular = Restangular;
		this.NgTableParams = NgTableParams;
		this.$uibModal = $uibModal;
		this.socket = socket;
		this.$log = $log;
		this.Auth = Auth;
		this.moment = moment;
		this.fieldReportService = fieldReportService;
		this.filter = '';
		this.isColumnsCollapsed = false;
		this.toastr = toastr;
		this.$timeout = $timeout;
		this.self = this;
	}

	$onInit() {
		let self = this;
		//NOTE : Invert logic due to weirdness with toggle switch
		self.$stateParams.accountID = self.Auth.getCurrentAccountSync().ref;

        self.fieldReportService.registerListener(self, (event, item, array) => {
            return self.onReceiptOfReport(event,item);
        }).catch(err => {
            console.error('Error getting events', err);
        });

		self.reports = [];

		/**
		 * An array of columns that are showed by default.
		 * We iterate through all the columns and mark those with VIEW set to true
		 */
		self.selectedColumns = [];

		self.cols = [{
				title: "Report Type",
				field: "type",
				show: true,
				sortable: "type",
				context: self
			}, {
				title: "Unit",
				field: "unitname",
				show: true,
				sortable: "unitname",
				context: self
			}, {
				title: "Created At",
				field: "createdAt",
				show: true,
				sortable: "createdAt",
				context: self
			},
			{
				title: 'Open',
				field: 'open',
				show: true,
				context: self
			},
		];

		_.forEach(self.cols, (col) => {
			if(col.show) {
				self.selectedColumns.push(col.title);
			}
		});


		self.tableParams = new self.NgTableParams({
			page: 1, // start with first page
			count: 10, // count per page
			sorting: {
				name: 'asc' // initial sorting
			}
		},
		{
			total: 0,
			getData(params) {
				let order;
				if (params && params.sorting) {
					order = params.sorting();
					return self.fieldReportService.getFieldReportsWithQuery({
							filter: self.filter.length ? self.filter : undefined,
							skip: (params.page() - 1) * params.count(),
							// accountID: self.$stateParams.accountID,
							limit: params.count(),
							by: Object.keys(order)[0],
							order: order[Object.keys(order)[0]],
						}).then(function(reports) {
							self.reports = reports;
							self.total = reports.total;
							params.total(reports.total);
							return reports;
						})
						.catch(err => {
							console.error("Error caught when getting data for field reports: ", err.data.err);
						});
				}
			}
		}
	);

		this.tableParams.reload();
	}

	$onDestroy() {
		let self = this;
		self.fieldReportService.unregisterListener(self, true);
	}


	onReceiptOfReport(event,report) {
		let self = this;

		let index = _.findIndex(self.reports, (storedReport) => {
			return storedReport._id === report._id;
		});

		if(index !== -1) {
			if(event == 'deleted') {
				self.reports.splice(index, 1);
			}else {
				self.reports.splice(index, 1, report);
			}
		}
	}


	onColumnSelected($item, $model) {
		$item.show = true;
	}

	onColumnRemoved($item, $model) {
		$item.show = false;
	}

	applyFilter() {
		this.tableParams.page(1);
		this.tableParams.reload();
	}


	reloader() {
		let self = this;
		self.tableParams.reload();
	}

	doLog() {
		let self = this;
		console.debug(self);
	}


	openReport(report) {
		let self = this;
		let modalInstance = self.$uibModal.open({
			component: 'viewFieldReport',
			backdrop: 'static',
			resolve:{
				report:_.cloneDeep(report),
			}
		});
	}

}

export default angular.module('secutraqApp.fieldReports')
	.component('fieldReports', {
		template: require('./reports.html'),
		controller: ReportsComponent,
		controllerAs: '$ctrl'
    })
	.name;
