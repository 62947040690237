import _ from 'lodash-es';

export class PhotoService {


    /*@ngInject*/
    constructor(moment, Restangular, socket, Auth) {
        this.moment = moment;
        this.Restangular = Restangular;
		this.photos = [];
		this.listenerMap = new Map();
		this.socket = socket;
		this.Auth = Auth;
		this.cacheTimeout = undefined;
        this.lastAccount = undefined;
		this.lastPhoto = undefined;
		this.joined = false;
    }

    //Copy
	onSocketEvent(event, item, array) {
		let self = this;
        self.photos = array;
		self.listenerMap.forEach((value, key) => {
			setTimeout(() => {
value(event, item, array);
}, 0);
		});
	}

	registerListener(context, cb, returnAll=true) {
		let self = this;
		if(!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if(self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
        if(!self.lastUser) {
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        } else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
            self.destroyService();
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        }
		let count = self.listenerMap.size;
		self.listenerMap.set(context, cb);
		// If listenermap was empty, (TODO and timer isn't running), join room, syncupdates
		if(!self.joined) {
			if(self.cacheTimeout) {
				clearTimeout(self.cacheTimeout);
				self.cacheTimeout = undefined;
			}
            if(returnAll) {
                return self.getPhotos().then((photos) => {
                    self.socket.syncUpdates('photo', self.photos, self.onSocketEvent.bind(self), self);
                    self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().ref}:*:photos`);
                    self.joined = true;
                    return photos;
                });
            } else {
                self.socket.syncUpdates('photo', self.photos, self.onSocketEvent.bind(self), self);
                self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().ref}:*:photos`);
                self.joined = true;
                return [];
            }
		} else {
			if(self.cacheTimeout) {
				clearTimeout(self.cacheTimeout);
				self.cacheTimeout = undefined;
			}
            if(returnAll) {
                return self.getPhotos();
            } else {
                return [];
            }
		}
	}

	destroyService() {
		let self = this;
		if(self.joined) {
			self.socket.unsyncUpdates('photo', self);
			self.socket.leaveRoom(`${self.lastAccount}:*:photos`);
		}
		self.joined = false;
		self.photos = [];
		self.listenerMap.clear();
		clearTimeout(self.cacheTimeout);
		self.cacheTimeout = undefined;
	}

	unregisterListener(context, cb) {
		let self = this;
		if(!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if(self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
        if(!self.lastUser) {
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        } else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
            self.destroyService();
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        }
		self.listenerMap.delete(context);
		if(self.listenerMap.size === 0) {
			self.cacheTimeout = setTimeout(self.destroyService.bind(self), 5000);
		}
	}

	unregisterAll() {
		let self = this;
		if(!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if(self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
        if(!self.lastUser) {
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        } else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
            self.destroyService();
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        }
		self.listenerMap.clear();
		if(self.listenerMap.size === 0) {
			self.cacheTimeout = setTimeout(self.destroyService.bind(self), 5000);
		}
	}

    getPhotos(query) {
		let self = this;
		if(self.photos.length > 0 && !query) {
			return new Promise(function(resolve) {
				resolve(_.cloneDeep(self.photos));
			});
		}
        let lastAccount = _.cloneDeep(self.lastAccount);
        let lastUser = _.cloneDeep(self.lastUser);
        return this.Restangular.all('photos').getList(query)
        .then((photos)=>{
            if(lastAccount == self.lastAccount && lastUser == self.lastUser) {
                self.photos = this.Restangular.stripRestangular(photos);
                return _.cloneDeep(photos);
            }else {
                return [];
            }
        });
    }

    //Custom (Change and keep if necessary)
    getPhotoByID(photoID) {
        let self = this;
        let result = _.find(self.photos, (photo) => {
            return photo._id === photoID;
        });
        if(result) {
            return Promise.resolve(_.cloneDeep(result));
        } else {
            return self.Restangular.one('photos', photoID).get();
        }
    }

    getPhotosForUnit(unitId,onlyOpen,skip,limit, by, order) {
        let self = this;
        return self.Restangular.all('photos').customGET('unitFilter',{query:unitId,field:'unit',limit,skip,onlyOpen,by,order});
    }
    // getPhotoByIDSync(photoID) {
    //     let self = this;
    //     let result = _.find(self.photos, (photo) => {
    //         return photo._id === photoID;
    //     });
    //     return _.cloneDeep(result);
    // }
    getPhotoByIDSync(photoId) {
        let self = this;
        if(self.photos && self.photos.length > 0) {
            let index = _.findIndex(self.photos,(storedPhoto) => {
                return storedPhoto._id === photoId;
            });

            if(index !== -1) {
                return _.cloneDeep(self.photos[index]);
            }else{
                return 'No such photo!';
            }
        }else{
            return 'No such photo!';
        }
    }

    getPhotoIndexByID(photoID) {
        let self = this;

        let idx = _.findIndex(self.photos, (photo) => {
            return photo._id === photoID;
        });
        return idx;
    }

    getPhotoIndexByIDSync(photoID) {
        let self = this;

        let idx = _.findIndex(self.photos, (photo) => {
            return photo._id === photoID;
        });
        return idx;
    }
}

export default angular.module('secutraqApp.dashboard')
.service('photoService', PhotoService);
