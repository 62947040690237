'use strict';
angular.module('secutraqApp.directives')
.directive('grow', ['$document', '$timeout',
function($document, $timeout) {
    return {
        restrict: 'A',
        link: function(scope, el, attrs) {

            let cssStyle = attrs.growStyle;
            let value = attrs.growValue;
            let wait = attrs.growWait;

            //   $timeout(() => {
            //       el.css({cssStyle: value});
            //   }, wait);
            $timeout(() => {
                el.css({"min-height": "200px"})
            }, 300);
        }
    };
}]);
