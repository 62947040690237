'use strict';

export function routeConfig($urlRouterProvider, $locationProvider, RestangularProvider, $qProvider, $stateProvider) {
	'ngInject';
	$qProvider.errorOnUnhandledRejections(false); // TODO: find out if this is the appropriate handling of error
	/*  $urlRouterProvider.otherwise(function($injector, $location) {
	    $location.path('/login');
	  });*/
	$urlRouterProvider.otherwise('//dashboard');

	$locationProvider.html5Mode(true);

	// Set Restangular base url to our api
	RestangularProvider.setBaseUrl('/api');
	// MongoDB _id field mapping
	RestangularProvider.setRestangularFields({
		id: '_id'
	});

	// Exceptions
	RestangularProvider.addResponseInterceptor(function(data, operation, what, url, response, deferred) {
		if (['units','custom','contacts','locations','reports','sites','users','events','routes','schedules','shifts','appVersions','fieldReportTemplates','fieldReports','customEventTypes', 'apiKeys'].includes(what) && operation === 'getList') {
			if(data.data) {
				let returnData = data.data;
				returnData.total = data.total;
				returnData.filter = data.filter;
				returnData.query = data.query;
				returnData.limit = data.limit;
				returnData.skip = data.skip;
				returnData.sort = data.sort;

				return returnData;

			}else{
				return data;
			}
		}

		return data;
	});


}
