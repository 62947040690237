import momentTZ from "moment-timezone";

import hasprivileges from "hasprivileges";
export class ReportsAdhocComponent {
	Restangular;
	moment;
	Auth;
	$window;
	toastr;
	$sce;

	properties = ["Name", "Group"];
	comparisonTypes = [
		"is",
		"is not",
		"contains",
		"does not contain",
		"starts with",
		"ends with",
		"matches",
	];

	quickReportOptions = [
		"Last 24 Hours",
		"Last 7 Days",
		"Last 31 Days",
		"Today",
		"This Week",
		"This Month",
	];

	/*@ngInject*/
	constructor(
		moment,
		Restangular,
		Auth,
		$window,
		$sce,
		toastr,
		usSpinnerService,
		unitService,
		siteService,
		routeService,
		scheduleService,
		appConfig,
		shiftService,
		taskService,
		$http,
		$scope,
		reportService,
		$ngConfirm,
		$stateParams,
		eventService
	) {
		let self = this;
		this.Restangular = Restangular;
		this.moment = moment;
		this.Auth = Auth;
		this.$window = $window;
		this.$sce = $sce;
		this.$http = $http;
		this.$scope = $scope;
		this.$stateParams = $stateParams;
		this.$ngConfirm = $ngConfirm;
		this.toastr = toastr;
		this.unitService = unitService;
		this.siteService = siteService;
		this.routeService = routeService;
		this.scheduleService = scheduleService;
		this.taskService = taskService;
		this.eventService = eventService;
		this.shiftService = shiftService;
		this.reportService = reportService;
		this.availableUnits = [];
		this.availableRoutes = [];
		this.availableSchedules = [];
		this.availableShifts = [];

		this.limitTasks = 10;
		this.currentPage = 1;
		this.totalTasks = 0;

		//this.availableEventTypes = _.cloneDeep(appConfig.eventTypes);
		//_.pull(this.availableEventTypes,"Geo-fence In","Geo-fence Out");
		//this.availableEventTypes.push('Waypoint Geo-fence In (GPS)');
		//this.availableEventTypes.push('Waypoint Geo-fence In (BLE)');
		//this.availableEventTypes.push('Waypoint Geo-fence Out (GPS)');
		//this.availableEventTypes.push('Waypoint Geo-fence Out (BLE)');
		//this.availableEventTypes.push('Site Geo-fence In (GPS)');
		//this.availableEventTypes.push('Site Geo-fence In (BLE)');
		//this.availableEventTypes.push('Site Geo-fence Out (GPS)');
		//this.availableEventTypes.push('Site Geo-fence Out (BLE)');
		//_.remove(this.availableEventTypes, (o) => {
		//return o == 'Recognition' || o == 'LPR Recognition'
		//});
		//this.availableEventTypes.unshift('All Events');
		this.availableSites = [];

		this.usSpinnerService = usSpinnerService;
		this.reports = [
			{
				name: "Event Summary",
				description:
					"Gives a summary of events for the specified filters",
				allowOutput: {
					pdf: true,
					excel: true,
					template: true,
				},
				parts: [
					{
						main: "Filter",
						data: {
							"Event Type": {
								multiValues: ["All Events"],
							},
							Units: {
								property: { name: "Name" },
								selectedUnits: [{ name: "All Units" }],
							},
							"Event Status": {
								multiValues: ["Open Events", "Closed Events"],
							},
						},
						allFilters: [
							{
								name: "Event Type",
								type: "multi",
								availableItems: ["All Events"],
							},
							{
								name: "Units",
								type: "specific",
								availableItems: [
									{
										name: "Name",
									},
									{
										name: "Group",
									},
								],
							},
							{
								name: "Event Status",
								type: "multi",
								availableItems: [
									"Open Events",
									"Closed Events",
								],
							},
						],
						availableFilters: [],
						unique: true,
						addData: self.addFilter.bind(self),
						removeData: self.removeData.bind(self),
					},
					{
						main: "Add Content",
						data: {},
						unique: true,
						allFilters: [
							{
								name: "Maps",
								type: "multi",
								availableItems: ["Units", "Sites"],
							},
							{
								name: "Photos",
								type: "single",
							},
							{
								name: "SOP Information",
								type: "single",
							},
							{
								name: "Field Report Information",
								type: "single",
							},
						],
						addData: self.addFilter.bind(self),
						removeData: self.removeData.bind(self),
					},
					{
						main: "Group Content",
						data: "None",
						unique: true,
						allFilters: [
							{
								name: "None",
								type: "single",
							},
							{
								name: "Event Type",
								type: "single",
							},
							{
								name: "Units",
								type: "single",
							},
							{
								name: "Assets",
								type: "single",
							},
							{
								name: "Sites",
								type: "single",
							},
						],
						addData: self.addFilter.bind(self),
						removeData: self.removeData.bind(self),
						limit: 1,
					},
				],
			},
			{
				name: "Shift Summary",
				description:
					"Gives a summary of shifts for the specified filters",
				allowOutput: {
					pdf: true,
					excel: true,
					template: true,
				},
				parts: [
					{
						main: "Filter",
						data: {
							Shift: {
								property: { name: "Schedule" },
								selectedSchedules: [{ name: "All Schedules" }],
							},
							"Event Type": {
								multiValues: ["All Events"],
							},
							Units: {
								property: { name: "Name" },
								selectedUnits: [{ name: "All Units" }],
							},
						},
						allFilters: [
							{
								name: "Shift",
								type: "specific",
								availableItems: [
									{
										name: "Site",
									},
									{
										name: "Schedule",
									},
									{
										name: "Shift",
									},
								],
							},
							{
								name: "Event Type",
								type: "multi",
								availableItems: ["All Events"],
							},
							// {
							// 	name: 'Routes',
							// 	type: 'specific',
							// 	availableItems: [{name:'Route Name'}],
							// },
							{
								name: "Units",
								type: "specific",
								availableItems: [
									{
										name: "Name",
									},
									{
										name: "Group",
									},
								],
							},
						],
						availableFilters: [],
						unique: true,
						addData: self.addFilter.bind(self),
						removeData: self.removeData.bind(self),
					},
					{
						main: "Add Content",
						data: {},
						unique: true,
						allFilters: [
							{
								name: "Maps",
								type: "multi",
								availableItems: ["Units", "Sites"],
							},
							{
								name: "Photos",
								type: "single",
							},
							{
								name: "SOP Information",
								type: "single",
							},
							{
								name: "Field Report Information",
								type: "single",
							},
						],
						addData: self.addFilter.bind(self),
						removeData: self.removeData.bind(self),
					},
				],
			},
			{
				name: "Time and Attendance Summary",
				description: "Gives a summary of geo-fence in and out events.",
				allowOutput: {
					pdf: true,
					excel: true,
					template: true,
				},
				parts: [
					{
						main: "Filter",
						data: {
							"Event Type": {
								multiValues: [
									"Site Geo-fence In (BLE)",
									"Site Geo-fence In (GPS)",
									"Site Geo-fence Out (BLE)",
									"Site Geo-fence Out (GPS)",
									"Login",
								],
							},
							Units: {
								property: { name: "Name" },
								selectedUnits: [{ name: "All Units" }],
							},
							Sites: {
								property: { name: "Site" },
								selectedSites: [{ name: "All Sites" }],
							},
							//'Event Status':{
							//multiValues: ['Open Events', 'Closed Events']
							//}
						},
						allFilters: [
							{
								name: "Event Type",
								type: "multi",
								availableItems: [
									"Site Geo-fence In (BLE)",
									"Site Geo-fence In (GPS)",
									"Site Geo-fence Out (BLE)",
									"Site Geo-fence Out (GPS)",
								],
							},
							{
								name: "Units",
								type: "specific",
								availableItems: [
									{
										name: "Name",
									},
									{
										name: "Group",
									},
								],
							},
							{
								name: "Sites",
								type: "specific",
								availableItems: [
									{
										name: "Site",
									},
								],
							},
							//{
							//name: 'Event Status',
							//type: 'multi',
							//availableItems: ['Open Events','Closed Events'],
							//}
						],
						availableFilters: [],
						unique: true,
						addData: self.addFilter.bind(self),
						removeData: self.removeData.bind(self),
					},
					{
						main: "Group Content",
						data: "None",
						unique: true,
						allFilters: [
							{
								name: "None",
								type: "single",
							},
							//{
							//name: 'Event Type',
							//type: 'single'
							//}
							{
								name: "Units",
								type: "single",
							},
							{
								name: "Assets",
								type: "single",
							},
							{
								name: "Sites",
								type: "single",
							},
						],
						addData: self.addFilter.bind(self),
						removeData: self.removeData.bind(self),
						limit: 1,
					},
				],
			},
			{
				name: "Heartbeat Summary",
				description: "Gives a summary of unit heartbeats.",
				allowOutput: {
					pdf: true,
					excel: true,
					template: true,
				},
				parts: [
					{
						main: "Filter",
						data: {
							Account: {
								property: { name: "Account Name" },
								selectedAccounts: [{ name: "All Accounts" }],
							},
						},
						allFilters: [
							{
								name: "Account",
								type: "specific",
								availableItems: [{ name: "Account Name" }],
							},
						],
					},
				],
			},
			{
				name: "Video Report",
				description:
					"Generate a time lapse video of the photos for the a unit.",
				allowOutput: {
					video: true,
				},
				parts: [
					{
						main: "Filter",
						data: {
							Unit: {
								property: { name: "Unit" },
							},
						},
						allFilters: [
							{
								name: "Unit",
								type: "single",
							},
						],
					},
				],
			},
			// {
			// 	name: 'Data Usage Summary',
			// 	description: 'Gives a summary of data for units',
			// 	targetFilters: []
			// },
			// {
			// 	name: 'SIM Swap Summary',
			// 	description: 'Gives a summary of SIM swaps for units',
			// 	targetFilters: []
			// },
			// {
			// 	name: 'Power Failure Summary',
			// 	description: 'Gives a summary of power failures for units',
			// 	targetFilters: []
			// },
			// {
			// 	name: 'Unit Status Summary',
			// 	description: 'Gives a summary of the current status for units',
			// 	hideDate: true,
			// 	targetFilters: []
			// },
			// {
			// 	name: 'Unit Detail Summary',
			// 	description: 'Gives details on units',
			// 	hideDate: true,
			// 	targetFilters: []
			// },
			// {
			// 	name: 'Unit Heartbeat Summary',
			// 	description: 'Gives heartbeats of specific unit',
			// 	selectUnit: true,
			// 	targetFilters: []
			// },
		];
	}

	$onInit() {
		// this.refreshUnits();
		let self = this;
		self.availableEventTypes = [];
		self.eventService.getEventDetails.then((eventDetails) => {
			_.forEach(eventDetails, (eventDetail, eventType) => {
				if (
					eventDetail.subEvents ||
					eventType == "online" ||
					eventType == "offline"
				) {
					return null;
				} else if (eventDetail.splitEvents) {
					self.availableEventTypes = self.availableEventTypes.concat(
						_.map(eventDetail.splitEvents, "alias")
					);
				} else {
					self.availableEventTypes.push(eventType);
				}
			});
			this.availableEventTypes.unshift("All Events");
		});
		self.Restangular.all("Units")
			.all("groups")
			.getList()
			.then((groups) => {
				self.availableGroups = groups;
			});
		self.taskService.registerListener(
			self,
			(event, item, array) => {
				if (event === "deleted") {
					self.onRemoveTask(item);
				} else {
					self.onReceiptOfTask(item);
				}
			},
			true
		);
		self.updateTasks();
		self.reportService
			.registerListener(self, (event, item, array) => {
				if (event === "deleted") {
					self.onRemoveReportTemplate(item);
				} else {
					self.onReceiptOfReportTemplate(item);
				}
			})
			.then((reportTemplates) => {
				self.reportTemplates = reportTemplates;
			});
		self.refreshUnits();
		self.privAccounts = self.Auth.getCurrentUserSync().accounts;
		//if(self.Auth.hasPrivilegeSync('faceRecall')){
		//this.availableEventTypes.push('Recognition');
		//this.availableEventTypes.push('LPR Recognition');
		//}
	}

	$onDestroy() {
		let self = this;
		self.taskService.unregisterListener(self);
		self.reportService.unregisterListener(self);
	}

	updateTasks() {
		let self = this;
		self.tasks = [];
		self.loadingTasks = true;
		// TODO: Add paging  <08-02-21, Liaan> //
		let query = {
			//filter: self.filter.length ? self.filter : undefined,
			skip: (self.currentPage - 1) * self.limitTasks,
			limit: self.limitTasks,
			by: "createdAt",
			order: "desc",
			params: [
				{
					field: "type",
					value: "Generate Report",
					type: "string",
					operator: "$eq",
				},
			],
		};
		let promises = [
			self.taskService.getTasksWithQuery(query),
			self.taskService.countTasksWithQuery(query),
		];
		Promise.all(promises)
			.then((data) => {
				self.loadingTasks = false;
				self.tasks = data[0];
				self.totalTasks = data[1];
				if (self.$stateParams && self.$stateParams.task) {
					let task = _.find(self.tasks, [
						"_id",
						self.$stateParams.task,
					]);
					if (task) {
						self.generateReportAccordion = true;
						if (!self.openedTask) {
							self.openedTask = {};
						}
						self.openedTask[task._id] = true;
					}
				}
			})
			.catch((err) => {
				self.loadingTasks = false;
				console.error(err);
			});
	}

	openDateTimePicker(index, picker, $event) {
		$event.preventDefault();
		$event.stopPropagation();
		this.reports[index][picker] = !this.reports[index][picker];
	}

	onRemoveTask(task) {
		let self = this;
		let index = _.findIndex(self.tasks, ["_id", task._id]);
		if (index >= 0) {
			self.tasks.splice(index, 1);
		}
	}

	onReceiptOfTask(task) {
		let self = this;
		if (task.type !== "Generate Report") return null;
		let index = _.findIndex(self.tasks, ["_id", task._id]);
		if (index >= 0) {
			self.tasks.splice(index, 1, task);
		} else {
			self.totalTasks++;
			self.tasks.push(task);
		}
	}

	onRemoveReportTemplate(report) {
		let self = this;
		let index = _.findIndex(self.reportTemplates, ["_id", report._id]);
		if (index >= 0) {
			self.reportTemplates.splice(index, 1);
		}
	}

	onReceiptOfReportTemplate(report) {
		let self = this;
		let index = _.findIndex(self.reportTemplates, ["_id", report._id]);
		if (index >= 0) {
			self.reportTemplates.splice(index, 1, report);
		} else {
			self.reportTemplates.push(report);
		}
	}

	quickPick(data, index, option) {
		let self = this;
		let to = this.moment();
		let from = this.moment();
		data[index].filterTo = to.toDate();
		switch (option) {
			case "Last 24 Hours":
				data[index].filterFrom = from.subtract(24, "hours").toDate();
				break;
			case "Last 7 Days":
				data[index].filterFrom = from.subtract(7, "days").toDate();

				break;
			case "Last 31 Days":
				data[index].filterFrom = from.subtract(31, "days").toDate();

				break;
			case "Today":
				data[index].filterTo = to.endOf("day").toDate();
				data[index].filterFrom = from.startOf("day").toDate();
				break;
			case "This Week":
				data[index].filterTo = to.endOf("week").toDate();
				data[index].filterFrom = from.startOf("week").toDate();
				break;
			case "This Month":
				data[index].filterTo = to.endOf("month").toDate();
				data[index].filterFrom = from.startOf("month").toDate();

				break;
			default:
		}
	}

	onFilterAdded(index) {
		this.reports[index].targetFilters.push({
			property: this.properties[0],
			comparison: this.comparisonTypes[0],
		});
	}

	addFilter(part) {
		let self = this;
		if (part.unique) {
			part.availableFilters = _.differenceBy(
				part.allFilters,
				part.data,
				"name"
			);
		} else {
			part.availableFilters = part.allFilters;
		}
		part.data.push({});
	}

	ensureUnique(part) {
		let self = this;
		if (part.unique) {
			part.availableFilters = _.differenceBy(
				part.allFilters,
				part.data,
				"name"
			);
		} else {
			part.availableFilters = part.allFilters;
		}
	}

	removeData(part, index) {
		let self = this;
		part.data.splice(index, 1);
		if (part.unique) {
			part.availableFilters = _.differenceBy(
				part.allFilters,
				part.data,
				"name"
			);
		} else {
			part.availableFilters = part.allFilters;
		}
	}

	refreshRoutes(query) {
		let self = this;
		self.routeService
			.getRoutesWithQuery({
				filter: query,
				limit: 20,
			})
			.then((routeCollections) => {
				// let routes = [];
				// routeCollections.forEach((collection) => {
				// 	collection.routes.forEach((route) => {
				// 		route.collection = collection.name;
				// 		routes.push(route);
				// 	})
				// })
				self.availableRoutes = routeCollections;
			});
	}

	refreshSites(query, doc, filter) {
		let self = this;
		if (!doc || !filter) {
			return null;
		}
		if (_.find(doc.selectedSites, { name: "All Sites" })) {
			self.checkAllType(doc, filter);
			return null;
		}
		return self.siteService
			.getSitesWithQuery({
				filter: query,
				limit: 20,
			})
			.then((response) => {
				let sites = response.data || response;
				filter.availableSites = sites;
				if (filter.type !== "single") {
					filter.availableSites.unshift({ name: "All Sites" });
				}
				return null;
			});
	}

	refreshSchedules(query, doc, filter) {
		let self = this;
		if (!doc || !filter) {
			return null;
		}
		if (_.find(doc.selectedSchedules, { name: "All Schedules" })) {
			self.checkAllType(doc, filter);
			return null;
		}

		self.scheduleService
			.getScheduleWithQuery({
				filter: query,
				activeOnly: true,
				limit: 20,
			})
			.then((response) => {
				filter.availableSchedules = response.data;
				if (filter.type !== "single") {
					filter.availableSchedules.unshift({
						name: "All Schedules",
					});
				}
			});
		// self.shiftService.getScheduleWithQuery({
		// 	filter: query,
		// 	limit : 20,
		// }).then((response) => {
		// 	self.availableSchedules = response.data;
		// });
	}

	refreshShifts(query, index, doc, filter) {
		let self = this;
		if (!doc || !filter) {
			return null;
		}
		if (_.find(doc.selectedShifts, { name: "All Shifts" })) {
			self.checkAllType(doc, filter);
			return null;
		}
		let fullQuery = {
			filter: query,
			activeOnly: true,
			limit: 20,
		};
		if (self.reports[index].filterTo && self.reports[index].filterFrom) {
			fullQuery.between = {
				start: self.moment(self.reports[index].filterFrom).valueOf(),
				end: self.moment(self.reports[index].filterTo).valueOf(),
			};
		}
		self.shiftService.getShiftWithQuery(fullQuery).then((response) => {
			filter.availableShifts = response.data;
			if (filter.type !== "single") {
				filter.availableShifts.unshift({ name: "All Shifts" });
			}
		});
		// self.shiftService.getScheduleWithQuery({
		// 	filter: query,
		// 	limit : 20,
		// }).then((response) => {
		// 	self.availableSchedules = response.data;
		// });
	}

	// refreshUnits() {
	// 	let self = this;
	// 	this.Units = this.Restangular.all('units');
	// 	this.Units.getList({
	// 			accountID: self.Auth.getCurrentAccountSync().ref,
	// 		}).then(function(units) {
	//
	// 			self.availableUnits = units;
	// 		})
	// 		.catch(err => {
	// 			console.error("Error caught when getting data for units: ", err.data.err);
	// 		});
	// }
	onFilterRemoved(reportIx, index) {
		this.reports[reportIx].targetFilters.splice(index, 1);
	}

	refreshUnits(query, doc, filter) {
		let self = this;
		if (!doc || !filter) {
			return null;
		}
		if (_.find(doc.selectedUnits, { name: "All Units" })) {
			self.checkAllType(doc, filter);
			return null;
		}
		self.unitService
			.getUnitsWithQuery({
				filter: query,
				limit: 20,
			})
			.then((units) => {
				filter.availableUnits = units;
				if (filter.type !== "single") {
					filter.availableUnits.unshift({ name: "All Units" });
				}
				//self.checkAllType(doc,filter);
			})
			.catch((err) => {
				console.error(err);
			});
	}

	refreshGroups(doc, filter) {
		let self = this;
		if (!doc || !filter) {
			return null;
		}
		if (!doc.selectedGroups) {
			doc.selectedGroups = [];
		}
		if (doc.selectedGroups.includes("All Groups")) {
			self.checkAllType(doc, filter);
			return null;
		}
		filter.availableGroups = _.cloneDeep(self.availableGroups);
		filter.availableGroups.unshift("All Groups");
		filter.availableGroups = _.uniq(filter.availableGroups);
		// self.checkAllType(doc,filter);
	}

	refreshAccounts(s, doc, filter) {
		let self = this;
		if (!doc || !filter) {
			return null;
		}
		if (!doc.selectedAccounts) {
			doc.selectedAccounts = [];
		}
		if (_.find(doc.selectedAccounts, { name: "All Accounts" })) {
			self.checkAllType(doc, filter);
			return null;
		}
		filter.availableAccounts = _.cloneDeep(self.privAccounts);
		if(filter.type !== 'single')
		filter.availableAccounts.unshift({ name: "All Accounts" });
		filter.availableAccounts = _.uniq(filter.availableAccounts);
		// self.checkAllType(doc,filter);
	}

	saveAsTemplate(index) {
		let self = this;
		let tempReport = self.formatReport(self.reports[index]);
		let reportTemplate = {
			alias: "",
			query: tempReport,
		};
		self.reportAlias = "";
		self.$ngConfirm({
			title: `Report Template`,
			theme: "light",
			animation: "top",
			scope: self.$scope,
			closeAnimation: "bottom",
			content: `
				<label>Template Alias : </label>
				<input ng-model="$ctrl.reportAlias" type="text" required class="form-control"></input>
				`,
			escapeKey: true,
			backgroundDismiss: true,
			buttons: {
				// long hand button definition
				ok: {
					text: "Save",
					btnClass: "btn-primary",
					action(scope) {
						reportTemplate.alias = scope.$ctrl.reportAlias;
						self.reportService.saveReport(reportTemplate);
					},
				},
				close: {
					text: "Cancel",
					action(scope) {},
				},
			},
		});
	}

	deleteReport(id) {
		let self = this;
		self.reportService.deleteReport(id);
	}

	formatReport(report) {
		let self = this;
		let tempReport = _.pick(report, ["filterFrom", "filterTo", "name"]);
		if (report.parts) {
			report.parts.forEach((part) => {
				if (part.main == "Filter") {
					tempReport.filter = [];
					_.forEach(part.data, (filter, filterName) => {
						if (
							filterName == "Event Type" &&
							!filter.multiValues.includes("All Events")
						) {
							tempReport.filter.push({
								key: "eventType",
								collection: "Event",
								values: filter.multiValues,
							});
						} else if (filterName == "Units") {
							if (
								filter.property.name == "Name" &&
								!_.find(filter.selectedUnits, {
									name: "All Units",
								})
							) {
								tempReport.filter.push({
									key: "_id",
									collection: "Unit",
									values: _.map(filter.selectedUnits, "_id"),
								});
							} else if (
								filter.property.name == "Group" &&
								!filter.selectedGroups.includes("All Groups")
							) {
								tempReport.filter.push({
									key: "groups",
									collection: "Unit",
									values: filter.selectedGroups,
								});
							}
						} else if (
							filterName == "Shift" ||
							filterName == "Sites"
						) {
							if (
								filter.property.name == "Site" &&
								!_.find(filter.selectedSites, {
									name: "All Sites",
								})
							) {
								tempReport.filter.push({
									key: "_id",
									collection: "Site",
									values: _.map(filter.selectedSites, "_id"),
								});
							} else if (
								filter.property.name == "Schedule" &&
								!_.find(filter.selectedSchedules, {
									name: "All Schedules",
								})
							) {
								let scheduleIds = _.reduce(
									filter.selectedSchedules,
									(ids, schedule) => {
										ids.push(schedule._id);
										if (schedule.prevIds) {
											ids = ids.concat(schedule.prevIds);
										}
										if (schedule.prevId) {
											ids.push(schedule.prevId);
										}
										return ids;
									},
									[]
								);
								tempReport.filter.push({
									key: "_id",
									collection: "Schedule",
									values: scheduleIds,
								});
							} else if (
								filter.property.name == "Shift" &&
								!_.find(filter.selectedShifts, {
									name: "All Shifts",
								})
							) {
								tempReport.filter.push({
									key: "_id",
									collection: "Shift",
									values: _.map(filter.selectedShifts, "_id"),
								});
							}
						} else if (
							filterName == "Event Status" &&
							filter.multiValues &&
							filter.multiValues.length < 2
						) {
							if (filter.multiValues.includes("Open Events")) {
								tempReport.filter.push({
									key: "active",
									collection: "Event",
									values: true,
								});
							} else {
								tempReport.filter.push({
									key: "active",
									collection: "Event",
									values: false,
								});
							}
						} else if (filterName == "Account") {
							if (
								_.find(filter.selectedAccounts, {
									name: "All Accounts",
								})
							) {
								tempReport.filter.push({
									key: "_id",
									collection: "Account",
									values: _.map(self.privAccounts, "ref"),
								});
							} else {
								tempReport.filter.push({
									key: "_id",
									collection: "Account",
									values: _.map(
										filter.selectedAccounts,
										"ref"
									),
								});
							}
						} else if (filterName == "Unit") {
								tempReport.filter.push({
									key: "_id",
									collection: "Unit",
									values: filter.selectedUnit,
								});
						}
					});
				} else if (part.main == "Add Content") {
					tempReport.content = _.map(part.data, (o, contentName) => {
						if (o.checked) {
							let doc = { key: contentName };
							if (o.multiValues && o.multiValues.length > 0) {
								doc.values = o.multiValues;
							}
							return doc;
						}
					});
					tempReport.content = _.filter(tempReport.content);
				} else if (
					part.main == "Group Content" &&
					part.data !== "None"
				) {
					tempReport.groupBy = part.data;
				}
			});
		}
		if (tempReport.name == "Shift Summary") {
			tempReport.groupBy = "Shift";
		}
		// tempReport.utcOffset = self.moment().utcOffset();
		tempReport.timeZone = momentTZ.tz.guess();

		return tempReport;
	}

	generateFromTemplate(index, format) {
		let self = this;
		let template = self.reportTemplates[index];
		let tempReport = _.cloneDeep(template.query);
		tempReport.filterTo = self.moment(template.filterTo).valueOf();
		tempReport.filterFrom = self.moment(template.filterFrom).valueOf();
		tempReport.timeZone = momentTZ.tz.guess();
		tempReport.format = format;
		if (format == "xlsx") {
			if (tempReport.content) {
				delete tempReport.content;
			}
		}
		let query = this.Restangular.one("reports");
		query
			.customPOST(tempReport, "generateReport")
			.then((result) => {
				if (!self.openedTask) {
					self.openedTask = {};
				}
				self.generateReportAccordion = true;
				self.openedTask[result._id] = true;
			})
			.catch((err) => {
				self.toastr.error("Could not start report task.");
			});
	}

	generateReport(index, format) {
		let self = this;
		// let accountId = self.Auth.getCurrentAccountSync().ref;
		// let tempReport = _.cloneDeep(self.reports[index]);
		self.usSpinnerService.spin("spinner-1");
		self.reportErr = false;
		let tempReport = self.formatReport(self.reports[index]);
		tempReport.filterTo = self.moment(tempReport.filterTo).valueOf();
		tempReport.filterFrom = self.moment(tempReport.filterFrom).valueOf();
		tempReport.format = format;
		if (format == "xlsx") {
			if (tempReport.content) {
				delete tempReport.content;
			}
		}
		let query = this.Restangular.one("reports");
		// query.withHttpConfig({
		// 	responseType: 'blob'
		// });
		query
			.customPOST(tempReport, "generateReport")
			.then((result) => {
				// let file = new Blob([result], {
				// 	type: 'application/pdf'
				// });
				// let fileURL = URL.createObjectURL(file);
				// let pdfFile = self.$sce.trustAsResourceUrl(fileURL);
				// self.$window.open(pdfFile, '_blank');
				if (!self.openedTask) {
					self.openedTask = {};
				}

				self.generateReportAccordion = true;
				self.openedTask[result._id] = true;
				self.usSpinnerService.stop("spinner-1");
			})
			.catch((err) => {
				console.error(err);

				self.usSpinnerService.stop("spinner-1");
				self.reportErr = true;
				self.toastr.error("Could not start report task.");
			});
	}

	deleteTask(task) {
		let self = this;
		self.taskService.removeTask(task).then((result) => {
			self.toastr.info("Report task removed.");
		});
	}

	downloadReport(task) {
		let self = this;
		task.processes.push({ type: "Download", progress: 0 });
		let deregisterListener = self.$scope.$on(
			"xhrProgress",
			function (event, url, loadedPct) {
				let taskProcess = _.find(task.processes, ["type", "Download"]);
				if (taskProcess) {
					taskProcess.progress = Math.round(loadedPct);
				}
			}
		);
		let options = {
			method: "GET",
			url: "/api/reports/gets3Report",
			params: {
				url: task.taskResults.reportKey,
				format: task.taskOptions.query.format,
			},
			headers: {
				"Content-Type": "application/pdf",
			},
			responseType: "arraybuffer",
		};
		if (task.taskOptions.query.format === 'xlsx') {
			options.headers["Content-Type"] =
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
		} else if (task.taskOptions.query.format === 'video') {
			options.headers["Content-Type"] =
				"video/x-msvideo";
		}
		self.$http(options)
			.then((result) => {
				deregisterListener();
				let taskProcessIndex = _.findIndex(task.processes, [
					"type",
					"Download",
				]);
				if (taskProcessIndex >= 0) {
					task.processes.splice(taskProcessIndex, 1);
				}
				let file = new Blob([result.data], {
					type: options.headers["Content-Type"],
				});

				if (['xlsx', 'video'].includes(task.taskOptions.query.format)) {
					var downloadLink = angular.element("<a></a>");
					downloadLink.attr("href", URL.createObjectURL(file));
					downloadLink.attr("target", "_self");
					let fileName = task.alias+'.xlsx';
					if(task.taskOptions.query.format === 'video'){
						fileName = task.alias+'.avi';
					}
					downloadLink.attr("download", fileName);
					downloadLink[0].click();
				} else {
					let fileURL = URL.createObjectURL(file);
					let pdfFile = self.$sce.trustAsResourceUrl(fileURL);
					self.$window.open(fileURL, "_blank");
				}
			})
			.catch((err) => {
				console.error(err);
			});
		// let query = this.Restangular.one('reports');
		// query.withHttpConfig({
		// 	responseType: 'blob'
		// });
		// query.customGET('gets3Report',{url:task.taskResults.reportKey}).then((result) => {
		// 	let file = new Blob([result], {
		// 		type: 'application/pdf'
		// 	});
		// 	let fileURL = URL.createObjectURL(file);
		// 	let pdfFile = self.$sce.trustAsResourceUrl(fileURL);
		// 	self.$window.open(pdfFile, '_blank');
		// 	// self.usSpinnerService.stop('spinner-1')
		// }).catch((err) => {
		// });
	}

	validate(newData) {
		let self = this;
		let data = _.cloneDeep(newData);
		if (data) {
			if (typeof data === "object") {
				let keys = Object.keys(data);
				keys = _.filter(keys, (o) => {
					return o !== "$$hashKey";
				});
				if (keys && keys.length > 0) {
					return true;
				} else {
					return false;
				}
			} else if (typeof data === "string" && string.length > 0) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	log() {
		console.debug(this);
	}

	groupEvents(type) {
		return function (item) {
			let self = this;
			if (type == "Event Type") {
				if (
					item.match(/(Abscon)|(Site)|(Arri)|(Roam)/) ||
					item == "Geo-fence In" ||
					item == "Geo-fence Out"
				) {
					return "Site Related";
				} else if (item.match(/(Way)|(Route)/)) {
					return "Route Related";
				} else if (item == "All Events") {
					return "All Events";
				} else {
					return "General";
				}
			}
		};
	}

	checkAllType(doc, filter, index) {
		let self = this;
		if (filter.name == "Event Type") {
			if (doc.multiValues.includes("All Events")) {
				filter.availableItems = ["All Events"];
				doc.multiValues = ["All Events"];
			} else {
				// TODO: If time and attendace report only allow geofence events <23-05-19, Liaan> //
				if (self.reports[index].name == "Time and Attendance Summary") {
					filter.availableItems = [
						"Site Geo-fence In (BLE)",
						"Site Geo-fence In (GPS)",
						"Site Geo-fence Out (BLE)",
						"Site Geo-fence Out (GPS)",
						"Login",
						"Logout",
					];
				} else {
					filter.availableItems = self.availableEventTypes;
				}
			}
		} else if (filter.name == "Units" && doc.property) {
			if (doc.property.name == "Name") {
				if (_.find(doc.selectedUnits, { name: "All Units" })) {
					filter.availableUnits = [{ name: "All Units" }];
					doc.selectedUnits = [{ name: "All Units" }];
				} else {
					self.refreshUnits("", doc, filter);
				}
			} else if (doc.property.name == "Group") {
				if (doc.selectedGroups.includes("All Groups")) {
					filter.availableGroups = ["All Groups"];
					doc.selectedGroups = ["All Groups"];
				} else {
					self.refreshGroups(doc, filter);
				}
			}
		} else if (filter.name == "Shift" || filter.name == "Sites") {
			if (doc.property.name == "Schedule") {
				if (_.find(doc.selectedSchedules, { name: "All Schedules" })) {
					filter.availableSchedules = [{ name: "All Schedules" }];
					doc.selectedSchedules = [{ name: "All Schedules" }];
				} else {
					self.refreshSchedules("", doc, filter);
				}
			} else if (doc.property.name == "Site") {
				if (_.find(doc.selectedSites, { name: "All Sites" })) {
					filter.availableSites = [{ name: "All Sites" }];
					doc.selectedSites = [{ name: "All Sites" }];
				} else {
					self.refreshSites("", doc, filter);
				}
			} else if (doc.property.name == "Shift") {
				if (_.find(doc.selectedShifts, { name: "All Shifts" })) {
					filter.availableShifts = [{ name: "All Shifts" }];
					doc.selectedShifts = [{ name: "All Shifts" }];
				} else {
					self.refreshShifts("", index, doc, filter);
				}
			}
		} else if (filter.name == "Account") {
			if (_.find(doc.selectedAccounts, { name: "All Accounts" })) {
				filter.availableAccounts = [{ name: "All Accounts" }];
				doc.selectedAccounts = [{ name: "All Accounts" }];
			} else {
				self.refreshAccounts("", doc, filter);
			}
		}
	}
}

export default angular
	.module("secutraqApp.reports")
	.component("reportsadhoc", {
		template: require("./reports.adhoc.html"),
		controller: ReportsAdhocComponent,
		controllerAs: "$ctrl",
	})
	.decorator("$xhrFactory", [
		"$delegate",
		"$rootScope",
		function ($delegate, $rootScope) {
			return function (method, url) {
				var xhr = $delegate(method, url);
				xhr.addEventListener("progress", function (event) {
					$rootScope.$broadcast(
						"xhrProgress",
						url,
						(event.loaded / event.total) * 100
					);
				});
				return xhr;
			};
		},
	]).name;
