'use strict';
angular.module('secutraqApp.directives')
  .directive('materialButton', ['$document',
    function($document) {
        return {
      restrict: 'A',
      link: function(scope, el, attrs) {
          let toggleButton = el.find('.material-button-toggle');
          let options = el.find('.option');
        toggleButton.on('click', ($event) => {
            if(!el.hasClass('moving')) {
                if(!toggleButton.hasClass('open')) {
                    toggleButton.addClass('open');
                    options.addClass('scale-on');
                } else {
                    toggleButton.removeClass('open');
                    options.removeClass('scale-on');
                }
            }
        });

        options.on('click', ($event) => {
            if(toggleButton.hasClass('open')) {
                toggleButton.removeClass('open');
                options.removeClass('scale-on');
            }
        });
      }
    };
  }]);
