export class UserAccountComponent {
    accounts;
    Auth;
    currentAccountName;

    /*@ngInject*/
    constructor(Auth, NgTableParams, $element, $timeout, $scope,Restangular, $uibModal, $ngConfirm, accountService, $http, toastr) {
        this.Auth = Auth;
        this.NgTableParams = NgTableParams;
        this.$scope = $scope;
        this.tableHeaders;
        this.Restangular = Restangular;
        this.$uibModal = $uibModal;
        this.$ngConfirm = $ngConfirm;
        this.accountService = accountService;
		this.$http = $http;
		this.toastr = toastr;
    }

    $onInit() {
        let self = this;
        this.accounts = this.Auth.getCurrentUserSync().accounts;
        this.currentAccountName = this.Auth.getCurrentAccountSync().name;
        this.Accounts = this.Restangular.all('accounts');
        self.filter = '';


        this.cols = [
            { field: "_id", title: "ID", sortable: "_id", show: true},
            { field: "name", title: "Name", sortable: "name", show: true},
            { field: "active", title: "Active", show: true},
            { field: "sopClassificationTags", title: "SOP Classifications", show: true},
        ];
        self.hasAccountPriv = false;
        self.Auth.hasPrivilege('account').then((has) => {
            if(has) {
                self.cols.push({ field: "removeAccount", title: "Disable Account", show: true });
				self.hasAccountPriv = true;
				self.$http.get(`/api/accounts/${this.Auth.getCurrentAccountSync().ref}`).then( (res) => {
					self.account = res.data;
				} )
.catch( (err) => {
					console.error(err);
				} );


            }
        });
        self.tableParams = new self.NgTableParams({
            page: 1, // start with first page
            count: 10, // count per page
            sorting: {
                name: 'asc' // initial sorting
            }
        },

        {
            total: 0,
            getData(params) {
                let order;
                if(params && params.sorting) {
                    order = params.sorting();
                    return self.Accounts.getList({
                        filter: self.filter.length ? self.filter : undefined,
                        skip: (params.page()-1)*params.count(),
                        limit: params.count(),
                        by: Object.keys(order)[0],
                        order: order[Object.keys(order)[0]]})
                        .then(function(accounts) {
                            self.accounts = accounts;
                            self.total = accounts.total;
                            params.total(accounts.total);
                            return accounts;
                        })
                        .catch(err => {
                            console.error("Error when retrieving accounts:" , err.data.err);
                        });
                    }
                }
            }
        );
        // end onInit
    }

    openCreateAccount() {
        let self = this;
        let modalInstance = self.$uibModal.open({
            component: 'accountmodal',
            bindToController: true,
            size: 'md',
            backdrop: 'static',
            resolve: {
                settings() {
                    return {
                        create: true,
                    };
                }
            }
        });

        modalInstance.result.then(function(result) {
            self.tableParams.reload();
        });
    }

    disableAccount(accountId) {
        let self = this;

        self.$ngConfirm(
            {
                title: `Disable Account`,
                theme: 'light',
                animation: 'top',
                scope: self.$scope,
                closeAnimation: 'bottom',
                content: '<h3>Are you sure you want to disable this account?</h3><h4>All unit will be moved to the default account.</h4>',
                escapeKey: true,
                backgroundDismiss: true,
                buttons: {
                    // long hand button definition
                    ok: {
                        text: "Yes",
                        btnClass: 'btn-primary',
                        keys: ['enter'], // will trigger when enter is pressed
                        action(scope) {
                            return self.accountService.removeAccount(accountId).then((result) => {
                                self.toastr.info('Account disabled.');
                            })
.catch((err) => {
                                self.toastr.info('Account deactivation failed.');
                            });
                        }
                    },
                    close: {
                        text: "No",
                        action(scope) {

                        }

                    }
                },
            }
        );
    }

	toggleEnforceMFA() {
		let self = this;
		let enforce = self.account.mfaEnforce;
		self.$http.post(`/api/accounts/${self.account._id}/enforceMFA`, {enforce}).then( (res) => {
			if(res) {
				self.toastr.info(`Account updated.`,{
					preventOpenDuplicates:true
				});
				self.account = res.data;
				let user = self.Auth.getCurrentUserSync();
				if(user && user.mfa && user.mfa.enrolled) {
				}else if(self.account.mfaEnforce) {
					let modal = self.$uibModal.open({
						component: 'totp',
						backdrop: 'static',
						keyboard: false,
						resolve:{
							mfa:{enabled:true,enroled:false},
							modal:true,
							forced:true
						}
					});

				}
			}
		} )
.catch( (err) => {
			console.error(err);
		} );
	}

	saveLogoutSettigns() {
		let self = this;
		self.$http.patch(`/api/accounts/${self.account._id}/config`, self.account).then( (res) => {
			self.toastr.info('Updated account settings');
		})
.catch( (err) => {
			self.toastr.err('Update failed.');
			console.error(err);
		} );

	}

}

export default angular.module('secutraqApp.settings')
.component('accountsettings', {
    template: require('./settings.account.html'),
    controller: UserAccountComponent,
    controllerAs: '$ctrl'
})
.name;
