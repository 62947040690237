import angular from 'angular';
import uiRouter from 'angular-ui-router';
import routes from './events.routes';
import uiBootstrap from 'angular-ui-bootstrap';
import NgTable from 'ng-table/bundles/ng-table';
import ngSanitize from 'angular-sanitize';


export default angular.module('secutraqApp.events', [uiRouter, 'ngTable', uiBootstrap])
  .config(routes)
  .name;
require('./events.component');
require('./photo-panel/photo-panel.component');
require('./event-view-modal/event-view.modal');
require('./action-event/action-event.modal');
