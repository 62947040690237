export class GenericMapService {
    /*@ngInject*/
    constructor($timeout, $interval, eventCarouselService, mapService, $sanitize) {
        let self = this;
        this.$timeout = $timeout;
		this.$sanitize = $sanitize;
        this.mapService = mapService;
        self.geocoder;
        // L.mapbox.accessToken = 'pk.eyJ1Ijoicm9tcGVsc3RvbXBlbCIsImEiOiJjamNoaHJmOWkxaDFqMnlydnV1d202MTA2In0.vaZnBgwNagSx6wAljOUEMQ';

        self.defaultMarker = L.icon({
            iconUrl: 'assets/images/markers/latearrival.png',
            iconSize: [17, 28],
            popupAnchor: [0, 0]
        });

        self.flagMarker = L.icon({
            iconUrl: 'assets/images/markers/flag.png',
            iconSize: [17, 28],
            popupAnchor: [0, 0],
			iconAnchor: [1, 28], // point of the icon which will correspond to marker's location
        });

        self.circleMarker = L.icon({
            iconUrl: 'assets/images/markers/circle6.png',
            iconSize: [18, 18],
            popupAnchor: [0, 0]
        });

        self.siteMarker = L.icon({
            iconUrl: 'assets/images/markers/map-marker-alt.svg',
			iconSize: [30,56], // size of the icon
			iconAnchor: [15,56], // point of the icon which will correspond to marker's location
			popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
        });


        // self.mapLayer = Tangram.leafletLayer({
        //     scene: 'cinnabar-style-more-labels.zip',
        //     attribution: '<a href="https://mapzen.com/tangram" target="_blank">Tangram</a> | &copy; OSM contributors | <a href="https://mapzen.com/" target="_blank">Mapzen</a>'
        // });

    }

    generateMap(mapId,options) {
        let self = this;
        let accessToken = 'pk.eyJ1Ijoicm9tcGVsc3RvbXBlbCIsImEiOiJjamNoaHJmOWkxaDFqMnlydnV1d202MTA2In0.vaZnBgwNagSx6wAljOUEMQ';
        let w3wAccessToken = 'IFXORIGZ';
        let neutrinoAccessToken = 'xi2f8ruxrpgVSsI6coMga76QSkY0BW7PgLghcN8H5IDX7OHC';
        let googleAPIKey = 'AIzaSyCl6k2GTrhQyJO91wYxSgB54QTP2-lQgC8';
        let neutrinoUserName = 'Liaan';
        let map = L.map(mapId, options);
        let streetsLayer = L.gridLayer.googleMutant({
            type: 'roadmap',
            iconURL: 'assets/images/map/street.png'
        }).addTo(map);

        // let streetsLayer = L.tileLayer('https://api.mapbox.com/v4/{map_id}/{z}/{x}/{y}.png?access_token={accessToken}',{
        //     attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>',
        //     map_id:'mapbox.streets',
        //     accessToken:accessToken,
        //     maxZoom: 20,
        //     label:'Streets'
        // }).addTo(map);
        if(options.satellite) {
            // let satelliteLayer = L.tileLayer('https://api.mapbox.com/v4/{map_id}/{z}/{x}/{y}.png?access_token={accessToken}',{
            //     attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>',
            //     map_id:'mapbox.streets-satellite',
            //     accessToken:accessToken,
            //     maxZoom: 16,
            //     label:'Satellite',
            //     // iconURL : 'AAAAAAAAAAA'
            //
            // });
            let satelliteLayer = L.gridLayer.googleMutant({
                type: 'hybrid',
                iconURL: 'assets/images/map/satellite.png'
            });

            // L.control.layers({'Street':streetsLayer,'Satelite':satelliteLayer}, {}, options.satellite).addTo(map);

            let baseMaps = [streetsLayer,satelliteLayer];
            let satelliteOptions = {
                basemaps : baseMaps,
                position:'topright'
            };
            if(typeof options.satellite === "object") {
                satelliteOptions = _.merge(satelliteOptions, options.satellite);
            }
            let baseMapControl = L.control.basemaps(satelliteOptions);
            map.addControl(baseMapControl);
            if(options.satellite.addOffset) {
                self.$timeout(function() {
                    let container = $(baseMapControl.getContainer());
                    container.css("margin-bottom","8.5em");
                }, 0);
            }

        }

        if(typeof options.zoomFunction === 'function') {
            L.Control.zoomToAll = L.Control.extend({
                onAdd(map) {

                    let button = `<button class="btn btn-default" style="padding:6px 7px;" title="Zoom to all events and units."><i class="fa fa-binoculars"></i></button>`;
                    var className = 'leaflet-zoom-to-all-control';
                    var container = L.DomUtil.create('div', `${className} leaflet-bar`);
                    angular.element(container).append(button); //to see it

                    L.DomEvent.addListener(container, 'click', function(e) {
                        options.zoomFunction();
                    });
                    // Search a lot for this click also.
                    return container;
                }
            });


            L.control.zoomToAll = function(opts) {
                return new L.Control.zoomToAll(opts);
            };

            L.control.zoomToAll({ position: 'topright' }).addTo(map);

        }
        if(options.showSites) {

            L.Control.showSites = L.Control.extend({
                onAdd(map) {

                    let button = `<button class="btn btn-default" style="padding:3px 7px;" title="Show/hide sites on map."><i class="fas fa-map-marker-alt" style="font-size:1.5em;"></i></button>`;
                    var className = 'leaflet-show-sites-control';
                    var container = L.DomUtil.create('div', `${className} leaflet-bar`);
                    angular.element(container).append(button); //to see it

                    L.DomEvent.addListener(container, 'click', function(e) {
                        self.mapService.toggleSitesOnMap(map);
                    });
                    // Search a lot for this click also.
                    return container;
                }
            });


            L.control.showSites = function(opts) {
                return new L.Control.showSites(opts);
            };

            L.control.showSites({ position: 'topright' }).addTo(map);
			if(options.showSites.enabled == true) {
				self.$timeout(function() {
					self.mapService.toggleSitesOnMap(map);
				},0);
			}
        }
        // TODO: Get a geocoder that works better. disable for now
        if(options.geocoder) {
            self.geocoder = L.Control.Geocoder.google(googleAPIKey);
            let geocoderOptions = {
                geocoder:self.geocoder,
            };
            if(typeof options.geocoder === "object") {
                geocoderOptions = _.merge(options.geocoder,geocoderOptions);
            }
            L.Control.geocoder(geocoderOptions).addTo(map);
        }


        if(options.clusterMarkers) {
            //default
            map.enableClustering = false;

            L.Control.toggleClustering = L.Control.extend({
                onAdd(map) {

                    let button = `<button class="btn btn-default" style="padding:0px 5px;" title="Toggle marker clustering."><i class="far fa-dot-circle" style="font-size:1.5em;padding-top:6px;padding-bottom:5px;"></i></button>`;
                    var className = 'leaflet-toggle-clustering-control';
                    var container = L.DomUtil.create('div', `${className} leaflet-bar`);
                    angular.element(container).append(button); //to see it

                    L.DomEvent.addListener(container, 'click', function(e) {
                        self.mapService.toggleClustering(map);
                    });
                    // Search a lot for this click also.
                    return container;
                }
            });


            L.control.toggleClustering = function(opts) {
                return new L.Control.toggleClustering(opts);
            };

            L.control.toggleClustering({ position: 'topright' }).addTo(map);
            //Disable by default
            if(options.clusterMarkers.enabled) {
                self.$timeout(function() {
                    self.mapService.toggleClustering(map);
                },0);
            }

        }
        return map;
    }

    getMapMarker() {
        let self = this;
        return new Promise((resolve, reject) => {
            resolve(self.defaultMarker);
        });
    }

    /**
     * My goal is to specify all our markers here, and that we can retrieve them by using certain
     * key-phrases.
     * NOTE: Using promises here doesn't make sense
     * TODO: Don't use promises. For now, use the sync method
     */
    getSpecifiedMarker(type) {
        let self = this;

        switch (type) {
            case "default":
                return new Promise((resolve, reject) => {
                    resolve(self.defaultMarker);
                });

            case "circle":
                return new Promise((resolve, reject) => {
                    resolve(self.circleMarker);
                });

            case "flag":
                return new Promise((resolve, reject) => {
                    resolve(self.flagMarker);
                });

                break;
            default:

        }
    }

    getSpecifiedMarkerSync(type) {
        let self = this;
        switch (type) {
            case "default":
                return self.defaultMarker;

            case "circle":
                return self.circleMarker;

            case "flag":
                return self.flagMarker;

            case "site":
                return self.siteMarker;

                break;
            default:
        }
    }

    // getMapLayer() {
    //     let self = this;
    //     return new Promise((resolve, reject) => {
    //         resolve(mapLayer);
    //     });
    // }

    setMapView(map, loc, zoom) {
        let self = this;
        map.setView(loc, zoom);
    }

    /**
     * To simply place a marker somewhere on a map. NOTE that options are optional
     * But we do need
     * @param map - the map that the marker should be added to
     * @param icon - the icon to be placed. TODO: If null is provided we use self.defaultMarker
     * @param loc - a latlng object
     */
    placeMarkerAtCoordinates(map, icon, loc, options) {
        let self = this;
        let marker;
        if (options) {
            _.merge(options, {icon});
            marker = L.marker(loc, options);
        } else {
            marker = L.marker(loc, {icon});
        }
        marker.addTo(map);
        return marker;
    }

    /**
     * To simply place a marker somewhere on a map. NOTE that options are optional
     * But we do need
     * @param  {[type]} map           [a layer to which the marker should be added to]
     * @param  {[type]} specifiedIcon [a text string]
     * @param  {[type]} loc           [a _latlng object]
     * @param  {[type]} options       [options]
     */
    placeSpecifiedMarkerAtCoordinates(map, specifiedIcon, loc, options) {
        let self = this;
        let icon = self.getSpecifiedMarkerSync(specifiedIcon);
        let marker;
        if (options) {
            _.merge(options, {icon});
            marker = L.marker(loc, options);
        } else {
            marker = L.marker(loc, {icon});
        }
        marker.addTo(map);
        return marker;
    }

    /**
     * To draw a polygon on a layer from an array of latlngs
     * @param  {[type]} map     [The layer to draw on]
     * @param  {[type]} loc     [array of latlngs]
     * @param  {[type]} options [optional options] - it none is given, we have defautlOptions
     * @return {[type]}         [The drawn polygon]
     */
    drawPolygonFromLoc(map, loc, options) {
        let self = this;
        let defaultOptions = {color: '#2C98DE', weight: 6};
        let polygon;
        try {
            if(options) {
                polygon = new L.polygon(loc, options).addTo(map);
            } else {
                polygon = new L.polygon(loc, defaultOptions).addTo(map);
            }
            return polygon;
        } catch (e) {
            console.error("Something went wrong when drawing your polygon:");
            console.error(e);
        }
    }

    /**
     * NOTE: Consider adding radius to defaultOptions
     * To draw a circle on a layer
     * @param  {[type]} map     [the layer to draw onto]
     * @param  {[type]} loc     [the latlng of the center]
     * @param  {[type]} options [optional options]
     * @return {[type]}         [description]
     */
    drawCircleFromLoc(map, loc, options) {
        let self = this;
        let defaultOptions = {color: '#2C98DE', weight: 6};
        let circle;
        try {
            if(options) {
                circle = new L.circle(loc, options).addTo(map);
            } else {
                circle = new L.circle(loc, defaultOptions).addTo(map);
            }
            return circle;
        } catch (e) {
            console.error("Something went wrong when drawing your circle:");
            console.error(e);
        }
    }

    /**
     * Just a basic function that allows us to draw a Polyline
     * From point A to B
     */
    drawPolylineFromLoc(map, loc, options) {
        let self = this;
        let polyline;
        if(options) {
            polyline = L.polyline(loc, options).addTo(map);
        } else {
            polyline = L.polyline(loc).addTo(map);
        }
        return polyline;
    }

    /**
     * @param layer - The layer of the _container _icon class. This means that when calling this we should
     * pass myLayer._tooltip._container.
     * TODO: Write this method that the above won't have to be necessary, ie, deduce where the appropriate _container would be,
     * whether we are working with a marker, a popup, tooltip, etc.
     * OR simply have unique methods, like addClassToTooltip, addClassToPopup...
     */
    addClassToLayer(layer, className) {
        let self = this;
        L.DomUtil.addClass(layer, className);
    }

    /**
     * @param layer - The layer of the _container _icon class.
     */
    removeClassFromLayer(layer, className) {
        let self = this;
        L.DomUtil.removeClass(layer, className);
    }

    /**
     * TODO Add options
     */
    addTooltipToLayer(layer, content) {
        let self = this;
        let tooltip = L.tooltip();
        tooltip.setContent(self.$sanitize(content));
        layer.bindTooltip(tooltip);
        return tooltip;
    }

    /**
     * I'm trying to keep this methods as generic as possible.
     * Options are 'hover', 'click', 'permanent', 'disable'
     * I'm removing all tooltips, everytime, and adding a new tooltips
     * This is because I cannot change the options of each tooltip with a function.
     * I have to set a new tooltip with a new set of options
     */
    setLayerTooltipOptions(layer, option) {
		let self = this;

        let options = ['hover', 'click', 'permanent', 'disable'];
        let config = {};
        let content = layer.getTooltip() === null ? "" : layer.getTooltip()._content;

        layer.closeTooltip();
        layer.unbindTooltip();


        if(_.indexOf(options, option) !== -1) {
            if(option === 'hover') {
                config.permanent = false;

            } else if(option === 'click') {
                //this is odd behaviour for a tooltip
                //Consdider either figuring this out, or rather making use of popup
            } else if(option === 'permanent') {
                config.permanent = true;
            } else if(option === 'disable') {
                config.opacity = 0;
            }

            let tooltip = L.tooltip(config);
            tooltip.setContent(self.$sanitize(content));
            layer.bindTooltip(tooltip);
        }
    }

    setLayerPopupOptions(layer, option) {
        let options = ['hover', 'click', 'permanent', 'disable'];
        let config = {};
        let content = layer.getTooltip() === null ? "" : layer.getTooltip()._content;

        if(_.indexOf(options, option) !== -1) {
            if(option === 'hover') {
                layer.on('mouseover', ($event) => {
                    if(!layer.isPopupOpen()) {
                        layer.openPopup();
                    }
                });
                layer.on('mouseout', ($event) => {
                    if(layer.isPopupOpen()) {
                        layer.closePopup();
                    }
                });
            }
        }
    }

    fitMapToLoc(map, loc, options) {
        let self = this;

        if(!map || !loc) {
            console.error("Consider the paramters given to fitMapToLoc()");
        }
        if(loc.length === 0) {
            console.error("No latlngs are available to fit the map to");
        } else if(loc.length === 1) {
            if(options) {
                if(Object.keys(options).indexOf("padding") !== -1) {
                    // console.error("You are providing some options that aren't allowed here");
                    options = _.omit(options, ['padding']);
                }
                if(Object.keys(options).length > 0) {
                    map.setView(loc[0], options);
                } else {
                    map.setView(loc[0], 15);
                }
            } else {
                map.setView(loc[0], 15);
            }
        } else if(options) {
                map.fitBounds(loc, options);
            } else {
                map.fitBounds(loc, {padding: [40, 40], maxZoom: 17});
            }
    }

    /**
     * Think - Show this layer WHEN I REACH this zoom level
     * @param zoomLevel - If we zoom out and pass this level, we remove layer
     * If we zoom in pass this level, we add level
     */
    showLayerWithZoom(map, layer, zoomLevel) {
        let self = this;
        map.on('zoomend', ($event) => {
            let zoom = map.getZoom();
            if(zoom < zoomLevel && map.hasLayer(layer)) {
                map.removeLayer(layer);
            } else if(zoom >= zoomLevel && !map.hasLayer(layer)) {
                map.addLayer(layer);
            }
        });
    }

    /**
     * Think - Remove this layer WHEN I REACH this zoom level
     * @param zoomLevel - If we zoom in pass this level, we remove layer
     * If we zoom out and pass this level, we add layer
     */
    hideLayerWithZoom(map, layer, zoomLevel) {
        let self = this;
        map.on('zoomend', ($event) => {
            let zoom = map.getZoom();
            if(zoom > zoomLevel && map.hasLayer(layer)) {
                map.removeLayer(layer);
            } else if(zoom <= zoomLevel && !map.hasLayer(layer)) {
                map.addLayer(layer);
            }
        });
    }

    removeLayerFromMap(map, layer) {
        let self = this;
        if(map.hasLayer(layer)) {
            map.removeLayer(layer);
        } else {
            console.error("Cannot remove layer from map because map doensn't have it in the first place");
        }
    }
}

export default angular.module('secutraqApp.dashboard')
.service('genericMapService', GenericMapService);
