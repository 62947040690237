
export class EventViewComponent {

    /*@ngInject*/
    constructor(NgTableParams, $uibModal,
        $scope, moment, $timeout, $filter,
        photoService, eventService, unitService, Restangular, fieldReportService,
        Auth, $ngConfirm, toastr, userService, siteService, liveStreamService) {

        this.photoService = photoService;
        this.$uibModal = $uibModal;
        this.NgTableParams = NgTableParams;
        this.$scope = $scope;
        this.moment = moment;
        this.$timeout = $timeout;
        this.$filter = $filter;
        this.eventService = eventService;
        this.unitService = unitService;
        this.liveStreamService = liveStreamService;
        this.Restangular = Restangular;
        this.events = [];
        this.Auth = Auth;
        this.$ngConfirm = $ngConfirm;
        this.toastr = toastr;
        this.userService = userService;
        this.siteService = siteService;
        this.fieldReportService = fieldReportService;
    }

    $onInit() {
        let self = this;
        self.$uibModalInstance = self.modalInstance;
        self.event = self.resolve.event;
        self.eventDetails = self.resolve.eventDetails;

        self.activeSecondaryTab = "Timeline";
        self.availableContacts = [];
        self.Contacts = self.Restangular.all('contacts');
        self.availableUsers = [];

        self.getAvailableUsers();
        /**
         * We are looking towards this object from within HTML to know whether our uib-tabset is active
         * or not. I've been forced ot use this strange approach because of issues with the rotation of the tabs
         * @see setCurrentSecondaryTab()
         * options are:
         *   i) Timeline
         *  ii) Assign
         * iii) Notes
         */
        self.isActive = { Timeline: 0, Notes: null, Assign: null };


        // **********************ngTable--Schedule************* //

        // **********************ngTable--Notes************* //
        /**
         * TODO: Implement the functionality that is needed to make this practically operable.
         * This means that we must think how notes are intertwined with events and how we display
         * notes accordingly.
         *
         * We want to show all notes for a person, (like Steve Butler), but we also want to know
         * which notes are relevant to which events generated by Steve.
         *
         * We're currently thinking of a view where we can see ONLY events history (Timeline View)
         * But then we also want a view where we perhaps can have a combination of events + seeing
         * which notes are applied to which event.
         *
         * But now that begs the question of whether we'll also have a "notes-only" view.
         *
         * What if I phone Martin Holdt and tell him to go assist Steve and I want to make a note of this.
         */

        self.showNoteEditor = false;
        self.noteEditorContent = '';
        self.dataNotes = [];
        self.colsNotes = [
            { field: "ts", title: "When", sortable: "ts", show: true },
            { field: "note", title: "Note", sortable: "note", show: true },
            { field: "username", title: "Controller", show: true }
        ];
        self.tableParamsNotes = new self.NgTableParams({
            page: 1, // start with first page
            count: 10, // count per page
            sorting: {
                ts: 'asc' // initial sorting
            }
        },
            {
                total: 0,
                getData(params) {
                    let sort = params.sorting();
                    let by = Object.keys(sort)[0];
                    let order = sort[Object.keys(sort)[0]];

                    if (self.dataNotes && self.dataNotes.length > 0) {
                        self.dataNotes = self.dataNotes.sort((a, b) => {
                            if (a[by] && b[by]) {
                                if (order == 'asc') {
                                    return a[by] <= b[by];
                                } else {
                                    return b[by] <= a[by];
                                }
                            }
                        });
                        params.total(self.dataNotes.length);
                        return self.dataNotes.slice((params.page() - 1) * params.count(), params.page() * params.count());
                    } else {
                        return self.dataNotes;
                    }
                }
            });
        // **********************ngTable--Notes************* //
        self.colsRelatedEvents = [
            { field: "createdAt", title: "When", sortable: "createdAt", show: true },
            { field: "diff", title: "Time Difference", show: true },
            { field: "sitename", title: "Site", sortable: "site", show: true },
            { field: "assetname", title: "Unit : Asset", show: true },
            { field: "closeEvent", title: "Close", show: true }
        ];
        self.tableParamsRelatedEvents = new self.NgTableParams({
            page: 1, // start with first page
            count: 10, // count per page
            sorting: {
                ts: 'asc' // initial sorting
            }
        },
            {
                total: 0,
                getData(params) {
                    let sort = params.sorting();
                    let by = Object.keys(sort)[0];
                    let order = sort[Object.keys(sort)[0]];
                    let promise;
                    if (!self.events) {
                        promise = self.eventService.getEvents();
                    } else {
                        promise = Promise.resolve(self.events);
                    }
                    return promise.then((events) => {

                        self.events = events;
                    });
                }
            });

        self.currentUnit = self.unitService.getUnitByID(self.event.unit).then((unit) => {
            self.unit = unit;
            self.unitSim1 = unit.sim1;
            self.unitSim2 = unit.sim2;
        });
        self.dataNotes = _.cloneDeep(self.event.notes);
        self.tableParamsNotes.reload();
        if (self.event.asset) {
            self.queryValue = self.event.asset;
            self.queryField = 'asset';
        } else {
            self.queryValue = self.event.unit;
            self.queryField = 'unit';
        }

        self.eventTabs = [
            { title: "Timeline", icon: null, color: null },
            { title: "Notes", icon: null, color: null },
            { title: "Assign", icon: null, color: null }
        ];
    }

    // Modal cancelled: either cancel pressed or clicked off screen
    close() {
        this.$uibModalInstance.close();
    }

    phoneUnit() {
        let self = this;

        self.$ngConfirm(
            {
                title: `<span style="margin:auto;">Phone ${self.event.unitname}</span>`,
                theme: 'modern',
                animation: 'top',
                scope: self.$scope,
                closeAnimation: 'bottom',
                content: require('../../dashboard/selected-event/phone-unit.html'),
                escapeKey: true,
                backgroundDismiss: true,
                buttons: {
                    // long hand button definition
                    // ok: {
                    //     text: "Yes",
                    //     btnClass: 'btn-primary',
                    //     keys: ['enter'], // will trigger when enter is pressed
                    //     action: function(scope) {
                    //
                    //
                    //     }
                    // },
                    close: {
                        text: "Close",
                        action(scope) {

                        }

                    }
                },
            }
        );
    }

    goToAddNote() {
        let self = this;
        self.setCurrentSecondaryTab({ title: "Notes" });
    }

    setCurrentSecondaryTab(tab, $event) {
        let self = this;
        let title = tab.title;
        if ($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }
        _.forEach(self.isActive, (value, tabTitle) => {
            if (tabTitle !== tab.title) {
                self.isActive[tabTitle] = 1;
            } else {
                self.isActive[tabTitle] = 0;
                self.activeSecondaryTab = title;
            }
        });

    }

    requestSnapshot(unitId) {
        let self = this;
        self.unitService.requestSnapshot(unitId).then((result) => {
            if (result) {
                self.toastr.info('Snapshot requested.');
            } else {
                self.toastr.warning('Snapshot request already pending.');
            }
        })
            .catch((err) => {
                self.toastr.error('Snapshot request failed.');
                console.error(err);
            });
    }

    requestSiteSnapshot(siteId) {
        let self = this;
        self.siteService.requestSiteSnapshots({ _id: siteId }, self.$scope);
    }

    escalateEvent(eventId) {
        let self = this;
        self.escalateToContact = undefined;

        self.$ngConfirm(
            {
                title: `<span style="margin:auto;">Escalate Event</span>`,
                theme: 'modern',
                animation: 'top',
                scope: self.$scope,
                closeAnimation: 'bottom',
                content: require('../../dashboard/selected-event/select-contact.html'),
                escapeKey: true,
                backgroundDismiss: true,
                buttons: {
                    // long hand button definition
                    ok: {
                        text: "Escalate",
                        btnClass: 'btn-primary',
                        keys: ['enter'], // will trigger when enter is pressed
                        action(scope) {
                            self.eventService.escalateEvent(self.event._id, self.escalateToContact).then((event) => {
                                if (event) {
                                    self.toastr.info('Event escalation sent.');
                                }
                            })
                                .catch(err => {
                                    self.toastr.error('Event escalation failed.');
                                    console.error(err);
                                });
                        }
                    },
                    close: {
                        text: "Cancel",
                        action(scope) {

                        }

                    }
                },
            }
        );
    }

    refreshContacts(searchVal) {
        let self = this;
        self.Contacts.getList({
            filter: searchVal,
            limit: 20,
        }).then((contacts) => {
            self.availableContacts = contacts;
        })
            .catch((err) => {
                console.error(err);
            });
    }

    openReport(event) {
        let self = this;
        self.fieldReportService.getFieldReportByID(event.metaData.reportId).then((report) => {
            let modalInstance = self.$uibModal.open({
                component: 'viewFieldReport',
                backdrop: 'static',
                resolve: {
                    report: _.cloneDeep(report),
                }
            });
        })
            .catch((err) => {
                console.error(err);

            });
    }

    doLog() {
        console.debug(this);
    }


    checkFailedLoginAndAction(event) {
        let self = this;
        if (event.active && event.eventType == 'Failed Login') {
            self.handleFailedLogin(event);
        } else {
            self.actionEvent(event);
        }
    }

    allowFailedLogin(event) {
        let self = this;
        self.eventService.allowLogin(event)
            .then(() => {
                return self.actionEvent(event);
            })
            .catch((err) => console.error(err));
    }

    actionEvent(event){
        let self = this;
        let promise;
        if(event.active){
            promise = self.eventService.actionEvents([event]).then(() => {
                self.close();
            })
        }else{
            promise = self.eventService.showEventSOP(event);
        }
        promise.catch((err) => {
            console.error(err);
            self.toastr('Error closing event!');
        } )
    }


    focusNoteEditor($event) {
        let self = this;
        if ($event) {
            $event.stopPropagation();
            $event.preventDefault();
        }

        if ($event.currentTarget) {
            $event.currentTarget.focus();
        } else {
            document.getElementById("styledEditor").focus();
        }
    }

    addNote() {
        let self = this;
        if (self.event._id) {
            if (self.noteEditorContent !== '') {
                let newEvent = _.cloneDeep(self.event);
                if (!newEvent.notes) {
                    newEvent.notes = [];
                }
                newEvent.notes.push({ ts: self.moment.utc().toDate(), note: self.noteEditorContent, user: self.Auth.getCurrentUserSync()._id, username: `${self.Auth.getCurrentUserSync().firstname} ${self.Auth.getCurrentUserSync().lastname}` });
                self.eventService.updateEvent(newEvent);
                self.noteEditorContent = '';
                self.showNoteEditor = false;
            }
        }
    }

    /**
     * TODO finish this
     */
    goToAddNote() {
        let self = this;
        self.setCurrentSecondaryTab({ title: "Notes" });
    }

    openFootage(snapshot) {
        let self = this;
        self.$uibModal.open({
            animation: true,
            backdrop: 'static',
            keyboard: true,
            template: require('../../photoAnalytics/imageCollection/modal.html'),
            controller: PhotoModalController,
            controllerAs: '$ctrl',
            size: 'lg',
            resolve: {
                snapshot() {
                    return snapshot;
                },
                snapPlaceholder() {
                    return self.eventCarouselMetaData.footages;
                }
            }
        }).result.then(function (result) {
            if (result && result.newSnap) {
                self.openFootage(result.newSnap);
            } else if (result && result.next) {
                //self.openNext();
            } else if (result && result.previous) {
                //self.openPrevious();
            }
        }, function () { });
    }

    reassignEvent() {
        let self = this;
        self.$ngConfirm(
            {
                title: `<span style="margin:auto;">Reassign</span>`,
                theme: 'dark',
                animation: 'top',
                scope: self.$scope,
                columnClass: 'xlarge',
                closeAnimation: 'bottom',
                // content: require('./footage-modal.html'),
                content: `<div>
                    Are you sure you want to reassign this event.
                </div>`,
                escapeKey: false,
                backgroundDismiss: true,
                buttons: {
                    // long hand button definition
                    ok: {
                        text: "Reassign",
                        btnClass: 'btn-primary',
                        keys: ['enter'], // will trigger when enter is pressed
                        action(scope) {
                            let newEvent = _.cloneDeep(self.event);
                            newEvent.reassignTo = self.reassignToUser._id;
                            self.eventService.updateEvent(newEvent);
                        }
                    },
                    close: {
                        text: "Cancel",
                        action(scope) {

                        }

                    }
                },
            }
        );
    }

    getAvailableUsers(searchVal) {
        let self = this;
        return self.userService.getUsersWithQuery({
            filter: searchVal,
            limit: 20,
        }).then((response) => {
            let users = response.data;
            self.availableUsers = _.filter(users, (o) => {
                return o._id !== self.Auth.getCurrentUserSync()._id;
            });
        });
    }


}
export default angular.module('secutraqApp.events')
    .component('eventViewModal', {
        template: require('./event-view.modal.html'),
        controller: EventViewComponent,
        controllerAs: "$ctrl",
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&',
            modalInstance: '<'
        }
    });
