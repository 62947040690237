export class TenantCreateModalController {
    $stateParams;
    $uibModalInstance;
    settings;
    Auth;
    contact;
    modalInstance;
    $http;

    /*@ngInject*/
    constructor($stateParams, $http, toastr,$scope, Auth) {
        this.Auth = Auth;
        this.$stateParams = $stateParams;
        this.toastr = toastr;
        this.valid = true;
        this.$scope = $scope;
        this.$http = $http;
    }

    $onInit() {
        let self = this;
        self.settings = self.resolve.settings;
        self.$uibModalInstance = self.modalInstance;
        if(self.settings.tenant) {
            let tenant = self.settings.tenant;
            self.tenant = tenant;
            self.edit = true;
        } else {
            self.edit = false;
            self.tenant = {
                alias:"New Tenant",
                accounts: [{ref:self.settings.account.ref, name:self.settings.account.name}],
            };
        }
        self.accounts = [];

        self.Auth.getCurrentUserSync().accounts.forEach(acc => {
            let ind = _.findIndex(self.tenant.accounts, o => {
                return o.ref == acc.ref;
            });
            if(ind == -1) {
                self.accounts.push({ref:acc.ref, name:acc.name});
            }
        });
    }

    // Setup all the models for display
    // Modal accept: send resulting object back to promise in parent controller
    ok() {
        let self = this;
        let result = self.tenant;
        // _.forEach(result.ids,(id)=>{
        // 	id.id = new Buffer.from(id.id,'hex');
        // })
        if(self.edit) {
            self.$http.patch(`/api/adTenant/${self.tenant._id}`, self.tenant).then(response => {
                self.toastr.info(`Tenant updated.`);
                self.$uibModalInstance.close(response);
                // self.toastr.error(`Contact create failed.`);
            })
                .catch((err) => {
                    console.error(err);
                    if(err && err.data && err.data.errors && err.data.errors.email && err.data.errors.email.message) {
                        self.toastr.error(err.data.errors.email.message);
                    }else {
                        self.toastr.error(`Tenant create failed.`);
                    }
                });

        }else {
            self.$http.post("/api/adTenant", self.tenant).then(response => {
                self.toastr.info(`Tenant created.`);
                self.$uibModalInstance.close(result);
            })
                .catch((err) => {
                    console.error(err);
                    if(err && err.data && err.data.errors && err.data.errors.email && err.data.errors.email.message) {
                        self.toastr.error(err.data.errors.email.message);
                    }else {
                        self.toastr.error(`Tenant create failed.`);
                    }
                });
        }
    }

    doLog() {
        console.debug(this);
    }

    // Modal cancelled: either cancel pressed or clicked off screen
    cancel() {
        this.$uibModalInstance.dismiss('Cancel pressed');
    }

    ensureNoDups() {
        let self = this;
        self.accounts = _.uniqBy(self.accounts, o=>{
            return o.ref;
        });
    }

    removeAcc(item) {
        let self = this;
        self.accounts.push(item);
    }
    selectAcc(item) {
        let self = this;
        self.accounts.splice(self.accounts.indexOf(item),1);
    }
}

export default angular.module('secutraqApp.settings')
    .component('createtenant', {
        template: require('./createtenant.modal.html'),
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&',
            modalInstance: '<'
        },
        controller: TenantCreateModalController,
        controllerAs: '$ctrl'
    })
    .name;
// .controller(ContactModalController).name;
