export default function($stateProvider) {
  'ngInject';
  $stateProvider
    .state('resetpassword', {
        url: '/reset/password/:id/:token?next',
        views: {
          root : {
            template: '<resetpassword></resetpassword>'
          }
        },
		authenticate:false
    })
    .state('resetpassrequest', {
        url: '/reset/password?next',
        views: {
          root : {
            template: '<resetpasswordrequest></resetpasswordrequest>'
          }
        },
		authenticate:false
    });
}
