
export class UnitVersionModalController {
	Restangular;
	$uibModalInstance;
	modalInstance;

	/*@ngInject*/
	constructor( $stateParams, Auth, Restangular, NgTableParams,$scope, $timeout, moment, toastr, appVersionService, $uibModal, unitService) {
		this.$stateParams = $stateParams;
		this.Auth = Auth;
		this.Restangular = Restangular;
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.moment = moment;
		this.toastr = toastr;
		this.appVersionService = appVersionService;
		this.unitService = unitService;
		this.NgTableParams = NgTableParams;
	}

	$onInit() {
		let self = this;
		self.$uibModalInstance = this.modalInstance;
		self.appVersion = self.resolve.appVersion;
		// TODO: Add privileges <24-02-20, Liaan> //
		self.availableAccounts = _.cloneDeep(self.Auth.getCurrentUserSync().accounts);
		self.availableAccounts = _.filter(self.Auth.getCurrentUserSync().accounts, (acc) => {
			return self.Auth.hasRoleSync('softwareUpdates',null,acc);
		});
		self.availableAccounts.push({ref:'All Accounts', name:'All Accounts'});

		self.query = {
			accounts: ["All Accounts"]
		}
        this.cols = [
            { field: "name", title: "Unit", sortable: "name", show: true, getValue: this.handleValue, context : this},
            { field: "firmwareVersion", title: "Current Version", show: true, getValue: this.handleValue, context: this  },
            { field: "lastActivity", title: "Online", sortable:"lastActivity", show: true, getValue: this.handleValue, context: this },
            { field: "groups", title: "Groups",show: true, getValue: this.handleValue, context: this },
            { field: "account.name", title: "Account",show: true, getValue: this.handleValue, context: this },
        ];
        self.tableParams = new self.NgTableParams({
            page: 1, // start with first page
            count: 10, // count per page
            sorting: {
                name: 'asc' // initial sorting
            }
        },

		{
			total: 0,
			getData: function(params) {
				let order;
				if(params && params.sorting) {
					order = params.sorting();
					return self.refreshUnits(params).then( (data) => {
						self.units = data;
						self.total = data.total;
						params.total(data.total);
						return data;
					} )
					.catch(err => {
						console.error("Error when retrieving units:" , err.data.err);
					})
				}
			}
		}
												 )
	}


	doLog() {
		let self = this;
		console.debug(this);
	}

	ok() {
		let self = this;
		self.requesting = true;
		let query = {
			accounts : self.query.accounts,
			supportRemoteUpdates:true,
		};

		if(self.query.groups && self.query.groups.length > 0){
			if(!query.andQueries){
				query.andQueries = [];
			}
			query.andQueries.push({
				field:'groups',
				query:self.query.groups
			})
		}

		if(self.query.versions && self.query.versions.length > 0){
			if(!query.andQueries){
				query.andQueries = [];
			}
			query.andQueries.push({
				field:'firmwareVersion',
				query:self.query.versions
			})
		}

		if(self.query.units && self.query.units.length > 0){
			if(!query.andQueries){
				query.andQueries = [];
			}
			query.andQueries.push({
				field:'_id',
				query:self.query.units
			})
		}
		self.unitService.softwareUpdate(query,self.appVersion).then( (response) => {

			self.requesting = false;
			self.modalInstance.close();
			self.toastr.info("Software update requested");
		} ).catch( (err) => {
			console.error(err);

			self.requesting = false;
			self.toastr.error("Software update request failed");
		} )
	};

	refreshUnits(params){

		let self = this;
		let order = params.sorting();
		let query = {
			accounts : self.query.accounts,
			supportRemoteUpdates:true,
			skip: (params.page()-1)*params.count(),
			limit: params.count(),
			by: Object.keys(order)[0],
			order: order[Object.keys(order)[0]]
		};

		if(self.query.groups && self.query.groups.length > 0){
			if(!query.andQueries){
				query.andQueries = [];
			}
			query.andQueries.push({
				field:'groups',
				query:self.query.groups
			})
		}

		if(self.query.versions && self.query.versions.length > 0){
			if(!query.andQueries){
				query.andQueries = [];
			}
			query.andQueries.push({
				field:'firmwareVersion',
				query:self.query.versions
			})
		}

		if(self.query.units && self.query.units.length > 0){
			if(!query.andQueries){
				query.andQueries = [];
			}
			query.andQueries.push({
				field:'_id',
				query:self.query.units
			})
		}

		return self.unitService.getUnistWithCustomQuery(query)
		.catch( (err) => {
			console.error(err);
		} );
	}


	// Modal cancelled: either cancel pressed or clicked off screen
	cancel() {
		this.modalInstance.dismiss('Cancel pressed');
	};


	accountSelected(account){
		let self = this;
		if(account.ref == 'All Accounts'){
			self.query.accounts = ['All Accounts'];
			self.reload();
		}else{
			_.remove(self.query.accounts, (a) => {
				return a == 'All Accounts';
			});

			self.reload();
		}
	}

	reload(){
		let self = this;
		self.tableParams.reload();

	}

	refreshGroups(search){
		let self = this;
		let query = {
			accounts : self.query.accounts,
			filter:search
		};
		self.unitService.getGroups(query).then( (groups) => {
			self.availableGroups = groups;
		} );
	}

	refreshVersions(search){
		let self = this;
		let query = {
			accounts : self.query.accounts,
			filter:search,
			supportRemoteUpdates:true
		};
		self.unitService.getVersions(query).then( (versions) => {
			self.availableVersions = versions;
		} );
	}

	refreshAvailableUnits(search){

		let self = this;
		let query = {
			accounts : self.query.accounts,
			filter:search,
			supportRemoteUpdates:true,
			limit: 20,
		};

		return self.unitService.getUnistWithCustomQuery(query).then( (units) => {
			let cleanedUnits = [];
			units.forEach( (unit) => {
				cleanedUnits.push(unit);
			} )
			self.availableUnits = cleanedUnits;
		} )
		.catch( (err) => {
			console.error(err);
		} );
	}

}

export default angular.module('secutraqApp.settings')
	.component('unitversionmodal', {
		template: require('./unit-version-management.modal.html'),
		bindings: {
			resolve: '<',
			close: '&',
			dismiss: '&',
			modalInstance: '<'
		},
		controller: UnitVersionModalController,
		controllerAs: '$ctrl'
	})

	.name;
