'use strict';
// @flow

export class ManageUsersComponent {
	Auth;

	/*@ngInject*/
	constructor(Auth,$scope,$stateParams,Restangular,NgTableParams,$uibModal,socket,$log,moment,userService,toastr,appConfig, $ngConfirm, $http) {
		this.Auth = Auth;
		this.$scope = $scope;
		this.$stateParams = $stateParams;
		this.Restangular = Restangular;
		this.NgTableParams = NgTableParams;
		this.$uibModal = $uibModal;
		this.socket = socket;
		this.$log = $log;
		this.moment = moment;
		this.userService = userService;
		this.filter = '';
		this.isColumnsCollapsed = false;
		this.getCurrentUser = Auth.getCurrentUserSync;
		this.toastr = toastr;
		this.self = this;
		this.appConfig = appConfig;
		this.$ngConfirm = $ngConfirm;
		this.$http = $http;
	}

	$onInit() {

		let self = this;
		self.$stateParams.accountID = self.Auth.getCurrentAccountSync().ref;

		self.userService.registerListener(self, (event, item, array) => {
			return self.onReceiptOfUser(event,item);
		}).catch(err => {
			console.error('Error getting events', err);
		});

		self.users = [];


		// self.Units = self.Restangular.all('units');

		/**
		 * An array of columns that are showed by default.
		 * We iterate through all the columns and mark those with VIEW set to true
		 */
		self.selectedColumns = [];

		self.cols = [{
			title: "First Name",
			field: "firstname",
			show: true,
			sortable: "firstname",
		}, {
			title: "Last Name",
			field: "lastname",
			show: true,
			sortable: "lastname",
		}, {
			title: "E-mail",
			field: "email",
			show: true,
			sortable: "email",
		},{
			title: "Verified",
			field: "verified",
			show: true,
			sortable: "verified",
		},{
			title: "Last Login",
			field: "lastLogin",
			show: true,
			sortable: "lastLogin",
		},{
			title: 'Accounts',
			field: 'accounts',
			show: true,
			sortable: "accounts",
		},{
			title: 'Edit Privileges',
			field: 'privileges',
			show: true,
		},{
			title: "Reset MFA",
			field: "mfaReset",
			show: true,
		},{
			title: "Reset Password",
			field: "passwordReset",
			show: true,
		},
        {
            title: 'Manage',
            field: 'edit',
            show: true
        }
    ];

		/**
		 * The purpose of colValues is to contain the HTML-code that we want
		 * to be assumed in NgTable.
		 * TODO: This still needs to be implemented, but I think this should stop
		 * angular from calling handleDisplay() function continuously
		 * We can then use $interval to handle update-intervals.
		 * @type {Object}
		 */
		self.colValues = {};

		_.forEach(self.cols, (col) => {
			if(col.show) {
				self.selectedColumns.push(col.title);
			}
			self.colValues[col.field] = '';
		});

		self.Auth.getCurrentUser().then(user => {
			self.currentUser = user;
			self.isAdmin = self.currentUser._id === self.appConfig.adminUserId;
		});


		self.tableParams = new self.NgTableParams({
			page: 1, // start with first page
			count: 10, // count per page
			sorting: {
				name: 'asc' // initial sorting
			}
		},
			{
				total: 0,
				getData(params) {
					let order;
					if (params && params.sorting) {
						order = params.sorting();
						return self.userService.getUsersWithQuery({
							filter: self.filter.length ? self.filter : undefined,
							skip: (params.page() - 1) * params.count(),
							accountID: self.$stateParams.accountID,
							limit: params.count(),
							by: Object.keys(order)[0],
							order: order[Object.keys(order)[0]]
						}).then(function(users) {
							if(self.Auth.getCurrentUserSync()._id !== self.appConfig.adminUserId) {
								let index = _.findIndex(users.data,(o) => {
									return o._id == self.appConfig.adminUserId;
								});
								if(index >= 0) {
									users.total--;
									users.data.splice(index,1);
								}
							}
							self.users = users.data;
							self.total = users.total;
							params.total(users.total);
							return users.data;
						})
							.catch(err => {
								console.error("Error caught when getting data for users: ", err.data.err);
							});
					}
				}
			});

		this.tableParams.reload();

	}

	$onDestroy() {
		let self = this;
		self.userService.unregisterListener(self);
	}


	onReceiptOfUser(event,user) {
		let self = this;

		let index = _.findIndex(self.users, (storedUser) => {
			return storedUser._id === user._id;
		});

		if(index !== -1) {
			if(event == 'deleted') {
				self.users.splice(index, 1);
			}else {
				self.users.splice(index, 1, user);
			}
		}
	}


	onColumnSelected($item, $model) {
		$item.show = true;
	}

	onColumnRemoved($item, $model) {
		$item.show = false;
	}

	applyFilter() {
		this.tableParams.page(1);
		this.tableParams.reload();
	}

	reloader() {
		let self = this;
		self.tableParams.reload();
	}

	/**
	 * Upon desiring to add a new user we execute this function from HTML.
	 * NOTE and TODO: We currently directly add this user to dummyUnits, but we should
	 * in fact tell the server about the new user, and then retrieve the new user from there
	 */
	createUser() {
		let self = this;

		let modalInstance = self.$uibModal.open({
			component: 'createuser',
			size: 'sm',
			backdrop: 'static',
			resolve: {
				settings() {
					return {
						edit: false,
						create: true,
						account: {ref: self.$stateParams.accountID, name: self.getAccountName(self.$stateParams.accountID)}
					};
				}
			}
		});

		modalInstance.result.then((user) => {
			// TODO: toast
			self.reloader();
		});
	}

	importUsers() {
		let self = this;

		let modalInstance = self.$uibModal.open({
			component: 'importusers',
			backdrop: 'static',
			resolve: {
				settings() {
					return {
						account: {ref: self.$stateParams.accountID, name: self.getAccountName(self.$stateParams.accountID)}
					};
				}
			}
		});

		modalInstance.result.then((result) => {
			// TODO: toast
		});
	}

	/**
	 * Upon desiring to edit a user we execute this function from HTML.
	 */
	editUser(user) {
		let self = this;
		let userCopy = _.clone(user);

		let modalInstance = self.$uibModal.open({
			component: 'createuser',
			size: 'sm',
			backdrop: 'static',
			resolve: {
				settings() {
					return {
						edit: true,
						user: userCopy
					};
				}
			}
		});

		modalInstance.result.then((user) => {
			//NOTE We should send the new, edited user to the server
			// TODO: toast
			self.reloader();
		});
	}

    deleteUser(user) {
		let self = this;
		let confirmDelete = self.$ngConfirm({
			title: `Delete ${user.firstname} ${user.lastname}?`,
			escapeKey: false,
			backgroundDismiss: false,
			scope: self.$scope,
			content: `Are you sure you wish to delete this user: ${user.firstname} ${user.lastname}? Any active events assigned will be unassigned.`,
			buttons: {
				enter: {
					text: "Yes",
					btnClass: 'btn-blue',
					action(scope, button) {
						self.$http.delete(`api/users/${user._id}`).then(response => {
						});
					}
				},
				close(scope, button) {
				}
			}
		});
    }
	editPrivileges(user) {
		let self = this;
		let userCopy = _.clone(user);

		let modalInstance = self.$uibModal.open({
			component: 'editprivileges',
			size: 'sm',
			backdrop: 'static',
			resolve: {
				settings() {
					return {
						user: userCopy
					};
				}
			}
		});

		modalInstance.result.then((user) => {
			//NOTE We should send the new, edited user to the server
			// TODO: toast
		});
	}
	getAccountName(accountID) {
		let self = this;
		let user = self.getCurrentUser();
		if(!user.accounts || typeof user.accounts === Array) {
			return 'No Account';
		}
		let account;
		for(let i = 0; i < user.accounts.length; ++i) {
			if(user.accounts[i].ref === accountID) {
				account = user.accounts[i];
				break;
			}
		}
		if(account !== undefined) {
			return account.name;
		}
		return 'No Account';
	}


	resetMFA(user) {
		let self = this;
		let confirmReset = self.$ngConfirm({
			title: `Reset two-factor authentication for ${user.firstname} ${user.lastname}`,
			escapeKey: false,
			backgroundDismiss: false,
			scope: self.$scope,
			content: `Are you sure you wish to reset this user's two-factor authentication: ${user.firstname} ${user.lastname}?`,
			buttons: {
				enter: {
					text: "Yes",
					btnClass: 'btn-blue',
					action(scope, button) {
						self.$http.post(`api/users/${user._id}/mfaReset`).then(response => {
						});
					}
				},
				close(scope, button) {
				}
			}
		});

	}

	resetPrivileges() {
		let self = this;
		self.userService.resetPrivileges()
			.then( () => self.toastr.info("Privileges Reset"))
			.catch( (err) => {
				self.toastr.error("Reset failed");
				console.error(err);
			});
	}

	resetUserPassword(user) {
		let self = this;
		let confirmReset = self.$ngConfirm({
			title: `Reset Password for ${user.firstname}`,
			escapeKey: false,
			backgroundDismiss: false,
			scope: self.$scope,
			content: `Are you sure you wish to reset this user's password: ${user.firstname}?`,
			buttons: {
				enter: {
					text: "Yes",
					btnClass: 'btn-blue',
					action(scope, button) {
						self.Restangular.one('users', user._id).post('resetUserPassword')
							.then( (result) => {
								if(result) {
									self.toastr.info('Password reset');
								} else {
									self.toastr.error('Reset failed');
								}
							} )
							.catch( (err) => {
								console.error(err);
								self.toastr.error('Reset failed');
							});
					}
				},
				close(scope, button) {
				}
			}
		});

	}
    doLog() {
        let self = this;
        console.debug(self);
    }
}

export default angular.module('secutraqApp.settings')
	.component('manageusers', {
		template: require('./settings.user.manageusers.html'),
		controller: ManageUsersComponent,
		controllerAs: '$ctrl'
	})
	.name;
