export class SiteService {
    /*@ngInject*/
    constructor($interval, Restangular, moment, Auth, socket, toastr, $ngConfirm, unitService) {
        this.Restangular = Restangular;
        this.$interval = $interval;
		this.moment = moment;
		this.Restangular = Restangular;
		this.listenerMap = new Map();
		this.socket = socket;
		this.Auth = Auth;
		this.unitService = unitService;
		this.$ngConfirm = $ngConfirm;
		this.cacheTimeout = undefined;
		this.lastAccount = undefined;
        this.lastUnit = undefined;
		this.joined = false;
        this.toastr = toastr;
        this.sites = [];
		this.rooms = [];
    }

    onSocketEvent(event, item, array) {
        let self = this;
        self.sites = array;
        self.listenerMap.forEach((value, key) => {
            setTimeout(() => {
                value(event, item, array);
            }, 0);
        });
    }

    registerListener(context, cb) {
        let self = this;
        if (!self.lastAccount) {
            self.lastAccount = self.Auth.getCurrentAccountSync().ref;
        } else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
            self.destroyService();
            self.lastAccount = self.Auth.getCurrentAccountSync().ref;
        }
        if(!self.lastUser) {
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        } else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
            self.destroyService();
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        }
        let count = self.listenerMap.size;
        self.listenerMap.set(context, cb);
        // If listenermap was empty, (TODO and timer isn't running), join room, syncupdates
        if (!self.joined) {
            if (self.cacheTimeout) {
                clearTimeout(self.cacheTimeout);
                self.cacheTimeout = undefined;
            }
            return self.getSites().then((sites) => {
                self.socket.syncUpdates('site', self.sites, self.onSocketEvent.bind(self), self);
				let filter = {};
				let hasPrivilege = self.Auth.hasPrivilegeSync('event.index',undefined,filter);
				if(hasPrivilege && filter && filter.filterParam && filter.filterParam.field == 'groups') {
					filter.filterParam.data.forEach( (group) => {
						self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().ref}:*:sites:${group}`);
						self.rooms.push(`${self.Auth.getCurrentAccountSync().ref}:*:sites:${group}`);
					} );
				}else{
					self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().ref}:*:sites`);
					self.rooms.push(`${self.Auth.getCurrentAccountSync().ref}:*:sites`);
				}
                self.joined = true;
                return sites;
            });
        } else {
			if(self.cacheTimeout) {
				clearTimeout(self.cacheTimeout);
				self.cacheTimeout = undefined;
			}
            return self.getSites();
        }
    }

    destroyService() {
        let self = this;
        if (self.joined) {
            self.socket.unsyncUpdates('site', self);
			if(self.rooms && self.rooms.length > 0) {
				self.rooms.forEach( (room) => {
					self.socket.leaveRoom(room);
				} );
				self.rooms = [];
			}
        }
        self.joined = false;
        self.sites = [];
        self.listenerMap.clear();
        clearTimeout(self.cacheTimeout);
        self.cacheTimeout = undefined;
    }

    unregisterListener(context, cb) {
        let self = this;
        if (!self.lastAccount) {
            self.lastAccount = self.Auth.getCurrentAccountSync().ref;
        } else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
            self.destroyService();
            self.lastAccount = self.Auth.getCurrentAccountSync().ref;
        }
        if(!self.lastUser) {
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        } else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
            self.destroyService();
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        }
        self.listenerMap.delete(context);
        if (self.listenerMap.size === 0) {
            self.cacheTimeout = setTimeout(self.destroyService.bind(self), 5000);
        }
    }

    unregisterAll() {
        let self = this;
        if (!self.lastAccount) {
            self.lastAccount = self.Auth.getCurrentAccountSync().ref;
        } else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
            self.destroyService();
            self.lastAccount = self.Auth.getCurrentAccountSync().ref;
        }
        if(!self.lastUser) {
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        } else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
            self.destroyService();
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        }
        self.listenerMap.clear();
        if (self.listenerMap.size === 0) {
            self.cacheTimeout = setTimeout(self.destroyService.bind(self), 5000);
        }
    }

    getSites(query) {
        let self = this;
        if (self.sites.length > 0) {
            return new Promise(function(resolve) {
                resolve(_.cloneDeep(self.sites));
            });
        }
        let lastAccount = _.cloneDeep(self.lastAccount);
        let lastUser = _.cloneDeep(self.lastUser);
        return this.Restangular.all('sites').getList(query)
            .then((sites) => {
                if(lastAccount == self.lastAccount && lastUser == self.lastUser) {
                    self.sites = sites;
                    return _.cloneDeep(sites);
                }else {
                    return [];
                }
            });
    }

    getSitesWithQuery(query) {
        let self = this;
        return this.Restangular.all('sites').getList(query)
        .then((response) => {
            return _.cloneDeep(response);
        })
.catch((err) => {
            console.error(err);
        });
    }

    setSites(sites) {
        let self = this;
        self.sites = sites;
    }

    hasSites() {
        let self = this;
        return new Promise((resolve, reject) => {
            if(self.sites !== undefined) {
                resolve(true);
            } else {
                let promise = self.$interval(() => {
                    if(self.sites !== undefined) {
                        resolve(true);
                        self.$interval.cancel(promise);
                    }
                }, 500, 10);

                promise.then((result) => {
                    reject(`No sites have been given to SiteService yet`);
                });
            }
        });
    }

    getSiteByIDSync(siteID) {
        let self = this;
        let site = _.find(self.sites, (site) => {
            return site._id === siteID;
        });
        return site;
    }

    saveNewSite(site) {
        let self = this;
        return self.Restangular.all('sites').post(site);
	}

    updateSite(site) {
        let self = this;
        return self.Restangular.one('sites',site._id).customPATCH(site);
	}

    removeSite(site) {
        let self = this;
        if(typeof site.remove === 'function') {
            site.remove().then((response) => {
                self.toastr.info('Site removed.');
            })
.catch((err) => {
                console.error(err);
                self.toastr.error('Site remove failed.');
            });

        }else{
            self.Restangular.one('sites',site._id).remove()
.then((response)=>{
                self.toastr.info('Site removed.');
            })
.catch((err) => {
                console.error(err);
                self.toastr.error('Site remove failed.');
            });

        }
    }

    getSOPs(siteId) {
        let self = this;
        return self.getSiteByID(siteId).then((site) => {
            if(site && site.sops && site.sops.length > 0) {
                return site.sops;
            }else {
                return [];
            }
        });
    }

    getSiteByID(siteId) {
        let self = this;
        if(self.sites && self.sites.length > 0) {
            let index = _.findIndex(self.sites,(storedSite) => {
                return storedSite._id === siteId;
            });

            if(index !== -1) {
                return Promise.resolve(_.cloneDeep(self.sites[index]));
            }else{
                return Promise.resolve('No such site!');
            }
        }else{
            return self.Restangular.one('sites',siteId).get()
.then((site) => {
                if(site) {
                    return site;
                }else{
                    return 'No such site!';
                }
            });
        }
    }

	getGroups() {
		let self = this;
		self.Groups = self.Restangular.all('sites').all('groups');
		return self.Groups.getList();
	}


	requestSiteSnapshots(site,scope) {
		let self = this;
		self.availableUnitsInSite = [];

		self.Restangular.one('sites',site._id).customGET('unitsOnSite')
.then( (units) => {
			if(units && units.length > 0) {
				self.availableUnitsInSite = units;
				self.$ngConfirm(
					{
						title: `<span style="margin:auto;">Select Devices</span>`,
							theme: 'modern',
						animation: 'top',
						scope,
						closeAnimation: 'bottom',
						content: require('./request-site-snapshot.html'),
						escapeKey: true,
						backgroundDismiss: true,
						buttons: {
							// long hand button definition
							ok: {
								text: "Request",
								btnClass: 'btn-primary',
								keys: ['enter'], // will trigger when enter is pressed
								action(scope) {
									let callCount = 0;
									let allPromises = [];
									let selectedUnits = _.filter(self.availableUnitsInSite,['selected', true]);
									if(selectedUnits && selectedUnits.length > 0) {
										selectedUnits.forEach( (unit) => {
											let a = self.unitService.requestSnapshot(unit._id).then( (requested) => {
												if(requested) {
													callCount++;
												}
											} )
.catch( (err) => {
												console.error(err);
												self.toastr.warning(`Request for ${unit.name} failed.`);
											} );
											allPromises.push(a);
										} );
										Promise.all(allPromises).then( () => {
											self.toastr.info(`Requested ${callCount} photos`);
										} );
									}else{
										self.toastr.warning(`No devices selected.`);
									}
								}
							},
							close: {
								text: "Cancel",
								action(scope) {
								}
							}
						},
					}
				);
			}else{
				self.toastr.warning('No online units on site');
			}
		} )
.catch( (err) => {
			self.toastr.error('Snapshots requests failed');
			console.error(err);
		} );


	}

    //
    // getSites() {
    //     let self = this;
    //     return self.hasSites()
    //         .then((sites) => {
    //             return new Promise((resolve, reject) => {
    //                 resolve(self.sites);
    //             });
    //         })
    //         .catch((error) => {
    //             console.error("Site Service has not been given sites yet");
    //         });
    //
    // }
}

export default angular.module('secutraqApp.dashboard')
.service('siteService', SiteService);
