export class UserCreateModalController {
	$stateParams;
	Restangular;
	$uibModalInstance;
	settings;
	contact;
	modalInstance;

	/*@ngInject*/
	constructor($stateParams, Restangular,toastr,$scope) {
		this.$stateParams = $stateParams;
		this.Restangular = Restangular;
		this.toastr = toastr;
		this.valid = true;
		this.passwordValid = false;
		this.$scope = $scope;
	}

	$onInit() {
		let self = this;
		self.settings = self.resolve.settings;
		self.$uibModalInstance = self.modalInstance;
		if (self.settings.user) {
			let user = self.settings.user;
			self.user = user;
			self.edit = true;
			// if(self.contact.ids && self.contact.ids.length > 0){
			// 	_.forEach(self.contact.ids,(id)=>{
			// 		id.id = id.id.toString('hex');
			// 	})
			// }
		} else {
			self.edit = false;
			self.user = {
				accounts: {ref:self.settings.account,name:self.settings.accountname},
			};
		}
		self.Users = self.Restangular.all('users');
	}


	// Setup all the models for display
	// Modal accept: send resulting object back to promise in parent controller
	ok() {
		let self = this;
		let result = self.user;
		// _.forEach(result.ids,(id)=>{
		// 	id.id = new Buffer.from(id.id,'hex');
		// })
		if(self.edit) {
			self.Restangular.one('users',result._id).customPATCH(result)
.then((response)=>{
				self.toastr.info(`User updated.`);
				self.$uibModalInstance.close(response);
				// self.toastr.error(`Contact create failed.`);
			})
.catch((err) => {
				console.error(err);
				if(err && err.data && err.data.errors && err.data.errors.email && err.data.errors.email.message) {
					self.toastr.error(err.data.errors.email.message);
				}else {
					self.toastr.error(`User create failed.`);
				}
			});

		}else {
			self.Users.post(result, {accountID: self.$stateParams.accountID}).then(function(result) {
				self.toastr.info(`User created.`);
				self.$uibModalInstance.close(result);
				// self.toastr.error(`Contact create failed.`);
			})
.catch((err) => {
				console.error(err);
				if(err && err.data && err.data.errors && err.data.errors.email && err.data.errors.email.message) {
					self.toastr.error(err.data.errors.email.message);
				}else {
					self.toastr.error(`User create failed.`);
				}
			});
		}
	}

	doLog() {
		console.debug(this);
	}

	// Modal cancelled: either cancel pressed or clicked off screen
	cancel() {
		this.$uibModalInstance.dismiss('Cancel pressed');
	}
}

export default angular.module('secutraqApp.settings')
	.component('createuser', {
		template: require('./createuser.modal.html'),
		bindings: {
			resolve: '<',
			close: '&',
			dismiss: '&',
			modalInstance: '<'
		},
		controller: UserCreateModalController,
		controllerAs: '$ctrl'
	})
	.name;
// .controller(ContactModalController).name;
