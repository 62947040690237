import {rrulestr} from 'rrule';

export class SchedulingComponent {
	Auth;
	$stateParams;
	Restangular;
	$uibModal;
	filter;
	moment;

	/*@ngInject*/
	constructor(Auth, $stateParams, Restangular, NgTableParams, $uibModal, moment, siteService, routeService, scheduleService, calendarConfig, $scope, $timeout, eventService) {
		this.Auth = Auth;
		this.$stateParams = $stateParams;
		this.Restangular = Restangular;
		this.NgTableParams = NgTableParams;
		this.$uibModal = $uibModal;
		this.getCurrentUser = Auth.getCurrentUserSync;
		this.moment = moment;
		this.siteService = siteService;
		this.routeService = routeService;
		this.scheduleService = scheduleService;
		this.routes = [];
		this.sites = [];
		this.schedules = [];
		this.calendarConfig = calendarConfig;
		this.$scope = $scope;
		this.eventService = eventService;
		this.$timeout = $timeout;
	}

	$onInit() {
		let self = this;
		self.$stateParams.accountID = self.Auth.getCurrentAccountSync().ref;
		self.filter = '';
		self.calendarView = 'month';
		self.viewDate = new self.moment();
		self.uiCalendarOptions = {
			edit(event) {
				if(event.type == 'schedule') {
					return true;
				}else{
					return false;
				}
			},
			editFunction : self.editSchedule.bind(self)

		};
	 	self.debouncedRefresh = _.debounce(self.refreshSchedule,1000);

		self.scheduleEvents = [];
		self.calendarEvents = [
		];
		self.calendarTitle = "Test Calendar";

		self.eventService.getEventDetails.then( (eventDetails) => {
			self.eventDetails = eventDetails;
			return null;
		} ).then( () => {
			// Changed to registerListener/unregisterListener
			self.siteService.registerListener(self, (event, item, array) => {
				return self.onReceiptOfSite(item, event);
			}).then((sites) => {

				sites.forEach(site => {
					self.onReceiptOfSite(site);
				});
			})
.catch(err => {
				console.error('Error getting Sites', err);
			});
			self.routeService.registerListener(self, (event, item, array) => {
				return self.onReceiptOfRoute(item);
			}).then((routes) => {

				routes.forEach(route => {
					self.onReceiptOfRoute(route);
				});
			})
.catch(err => {
				console.error('Error getting Routes', err);
			});
			self.scheduleService.registerListener(self, (event, item, array) => {
				return self.onReceiptOfSchedule(item, event);
			}).then((schedules) => {
				schedules.forEach(schedule => {
					self.onReceiptOfSchedule(schedule);
				});
				self.debouncedRefresh();
			})
.catch(err => {
				console.error('Error getting Schedules', err);
			});

		} );

		self.$scope.$watchGroup([
			'$ctrl.calendarView',
			'$ctrl.viewDate'
		], () => {
			self.debouncedRefresh();
		});
	}

	$onDestroy() {
		let self = this;
		// self.mapService.clearMapMarkers();
		self.siteService.unregisterListener(self);
		self.routeService.unregisterListener(self);
		self.scheduleService.unregisterListener(self);
	}

	addNewSchedule() {
		let self = this;
		let modalInstance = self.$uibModal.open({
			component: 'schedulingmodal',
			bindToController: true,
			size: 'xlg',
			backdrop: 'static',
			keyboard: false,
			resolve: {
				settings() {
					return {
						edit: false,
						create: true,
						account: {
							ref: self.$stateParams.accountID
						}
					};
				},
				sites() {
					return self.sites;
				},
				routes() {
					return self.routes;
				}
			}
		});

		modalInstance.result.then(function(result) {
			//TODO:Save schedule
			result.rruleSet = result.rruleSet.valueOf();
			result.duration = self.moment(result.end).diff(self.moment(result.start),'seconds');
			if(result.site) {
				result.sitename = result.site.name;
				result.site = result.site._id;
			}
			result.groups = [];
			if(result.routes && result.routes.length > 0) {
				result.routes.forEach( (route) => {
					let routeCollection = _.find(self.routes, (a) => {
						return _.find(a.routes,["_id",route._id]);
					} );
					if(routeCollection) {
						result.groups = _.uniq(result.groups.concat(routeCollection.groups));
					}
				} );
			}
			if(result.site) {
				let site = _.find(self.sites,['_id', result.site]);
				result.groups = _.uniq(result.groups.concat(site.groups));
			}
			// TODO: Set site name
			self.scheduleService.saveNewSchedule(result).then((result) => {
			});
		});
	}

	editSchedule(schedule) {
		let self = this;
		let tempSchedule = _.cloneDeep(schedule);
		if(tempSchedule.site) {
			tempSchedule.site = _.find(self.sites,(o) => {
				return o._id == tempSchedule.site;
			});
		}
		let routes = _.uniq(_.map(tempSchedule.slots,'typeId'));
		tempSchedule.routes = [];
		routes.forEach((routeId) => {
			if(routeId) {
				let route = self.routeService.getSubRouteByIdSync(routeId);
				if(route && route._id) {
					tempSchedule.routes.push(route);
				}
			}
		});
		let modalInstance = self.$uibModal.open({
			component: 'schedulingmodal',
			bindToController: true,
			size: 'xlg',
			backdrop: 'static',
			keyboard: false,
			resolve: {
				settings() {
					return {
						edit: true,
						create: false,
						account: {
							ref: self.$stateParams.accountID
						},
					};
				},
				sites() {
					return self.sites;
				},
				routes() {
					return self.routes;
				},
				schedule: tempSchedule,

			}
		});

		modalInstance.result.then(function(result) {
			//TODO:Save schedule
			result.rruleSet = result.rruleSet.valueOf();
			result.duration = self.moment(result.end).diff(self.moment(result.start),'seconds');
			if(result.site) {
				result.sitename = result.site.name;
				result.site = result.site._id;
			}
			result.groups = [];
			if(result.routes && result.routes.length > 0) {
				result.routes.forEach( (route) => {
					let routeCollection = _.find(self.routes, (a) => {
						return _.find(a.routes,["_id",route._id]);
					} );
					if(routeCollection) {
						result.groups = _.uniq(result.groups.concat(routeCollection.groups));
					}
				} );
			}
			if(result.site) {
				let site = _.find(self.sites,['_id', result.site]);
				result.groups = _.uniq(result.groups.concat(site.groups));
			}


			// TODO: think about how editing a schedule wil work
			self.scheduleService.updateSchedule({_id:result._id,active:false}).then((status) => {
				result.prevIds.push(result._id);
				result.prevIds = _.uniq(result.prevIds);
				delete result._id;
				self.scheduleService.saveNewSchedule(result).then((nresult) => {
					// TODO: Add toastr
				});
			})
.catch((err) => {
				console.error(err);
			});


		});
	}

	onReceiptOfRoute(route, event) {
		let self = this;
		let index = _.findIndex(self.routes, (o) => {
			return o._id === route._id;
		});
		if (index > -1) {
			if (event === 'deleted') {
				self.routes.splice(index, 1);
			} else {
				self.routes.splice(index, 1, route);
			}
		} else {
			self.routes.push(route);
		}
	}

	onReceiptOfSite(site, event) {
		let self = this;
		let index = _.findIndex(self.sites, (o) => {
			return o._id === site._id;
		});
		if (index > -1) {
			if (event === 'deleted') {
				self.sites.splice(index, 1);
			} else {
				self.sites.splice(index, 1, site);
			}
		} else {
			self.sites.push(site);
		}
	}

	onReceiptOfSchedule(schedule, event) {
		let self = this;
		let index = _.findIndex(self.schedules, (o) => {
			return o._id === schedule._id;
		});
		if (index > -1) {
			if (event === 'deleted') {
				self.schedules.splice(index, 1);
				self.debouncedRefresh();
			} else {
				self.convertScheduleToCalendarEvent(schedule);
				self.schedules.splice(index, 1, schedule);
				self.debouncedRefresh();
			}
		} else {
			self.convertScheduleToCalendarEvent(schedule);
			self.schedules.push(schedule);
			// self.debouncedRefresh();
		}
	}

	refreshSchedule() {
		let self = this;
		self.calendarEvents = [];
		self.scheduleEvents = [];
		let window = self.getWindow();
		self.schedules.forEach((schedule) => {
			let rruleSet = rrulestr(schedule.rruleSet.join('\n'),{forceset:true});
			let dates = rruleSet.between(window.start.toDate(), window.end.toDate());
			dates.forEach((date) => {
				let tempEvent = _.cloneDeep(schedule);
				tempEvent.startsAt = new Date(date);
				tempEvent.endsAt = self.moment(date).add(tempEvent.duration, 'seconds')
.toDate();
				tempEvent.start = new self.moment(date).toDate();
				tempEvent.end = new self.moment(date).add(tempEvent.duration, 'seconds')
.toDate();
				tempEvent.type = 'schedule';
				if(tempEvent.start > self.moment().add(30,'minutes')) {
					self.calendarEvents.push(tempEvent);
					self.scheduleEvents.push(tempEvent);
				}
			});
		});
		self.getShifts().then((shifts) => {
			shifts.forEach((shift) => {

				shift.title = shift.name;
				shift.start = new self.moment(shift.start);
				shift.end = new self.moment(shift.end);
				shift.type = 'shift';
				if(self.moment.utc(shift.end) < self.moment()) {
					shift.color = 'grey';
				}else {
					shift.color = 'green';
				}
				let shiftIndex = _.findIndex(self.calendarEvents,(o) => {
					return o._id && o._id == shift._id;
				});
				if(shiftIndex >= 0) {
					if(shift.start <= self.moment().add(10,'minutes')) {
						self.splice(shiftIndex,1,shift);
					}else {
						self.splice(shiftIndex,1);
					}
				}else if(shift.start <= self.moment().add(10,'minutes')) {
						self.calendarEvents.push(shift);
					}
			});
		});

	}

	getShifts() {
		let self = this;
		let query = {};
		let window = self.getWindow();

		query.between = {start:self.moment(window.start).utc()
.valueOf(),end:self.moment(window.end).utc()
.valueOf()};
		query.activeOnly = 'true';
        return this.Restangular.all('shifts').customGET('',query)
        .then((results)=>{
            return results.data;
        });
	}

	getWindow() {
		let self = this;
		let start = new self.moment(self.viewDate).startOf(self.calendarView == 'agendaWeek' ? 'week' : self.calendarView);
		let end = new self.moment(self.viewDate).endOf(self.calendarView == 'agendaWeek' ? 'week' : self.calendarView);
		return {start,end};
	}

	convertScheduleToCalendarEvent(schedule) {
		let self = this;
		schedule.title = schedule.name || 'New Schedule';
		schedule.color = self.calendarConfig.colorTypes.info;
		let tempView = self.calendarView;
		self.calendarView = '';
		self.calendarView = tempView;
	}

	doLog() {
		console.debug(this);
	}

}

export default angular.module('secutraqApp.scheduling')
	.component('scheduling', {
		template: require('./scheduling.html'),
		controller: SchedulingComponent,
		controllerAs: '$ctrl'
	})
	.name;
