export default function($stateProvider) {
	'ngInject';
	$stateProvider
		.state('main.reports', {
			abstract: true,
			url: '/reports',
			data: {
				title: 'Reports'
			},
			authenticate: true
		})
		.state('main.reports.adhoc', {
			url: '/adhoc?task',
			views: {
				'content@main': {
					template: '<reportsadhoc></reportsadhoc>'
				}
			},
			data: {
				title: 'Adhoc'
			},
			authenticate: true
		})
		.state('main.reports.scheduled', {
			url: '/scheduled',
			views: {
				'content@main': {
					template: '<reportsscheduled></reportsscheduled>'
				}
			},
			data: {
				title: 'Scheduled'
			},
			authenticate: true
		});
}
