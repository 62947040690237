'use strict';

angular.module('SmartAdmin.Forms').directive('smartClockpicker', function($timeout) {
    return {
        restrict: 'A',
        compile(tElement, tAttributes) {
            tElement.removeAttr('smart-clockpicker data-smart-clockpicker');

            var options = {
                placement: 'top',
                donetext: 'Done'
            };

            $timeout(() => {
                tElement.clockpicker(options);
            });


        }
    };
});
