'use strict';
import uiBootstrap from 'angular-ui-bootstrap';
import Dropzone from 'dropzone';
import 'dropzone/dist/dropzone.css';
import 'ngdropzone';

Dropzone.autoDiscover = false;

export default class LookupController {
    $state;
    $http;
    Auth;
    $scope;
    searching;
    $uibModal;
    $uibModalInstance;
    allTags;
    lookupResults = [];
    bestMatch = [];
    reference = {};
    currentAccount;
    buttonClass = 'disabled';

    /*@ngInject*/
    constructor($uibModalInstance, Auth, $http, $state, $scope, toastr, $uibModal) {
        this.$uibModalInstance = $uibModalInstance;
        this.$state = $state;
        this.searching = false;
        this.toastr = toastr;
        this.Auth = Auth;
        this.$http = $http;
        this.$scope = $scope;
        this.resultsFound = false;
        this.searchDone = false;
        this.waiting = false;
        this.$uibModal = $uibModal;
    }

    doLog() {
        let self = this;
		console.debug(this);
    }

    $onInit() {
        let self = this;
        //this.bestMatch = testData.splice(0, 1);
        self.currentAccount = self.Auth.getCurrentAccountSync().accountId;

        self.dzCallbacks = {
            addedfile(file) {
                self.buttonClass = "enabled";
            },

            maxfilesexceeded(file) {
                self.dzMethods.removeFile(file);
                self.toastr.warning('Only one photo can be uploaded at a time', {
                    preventOpenDuplicates: true
                });
            },

            sending(file, xhr, formData) {
                self.waiting = true;
                self.toastr.info('Searching, Please Wait..', {
                    preventOpenDuplicates: true
                });
                self.buttonClass = 'disabled';
            },

            error(test, res) {
            },

            removedfile(file) {
                self.buttonClass = 'disabled';
            },

            success(test, res) {
                self.searching = false;
                if(res.length > 0) {
                    self.toastr.success("Possible match found", {
                        preventOpenDuplicates: true
                    });
                    self.resultsFound = true;
                    self.bestMatch = res.splice(0, 1)[0];
                    self.lookupResults = res;
                } else {
                    self.toastr.warning('No Result', {
                        preventOpenDuplicates: true
                    });
                }
            }
        };

        self.dzOptions = {
            url: '/api/references/lookup',
            maxFilesize: 1,
            maxFiles: 1,
            headers: {
                "X-JS-ACCOUNT": self.currentAccount
            },
            autoProcessQueue: false,
            acceptedFiles: 'image/jpeg, image/jpg',
            addRemoveLinks: true,
            dictDefaultMessage: 'Click to add photo (1 max)',
            dictRemoveFile: 'Remove Photo',
            dictResponseError: 'Could not upload this photo'
        };
        self.dzMethods = {};
    }

    $postLink() {
        let self = this;
        var dz = self.dzMethods.getDropzone();
    }

    lookup() {
        let self = this;
        self.searching = true;
        if(self.Auth.hasPrivilegeSync(['secuvue.reference.lookup', 'secuvue.snapshot.index'])) {
            self.dzMethods.processQueue();
        }
    }

    //upload() {
        //let self = this;
        //if (self.hasPrivilege('secuvue.references.upload')) {
            //self.dzMethods.processQueue();
        //} else {
            //self.toastr.error('You do not have privileges for this action', {
            //preventOpenDuplicates: true
            //});
        //}
        //self.$uibModalInstance.dismiss();
    //}

    close() {
        let self = this;
        self.$uibModalInstance.dismiss();
    }

    openRef(face) {
        let self = this;
        self.$uibModalInstance.close({face});
    }

    removeTags(tags) {
        let label = " (create new tag)";
        tags.forEach((tag, index) => {
            if(tag.slice(-label.length) === label) {
                tags[index] = tag.slice(0, -label.length);
            }
        });
    }
}
