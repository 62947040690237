export class AppVersionComponent {
    accounts;
    Auth;

    /*@ngInject*/
    constructor(Auth, NgTableParams, $element, $timeout, $scope,Restangular, $uibModal, $ngConfirm, appVersionService) {
        this.Auth = Auth;
        this.NgTableParams = NgTableParams;
        // this.$element = $element;
        // this.$timeout = $timeout;
        this.$scope = $scope;
        this.tableHeaders;
        this.Restangular = Restangular;
        this.$uibModal = $uibModal;
        this.$ngConfirm = $ngConfirm;
        this.appVersionService = appVersionService;
    }

    $onInit() {
        let self = this;
		self.appVersions = [];
        self.currentAccountName = self.Auth.getCurrentAccountSync().name;
        self.AppVersions = self.Restangular.all('appVersions');
		self.hasCreatePriv = self.Auth.hasRoleSync('manageAppVersion');
        self.filter = '';

		//Join socket
		self.appVersionService.registerListener(self, (event, item, array) => {
			return self.onReceiptOfNewAppVersion(event,item);
		}).catch(err => {
			console.error('Error getting app versions', err);
		});

        self.cols = [
            { field: "title", title: "Title", sortable: "title", show: true, getValue: self.handleValue, context : self},
            { field: "description", title: "Description", show: true, getValue: self.handleValue, context: self  },
            { field: "version", title: "Version Number", sortable: "version", show: true, getValue: self.handleValue, context: self },
            { field: "unitCount", title: "Current Unit Count", show: true, getValue: self.handleValue, context: self },
            { field: "pendingUnitCount", title: "Units Pending Update", show: true, getValue: self.handleValue, context: self },
            { field: "update", title: "Update Units", show: true, getValue: self.handleValue, context: self },
            { field: "s3Uploaded", title: "S3 Upload", show: true, getValue: self.handleValue, context: self },
        ];
        self.tableParams = new self.NgTableParams({
            page: 1, // start with first page
            count: 10, // count per page
            sorting: {
                version: 'asc' // initial sorting
            }
        },

        {
            total: 0,
            getData: function(params) {
                let order;
                if(params && params.sorting) {
                    order = params.sorting();
                    return self.AppVersions.getList({
                        filter: (self.filter.length) ? self.filter : undefined,
                        skip: (params.page()-1)*params.count(),
                        limit: params.count(),
                        by: Object.keys(order)[0],
                        order: order[Object.keys(order)[0]]})
                        .then(function(appVersions) {
                            self.appVersions = appVersions;
                            self.total = appVersions.total;
                            params.total(appVersions.total);
                            return appVersions;
                        })
                        .catch(err => {
                            console.error("Error when retrieving appVersions:" , err.data.err);
                        })
                    }
                }
            }
        )
        // end onInit
    }

    handleValue(self, col, appVersion) {
		return appVersion[col.field];
		switch (col.field) {
			//case 'title':
				//break;
			//case 'description':
				//break;
			//case 'version':
				//break;
			default:
				return appVersion[col.field];

		}
    }

    openCreateAppVersion() {
        let self = this;
        let modalInstance = self.$uibModal.open({
            component: 'appversionmodal',
            bindToController: true,
            size: 'md',
            backdrop: 'static',
            resolve: {
                settings: function() {
                    return {
                        create: true,
                    };
                }
            }
        });

        modalInstance.result.then(function(result){
            self.tableParams.reload();
        });
    };

    openEditAppVersion(appVersion) {
        let self = this;
        let modalInstance = self.$uibModal.open({
            component: 'appversionmodal',
            bindToController: true,
            size: 'md',
            backdrop: 'static',
            resolve: {
                settings: function() {
                    return {
                        create: false,
						edit:true,
						appVersion : appVersion
                    };
                }
            }
        });

        modalInstance.result.then(function(result){
            self.tableParams.reload();
        });
    };

	updateUnits(appVersion){
		let self = this;
        let modalInstance = self.$uibModal.open({
            component: 'unitversionmodal',
            bindToController: true,
            size: 'lg',
            backdrop: 'static',
            resolve: {
				appVersion : appVersion,
            }
        });

        modalInstance.result.then(function(result){
            self.tableParams.reload();
        });
	}

    disableAccount(accountId){
        let self = this;

        self.$ngConfirm(
            {
                title: `Disable Account`,
                theme: 'light',
                animation: 'top',
                scope: self.$scope,
                closeAnimation: 'bottom',
                content: '<h3>Are you sure you want to disable this account?</h3><h4>All unit will be moved to the default account.</h4>',
                escapeKey: true,
                backgroundDismiss: true,
                buttons: {
                    // long hand button definition
                    ok: {
                        text: "Yes",
                        btnClass: 'btn-primary',
                        keys: ['enter'], // will trigger when enter is pressed
                        action: function(scope){
                            return self.accountService.removeAccount(accountId).then((result) => {
                                self.toastr.info('Account disabled.');
                            }).catch((err) => {
                                self.toastr.info('Account deactivation failed.');
                            })
                        }
                    },
                    close: {
                        text: "No",
                        action: function(scope) {

                        }

                    }
                },
            }
        );
    }

	onReceiptOfNewAppVersion(event,appVersion) {
		let self = this;

		let index = _.findIndex(self.appVersions, (storedAppVersion) => {
			return storedAppVersion._id === appVersion._id;
		});

		if(index !== -1) {
			if(event == 'deleted'){
				self.appVersions.splice(index, 1);
			}else {
				self.appVersions.splice(index, 1, appVersion);
			}
		}
	}
}

export default angular.module('secutraqApp.settings')
.component('appVersionManagement', {
    template: require('./app-version.html'),
    controller: AppVersionComponent,
    controllerAs: '$ctrl'
})
.name;
