function checkFirmwareVersion(unitVersion, requiredVersion) {
	if(!unitVersion) {
		return false;
	}
	let current = unitVersion.split('.');
	let required = requiredVersion.split('.');
	let returnVal = false;
	required.every( (reqVer,index) => {
		let currVer = +current[index];
		reqVer = +reqVer;
		if(currVer < reqVer) {
			returnVal = false;
			return false;
		}else if(currVer > reqVer) {
			returnVal = true;
			return false;
		}
		return true;
	});
	if(unitVersion === requiredVersion) {
		return true;
	}else{
		return returnVal;
	}
}

export class NewGroupComponent {
	/*@ngInject*/
	constructor($timeout, $ngConfirm, $uibModal, $scope, unitService, moment, toastr, groupService, siteService) {
		this.$timeout = $timeout;
		this.$ngConfirm = $ngConfirm;
		this.unitService = unitService;
		this.$uibModal = $uibModal;
		this.$scope = $scope;
		this.moment = moment;
		this.groupService = groupService;
		this.toastr = toastr;
		this.siteService = siteService;
	}

	$onInit() {
		let self = this;
		self.activeWizardStep = 1;
		// TODO: edit/new
		if (self.resolve.group) {
			self.edit = true;
			self.group = self.resolve.group;
		} else {
			self.group = {
				sources: []
			};
		}
		self.availableSourceTypes = ['Unit Group', 'Units', 'Sites'];
		self.availableUnitGroups = [];
		self.availableUnits = [];
		self.availableSites = [];
		self.refreshUnitGroups();
		self.refreshUnits();
		self.debouncedRefresh = _.debounce(self.refreshGroupUnits, 300);
		self.$scope.$watch('$ctrl.group.sources', () => {
			self.debouncedRefresh();
		}, true);
		if (self.group.defaultRules && self.group.defaultRules.Location >= 0) {
			self.group.defaultRules.minutes = Math.floor(self.group.defaultRules.Location / 60);
			self.group.defaultRules.seconds = self.group.defaultRules.Location - self.group.defaultRules.minutes * 60;
			self.group.defaultRules.time = self.moment().startOf('day')
.add(self.group.defaultRules.Location, 'seconds')
.toDate();
		}
		if (self.group.units) {
			self.group.units.forEach((unit) => {
				if (unit.rules && unit.rules.Location >= 0) {
					unit.rules.time = self.moment().startOf('day')
.add(unit.rules.Location, 'seconds')
.toDate();
				}
			});
		}
		// $onInit end
	}

	refreshUnitGroups() {
		let self = this;
		self.unitService.getGroups().then(function(groups) {
			self.availableUnitGroups = groups;
		})
.catch((err) => {
			console.error(err);
		});
	}
	refreshUnits(query) {
		let self = this;
		self.unitService.getUnitsWithQuery({
			filter: query,
			query: ["SecutraqApp","SecutraqStationaryApp"],
			field: 'type',
			//limit: 20,
		}).then((units) => {
			self.availableUnits = units;
		})
.catch((err) => {
			console.error(err);
		});
	}
	refreshSites(query) {
		let self = this;
		self.siteService.getSitesWithQuery({
			filter: query,
			limit: 20,
		}).then((sites) => {
			self.availableSites = sites;
		})
.catch((err) => {
			console.error(err);
		});
	}

	refreshGroupUnits() {
		let self = this;
		let promises = [];
		self.group.sources.forEach((source) => {
			if (source.data) {
				switch (source.type) {
					case 'Units':
						source.data.forEach((unitId) => {
							promises.push(self.unitService.getUnitByID(unitId));
						});
						break;
					case 'Unit Group':
						source.data.forEach((group) => {
							promises.push(self.unitService.getUnitsWithQuery({
								query: group,
								field: 'groups'
							}));
						});
						break;
					default:
				}
			}
		});
		return Promise.all(promises).then((data) => {
			let units = [];
			data.forEach((source) => {
				if (_.isArray(source)) {
					units = units.concat(source);
				} else {
					units.push(source);
				}
			});
			units = _.uniqBy(units, '_id');
			units = _.filter(units, (u) => {
				return u.type.includes('App');
			});
			let tempUnitRules = _.cloneDeep(self.group.units) || [];
			self.group.units = [];
			tempUnitRules.forEach((unitRule) => {
				let index = _.findIndex(units, (o) => {
					return o._id == unitRule.unit;
				});
				if (index != -1) {
					unitRule.data = units.splice(index, 1)[0];
					self.group.units.push(unitRule);
				}
			});
			units.forEach((unit) => {
				self.group.units.push({
					unit: unit._id,
					data: unit
				});
			});
		});
		// TODO: Resolve sources.
	}

	nextWizardStep() {
		let self = this;
		self.activeWizardStep++;
	}

	prevWizardStep() {
		let self = this;
		if (self.activeWizardStep !== 1) {
			self.activeWizardStep--;
		}
	}


	closeModal() {
		let self = this;
		let result = _.cloneDeep(self.group);
		delete result.defaultRules.time;
		if(result.units) {
			result.units.forEach((unit) => {
				delete unit.data;
				if(unit.rules) {
					delete unit.rules.time;
				}
			});
		}
		let savePromise = Promise.resolve();
		if(self.edit) {
			savePromise = self.groupService.updateGroup(result);
		} else {
			savePromise = self.groupService.createGroup(result);
		}
		savePromise.then( () => self.modalInstance.close() )
			.catch( (err) => {
				console.error(err);
				self.toastr.error('Group save failed.');
			});
	}

	dismissModal() {
		let self = this;

		self.$ngConfirm({
			title: `Are you sure?`,
			theme: 'modern',
			content: `Clicking <b>Discard</b> would discard all information entered.`,
			escapeKey: true,
			backgroundDismiss: true,
			buttons: {
				// long hand button definition
				ok: {
					text: "Discard",
					btnClass: 'btn-primary',
					keys: ['enter'], // will trigger when enter is pressed
					action(scope) {
						self.modalInstance.dismiss();
					}
				},
				// short hand button definition
				close: {
					text: 'Cancel',
					action(scope) {}
				}
			},
		});
	}

	doLog() {
		console.debug(this);
	}

	convertTime(rule) {
		let self = this;
		let date = self.moment(rule.time);
		let duration = (rule.minutes||0)*60 + rule.seconds||0;
		if (duration > 60 * 60 * 18) {
			duration = 60 * 60 * 18;
			// rule.time = self.moment().startOf('day').add(duration, 'seconds').toDate();
		}
		rule.Location = duration;
	}

	removeRules(unit) {
		let self = this;
		delete unit.rules;
	}

}
export default angular.module('secutraqApp.groups')
	.component('newGroup', {
		template: require('./new-group.component.html'),
		controller: NewGroupComponent,
		controllerAs: "$ctrl",
		bindings: {
			modalInstance: '=',
			resolve: '<'
		}
	})
	.filter('checkGroupInvalid', function() {
		return function(group) {

			let hasStationary = _.some(group.units, (u) => {
				return u.data.type === 'SecutraqStationaryApp';
			});
			let hasSubReqVersion = _.some(group.units, (u) => {
				return u.data.type === 'SecutraqApp' && !checkFirmwareVersion(u.data.firmwareVersion, '1.6.1.0');
			});
			if(hasStationary && hasSubReqVersion) {
				return true;
			} else {
				return false;
			}
		};
	})
	.filter('checkPTTVersions', function() {
		return function(group) {
			let returnVal = false;
			let checkPTT = group.defaultRules.PTT;
			checkPTT = checkPTT || _.some(group.units, (u) => {
				if(u.rules && u.rules.PTT) {
					return true;
				}else{
					return false;
				}
			});
			if(checkPTT) {
				returnVal = _.some(group.units, (u) => {
					return !checkFirmwareVersion(u.data.firmwareVersion,'1.3.0.1');
				});
			}
			return returnVal;
		};
	})
	.name;
