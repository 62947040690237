export default function($stateProvider) {
    'ngInject';
    $stateProvider
    .state('main.sites', {
        url: '/site',
        views: {
            'content@main' : {
                template: '<site></site>'
            }
        },
        authenticate: true,
        data: {
            title: 'Site'
        }
    })

    .state('main.sites.detail', {
        url: '/:siteID',
        params: {
            selectedSite: null,
            sites: null,
            siteID: null
        },
        authenticate: true,
        data: {
            title: 'Detail'
        }
    });
}
