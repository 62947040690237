export default function($stateProvider) {
  'ngInject';
  $stateProvider
    .state('login', {
      url: '/login',
      views: {
        root: {
          template: '<login></login>'
        }
      }
      })
    .state('logout', {
      url: '/logout?referrer',
      referrer: 'main',
      template: '',
      controller($state, Auth) {
        'ngInject';
        var referrer = $state.params.referrer || $state.current.referrer || 'main';
          Auth.logout();
          $state.go(referrer);
      }
    });
}
