'use strict';
// @flow
export class CustomEventsListComponent {
	/*@ngInject*/
	constructor(Auth, $uibModal, $ngConfirm, accountService,toastr, moment, eventService, mapService, customEventTypeService) {
		this.Auth = Auth;
		this.$uibModal = $uibModal;
		this.$ngConfirm = $ngConfirm;
		this.accountService = accountService;
		this.toastr = toastr;
		this.moment = moment;
		this.eventService = eventService;
		this.mapService = mapService;
		this.customEventTypeService = customEventTypeService;
	}

	$onInit() {
		const self = this;
		self.markerIcons = self.mapService.markerIcons;
		self.eventService.getEventDetails.then( (eventDetails) => {
			self.eventDetails = [];
			_.forEach(eventDetails, (details, type) => {
				const eventDetails = _.cloneDeep(details);
				if(type !== 'online' && type !== 'offline') {
					eventDetails.eventType = type;
					self.eventDetails.push(eventDetails);
				}
			});
			self.customEventTypeService.registerListener(self, (event, item, array) => {

				let index = _.findIndex(self.eventDetails, (event) =>{
					return event._id === item._id || event.eventType === item.eventType;
				} );
				self.eventDetails.splice(index,1,item);
			});
		});
	}


	editEvent(event) {
		let self = this;
		let modalInstance = self.$uibModal.open({
			component: 'customEventEditModal',
			backdrop: 'static',
			size: 'lg',
			keyboard: false,
			resolve:{
				event:_.cloneDeep(event),
				edit:true
			}
		});
	}
}


export default angular.module('secutraqApp.settings')
	.component('eventSettings', {
		template: require('./custom-event-list.html'),
		controller: CustomEventsListComponent,
		controllerAs: '$ctrl',
		bindings: {
			currentAccount: '<'
		}
	})
	.name;
