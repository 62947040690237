export class UnitsListComponent {
	/*@ngInject*/
	constructor($stateParams, Restangular, NgTableParams, $uibModal, socket, $log, Auth, moment, unitService, toastr, $timeout, appVersionService) {
		this.$stateParams = $stateParams;
		this.Restangular = Restangular;
		this.NgTableParams = NgTableParams;
		this.$uibModal = $uibModal;
		this.socket = socket;
		this.$log = $log;
		this.Auth = Auth;
		this.moment = moment;
		this.unitService = unitService;
		this.appVersionService = appVersionService;
		this.filter = '';
		this.isColumnsCollapsed = false;
		this.toastr = toastr;
		this.$timeout = $timeout;
		this.self = this;
	}

	$onInit() {
		let self = this;
		//NOTE : Invert logic due to weirdness with toggle switch
		self.showAllAccounts = false;
		self.$stateParams.accountID = self.Auth.getCurrentAccountSync().ref;

        self.unitService.registerListener(self, (event, item, array) => {
            return self.onReceiptOfNewUnit(event,item);
        }).catch(err => {
            console.error('Error getting events', err);
        });

		self.units = [];
		self.appVersions = [];
		self.dummyUnits = [
			{
				_id: self.generateNumber(1000, 10000),
				name: "SEQUTRAQ UNIT_001",
				description: "An active field-unit",
				imei: "SampleIMEI",
				sim1: "0790760740",
				sim2: "0828756822",
				lastHB: [{
					ts: self.moment.utc(),
					loc: {
						lat: 25,
						lng: 25
					},
					charge: false,
					battery: 80,
					online: true
				}],
				lastlocation: {
					lat: 25,
					lng: 25
				},
				lastuser: {
					_id: "Unique ID",
					firstname: "Michael",
					lastname: "Rogers"
				},
				lastsite: {
					_id: 1,
					sitename: "Manola-Park",
					loc: {
						lat: 24,
						lng: 24
					}
				},
				unitstatus: {id: 1, description: "Active"},
				groups: "1"
			}
		];


		// self.Units = self.Restangular.all('units');

		/**
		 * An array of columns that are showed by default.
		 * We iterate through all the columns and mark those with VIEW set to true
		 */
		self.selectedColumns = [];

		self.cols = [{
				title: "SEQUTRAQ Unit",
				field: "name",
				show: true,
				sortable: "name",
			}, {
				title: "Description",
				field: "description",
				show: true,
				sortable: "description",
			}, {
				title: "IMEI",
				field: "imei",
				show: false,
				sortable: "imei",
			},
			// {
			// 	title: "Sim 1",
			// 	field: "sim1",
			// 	show: true,
			// 	sortable: "sim1",
			// }, {
			// 	title: "Sim 2",
			// 	field: "sim2",
			// 	show: true,
			// 	sortable: "sim2",
			// },
			{
				title: "Last Activity",
				field: "lasthb",
				show: true,
				sortable: "lastActivity",
			},
			{
				title: "Chreosis name",
				field: "cname",
				show: false,
			},
			 {
				title: "Online",
				field: "online",
				show: true,
				sortable: "online",
			},
			 {
				title: "Version",
				field: "firmwareVersion",
				show: true,
				sortable: "firmwareVersion",
			},
			 {
				title: 'Battery',
				field: 'battery',
				show: true,
				sortable: "lastHB.0.battery",
			}, {
				title: 'Charging',
				field: 'charging',
				show: false,
				sortable: "lastHB.0.status.charging",
			}, {
				title: 'Low Battery',
				field: 'lowbattery',
				show: false,
				sortable: "lastHB.0.status.lowbattery",
			}, {
				title: 'Last Location',
				field: 'lastLocation',
				show: false,
			},
		   	{
				title: 'API Linked',
				field: 'active',
				show: true,
			}, {
				title: 'Last/Current User',
				field: 'lastAsset',
				show: true,
				sortable: "lastAsset.0.firstname",
			},
			// {
			// 	title: 'Last Site',
			// 	field: 'lastSite',
			// 	show: true,
			// 	sortable: "lastSite",
			// },
			{
				title: 'Type',
				field: 'type',
				show: true,
				sortable: "type",
			},
			{
				title: 'Groups',
				field: 'groups',
				show: true,
				sortable: "groups",
			},
		];
		self.hasFaceRecallPriv = self.Auth.hasRoleSync('faceRecall');
		self.hasEditPriv = self.Auth.hasRoleSync('edit');
		self.hasMovePriv = self.Auth.hasRoleSync('account');
		self.hasApiPriv = self.Auth.hasRoleSync('apiKeys');
		self.hasSoftwarePriv = self.Auth.hasRoleSync('softwareUpdates');
		if(self.hasApiPriv) {
		   	self.cols.push({
				title: 'API Enabled',
				field: 'apiEnabled',
				show: true,
			});
		}
		if(self.hasFaceRecallPriv) {
			self.cols.push({
				title: 'FR Analytics',
				field: 'analytics',
				show: true,
				sortable: "analytics",
			});
            self.cols.push( {
                    title: "LPR Region",
                    field: "country",
                    show: false,
                    sortable: "country",
                });
			self.cols.push({
				title: 'LPR Analytics',
				field: 'lprAnalytics',
				show: true,
				sortable: "lprAnalytics",
			});
		}

		if(self.hasSoftwarePriv) {
			self.appVersionService.registerListener(self, (event, item, array) => {
				return self.onReceiptOfNewAppVersion(event,item);
			}).then( (appVersions) => {
				appVersions.forEach( (appVersion) => {
					self.onReceiptOfNewAppVersion('update', appVersion);
				} );
			} )
.catch(err => {
				console.error('Error getting app versions', err);
			});
		}
		/**
		 * The purpose of colValues is to contain the HTML-code that we want
		 * to be assumed in NgTable.
		 * TODO: This still needs to be implemented, but I think this should stop
		 * angular from calling handleDisplay() function continuously
		 * We can then use $interval to handle update-intervals.
		 * @type {Object}
		 */
		self.colValues = {};

		_.forEach(self.cols, (col) => {
			if(col.show) {
				self.selectedColumns.push(col.title);
			}
			self.colValues[col.field] = '';
		});


		self.tableParams = new self.NgTableParams({
			page: 1, // start with first page
			count: 10, // count per page
			sorting: {
				name: 'asc' // initial sorting
			}
		},
			{
				total: 0,
				getData(params) {
					let order;
					if (params && params.sorting) {
						order = params.sorting();
						return self.unitService.getUnitsWithQuery({
							filter: self.filter.length ? self.filter : undefined,
							skip: (params.page() - 1) * params.count(),
							// accountID: self.$stateParams.accountID,
							limit: params.count(),
							by: Object.keys(order)[0],
							order: order[Object.keys(order)[0]],
							allAccounts: self.showAllAccounts
						}).then(function(units) {
							self.units = units;
							self.total = units.total;
							params.total(units.total);
							// TODO: We need to call formatData on each unit and return only units
							_.forEach(units, (unit) => {
								self.formatData(unit);
							});
							return units;
							// return self.formatData(units);
						})
							.catch(err => {
								console.error("Error caught when getting data for units: ", err.data.err);
							});
					}
				}
			}
		);

		this.tableParams.reload();

	}

	$onDestroy() {
		let self = this;
		self.unitService.unregisterListener(self, true);
		self.appVersionService.unregisterListener(self, true);
	}

	/**
	 * This function would become the function that will properly handle
	 * the receipt of new units.
	 */
	// onReceiptofNewUnit(newUnit) {
	// 	let self = this;
	// 	let unit = self.formatData(newUnit);
	// 	self.categorizeUnit(unit);
	// 	self.reloader();
	// }

    /**
     * Places a unit in it's appropriate place within self.units
     */
    categorizeUnit(unit) {
        let self = this;

		if(unit.isEdit) {
			//Received unit is marked as an edited one
			let index = _.findIndex(self.units, (storedUnit) => {
				return storedUnit._id === unit._id;
			});

			if(index !== -1) {
				//We've found this unit
				self.units.splice(index, 1, unit);
			} else {
				console.error("Cannot find the unit to replace with a newly received, edited one");
			}
		} else {
			//This is not an edited unit, but a newly created one
			self.units.push(unit);
		}
    }

	onReceiptOfNewUnit(event,unit) {
		let self = this;

		let index = _.findIndex(self.units, (storedUnit) => {
			return storedUnit._id === unit._id;
		});

		if(index !== -1) {
			if(event == 'deleted') {
				self.units.splice(index, 1);
			}else {
				self.units.splice(index, 1, self.formatData(unit));
			}
		}
	}

	onReceiptOfNewAppVersion(event,appVersion) {
		let self = this;

		let index = _.findIndex(self.appVersions, (storedAppVersion) => {
			return storedAppVersion._id === appVersion._id;
		});

		if(index !== -1) {
			if(event == 'deleted') {
				self.appVersions.splice(index, 1);
			}else {
				self.appVersions.splice(index, 1, appVersion);
			}
		}else{
			self.appVersions.push(appVersion);
		}
		if(!self.latestAppVersion && appVersion.s3Uploaded) {
			self.latestAppVersion = appVersion;
		}
		if(appVersion.versionNumber > self.latestAppVersion.versionNumber && appVersion.s3Uploaded) {
			self.latestAppVersion = appVersion;
		}
	}

	showMapFromLatLng(unit) {
		let self = this;
		if(!unit) return null;
		if(!unit.lastLocation) return null;
		if(!unit.lastlocation[0]) return null;
		if(!unit.lastlocation[0].location) return null;
		if(!unit.lastlocation[0].location.coordinates) return null;

		let lat = unit[lastlocation][0].location.coordinates[1];
		let lng = unit[lastlocation][0].location.coordinates[0];
		let online = unit.online;
		let modalInstance = self.$uibModal.open({
			component: 'minimapmodal',
			// backdrop: 'static',
			size:'sm',
			resolve: {
				loc: {lat, lng},
				online,
			}
		});

		modalInstance.result.then((result) => {

		});

	}

	formatData(unit) {
		let self = this;

		// TODO: GET RELEVANT DEFAULT VALUES
		unit.online = unit.online || false;
		if (!unit.lastHB || !unit.lastHB[0]) {
			unit.lastHB = [{
				battery: 0,
				unitstatus: 0,
				ts: 0,
			}];
			// unit.lastuser = {
			// 	firstname: 'no last user',
			// 	lastname: '',
			// 	_id: ''
			// };
			//
			// unit.lastlocation = {
			// 	lat: 0,
			// 	lng: 0
			// };
			//
			// unit.lastsite = {
			// 	loc: {
			// 		lat: 0,
			// 		lng: 0
			// 	},
			// 	sitename: 'No last site',
			// 	_id: ''
			// }
		}

		// _.merge(unit, {
		// 	online: unit.lastHB[0].online,
		// 	battery: unit.lastHB[0].battery
		// });


		return unit;
	}

	onColumnSelected($item, $model) {
		$item.show = true;
	}

	onColumnRemoved($item, $model) {
		$item.show = false;
	}

	stringtoimei(str) {
		if (str.length !== 8) {
return 0;
}

		let numlsb0 = bigInt(str[0]);
		let numlsb1 = bigInt(str[1]);
		let numlsb2 = bigInt(str[2]);
		let numlsb3 = bigInt(str[3]);
		let numlsb4 = bigInt(str[4]);
		let numlsb5 = bigInt(str[5]);
		let numlsb6 = bigInt(str[6]);
		let numlsb7 = bigInt(str[7]);
		let imei = numlsb0;
		imei = imei.add(numlsb1.times(bigInt('256')));
		imei = imei.add(numlsb2.times(bigInt('65536')));
		imei = imei.add(numlsb3.times(bigInt('16777216')));
		imei = imei.add(numlsb4.times(bigInt('4294967296')));
		imei = imei.add(numlsb5.times(bigInt('1099511627776')));
		imei = imei.add(numlsb6.times(bigInt('281474976710656')));
		imei = imei.add(numlsb7.times(bigInt('72057594037927936')));
		return imei.toString();
	}

	imeitobytes(imei) {
		let bytes = [];
		let imeibig = bigInt(imei);
		bytes.push(imeibig.and(0xFF).valueOf());
		imeibig = imeibig.shiftRight(8);
		bytes.push(imeibig.and(0xFF).valueOf());
		imeibig = imeibig.shiftRight(8);
		bytes.push(imeibig.and(0xFF).valueOf());
		imeibig = imeibig.shiftRight(8);
		bytes.push(imeibig.and(0xFF).valueOf());
		imeibig = imeibig.shiftRight(8);
		bytes.push(imeibig.and(0xFF).valueOf());
		imeibig = imeibig.shiftRight(8);
		bytes.push(imeibig.and(0xFF).valueOf());
		imeibig = imeibig.shiftRight(8);
		bytes.push(imeibig.and(0xFF).valueOf());
		imeibig = imeibig.shiftRight(8);
		bytes.push(imeibig.and(0xFF).valueOf());
		return bytes;
	}

	getIMEI(unit) {
		return stringtoimei(unit.connID.data) || 'Wrong Format';
	}

	applyFilter() {
		this.tableParams.page(1);
		this.tableParams.reload();
	}

	isOnline(unit) {
		if (unit.lastHB && unit.lastHB.length > 0) {
return this.moment.utc().diff(this.moment.utc(unit.lastHB[0].ts), 'minutes') < 10;
}
		return false;
	}

	lastHeartbeat(unit) {
		if (unit.lastHB && unit.lastHB.length > 0) {
			let minutes = Math.abs(this.moment.utc().diff(this.moment.utc(unit.lastHB[0].ts), 'minutes'));
			if (minutes === 1) {
				return '1 minute ago';
			}
			if (minutes > 1) {
				return `${minutes.toString()} minutes ago`;
			}
			return 'less than a minute ago';
		}
		return 'no last heartbeat';
	}

	countOnlineClients(clients) {
		return _.filter(clients, {
			connected: true
		}).length;
	}

	reloader() {
		let self = this;
		self.tableParams.reload();
	}

	/**
	 * Upon desiring to add a new unit we execute this function from HTML.
	 * NOTE and TODO: We currently directly add this unit to dummyUnits, but we should
	 * in fact tell the server about the new unit, and then retrieve the new unit from there
	 */
	addNewUnit() {
		let self = this;

		let modalInstance = self.$uibModal.open({
			component: 'newunit',
			backdrop: 'static',
			resolve: {
				create: true
			}
		});

		modalInstance.result.then((unit) => {
			self.onReceiptofNewUnit(unit);
		});
	}

	changeUnitAccount() {
		let self = this;

		let modalInstance = self.$uibModal.open({
			component: 'changeunitaccount',
			backdrop: 'static',
		});

		modalInstance.result.then((unit) => {
		});
	}

	/**
	 * Upon desiring to edit a unit we execute this function from HTML.
	 */
	editUnit(unit) {
		let self = this;
		let unitCopy = _.cloneDeep(unit);

		let modalInstance = self.$uibModal.open({
			component: 'newunit',
			backdrop: 'static',
			resolve: {
				edit: true,
				unit: unitCopy
			}
		});

		modalInstance.result.then((unit) => {
			//NOTE We should send the new, edited unit to the server
			unit = _.pick(unit,['_id','name', 'groups', 'config', 'country', 'requestSnapshotCallback', 'apiType', 'lastLocation', 'defaultLocation', 'metagratedEnabled', 'metagratedCamId', 'scheduledConfigChanges']);
			self.unitService.updateUnit(unit).then((result) => {
				self.toastr.info('Unit updated.');
			})
.catch((err) => {
				self.toastr.error('Unit update failed.');
				console.error(err);
			});
			// unit.isEdit = true;
			// self.onReceiptofNewUnit(unit);
		});
	}

	createUnit() {
		let self = this;

		let modalInstance = self.$uibModal.open({
			component: 'newunit',
			size: 'm',
			backdrop: 'static',
			resolve: {
				edit: false,
				create:true,
			}
		});

		modalInstance.result.then((unit) => {
			//NOTE We should send the new, edited unit to the server
			unit = _.pick(unit,['imei','name','description','type','groups', 'stationaryUnit', 'lastLocation', 'metagratedEnabled', 'metagratedCamId']);
			self.unitService.createUnit(unit).then((result) => {
				self.toastr.info('Unit created.');
			})
.catch((err) => {
				self.toastr.error('Unit create failed.');
				console.error(err);
			});
			// unit.isEdit = true;
			// self.onReceiptofNewUnit(unit);
		});
	}

    generateNumber(min,max) {
        return Math.floor(Math.random()*(max-min+1)+min);
    }

	logoutAsset(unitId) {
		let self = this;
		self.Restangular.one("units",unitId).customPATCH({},'logoutAssets')
.then((result) => {
		})
.catch((err) => {
			console.error(err);
		});
	}
	requestSnapshot(unitId) {
		let self = this;
		self.unitService.requestSnapshot(unitId).then((result) => {
			if(result) {
				self.toastr.info('Snapshot requested.');
			}else{
				self.toastr.warning('Snapshot request already pending.');
			}
		})
.catch((err) => {
			self.toastr.error('Snapshot request failed.');
			console.error(err);
		});
	}
	requestVideo(unitId) {
		let self = this;
		self.unitService.requestVideo(unitId).then((result) => {
			self.toastr.info('Video requested.');
		})
.catch((err) => {
			self.toastr.error('Video request failed.');
			console.error(err);
		});
	}

	syncUnit(unit) {
		let self = this;
		self.unitService.syncUnit(unit._id).then((result) => {
			self.toastr.info('Update requested.');
		})
.catch((err) => {
			self.toastr.error('Update request failed.');
			console.error(err);
		});
	}

	toggleLprAnalytics(unit) {
		let self = this;
		if(unit.lprAnalytics) {
			self.unitService.changeLprAnalytics(unit,false).then((result) => {
				self.toastr.info('LPR Analytics have been disabled.');
			})
.catch((err) => {
				self.toastr.error('LPR Analytics disabeling failed.');
			});
		}else {
			self.unitService.changeLprAnalytics(unit,true).then((result) => {
				self.toastr.info('LPR Analytics have been enabled.');
			})
.catch((err) => {
				self.toastr.error('LPR Analytics enabeling failed.');
			});
		}
	}

	toggleAPIAuthorization(unit) {
		let self = this;
		if(unit.apiEnabled) {
			self.unitService.changeAPIAuthorization(unit,false).then((result) => {
				self.toastr.info('API Authorization have been disabled.');
			})
.catch((err) => {
				self.toastr.error('API Authorization disabeling failed.');
			});
		}else {
			self.unitService.changeAPIAuthorization(unit,true).then((result) => {
				self.toastr.info('API Authorization have been enabled.');
			})
.catch((err) => {
				self.toastr.error('API Authorization enabeling failed.');
			});
		}
	}

	toggleAnalytics(unit) {
		let self = this;
		if(unit.analytics) {
			self.unitService.changeAnalytics(unit,false).then((result) => {
				self.toastr.info('Analytics have been disabled.');
			})
.catch((err) => {
				self.toastr.error('Analytics disabeling failed.');
			});
		}else {
			self.unitService.changeAnalytics(unit,true).then((result) => {
				self.toastr.info('Analytics have been enabled.');
			})
.catch((err) => {
				self.toastr.error('Analytics enabeling failed.');
			});
		}
	}

	changeAllAnalytics(value) {
		let self = this;
		self.unitService.changeAllAnalytics(value).then((result) => {
			if(value) {
				self.toastr.info('Analytics have been enabled.');
			}else {
				self.toastr.info('Analytics have been disabled.');
			}
		})
.catch((err) => {
			self.toastr.error('Analytics change failed.');
		});
	}

	doLog() {
		console.debug(this);
	}

	toggle() {
		let self = this;
		self.$timeout( () => {
			if(self.showAllAccounts) {

				self.cols.push({
					title: "Account",
					field: "account.name",
					show: true,
					sortable: "account.name",
				});
			}else{
				_.remove(self.cols,['title','Account']);
			}
			self.tableParams.reload();
		},0);
	}

	upgradeUnitSoftware(unit) {
		let self = this;
		self.unitService.softwareUpdateSingleUnit(unit._id, self.latestAppVersion).then( (res) => {
			self.toastr.info("Update requested");
		} )
.catch( (err) => {
			self.toastr.info("Update request failed");
		} );

	}

}

export default angular.module('secutraqApp.units')
	.component('unitlist', {
		template: require('./units.list.html'),
		controller: UnitsListComponent,
		controllerAs: '$ctrl'
    })
.filter("countryFilter", function() {
        return function(input) {
            let countries = {
                ar: "Argentina",
                au: "Australia",
                br: "Brazil",
                cn: "China",
                eu: "Europe",
                gb: "Great Britain",
                gcc: "Gulf Cooperation Council",
                in: "India",
                id: "Indonesia",
                jp: "Japan",
                kr: "Korea",
                my: "Malaysia",
                middleeast: "Middle East",
                nz: "New Zealand",
                ru: "Russia",
                sg: "Singapore",
                za: "South Africa",
                th: "Thailand",
                us: "North America",
            };
            return countries[input];
        };
    })
	.name;
