export class PrivGroupService {
	/*@ngInject*/
	constructor($uibModal, moment, Restangular, socket, Auth, toastr, unitService, siteService, routeService) {
		let self = this;
		this.$uibModal = $uibModal;

		this.Restangular = Restangular;
		this.Auth = Auth;

		this.Contacts = this.Restangular.all('contacts');
		this.Groups = this.Contacts.all('groups');
		this.unitService = unitService;
		this.siteService = siteService;
		this.routeService = routeService;
		// self.generateRoute();


	}


    getGroupWithQuery(query){
		let self = this;
		let allGroups = [];
		allGroups.push(self.siteService.getGroups(query));
		allGroups.push(self.unitService.getGroups(query));
		allGroups.push(self.routeService.getGroups(query));
		allGroups.push(self.Groups.getList(query));
		return Promise.all(allGroups).then( (results) => {
			let groups = _.uniq(_.flatten(results));
			return groups;
		} ).catch(err=>{
			console.error(err);
			return err;
		});

    }

}

export default angular.module('secutraqApp.dashboard')
	.service('privGroupService', PrivGroupService);
