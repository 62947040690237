export class SiteComponent {

    /*@ngInject*/
    constructor($scope, $timeout, $state, siteService, $stateParams, Restangular) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.siteService = siteService;
        this.$stateParams = $stateParams;
        this.Restangular = Restangular;

    }

    $onInit() {
        let self = this;
        self.sites = [];

        self.siteService.registerListener(self,(event,item,array)=>{
            if (event === 'deleted') {
                self.onRemoveSite(item);
            } else {
                self.onReceiptOfSite(item);
            }
        }).then((sites) => {
            self.sites = sites;
            self.selectedSite = self.sites[0];

            if(self.$stateParams.siteID) {
                self.$scope.$broadcast('siteIDInAddressBar', {siteID: self.$stateParams.siteID});
            }
        });

    }

	$onDestroy() {
		this.siteService.unregisterListener(this);
	}

    onReceiptOfSite(newSite) {
        let self = this;
        let index = _.findIndex(self.sites, (site) => {
            return newSite._id === site._id;
        });
        if(newSite.contacts && newSite.contacts.length > 0) {
            newSite.contacts.forEach((contact) => {
                if(!contact._id) {
                    self.Restangular.one('contacts',contact).get()
.then((newContact) => {
                        let siteIndex = _.findIndex(self.sites,(o) => {
                            return o._id == newSite._id;
                        });

                        if(siteIndex == -1) {
                            console.error('No Site found when populating contact.');
                            return null;
                        }
                        let contactIndex = _.findIndex(self.sites[siteIndex].contacts,(o) => {
                            return o == newContact._id;
                        });

                        if(contactIndex == -1) {
                            console.error('No contact found in site when populating contact.');
                            return null;
                        }
                        self.sites[siteIndex].contacts[contactIndex] = newContact;
                    })
.catch((err) => {
                        console.error(err);
                    });
                }
            });
        }

        if(index === -1) {
            //We don't have this site so we add it
            self.sites.unshift(newSite);
            self.$scope.$broadcast('drawNewSite', newSite);
        } else {
            self.sites.splice(index, 1, newSite);
            self.$scope.$broadcast('siteEdited', newSite);
        }
    }


    /**
     * Well, after quite some changes, this function is no longer very useful. I am no longer using ui-sref and stateParams, but
     * simply decided to put SiteDetailComponent as a child of SiteListComponent and have it bind to some variables.
     * NOTE: The reason I'm leaving this here, is because of the todo:
     * TODO: We must implement a route that we can visit a specific site via address-bar and have that open the accordion
     */
    setSelectedSite(selectedSite) {
        let self = this;
        self.selectedSite = selectedSite;
        self.$state.go('main.configure.site.detail', {siteID: selectedSite._id,
                                                      selectedSite,
                                                      sites: self.sites});
        self.$scope.$broadcast('selectedSiteChanged', selectedSite);
    }

    /**
     * At SiteListComponent we have onRemoveSite() which then calls to this removeSite() (via bindings)
     */
    onRemoveSite(siteToRemove) {
        let self = this;

        let index = _.findIndex(self.sites, (site) => {
            return site._id === siteToRemove._id;
        });

        if(index !== -1) {
            self.sites.splice(index, 1);
        }

        // TODO: We must let the server know about the removed site
    }

    removeSite(site) {
        let self = this;
        self.siteService.removeSite(site);
    }

    doLog() {
        console.debug(this);
    }


}
export default angular.module('secutraqApp.sites')
.component('site', {
    template: require('./site.component.html'),
    controller: SiteComponent,
    controllerAs: "$ctrl"
});
