export class RouteDetailComponent {
    /*@ngInject*/
    constructor($uibModal, $scope, routeService, siteService, genericMapService, $timeout, $interval, $sanitize) {
        this.$uibModal = $uibModal;
        this.$scope = $scope;
		this.$sanitize = $sanitize;
        this.routeService = routeService;
        this.siteService = siteService;
        this.genericMapService = genericMapService;
        this.$timeout = $timeout;
        this.$interval = $interval;

    }

    $onInit() {
        let self = this;
        self.routeID = `${self.route._id}`;

        /**
         * The simulation marker that will be moving along the route, showing how it has been defined
         * Point A, Point B, back to Point A, Point C
         */
        self.demoMarker = self.genericMapService.getSpecifiedMarkerSync("site");

        /**
         * Whether we are presenting our demo
         */
        self.demoMoving = false;

        /**
         * MovingMarker has a start() method, but it is not working correctly.
         * I've noticed that I can get it functioning with moveTo()
         * @type {Array}
         */
        self.demoLatLngs = [];


        self.$timeout(() => {

            // L.mapbox.accessToken = 'pk.eyJ1Ijoicm9tcGVsc3RvbXBlbCIsImEiOiJjamNoaHJmOWkxaDFqMnlydnV1d202MTA2In0.vaZnBgwNagSx6wAljOUEMQ';
            // self.map = L.mapbox
            //     .map(`${self.routeID}`,'mapbox.streets', {
            //         zoomControl:false,
            //         drawControl: false,
            //         doubleClickZoom: false,
            //     });


            // self.map = L.map(`${self.routeID}`, {
            //     zoomControl:false,
            //     drawControl: false,
            //     doubleClickZoom: false,
            //     });
            //
            // L.tileLayer('https://api.mapbox.com/v4/{map_id}/{z}/{x}/{y}.png?access_token={accessToken}',{
            //     attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>',
            //     map_id:'mapbox.streets',
            //     accessToken:'pk.eyJ1Ijoicm9tcGVsc3RvbXBlbCIsImEiOiJjamNoaHJmOWkxaDFqMnlydnV1d202MTA2In0.vaZnBgwNagSx6wAljOUEMQ'
            // }).addTo(self.map);
            self.map = self.genericMapService.generateMap(`${self.routeID}`,{
                zoomControl:false,
                drawControl: false,
                doubleClickZoom: false,
            });
            self.map.setView([-26.6145, 27.0950], 5);

            self.map.invalidateSize();

            //If this route is LinkedToSite we should then just draw that site
            //NOTE that I am getting the site asyncronously. Check with Liaan
            if(self.linkedToSite) {
                self.drawSite(self.routeCollection.site);
            }
            self.drawRouteWaypoints();
            self.fitMapToWaypoints();
            self.drawDistanceLines();
            self.instantiateRouteDemo();
        });
    }

    /**
     * When we want the demoMarker to show us how the route will be walked
     */
    startDemoMarker() {
        let self = this;
        let demoTime = 1000;
        let steps = self.demoLatLngs.length-1;

        if(!self.demoMoving) {
            self.demoMoving = true;
            for (let i = 1; i < self.demoLatLngs.length; i++) {
                self.$timeout(() => {
                    self.demo.moveTo(self.demoLatLngs[i], demoTime);
                }, (i-1)*demoTime);
            }

            self.$timeout(() => {
                self.demo.moveTo(self.demoLatLngs[0], demoTime);
                self.$timeout(() => {
                    self.demoMoving = false;
                }, demoTime);
            }, steps*demoTime);
        } else {

        }

    }

    stopDemoMarker() {
        let self = this;
        self.demo.pause();
    }

    /**
     * To lay the foundation for the demoMarker and placing it on the first waypoint in the route
     * @return {[type]} [description]
     */
    instantiateRouteDemo() {
        let self = this;
        let latlngs = [];
        _.forEach(self.route.path, (point) => {
            latlngs.push(_.find(self.routeCollection.waypoints,['_id',point.waypoint]).loc);
        });
        self.demo = L.Marker.movingMarker(latlngs, [3000], {icon:self.demoMarker}).addTo(self.map);
        self.demoLatLngs = latlngs;

        let idx = 0;
    }

    drawRouteWaypoints() {
        let self = this;
        let waypointNumber = 1;
        _.forEach(self.route.path, (point) => {
            //Placing the marker
            // let waypoint = self.genericMapService.placeSpecifiedMarkerAtCoordinates(self.map, "circle", point.waypoint.loc);
            let wp = _.find(self.routeCollection.waypoints,['_id',point.waypoint]);
            let waypoint = self.genericMapService.drawCircleFromLoc(self.map, wp.loc, {radius:wp.radius, weight:4, fillColor: '#2C98DE'});

            //Binding tooltip
            waypoint.bindTooltip(`${waypointNumber}`, {permanent: true, direction: 'center', offset:[10,0]});
            self.genericMapService.addClassToLayer(waypoint._tooltip._container, "waypoint-small-label");

            //Adding the popup
			let waypointDoc = _.find(self.routeCollection.waypoints,['_id',point.waypoint]);
            let popup = L.popup({className: 'route-detail-popup', autoPan: false, closeButton: false}).setContent(self.$sanitize(waypointDoc.name));
            waypoint.bindPopup(popup);
            self.genericMapService.setLayerPopupOptions(waypoint, 'hover');

            //TODO: consider if there might be a better way
            waypointNumber++;
        });
    }

    fitMapToWaypoints() {
        let self = this;
        let loc = [];

        _.forEach(self.route.path, (point) => {
            loc.push(_.find(self.routeCollection.waypoints,['_id',point.waypoint]).loc);
        });

        self.genericMapService.fitMapToLoc(self.map, loc);
    }

    drawDistanceLines() {
        let self = this;
        let loc = [];

        let prevPointLoc = 0;
        _.forEach(self.route.path, (point) => {
            loc.push(_.find(self.routeCollection.waypoints,['_id',point.waypoint]).loc);
        });

        let polyline = self.genericMapService.drawPolylineFromLoc(self.map, loc);
        self.genericMapService.addClassToLayer(polyline._path, 'route-detail-distance-line');
    }

    /**
     * In case this route is bound to a site, we would like to draw that site
     */
    drawSite(site) {
        let self = this;
        let siteID = site._id;
        let primaryIsDrawn = false;
        // self.layers[siteID] = {layers: {}, marker: {}, circles: {}};

        _.forEach(site.locs, (shape) => {

            if(shape.loc.length !== 0) {
                if(shape.loc.length === 1) {
                    //If the site only has one location, therefore it is a ..dot.
                    //This won't be able to happen and I should remove this
                    //TODO: The above comment
                    // self.placeMarkerAtCoordinates(shape.loc[0], site);
                } else {
                    let polygon = self.drawPolygonFromBounds(shape.loc, site, shape.color, shape.weight);

                    //Only giving the center-marker to the primary shape
                    if(!primaryIsDrawn) {
                        // let center = polygon.getBounds().getCenter();
                        // self.layers[siteID].marker = L.marker(center, {icon: self.mapMarker}).addTo(self.allSitesMarkers);
                        // let centerMarker = self.layers[siteID].marker;
                        // let popup = L.popup().setContent(`<h4>${site.sitename}</h4>`);
                        // centerMarker.bindPopup(popup);

                        primaryIsDrawn = true;
                    }
                }
            }
        });

        //adding the notes/labels
        // _.forEach(site.notes, (note) => {
        //     let marker = L.marker(note.loc, {opacity: 0, draggable: false, icon: self.mapMarker});
        //     marker.bindTooltip(note.content, {direction: 'center', permanent: true, className: 'site-data-label', offset: [10,-5]}).addTo(self.allSitesLayer);
        //     //Have to put this in a timeout, otherwise when Leaflet tries to add the class, it gets a classlist of undefined
        //     self.$timeout(() => {
        //         L.DomUtil.addClass(marker._icon, 'leaflet-note-class');
        //     });
        // });
    }

    /**
     * This function is being called from @see drawSite()
     * when it receives a site to draw
     */
    drawPolygonFromBounds(bounds, site, color, weight) {
        let self = this;
        let id = site._id;
        let drawingColor = color ? color : '#2c09DE';
        let drawingWeight = weight ? weight : 6;
        let polygon = L.polygon(bounds, {color: drawingColor, weight: drawingWeight}).addTo(self.map);
		let name = self.$sanitize(site.sitename);
        let popup = L.popup().setContent(`<h4>${name}</h4>`);
        polygon.bindPopup(popup);
        return polygon;
    }

    doLog() {
		console.debug(this);
    }


}

export default angular.module('secutraqApp.routes')
.component('routeDetails', {
    template: require('./route-detail.html'),
    controller: RouteDetailComponent,
    controllerAs: "$ctrl",
    bindings: {
        routeCollection: '<',
        route: '<',
        linkedToSite: '<',
        siteID: '<'
    }
});
