// @flow

type User = {
	name: string;
	email: string;
	password: string;
};

export class LoginComponent {
	user: User = {
		name: '',
		email: '',
		password: ''
	};
	errors = {
		login: undefined
	};
	submitted = false;
	Auth;
	$state;
	$location;
	$window;
	$log;
	$rootScope;
	hostnameIcon;
	user;
	errors;
	submitted;

	//login : Function;


	/*@ngInject*/
	constructor($rootScope, $scope, Auth, $state, $location, $window, $log, $http, $timeout, socket, $transitions, $uibModal) {
		this.$http = $http;
		this.$rootScope = $rootScope;
        this.$scope = $scope;
		this.Auth = Auth;
		this.$state = $state;
		this.$location = $location;
		this.$window = $window;
		this.$timeout = $timeout;
		this.socket = socket;
		this.$transitions = $transitions;
        this.azureBusy = false;
		this.$uibModal = $uibModal;
	}

	$onInit() {
		this.hostnameIcon = null;
		this.errors = {};
		this.Auth.logout();

		this.$rootScope.$on('azureError', (event, err) => {
			this.errors.login = `${err.statusText} - ${err.data.message}`;
		});
		this.user = {
			rememberme: true
		};

		this.$http.get(`assets/images/domainicons/${this.$window.location.hostname}.png`)
			.then(data => {
				this.hostnameIcon = `assets/images/domainicons/${this.$window.location.hostname}.png`;
			})
.catch( (err) => {
				this.hostnameIcon = null;
			} );
        let hash = this.$window.location.hash.slice(1).split("&");

        if(hash) {
            let postBody = {};
            let doPost = false;
            hash.forEach(param => {
                let [key,value] = param.split("=");
                if(key == "id_token") {
                    key = "access_token";
                    doPost = true;
	}
                postBody[key] = value;
            });

            if(doPost) {
                this.azureBusy = true;
                return this.$http.post("/auth/azureAD/openid/returnSPA", postBody).then(response=> {
                    return response;
                })
                    .then(this.Auth.loginStep2())
                    .then(this.Auth.loginStep3())
                    .then(data => {
                        this.socket.reAuth();
                        if(this.Auth.redirectState) {
                            this.Auth.redirectToSavedState();
                        }else {
                            this.$state.go('main.dashboard', {
                                accountID: this.Auth.getCurrentAccountSync().ref
                            });
                        }
                    })
                    .catch(err => {
                        console.error(`Error during Azure AD login: ${err}`);
                        this.errors.login = `${err.statusText} - ${err.data.message}`;
                    });
            }
        }

    }

	clearSubmission() {
		this.submitted = false;
		this.errors.login = null;
	}



	login(form) {
		let self = this;
		this.submitted = true;

		if (form.$valid) {
			self.errors.login = null;
			this.Auth.login({
					email: this.user.email,
					password: this.user.password
				})
				.then((data) => {
						if(data && data.mfaRequired && data.mfa){
							let modalInstance = self.$uibModal.open({
								component: 'totp',
								backdrop: 'static',
								keyboard: false,
								resolve:{
									mfa:data.mfa,
									modal:true
								}
							});
						}else if(data){
							self.socket.reAuth();
							if(this.Auth.redirectState){
								this.Auth.redirectToSavedState();
							}else {
								this.$state.go('main.dashboard', {
									accountID: this.Auth.getCurrentAccountSync().ref
								});
							}
						}
					// Logged in, redirect to home
				})
				.catch(err => {
					console.error(`Error during login: ${err}`);
					this.errors.login = err.message;
				});
		}
	}

    loginAAD() {
        let self = this;
        return self.Auth.azureADLogin()
            .then(data => {
                self.azureBusy = true;
                self.socket.reAuth();
                if(this.Auth.redirectState) {
                    this.Auth.redirectToSavedState();
                }else {
                    this.$state.go('main.dashboard', {
                        accountID: this.Auth.getCurrentAccountSync().ref
                    });
                }
            })
            .catch(err => {
                if(err.substring(0,11) == "AADSTS50105") {
                    this.errors.login("Not authorized to use this application");
                }
            });
    }

	loginOauth(provider) {
		this.$window.location.href = `/auth/${provider}`;
	}
}

export default angular.module('secutraqApp.login')
	.component('login', {
		template: require('./login.html'),
		controller: LoginComponent,
		controllerAs: '$ctrl'
	})
	.name;
