export class MinimapModalComponent {
	/*@ngInject*/
    constructor(genericMapService, $timeout, mapService) {
        this.genericMapService = genericMapService;
        this.$timeout = $timeout;
        this.mapService = mapService;
    }

    $onInit() {
        let self = this;
		self.loc = self.resolve.loc;
		self.unitOnline = self.resolve.online;
		self.map = self.genericMapService.generateMap('lastLocationMinimap',{
			zoomControl:false,
			satellite : true,
			drawControl : false
		});


        self.onlineMarker = self.mapService.markerIcons.online;
        self.offlineMarker = self.mapService.markerIcons.offline;

        // self.genericMapService.getMapLayer().then((layer) => {
        //     self.layer = layer;
        //     self.layer.addTo(self.map);
        // });

        new L.Control.Zoom({position: 'bottomright'}).addTo(self.map);
        self.map.setView([-26.6145, 27.0950], 5);

        self.$timeout(() => {
            self.map.invalidateSize();
            self.genericMapService.setMapView(self.map, self.loc, 15);
            self.marker = self.genericMapService.placeMarkerAtCoordinates(self.map, self.unitOnline ? self.onlineMarker : self.offlineMarker, self.loc, {draggable: false});
        });

    }

    $onDestroy() {
        let self = this;
        self.map.remove();
    }

    doLog() {
		console.debug(this);
    }

}


export default angular.module('secutraqApp.units')
.component('minimapmodal', {
    template: require('./minimap-modal.html'),
    controller: MinimapModalComponent,
    controllerAs: '$ctrl',
    bindings: {
        resolve: '<'
    }
})
.name;
