import _ from 'lodash-es';

export class UserService {

    /*@ngInject*/
    constructor(moment, Restangular, socket, Auth) {
        this.moment = moment;
        this.Restangular = Restangular;
		this.users = [];
		this.listenerMap = new Map();
		this.socket = socket;
		this.Auth = Auth;
		this.cacheTimeout = undefined;
        this.lastAccount = undefined;
		this.joined = false;
    }

    //Copy
	onSocketEvent(event, item, array) {
		let self = this;
        self.users = array;
		self.listenerMap.forEach((value, key) => {
			setTimeout(() => {
value(event, item, array);
}, 0);
		});
	}

	registerListener(context, cb) {
		let self = this;
		if(!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if(self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
        if(!self.lastUser) {
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        } else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
            self.destroyService();
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        }
		let count = self.listenerMap.size;
		self.listenerMap.set(context, cb);
		// If listenermap was empty, (TODO and timer isn't running), join room, syncupdates
		if(!self.joined) {
			if(self.cacheTimeout) {
				clearTimeout(self.cacheTimeout);
				self.cacheTimeout = undefined;
			}
			return self.getUsers().then((users) => {
				self.socket.syncUpdates('user', self.users, self.onSocketEvent.bind(self), self);
				self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().ref}:*:users`);
				self.joined = true;
				return users;
			});
		} else {
			if(self.cacheTimeout) {
				clearTimeout(self.cacheTimeout);
				self.cacheTimeout = undefined;
			}
			return self.getUsers();
		}
	}

	destroyService() {
		let self = this;
		if(self.joined) {
			self.socket.unsyncUpdates('user', self);
			self.socket.leaveRoom(`${self.lastAccount}:*:users`);
		}
		self.joined = false;
		self.users = [];
		self.listenerMap.clear();
		clearTimeout(self.cacheTimeout);
		self.cacheTimeout = undefined;
	}

	unregisterListener(context, cb) {
		let self = this;
		if(!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if(self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
        if(!self.lastUser) {
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        } else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
            self.destroyService();
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        }
		self.listenerMap.delete(context);
		if(self.listenerMap.size === 0) {
			self.cacheTimeout = setTimeout(self.destroyService.bind(self), 5000);
		}
	}

	unregisterAll() {
		let self = this;
		if(!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if(self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
        if(!self.lastUser) {
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        } else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
            self.destroyService();
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        }
		self.listenerMap.clear();
		if(self.listenerMap.size === 0) {
			self.cacheTimeout = setTimeout(self.destroyService.bind(self), 5000);
		}
	}

    getUsers(query) {
		let self = this;
		if(self.users.length > 0 && !query) {
			return new Promise(function(resolve) {
				resolve(_.cloneDeep(self.users));
			});
		}
        let lastAccount = _.cloneDeep(self.lastAccount);
        let lastUser = _.cloneDeep(self.lastUser);
        return this.Restangular.all('users').getList(query)
        .then((users)=>{
            if(lastAccount == self.lastAccount && lastUser == self.lastUser) {
                self.users = this.Restangular.stripRestangular(users);
                return _.cloneDeep(users);
            }else {
                return [];
            }
        });
    }

    getUsersWithQuery(query) {
        return this.Restangular.all('users').customGET('',query);
    }

    //Custom (Change and keep if necessary)
    getUserById(userId) {
        let self = this;
        let result = _.find(self.users, (user) => {
            return user._id === userId;
        });
        if(result) {
            return _.cloneDeep(result);
        }else {
            return self.Restangular.one('users',userId).get();
        }
    }

    getUserByIdSync(userId) {
        let self = this;
        if(self.users && self.users.length > 0) {
            let index = _.findIndex(self.users,(storedUser) => {
                return storedUser._id === userId;
            });

            if(index !== -1) {
                return _.cloneDeep(self.users[index]);
            }else{
                return 'No such user!';
            }
        }else{
            return 'No such user!';
        }
    }

	resetPrivileges() {
		return this.Restangular.all('users').customPOST({},'resetPrivileges');
	}
}

export default angular.module('secutraqApp.dashboard')
.service('userService', UserService);
