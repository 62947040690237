export class SopsComponent {

    /*@ngInject*/
    constructor($scope, $timeout, $state, accountService, $stateParams, Auth) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.accountService= accountService;
        this.$stateParams = $stateParams;
        this.Auth = Auth;

    }

    $onInit() {
        let self = this;

        /**
         * [accounts description]
         * @type {Array}
         */
        self.accounts = [];

        /**
         * [currentAccount description]
         * @type {Object}
         */
        self.currentAccount = {};

		self.accountService.registerListener(self, (event, item, array) => {
            self.onReceiptOfAccount(item);
		}).then((accounts) => {
            _.forEach(accounts, (account) => {
                self.onReceiptOfAccount(account);
            });
		});

	}

	$onDestroy() {
		let self = this;
		self.accountService.unregisterListener(self);
	}

    onReceiptOfAccount(account) {
        let self = this;
        let currentAccountID = self.Auth.getCurrentAccountSync().ref;
        if(currentAccountID === account._id) {
            self.currentAccount = account;
        }

        let index = _.findIndex(self.accounts, (storedAccount) => {
            return storedAccount._id === account._id;
        });

        if(index !== -1) {
            self.accounts.splice(index, 1, account);
        } else {
            self.accounts.push(account);
        }
    }

    doLog() {
        console.debug(this);
    }


}
export default angular.module('secutraqApp.sops')
.component('sops', {
    template: require('./sops.html'),
    controller: SopsComponent,
    controllerAs: "$ctrl"
});
