import angular from 'angular';
import {Terminal} from 'xterm';
import {FitAddon} from 'xterm-addon-fit';

export class DMSTerminal{
	/*@ngInject*/
	constructor($uibModal, $anchorScroll, socket, unitService) {
		this.socket = socket;
		this.unitService = unitService;
		this.$uibModal = $uibModal;
		this.$anchorScroll = $anchorScroll;
	}

	$onInit() {
		let self = this;
		if(self.resolve && self.resolve.unit) {
			self.unit = self.resolve.unit;
		}
		self.terminal = new Terminal({
			cursorBlink: true,
			cursorStyle: 'block',
			scrollback: 100000,
			convertEol: true
		});
		const fitAddon = new FitAddon()
		self.terminal.loadAddon(fitAddon);
		self.terminal.open(document.getElementById('terminal'));
		fitAddon.fit();
		self.terminal.prompt = () => {
			self.terminal.write('\r\n$ ');
		};
		self.terminal.writeln('Please type in commands after the prompt. Responses will be displayed');
		self.terminal.prompt();
		let curr_line = '';
		self.terminal.onData(e => {
			switch(e) {
				case '\r':
				case '\u0003':
					self.unitService.requestDMSCommand(self.unit._id, curr_line);
					curr_line = '';
					self.terminal.prompt();
					break;
				case '\u007F':
					if(curr_line) {
						curr_line = curr_line.slice(0, curr_line.length - 1);
						self.terminal.write('\b \b');
					}
					break;
				default:
					curr_line += e;
					self.terminal.write(e);
					break;
			}
		});

		self.socket.joinRoom(`${self.unit.account.ref}:${self.unit._id}:dms:units`);
		self.socket.socket.on('terminalresponse', (item) => {
			self.terminal.writeln('');
			self.terminal.write(`\x1B[32m${item.response}\x1B[39m`);
			self.terminal.prompt();
			self.terminal.write(curr_line);
		});
	}

	$onDestroy() {
		let self = this;
		self.socket.leaveRoom(`${self.unit.account.ref}:${self.unit._id}:dms:units`);
	}

	closeModal() {
		let self = this;
        self.modalInstance.close();
	}
}

export default angular.module('directives.DMSTerminal', [])
	.component('dmsTerminal', {
		template: require('./dms-terminal.html'),
		controller: DMSTerminal,
		controllerAs: "$ctrl",
		bindings: {
			resolve: '<',
			modalInstance: '<',
		}
	})
	.name;
