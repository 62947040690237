import angular from 'angular';
import uiRouter from 'angular-ui-router';
import routes from './settings.routes';
import NgTable from 'ng-table/bundles/ng-table';
import ngSanitize from 'angular-sanitize';

// import NgTable from 'ng-table';

export default angular.module('secutraqApp.settings', [uiRouter, 'ngTable', 'ngSanitize', 'ngDragDrop'])
  .config(routes)
  .name;

require('./manage-azureAD/settings.azureAD.component');
require('./manage-azureAD/create-tenant/createtenant.modal.component');

require('./settings.user.component');
require('./settings.apikeys.component');
require('./settings.user.changepassword.component');
require('./settings.user.mfa.component');
require('./settings.account.component');
require('./add-account/account.modal.component');
require('./manage-users/settings.user.manageusers.component');
require('./manage-users/create-user/createuser.modal.component');
require('./manage-users/import-users/importusers.modal.component');
require('./manage-users/edit-privileges/editprivileges.modal.component');
require('./create-api-key/create-api-key.modal.component');
require('./app-version/app-version.component');
require('./app-version/app-version.modal.component');
require('./unit-version-management/unit-version-management.modal.component');
require('./custom-events/custom-event-list.component');
require('./custom-events/custom-event-edit/custom-event-edit.modal.component');
