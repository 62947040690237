export class AccountService {
	/*@ngInject*/
	constructor($uibModal, moment, Restangular, socket, Auth) {
		let self = this;
		this.$uibModal = $uibModal;

		this.moment = moment;
		this.Restangular = Restangular;
		this.listenerMap = new Map();
		this.socket = socket;
		this.Auth = Auth;
		this.cacheTimeout = undefined;
		this.lastAccount = undefined;
		this.lastUser = undefined;
		this.joined = false;

		this.accounts = [];

	}

	onSocketEvent(event, item, array) {
		let self = this;
		self.accounts = array;
		self.listenerMap.forEach((value, key) => {
			setTimeout(() => {
				value(event, item, array);
			}, 0);
		});
	}

	registerListener(context, cb) {
		let self = this;
		if (!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
		if(!self.lastUser) {
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		} else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
			self.destroyService();
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		}
		let count = self.listenerMap.size;
		self.listenerMap.set(context, cb);
		// If listenermap was empty, (TODO and timer isn't running), join room, syncupdates
		if (!self.joined) {
			if (self.cacheTimeout) {
				clearTimeout(self.cacheTimeout);
				self.cacheTimeout = undefined;
			}
			return self.getAccounts().then((accounts) => {
				self.socket.syncUpdates('account', self.accounts, self.onSocketEvent.bind(self), self);
				self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().ref}:accounts`);
				self.joined = true;
				return accounts;
			});
		} else {
			if(self.cacheTimeout) {
				clearTimeout(self.cacheTimeout);
				self.cacheTimeout = undefined;
			}
			return self.getAccounts();
		}
	}

	destroyService() {
		let self = this;
		if (self.joined) {
			self.socket.unsyncUpdates('account', self);
			self.socket.leaveRoom(`${self.lastAccount}:*:accounts`);
		}
		self.joined = false;
		self.accounts = [];
		self.listenerMap.clear();
		clearTimeout(self.cacheTimeout);
		self.cacheTimeout = undefined;
	}

	unregisterListener(context, cb) {
		let self = this;
		if (!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
		if(!self.lastUser) {
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		} else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
			self.destroyService();
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		}
		self.listenerMap.delete(context);
		if (self.listenerMap.size === 0) {
			self.cacheTimeout = setTimeout(self.destroyService.bind(self), 5000);
		}
	}

	unregisterAll() {
		let self = this;
		if (!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
		if(!self.lastUser) {
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		} else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
			self.destroyService();
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		}
		self.listenerMap.clear();
		if (self.listenerMap.size === 0) {
			self.cacheTimeout = setTimeout(self.destroyService.bind(self), 5000);
		}
	}

	getAccounts() {
		let self = this;
		if (self.accounts.length > 0) {
			return new Promise(function(resolve) {
				resolve(_.cloneDeep(self.accounts));
			});
		}
		return this.Restangular.all('accounts').getList()
			.then((accounts) => {
				self.accounts = accounts;
				return _.cloneDeep(accounts);
			});
	}


	manageSOPTags() {
		let self = this;
		let modalInstance = self.$uibModal.open({
			component: 'manageSOPTags',
			backdrop: 'static',
			keyboard: false,
			resolve: {
				account : self.getCurrentAccount()
			}
		});

		return modalInstance.result.then((result) => {
			let promise = new Promise((resolve, reject) => {
				resolve(result);
			});
			return promise;
		});

		return modalInstance.dismiss.then((reason) => {
			let promise = new Promise((resolve, reject) => {
				reject(reason);
			});
		});
	}

	createNewSOP() {
		let self = this;
		let modalInstance = self.$uibModal.open({
			component: 'newSOP',
			size: 'xlg',
			backdrop: 'static',
			keyboard: false
		});

		return modalInstance.result.then((result) => {
			let promise = new Promise((resolve, reject) => {
				resolve(result);
			});
			return promise;
		});

		return modalInstance.dismiss.then((reason) => {
			let promise = new Promise((resolve, reject) => {
				reject(reason);
			});
		});
	}

	editSOP(sop) {
		let self = this;
		let modalInstance = self.$uibModal.open({
			component: 'newSOP',
			size: 'xlg',
			backdrop: 'static',
			keyboard: false,
			resolve: {
				edit: true,
				sop
			}
		});

		return modalInstance.result.then((result) => {
			let promise = new Promise((resolve, reject) => {
				resolve(result);
			});
			return promise;
		});

		return modalInstance.dismiss.then((reason) => {
			let promise = new Promise((resolve, reject) => {
				reject(reason);
			});
		});
	}

	getCurrentAccountSOPs() {
		let self = this;
		let accountId = self.Auth.getCurrentAccountSync().ref;
		return self.getAccountByID(accountId).then((acc) => {
			return acc.sopTemplates;
		});
	}
	getCurrentAccount() {
		let self = this;
		let accountId = self.Auth.getCurrentAccountSync().ref;
		return self.getAccountByID(accountId);
	}

	getAccountByID(accountId) {
		let self = this;
		if(self.accounts && self.accounts.length > 0) {
			let index = _.findIndex(self.accounts,(storedAccount) => {
				return storedAccount._id === accountId;
			});

			if(index !== -1) {
				return Promise.resolve(_.cloneDeep(self.accounts[index]));
			}else{
				return Promise.reject('No such Account!');
			}
		}else{
			return self.Restangular.one('accounts',accountId).get()
.then((account) => {
				if(account) {
					return account;
				}else{
					return 'No such account!';
				}
			});
		}
	}

	updateAccount(account) {
		let self = this;
		return self.Restangular.one('accounts',account._id).customPATCH(account)
.then((response)=>{
			return response;
		});
	}

	createAccount(account) {
		let self = this;
		return self.Restangular.all('accounts').post(account)
.then((response)=>{
			return response;
		});
	}
	removeAccount(accountId) {
		let self = this;
		return self.Restangular.one('accounts',accountId).remove()
.then((response)=>{
			return response;
		});
	}
}

export default angular.module('secutraqApp.dashboard')
	.service('accountService', AccountService);
