'use strict';
/* eslint no-sync: 0 */

import angular from 'angular';
import uiSelect from 'ui-select';
const uiBootstrap = require('angular-ui-bootstrap');


export class NavbarComponent {
    menu =
    [{
        title: 'Home',
        state: 'main.testSmartAdmin'
    }];

    constructor(Auth, $location, $state, $rootScope) {
        'ngInject';
        this.Auth = Auth;
        this.isLoggedIn = Auth.isLoggedInSync;
        this.isAdmin = Auth.isAdminSync;
        this.getCurrentUser = Auth.getCurrentUserSync;
        this.getCurrentAccount = Auth.getCurrentAccountSync;
        this.setCurrentAccount = Auth.setCurrentAccount;
        this.hasRole = Auth.hasRoleSync;
        this.$location = $location;
        this.$state = $state;
        this.$rootScope = $rootScope;
    }

    $onInit() {
        let self = this;
        self.currentUser = self.getCurrentUser();
        self.currentAccount = self.Auth.getCurrentAccountSync();
        self.accountIcon = `assets/images/accounticons/${self.currentAccount.name}.png`;
        // TODO: Implement functionality that if no currentAccount.png exists, a next .png [see below] should be chosen
        // this.accountIcon = `assets/images/domainicons/${this.$window.location.hostname}.png`;

        this.$rootScope.$on('accountChanged', (event) => {
            self.currentAccount = this.getCurrentAccount();
            self.accountIcon = `assets/images/accounticons/${self.currentAccount.name}.png`;
        });

        this.navigationPanes = {
            dashboard: {
                title: 'Dashboard',
                sref: 'main.dashboard',
                icon: 'fa fa-2x fa-fw fa-fw fa-cog',
            },
            settings: {
                title: 'Settings',
                sref: 'main.settings',
                icon: 'fa fa-2x fa-fw fa-th-list',
				subViews:{
					customEvents:{
						privilege:'edit'
					},
					apiKeys:{
						privilege:'apiKeys'
					},
					appVersions:{
						privilege:'softwareUpdates'
					}
				}
			},
            reports: {
                title : 'Reports',
                sref: 'main.reports',
                icon : 'fa fa-2x fa-fw fa-history'
            },
            configure: {
                title: 'Configure',
                sref: 'main.configure',
                icon: 'fa fa-2x fa-fw fa-ellipsis-h',
            },
            contacts:{
                title: 'Contacts',
                sref: 'main.contacts',
                icon: 'fa fa-2x fa-fw fa-address-book'
            },
            photoAnalytics:{
                title: 'Photo Analytics',
                sref: 'main.photoAnalytics',
                icon: 'fa fa-2x fa-fw fa-address-book',
                privilege : 'faceRecall'
            },
            fieldReports:{
                title: 'Field Reports',
                sref: 'main.fieldReports',
                icon: 'fa fa-2x fa-fw fa-address-book',
                privilege : 'fieldReport'
            },
            singleViews: [
                // {
                //     title: 'Charts',
                //     sref: 'app.home',
                //     icon: 'fa fa-2x fa-fw fa-bar-chart'
                // },
                //
                // {
                //     title: 'Workforce',
                //     sref: 'app.home',
                //     icon: 'fa fa-2x fa-fw fa-users'
                // },
                //

                {
                    title: 'Events',
                    sref: 'main.events',
                    icon: 'fa fa-2x fa-fw fa-fw fa-stream',
                },
                {
                    title: 'Units',
                    sref: 'main.units.list',
                    icon: 'fa fa-2x fa-fw fa-list'
                },
                //
                // {
                //     title: 'Photo Collections',
                //     sref: 'main.imageCollection',
                //     icon: 'fa fa-2x fa fw fa-picture-o',
                //     privilege:'faceRecall'
                // },
                //
                // {
                //     title: 'Face Collections',
                //     sref: 'main.faceCollection',
                //     icon: 'fa fa-2x fa fw fa-cog',
                //     privilege:'faceRecall'
                // },

                {
                    title: 'Sites',
                    sref: 'main.sites',
                    icon: 'fas fa-2x fa-fw fa-map-marker-alt'
                },

                {
                    title: 'Routes',
                    sref: 'main.routes',
                    icon: 'fas fa-2x fa-fw fa-expand-arrows-alt'
                },

                {
                    title: `SOPs`,
                    sref: 'main.sops',
                    icon: 'fa fa-2x fa-fw fa-tasks',
                    privilege:'edit'
                },

                {
                    title: 'Scheduling',
                    sref: 'main.scheduling',
                    icon: 'far fa-2x fa-fw fa-calendar-alt'
                },
                {
                    title: 'Interactive Groups',
                    sref: 'main.groups',
                    icon: 'fa fa-2x fa-fw fa-users',
                    privilege:'notificationGroup'
                },
                {
                    title: 'Privilege Groups',
                    sref: 'main.privGroups',
                    icon: 'fa fa-2x fa-fw fa-users',
                },

            ],
        };

        this.currentPane = '';
        _.forEach(this.navigationPanes,(menu,key) => {
            if(key == 'singleViews') {
                menu.forEach((smenu) => {
                    smenu.hasPrivilege = self.testMenuPrivilege(smenu.privilege);
                });
            }else {
                menu.hasPrivilege = self.testMenuPrivilege(menu.privilege);
				if(menu.subViews) {
					_.forEach(menu.subViews, (subView) => {
						subView.hasPrivilege = self.testMenuPrivilege(subView.privilege);
					});
				}
            }
        });
    }

    navigateTo(state) {
        this.$state.go(state);
    }

    testMenuPrivilege(privilege) {
        if(privilege && privilege !== "main") {
            return this.hasRole(privilege);
        } else {
            return true;
        }
    }

}

export default angular.module('directives.navbar', [uiBootstrap, uiSelect])
.component('navbar', {
    template: require('./navbar.html'),
    controller: NavbarComponent,
    controllerAs: '$ctrl'
})
.name;
