export class RouteService {
	/*@ngInject*/
	constructor($uibModal, moment, Restangular, socket, Auth) {
		let self = this;
		this.$uibModal = $uibModal;

		/**
		 * A routeCollection is an array of {Objects} which represent a
		 * 'collection of routes'. If a user is defining multiple routes, he'll be
		 * defining all those routes linked to a certain site.
		 * The combination of a route + its site is what forms the routeCollection.
		 * Does this make sense?
		 * @type {Array}
		 */
		this.routeCollections = [];
		this.moment = moment;
		this.Restangular = Restangular;
		this.listenerMap = new Map();
		this.socket = socket;
		this.Auth = Auth;
		this.cacheTimeout = undefined;
		this.lastAccount = undefined;
		this.lastUser = undefined;
		this.joined = false;
		this.rooms = [];

		// self.generateRoute();


	}

	onSocketEvent(event, item, array) {
		let self = this;
		self.setLinkToSite(item);
		self.routes = array;
		self.listenerMap.forEach((value, key) => {
			setTimeout(() => {
				value(event, item, array);
			}, 0);
		});
	}

	registerListener(context, cb) {
		let self = this;
		if (!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
		if (!self.lastUser) {
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		} else if (self.lastUser !== self.Auth.getCurrentUserSync()._id) {
			self.destroyService();
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		}
		let count = self.listenerMap.size;
		self.listenerMap.set(context, cb);
		// If listenermap was empty, (TODO and timer isn't running), join room, syncupdates
		if (!self.joined) {
			if (self.cacheTimeout) {
				clearTimeout(self.cacheTimeout);
				self.cacheTimeout = undefined;
			}
			return self.getRoutes().then((routeCollections) => {
				// TODO : ensure that passing a cloned version(recieved from getRoutes) to syncUpdates works correctly.
				self.socket.syncUpdates('route', self.routeCollections, self.onSocketEvent.bind(self), self);
				let filter = {};
				let hasPrivilege = self.Auth.hasPrivilegeSync('route.index',undefined,filter);
				if(hasPrivilege && filter && filter.filterParam && filter.filterParam.field == 'groups') {
					filter.filterParam.data.forEach( (group) => {
						self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().ref}:*:routes:${group}`);
						self.rooms.push(`${self.Auth.getCurrentAccountSync().ref}:*:routes:${group}`);
					} );
				}else{
					self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().ref}:*:routes`);
						self.rooms.push(`${self.Auth.getCurrentAccountSync().ref}:*:routes`);
				}
				self.joined = true;
				return routeCollections;
			});
		} else {
			if(self.cacheTimeout) {
				clearTimeout(self.cacheTimeout);
				self.cacheTimeout = undefined;
			}
			return self.getRoutes();
		}
	}

	destroyService() {
		let self = this;
		if (self.joined) {
			self.socket.unsyncUpdates('route', self);
			if(self.rooms && self.rooms.length > 0) {
				self.rooms.forEach( (room) => {
					self.socket.leaveRoom(room);
				} );
				self.rooms = [];
			}
		}
		self.joined = false;
		self.routeCollections = [];
		self.listenerMap.clear();
		clearTimeout(self.cacheTimeout);
		self.cacheTimeout = undefined;
	}

	unregisterListener(context, cb) {
		let self = this;
		if (!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
		if (!self.lastUser) {
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		} else if (self.lastUser !== self.Auth.getCurrentUserSync()._id) {
			self.destroyService();
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		}
		self.listenerMap.delete(context);
		if (self.listenerMap.size === 0) {
			self.cacheTimeout = setTimeout(self.destroyService.bind(self), 5000);
		}
	}

	unregisterAll() {
		let self = this;
		if (!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
		if (!self.lastUser) {
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		} else if (self.lastUser !== self.Auth.getCurrentUserSync()._id) {
			self.destroyService();
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		}
		self.listenerMap.clear();
		if (self.listenerMap.size === 0) {
			self.cacheTimeout = setTimeout(self.destroyService.bind(self), 5000);
		}
	}

	getRoutes(query) {
		let self = this;
		if (self.routeCollections.length > 0 && !query) {
			return new Promise(function(resolve) {
				resolve(_.cloneDeep(self.routeCollections));
			});
		}
        let lastAccount = _.cloneDeep(self.lastAccount);
        let lastUser = _.cloneDeep(self.lastUser);
		return this.Restangular.all('routes').getList(query)
			.then((routeCollections) => {
				if(lastAccount == self.lastAccount && lastUser == self.lastUser) {
					routeCollections.forEach((route) => {
						self.setLinkToSite(route);
					});
					self.routeCollections = routeCollections;
					return _.cloneDeep(routeCollections);
				}else {
					return [];
				}
			});
	}

	getRoutesWithQuery(query) {
		let self = this;
		return this.Restangular.all('routes').getList(query)
			.then((routeCollections) => {
				routeCollections.forEach((route) => {
					self.setLinkToSite(route);
				});
				//NOTE : This should not happen
				//self.routeCollections = routeCollections;
				return _.cloneDeep(routeCollections);
			})
.catch((err) => {
				console.error(err);
			});
	}

	setLinkToSite(route) {
		if (route.site) {
			route.linkedToSite = true;
		} else {
			route.linkedToSite = false;
		}
	}

	hasRoutes() {
		let self = this;
		return new Promise((resolve, reject) => {
			if (self.routeCollections !== undefined) {
				resolve(true);
			} else {
				let promise = self.$interval(() => {
					if (self.routeCollections !== undefined) {
						resolve(true);
						self.$interval.cancel(promise);
					}
				}, 500, 10);

				promise.then((result) => {
					reject(`No routes have been given to RouteService yet`);
				});
			}
		});
	}

	// getRoutes() {
	// 	let self = this;
	// 	return self.hasRoutes()
	// 		.then((routes) => {
	// 			return new Promise((resolve, reject) => {
	// 				resolve(self.routeCollections);
	// 			});
	// 		})
	// 		.catch((error) => {
	// 			console.error("Site Service has not been given sites yet");
	// 		});
	// }

	createNewRoute() {
		let self = this;
		let modalInstance = self.$uibModal.open({
			component: 'newRoute',
			size: 'xlg',
			backdrop: 'static',
			keyboard: false
		});

		// TODO: We must notify the server of the new route
		return modalInstance.result.then((result) => {
			let promise = new Promise((resolve, reject) => {
				resolve(result);
			});
			return promise;
		});

		return modalInstance.dismiss.then((reason) => {
			let promise = new Promise((resolve, reject) => {
				reject(reason);
			});
		});
	}

	// TODO: We must notify the server of the edited route
	editRoute(routeDefinition, route) {
		let self = this;

		//Whilst editing we are saving to the original document, which we want to avoid
		let newRouteDefinition = _.cloneDeep(routeDefinition);
		let newRoute = _.cloneDeep(route);

		let routeToEdit = newRoute;
		let routes = newRouteDefinition.routes;
		let linkedToSite = !!newRouteDefinition.site;
		let waypoints = newRouteDefinition.waypoints;

		let modalInstance = self.$uibModal.open({
			component: 'newRoute',
			size: 'xlg',
			backdrop: 'static',
			keyboard: false,
			resolve: {
				edit: true,
				settings: {
					routeToEdit,
					routes,
					linkedToSite,
					waypoints,
					routeCollection: newRouteDefinition
				}
			}
		});

		return modalInstance.result.then((result) => {
			let promise = new Promise((resolve, reject) => {
				resolve(result);
			});
			return promise;
		});

		return modalInstance.dismiss.then((reason) => {
			let promise = new Promise((resolve, reject) => {
				reject(reason);
			});
		});
	}

	// TODO: We must notify the server of the removedRoute
	removeRoute(routeCollection, route) {
		let self = this;
		let tempRouteCollection = _.cloneDeep(routeCollection);
		let routeIndex = _.findIndex(tempRouteCollection.routes, (storedRoute) => {
			return storedRoute._id === route._id;
		});

		if (routeIndex !== -1) {
			tempRouteCollection.routes.splice(routeIndex, 1);
			if (tempRouteCollection.routes.length === 0) {
				self.removeRouteCollection(tempRouteCollection);
			} else {
				self.updateRouteCollection(tempRouteCollection);
			}
		}
	}

	findWaypoint(waypointID, routeCollection) {
		return _.find(routeCollection.waypoints, ['_id', waypointID]);
	}

	generateNumber(min, max) {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}

	saveNewRouteCollection(routeCollection) {
		let self = this;
		return self.Restangular.all('routes').post(routeCollection);
	}

	updateRouteCollection(routeCollection) {
		let self = this;
		if (routeCollection.boundedSite) {
			delete routeCollection.boundedSite;
		}

		return self.Restangular.one('routes', routeCollection._id).customPATCH(routeCollection);
	}

	removeRouteCollection(routeCollection) {
		let self = this;
		if (typeof routeCollection.remove === 'function') {
			return routeCollection.remove();

		} else {
			return self.Restangular.one('routes', routeCollection._id).remove();
		}
	}

	/**
	 * Here we simply generate a dummy route.
	 * NOTE that I'm only giving it the bare basics. The server will be giving us a fuller object
	 * @return {[type]} [description]
	 */
	generateRoute() {
		let self = this;
		let routeCollection = {
			boundedSite: {},
			routes: [],
			waypoints: []
		};
		routeCollection.linkedToSite = true;
		routeCollection.boundedSite.sitename = "Shemuel Milling Companies";
		routeCollection.boundedSite._id = 1;
		routeCollection._id = self.generateNumber(1, 10000);

		routeCollection.routes.push({
			_id: self.generateNumber(1, 10000),
			description: "The main route that guards of BridgeFour will take. This shift occurs on a daily basis",
			name: "BridgeFour - Arrival Route",
			path: []
		});
		routeCollection.routes[0].path.push({
			waypoint: {
				name: "Main Gate"
			}
		});
		routeCollection.routes[0].path.push({
			waypoint: {
				name: "Side exit"
			}
		});
		routeCollection.routes[0].path.push({
			waypoint: {
				name: "Factory Entrance"
			}
		});

		self.generateLocationForRoute(routeCollection.routes[0]);
		self.generateLayerEtcForRoute(routeCollection.routes[0]);

		self.routeCollections.push(routeCollection);
	}

	generateLocationForRoute(route) {
		let self = this;
		let idx = 0;
		let locations = [{
				lat: -28.89032391035545,
				lng: 31.44487734568075
			},
			{
				lat: -28.890508453392695,
				lng: 31.44787739237626
			},
			{
				lat: -28.891697952343986,
				lng: 31.448402100218633
			}
		];

		_.forEach(route.path, (point) => {
			point.waypoint.loc = locations[idx];
			idx++;
		});
	}

	generateLayerEtcForRoute(route) {
		let self = this;
		for (let i = 0; i < route.path.length; i++) {
			route.path[i].waypoint.layer = {};
			route.path[i].waypoint.layer.waypointNumber = i + 1;
		}
	}

	getSubRouteByIdSync(id) {
		let self = this;
		let collection = _.find(self.routeCollections, (routeCol) => {
			return _.find(routeCol.routes, (route) => {
				return route._id == id;
			});
		});
		if (collection) {
			let subRoute = _.find(collection.routes, (route) => {
				return route._id == id;
			});
			if(collection.site) {
				subRoute.site = collection.site;
			}
			return subRoute;
		} else {
			return "Route Not Found!!!!";
		}
	}

	getGroups() {
		let self = this;
		self.Groups = self.Restangular.all('routes').all('groups');
		return self.Groups.getList();
	}
}

export default angular.module('secutraqApp.dashboard')
	.service('routeService', RouteService);
