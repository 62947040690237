export class EditPrivilegesModalController {
	$stateParams;
	Restangular;
	$uibModalInstance;
	settings;
	contact;
	modalInstance;

	/*@ngInject*/
	constructor($stateParams, Restangular,toastr,$scope,Auth, appConfig, unitService, siteService, routeService) {
		this.$stateParams = $stateParams;
		this.Restangular = Restangular;
		this.toastr = toastr;
		this.valid = true;
		this.$scope = $scope;
		this.Auth = Auth;
		this.appConfig = appConfig;
		this.availablePrivileges = appConfig.roles;
		this.Contacts = this.Restangular.all('contacts');
		this.Groups = this.Contacts.all('groups');
		this.unitService = unitService;
		this.siteService = siteService;
		this.routeService = routeService;
	}

	$onInit() {
		let self = this;
		self.settings = self.resolve.settings;
		self.$uibModalInstance = self.modalInstance;

		self.user = self.settings.user;
		self.userAccount = _.find(self.user.accounts,(o) => {
			return o.ref == self.Auth.getCurrentAccountSync().ref;
		});
		self.currentUser = self.Auth.getCurrentUserSync();
		if(self.currentUser._id !== self.appConfig.adminUserId) {
			_.remove(self.availablePrivileges, (role) => {
				return role.adminOnly;
			});
		}
		self.defaultPrivileges = _.find(self.availablePrivileges,['key','default']);
		if(!self.userAccount.privileges) {
			self.userAccount.privileges = self.defaultPrivileges.privileges;
		}
		self.filterContent = false;
		if(self.userAccount.roles && self.userAccount.roles.filter && self.userAccount.roles.filter.length > 0) {
			self.filterContent = true;
		}
		self.filterRole = _.find(self.availablePrivileges,['key','filter']);
		self.changeFilterActive();
		self.Users = self.Restangular.all('users');
	}


	// Setup all the models for display
	// Modal accept: send resulting object back to promise in parent controller
	ok() {
		let self = this;
		// _.forEach(result.ids,(id)=>{
		// 	id.id = new Buffer.from(id.id,'hex');
		// })
		self.Restangular.one('users',self.user._id).customPATCH(self.userAccount.roles,'updatePrivileges')
.then((response)=>{
			self.$uibModalInstance.close(response);
			// self.toastr.error(`Contact create failed.`);
		})
.catch((err) => {
			console.error(err);
			self.toastr.error(`User update failed.`);
		});
	}

	doLog() {
		console.debug(this);
	}

	// Modal cancelled: either cancel pressed or clicked off screen
	cancel() {
		this.$uibModalInstance.dismiss('Cancel pressed');
	}

	refreshGroups() {
		let self = this;
		let allGroups = [];
		allGroups.push(self.siteService.getGroups());
		allGroups.push(self.unitService.getGroups());
		allGroups.push(self.routeService.getGroups());
		allGroups.push(self.Groups.getList());
		Promise.all(allGroups).then( (results) => {
			self.availableGroups = _.uniq(_.flatten(results));
		} )
.catch(err=>{
			console.error(err);
		});
	}

	changeFilterActive() {
		let self = this;
		if(self.filterContent) {
			self.filterRole.removeRoles.forEach( (removeRole) => {
				self.userAccount.roles[removeRole] = false;
			} );
		}else{
			self.userAccount.roles.filter = [];
		}

	}
}

export default angular.module('secutraqApp.settings')
	.component('editprivileges', {
		template: require('./editprivileges.modal.html'),
		bindings: {
			resolve: '<',
			close: '&',
			dismiss: '&',
			modalInstance: '<'
		},
		controller: EditPrivilegesModalController,
		controllerAs: '$ctrl'
	})
	.name;
// .controller(ContactModalController).name;
