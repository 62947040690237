'use strict';
var faIcons = require('./font-awsome-icon-list.json');
// @flow
export class CustomEventTypeComponent {
	/*@ngInject*/
	constructor(Auth, mapService) {
		this.Auth = Auth;
		this.mapService = mapService;
	}

	$onInit() {
		let self = this;
		if(self.resolve && self.resolve.edit) {

			self.customEventType = self.resolve.customEventType;
			// TODO: Remove # from color <13-04-20, Liaan> //
			// Convert RGB to hex
		}else{
			self.customEventType = {
				eventType: "",
				icon: "fas fa-clipboard-list",
				color: "#f5bf42"
			};
			if(self.resolve && self.resolve.initialEventType) {
				self.customEventType.eventType = self.resolve.initialEventType;
			}
			self.resolve.customEventType = self.customEventType;
		}
		if(/rgb/i.test(self.customEventType.color)) {
			self.customEventType.color = self.rgbToHex(self.customEventType.color);
		}
		self.tempColor = self.customEventType.color.replace("#","");

		self.colorPickerOptions = {
			required:true,
			placeholder:'Choose event display color',
			id:'eventColor',
			name:'eventColor',
			inputClass:'form-control inpud-md',
			format:'hex'
		};

		self.eventApi = {
			onChange:(api,color,$event) => {
				self.customEventType.color = `#${color}`;
			},
		};

		self.allIcons = _.map(faIcons, (icon,ref) => {
			icon.ref = ref;
			return icon;
		});
		//Remove brands, not sure about trademarking
		_.remove(self.allIcons, (icon) => {
			return icon.styles.includes("brands");
		});

		self.customIconSelected = false;
		if(self.customEventType.icon) {
			self.customIconSelected = true;
		}
		self.notificationSelected = false;
		if(self.customEventType.notification) {
			self.notificationSelected = true;
		}

		self.availableNotifications = [];
		for (var i = 0; i <= 13; i++) {
			self.availableNotifications.push({
				name: `Notification ${i+1}`,
				file: `assets/sounds/notifications/${i}.wav`
			});
		}
	}

	toggle() {
		let self = this;
		//self.customIconSelected != self.customIconSelected;
		if(self.customIconSelected) {
			self.customEventType.icon = "fas fa-clipboard-list";
		} else if(self.mapService.markerIcons && self.mapService.markerIcons[self.customEventType.eventType] && self.mapService.markerIcons[self.customEventType.eventType].options && self.mapService.markerIcons[self.customEventType.eventType].options.iconUrl) {
			//Only if marker available
			self.customEventType.icon = null;
		}
	}

	toggleNotification() {
		let self = this;
		//self.customIconSelected != self.customIconSelected;
		if(!self.notificationSelected) {
			self.customEventType.notification = null;
		}
	}

	doLog() {
		console.debug(this);
	}

	stopProp($event) {
		let self = this;
		$event.preventDefault();
		$event.stopPropagation();
	}

	rgbToHex(color) {
		let colors = color.match(/(\d+)/g);
		let r = +colors[0] || 0;
		let g = +colors[1] || 0;
		let b = +colors[2] || 0;

		function componentToHex(c) {
			c = Math.min(255,Math.max(0,c));
			var hex = c.toString(16);
			return hex.length == 1 ? `0${hex}` : hex;
		}
		return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
	}
}


export default angular.module('secutraqApp.sops')
	.component('customEventType', {
		template: require('./custom-event-type.html'),
		controller: CustomEventTypeComponent,
		controllerAs: '$ctrl',
		bindings: {
			resolve: '='
		}
	})
	.name;
