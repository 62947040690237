'use strict';
import uiBootstrap from 'angular-ui-bootstrap';
import Dropzone from 'dropzone';
import 'dropzone/dist/dropzone.css';
import 'ngdropzone';

Dropzone.autoDiscover = false;

export default class UploadController {
    $state;
    $http;
    Auth;
    $scope;
    $uibModal;
    $uibModalInstance;
    allTags;
    reference = {};
    currentAccount;
    buttonClass = '';


    /*@ngInject*/
    constructor($uibModalInstance, Auth, $http, $state, $scope, toastr, $uibModal) {
        this.$uibModalInstance = $uibModalInstance;
        this.$state = $state;
        this.toastr = toastr;
        this.Auth = Auth;
        this.hasPrivilege = Auth.hasPrivilegeSync;
        this.$http = $http;
        this.$scope = $scope;
        this.$uibModal = $uibModal;
    }

    doLog() {
		console.debug(this);
    }

    $onInit() {
        let self = this;
        self.currentAccount = self.Auth.getCurrentAccountSync().ref;

        self.dzCallbacks = {
            addedfile(file) {
            },
            maxfilesexceeded(file) {
                self.dzMethods.removeFile(file);
                self.toastr.warning('Only one photo can be uploaded at a time', {
                    preventOpenDuplicates: true
                });
            },
            sending(file, xhr, formData) {
                self.toastr.info('Uploading, Please Wait..', {
                    preventOpenDuplicates: true
                });
                self.buttonClass = 'disabled';
                if (self.reference.alias) {
                    formData.append('alias', self.reference.alias);
                }
                if (self.reference.description) {
                    formData.append('description', self.reference.description);
                }
                if (self.reference.tags) {
                    formData.append('tags', self.reference.tags);
                }
                if (self.reference.flag) {
                    formData.append('flag', self.reference.flag);
                }
            },
            success(test, res) {
                if (!res.exists) {
                    self.toastr.success('Succesfully added reference to database', {
                        preventOpenDuplicates: true
                    });
                } else {
                    self.toastr.warning('Person already exists in database', {
                        preventOpenDuplicates: true
                    });
                }
                self.$uibModalInstance.close({});
            }
        };
        self.dzOptions = {
            url: '/api/references/upload',
            maxFilesize: 1,
            maxFiles: 1,
            headers: {
                "X-JS-ACCOUNT": self.currentAccount
            },
            autoProcessQueue: false,
            acceptedFiles: 'image/jpeg, image/jpg',
            addRemoveLinks: true,
            dictDefaultMessage: 'Click to add photo (1 max)',
            dictRemoveFile: 'Remove Photo',
            dictResponseError: 'Could not upload this photo'
        };
        self.dzMethods = {};
        self.$http.get('api/references/findTags').then((response) => {
            self.allTags = response.data;
        });
    }
    $postLink() {
        let self = this;
        var dz = self.dzMethods.getDropzone();
    }

    upload() {
        let self = this;
        //if (self.hasPrivilege('secuvue.references.upload')) {
        if (true) {
            self.dzMethods.processQueue();
        } else {
            //self.toastr.error('You do not have privileges for this action', {
            //preventOpenDuplicates: true
            //});
        }
        self.$uibModalInstance.dismiss();
    }

    close() {
        let self = this;
        self.$uibModalInstance.dismiss();
    }

    removeTags(tags) {
        let label = " (create new tag)";
        tags.forEach((tag, index)=>{
            if (tag.slice(-label.length) === label) {
                tags[index] = tag.slice(0, -label.length);
            }
        });
    }
}
