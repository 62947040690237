'use strict';

import fabricJS from 'fabric';
import OptionsController from './options.controller';
import UploadController from './upload.controller';
import FilterController from './filter.controller';
import SnapshotModalController from '../imageCollection/modal.controller';
import LookupController from './lookup.controller';

export class PlateCollectionComponent {
    $http;
    $document;
    $state;
    moment;
    Auth;
    $uibModal;
    plateRefs;
    newUser;
    filter={};
    socket;
    tempFilter={};
    tempWanted={};
    tempsnaps={};
    lastFilter={};


    /*@ngInject*/
    constructor($http, $window, socket, $uibModal, NgTableParams, moment, $document, $state, Auth, $timeout) {
        this.$http = $http;
        this.moment = moment;
        this.socket = socket;
        this.$uibModal = $uibModal;
        this.$document = $document;
        this.$timeout = $timeout;
        this.$window = $window;
        this.$state = $state;
		this.NgTableParams = NgTableParams;
        this.Auth = Auth;
        this.custClass = 'hidden-panel';
    }

    doLog() {
		console.debug(this);
    }

    $onInit() {
        let self = this;
        if(self.$state.params.filter) {
            self.lastFilter.alias = self.$state.params.filter;
            self.applyFilter(self.lastFilter);
        }
        self.socket.socket.on('plate:save', function(item) {
			self.tableParams.reload();
        });
        self.socket.socket.on('plate:remove', function(item) {
			self.tableParams.reload();
        });

		self.selectedColumns = [];

		self.cols = [{
				title: "Alias",
				field: "alias",
				show: true,
			}, {
				title: "Plate",
				field: "plate",
				show: true,
				sortable: "plate",
			}, {
				title: "Province",
				field: "region",
				show: true,
			}, {
				title: "Last Seen Time",
				field: "lastActiveTime",
				show: true,
				sortable:'lastActiveTime',
			}, {
				title: "Tags",
				field: "tags",
				show:true,
			}, {
				title: "Last Seen Snapshot",
				field: "lastActiveSnap",
				show:true,
			}, {
				title: "Flagged",
				field: "flag",
				show:true,
				sortable:'flag',
			},
		];

		self.colValues = {};

		_.forEach(self.cols, (col) => {
			if(col.show) {
				self.selectedColumns.push(col.title);
			}
			self.colValues[col.field] = '';
		});

		self.tableParams = new self.NgTableParams({
			page: 1, // start with first page
			count: 10, // count per page
			sorting: {
				lastActiveTime: 'asc' // initial sorting
			}
		},
		{
			total: 0,
			getData(params) {
				let order;
				if (params && params.sorting) {
					order = params.sorting();
					return self.getPlatesTable(params).then( (response) => {
						let data = response.data;
						self.plateRefs = data.data;
						self.total = data.total;
						params.total(data.total);
						// TODO: We need to call formatData on each unit and return only units
						return self.plateRefs;

					} );
				}
			}
		});

		this.tableParams.reload();
	}

    getPlatesTable(params) {
		let self = this;
		let order = params.sorting();
		return self.$http.get('/api/plates/', {params: {
			filter: self.filter.filter ? self.filter.filter : undefined,
		   	alias: self.filter.alias,
		   	tags: self.filter.tags,
		   	flag: self.filter.flag,
		   	province: self.filter.province,
		   	description: self.filter.description ,
			skip: (params.page() - 1) * params.count(),
			// accountID: self.$stateParams.accountID,
			limit: params.count(),
			by: Object.keys(order)[0],
			order: order[Object.keys(order)[0]],
		}})
		.then(response => {
            response.data.data.forEach(plate => {
				if(plate.lastActiveSnap) {
					let lastSnap = plate.lastActiveSnap.id;
					self.$http({url:lastSnap.data,method:"GET",responseType:'arraybuffer'}).then( (res) => {
						let imageB64 = self.arrayBufferToBase64(res.data);
						let dataUrl = `data:image/jpg;base64,${imageB64}`;

						return dataUrl;
					} )
.then( (dataUrl) => {
						let image = new fabric.Image.fromURL(dataUrl, function(image) {
							image.setOptions({
								left: 0,
								top: 0,
								opacity: 1,
							});
							let snapPlate = _.find(lastSnap.lprResults, o => {
								return o.plate == plate.plate;
							});
							if(snapPlate) {
								let left;
								let top;
								let bottom;
								let right;
								snapPlate.boundingBox.forEach(point => {
									if(!left || point.x < left) {
										left = point.x;
									}
									if(!right || point.x > right) {
										right = point.x;
									}
									if(!bottom || point.y > bottom) {
										bottom = point.y;
									}
									if(!top || point.y < top) {
										top = point.y;
									}
								});
								left = left*image.width;
								right = right*image.width;
								top = top*image.height;
								bottom = bottom*image.height;
								plate.lastSeenCrop = image.toDataURL({left,top,width:right-left,height:bottom-top});
							}
						});
					});

				}
            });
			return response;
		});
    }

    applyFilter(filter) {
        let self = this;
        self.filter = _.clone(filter);
        //self.$http.get('/api/plates/refCount', {params: {alias: self.filter.alias, province: self.filter.province, tags: self.filter.tags, flag: self.filter.flag, description: self.filter.description}}).then(response => {
            //self.plateRefs = response.data;
        //});
        //self.plateAdapter.reload();
		this.tableParams.page(1);
		self.tableParams.reload();
    }

    update(id, value) {
        let self = this;
        self.$http.patch(`/api/plates/${id}`, _.pick(value, ['alias', 'plate', 'flag', 'description', 'tags', 'province', 'region'])).then(response => {
        });
    }

    reverseArray(array) {
        let tempArr = [];
        for(let i = array.length - 1; i >= 0; i--) {
            tempArr.push(array[i]);
        }
        return tempArr;
    }

    editPlate(plate) {
        let self = this;
        self.$uibModal.open({
            animation: true,
            backdrop: true,
            template: require('./options.html'),
            controller: OptionsController,
            controllerAs: '$ctrl',
            size: 'lg',
            resolve: {
                plate() {
                    return plate;
                },
            }
        }).result.then(result => {
            if(result.plate) {
                return self.update(result.plate._id, result.plate);
            } else if(result.deleting) {
                return self.deletePlate(result.deleting);
            } else if(result.snap) {
                return self.openSnap(result.snap);
            }
        }, function() {});
    }

    openSnap(snapshot) {
        let self = this;
        self.$uibModal.open({
            animation: true,
            backdrop: true,
            template: require('../imageCollection/modal.html'),
            controller: SnapshotModalController,
            controllerAs: '$ctrl',
            size: 'lg',
            resolve: {
                snapshot() {
                    return snapshot;
                },
                snapPlaceholder() {
                    return [];
                }
            }
        }).result.then(function(result) {
            if(result && result.newSnap) {
                self.openSnap(result.newSnap);
            }
        }, function() {});
    }

    deletePlate(plate) {
        let self = this;
        self.$http.delete(`/api/plates/${plate}`).then(response => {
			 //TODO:Remove   <31-05-19, yourname> //
			//self.plateRefs -= 1;
        });
    }

    lookup() {
        let self = this;
        self.$uibModal.open({
            animation: true,
            backdrop: true,
            template: require('./lookup.html'),
            controller: LookupController,
            controllerAs: '$ctrl',
            size: 'xlg',
            resolve: {

            }
        }).result.then(result => {
            if(result._id) {
                self.editPlate(result._id);
            }
        }, function() {});
    }

    uploadWanted() {
        let self = this;
        self.$uibModal.open({
            animation: true,
            backdrop: true,
            template: require('./upload.html'),
            controller: UploadController,
            controllerAs: '$ctrl',
            size: 'lg',
            resolve: {
            }
        }).result.then(result => {
        }, function() {});
    }

    clearFilter() {
        let self = this;
        self.lastFilter = {};
        self.applyFilter(self.lastFilter);
    }

    openFilter() {
        let self = this;
        self.$uibModal.open({
            animation: true,
            backdrop: true,
            template: require('./filter.html'),
            controller: FilterController,
            controllerAs: '$ctrl',
            size: 'lg',
            resolve: {lastFilter() {
                return self.lastFilter;
            }
            }
        }).result.then(result => {
            if(result.filter) {
                self.lastFilter = result.filter;
                return self.applyFilter(result.filter);
            }
        }, function() {});
    }

	arrayBufferToBase64(buffer) {
		var binary = '';
		var bytes = new Uint8Array(buffer);
		var len = bytes.byteLength;
		for (var i = 0; i < len; i++) {
			binary += String.fromCharCode(bytes[i]);
		}
		return window.btoa(binary);
	}
}

export default angular.module('secutraqApp.photoAnalytics')
    .component('plateCollection', {
        template: require('./plateCollection.html'),
        controller: PlateCollectionComponent,
        controllerAs: '$ctrl'
    })
    .filter('provinceFilter', function() {
        return function(input) {
            let provinceOptions = {
                "za-nl": "KwaZulu Natal",
                "za-gt": "Gauteng",
                "za-nw": "North West Province",
                "za-mp": "Mpumalanga",
                "za-lp": "Limpopo",
                "za-ec": "Eastern Cape",
                "za-wc": "Western Cape",
                "za-fs": "Free State",
                "za-nc": "Northern Cape"
            };
            if(provinceOptions.hasOwnProperty(input)) {
                return provinceOptions[input];
            } else {
                return input;
            }
        };
    }).name;
