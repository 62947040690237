'use strict';
import uiBootstrap from 'angular-ui-bootstrap';
import fabricJS from 'fabric';

//REGIONS:
//Natal - za-nl
//Gauteng - za-gt
//North West - za-nw
//Eastern Cape - za-ec
//Western Cape - za-wc
//Mpumalanga - za-mp
//Limpopo - za-lp
//Free State - za-fs
//North Cape - za-nc


export default class OptionsController {
    $state;
    $http;
    $scope;
    $uibModal;
    $uibModalInstance;
    plate;
    moment;
    more;
    canvas;
    canvasContext;
    toastr;
    otherSightings;
    Auth;
    allTags;
    /*@ngInject*/

    constructor($uibModalInstance, moment, Auth, toastr, $http, $state, $scope, $timeout, $uibModal, plate) {
        this.$uibModalInstance = $uibModalInstance;
        this.$state = $state;
        this.$timeout = $timeout;
        this.moment = moment;
        this.toastr = toastr;
        this.Auth = Auth;
        this.$http = $http;
        this.$scope = $scope;
        this.otherSightings = [];
        this.more = true;
        this.$uibModal = $uibModal;
        this.plate = plate;
        this.provinceOptions = [
            {
                province: "KwaZulu Natal",
                region: "za-nl"
            },
            {
                province: "Gauteng",
                region: "za-gt"
            },
            {
                province: "North West Province",
                region: "za-nw"
            },
            {
                province: "Mpumalanga",
                region: "za-mp"
            },
            {
                province: "Limpopo",
                region: "za-lp"
            },
            {
                province: "Eastern Cape",
                region: "za-ec"
            },
            {
                province: "Western Cape",
                region: "za-wc"
            },
            {
                province: "Free State",
                region: "za-fs"
            },
            {
                province: "Northern Cape",
                region: "za-nc"
            },
            {
                province: "South Africa",
                region: "za"
            },
        ];
    }

    doLog() {
		console.debug(this);
    }

    $onInit() {
        let self = this;
        self.$http.get('api/plates/findTags').then(res => {
            self.allTags = res.data;
            self.$timeout(function() {
                self.drawCanvas();
            },0);
        });
        //switch(self.plate.region) {
            //case "za-gt" :
                //self.plate.province = "Gauteng";
                //break;
            //case "za-wc" :
                //self.plate.province = "Western Cape";
                //break;
            //case "za-nw" :
                //self.plate.province = "North West Province";
                //break;
            //case "za-fs" :
                //self.plate.province = "Free State";
                //break;
            //case "za-mp" :
                //self.plate.province = "Mpumalanga";
                //break;
            //case "za-lp" :
                //self.plate.province = "Limpopo";
                //break;
            //case "za-nl" :
                //self.plate.province = "KwaZulu Natal";
                //break;
            //case "za-ec" :
                //self.plate.province = "Eastern Cape";
                //break;
            //case "za-nc" :
                //self.plate.province = "Northern Cape";
                //break;
        //}

        self.$http.get(`api/photos/snapPlates/${self.plate.plate}`, {
            params: {ts: +self.moment().utc()}
        }).then(response => {
            if(response.data.length > 0) {
                if(response.data.length < 4) {
                    self.more = false;
                }
                //
                self.otherSightings = response.data;
                return self.otherSightings;
            } else {
                self.more = false;
            }
        });
    }


    drawCanvas() {
        if(fabricJS.hasOwnProperty('fabric')) {
            let fabric = fabricJS.fabric;
        }
        let self = this;
        if(!self.canvas) {
            self.canvas = new fabric.Canvas('snapshotCanvas');
        }
        self.canvas.clear();
        self.canvas.containerClass = 'snapshot-wrapper';
		self.$http({url:self.plate.data,method:"GET",responseType:'arraybuffer'}).then( (res) => {
			let imageB64 = self.arrayBufferToBase64(res.data);
			let dataUrl = `data:image/jpg;base64,${imageB64}`;

			return dataUrl;
		} )
.then( (dataUrl) => {
			let image = new fabric.Image.fromURL(dataUrl, function(image) {
				image.setOptions({left: 0, top: 0, opacity: 1, width: self.plate.width, height: self.plate.height});
				self.canvas.setBackgroundImage(image, self.canvas.renderAll.bind(self.canvas), {
					originX: 'left',
					originY: 'top'
				});
				self.canvas.setWidth(image.width, {backstoreOnly: true});
				self.canvas.setHeight(image.height, {backstoreOnly: true});
				self.canvas.setWidth('100%', {cssOnly: true});
				self.canvas.setHeight('auto', {cssOnly: true});
				self.canvas.selection = false;

				let rect = new fabric.Rect({
					fill: 'rgba(0,0,0,0)',
					width: self.plate.boundingBox.Width * self.plate.width,
					height: self.plate.boundingBox.Height * self.plate.height,
					left: self.plate.boundingBox.Left * self.plate.width,
					top: self.plate.boundingBox.Top * self.plate.height,
					stroke: 'rgba(0,255,0, 0.5)',
					selectable: false,
					strokeWidth: 4
				});
				self.canvas.add(rect);
				rect.moveTo(30);
				rect.bringToFront();
				self.canvas.renderAll();
			});
        });
    }

    close() {
        let self = this;
        self.$uibModalInstance.dismiss();
    }

    openSnapshot(snap) {
        let self = this;
        self.$uibModalInstance.close({snap});
    }

    update() {
        let self = this;
        self.$uibModalInstance.close({plate: self.plate});
    }

    delete() {
        let self = this;
        let r = confirm("Are you sure you want to delete?");
        if(r == true) {
            self.$uibModalInstance.close({deleting: self.plate.plate});
        }
    }

    loadMore() {
        let self = this;
        self.$http.get(`api/photos/snapPlates/${self.plate.plate}`, {
            params: {
                ts: +self.otherSightings[self.otherSightings.length - 1].ts
            }
        }).then(response => {
            if(response.data.length > 0) {
                self.otherSightings = self.otherSightings.concat(response.data);
            }
            if(response.data.length < 4) {
                self.toastr.warning("There are no more photos to be loaded", {
                    preventOpenDuplicates: true
                });
                self.more = false;
            }
        });
    }

    removeTags(tags) {
        let label = " (create new tag)";
        tags.forEach((tag, index) => {
            if(tag.slice(-label.length) === label) {
                tags[index] = tag.slice(0, -label.length);
            }
        });
    }

	arrayBufferToBase64(buffer) {
		var binary = '';
		var bytes = new Uint8Array(buffer);
		var len = bytes.byteLength;
		for (var i = 0; i < len; i++) {
			binary += String.fromCharCode(bytes[i]);
		}
		return window.btoa(binary);
	}
}
