export class ApiKeyService {
    /*@ngInject*/
    constructor($interval, Restangular, moment, Auth, socket, toastr, $ngConfirm, unitService) {
        this.Restangular = Restangular;
        this.$interval = $interval;
		this.moment = moment;
		this.Restangular = Restangular;
		this.listenerMap = new Map();
		this.socket = socket;
		this.Auth = Auth;
		this.unitService = unitService;
		this.$ngConfirm = $ngConfirm;
		this.cacheTimeout = undefined;
		this.lastAccount = undefined;
        this.lastUnit = undefined;
		this.joined = false;
        this.toastr = toastr;
        this.apiKeys = [];
		this.rooms = [];
    }

    onSocketEvent(event, item, array) {
        let self = this;
        self.apiKeys = array;
        self.listenerMap.forEach((value, key) => {
            setTimeout(() => {
                value(event, item, array);
            }, 0);
        });
    }

    registerListener(context, cb) {
        let self = this;
        if (!self.lastAccount) {
            self.lastAccount = self.Auth.getCurrentAccountSync().ref;
        } else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
            self.destroyService();
            self.lastAccount = self.Auth.getCurrentAccountSync().ref;
        }
        if(!self.lastUser) {
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        } else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
            self.destroyService();
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        }
        let count = self.listenerMap.size;
        self.listenerMap.set(context, cb);
        // If listenermap was empty, (TODO and timer isn't running), join room, syncupdates
        if (!self.joined) {
            if (self.cacheTimeout) {
                clearTimeout(self.cacheTimeout);
                self.cacheTimeout = undefined;
            }
            return self.getApiKeys().then((apiKeys) => {
                self.socket.syncUpdates('apiKey', self.apiKeys, self.onSocketEvent.bind(self), self);
				let filter = {};
				let hasPrivilege = self.Auth.hasPrivilegeSync('event.index',undefined,filter);
				if(hasPrivilege && filter && filter.filterParam && filter.filterParam.field == 'groups') {
					filter.filterParam.data.forEach( (group) => {
						self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().ref}:*:apiKeys:${group}`);
						self.rooms.push(`${self.Auth.getCurrentAccountSync().ref}:*:apiKeys:${group}`);
					} );
				}else{
					self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().ref}:*:apiKeys`);
					self.rooms.push(`${self.Auth.getCurrentAccountSync().ref}:*:apiKeys`);
				}
                self.joined = true;
                return apiKeys;
            });
        } else {
			if(self.cacheTimeout) {
				clearTimeout(self.cacheTimeout);
				self.cacheTimeout = undefined;
			}
            return self.getApiKeys();
        }
    }

    destroyService() {
        let self = this;
        if (self.joined) {
            self.socket.unsyncUpdates('apiKey', self);
			if(self.rooms && self.rooms.length > 0) {
				self.rooms.forEach( (room) => {
					self.socket.leaveRoom(room);
				} );
				self.rooms = [];
			}
        }
        self.joined = false;
        self.apiKeys = [];
        self.listenerMap.clear();
        clearTimeout(self.cacheTimeout);
        self.cacheTimeout = undefined;
    }

    unregisterListener(context, cb) {
        let self = this;
        if (!self.lastAccount) {
            self.lastAccount = self.Auth.getCurrentAccountSync().ref;
        } else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
            self.destroyService();
            self.lastAccount = self.Auth.getCurrentAccountSync().ref;
        }
        if(!self.lastUser) {
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        } else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
            self.destroyService();
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        }
        self.listenerMap.delete(context);
        if (self.listenerMap.size === 0) {
            self.cacheTimeout = setTimeout(self.destroyService.bind(self), 5000);
        }
    }

    unregisterAll() {
        let self = this;
        if (!self.lastAccount) {
            self.lastAccount = self.Auth.getCurrentAccountSync().ref;
        } else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
            self.destroyService();
            self.lastAccount = self.Auth.getCurrentAccountSync().ref;
        }
        if(!self.lastUser) {
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        } else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
            self.destroyService();
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        }
        self.listenerMap.clear();
        if (self.listenerMap.size === 0) {
            self.cacheTimeout = setTimeout(self.destroyService.bind(self), 5000);
        }
    }

    getApiKeys(query) {
        let self = this;
        if (self.apiKeys.length > 0) {
            return new Promise(function(resolve) {
                resolve(_.cloneDeep(self.apiKeys));
            });
        }
        let lastAccount = _.cloneDeep(self.lastAccount);
        let lastUser = _.cloneDeep(self.lastUser);
        return this.Restangular.all('apiKeys').getList(query)
            .then((apiKeys) => {
                if(lastAccount == self.lastAccount && lastUser == self.lastUser) {
                    self.apiKeys = apiKeys;
                    return _.cloneDeep(apiKeys);
                }else {
                    return [];
                }
            });
    }

    getApiKeysWithQuery(query) {
        let self = this;
        return this.Restangular.all('apiKeys').getList(query)
        .then((response) => {
            return _.cloneDeep(response);
        })
.catch((err) => {
            console.error(err);
        });
    }

    setApiKeys(apiKeys) {
        let self = this;
        self.apiKeys = apiKeys;
    }

    hasApiKeys() {
        let self = this;
        return new Promise((resolve, reject) => {
            if(self.apiKeys !== undefined) {
                resolve(true);
            } else {
                let promise = self.$interval(() => {
                    if(self.apiKeys !== undefined) {
                        resolve(true);
                        self.$interval.cancel(promise);
                    }
                }, 500, 10);

                promise.then((result) => {
                    reject(`No apiKeys have been given to apiKeyService yet`);
                });
            }
        });
    }

    getApiKeyByIDSync(apiKeyID) {
        let self = this;
        let apiKey = _.find(self.apiKeys, (apiKey) => {
            return apiKey._id === apiKeyID;
        });
        return apiKey;
    }

    saveNewApiKey(apiKey) {
        let self = this;
        return self.Restangular.all('apiKeys').post(apiKey);
	}

    updateApiKey(apiKey) {
        let self = this;
        return self.Restangular.one('apiKeys',apiKey._id).customPATCH(apiKey);
	}

    removeApiKey(apiKey) {
        let self = this;
        if(typeof apiKey.remove === 'function') {
            apiKey.remove().then((response) => {
                self.toastr.info('apiKey removed.');
            })
.catch((err) => {
                console.error(err);
                self.toastr.error('apiKey remove failed.');
            });

        }else{
            self.Restangular.one('apiKeys',apiKey._id).remove()
.then((response)=>{
                self.toastr.info('apiKey removed.');
            })
.catch((err) => {
                console.error(err);
                self.toastr.error('apiKey remove failed.');
            });

        }
    }


    getApiKeyByID(apiKeyId) {
        let self = this;
        if(self.apiKeys && self.apiKeys.length > 0) {
            let index = _.findIndex(self.apiKeys,(storedapiKey) => {
                return storedapiKey._id === apiKeyId;
            });

            if(index !== -1) {
                return Promise.resolve(_.cloneDeep(self.apiKeys[index]));
            }else{
                return Promise.resolve('No such apiKey!');
            }
        }else{
            return self.Restangular.one('apiKeys',apiKeyId).get()
.then((apiKey) => {
                if(apiKey) {
                    return apiKey;
                }else{
                    return 'No such apiKey!';
                }
            });
        }
    }

    removeAccountFromApiKey(apiKey, account) {
        return this.Restangular.one('apiKeys',apiKey._id).customPATCH({accounts:[account.ref]},'removeAccounts');
    }
    addAccountsToApi(apiKey, accounts) {
        return this.Restangular.one('apiKeys',apiKey._id).customPATCH({accounts},'addAccounts');
    }
}

export default angular.module('secutraqApp.dashboard')
.service('apiKeyService', ApiKeyService);
