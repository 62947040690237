import angular from 'angular';
import uiRouter from 'angular-ui-router';
import uiBootstrap from 'angular-ui-bootstrap';

import uiSelect from 'ui-select';
import Restangular from 'restangular';
import NgTable from 'ng-table/bundles/ng-table'; // TODO figure out why this is needed
// import NgTable from 'ng-table';

import routes from './scheduling.routes';

export default angular.module('secutraqApp.scheduling',
    [uiRouter, uiBootstrap, Restangular, 'ngTable', uiSelect])
.config(routes)
.name;
require('./scheduling.component');
require('./vis-schedule-timeline/vis-schedule-timeline.component');
require('./add-schedule/scheduling.modal.component');
require('./slot-definition-modal/slot-definition.modal.component');
require('./ui-calendar/ui-calendar.component');
require('./schedule-list/schedule-list.component');
