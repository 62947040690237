export default function($stateProvider) {
    'ngInject';
    $stateProvider
    .state('main.routes', {
        // abstract: true,
        url: '/routes',
        views: {
            'content@main': {
                template: '<route></route>'
            }
        },
        authenticate: true,
        data: {
            title: 'Route'
        }
    })

    // .state('main.configure.route.list', {
    //     url: '',
    //     views: {
    //         'route-list': {
    //             template: '<list-routes></list-routes>'
    //         }
    //     },
    //     authenticate: true,
    //     data: {
    //         title: 'List'
    //     }
    // })
    //
    // .state('main.configure.route.detail', {
    //     url: '/:routeID',
    //     views: {
    //         'route-detail': {
    //             template: '<route-detail></route-detail'
    //         }
    //     },
    //     authenticate: true,
    //     data: {
    //         title: 'Detail'
    //     }
    // })

}
