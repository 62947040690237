import _ from 'lodash-es';

export class AppVersionService {

    /*@ngInject*/
    constructor(moment, Restangular, socket, Auth, $ngConfirm) {
        this.moment = moment;
        this.$ngConfirm = $ngConfirm;
        this.Restangular = Restangular;
		this.appVersions = [];
		this.listenerMap = new Map();
		this.socket = socket;
		this.Auth = Auth;
		this.cacheTimeout = undefined;
        this.lastAccount = undefined;
		this.lastAppVersion = undefined;
		this.joined = false;
		this.rooms = [];
		this.throttledFunctions = {};
		this.throttledFunctionCalled = {};
    }

    //Copy
	onSocketEvent(event, item, array) {
		let self = this;
        self.appVersions = array;
		self.listenerMap.forEach((value, key) => {
			setTimeout(() => {
value(event, item, array);
}, 0);
		});
	}

	registerListener(context, cb) {
		let self = this;
		if(!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if(self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
        if(!self.lastUser) {
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        } else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
            self.destroyService();
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        }
		let count = self.listenerMap.size;
		self.listenerMap.set(context, cb);
		// If listenermap was empty, (TODO and timer isn't running), join room, syncupdates
		if(!self.joined) {
			if(self.cacheTimeout) {
				clearTimeout(self.cacheTimeout);
				self.cacheTimeout = undefined;
			}
			return self.getAppVersions().then((appVersions) => {
				self.socket.syncUpdates('appVersion', self.appVersions, self.onSocketEvent.bind(self), self);
				let filter = {};
				// TODO: Add user privileges  <20-02-20, Liaan> //
				self.socket.joinRoom(`*:appVersions`);
				self.rooms.push(`*:appVersions`);
				self.joined = true;
				return appVersions;
			});
		} else {
			if(self.cacheTimeout) {
				clearTimeout(self.cacheTimeout);
				self.cacheTimeout = undefined;
			}
			return self.getAppVersions();
		}
	}

	destroyService() {
		let self = this;
		if(self.joined) {
			self.socket.unsyncUpdates('appVersion', self);
			if(self.rooms && self.rooms.length > 0) {
 self.rooms.forEach( (room) => {
					self.socket.leaveRoom(room);
				} );
				self.rooms = [];
			}
		}
		self.joined = false;
		self.appVersions = [];
		self.listenerMap.clear();
		clearTimeout(self.cacheTimeout);
		self.cacheTimeout = undefined;
	}

	unregisterListener(context, noCache) {
		let self = this;
		if(!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if(self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
        if(!self.lastUser) {
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        } else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
            self.destroyService();
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        }
		self.listenerMap.delete(context);
		if(self.listenerMap.size === 0) {
            if(noCache) {
                self.destroyService();
            }else {
                self.cacheTimeout = setTimeout(self.destroyService.bind(self), 5000);
            }
		}
	}

	unregisterAll() {
		let self = this;
		if(!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if(self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
        if(!self.lastUser) {
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        } else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
            self.destroyService();
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        }
		self.listenerMap.clear();
		if(self.listenerMap.size === 0) {
			self.cacheTimeout = setTimeout(self.destroyService.bind(self), 5000);
		}
	}

    getAppVersions(query) {
		let self = this;
		if(self.appVersions.length > 0 && !query) {
			return new Promise(function(resolve) {
				resolve(_.cloneDeep(self.appVersions));
			});
		}
        let lastAccount = _.cloneDeep(self.lastAccount);
        let lastUser = _.cloneDeep(self.lastUser);
        return this.Restangular.all('appVersions').getList(query)
        .then((appVersions)=>{

            if(lastAccount == self.lastAccount && lastUser == self.lastUser) {
				if(!query) {
					self.appVersions = this.Restangular.stripRestangular(appVersions);
				}
                return _.cloneDeep(appVersions);
            }else {
                return [];
            }
        });
    }

    getAppVersionsWithQuery(query) {
        return this.Restangular.all('appVersions').getList(query)
        .then((appVersions)=>{
            return appVersions;
        });
    }

    //Custom (Change and keep if necessary)
    getAppVersionByID(appVersionID, select) {
        let self = this;
        let result = _.find(self.appVersions, (appVersion) => {
            return appVersion._id === appVersionID;
        });
        if(result) {
            if(select && select.lenght > 0) {
                result = _.pick(result,select);
            }
            return Promise.resolve(_.cloneDeep(result));
        }else {
            return self.Restangular.one('appVersions', appVersionID).get({select});
        }
    }

    getAppVersionByIDSync(appVersionId) {
        let self = this;
        if(self.appVersions && self.appVersions.length > 0) {
            let index = _.findIndex(self.appVersions,(storedappVersion) => {
                return storedappVersion._id === appVersionId;
            });

            if(index !== -1) {
                return _.cloneDeep(self.appVersions[index]);
            }else{
                return 'No such appVersion!';
            }
        }else{
            return 'No such appVersion!';
        }
    }

	createAppVersion(appVersion) {
		let self = this;
		return self.Restangular.all('appVersions').post(appVersion)
.then((response)=>{
			return response;
		});
	}

    updateAppVersion(appVersion) {
        let self = this;
        return self.Restangular.one('appVersions',appVersion._id).customPATCH(appVersion);
    }

}

export default angular.module('secutraqApp.dashboard')
.service('appVersionService', AppVersionService);
