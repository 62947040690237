export class NewScheduledReportModalComponent {
	Restangular;
	$stateParams;
	$uibModalInstance;
	settings;
	Contacts;
	availableContacts;
	modalInstance;

	/*@ngInject*/
	constructor(Restangular, $stateParams, Auth, reportService, scheduledReportService, moment) {
		this.Auth = Auth;
		this.Restangular = Restangular;
		this.$stateParams = $stateParams;
		this.reportService = reportService;
		this.scheduledReportService = scheduledReportService;
		this.moment = moment;
	}

	$onInit() {
		let self = this;
		this.$stateParams.accountID = this.Auth.getCurrentAccountSync().ref;
		this.$uibModalInstance = this.modalInstance;
		this.settings = this.resolve.settings;
		this.Contacts = this.Restangular.all('contacts');
		this.availableContacts = [];
		this.availableFreq = ['Daily','Weekly','Monthly'];
		this.availableOn = [];
		this.availableRanges = ['Day', 'Week', 'Month'];

		self.refreshContacts();
		self.refreshReportTemplates();

		// Add alert data to controller
		if (this.settings.item) {
			self.scheduledReport = self.settings.item;
		}else {
			self.scheduledReport = {
				range : 'Week',
				repeat : {
					every : 'Weekly',
					on : 'Monday',
					at : 13
				}
			};
		}
		self.refreshAvailableOn();

	}

	// Modal accept: send resulting object back to promise in parent controller
	ok() {
		let self = this;
		let scheduledReport = _.cloneDeep(self.scheduledReport);
		scheduledReport.reports = _.map(scheduledReport.reports, '_id');
		scheduledReport.contacts = _.map(scheduledReport.contacts, '_id');
		scheduledReport.repeat.at = scheduledReport.repeat.at;
		if(scheduledReport.active == undefined) {
			scheduledReport.active = true;
		}
		if(self.settings.edit) {
			self.scheduledReportService.updateScheduledReport(scheduledReport).then((result) => {
				self.$uibModalInstance.close(result);
			});
		}else {
			self.scheduledReportService.saveScheduledReport(scheduledReport).then((result) => {
				self.$uibModalInstance.close(result);
			});
		}
		// this.$uibModalInstance.close()
	}

	// Modal cancelled: either cancel pressed or clicked off screen
	cancel() {
		this.$uibModalInstance.dismiss('Cancel pressed');
	}


	refreshAvailableOn() {
		let self = this;
		if(self.scheduledReport && self.scheduledReport.repeat && self.scheduledReport.repeat.every) {
			switch (self.scheduledReport.repeat.every) {
				case 'Daily':
					self.availableOn = [];
					self.scheduledReport.repeat.on = undefined;
					break;
				case 'Weekly':
					self.availableOn = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
					self.scheduledReport.repeat.on = 'Monday';
					break;
				case 'Monthly':
					self.availableOn = ['First','Last'];
					self.scheduledReport.repeat.on = 'First';
					break;
				default:
			}
		}else {
			self.availableOn = [];
		}
	}

	refreshContacts(search) {
		let self = this;
		this.Contacts.getList({
			filter:search,
			limit:20,
		}).then(function(contacts) {
			self.availableContacts = contacts;
		});
	}

	refreshReportTemplates(search) {
		let self = this;
		self.reportService.getReportsWithQuery({filter:search,limit:20}).then((reportTemplates) => {
			self.availableReportTemplates = reportTemplates;
		})
.catch((err) => {
			console.error(err);
		});
	}
}
export default angular.module('secutraqApp.reports')
	.component('newscheduledreport', {
		template: require('./new-scheduled-report.modal.html'),
		bindings: {
			resolve: '<',
			close: '&',
			dismiss: '&',
			modalInstance: '<',
			settings: '<'
		},
		controller: NewScheduledReportModalComponent,
		controllerAs: '$ctrl'
	})
	.directive('localHourParser', ['moment',(moment) => {
		return {
			restrict: 'A',
			require:'ngModel',
			link: (scope, element, attrs, modelCtrl) => {
				modelCtrl.$parsers.push(function(modelValue) {
					var utcHour = moment().hour(modelValue)
.utc()
.hour();
					return utcHour;
				});

				// when model value is updated
				modelCtrl.$formatters.push(function(modelValue) {
					var localHour = moment.utc().hour(modelValue)
.local()
.hour();
					return localHour;
				});
			}
		};
	}])
	.name;
