'use strict';

export default function($stateProvider) {
	'ngInject';
	$stateProvider
		.state('main.scheduling', {
			url: '/scheduling',
			views: {
				'content@main': {
					template: '<scheduling></scheduling>'
				}
			},
			authenticate: true,
			data: {
				title: 'Scheduling'
			},
		})
}
