export class CustomEventTypeService {
    /*@ngInject*/
    constructor($interval, Restangular, moment, Auth, socket, toastr) {
        this.Restangular = Restangular;
        this.$interval = $interval;
		this.moment = moment;
		this.listenerMap = new Map();
		this.socket = socket;
		this.Auth = Auth;
		this.cacheTimeout = undefined;
		this.lastAccount = undefined;
        this.lastUnit = undefined;
		this.joined = false;
        this.toastr = toastr;
        this.customEventTypes = [];
		this.rooms = [];
    }

    onSocketEvent(event, item, array) {
        let self = this;
        self.customEventTypes = array;
        self.listenerMap.forEach((value, key) => {
            setTimeout(() => {
                value(event, item, array);
            }, 0);
        });
    }

    registerListener(context, cb) {
        let self = this;
        if (!self.lastAccount) {
            self.lastAccount = self.Auth.getCurrentAccountSync().ref;
        } else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
            self.destroyService();
            self.lastAccount = self.Auth.getCurrentAccountSync().ref;
        }
        if(!self.lastUser) {
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        } else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
            self.destroyService();
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        }
        let count = self.listenerMap.size;
        self.listenerMap.set(context, cb);
        // If listenermap was empty, (TODO and timer isn't running), join room, syncupdates
        if (!self.joined) {
            if (self.cacheTimeout) {
                clearTimeout(self.cacheTimeout);
                self.cacheTimeout = undefined;
            }
            return self.getCustomEventTypes().then((customEventTypes) => {
                self.socket.syncUpdates('customEventType', self.customEventTypes, self.onSocketEvent.bind(self), self);
				let filter = {};
				let hasPrivilege = self.Auth.hasPrivilegeSync('event.index',undefined,filter);
				if(hasPrivilege && filter && filter.filterParam && filter.filterParam.field == 'groups') {
					filter.filterParam.data.forEach( (group) => {
						self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().ref}:*:customEventTypes:${group}`);
						self.rooms.push(`${self.Auth.getCurrentAccountSync().ref}:*:customEventTypes:${group}`);
					} );
				}else{
					self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().ref}:*:customEventTypes`);
					self.rooms.push(`${self.Auth.getCurrentAccountSync().ref}:*:customEventTypes`);
				}
                self.joined = true;
                return customEventTypes;
            });
        } else {
			if(self.cacheTimeout) {
				clearTimeout(self.cacheTimeout);
				self.cacheTimeout = undefined;
			}
            return self.getCustomEventTypes();
        }
    }

    destroyService() {
        let self = this;
        if (self.joined) {
            self.socket.unsyncUpdates('customEventType', self);
			if(self.rooms && self.rooms.length > 0) {
				self.rooms.forEach( (room) => {
					self.socket.leaveRoom(room);
				} );
				self.rooms = [];
			}
        }
        self.joined = false;
        self.customEventTypes = [];
        self.listenerMap.clear();
        clearTimeout(self.cacheTimeout);
        self.cacheTimeout = undefined;
    }

    unregisterListener(context, cb) {
        let self = this;
        if (!self.lastAccount) {
            self.lastAccount = self.Auth.getCurrentAccountSync().ref;
        } else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
            self.destroyService();
            self.lastAccount = self.Auth.getCurrentAccountSync().ref;
        }
        if(!self.lastUser) {
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        } else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
            self.destroyService();
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        }
        self.listenerMap.delete(context);
        if (self.listenerMap.size === 0) {
            self.cacheTimeout = setTimeout(self.destroyService.bind(self), 5000);
        }
    }

    unregisterAll() {
        let self = this;
        if (!self.lastAccount) {
            self.lastAccount = self.Auth.getCurrentAccountSync().ref;
        } else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
            self.destroyService();
            self.lastAccount = self.Auth.getCurrentAccountSync().ref;
        }
        if(!self.lastUser) {
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        } else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
            self.destroyService();
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        }
        self.listenerMap.clear();
        if (self.listenerMap.size === 0) {
            self.cacheTimeout = setTimeout(self.destroyService.bind(self), 5000);
        }
    }

    getCustomEventTypes(query) {
        let self = this;
        if (self.customEventTypes.length > 0) {
            return new Promise(function(resolve) {
                resolve(_.cloneDeep(self.customEventTypes));
            });
        }
        let lastAccount = _.cloneDeep(self.lastAccount);
        let lastUser = _.cloneDeep(self.lastUser);
        return this.Restangular.all('customEventTypes').getList(query)
            .then((customEventTypes) => {
                if(lastAccount == self.lastAccount && lastUser == self.lastUser) {
                    self.customEventTypes = customEventTypes;
                    return _.cloneDeep(customEventTypes);
                }else {
                    return [];
                }
            });
    }

    getCustomEventTypesWithQuery(query) {
        let self = this;
        return this.Restangular.all('customEventTypes').getList(query)
        .then((response) => {
            return _.cloneDeep(response);
        })
.catch((err) => {
            console.error(err);
        });
    }

    setCustomEventTypes(customEventTypes) {
        let self = this;
        self.customEventTypes = customEventTypes;
    }

    hasCustomEventTypes() {
        let self = this;
        return new Promise((resolve, reject) => {
            if(self.customEventTypes !== undefined) {
                resolve(true);
            } else {
                let promise = self.$interval(() => {
                    if(self.customEventTypes !== undefined) {
                        resolve(true);
                        self.$interval.cancel(promise);
                    }
                }, 500, 10);

                promise.then((result) => {
                    reject(`No customEventTypes have been given to customEventTypeService yet`);
                });
            }
        });
    }

    getCustomEventTypeByIDSync(customEventTypeID) {
        let self = this;
        let customEventType = _.find(self.customEventTypes, (customEventType) => {
            return customEventType._id === customEventTypeID;
        });
        return customEventType;
    }

    saveNewCustomEventType(customEventType) {
        let self = this;
        return self.Restangular.all('customEventTypes').post(customEventType);
	}

    updateCustomEventType(customEventType) {
        let self = this;
        return self.Restangular.one('customEventTypes',customEventType._id).customPATCH(customEventType);
	}

    removeCustomEventType(customEventType) {
        let self = this;
        if(typeof customEventType.remove === 'function') {
            customEventType.remove().then((response) => {
                self.toastr.info('customEventType removed.');
            })
.catch((err) => {
                console.error(err);
                self.toastr.error('customEventType remove failed.');
            });

        }else{
            self.Restangular.one('customEventTypes',customEventType._id).remove()
.then((response)=>{
                self.toastr.info('customEventType removed.');
            })
.catch((err) => {
                console.error(err);
                self.toastr.error('customEventType remove failed.');
            });

        }
    }


    getCustomEventTypeByID(customEventTypeId) {
        let self = this;
        if(self.customEventTypes && self.customEventTypes.length > 0) {
            let index = _.findIndex(self.customEventTypes,(storedcustomEventType) => {
                return storedcustomEventType._id === customEventTypeId;
            });

            if(index !== -1) {
                return Promise.resolve(_.cloneDeep(self.customEventTypes[index]));
            }else{
                return Promise.resolve('No such customEventType!');
            }
        }else{
            return self.Restangular.one('customEventTypes',customEventTypeId).get()
.then((customEventType) => {
                if(customEventType) {
                    return customEventType;
                }else{
                    return 'No such customEventType!';
                }
            });
        }
    }
}

export default angular.module('secutraqApp.dashboard')
.service('customEventTypeService', CustomEventTypeService);
