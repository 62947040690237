'use strict';
angular.module('secutraqApp.directives')
    .directive('appWizard', ['$document', '$timeout',
    function($document, $timeout) {
        return {
            restrict: 'A',
            link: function(scope, el, attrs) {


                //The CONTENT that is currently displayed
                let currentTab = el.find(".wizard .tab-content .tab-pane").filter('.active');
                //The <li> that is active
                let currentCircle = el.find('.wizard .nav-tabs li.active');
                //All the 'round-tabs that we click on'
                let stepCircle = el.find('.round-tab');
                //All the nextStep buttons
                let nextStep = el.find('.next-step');
                //All the prevStep buttons
                let prevStep = el.find('.prev-step');

                /**
                 * When I click one of the 'round-tabs', I wnat to navigate there
                 */
                stepCircle.on('click', ($event) => {
                    //We are clicking the round-tab, but we want this one's <li>
                    let spanParent = angular.element($event.currentTarget.parentElement.parentElement);
                    if(!spanParent.hasClass('disabled') && !spanParent.hasClass('should-wait') && currentTab !== undefined) {
                        //We unmark the content that is active
                        currentTab.removeClass('active');
                        //We unmark this content's respective <li>
                        currentCircle.removeClass('active');

                        //We make the clicked circle's <li> active
                        spanParent.addClass('active');
                        //We point currentCircle to spanParent
                        currentCircle = spanParent;

                        //IMPORTANT, the <li>'s > <a>  must have an ID'.
                        //IMPORTANT, the .tab-content>.tab-pane must have the same ID
                        //IMPORTANT, this is because if I click on a circle, I find its parents ID.
                        //IMPORTANT, This ID is now my link to the tab-content
                        let correspondingTabID = spanParent[0].firstElementChild.id;
                        //We now find the correspondingTab-content
                        let correspondingTab = el.find(correspondingTabID);
                        //We make the correspondingTab active
                        correspondingTab.addClass('active');
                        //We update our pointer
                        currentTab = correspondingTab;

                        //I'm having a problem with text being selected whenever
                        //I click a round-tab. I'm struggling to find the cause of this
                        //so I'm using this workaround for the time being
                        //TODO - Find the cause
                        if(window.getSelection) {
                            let text = window.getSelection().toString();
                            if(text !== '') {
                                window.getSelection().removeAllRanges();
                            }
                        }


                    }
                });

                /**
                 * Handles the behaviour when I click 'next'
                 */
                nextStep.on('click', ($event) => {
                    let activeEl = el.find('.wizard .nav-tabs li.active');
                    let nextEl = activeEl.next();
                    //the 'should-wait' class can be given to the HTML element from the component (see NewSiteComponent)
                    //This is how you have to go about if you want to 'prevent' a transition
                    if(!nextEl.hasClass('should-wait')) {
                        activeEl.removeClass('active');
                        nextTab(nextEl);
                    }
                });

                /**
                 * Handles the behaviour when I click 'prev'
                 */
                prevStep.on('click', ($event) => {
                    let activeEl = el.find('.wizard .nav-tabs li.active');
                    let prevEl = angular.element(activeEl[0].previousElementSibling);

                    //the 'should-wait' class can be given to the HTML element from the component (see NewSiteComponent)
                    //This is how you have to go about if you want to 'prevent' a transition
                    if(!prevEl.hasClass('should-wait')) {
                        activeEl.removeClass('active');
                        prevTab(prevEl);
                    }
                    // prevEl.addClass('active');
                });

                /**
                 * We change tabs by making giving the next tab the 'active' class
                 * and removing it from the current one
                 */
                function nextTab(elem) {
                    elem.removeClass('disabled');
                    elem.addClass('active');
                    currentCircle = elem;

                    let activeTab = el.find('.wizard .tab-content .tab-pane').filter('.active');
                    let nextTab = activeTab.next();
                    activeTab.removeClass('active');
                    nextTab.addClass('active');
                    currentTab = nextTab;
                }

                /**
                 * We navigate to the previous tab by making the prev tab 'active'
                 */
                function prevTab(elem) {
                    elem.addClass('active');
                    currentCircle = elem;

                    let activeTab = el.find('.wizard .tab-content .tab-pane').filter('.active');
                    let prevTab = angular.element(activeTab[0].previousElementSibling);
                    activeTab.removeClass('active');
                    prevTab.addClass('active');
                    currentTab = prevTab;
                }
            }
        };
    }]);
