import PhotoModalController from '../../photoAnalytics/imageCollection/modal.controller';

export class SelectedEventComponent {
// for infinite scroll on ngtable check this out
// http://4dev.tech/category/angularjs/ng-table/

    /*@ngInject*/
    constructor(NgTableParams, $uibModal,
        $scope, moment, $timeout, $filter,
        mapService, currentEventService, eventCarouselService,
        photoService, eventService, unitService, Restangular, fieldReportService,
        Auth, $ngConfirm, toastr, accountService, userService, siteService, liveStreamService) {

        this.photoService = photoService;
        this.$uibModal= $uibModal;
        this.NgTableParams = NgTableParams;
        this.$scope = $scope;
        this.moment = moment;
        this.$timeout = $timeout;
        this.$filter = $filter;
        this.mapService = mapService;
        this.currentEventService = currentEventService;
        this.eventCarouselService = eventCarouselService;
        this.eventService = eventService;
        this.unitService = unitService;
		this.liveStreamService = liveStreamService;
        this.Restangular = Restangular;
        this.events = [];
        this.Auth = Auth;
        this.$ngConfirm = $ngConfirm;
        this.toastr = toastr;
        this.accountService = accountService;
        this.userService = userService;
		this.siteService = siteService;
		this.fieldReportService = fieldReportService;
    }

    $onInit() {
        let self = this;
        self.currentEventMetaData;
        self.currentEvent;
        self.showMore = false;
        self.activeSecondaryTab = "Timeline";
		self.availableContacts = [];
		self.Contacts = self.Restangular.all('contacts');
		self.slickConfig = {
			methods:{},
			responsive:[
				{
					breakpoint: 1440,
					settings: {
						slidesToShow: 8,
						slidesToScroll: 4,
						dots: true
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 6,
						slidesToScroll: 4,
						dots: true
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 4,
						dots: true
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						dots: true
					}
				}
			]
        };
        self.availableUsers = [
            // {
            // 	firstname: 'Liaan',
            // 	lastname: 'Moolman',
            // 	email: 'liaanmoolman@gmail.com'
            // }, {
            // 	firstname: 'Piet',
            // 	lastname: 'Retief',
            // 	email: 'pr@gmail.com'
            // }
        ];


        //self.eventService.getEvents().then((events) => {
            //self.events = events;
        //});

        self.getAvailableUsers();
        /**
         * We are looking towards this object from within HTML to know whether our uib-tabset is active
         * or not. I've been forced ot use this strange approach because of issues with the rotation of the tabs
         * @see setCurrentSecondaryTab()
         * options are:
         *   i) Timeline
         *  ii) Assign
         * iii) Notes
         */
        self.isActive = {Timeline: 0, Notes: null, Assign: null};
        self.eventCarouselService.getMetaData().then((metaData) => {
            self.eventCarouselMetaData = metaData;
        });

        /**
         * @see action-event.html.
         * When we action an event, this number indicates the current step we're looking at
         * @type {Number}
         */
        self.activeStep = 0;

        /**
         * For each step of the SOP. we store the 'result' here.
         * In the case of:
         *  i) Checkbox, a boolean
         * ii) Text, the string of user-inputted text
         * @type {Array}
         */
        self.stepUserInteraction = [];

        /**
         * The data below is only mock data. Currently we don't have one unit emittion multiple
         * events. What'll happen is that I'll have to do a get to the server, retrieving a
         * list of the user's last events.
         * NOTE I'm going to simulate a GET by asking eventService
         */
        // **********************ngTable--Schedule************* //
        self.eventHistoryData = [
            // {when: self.generateDate(0, 8, 41), activity: "Video", status: true, check: true},
            // {when: self.generateDate(0, 35, 35), activity: "Panic", status: true, check: true},
            // {when: self.generateDate(1, 7, 7), activity: "Photo", status: false, check: false},
            // {when: self.retrieveDMY() + " 08:15:32", activity: "Late Arrival", status: false, check: false},
        ];


        // **********************ngTable--Schedule************* //

        // **********************ngTable--Notes************* //
        /**
         * TODO: Implement the functionality that is needed to make this practically operable.
         * This means that we must think how notes are intertwined with events and how we display
         * notes accordingly.
         *
         * We want to show all notes for a person, (like Steve Butler), but we also want to know
         * which notes are relevant to which events generated by Steve.
         *
         * We're currently thinking of a view where we can see ONLY events history (Timeline View)
         * But then we also want a view where we perhaps can have a combination of events + seeing
         * which notes are applied to which event.
         *
         * But now that begs the question of whether we'll also have a "notes-only" view.
         *
         * What if I phone Martin Holdt and tell him to go assist Steve and I want to make a note of this.
         */

            self.showNoteEditor = false;
            self.noteEditorContent = '';
            self.dataNotes = [];
            self.colsNotes = [
              { field: "ts", title: "When", sortable: "ts", show: true},
              { field: "note", title: "Note", sortable: "note", show: true},
              { field: "username", title: "Controller", show: true}
            ];
            self.tableParamsNotes = new self.NgTableParams({
    			page: 1, // start with first page
    			count: 10, // count per page
    			sorting: {
    				ts: 'asc' // initial sorting
    			}
    		},
    		{
                total: 0,
    			getData(params) {
					let sort = params.sorting();
                    let by = Object.keys(sort)[0];
                    let order = sort[Object.keys(sort)[0]];

                    if(self.dataNotes && self.dataNotes.length > 0) {
                        self.dataNotes = self.dataNotes.sort((a,b) => {
                            if(a[by] && b[by]) {
                                if(order == 'asc') {
                                    return a[by] <= b[by];
                                }else {
                                    return b[by] <= a[by];
                                }
                            }
                        });
                        params.total(self.dataNotes.length);
                        return self.dataNotes.slice((params.page()-1)*params.count(),params.page()*params.count());
                    }else{
                        return self.dataNotes;
                    }
    			}
    		});
        // **********************ngTable--Notes************* //
            self.colsRelatedEvents = [
              { field: "createdAt", title: "When", sortable: "createdAt", show: true },
              { field: "diff", title: "Time Difference", show: true},
              { field: "sitename", title: "Site", sortable: "site", show: true},
              { field: "assetname", title: "Unit : Asset",show: true},
              { field: "closeEvent", title: "Close", show: true }
            ];
            self.tableParamsRelatedEvents = new self.NgTableParams({
    			page: 1, // start with first page
    			count: 10, // count per page
    			sorting: {
    				ts: 'asc' // initial sorting
    			}
    		},
    		{
                total: 0,
    			getData(params) {
					let sort = params.sorting();
                    let by = Object.keys(sort)[0];
                    let order = sort[Object.keys(sort)[0]];
                    let promise;
                    if(!self.events) {
                        promise = self.eventService.getEvents();
                    }else {
                        promise = Promise.resolve(self.events);
                    }
                    return promise.then((events) => {

                        self.events = events;
                        self.relatedSOPEvents = _.filter(self.events,(o) => {
                            return o.unit == self.currentEventMetaData.currentEvent.unit && o.eventType == self.currentEventMetaData.currentEvent.eventType && (o.activeUser == undefined || o.activeUser == self.currentEventMetaData.currentEvent.activeUser) && o._id !== self.currentEventMetaData.currentEvent._id;
                        });

                        if(self.relatedSOPEvents && self.relatedSOPEvents.length > 0) {
                            self.relatedSOPEvents = self.relatedSOPEvents.sort((a,b) => {
                                if(a[by] && b[by]) {
                                    if(order == 'asc') {
                                        return a[by] <= b[by];
                                    }else {
                                        return b[by] <= a[by];
                                    }
                                }
                            });
                            params.total(self.relatedSOPEvents.length);
                            return self.relatedSOPEvents.slice((params.page()-1)*params.count(),params.page()*params.count());
                        }else{
                            return self.relatedSOPEvents;
                        }
                    });
    			}
    		});

        //We get the sopClassificationTags for this account
        self.updateSOPClassificationTags();

        self.currentEventService.getMetaData().then((metaData) => {
            self.currentEventMetaData = metaData;
            self.currentEventMetaData.currentEvent = {};
            self.currentEventMetaData.showCurrentEvent = false;
            self.currentUnit = self.unitService.getUnitByIDSync(self.currentEventMetaData.currentEvent.unit || self.currentEventMetaData.currentEvent._id);
            if(!self.currentUnit.name) {
                self.currentUnit = undefined;
            }

            self.$scope.$watch(() => {
                return self.currentEventMetaData.currentEvent;
            }, (newValue, oldValue) => {
                    if(newValue._id !== oldValue._id) {
                        if(newValue._id) {
                            if(newValue.unit) {
                                let unitID = self.currentEventMetaData.currentEvent.unit;
                                self.currentUnit = self.unitService.getUnitByIDSync(unitID);
                                self.unitSim1 = self.unitService.getUnitByIDSync(unitID).sim1;
                                self.unitSim2 = self.unitService.getUnitByIDSync(unitID).sim2;

                                if(newValue.asset) {
                                    self.queryValue = newValue.asset;
                                    self.queryField = 'asset';
                                } else{
                                    self.queryValue = newValue.unit;
                                    self.queryField = 'unit';
                                }
                                self.dataNotes = _.cloneDeep(newValue.notes);
                            } else{
                                self.queryValue = newValue._id;
                                self.queryField = 'unit';
                                self.currentUnit = self.unitService.getUnitByIDSync(newValue._id);
                                if(self.currentUnit && self.currentUnit.name) {
                                    self.unitSim1 = self.unitService.getUnitByIDSync(newValue._id).sim1;
                                    self.unitSim2 = self.unitService.getUnitByIDSync(newValue._id).sim2;
                                }else {
                                    self.currentUnit = undefined;
                                }
                            }
                            // self.tableParams.reload();
                            if(self.currentEventMetaData.currentEvent.unit) {
                                self.eventService.getPhotosForEvent(self.currentEventMetaData.currentEvent).then((photos) => {
                                    if(photos && photos.length > 0 && (self.currentEventMetaData.currentEvent.eventType.includes("Photo") || self.currentEventMetaData.currentEvent.eventType == "Panic" || self.currentEventMetaData.currentEvent.eventType == "LPR VOI")) {
                                        self.currentEventMetaData.photoData = photos[0].data;
                                        self.currentEventMetaData.photoId = photos[0]._id;
										self.currentEventMetaData.photo = photos[0];
                                    }else {
                                        self.currentEventMetaData.photoData = undefined;
                                        self.currentEventMetaData.photoId = undefined;
                                        self.currentEventMetaData.photo = undefined;
                                    }
                                });
                            }

                            self.tableParamsNotes.reload();
                            // self.getCurrentEventHistoryByUnit();
                        }
                    } else{
                        self.dataNotes = _.cloneDeep(newValue.notes);
                        self.tableParamsNotes.reload();
                    }
                }
            ,true);
        });

        self.eventTabs = [
            { title: "Timeline", icon: null, color: null },
            { title: "Notes", icon: null, color: null },
            { title: "Assign", icon: null, color: null }
        ];

        self.unitTabs = [
            { title: "Timeline", icon: null, color: null },
            // { title: "Notes", icon: null, color: null },
        ];

        self.throttledLastConnectivityFunction = _.throttle(()=>{
            if(self.currentEventMetaData && self.currentEventMetaData.currentEvent && self.currentEventMetaData.currentEvent.unit) {
                let hbs = self.unitService.getUnitHeartbeatsSync(self.currentEventMetaData.currentEvent.unit);
                if(hbs && hbs.length > 0) {
                    return hbs[0].message;
                }else{
                    return 'No last activity';
                }
            }else if(self.currentEventMetaData && self.currentEventMetaData.currentEvent && self.currentEventMetaData.currentEvent._id) {
                let hbs = self.unitService.getUnitHeartbeatsSync(self.currentEventMetaData.currentEvent._id);
                if(hbs && hbs.length > 0) {
                    return hbs[0].message;
                }else{
                    return 'No last activity';
                }
            }
        },1000);
    }

    /**
     * We have a watch placed on currentEvent. When it gets set to a new event,
     * we want that event's history to be retrieved.
     */
    getCurrentEventHistoryByUnit() {
        let self = this;
        if (self.currentEventMetaData.currentEvent.lastHB) {
            let events = self.currentEventService.getSelectedUnitRelatedEvents();
            self.formatEventHistory(events);
        } else {
            let events = self.currentEventService.getCurrentEventRelatedEvents();
            self.formatEventHistory(events);
        }
    }

    //TODO consider viewing, and another information block comes in (new photo)
    formatEventHistory(events) {
        let self = this;
        let eventHistoryData = [];

        _.forEach(events, (historicEvent) => {
            let eventFormatted = {};
            eventFormatted.when = historicEvent.createdAt;
            eventFormatted.activity = historicEvent.eventType;
            eventFormatted.status = historicEvent.active;
            eventFormatted.check = historicEvent.active;
            eventFormatted.person = historicEvent.assetname;
            eventFormatted.unitno = historicEvent.unitname;

            eventHistoryData.push(eventFormatted);
        });
        return eventHistoryData;
        // self.reloadEventHistory();
    }

    /**
     * We have a watch placed on currentEvent. When it gets set to a new event,
     * we want that event's history to be retrieved.
     */
    getCurrentEventHistoryByHumanAsset() {
        let self = this;
        let events = self.currentEventService.getRelatedEventsByHumanAsset();

        self.formatEventHistory(events);
    }

    /**
     * I've found it a hassle to call self.tableParams.reload(). It's not working
     * I think this is because our table keeps referencing the old dataset
     * (so when I change self.eventHistoryData, it still looks toward the previous address)
     */
    // reloadEventHistory() {
    //     let self = this;
    //     self.tableParams = new self.NgTableParams({}, {counts: [], dataset: self.eventHistoryData});
    //     // self.tableParams.sorting({});
    //     // self.tableParams.page(1);
    //     // self.tableParams.reload();
    // }

    /**
     * [filterEventsBy description]
     * @param  {[type]} value [description]
     * @return {[type]}       [description]
     */
    filterEventsBy(value) {
        let self = this;

        let unitno = _.find(self.cols, (col) => {
            return col.field === 'unitno';
        });
        let person = _.find(self.cols, (col) => {
            return col.field === 'person';
        });

        if(value === 'assetID') {
            unitno.show = true;
            person.show = false;
        } else if(value === 'unitID') {
            unitno.show = false;
            person.show = true;
        }
    }


    /**
     * @see DashboardComponent clearCurrentEvent()
     */
    clearCurrentEvent(closeDueToAction) {
        let self = this;
        self.activeSecondaryTab = 'Timeline';
        self.onClearCurrentEvent({closeDueToAction});
        self.relatedSOPEvents = [];
        self.events = undefined;
        // self.currentEventService.clearCurrentEvent();
    }

	checkFailedLoginAndAction(event) {
		let self = this;
		if(event.active && event.eventType == 'Failed Login') {
			self.handleFailedLogin(event);
		}else{
			self.actionEvent(event);
		}
	}

	//handleFailedLogin(event) {
		//let self = this;
		//let settings = {
			//title: `<span style="margin:auto;">Login failed for ${event.assetname}</span>`,
			//theme: 'modern',
			//animation: 'top',
			//scope: self.$scope,
			//closeAnimation: 'bottom',
			//content: `Would you like to allow the failed login?`,
			//escapeKey: false,
			//backgroundDismiss: false,
			//buttons: {
				//// long hand button definition
				//allow: {
					//text: "Allow",
					//btnClass: 'btn btn-primary',
					//action(scope, button) {
						//self.eventService.allowLogin(event)
							//.then( () => {
								//return self.actionEvent(event);
							//})
							//.catch( (err) => console.error(err));
						//return true;
					//}
				//},
				//close: {
					//text: "Deny",
					//btnClass: 'btn btn-warning',
					//action(scope) {
						//self.actionEvent(event);
					//}
				//},
				////escalate: {
					////text: "Escalate",
					////btnClass: 'btn btn-danger',
					////action(scope, button) {
						////self.escalateEvent(event._id);
						////return false;
					////}
				////},
			//},
		//};
		//self.$ngConfirm(settings);
	//}

	allowFailedLogin(event) {
		let self = this;
		self.eventService.allowLogin(event)
			.then( () => {
				return self.actionEvent(event);
			})
			.catch( (err) => console.error(err));
	}

    /**
     * Handles the action of an event.
     * It should send the new event to the server with the notes
     */
    actionEvent(currentEvent) {
        let self = this;
        let actionedEvent = currentEvent;

        let eventStack = self.eventStack[actionedEvent.eventType];
        self.activeStep = 0;
        self.stepUserInteraction = {};
        let sop = self.currentEventMetaData.currentEvent.sop;
        self.events = _.cloneDeep(eventStack);
        let relatedSOPEvents = _.filter(self.events,(o) => {
            return o.unit == currentEvent.unit && o.eventType == currentEvent.eventType && (o.activeUser == undefined || o.activeUser == currentEvent.activeUser) && o._id !== currentEvent._id;
        });
		let eventDetails = self.eventDetails[currentEvent.eventType];
		let formattedEventType = self.$filter('formatEventType')(currentEvent.eventType, currentEvent, eventDetails);

        self.relatedSOPEvents = relatedSOPEvents;
        if(!sop && self.relatedSOPEvents.length <= 0) {
            let settings = {
                    title: `<span style="margin:auto;">Action ${formattedEventType}</span>`,
                    theme: 'modern',
                    animation: 'top',
                    scope: self.$scope,
                    closeAnimation: 'bottom',
                    content: `Are you sure you wish to action this event?`,
                    escapeKey: false,
                    backgroundDismiss: false,
                    buttons: {
                        // long hand button definition
                        ok: {
                            text: "Action",
                            btnClass: 'btn-primary',
                            keys: ['enter'], // will trigger when enter is pressed
                            action(scope, button) {
                                        let newEvent = _.cloneDeep(currentEvent);
                                        newEvent.active = false;
                                        newEvent.closedAt = self.moment.utc().toDate();
                                        let currentUser = self.Auth.getCurrentUserSync();
                                        newEvent.closer = currentUser._id;
                                        newEvent.closername = `${currentUser.firstname} ${currentUser.lastname}`;
                                        self.eventService.updateEvent(newEvent).then((response) => {

                                            self.clearCurrentEvent(true);
                                        })
.catch((err) => {
                                            console.error(err);
                                            self.toastr('Error closing event!');
                                        });
                            }
                        },
                        close: {
                            text: "Cancel",
                            action(scope) {

                            }
                        }
                    },
                };
            if(!self.currentEventMetaData.currentEvent.active) {
                settings.title =`<span style="margin:auto;">${formattedEventType} closed</span>`;
                settings.content = 'Event has been closed with no SOP.';
                delete settings.buttons.ok;
                settings.buttons.close.text = 'Close';
            }
            self.$ngConfirm(settings);
        } else {
            if(!self.currentEventMetaData.currentEvent.sop && self.currentEventMetaData.currentEvent.active) {

                self.currentEventMetaData.currentEvent.sop = {
                    name:'Close Multiple Events',
                    eventType: self.currentEventMetaData.currentEvent.eventType,
                    procedures:[]
                };
            }
            if(sop && sop.procedures) {
                _.forEach(sop.procedures, (procedure) => {
                    self.stepUserInteraction[procedure._id] = {compulsory: procedure.compulsory, procedureType: procedure.procedureType, humanInput: procedure.value || '', done:false};
                });
            }
			if(self.currentEventMetaData.currentEvent.active) {
				self.currentEventMetaData.currentEvent.sop.procedures.push({label:'Close Multiple Events',procedureType:'Table',instructions:'Select events that will be closed using the same SOP.'});
			}

            let settings = {
                    title: `<span style="margin:auto;">Action ${formattedEventType}</span>`,
                    theme: 'modern',
                    animation: 'top',
                    scope: self.$scope,
                    closeAnimation: 'bottom',
                    columnClass: 'large',
                    content: require('./action-event.html'),
					size: 'lg',
                    escapeKey: false,
                    backgroundDismiss: false,
                    buttons: {
                        // long hand button definition
                        ok: {
                            text: "Action",
                            btnClass: 'btn-primary',
                            keys: ['enter'], // will trigger when enter is pressed
                            action(scope, button) {
                                let proceed = true;
                                _.forEach(self.stepUserInteraction, (userStep) => {
                                    if(userStep.compulsory) {
                                        if(!userStep.done) {
                                            proceed = false;
    										// self.toastr.info(`Not all <b>compulsory</b> steps have been completed. Please complete all steps before trying to action this event`,  {
    										// 	closeButton: true,
    										// 	tapToDismiss: true,
    										// 	allowHtml: true
    										// });
                                        }
                                    }
                                });


                                if(!proceed) {
                                    self.$ngConfirm(`Not all <span style="text-decoration:underline;">compulsory</span> steps have been completed. <br />Please complete all steps that are marked as compulsory`, `Complete all <b>compulsory</b> steps`);
                                    return false;
                                } else {
                                    let sop = self.actionedSOP;
                                    let index = _.findIndex(eventStack, (event) => {
 return event._id === actionedEvent._id;
});

                                    _.remove(currentEvent.sop.procedures,(o) => {
                                        return o.procedureType == 'Table' && o.label == 'Close Multiple Events';
                                    });
                                    if(index !== -1) {

                                        let newEvent = _.cloneDeep(currentEvent);
                                        newEvent.active = false;

                                        let idx = 0;
                                        _.forEach(self.stepUserInteraction, (userStep) => {
                                            newEvent.sop.procedures[idx].value = userStep.humanInput;
                                            idx ++;
                                        });
                                        newEvent.closedAt = self.moment.utc().toDate();
                                        let currentUser = self.Auth.getCurrentUserSync();
                                        newEvent.closer = currentUser._id;
                                        newEvent.closername = `${currentUser.firstname} ${currentUser.lastname}`;

                                        if(_.some(self.relatedSOPEvents,'closeEvent')) {
                                            let sop = newEvent.sop;

                                            let closeTheseEvents = _.filter(self.relatedSOPEvents,'closeEvent');
                                            let eventIds = _.map(closeTheseEvents,'_id');
                                            eventIds.push(newEvent._id);
                                            self.eventService.closeMultiple(sop,eventIds).then((response) => {
                                                self.clearCurrentEvent(true);
                                            })
.catch((err) => {
                                                console.error(err);
                                                self.toastr('Error closing multiple event!');
                                            });

                                        }else {
                                            self.eventService.updateEvent(newEvent).then((response) => {

                                                self.clearCurrentEvent(true);
                                            })
.catch((err) => {
                                                console.error(err);
                                                self.toastr('Error closing event!');
                                            });
                                        }
                                    } else {
                                        console.error("Event to action cannot be found");
                                    }
                                }
                            }
                        },
                        close: {
                            text: "Cancel",
                            action(scope) {

                                if(currentEvent.sop && currentEvent.sop.procedures) {
                                    _.remove(currentEvent.sop.procedures,(o) => {
                                        return o.procedureType == 'Table' && o.label == 'Close Multiple Events';
                                    });
                                }
                            }

                        }
                    },
                };
            if(!self.currentEventMetaData.currentEvent.active) {
                settings.title =`<span style="margin:auto;">${formattedEventType} closed</span>`;
                delete settings.buttons.ok;
                settings.buttons.close.text = 'Close';
				if(!self.currentEventMetaData.currentEvent.sop) {
					delete settings.columnClass;
					settings.content = 'Event has been closed with no SOP.';
				}else if(self.currentEventMetaData.currentEvent.sop.name == "Close Multiple Events") {
					delete settings.columnClass;
					settings.content = 'Event has been closed with multiple events with no SOP.';
				}
            }
            self.$ngConfirm(settings);
        }


    }

    setActiveStep(index) {
        let self = this;
        self.activeStep = index;
    }

    phoneUnit() {
        let self = this;

        self.$ngConfirm(
            {
                title: `<span style="margin:auto;">Phone ${self.currentEventMetaData.currentEvent.name || self.currentEventMetaData.currentEvent.unitname}</span>`,
                theme: 'modern',
                animation: 'top',
                scope: self.$scope,
                closeAnimation: 'bottom',
                content: require('./phone-unit.html'),
                escapeKey: true,
                backgroundDismiss: true,
                buttons: {
                    // long hand button definition
                    // ok: {
                    //     text: "Yes",
                    //     btnClass: 'btn-primary',
                    //     keys: ['enter'], // will trigger when enter is pressed
                    //     action: function(scope) {
                    //
                    //
                    //     }
                    // },
                    close: {
                        text: "Close",
                        action(scope) {

                        }

                    }
                },
            }
        );
    }

    getEventPhone() {
        let self = this;

    }

    //Used for dummy purposes in the selected-event schedule table
    generateDate(hours, minutes, seconds) {
        let self = this;
        return self.moment()
            .subtract(hours || 0, "hours")
            .subtract(minutes || 0, "minutes")
            .subtract(seconds || 0, "seconds")
            .format('DD/MM/YYYY hh:mm:ss');
    }

    retrieveDMY() {
        let self = this;
        return self.moment().format('DD/MM/YYYY');

    }

    /**
     * When a tab gets clicked it is bound to the variable, 'activeSecondaryTab'
     */
    setCurrentSecondaryTab(tab, $event) {
        let self = this;
        let title = tab.title;
        if($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }
        _.forEach(self.isActive, (value, tabTitle) => {
            if(tabTitle !== tab.title) {
                self.isActive[tabTitle] = 1;
            } else {
                self.isActive[tabTitle] = 0;
                self.activeSecondaryTab = title;
            }
        });

    }

    /**
     * This function has to do with retrieving and displaying notes concerning
     * events when the user shows details of a specific event and then clicks on 'notes'
     * TODO: Understand whether this notes are of concerning the PERSON
     * or the EVENT, the SITE, etc.
     */
    getEventNotes() {
        let self = this;
        // self.dataNotes.push[{}]
    }

    focusNoteEditor($event) {
        let self = this;
        if($event) {
            $event.stopPropagation();
            $event.preventDefault();
        }

        if($event.currentTarget) {
            $event.currentTarget.focus();
        } else {
            document.getElementById("styledEditor").focus();
        }
    }

    addNote() {
        let self = this;
        if(self.currentEventMetaData.currentEvent._id) {
            if(self.noteEditorContent !== '') {
                let newEvent = _.cloneDeep(self.currentEventMetaData.currentEvent);
                if(!newEvent.notes) {
                    newEvent.notes = [];
                }
                newEvent.notes.push({ts:self.moment.utc().toDate(), note:self.noteEditorContent, user: self.Auth.getCurrentUserSync()._id, username: `${self.Auth.getCurrentUserSync().firstname} ${self.Auth.getCurrentUserSync().lastname}`});
                self.eventService.updateEvent(newEvent);
                self.noteEditorContent = '';
                self.showNoteEditor = false;
            }
        }
    }

    /**
     * TODO finish this
     */
    goToAddNote() {
        let self = this;
        self.setCurrentSecondaryTab({title: "Notes"});
    }

    doLog() {
        let self = this;
		console.debug(this);
    }

    getLastConnectivity() {
        let self = this;
        return self.throttledLastConnectivityFunction();
    }

    getVideoUrlFromEvent(event) {
        $ctrl.eventService.getVideoUrl(event._id).then((video)=>{
            return video.data;
        });
    }

    openFootage(snapshot) {
        let self = this;
        self.eventCarouselService.closeCarouselWidget();
        self.$uibModal.open({
            animation: true,
            backdrop: 'static',
            keyboard: true,
            template: require('../../photoAnalytics/imageCollection/modal.html'),
            controller: PhotoModalController,
            controllerAs: '$ctrl',
            size: 'lg',
            resolve: {
                snapshot() {
                    return snapshot;
                },
                snapPlaceholder() {
                    return self.eventCarouselMetaData.footages;
                }
            }
        }).result.then(function(result) {
            if(result && result.newSnap) {
                self.openFootage(result.newSnap);
            } else if(result && result.next) {
                //self.openNext();
            } else if(result && result.previous) {
                //self.openPrevious();
            }
        }, function() {});
    }

    //openFootage(index) {
        //let self = this;
        //if(!self.eventCarouselMetaData.footages[index]) {
            //return null;
        //}
        //if(self.openedFootage) {
            //self.openedFootage.close();
        //}
        //self.openFootageIndex = index;
        //let footage = self.eventCarouselMetaData.footages[index];
        //let timeFormat = "YYYY-MM-DD HH:mm:ss";

        //self.openedFootage = self.$ngConfirm(
            //{
                //title: `<span style="margin:auto;">${footage.unitname} - ${self.moment(footage.ts).format(timeFormat)}</span>`,
                //theme: 'dark',
                //animation: 'top',
                //scope: self.$scope,
                //columnClass: 'xlarge',
                //closeAnimation: 'bottom',
                //closeIcon:true,
                //// content: require('./footage-modal.html'),
                //content: `<div style="display:flex;justify-content:center;">
                    //<img ng-src=${footage.data} style="max-width:90em; max-height:60em;width:unset;"/>
                    //<div ng-if="$ctrl.eventCarouselMetaData.footages[$ctrl.openFootageIndex+1]" class="half-overlay right" ng-click="$ctrl.openFootage($ctrl.openFootageIndex+1)">
                        //<div class="hover-icon" style="left:70%"><i class="fa fa-chevron-right"></i></div>
                    //</div>
                    //<div ng-if="$ctrl.eventCarouselMetaData.footages[$ctrl.openFootageIndex-1]" class = "half-overlay left" ng-click="$ctrl.openFootage($ctrl.openFootageIndex-1)">
                        //<div class="hover-icon" style="left:30%"><i class="fa fa-chevron-left"></i></div>
                    //</div>
                //</div>`,
                //escapeKey: false,
                //backgroundDismiss: true,
                //buttons: {
                    //// long hand button definition
                    //// ok: {
                    ////     text: "Action",
                    ////     btnClass: 'btn-primary',
                    ////     keys: ['enter'], // will trigger when enter is pressed
                    ////     action: function(scope) {
                    ////
                    ////     }
                    //// },
                    //close: {
                        //text: "Close",
                        //action: function(scope) {

                        //}

                    //}
                //},
            //}
        //);
    //}

    reassignEvent() {
        let self = this;
        self.$ngConfirm(
            {
                title: `<span style="margin:auto;">Reassign</span>`,
                theme: 'dark',
                animation: 'top',
                scope: self.$scope,
                columnClass: 'xlarge',
                closeAnimation: 'bottom',
                // content: require('./footage-modal.html'),
                content: `<div>
                    Are you sure you want to reassign this event.
                </div>`,
                escapeKey: false,
                backgroundDismiss: true,
                buttons: {
                    // long hand button definition
                    ok: {
                        text: "Reassign",
                        btnClass: 'btn-primary',
                        keys: ['enter'], // will trigger when enter is pressed
                        action(scope) {
                            let newEvent = _.cloneDeep(self.currentEventMetaData.currentEvent);
                            newEvent.reassignTo = self.reassignToUser._id;
                            self.eventService.updateEvent(newEvent);
                        }
                    },
                    close: {
                        text: "Cancel",
                        action(scope) {

                        }

                    }
                },
            }
        );
    }

    updateSOPClassificationTags() {
        let self = this;
		self.accountService.getAccountByID(self.Auth.getCurrentAccountSync().ref).then((account) => {
            self.sopClassificationTags = account.sopClassificationTags;
        });
    }


    getAvailableUsers(searchVal) {
        let self = this;
        return self.userService.getUsersWithQuery({
			filter : searchVal,
			limit : 20,
        }).then((response) => {
            let users = response.data;
            self.availableUsers = _.filter(users,(o) => {
                return o._id !== self.Auth.getCurrentUserSync()._id;
            });
        });
    }

    toggleCarouselLocked() {
        let self = this;
        if(self.eventCarouselService.widgetLocked) {
            self.eventCarouselService.unlockCarouselWidget();
        }else{
            self.eventCarouselService.lockCarouselWidget();
        }
    }

    toggleOpenClosedPhotos() {
        let self = this;

        if(self.eventCarouselService.showOnlyOpen) {
            self.eventCarouselService.setShowOpenAndClosed();
        }else{
            self.eventCarouselService.setShowOnlyOpen();
        }
        self.$timeout(() => {
			let event = self.eventService.getEventByIDSync(self.eventCarouselMetaData.footagesId);
            if(event) {
                self.eventCarouselService.preliminarilySetFootages(event, true);
            }else {
                let unit = self.unitService.getUnitByIDSync(self.eventCarouselMetaData.footagesId);
                self.eventCarouselService.preliminarilySetFootagesUnit(unit, true);
            }
        });
    }

    requestSnapshot(unitId) {
        let self = this;
        self.unitService.requestSnapshot(unitId).then((result) => {
			if(result) {
				self.toastr.info('Snapshot requested.');
			}else{
				self.toastr.warning('Snapshot request already pending.');
			}
        })
.catch((err) => {
			self.toastr.error('Snapshot request failed.');
            console.error(err);
        });
    }

    requestSiteSnapshot(siteId) {
        let self = this;
		self.siteService.requestSiteSnapshots({_id:siteId},self.$scope);
    }


    toggleMoreDetail() {
        let self = this;
        self.showMore = !self.showMore;
		self.showVOI = false;
    }

    toggleVOIDetail() {
        let self = this;
        self.showVOI = !self.showVOI;
		self.showMore = false;
    }

    loadMoreImages() {
        let self = this;

        let event = self.eventService.getEventByIDSync(self.eventCarouselMetaData.footagesId);
        if(!event) {
            let unit = self.unitService.getUnitByIDSync(self.eventCarouselMetaData.footagesId);
            event = {_id : unit._id, unit: unit._id};
        }
        self.eventCarouselService.loadMoreImages(event);
    }

	refreshContacts(searchVal) {
		let self = this;
		self.Contacts.getList({
			filter : searchVal,
			limit : 20,
		}).then((contacts) => {
			self.availableContacts = contacts;
		})
.catch((err) => {
			console.error(err);
		});
	}

	escalateEvent(eventId) {
		let self = this;
		self.escalateToContact = undefined;

        self.$ngConfirm(
            {
                title: `<span style="margin:auto;">Escalate Event</span>`,
                theme: 'modern',
                animation: 'top',
                scope: self.$scope,
                closeAnimation: 'bottom',
                content: require('./select-contact.html'),
                escapeKey: true,
                backgroundDismiss: true,
                buttons: {
                    // long hand button definition
					 ok: {
						 text: "Escalate",
						 btnClass: 'btn-primary',
						 keys: ['enter'], // will trigger when enter is pressed
						 action(scope) {
							 self.eventService.escalateEvent(self.currentEventMetaData.currentEvent._id,self.escalateToContact).then( (event) => {
								 if(event) {
									 self.toastr.info('Event escalation sent.');
								 }
							 } )
.catch(err=>{
								 self.toastr.error('Event escalation failed.');
							 	console.error(err);
							 });
						 }
					 },
                    close: {
                        text: "Cancel",
                        action(scope) {

                        }

                    }
                },
            }
        );
	}

	openReport(event) {
		let self = this;
		self.fieldReportService.getFieldReportByID(event.metaData.reportId).then( (report) => {
			let modalInstance = self.$uibModal.open({
				component: 'viewFieldReport',
				backdrop: 'static',
				resolve:{
					report:_.cloneDeep(report),
				}
			});
		} )
.catch( (err) => {
			console.error(err);

		} );
	}


}
export default angular.module('secutraqApp.dashboard')
.component('appSelectedEvent', {
    template: require('./selected-event.component.html'),
    controller: SelectedEventComponent,
    controllerAs: '$ctrl',
    bindings: {
        // showCurrentEvent: '=',
        onClearCurrentEvent: '&',
        eventDetails: '=',
        eventStack: '=',
        slickLoaded: '=',
        footages: '=',
        showSlickWidget: '=',
    }
})
.filter('formatFailedVideo',() => {
    return (val) => {

        switch (val) {
            case 'noResult':
                return 'No results for this time period';
                break;
            case 'timeout':
                return 'Request timed out';
                break;
            default:
                return val;
        }
    };
})
.filter('pastTense',() => {
    return (val) => {

        switch (val) {
            case 'Accept':
                return 'accepted';
                break;
            case 'Decline':
                return 'declined';
                break;
            case 'Cancel':
                return 'canceled';
                break;
            default:
                return val;
        }
    };
})
.name;
