export class FieldReportService {
    /*@ngInject*/
    constructor($interval, Restangular, moment, Auth, socket, toastr, $ngConfirm, unitService) {
        this.Restangular = Restangular;
        this.$interval = $interval;
		this.moment = moment;
		this.Restangular = Restangular;
		this.listenerMap = new Map();
		this.socket = socket;
		this.Auth = Auth;
		this.unitService = unitService;
		this.$ngConfirm = $ngConfirm;
		this.cacheTimeout = undefined;
		this.lastAccount = undefined;
        this.lastUnit = undefined;
		this.joined = false;
        this.toastr = toastr;
        this.fieldReports = [];
		this.rooms = [];
    }

    onSocketEvent(event, item, array) {
        let self = this;
        self.fieldReports = array;
        self.listenerMap.forEach((value, key) => {
            setTimeout(() => {
                value(event, item, array);
            }, 0);
        });
    }

    registerListener(context, cb) {
        let self = this;
        if (!self.lastAccount) {
            self.lastAccount = self.Auth.getCurrentAccountSync().ref;
        } else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
            self.destroyService();
            self.lastAccount = self.Auth.getCurrentAccountSync().ref;
        }
        if(!self.lastUser) {
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        } else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
            self.destroyService();
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        }
        let count = self.listenerMap.size;
        self.listenerMap.set(context, cb);
        // If listenermap was empty, (TODO and timer isn't running), join room, syncupdates
        if (!self.joined) {
            if (self.cacheTimeout) {
                clearTimeout(self.cacheTimeout);
                self.cacheTimeout = undefined;
            }
            return self.getFieldReports().then((fieldReports) => {
                self.socket.syncUpdates('fieldReport', self.fieldReports, self.onSocketEvent.bind(self), self);
				let filter = {};
				let hasPrivilege = self.Auth.hasPrivilegeSync('event.index',undefined,filter);
				if(hasPrivilege && filter && filter.filterParam && filter.filterParam.field == 'groups') {
					filter.filterParam.data.forEach( (group) => {
						self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().ref}:*:fieldReports:${group}`);
						self.rooms.push(`${self.Auth.getCurrentAccountSync().ref}:*:fieldReports:${group}`);
					} );
				}else{
					self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().ref}:*:fieldReports`);
					self.rooms.push(`${self.Auth.getCurrentAccountSync().ref}:*:fieldReports`);
				}
                self.joined = true;
                return fieldReports;
            });
        } else {
			if(self.cacheTimeout) {
				clearTimeout(self.cacheTimeout);
				self.cacheTimeout = undefined;
			}
            return self.getFieldReports();
        }
    }

    destroyService() {
        let self = this;
        if (self.joined) {
            self.socket.unsyncUpdates('fieldReport', self);
			if(self.rooms && self.rooms.length > 0) {
				self.rooms.forEach( (room) => {
					self.socket.leaveRoom(room);
				} );
				self.rooms = [];
			}
        }
        self.joined = false;
        self.fieldReports = [];
        self.listenerMap.clear();
        clearTimeout(self.cacheTimeout);
        self.cacheTimeout = undefined;
    }

    unregisterListener(context, cb) {
        let self = this;
        if (!self.lastAccount) {
            self.lastAccount = self.Auth.getCurrentAccountSync().ref;
        } else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
            self.destroyService();
            self.lastAccount = self.Auth.getCurrentAccountSync().ref;
        }
        if(!self.lastUser) {
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        } else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
            self.destroyService();
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        }
        self.listenerMap.delete(context);
        if (self.listenerMap.size === 0) {
            self.cacheTimeout = setTimeout(self.destroyService.bind(self), 5000);
        }
    }

    unregisterAll() {
        let self = this;
        if (!self.lastAccount) {
            self.lastAccount = self.Auth.getCurrentAccountSync().ref;
        } else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
            self.destroyService();
            self.lastAccount = self.Auth.getCurrentAccountSync().ref;
        }
        if(!self.lastUser) {
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        } else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
            self.destroyService();
            self.lastUser = self.Auth.getCurrentUserSync()._id;
        }
        self.listenerMap.clear();
        if (self.listenerMap.size === 0) {
            self.cacheTimeout = setTimeout(self.destroyService.bind(self), 5000);
        }
    }

    getFieldReports(query) {
        let self = this;
        if (self.fieldReports.length > 0) {
            return new Promise(function(resolve) {
                resolve(_.cloneDeep(self.fieldReports));
            });
        }
        let lastAccount = _.cloneDeep(self.lastAccount);
        let lastUser = _.cloneDeep(self.lastUser);
        return this.Restangular.all('fieldReports').getList(query)
            .then((fieldReports) => {
                if(lastAccount == self.lastAccount && lastUser == self.lastUser) {
                    self.fieldReports = fieldReports;
                    return _.cloneDeep(fieldReports);
                }else {
                    return [];
                }
            });
    }

    getFieldReportsWithQuery(query) {
        let self = this;
        return this.Restangular.all('fieldReports').getList(query)
        .then((response) => {
            return _.cloneDeep(response);
        })
.catch((err) => {
            console.error(err);
        });
    }

    setFieldReports(fieldReports) {
        let self = this;
        self.fieldReports = fieldReports;
    }

    hasFieldReports() {
        let self = this;
        return new Promise((resolve, reject) => {
            if(self.fieldReports !== undefined) {
                resolve(true);
            } else {
                let promise = self.$interval(() => {
                    if(self.fieldReports !== undefined) {
                        resolve(true);
                        self.$interval.cancel(promise);
                    }
                }, 500, 10);

                promise.then((result) => {
                    reject(`No fieldReports have been given to fieldReportService yet`);
                });
            }
        });
    }

    getFieldReportByIDSync(fieldReportID) {
        let self = this;
        let fieldReport = _.find(self.fieldReports, (fieldReport) => {
            return fieldReport._id === fieldReportID;
        });
        return fieldReport;
    }

    saveNewFieldReport(fieldReport) {
        let self = this;
        return self.Restangular.all('fieldReports').post(fieldReport);
	}

    updateFieldReport(fieldReport) {
        let self = this;
        return self.Restangular.one('fieldReports',fieldReport._id).customPATCH(fieldReport);
	}

    removeFieldReport(fieldReport) {
        let self = this;
        if(typeof fieldReport.remove === 'function') {
            fieldReport.remove().then((response) => {
                self.toastr.info('fieldReport removed.');
            })
.catch((err) => {
                console.error(err);
                self.toastr.error('fieldReport remove failed.');
            });

        }else{
            self.Restangular.one('fieldReports',fieldReport._id).remove()
.then((response)=>{
                self.toastr.info('fieldReport removed.');
            })
.catch((err) => {
                console.error(err);
                self.toastr.error('fieldReport remove failed.');
            });

        }
    }


    getFieldReportByID(fieldReportId) {
        let self = this;
        if(self.fieldReports && self.fieldReports.length > 0) {
            let index = _.findIndex(self.fieldReports,(storedfieldReport) => {
                return storedfieldReport._id === fieldReportId;
            });

            if(index !== -1) {
                return Promise.resolve(_.cloneDeep(self.fieldReports[index]));
            }else{
                return Promise.resolve('No such fieldReport!');
            }
        }else{
            return self.Restangular.one('fieldReports',fieldReportId).get()
.then((fieldReport) => {
                if(fieldReport) {
                    return fieldReport;
                }else{
                    return 'No such fieldReport!';
                }
            });
        }
    }
}

export default angular.module('secutraqApp.dashboard')
.service('fieldReportService', FieldReportService);
