import fabricJS from 'fabric-with-gestures';

export default class SnapshotModalController {
	$http;
	$uibModalInstance;
	$uibModal;
	$document;
	$state;
	$scope;
	canvas;
	canvasContext;
	snapshot;
	toastr;
	Auth;
	image;
	heatmap;
	more;
	dlibMore;
	moment;
	actualSnapshot;
	motionEnabled = false;
	facesEnabled = false;
	heatmapEnabled = false;
	recogResultsFound = false;
	tempRecognitions;
	snapPlaceholder;
	recalling;
	last = false;
	labelling;
	dlibRecognitions;
	tempSnaps = {};
	dlibSnaps = {};
	tempRefs = {};
	lprSnaps = {};
	plateOpen = {};
	plateSnaps = {};
	platesEnabled = false;
	plateWanted = {};
	dlibRefs = {};
	lastFilter = {};
	tempCrop = {};
	dlibCrop = {};
	$ngConfirm;
	currentCamera;
	tempWanted = {};
	dlibWanted = {};
	snapSize;
	hasFaceRecallPriv = false;

	isLoggedIn;
	isAdmin;
	isSuperAdmin;
	getCurrentUser;

	/*@ngInject*/

	constructor($uibModalInstance, photoService, toastr, snapPlaceholder, moment, $http, $document, snapshot, Auth, $state, $scope, $uibModal, $timeout, $ngConfirm, unitService) {
		this.$http = $http;
		this.unitService = unitService;
		this.$ngConfirm = $ngConfirm;
		this.Auth = Auth;
		this.moment = moment;
		this.$uibModalInstance = $uibModalInstance;
		this.$uibModal = $uibModal;
		this.$document = $document;
		this.$timeout = $timeout;
		this.snapPlaceholder = snapPlaceholder;
		this.snapshot = snapshot;
		this.recalling = false;
		this.photoService = photoService;
		this.labelling = false;
		this.$state = $state;
		this.toastr = toastr;
		this.$scope = $scope;
		this.linesEnabled = false;
		this.more = true;
		this.dlibMore = true;
		this.isLoggedIn = Auth.isLoggedInSync;
		this.isAdmin = Auth.isAdminSync;
		this.isSuperAdmin = Auth.isSuperAdminSync;
		this.showAnnotations = false;
		this.annotations = [];
		this.getCurrentUser = Auth.getCurrentUserSync;
		this.currentRooms = [];
		this.unitService = unitService;
		let self = this;
		self.$scope.$on('$destroy', function() {
			self.photoService.unregisterListener(self);
		});
	}

	$onInit() {
		let self = this;
		if(self.snapshot.metaData && self.snapshot.metaData.secuvueData && self.snapshot.metaData.secuvueData.reason && self.snapshot.metaData.secuvueData.reason === "LineTrip") {
			self.linesEnabled = true;
		}

		self.Auth.hasPrivilege("faceRecall").then(has => {
			self.hasFaceRecallPriv = has;
		});
		let lastIndex = _.findIndex(self.snapPlaceholder, snap => {
			return snap._id === self.snapshot._id;
		});
		if(self.snapPlaceholder.length === 0) {
			self.snapshot.first = true;
		} else {
			let selfIndex = _.findIndex(self.snapPlaceholder, o => {
				return o._id === self.snapshot._id;
			});
			if(selfIndex > 0) {
				self.snapshot.first = false;
			}
		}
		if(lastIndex === self.snapPlaceholder.length - 1 ) {
			self.last = true;
		}
		if(this.snapshot.snapRecognitions && this.snapshot.snapRecognitions.length > 0) {
			this.tempRecognitions = this.snapshot.snapRecognitions;
			this.tempRecognitions.forEach(recog => {
				recog.tempSimilarity = Math.floor(recog.similarity);
			});
		}

		if(self.snapshot.faceRecognitions && self.snapshot.faceRecognitions.length > 0) {
			self.dlibRecognitions = self.snapshot.faceRecognitions;
		}

		let has = true;
		//this.Auth.hasPrivilege('secuvue.snapshot').then(has => {
		if(has) {
			this.$http.get(`/api/photos/${this.snapshot._id}`).then(response => {
				this.actualSnapshot = response.data;
                self.$timeout(function () {
                    self.drawCanvas();
                }, 0);
			}, err => {
				if(err.status !== 401 && err.status !== 403) {
					console.error(err);
				}
			});
		} else if(this.Auth.isLoggedInSync()) {
			//self.toastr.info(`You require secuvue.snapshot privileges`, 'Unprivileged : ', {preventOpenDuplicates: true})
		}
		//});

		//this.Auth.hasPrivilege('secuvue.camera.configuration').then(has => {
		if(has) {
			// TODO: Fix issue when photo has no unit <12-02-21, liaan> //
			if(this.snapshot.unit) {
				this.$http.get(`/api/units/${this.snapshot.unit}`).then(response => {
					this.currentCamera = response.data;
					return null;
				})
					.catch( (err) => console.error(err));
			}
			self.photoService.registerListener(self, (event, item, array)=>{

				if(item._id === self.snapshot._id) {
					self.recalling = false;
					self.toastr.success("Photo analysed succesfully", {preventOpenDuplicates: true});
					if(self.hasFaceRecallPriv === true) {
						if(item.snapRecognitions.length>0) {
							self.snapshot.snapRecognitions = item.snapRecognitions;
						}
						if(self.snapshot.snapRecognitions && self.snapshot.snapRecognitions.length > 0) {
                            self.$timeout(function() {
                                self.drawCanvas();
                            }, 0);
							self.tempRecognitions = item.snapRecognitions;
							self.tempRecognitions.forEach(recog => {
								recog.tempSimilarity = Math.floor(recog.similarity);
								self.findRecogResults(recog.faceId);
							});
						}

						//if(item.faceRecognitions && item.faceRecognitions.length > 0) {
						//self.dlibRecognitions = item.faceRecognitions;
						//self.dlibRecognitions.forEach(recog => {
						//self.findDlibResults(recog.faceId);
						//});
						//}
					}
				}

			}, false);
		} else if(this.Auth.isLoggedInSync()) {
			//self.toastr.info(`You require secuvue.camera.configuration privileges`, 'Unprivileged : ', {preventOpenDuplicates: true})
		}
		//});

		if(self.hasFaceRecallPriv && this.snapshot.snapRecognitions && this.snapshot.snapRecognitions.length > 0) {
			this.populateWanted();
		}
	}

	close() {
		let result = "stuff";
		this.$uibModalInstance.close(result);
	}
	doLog() {
		console.debug(this);
	}

	next() {
		let self = this;
		let index = _.findIndex(self.snapPlaceholder, snap => {
			return snap._id === self.snapshot._id;
		});
		if(index !== -1 && index !== self.snapPlaceholder.length - 1) {
			this.$uibModalInstance.close({newSnap: self.snapPlaceholder[index + 1]});
		}
	}

	previous() {
		let self = this;
		let index = _.findIndex(self.snapPlaceholder, snap => {
			return snap._id === self.snapshot._id;
		});
		if(index !== -1 && index !== 0) {
			this.$uibModalInstance.close({newSnap: self.snapPlaceholder[index - 1]});
		}
	}

	updateRef(id, alias) {
		this.$http.patch(`/api/references/${id}`, {alias}).then(response => {
		});
	}

	divertSnap(snap) {
		this.$uibModalInstance.close({newSnap: snap});
	}

	detectFaces() {
		this.$http.get(`/api/photos/detectFaces/${this.snapshot._id}`).then(res => {
		});
	}

	populateDlib() {
		let self = this;
		let query = [];
		self.snapshot.faceRecognitions.forEach(obj => {
			query.push(obj.faceId);
		});
		self.$http.get('api/faces/populateWanted', {params: {faceIds: query}})
			.then(response => {
				response.data.forEach(obj => {
					self.dlibWanted[obj.faceId] = obj;
				});
			});
	}

	populateWanted() {
		let query = [];
		let self = this;
		self.snapshot.snapRecognitions.forEach(obj => {
			query.push(obj.faceId);
		});
		self.$http.get('api/references/populateWanted', {params: {faceIds: query}})
			.then(response => {
				response.data.forEach(obj => {
					self.tempWanted[obj.faceId] = obj;
				});
			});
	}

	redirectRef(id) {
		let self = this;
		self.$state.go('main.reference', {filter: id})
			.catch(err => {
				console.error(err);
			});
		self.$uibModalInstance.close();
	}

	updateWanted(id) {
		this.$http.get(`/api/references/updateWanted/${id}`, {params: {flag: true}})
			.then(response => {
				this.tempWanted[id].flag = true;
			});
	}

	requestSnapshot() {
		let self = this;
		if(!self.currentCamera) return null;
		self.unitService.requestSnapshot(self.currentCamera._id).then((result) => {
			if(result) {
				self.toastr.info('Snapshot requested.');
			}else{
				self.toastr.warning('Snapshot request already pending.');
			}
		})
			.catch((err) => {
				self.toastr.error('Snapshot request failed.');
				console.error(err);
			});
	}

	validateTime() {
		let self = this;
		let maxSeconds = 5*60;

		self.minutesAfter = self.moment(self.toTime).hour();
		self.secondsAfter = self.moment(self.toTime).minute() + self.minutesAfter*60;

		self.minutesBefore = self.moment(self.fromTime).hour();
		self.secondsBefore = self.moment(self.fromTime).minute() + self.minutesBefore*60;

		self.totalMinutes = Math.floor((self.secondsBefore+self.secondsAfter)/60);
		self.totalSeconds = (self.secondsBefore+self.secondsAfter)%60;

		if(self.secondsAfter+self.secondsBefore > maxSeconds) {
			self.validTime = false;
			self.timeConfirmer.buttons.ok.setDisabled(true);
		} else if(self.totalMinutes == 0 && self.totalSeconds == 0) {
			self.validTime = false;
			self.timeConfirmer.buttons.ok.setDisabled(true);
		} else {
			self.validTime = true;
			self.timeConfirmer.buttons.ok.setDisabled(false);
		}
		return;
	}

	requestVideo() {
		let self = this;
		if(!self.currentCamera) return null;
		//let from = self.moment.utc(self.snapshot.ts).subtract(10,'seconds')
		//.valueOf();
		//let to = self.moment.utc(self.snapshot.ts).add(10,'seconds')
		//.valueOf();
		self.fromTime = self.moment().startOf("day")
			.add(10,'minutes')
			.toDate();
		self.toTime = self.moment().startOf("day")
			.add(10,'minutes')
			.toDate();
		self.maxTime = self.moment().startOf("day")
			.add(5,'hours')
			.toDate();
		self.minTime = self.moment().startOf("day")
			.toDate();

		self.minutesAfter = 0;
		self.secondsAfter = 10;

		self.minutesBefore =0;
		self.secondsBefore = 10;
		self.validTime = true;
		self.motion = false;
		//self.unitService.openVideoRequest(self.snapshot.unit, self.snapshot.ts, self);

		self.timeConfirmer = self.$ngConfirm({
			title: `Request Video`,
			theme: 'modern',
			animation: 'top',
			closeAnimation: 'bottom',
			columnClass:'medium',
			content: require("../../dashboard/factories/videoRequest.html"),
			scope: self.$scope,
			escapeKey: false,
			backgroundDismiss: false,
			buttons: {
				// long hand button definition
				ok: {
					text: "Request Video",
					btnClass: 'btn-primary',
					disabled: !self.validTime,
					// keys: ['enter'], // will trigger when enter is pressed
					action(scope, button) {
						if(!self.validTime) {
							toastr.info(`You require secuvue.snapshot privileges`, 'Unprivileged : ', {preventOpenDuplicates: true});
							return false;
						} else {
							let minutesAfter = self.moment(self.toTime).hour();
							let secondsAfter = self.moment(self.toTime).minute() + minutesAfter*60;

							let minutesBefore = self.moment(self.fromTime).hour();
							let secondsBefore = self.moment(self.fromTime).minute() + minutesBefore*60;

							let fromTime = self.moment.utc(self.snapshot.ts).subtract(secondsBefore, "seconds")
								.valueOf();
							let toTime = self.moment.utc(self.snapshot.ts).add(secondsAfter, "seconds")
								.valueOf();

							self.unitService.requestVideoWithParams(self.currentCamera._id, {from:fromTime, to:toTime, motion:self.motion})
								.then(res => {
									self.toastr.success("Video Requested");
								})
								.catch(err => {
									console.error("Error with video request", err);
									self.toastr.error("Video Request Failed");
									return;
								});
						}
					}
				},
				close: {
					text: "Cancel",
					action(scope) {
					}
				}
			},
		});


	}

	drawCanvas() {
        let fabric = fabricJS;
		if(fabricJS.hasOwnProperty('fabric')) {
			fabric = fabricJS.fabric;
		}
		let self = this;
		if(!self.actualSnapshot) {
			return;
		}
		self.emSize = Number(getComputedStyle(document.getElementById("snapshotCanvas"),"").fontSize.match(/(\d+)px/)[1]);
		if(!self.canvas) {
			self.canvas = new fabric.Canvas('snapshotCanvas');
			self.pausePanning = false;
			self.canvas.on({
				'mouse:wheel'(opt) {
					var delta = opt.e.deltaY;
					var pointer = self.canvas.getPointer(opt.e);
					var zoom = self.canvas.getZoom();
					zoom = zoom - delta / 200;
					// limit zoom to 4x in
					if (zoom > 4) zoom = 4;
					// limit zoom to 1x out
					if (zoom < 1) {
						zoom = 1;
						self.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
					}
					self.canvas.zoomToPoint({
						x: opt.e.offsetX,
						y: opt.e.offsetY
					}, zoom);
					opt.e.preventDefault();
					opt.e.stopPropagation();
				},
				'touch:gesture'(e) {
					if (e.e.touches && e.e.touches.length === 2) {
						self.pausePanning = true;
						var point = new fabric.Point(e.self.x, e.self.y);
						if (e.self.state === "start") {
							self.zoomStartScale = self.canvas.getZoom();
						}
						var delta = self.zoomStartScale * e.self.scale;
						self.canvas.zoomToPoint(point, delta);
						self.pausePanning = false;
						// limit zoom to 4x in
						if (delta > 4) delta = 4;
						// limit zoom to 1x out
						if (delta < 1) {
							delta = 1;
							self.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
						}
					}
				},
				'touch:drag'(e) {
					if (self.pausePanning === false && undefined !== e.self.x && undefined !== e.self.y) {
						self.currentX = e.self.x;
						self.currentY = e.self.y;
						self.xChange = self.currentX - self.lastX;
						self.yChange = self.currentY - self.lastY;

						if (Math.abs(self.currentX - self.lastX) <= 100
							&& Math.abs(self.currentY - self.lastY) <= 100) {
							let delta = new fabric.Point(self.xChange*1.25, self.yChange*1.25);
							self.canvas.relativePan(delta);
						}

						self.lastX = e.self.x;
						self.lastY = e.self.y;
					}
				}
			});
		}
		self.canvas.clear();
		self.canvas.containerClass = 'snapshot-wrapper';

		self.$http({url:self.actualSnapshot.data,method:"GET",responseType:'arraybuffer'}).then( (res) => {
			let imageB64 = self.arrayBufferToBase64(res.data);
			let dataUrl = `data:image/jpg;base64,${imageB64}`;

			return dataUrl;
		} )
			.then( (dataUrl) => {
				let image = new fabric.Image.fromURL(dataUrl, function(image) {
					self.snapshot.height = image.height;
					self.snapshot.width = image.width;
					image.setOptions({left: 0, top: 0, opacity: 1, width: self.snapshot.width, height: self.snapshot.height });
					self.canvas.setBackgroundImage(image, self.canvas.renderAll.bind(self.canvas), {
						originX: 'left',
						originY: 'top'
					});
					self.canvas.setWidth(image.width, {backstoreOnly: true});
					self.canvas.setHeight(image.height, {backstoreOnly: true});

					if(image.width > image.height) {
						self.canvas.setWidth('100%', {cssOnly: true});
						self.canvas.setHeight('auto', {cssOnly: true});
					} else {
						self.canvas.setWidth('auto', {cssOnly: true});
						self.canvas.setHeight('100%', {cssOnly: true});
					}
					self.canvas.selection = false;

					if(self.platesEnabled) {
						if(self.snapshot.lprResults.length > 0) {
							self.snapshot.lprResults.forEach(plate => {
								let bb = [];
								plate.boundingBox.forEach(co => {
									bb.push({x: co.x*self.snapshot.width, y: co.y*self.snapshot.height});
								});

								let poly = new fabric.Polygon(bb,{
									stroke:"orange",
									strokeWidth: 3,
									selectable: false,
									fill:null
								});

								//TODO: Ensure alias does not go out of canvas area
								let textAlias = new fabric.Text(plate.plate, {
									fontSize: 28,
									fontFamily: 'Monospace',
									textAlign: 'center',
									left: poly.left+poly.width/2,
									top: poly.top+poly.height + 20,
									shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
									textBackgroundColor: 'rgba(200,200,200,0.5)',
									originX: 'center',
									originY: 'center',
									//padding: 10,
									//selectable: false,
									hasBorders: false,
									hasControls: false
								});
								self.canvas.add(textAlias);
								self.canvas.add(poly);
								self.canvas.renderAll();
							});
						}
					}

					if(self.popiEnabled) {
						if(self.snapshot.snapRecognitions.length > 0) {
							self.snapshot.snapRecognitions.forEach(rec => {
								self.$http({url:self.actualSnapshot.data,method:"GET",responseType:'arraybuffer'}).then( (res) => {
									let imageB64 = self.arrayBufferToBase64(res.data);
									let dataUrl = `data:image/jpg;base64,${imageB64}`;

									return dataUrl;
								} )
									.then( (dataUrl) => {
										let image = new fabric.Image.fromURL(dataUrl, function(image) {
											image.setOptions({
												left: 0,
												top: 0,
												opacity: 1,
												width: self.snapshot.width,
												height: self.snapshot.height,
												selectable: false,
												clipPath : new fabric.Rect({
													left:rec.boundingBox.Left * self.snapshot.width - self.snapshot.width / 2,
													top:rec.boundingBox.Top * self.snapshot.height - self.snapshot.height / 2,
													width:rec.boundingBox.Width * self.snapshot.width,
													height:rec.boundingBox.Height * self.snapshot.height
												})
											});
											image.filters.push(new fabric.Image.filters.Pixelate({blocksize: 20}));
											image.applyFilters();
											self.canvas.add(image);
											image.moveTo(10);
											self.canvas.renderAll();
										});
									});
							});
						} else if(self.snapshot.metaData && self.snapshot.metaData.secuvueData && self.snapshot.metaData.secuvueData.detections && self.snapshot.metaData.secuvueData.detections.length>0) {
							let detections = self.snapshot.metaData.secuvueData.detections;
							detections.forEach(function(detection) {
								if(detection.detectionType !== 'FrontalFace') {
									return;
								}
								self.$http({url:self.actualSnapshot.data,method:"GET",responseType:'arraybuffer'}).then( (res) => {
									let imageB64 = self.arrayBufferToBase64(res.data);
									let dataUrl = `data:image/jpg;base64,${imageB64}`;

									return dataUrl;
								} )
									.then( (dataUrl) => {
										let image = new fabric.Image.fromURL(dataUrl, function(image) {
											image.setOptions({
												left: 0,
												top: 0,
												opacity: 1,
												width: self.snapshot.width,
												height: self.snapshot.height,
												selectable: false,
												clipPath : new fabric.Rect({
													left:detection.rect.left - self.snapshot.width/2,
													top:detection.rect.top - self.snapshot.height/2,
													width:detection.rect.right - detection.rect.left,
													height:detection.rect.bottom - detection.rect.top
												})
											});
											image.filters.push(new fabric.Image.filters.Pixelate({blocksize: 20}));
											image.applyFilters();
											self.canvas.add(image);
											image.moveTo(10);
											self.canvas.renderAll();
										});
									});
							});
						}
					}

					//if(self.heatmapEnabled) {
					//let url = self.currentCamera.heatmap + `&ts=${+self.moment.now()}`;
					//let image = new fabric.Image.fromURL(url, function(image) {
					//image.setOptions({
					//left: 0,
					//top: 0,
					//opacity: 0.5,
					//width: self.snapshot.width,
					//height: self.snapshot.height
					//});
					//image.set('selectable', false);
					//self.canvas.add(image);
					//image.moveTo(20);
					//self.canvas.renderAll();
					//});
					//}

					if(self.hasFaceRecallPriv && self.snapshot.snapRecognitions.length > 0 && self.facesEnabled) {
						self.snapshot.snapRecognitions.forEach(rec => {
							let rect = new fabric.Rect({
								fill: 'rgba(0,0,0,0)',
								width: rec.boundingBox.Width * image.width,
								height: rec.boundingBox.Height * image.height,
								left: rec.boundingBox.Left * image.width,
								top: rec.boundingBox.Top * image.height,
								stroke: 'rgba(0,255,0, 0.5)',
								selectable: false,
								strokeWidth: 3
							});
							self.canvas.add(rect);
							rect.moveTo(30);
							rect.bringToFront();
						});
					}

					if(self.snapshot.metaData?.secuvueData?.openvinoPeopleDetections?.length > 0 && self.peopleEnabled) {
						self.snapshot.metaData.secuvueData.openvinoPeopleDetections.forEach(rec => {
							let rect = new fabric.Rect({
								fill: 'rgba(0,0,0,0)',
								width: rec.boundingBox.width * image.width,
								height: rec.boundingBox.height * image.height,
								left: rec.boundingBox.left * image.width,
								top: rec.boundingBox.top * image.height,
								stroke: 'rgba(255,255,0, 0.5)',
								strokeDashArray:[10,5],
								selectable: false,
								strokeWidth: 3
							});
							let text = new fabric.Text(`${Math.round(rec.confidence*10000)/100}%`,{
								fontSize: 12,
								fontFamily: 'Monospace',
								textAlign: 'center',
								left: rect.left+rect.width/2,
								top: rect.top + 20,
								shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
								textBackgroundColor: 'rgba(255,255,0,0.5)',
								originX: 'center',
								originY: 'center',
								//padding: 10,
								selectable: false,
								hasBorders: false,
								hasControls: false
							});
							self.canvas.add(text);
							self.canvas.add(rect);
							rect.moveTo(30);
							rect.bringToFront();
						});
					}

					if(self.snapshot.metaData?.secuvueData?.humanPoseResults?.length>0) {
						let colormap = [
							'rgba(255,0,0,1)', 'rgba(255,85,0,1)','rgba(255,170,0,1)',
							'rgba(255,255,0,1)', 'rgba(170,255,0,1)','rgba(85,255,0,1)',
							'rgba(0,255,0,1)', 'rgba(0,255,85,1)','rgba(0,255,170,1)',
							'rgba(0,255,255,1)', 'rgba(0,170,255,1)','rgba(0,85,255,1)',
							'rgba(0,0,255,1)', 'rgba(85,0,255,1)','rgba(170,0,255,1)',
							'rgba(255,0,255,1)', 'rgba(255,0,170,1)','rgba(255,0,85,1)',
						];
						let keypointPairs = [
							[1,2],
							[1,5],
							[2,3],
							[3,4],
							[5,6],
							[6,7],
							[1,8],
							[8,9],
							[9,10],
							[1,11],
							[11,12],
							[12,13],
							[1,0],
							[0,14],
							[14,16],
							[0,15],
							[15,17],
						];
						let pixels = image.width * image.height;
						let circRadius = pixels<500000 ? 1 : 4;
						self.snapshot.metaData.secuvueData.humanPoseResults.forEach(pose => {
							keypointPairs.forEach((pair, num) => {
								let kp0 = pose.keypoints[pair[0]];
								let kp1 = pose.keypoints[pair[1]];
								if(![kp0.x,kp0.y,kp1.x,kp1.y].includes(-1)) {

									let line = new fabric.Line([
										kp0.x*image.width,
										kp0.y*image.height,
										kp1.x*image.width,
										kp1.y*image.height,
									],{
										strokeWidth: 3,
										stroke: colormap[num],
										strokeDashArray:[10,5],
										originX: 'center',
										originY: 'center',
										perPixelTargetFind: true,
										selectable: false,
										targetFindTolerance: 10,
										//padding: 4,
										hasControls: false
									});
									self.canvas.add(line);

								}
							});
							pose.keypoints.forEach((kp,num) => {
								//Draw circles for each keypoint
								if(kp.x !== -1 && kp.y !== -1) {
									let kpCircle = new fabric.Circle({
										fill: colormap[num],
										radius:circRadius,
										originX: 'center',
										originY: 'center',
										//radius:1,
										left:kp.x*image.width,
										top:kp.y*image.height,
										selectable:false
									});
									self.canvas.add(kpCircle);
									kpCircle.bringToFront();
								}
							});
						});
					}

					if(self.snapshot.metaData?.secuvueData?.openvinoVehicleDetections?.length > 0 && self.vehiclesEnabled) {
						self.metaData.secuvueData.openvinoVehicleDetections.forEach(rec => {
							let rect = new fabric.Rect({
								fill: 'rgba(0,0,0,0)',
								width: rec.boundingBox.width * image.width,
								height: rec.boundingBox.height * image.height,
								left: rec.boundingBox.left * image.width,
								top: rec.boundingBox.top * image.height,
								stroke: 'rgba(0,255,255, 0.5)',
								strokeDashArray:[10,5],
								selectable: false,
								strokeWidth: 3
							});
							let text = new fabric.Text(`${Math.round(rec.confidence*10000)/100}%`,{
								fontSize: 12,
								fontFamily: 'Monospace',
								textAlign: 'center',
								left: rect.left+rect.width/2,
								top: rect.top + 20,
								shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
								textBackgroundColor: 'rgba(0,255,255,0.5)',
								originX: 'center',
								originY: 'center',
								//padding: 10,
								selectable: false,
								hasBorders: false,
								hasControls: false
							});
							self.canvas.add(text);
							self.canvas.add(rect);
							rect.moveTo(30);
							rect.bringToFront();
						});
					}

					if(self.snapshot.metaData?.secuvueData?.openvinoResults?.length > 0 && self.openvinoEnabled) {
						self.snapshot.metaData.secuvueData.openvinoResults.forEach(rec => {
							//let width = rec.boundingBox.right - rec.boundingBox.left;
							//let height = rec.boundingBox.bottom - rec.boundingBox.top;

							// TODO: Check this that this scaling value is correct <15-06-21, Liaan> //
							//let pix = rec.boundingBox.width * self.actualSnapshot.width * rec.boundingBox.height * self.actualSnapshot.height * self.currentCamera.camera.configuration.analyticsConfiguration.faceConfiguration.scaledown;
							let pix = rec.boundingBox.width * self.actualSnapshot.width * rec.boundingBox.height * self.actualSnapshot.height;
							let adjustedPix = pix * 0.5;

							let rect = new fabric.Rect({
								fill: 'rgba(0,0,0,0)',
								width: rec.boundingBox.width * image.width,
								height: rec.boundingBox.height * image.height,
								left: rec.boundingBox.left * image.width,
								top: rec.boundingBox.top * image.height,
								stroke: 'rgba(255,0,0, 0.5)',
								strokeDashArray: [10,5],
								selectable: false,
								strokeWidth: 3
							});
							let text = new fabric.Text(`${Math.round(pix)} (${Math.round(adjustedPix)})`,{
								fontSize: 12,
								fontFamily: 'Monospace',
								textAlign: 'center',
								left: rect.left+rect.width/2,
								top: rect.top+10,
								shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
								textBackgroundColor: 'rgba(200,200,200,0.5)',
								originX: 'center',
								originY: 'center',
								//padding: 10,
								selectable: false,
								hasBorders: false,
								hasControls: false
							});
							self.canvas.add(text);
							self.canvas.add(rect);
							rect.moveTo(30);
							rect.bringToFront();
						});
					}

					if( self.snapshot?.metaData?.secuvueData?.detections?.length>0) {
						let detections = self.snapshot.metaData.secuvueData.detections;
						detections.forEach(function(detection) {
							let detWidth = detection.rect.right - detection.rect.left;
							let detHeight = detection.rect.bottom - detection.rect.top;
							if(detection.detectionType === 'Motion' && self.motionEnabled) {
								let rect = new fabric.Rect({
									fill: 'rgba(0,0,0,0)',
									width: detection.rect.right - detection.rect.left,
									height: detection.rect.bottom - detection.rect.top,
									left: detection.rect.left,
									top: detection.rect.top,
									stroke: 'rgba(0,0,255, 0.5)',
									selectable: false,
									strokeWidth: 3
								});
								self.canvas.add(rect);
								rect.moveTo(30);
								rect.bringToFront();
							} else if(detection.detectionType === 'FrontalFace' && self.facesEnabled && self.snapshot.snapRecognitions.length == 0) {
								let rect = new fabric.Rect({
									fill: 'rgba(0,0,0,0)',
									width: detection.rect.right - detection.rect.left,
									height: detection.rect.bottom - detection.rect.top,
									left: detection.rect.left,
									top: detection.rect.top,
									stroke: 'rgba(0,255,0, 0.5)',
									selectable: false,
									strokeWidth: 3
								});
								self.canvas.add(rect);
								rect.moveTo(30);
								rect.bringToFront();
							} else if(detection.detectionType === 'Person' && self.peopleEnabled) {
								//TODO change if
								let rect = new fabric.Rect({
									fill: 'rgba(0,0,0,0)',
									width: detection.rect.right - detection.rect.left,
									height: detection.rect.bottom - detection.rect.top,
									left: detection.rect.left,
									top: detection.rect.top,
									stroke: 'rgba(255,127,0, 0.5)',
									selectable: false,
									strokeWidth: 3
								});
								let text = new fabric.Text(`${Math.round(detection.confidence * 10000)/100} %`,{
									fontSize: 12,
									fontFamily: 'Monospace',
									textAlign: 'center',
									left: rect.left+detWidth/2,
									top: rect.top+10,
									shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
									textBackgroundColor: 'rgba(255,127,0,0.5)',
									originX: 'center',
									originY: 'center',
									//padding: 10,
									selectable: false,
									hasBorders: false,
									hasControls: false
								});
								self.canvas.add(text);
								self.canvas.add(rect);
								rect.moveTo(30);
								rect.bringToFront();
							} else if(detection.detectionType === 'Vehicle' && self.vehiclesEnabled) {
								//TODO change if
								let rect = new fabric.Rect({
									fill: 'rgba(0,0,0,0)',
									width: detection.rect.right - detection.rect.left,
									height: detection.rect.bottom - detection.rect.top,
									left: detection.rect.left,
									top: detection.rect.top,
									stroke: 'rgba(0, 127, 255, 0.5)',
									selectable: false,
									strokeWidth: 3
								});
								let text = new fabric.Text(`${Math.round(detection.confidence*10000)/100} %`,{
									fontSize: 12,
									fontFamily: 'Monospace',
									textAlign: 'center',
									left: rect.left+detWidth/2,
									top: rect.top+10,
									shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
									textBackgroundColor: 'rgba(0,127,255,0.5)',
									originX: 'center',
									originY: 'center',
									//padding: 10,
									selectable: false,
									hasBorders: false,
									hasControls: false
								});
								self.canvas.add(text);
								self.canvas.add(rect);
								rect.moveTo(30);
								rect.bringToFront();
							} else if(detection.detectionType === 'SSD' && self.Auth.hasUserPrivilegeSync('debug')) {
								let rect = new fabric.Rect({
									fill: 'rgba(0,0,0,0)',
									width: (detection.rect.right - detection.rect.left) * self.snapshot.width,
									height: (detection.rect.bottom - detection.rect.top) * self.snapshot.height,
									left: detection.rect.left * self.snapshot.width,
									top: detection.rect.top * self.snapshot.height,
									stroke: 'rgba(18, 138, 0, 0.5)',
									selectable: false,
									strokeWidth: 3
								});
								let text = new fabric.Text(`${detection.label[0].toUpperCase() + detection.label.substring(1)}`,{
									fontSize: 12,
									fontFamily: 'Monospace',
									textAlign: 'center',
									left: rect.left + detWidth*self.snapshot.width/2 ,
									top: rect.top + 10 ,
									shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
									textBackgroundColor: 'rgba(18, 138, 0, 0.5)',
									originX: 'center',
									originY: 'center',
									//padding: 10,
									selectable: false,
									hasBorders: false,
									hasControls: false
								});
								self.canvas.add(text);
								self.canvas.add(rect);
								rect.moveTo(30);
								rect.bringToFront();

							} else if(detection.detectionType === 'HumanPose' && self.poseEnabled) {
								let colormap = [
									'rgba(255,0,0,1)', 'rgba(255,85,0,1)','rgba(255,170,0,1)',
									'rgba(255,255,0,1)', 'rgba(170,255,0,1)','rgba(85,255,0,1)',
									'rgba(0,255,0,1)', 'rgba(0,255,85,1)','rgba(0,255,170,1)',
									'rgba(0,255,255,1)', 'rgba(0,170,255,1)','rgba(0,85,255,1)',
									'rgba(0,0,255,1)', 'rgba(85,0,255,1)','rgba(170,0,255,1)',
									'rgba(255,0,255,1)', 'rgba(255,0,170,1)','rgba(255,0,85,1)',
								];
								let keypointPairs = [
									[1,2],
									[1,5],
									[2,3],
									[3,4],
									[5,6],
									[6,7],
									[1,8],
									[8,9],
									[9,10],
									[1,11],
									[11,12],
									[12,13],
									[1,0],
									[0,14],
									[14,16],
									[0,15],
									[15,17],
								];
								let pixels = image.width * image.height;
								let circRadius = pixels<500000 ? 1 : 4;
								keypointPairs.forEach((pair, num) => {
									let kp0 = detection.keypoints[pair[0]];
									let kp1 = detection.keypoints[pair[1]];
									if(![kp0.x,kp0.y,kp1.x,kp1.y].includes(-1)) {

										let line = new fabric.Line([
											kp0.x*image.width,
											kp0.y*image.height,
											kp1.x*image.width,
											kp1.y*image.height,
										],{
											strokeWidth: 3,
											stroke: colormap[num],
											originX: 'center',
											originY: 'center',
											perPixelTargetFind: true,
											selectable: false,
											targetFindTolerance: 10,
											//padding: 4,
											hasControls: false
										});
										self.canvas.add(line);

									}
								});
								detection.keypoints.forEach((kp,num) => {
									//Draw circles for each keypoint
									if(kp.x !== -1 && kp.y !== -1) {
										let kpCircle = new fabric.Circle({
											fill: colormap[num],
											radius:circRadius,
											originX: 'center',
											originY: 'center',
											//radius:1,
											left:kp.x*image.width,
											top:kp.y*image.height,
											selectable:false
										});
										self.canvas.add(kpCircle);
										kpCircle.bringToFront();
									}
								});

								let rect = new fabric.Rect({
									fill: 'rgba(0,0,0,0)',
									width: (detection.rect.right - detection.rect.left) * self.snapshot.width,
									height: (detection.rect.bottom - detection.rect.top) * self.snapshot.height,
									left: detection.rect.left * self.snapshot.width,
									top: detection.rect.top * self.snapshot.height,
									stroke: 'rgba(80, 8, 153, 0.5)',
									selectable: false,
									strokeWidth: 3
								});

								if (detection.poseLabel !== undefined && detection.poseLabel !== 'Unknown') {
									// TODO: Print tidy version of label later
									let labelText = new fabric.Text(`${detection.poseLabel}`,{
										fontSize: 12,
										fontFamily: 'Monospace',
										textAlign: 'center',
										left: rect.left+detWidth*self.snapshot.width/2,
										top: rect.top-7,
										shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
										textBackgroundColor: 'rgba(80,8,153,0.5)',
										originX: 'center',
										originY: 'center',
										//padding: 10,
										selectable: false,
										hasBorders: false,
										hasControls: false
									});
									self.canvas.add(labelText);

									let confidencePercentage = Math.round(detection.poseConfidence * 10000)/100;
									if (!isNaN(confidencePercentage)) {
										let confText = new fabric.Text(`${confidencePercentage}%`,{
											fontSize: 12,
											fontFamily: 'Monospace',
											textAlign: 'center',
											left: rect.left+detWidth*self.snapshot.width/2,
											top: rect.top+10,
											shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
											textBackgroundColor: 'rgba(80,8,153,0.5)',
											originX: 'center',
											originY: 'center',
											//padding: 10,
											selectable: false,
											hasBorders: false,
											hasControls: false
										});
										self.canvas.add(confText);
									}
								}

								self.canvas.add(rect);
								rect.moveTo(30);
								rect.bringToFront();
							}
						});
					}

					if(self.linesEnabled && self.snapshot.metaData && self.snapshot.metaData.secuvueData && self.snapshot.metaData.secuvueData.lines && self.snapshot.metaData.secuvueData.lines.length > 0) {
						let lineConfigs = self.snapshot.metaData.secuvueData.lines;
						let detections = self.snapshot.metaData.secuvueData.detections;
						let peopleCounters = self.snapshot.metaData.secuvueData.peopleCounters;

						let tripLines = [];
						//if(self.snapshot.metaData.secuvueData.reason && self.snapshot.metaData.secuvueData.reason === 'LineTrip') {
						detections.forEach(detection => {
							if(detection.detectionType === "Motion") {
								if(detection.lines && detection.lines.length > 0) {
									detection.lines.forEach(line => {
										tripLines.push(line);
									});
								}
							}
						});
						//}
						lineConfigs && lineConfigs.forEach(config => {

							let stats = _.find(peopleCounters, obj => {
								return obj.line === config.id;
							});

							if(stats !== undefined) {
								let color = "rgba(255,0,0,0.8)";
								let dashArray = [25, 10];

								if(tripLines.includes(config.id)) {
									color = "rgba(0, 255, 0, 0.8)";
									dashArray = [10, 2];
								}

								let line = new fabric.Line([
									config.line[0].x*self.canvas.width,
									config.line[0].y*self.canvas.height,
									config.line[1].x*self.canvas.width,
									config.line[1].y*self.canvas.height
								],{
									strokeWidth: 3,
									strokeDashArray: dashArray,
									stroke: `${color}`,
									originX: 'center',
									originY: 'center',
									perPixelTargetFind: true,
									selectable: false,
									targetFindTolerance: 10,
									//padding: 4,
									hasControls: false
								});
								self.canvas.add(line);

								line.setShadow({
									color: 'rgba(20, 20, 20, 1)',
									blur: 2,
									offsetX: 1,
									offsetY: 1
								});

								let coords = self.calculatePerpendicularBisectPoints(line, 50);

								let textA = new fabric.Text(`A\n${stats.numBToA}`, {
									fontSize: 16,
									fontFamily: 'Monospace',
									textAlign: 'center',
									left: coords[2],
									top: coords[3],
									textBackgroundColor: 'rgba(0,200,0,1)',
									originX: 'center',
									originY: 'center',
									shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
									selectable: false,
									hasBorders: false,
									hasControls: false
								});

								let textB = new fabric.Text(`B\n${stats.numAToB}`, {
									fontSize: 16,
									fontFamily: 'Monospace',
									textAlign: 'center',
									left: coords[0],
									top: coords[1],
									fill: 'white',
									shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
									textBackgroundColor: 'rgba(0,0,200,1)',
									originX: 'center',
									originY: 'center',
									selectable: false,
									hasBorders: false,
									hasControls: false
								});

								let textAlias = new fabric.Text(config.alias, {
									fontSize: 16,
									fontFamily: 'Monospace',
									textAlign: 'center',
									left: line.left,
									top: line.top-line.height/2 - 20,
									shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
									textBackgroundColor: 'rgba(200,200,200,0.5)',
									originX: 'center',
									originY: 'center',
									//padding: 10,
									selectable: false,
									hasBorders: false,
									hasControls: false
								});

								self.canvas.add(textA);
								self.canvas.add(textB);
								self.canvas.add(textAlias);
								self.canvas.sendToBack(textAlias);
								line.textA = textA;
								line.textB = textB;
								line.textAlias = textAlias;
							}
						});

					}


					self.canvas.renderAll();
				});
			})
			.catch( (err) => {
				console.error(err);
			} );

	}
	calculatePerpendicularBisectPoints(line, length) {
		let startx = (line.x1 + line.x2)/2;
		let starty = (line.y1 + line.y2)/2;
		let deltax = -line.y1 + line.y2;
		let deltay = line.x1 - line.x2;
		let linelength = Math.sqrt(Math.pow(line.x2 - line.x1, 2) + Math.pow(line.y2 - line.y1, 2));

		return [startx - length*deltax/linelength, starty - length*deltay/linelength, startx + length*deltax/linelength, starty + length*deltay/linelength];
	}

	requestHeatmap() {
		this.$http.get(`/api/cameras/${this.snapshot.camera}/requestHeatmap?${+this.moment.now()}` ).then(response => {}, err => {
			if(err.status !== 401 && err.status !== 403) {
				console.error(err);
			}
		});
	}

	getClipStyle(bb) {
		return `inset(${bb.Top * 100}% ${100 - (bb.Left + bb.Width) * 100}% ${100 - (bb.Top + bb.Height) * 100}% ${bb.Left * 100}%)`;
	}

	formatDate() {
		let self = this;
		return self.moment(this.snapshot.ts).local()
			.format('ll LTS');
	}

	ok() {
		this.$uibModalInstance.close();
	}

	// getVideo() {
	//   this.$uibModalInstance.close();
	//   this.$state.go('filemanager', {id: this.snapshot.site, cameras: [this.snapshot.camera], date: this.snapshot.ts})
	//   .catch(err => {
	//     console.error(err);
	//   })
	// }
	detectLabels() {
		let self = this;
		self.labelling = true;
		self.$http.get(`api/photos/detectLabels/${self.snapshot._id}`).then(response => {
			self.labelling = false;
			self.snapshot = response.data;
			self.toastr.success("Scene Analysis complete", {preventOpenDuplicates: true});
		});
	}

	/*popify() {*/
	/*this.$http.get('api/photos/listCollection').then(response => {*/
	/*});*/
	/*}*/

	loadDlibMore(id) {
		let self = this;
		self.$http.get(`api/photos/snapDlib/${id}`, {
			params: {
				snapId: self.snapshot._id,
				timestamp: +self.dlibSnaps[`${id}`][self.dlibSnaps[`${id}`].length - 1].ts
				//timestamp: +self.dlibSnaps[`${id}`][0].ts
			}
		}).then(response => {
			if(response.data.length > 0) {
				self.dlibSnaps[`${id}`] = self.dlibSnaps[`${id}`].concat(response.data);
			}
			if(response.data.length < 4) {
				self.toastr.warning("There are no more photos to be loaded", {
					preventOpenDuplicates: true
				});
				self.more = false;
			}
		});
	}

	loadPlateMore(id) {
		let self = this;
		let timestamp = new Date(self.tempSnaps[`${id}`][self.tempSnaps[`${id}`].length - 1].ts).getTime();
		self.$http.get(`api/photos/snapFaces/${id}`, {
			params: {
				snapId: self.snapshot._id,
				timestamp
				//timestamp: +self.tempSnaps[`${id}`][0].ts
			}
		}).then(response => {
			if(response.data.length > 0) {
				self.tempSnaps[`${id}`] = self.tempSnaps[`${id}`].concat(response.data);
			}
			if(response.data.length < 4) {
				self.toastr.warning("There are no more photos to be loaded", {
					preventOpenDuplicates: true
				});
				self.more = false;
			}
		});
	}

	loadMore(id) {
		let self = this;
		let timestamp = new Date(self.tempSnaps[`${id}`][self.tempSnaps[`${id}`].length - 1].ts).getTime();
		self.$http.get(`api/photos/snapFaces/${id}`, {
			params: {
				snapId: self.snapshot._id,
				timestamp
				//timestamp: +self.tempSnaps[`${id}`][0].ts
			}
		}).then(response => {
			if(response.data.length > 0) {
				self.tempSnaps[`${id}`] = self.tempSnaps[`${id}`].concat(response.data);
			}
			if(response.data.length < 4) {
				self.toastr.warning("There are no more photos to be loaded", {
					preventOpenDuplicates: true
				});
				self.more = false;
			}
		});
	}

	findDlibResults(id) {
		let self = this;
		if(self.snapshot.faceRecognitions.length > 0 && !self.dlibSnaps[`${id}`]) {
			self.populateDlib();
			self.$http.get(`api/photos/snapDlib/${id}`, {
				params: {
					snapId: self.snapshot._id,
					timestamp: +self.moment().utc()
				}
			}).then(response => {
				if(response.data.length > 0) {
					if(response.data.length < 4) {
						self.dlibMore = false;
					}
					let tempObj = response.data;
					self.dlibSnaps[`${id}`] = tempObj;
				}
			});
		}
		if(self.snapshot.faceRecognitions.length > 0 && !self.dlibRefs[`${id}`]) {
			self.$http.get(`api/photos/findDlibRefs/${id}`).then(response => {
				if(response.data.length > 0) {
					let face = response.data[0];
					self.$http({url:face.data,method:"GET",responseType:'arraybuffer'}).then( (res) => {
						let imageB64 = self.arrayBufferToBase64(res.data);
						let dataUrl = `data:image/jpg;base64,${imageB64}`;

						return dataUrl;
					} )
						.then( (dataUrl) => {
							let image = new fabric.Image.fromURL(dataUrl, function(image) {
								image.setOptions({
									left: 0,
									top: 0,
									opacity: 1,
									width: face.width,
									height: face.height
								});
								self.$timeout(function() {
									self.dlibRefs[`${id}`] = image.toDataURL({multiplier: 3, left: face.boundingBox.left, top: face.boundingBox.top, width: face.boundingBox.right - face.boundingBox.left, height: face.boundingBox.bottom - face.boundingBox.top});
								});
							});
						});
				}
			});
		}

		if(self.snapshot.faceRecognitions.length > 0 && !self.dlibCrop[`${id}`]) {
			self.$http({url:self.actualSnapshot.data,method:"GET",responseType:'arraybuffer'}).then( (res) => {
				let imageB64 = self.arrayBufferToBase64(res.data);
				let dataUrl = `data:image/jpg;base64,${imageB64}`;

				return dataUrl;
			} )
				.then( (dataUrl) => {
					let image = new fabric.Image.fromURL(dataUrl, function(image) {
						image.setOptions({
							left: 0,
							top: 0,
							opacity: 1,
							width: self.snapshot.width,
							height: self.snapshot.height
						});

						let recog = _.find(self.snapshot.faceRecognitions, recognition => {
							return recognition.faceId === id;
						});

						if(recog) {
							self.dlibCrop[`${id}`] = image.toDataURL({multiplier: 3,
								left: recog.boundingBox.left,
								top: recog.boundingBox.top,
								width: recog.boundingBox.right - recog.boundingBox.left,
								height: recog.boundingBox.bottom - recog.boundingBox.top});
						}
					});
				});
		}
	}

	doLPR() {
		let self = this;
		self.$http.get(`api/photos/lprDetect/${self.snapshot._id}`).then(response => {
			self.toastr.success("Photo added to LPR pipeline", {preventOpenDuplicates: true});
		});
	}

	findRecogResults(id) {
		let self = this;
		if(self.snapshot.snapRecognitions.length > 0 && !self.tempSnaps[`${id}`]) {
			self.populateWanted();
			self.$http.get(`api/photos/snapFaces/${id}`, {
				params: {
					snapId: self.snapshot._id,
					timestamp: +self.moment().utc()
				}
			}).then(response => {
				if(response.data.length > 0) {
					if(response.data.length < 4) {
						self.more = false;
					}
					let tempObj = response.data;
					this.tempSnaps[`${id}`] = tempObj;
				}
			});
		}
		if(this.snapshot.snapRecognitions.length > 0 && !this.tempRefs[`${id}`]) {
			this.$http.get(`api/photos/findRefs/${id}`).then(response => {

				// let index = _.findIndex(response.data[0].snapRecognitions,(obj)=>{
				//   return obj.faceId == id;
				// });
				if(response.data.length>0) {
					let face = response.data[0];
					self.$http({url:face.data,method:"GET",responseType:'arraybuffer'}).then( (res) => {
						let imageB64 = self.arrayBufferToBase64(res.data);
						let dataUrl = `data:image/jpg;base64,${imageB64}`;

						return dataUrl;
					} )
						.then( (dataUrl) => {
							let image = new fabric.Image.fromURL(dataUrl, function(image) {
								image.setOptions({
									left: 0,
									top: 0,
									opacity: 1,
									width: face.width,
									height: face.height
								});
								self.$timeout(function() {
									self.tempRefs[`${id}`] = image.toDataURL({multiplier: 3, left: face.boundingBox.Left*face.width, top: face.boundingBox.Top*face.height, width: face.boundingBox.Width*face.width, height: face.boundingBox.Height*face.height});
								});
							});
						});
				}
			});
		}

		if(this.snapshot.snapRecognitions.length > 0 && !this.tempCrop[`${id}`]) {
			self.$http({url:self.actualSnapshot.data,method:"GET",responseType:'arraybuffer'}).then( (res) => {
				let imageB64 = self.arrayBufferToBase64(res.data);
				let dataUrl = `data:image/jpg;base64,${imageB64}`;

				return dataUrl;
			} )
				.then( (dataUrl) => {
					let image = new fabric.Image.fromURL(dataUrl, function(image) {
						image.setOptions({
							left: 0,
							top: 0,
							opacity: 1,
							width: self.snapshot.width,
							height: self.snapshot.height
						});
						let recog = _.find(self.snapshot.snapRecognitions, (recognition) => {
							return recognition.faceId === id;
						});
						if(recog) {
							self.tempCrop[`${id}`] = image.toDataURL({multiplier: 3, left: recog.boundingBox.Left*self.snapshot.width, top: recog.boundingBox.Top*self.snapshot.height, width: recog.boundingBox.Width*self.snapshot.width, height: recog.boundingBox.Height*self.snapshot.height});
						}
					});
				});
		}
	}

	// searchUser() {
	// 	this.$http.get(`api/photos/searchFaces/${this.snapshot._id}`).then(response => {
	// 	});
	// }

	formatSnapDate(date) {
		let self = this;
		return self.moment(date).local()
			.format('ll LTS');
	}

	faceRecall() {
		let self = this;
		self.$http.get(`api/photos/faceRecall/${self.snapshot._id}`).then(response => {
			self.recalling = true;
			self.toastr.info("Snapshot added to FaceRecall pipeline", {preventOpenDuplicates: true});
			//if (response.data.IndexFaces.FaceRecords.length>0){
			//self.toastr.success("FaceRecall completed succesfully", {preventOpenDuplicates: true})
			//self.drawCanvas();
			//self.snapshot.snapRecognitions = response.data.up.snapRecognitions;
			//self.tempRecognitions = response.data.up.snapRecognitions;
			//this.tempRecognitions.forEach(recog=>{
			//recog['tempSimilarity'] = Math.floor(recog.similarity);
			//this.findRecogResults(recog.faceId);
			//});
			//}else{
			//self.toastr.error("FaceRecall could not find any faces", {preventOpenDuplicates: true})
			//}
		});
	}

	populatePlate() {
		let self = this;
		let query = [];
		self.snapshot.lprResults.forEach(obj => {
			query.push(obj.plate);
		});
		self.$http.get('api/plates/populateWanted', {params: {plates: query}})
			.then(response => {
				response.data.forEach(obj => {
					self.plateWanted[obj.plate] = obj;
					let snapInd = _.findIndex(self.snapshot.lprResults, o => {
						return o.plate == obj.plate;
					});
					if(snapInd !== -1) {
						self.snapshot.lprResults[snapInd].metagratedInfo = obj.metagratedInfo;
					}
				});
			});
	}

	findPlateResults(plate) {
		let self = this;
		if(self.snapshot.lprResults.length > 0 && !self.lprSnaps[`${plate}`]) {
			self.populatePlate();
			self.$http.get(`api/photos/snapPlates/${plate}`, {
				params: {
					snapId: self.snapshot._id,
					ts: +self.moment().utc()
				}
			}).then(response => {
				if(response.data.length > 0) {
					if(response.data.length < 4) {
						self.plateMore = false;
					}
					let tempObj = response.data;
					self.plateSnaps[`${plate}`] = tempObj;
				}
			});
		}
	}

	verifyPlate(correct, index) {
		let self = this;
		if(correct) {
			self.snapshot.lprResults[index].userVerified = true;
			self.snapshot.lprResults[index].userCorrect = true;
			self.$http.patch(`api/photos/${self.snapshot._id}`, {lprResults:self.snapshot.lprResults}).then(response => {
			});
		} else {
			self.snapshot.lprResults[index].userVerified = true;
			self.snapshot.lprResults[index].userCorrect = false;
			self.$http.patch(`api/photos/${self.snapshot._id}`, {lprResults:self.snapshot.lprResults}).then(response => {
			});
			//self.$http.plate.patch();
		}
	}

	correctPlate(plate) {
		let self = this;
		self.$ngConfirm({
			title: "Verify Plate",
			escapeKey: false,
			backgroundDismiss: false,
			scope: self.$scope,
			content:
			`
			Please enter the correct licence plate number:
			<input ng-model="$ctrl.correctedPlate" type="text"> </input>
			`,
			buttons:{
				enter: {
					text: "Enter",
					btnClass: 'btn-blue',
					action(scope, button) {
						if(self.correctedPlate) {
							self.updatePlateResult(plate.plate, self.correctedPlate, false);
							self.correctedPlate = undefined;
							return;
						} else {
							self.toastr.warning("Plate cannot be empty when correcting", {preventOpenDuplicates:true});
							return false;
						}
					}
				},
				noPlate: {
					text: "Not a Plate",
					btnClass: 'btn-red',
					action(scope, button) {
						self.updatePlateResult(plate.plate, undefined, true);
						self.correctedPlate = undefined;
						return;
					}
				},
				close(scope, button) {
				}
			}
		});
	}

	updatePlateResult(oldPlate, newPlate, noPlate) {
		let self = this;
		if(noPlate) {
			self.$http.patch(`/api/photos/updatePlateResult/${self.snapshot._id}`, {oldPlate, noPlate, newPlate}).then(response => {
				self.toastr.info("LPR Results Updated Succesfully", {preventOpenDuplicates:true});
			});
		} else {
			self.$http.patch(`/api/photos/updatePlateResult/${self.snapshot._id}`, {oldPlate, noPlate, newPlate}).then(response => {
				self.toastr.info("LPR Results Updated Succesfully", {preventOpenDuplicates:true});
			});
		}
	}

	arrayBufferToBase64(buffer) {
		var binary = '';
		var bytes = new Uint8Array(buffer);
		var len = bytes.byteLength;
		for (var i = 0; i < len; i++) {
			binary += String.fromCharCode(bytes[i]);
		}
		return window.btoa(binary);
	}

	toggleAnnotations() {
		let self = this;
		if(self.snapshot.annotations && self.snapshot.annotations) {
			self.showAnnotations = !self.showAnnotations;
			if(self.showAnnotations) {
				self.addAnnotations(self.snapshot.annotations);
			}else{
				self.removeAnnotations();
			}
		}
	}

	addAnnotations(annotations) {
		let self = this;
		annotations.forEach( (annotation) => {
			let currentPoint;
			annotation.path.forEach( (point) => {
				if(!currentPoint) {
					currentPoint = point;
				}else{
					let line = new fabric.Line([
						currentPoint[0]*self.canvas.width,
						currentPoint[1]*self.canvas.height,
						point[0]*self.canvas.width,
						point[1]*self.canvas.height
					],{
						strokeWidth: 7,
						stroke: `${annotation.color}`,
						originX: 'center',
						originY: 'center',
						perPixelTargetFind: true,
						selectable: false,
						targetFindTolerance: 10,
						//padding: 4,
						hasControls: false
					});
					self.canvas.add(line);
					self.annotations.push(line);
					currentPoint = point;
				}
			} );
		} );
		self.canvas.renderAll();
	}

	removeAnnotations() {
		let self = this;
		self.annotations.forEach( (annotation) => {
			self.canvas.remove(annotation);
		} );
		self.annotations = [];
	}
}
