import angular from 'angular';

export class PTTPanel {
	/*@ngInject*/
	constructor(moment, $timeout, $uibModal, siteService, routeService, eventService, currentEventService, $filter, webRTCService, unitService, userService, Auth, socket, groupService) {
		this.moment = moment;
		this.$timeout = $timeout;
		this.$uibModal = $uibModal;
		this.$filter = $filter;
		this.siteService = siteService;
		this.eventService = eventService;
		this.routeService = routeService;
		this.groupService = groupService;
		this.currentEventService = currentEventService;
		this.filter = '';
		this.webRTCService = webRTCService;
		this.unitService = unitService;
		this.userService = userService;
		this.socket = socket;
		this.Auth = Auth;
		this.users = [];
		this.units = [];
		this.groups = [];
		this.showDropdown = false;
		this.onlineOnlyUnits = true;
		this.onlineOnlyUsers = true;
		this.showOffline = false;
		this.activeTab = 'activeCalls';
		this.activeCallCount = 0;
	}

	$onInit() {
		let self = this;
		let promises = [];

        let unitPromise = self.unitService.registerListener(self, (event, item, array) => {
            return self.onReceiptOfUnit(event,item);
        }).then( (units) => {
			self.units = units;
			return new Promise( (res,rej) => {
				return self.groupService.registerListener(self, (event, item, array) => {
					return self.onReceiptOfGroup(event,item);
				}).then( (groups) => {
					groups.forEach( (group) => {
						self.onReceiptOfGroup('created',group);
					} );
					res();
					return null;
				} )
.catch( (err) => {
					rej(err);

				} );

			} );
        } );

		let userPromise = self.Auth.getCurrentUser().then( (user) => {
			self.currentAccountId = self.Auth.getCurrentAccountSync().ref;
			self.socket.syncUpdates('user', self.users, self.onReceiptOfUser.bind(self), self);
			user.accounts.forEach( (account) => {
				self.socket.joinRoom(`${account.ref}:*:users`);
			} );
			return self.userService.getUsersWithQuery({
				//allAccounts: true,
				pttOnly:true
			}).then((results) => {
				let users = results.data;
				let currentUser = _.remove(users, (o) => {
					if(o._id == user._id) {
						return true;
					}else{
						return false;
					}
				});
				self.currentUser = currentUser[0];
				self.users = users;
				self.webRTCService.users = self.users;
				return null;
			});
		} );
        //let userPromise = self.userService.registerListener(self, (event, item, array) => {
            //return self.onReceiptOfUser(event,item);
        //}).then( (users) => {
			//let user = self.Auth.getCurrentUserSync();

			//let currentUser = _.remove(users, (o) => {
				//if(o._id == user._id){
					//return true;
				//}else{
					//return false;
				//}
			//});
			//self.currentUser = currentUser[0];
			//self.users = users;
			//return null;
        //} ).catch(err => {
            //console.error('Error getting events', err);
        //});
		promises.push(userPromise);
		Promise.all(promises).then( () => {
			return self.webRTCService.connectToSignallingServer().then( () => {
				self.activeCalls = self.webRTCService.peers;
				return null;
			} )
.catch( (err) => {
				console.error(err);
			} );
		});
	}

	$onDestroy() {
		let self = this;
		self.unitService.unregisterListener(self, true);

		self.socket.unsyncUpdates('user', self);
		let user = self.Auth.getCurrentUserSync();
		if(user && user.accounts) {
			user.accounts.forEach( (account) => {
				self.socket.leaveRoom(`${account.ref}:*:users`);
			} );
		}
		self.webRTCService.disconnect();
	}

	onReceiptOfUnit(event,unit) {
		let self = this;

		let index = _.findIndex(self.units, (storedUnit) => {
			return storedUnit._id === unit._id;
		});

		if(index !== -1) {
			if(event == 'deleted') {
				self.units.splice(index, 1);
			}else {
				self.units.splice(index, 1, unit);
				self.updateUnitInGroups(unit);
			}
		}
	}

	updateUnitInGroups(unit) {
		let self = this;
		self.groups.forEach( (group) => {
			let index = _.findIndex(group.units, (storedUnit) => {
				return storedUnit._id === unit._id;
			});
			if(index !== -1) {
				group.units.splice(index, 1, unit);
			}
		} );
	}

	onReceiptOfGroup(event,group) {
		let self = this;

		let index = _.findIndex(self.groups, (storedGroup) => {
			return storedGroup._id === group._id;
		});

		if(index !== -1) {
			if(event == 'deleted') {
				self.groups.splice(index, 1);
			}else {
				let formattedGroup = self.addUnitsToGroup(group);
				self.groups.splice(index, 1, formattedGroup);
			}
		}else{
			let formattedGroup = self.addUnitsToGroup(group);
			self.groups.push(formattedGroup);
		}
	}

	addUnitsToGroup(group) {
		let self = this;
		group.units.forEach( (u, index) => {
			let unitId;
			if(u.unit) {
				unitId = u.unit;
			}else if(u._id) {
				unitId = u._id;
			}
			let unit = _.find(self.units, (o) => {
				return o._id == unitId;

			});
			group.units[index] = unit;
		} );
		return group;
	}

	onReceiptOfUser(event,user) {
		let self = this;

		let myId = self.Auth.getCurrentUserSync()._id;

		if(myId == user._id) {
			self.currentUser = user;
			return null;
		}
		let index = _.findIndex(self.users, (storedUser) => {
			return storedUser._id === user._id;
		});

		if(index !== -1) {
			if(event == 'deleted') {
				self.users.splice(index, 1);
			}else {
				self.users.splice(index, 1, user);
			}
		}
	}

	createCall(id, type) {
		let self = this;
		self.webRTCService.startupSession(id, type);
	}

	toggleDropdown() {
		let self = this;
		self.showDropdown = !self.showDropdown;

	}

	endCall(sessionId) {
		let self = this;
		self.webRTCService.endCall(sessionId);

	}

	acceptCall(sessionId) {
		let self = this;
		self.webRTCService.answerCall(sessionId);

	}

	declineCall(sessionId) {
		let self = this;
		self.webRTCService.declineCall(sessionId);

	}

	toggleShowOffline() {
		let self = this;
		self.showOffline = !self.showOffline;
	}

	leaveRoom(roomId) {
		let self = this;
		self.webRTCService.leaveRoom(roomId);
	}

	stopProp($event) {
		let self = this;
		$event.preventDefault();
		$event.stopPropagation();

	}

	doLog() {
		console.debug(this);
	}

	countOnlineUnits(group) {
		let self = this;
		let onlineCount = _.countBy(group.units,'ssConnected');
		return onlineCount;
	}


}

export default angular.module('directives.pttPanel', [])
	.component('pttPanel', {
		template: require('./ptt-panel.html'),
		controller: PTTPanel,
		controllerAs: "$ctrl",
		bindings: {

		}
	})
	.filter('formatState', function() {
		return function(state) {
			let formattedState = state;
			switch (state) {
				case 'pendingAnswer':
					return false;
					break;
				case 'callAccepted':
					return false;
					break;
				case 'connected':
					return false;
					break;
				case 'calling':
					return 'Calling';
					break;
				case 'callFailed':
					return 'Call Ended';
					break;
				default:
					return false;
			}
		};
	})
	.filter('showOffline', function() {
		return function(data, show,type,accountId) {
			if(show) {
				return data;
			}else if(type == 'group') {
				return _.filter(data, (o) => {
					return _.find(o.units,['ssConnected',true]);
				});
			}else if(type == 'user') {
				return _.filter(data, (user) => {
					return user.ssConnected && user.activeAccount == accountId;

				});
			}else if(type == 'unit') {
				return _.filter(data, ['ssConnected',true]);
			}
		};
	})
	.filter('keyLength', function() {
		return function(data, sessionOnly) {
			if(sessionOnly) {
				let tempData = _.filter(data, (o) => {
					return o.sessions && o.sessions.length > 0;
				});
				if(tempData && Object.keys(tempData).length > 0) {
					return Object.keys(tempData).length;
				}else{
					return 0;
				}

			}else if(data && Object.keys(data).length > 0) {
					return Object.keys(data).length;
				}else{
					return 0;
				}
		};
	})
	.name;
