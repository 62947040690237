export class SlotDefinitionModalController {
	$stateParams;
	Restangular;
	$uibModalInstance;
	settings;
	availableGroups;
	contact;
	Contacts;
	modalInstance;

	/*@ngInject*/
	constructor($stateParams, Restangular) {
		this.$stateParams = $stateParams;
		this.Restangular = Restangular;
	}

	$onInit() {
		let self = this;
		self.settings = self.resolve.settings;
		self.templateId = self.resolve.settings.templateId;
		self.template = self.resolve.settings.allTemplates[self.templateId];
		// self.maxAssets = self.resolve.settings.maxAssets;
		self.$uibModalInstance = self.modalInstance;
		// Add alert data to controller
		self.Contacts = self.Restangular.all('contacts');
		self.Units = self.Restangular.all('units');
		self.Groups = self.Contacts.all('groups');
		self.availableGroups = [];
		self.availableUnits = [];
		self.availableAssets = [];
		// if(self.maxAssets > 0 && self.template.length == 0){
		// 	self.template.push({});
		// }
		self.refreshGroups();
		self.refreshUnits(self.template.unit || '');
		self.refreshAssets(self.template.asset || '');
	}


	refreshGroups() {
		let self = this;
		if(self.$stateParams.accountID) {
			self.Groups.getList({
				accountID: self.$stateParams.accountID,
				assetOnly : true
			}).then(function(groups) {
				self.availableGroups = groups;
			})
.catch((err) => {
				console.error(err);
			});
		}
	}

	refreshAssets(searchVal) {
		let self = this;
		self.Contacts.getList({
			assetsOnly : true,
			filter : searchVal,
			limit : 20,
		}).then((assets) => {
			self.availableAssets = assets;
		})
.catch((err) => {
			console.error(err);
		});
	}

	refreshUnits(searchVal) {
		let self = this;
		self.Units.getList({
			filter : searchVal,
			limit : 20,
		}).then((units) => {
			self.availableUnits = units;
		})
.catch((err) => {
			console.error(err);
		});
	}

	addAsset() {
		let self = this;
		self.template.push({});
		// if(self.maxAssets > self.template.length){
		// }
	}

	removeAsset(index) {
		let self = this;
		if(self.template[index]) {
			self.template.splice(index,1);
		}
	}

	// Setup all the models for display
	// Modal accept: send resulting object back to promise in parent controller
	ok() {
		let self = this;
		let result = self.template;
		self.$uibModalInstance.close(result);
	}

	setAssetName(slot) {
		let self = this;
		if(!slot.asset) {
			return null;
		}
		let asset = _.find(self.availableAssets, (o) => {
			return slot.asset == o._id;
		});
		if(!asset) {
			console.error('Asset not found : ', slot.asset);
			return null;
		}
		slot.assetname = `${asset.firstname} ${asset.lastname}`;
	}

	// onGroupAdded(){
	// 	let self = this;
	// 	let label = " (create new group)";
	// 	let tempGroups = [];
	// 	_.forEach(self.contact.groups, (group) => {
	// 		if (group.slice(-(label.length)) === label) {
	// 			group = group.slice(0, -(label.length));
	// 		}
	// 		tempGroups.push(group);
	// 	});
	// 	// NOTE: I commented out the code below and substituted it for the _.forEach above
	// 	// result.groups.forEach((group) => {
	// 	// 	if (group.slice(-(label.length)) === label) {
	// 	// 		group = group.slice(0, -(label.length));
	// 	// 	}
	// 	// 	tempGroups.push(group);
	// 	// });
	// 	self.contact.groups = tempGroups;
	// }

	// onIdAdded(){
	// 	let self = this;
	// 	if(!self.contact.ids){
	// 		self.contact.ids = [];
	// 	}
	// 	self.contact.ids.push({type:self.availableIds[0],id:''});
	// }
	//
	// removeId(index){
	// 	let self = this;
	// 	self.contact.ids.splice(index,1);
	// }
	//
	// validateId(index){
	// 	let self = this;
	// 	let thisValid = true;
	// 	let id = self.contact.ids[index];
	// 	if(typeof id.id === 'string'){
	// 		switch (id.type) {
	// 			case 'iButton':
	// 			let matchArray = id.id.match(/^(0x|0X)?[a-fA-F0-9]{16}$/);
	// 			if(!id.id == ""){
	// 				if(!matchArray || matchArray.length == 0){
	// 					thisValid = false;
	// 				}
	// 			}
	// 			break;
	// 		}
	// 	}
	// 	return thisValid;
	// }

	// Modal cancelled: either cancel pressed or clicked off screen
	cancel() {
		this.$uibModalInstance.dismiss('Cancel pressed');
	}
}

export default angular.module('secutraqApp.scheduling')
.component('slotdefinitionmodal', {
	template: require('./slot-definition.modal.html'),
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&',
		modalInstance: '<'
	},
	controller: SlotDefinitionModalController,
	controllerAs: '$ctrl'
})
.name;
// .controller(ContactModalController).name;
