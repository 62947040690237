export class MainheadingsComponent {

    /*@ngInject*/
    constructor(mapService, moment, $timeout) {
        this.mapService = mapService;
        this.moment = moment;
		this.$timeout = $timeout;

    }

    $onInit() {
        this.mapService.getMetaData().then((metaData) => {
            this.metaData = metaData;
        });
    }

    onMainHeadingClicked(eventType,eventDetail) {
        let self = this;

        if(eventType === 'online') {
            self.openSubtabsTo('Events');
            self.openEventsAccordionTo('units');
        } else {
            self.openSubtabsTo('Events');
            self.openEventsAccordionTo('inbox');
            self.openInboxAccordionTo(eventType);
        }
        if(eventDetail.subEvents && eventDetail.subEvents.length > 0) {
            self.filterMarkers(eventDetail);
            eventDetail.subEvents.forEach((o) => {
                self.filterMarkers(self.eventDetails[o],o);
            });
        }else {
            self.filterMarkers(eventDetail,eventType);
        }
        let unfilteredEventTypes = [];
        if(_.some(self.eventDetails,'filtered')) {
            _.forEach(self.eventDetails, (detail,key) => {
                if(!detail.filtered) {
                    if(detail.subEvents && detail.subEvents.length > 0) {
                        unfilteredEventTypes = unfilteredEventTypes.concat(detail.subEvents);
                    }else {
                        unfilteredEventTypes.push(key);
                    }
                }
            });
        }else {
            unfilteredEventTypes = _.reduce(self.eventDetails,(arr,o,k) => {
                if(o.subEvents && o.subEvents.length > 0) {
                    return arr.concat(o.subEvents);
                }else {
                    arr.push(k);
                    return arr;
                }
            },[]);
        }
        self.mapService.actionMainHeading(unfilteredEventTypes);
        // if(self.metaData.activeTab !== "Events") {
        //     //Events tab is closed, so we open to the clicked event
        //     self.openInboxAccordionTo(eventType);
        //     self.openEventsAccordionTo('inbox');
        // }
        // else {
        //     if(self.metaData.inboxAccordion[eventType] !== true) {
        //         //this heading is not opened currently, so we open it
        //         self.openEventsAccordionTo('inbox');
        //         self.openInboxAccordionTo(eventType);
        //     }
        //     else {
        //         //this heading is currently probably opened, so we close it
        //         // self.closeInboxAccordionAt(eventType);
        //     }
        // }
    }

    openInboxAccordionTo(eventType) {
        let self = this;
        self.openSubtabsTo('Events');
		if(self.metaData.openEventsGroupBy == 'eventType') {
			self.metaData.inboxAccordion.NoId[eventType] = true;
		}
    }

    openEventsAccordionTo(option) {
        let self = this;
        self.metaData.eventsAccordion[option] = true;
    }

    openSubtabsTo(title) {
        let self = this;
        self.metaData.activeTab = title;
    }

    closeInboxAccordionAt(eventType) {
        let self = this;
        self.metaData.inboxAccordion[eventType] = false;
    }

    /**
     * This function is responsible for filtering makers on the map
     * @see mapService.addToFilteredMarkers();
     */
    filterMarkers(eventDetail, eventType) {
        let self = this;
        let someFiltered = _.some(self.eventDetails, 'filtered');
        if(!someFiltered) {
            _.forEach(self.eventDetails,(e) => {
                e.filtered = true;
            });
        }
        if(eventDetail.filtered) {
            if(eventType) {
                self.mapService.addToFilteredMarkers(eventType);
            }
            eventDetail.filtered = false;
        } else {
            if(eventType) {
                self.mapService.removeFromFilteredMarkers(eventType);
            }
            eventDetail.filtered = true;
        }

        let allFiltered = _.every(self.eventDetails, 'filtered');
        let noneFiltered = _.every(self.eventDetails, (e) => {
            if(e.filtered) {
                if(e.hidden) {
                    return true;
                }else {
                    return false;
                }
            }else {
                return true;
            }
        });
        if(allFiltered) {
            _.forEach(self.eventDetails,(e) => {
                e.filtered = false;
            });
            self.mapService.showAllMarkers();
        }else if(noneFiltered) {
            _.forEach(self.eventDetails,(e) => {
                e.filtered = false;
            });
            _.forEach(self.eventDetails,(o,k) => {
                self.mapService.removeFromFilteredMarkers(k);
            });
            self.mapService.showAllMarkers();
        }
    }


	toggleHeadingsShow() {
		let self = this;
		self.hideHeadings = !self.hideHeadings;
		self.$timeout( () => {
			$(window).trigger('resize.mapView');
		} );
	}

}
export default angular.module('secutraqApp.dashboard')
.component('appMainheadings', {
    template: require('./mainheadings.component.html'),
    controller: MainheadingsComponent,
    controllerAs: '$ctrl',
    bindings: {
        eventDetails: '=',
        eventStack: '='
    }
})
.name;
