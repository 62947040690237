

import { Calendar } from '@fullcalendar/core';
//import adaptivePlugin from '@fullcalendar/adaptive';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import momentPlugin from '@fullcalendar/moment'
//import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
//import resourceTimelinePlugin from '@fullcalendar/resource-timeline';


export class uiCalendarComponent {
	/*@ngInject*/
	constructor(moment, $scope, $timeout, $compile, $uibModal, siteService, routeService, Restangular, scheduleService, Auth, $sanitize) {
		this.moment = moment;
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.$compile = $compile;
		this.$sanitize = $sanitize;
		this.$uibModal = $uibModal;
		this.siteService = siteService;
		this.routeService = routeService;
		this.scheduleService = scheduleService;
		this.Restangular = Restangular;
		this.Auth = Auth;

		// this.uiCalendarConfig = uiCalendarConfig;

		this.eventSources = [];
		this.tempCalendarEvents = [];
		if(!this.calendarId) {
			this.calendarId = 'defaultCalendarId';
		}
	}

	$onInit() {
		let self = this;
		self.hasEditPriv = self.Auth.hasRoleSync('edit');
		self.calendarOptions = {
			plugins:[dayGridPlugin,timeGridPlugin, interactionPlugin, momentPlugin],
			height: "auto",
			nowIndicator:true,
			selectable:true,
			editable: false,
			dayMaxEventRows:10,
			dayMaxEvents:10,
			nextDayThreshold:"00:00:00",
			headerToolbar: false,
			events:self.events,
			eventClick: (info) => {
				self.editEvent(info.event.extendedProps._id);
			},
		};
		if(self.options) {
			_.merge(self.calendarOptions,self.options);
		}
		self.$timeout(() => {
			var calendarEl = document.getElementById(self.calendarId);
			self.calendar = new Calendar(calendarEl,self.calendarOptions);
			self.calendar.render();
		});
		self.refreshCalendarEvents = _.debounce(() => {
			self.calendar.removeAllEvents();
			self.calendar.removeAllEventSources();
			self.calendar.addEventSource({id:'myEvents',events:self.events});
		},500);
		self.$scope.$watch(() => {
			return self.events;
		},() => {
			self.refreshCalendarEvents();
		},true);
		//
		self.$scope.$watch('$ctrl.view',() => {
			self.$timeout(() => {
				let view = self.view;
				if(self.view == 'month'){
					view = 'dayGridMonth'
				}else if(self.view == 'week'){
					view = 'timeGridWeek'
				}else if(self.view == 'agendaWeek'){
					view = 'timeGridWeek'
				}
				if(self.calendar && (self.view !== 'day' && self.view !== 'list')) {
					self.calendar.changeView(view);
				}

			});
		});

		self.$scope.$watch('$ctrl.viewDate',() => {
			self.$timeout(() => {
				if(self.calendar) {
					let date = self.viewDate;
					if(typeof self.viewDate.toDate == 'function'){
						date = self.viewDate.toDate();
					}
					self.calendar.gotoDate(date);
				}
			});
		});
		//
		// $onInit() end
	}

	editEvent(eventId) {
		let self = this;
		if(self.options && self.options.editFunction && typeof self.options.editFunction === 'function') {
			let event = _.find(self.events,{_id:eventId});
			if(event) {
				self.options.editFunction(event);
			}else {
				throw new Error(`Event with id : ${eventId} not found in events.`);
			}
		}else if(self.options && self.options.editFunction) {
				throw new Error(`Edit function is not a function : ${typeof self.options.editFunction}`);
			}else{
				throw new Error(`No edit function provided.`);
			}
	}

	$onDestroy() {

	}

	doLog() {
		console.debug(this);
	}


}

export default angular.module('secutraqApp.scheduling')
	.component('uiCalendar', {
		template: require('./ui-calendar.html'),
		controller: uiCalendarComponent,
		controllerAs: "$ctrl",
		bindings: {
			events: '=',
			view: '<',
			viewDate: '<',
			viewTitle: '<',
			options: '<',
			calendarId : '@',
			calendar: '='
		}
	});
