'use strict';
import angular from 'angular';

export class ConsentComponent {
    /*@ngInject*/
    constructor($scope,Auth,$http, toastr) {
        this.$scope = $scope;
		this.Auth = Auth;
		this.$http = $http;
		this.toastr = toastr;
    }

    $onInit() {
        let self = this;
		// TODO: Check privacy and t&cs update dates <28-06-21, Liaan> //
    }

    $onDestroy() {

    }

	ok() {
		let self = this;
		// TODO: Save consent date <28-06-21, Liaan> //
		let update = {
			consent:{
				privacy: new Date(),
				terms: new Date(),
				disclaimer: new Date(),
			}
		};

		self.$http.patch('/api/users/me', update)
			.then( () => {
				self.modalInstance.close();
			} )
			.catch( (err) => {
				self.toastr.error('Policy accept failed, please try again');
				console.error(err);
			} );
	}

	doLog() {
		let self = this;
		console.debug(this);
	}

	cancel() {
		let self = this;
		// TODO: Logout and deny access <28-06-21, Liaan> //
		self.Auth.logout();
		self.modalInstance.close();
	}

}

export default angular.module('directives.consent', [])
	.directive('onScrollToBottom', function($document) {
		//This function will fire an event when the container/document is scrolled to the bottom of the page
		return {
			restrict: 'A',
			link(scope, element, attrs) {

				var doc = element[0];

				element.bind("scroll", function() {
					if (doc.scrollTop + doc.offsetHeight >= doc.scrollHeight) {
						//run the event that was passed through
						scope.$apply(attrs.onScrollToBottom);
					}
				});
			}
		};
	})
    .component('consent', {
        template: require('./consent.html'),
        controller: ConsentComponent,
        controllerAs: '$ctrl',
		bindings: {
			modalInstance: '=',
			resolve: '='
		}
    })
    .name;
