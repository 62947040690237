export default function($stateProvider) {
    'ngInject';
    $stateProvider
    .state('main.sops', {
        url: '/sop',
        views: {
            'content@main' : {
                template: '<sops></sops>'
            }
        },
        authenticate: true,
        data: {
            title: 'SOP.'
        }
    });

    // .state('main.sites.detail', {
    //     url: '/:siteID',
    //     // views: {
    //     //     'site-detail' : {
    //     //         template: '<site-detail></site-detail>'
    //     //     }
    //     // },
    //     params: {
    //         selectedSite: null,
    //         sites: null,
    //         siteID: null
    //     },
    //     authenticate: true,
    //     data: {
    //         title: 'Detail'
    //     }
    // })

    // .state('main.configure.route', {
    //     url: '/route',
    //     views: {
    //         'config@main.configure' : {
    //             template: '<route></route>'
    //         }
    //     },
    //     authenticate: true,
    //     data: {
    //         title: 'Route'
    //     }
    // })

    // .state('main.configure.detail', {
    //     url: '/detail',
    //     views: {
    //         'config@main.configure' : {
    //             template: '<site-detail></site-detail>'
    //         }
    //     },
    //     authenticate: true,
    //     data: {
    //         title: 'Detail'
    //     }
    // })


}
