export class EventService {
	/*@ngInject*/
	constructor(
		moment,
		$interval,
		Restangular,
		socket,
		Auth,
		appConfig,
		photoService,
		customEventTypeService,
		$state,
		$ngConfirm,
		accountService,
		siteService,
		$uibModal
	) {
		this.moment = moment;
		this.$interval = $interval;
		this.events = [];
		this.rooms = [];
		this.eventStack = {};
		this.closedEventStack = {};
		this.customEventGroupingCache = {};
		this.listenerMap = new Map();
		this.socket = socket;
		this.Auth = Auth;
		this.Restangular = Restangular;
		this.cacheTimeout = undefined;
		this.lastAccount = undefined;
		this.lastUser = undefined;
		this.joined = false;
		this.appConfig = appConfig;
		this.photoService = photoService;
		this.$state = $state;
		this.$ngConfirm = $ngConfirm;
		this.accountService = accountService;
		this.siteService = siteService;
		this.$uibModal = $uibModal;

		this.customEventTypeService = customEventTypeService;

		this.eventTypes = _.cloneDeep(appConfig.eventTypes);
		this.eventTypes.push("online");
		_.forEach(this.eventTypes, (eventType) => {
			this.eventStack[eventType] = [];
		});
		//this.eventDetails = {};
	}

	$onInit() { }

	onSocketEvent(event, item, array) {
		let self = this;
		self.events = array;
		self.listenerMap.forEach((value, key) => {
			setTimeout(() => {
				value(event, item, array);
			}, 0);
		});
	}

	registerListener(context, cb, options) {
		let self = this;
		if (!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
		if (!self.lastUser) {
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		} else if (self.lastUser !== self.Auth.getCurrentUserSync()._id) {
			self.destroyService();
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		}
		let count = self.listenerMap.size;
		self.listenerMap.set(context, cb);
		// If listenermap was empty, (TODO and timer isn't running), join room, syncupdates
		if (!self.joined) {
			if (self.cacheTimeout) {
				clearTimeout(self.cacheTimeout);
				self.cacheTimeout = undefined;
			}
			return self.getEvents(options).then((event) => {
				let account = self.Auth.getCurrentAccountSync();
				self.socket.syncUpdates(
					"event",
					self.events,
					self.onSocketEvent.bind(self),
					self
				);
				self.socket.socket.on(
					"event:reload",
					self.reloadEvents.bind(self)
				);

				let filter = {};
				let hasPrivilege = self.Auth.hasPrivilegeSync(
					"event.index",
					undefined,
					filter
				);
				if (
					hasPrivilege &&
					filter &&
					filter.filterParam &&
					filter.filterParam.field == "groups"
				) {
					filter.filterParam.data.forEach((group) => {
						self.socket.joinRoom(
							`${self.Auth.getCurrentAccountSync().ref
							}:*:events:${group}`
						);
						self.rooms.push(
							`${self.Auth.getCurrentAccountSync().ref
							}:*:events:${group}`
						);
					});
				} else {
					self.socket.joinRoom(
						`${self.Auth.getCurrentAccountSync().ref}:*:events`
					);
					self.rooms.push(
						`${self.Auth.getCurrentAccountSync().ref}:*:events`
					);
				}
				self.joined = true;
				return event;
			});
		} else {
			if (self.cacheTimeout) {
				clearTimeout(self.cacheTimeout);
				self.cacheTimeout = undefined;
			}
			return self.getEvents(options);
		}
	}

	destroyService() {
		let self = this;
		if (self.joined) {
			self.socket.unsyncUpdates("event", self);
			self.socket.socket.removeListener(
				"event:reload",
				self.reloadEvents
			);
			if (self.rooms && self.rooms.length > 0) {
				self.rooms.forEach((room) => {
					self.socket.leaveRoom(room);
				});
				self.rooms = [];
			}
		}
		self.joined = false;
		self.events = [];
		self.eventDetails = {};
		self.clearEventStack();
		self.listenerMap.clear();
		clearTimeout(self.cacheTimeout);
		self.cacheTimeout = undefined;
	}

	unregisterListener(context, cb) {
		let self = this;
		if (!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
		if (!self.lastUser) {
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		} else if (self.lastUser !== self.Auth.getCurrentUserSync()._id) {
			self.destroyService();
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		}
		self.listenerMap.delete(context);
		if (self.listenerMap.size === 0) {
			self.cacheTimeout = setTimeout(
				self.destroyService.bind(self),
				5000
			);
		}
	}

	unregisterAll() {
		let self = this;
		if (!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
		if (!self.lastUser) {
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		} else if (self.lastUser !== self.Auth.getCurrentUserSync()._id) {
			self.destroyService();
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		}
		self.listenerMap.clear();
		if (self.listenerMap.size === 0) {
			self.cacheTimeout = setTimeout(
				self.destroyService.bind(self),
				5000
			);
		}
	}

	// actionEvent(event) {
	//     let self = this;
	//
	//     let index = _.findIndex(self.eventStack, (event) => { return event._id === actionedEvent._id; });
	//
	//     if(index !== -1) {
	//         // event.leave = true;
	//         self.$timeout(() => {
	//             // self.mapService.removeMarkerFromMap(currentEvent._id, currentEvent.unit._id);
	//             eventStack.splice(index, 1);
	//             self.clearCurrentEvent();
	//
	//             self.eventCarouselService.closeCarouselWidget();
	//         }, 250);
	//     }
	//     else {
	//         console.error("Event to action cannot be found");
	//     }
	//     let newEvent = _.mergeWith(actionedEvent, {sop: "Add the SOP entered here", active: false}, function(objValue, srcValue) {
	//         if(_.isArray(objValue)) {
	//             return srcValue;
	//         }
	//     }); //going to add all the additional info we need and send this to the server.
	// }

	getEvents(options) {
		let self = this;
		options = options || {};
		if (self.events.length > 0) {
			return new Promise(function (resolve) {
				resolve(_.cloneDeep(self.events));
			});
		}
		let lastAccount = _.cloneDeep(self.lastAccount);
		let lastUser = _.cloneDeep(self.lastUser);
		return this.Restangular.all("events")
			.getList({
				field: "active",
				query: true,
				limit: options.limit || 500,
				by: "createdAt",
				order: options.order || "asc",
			})
			.then((events) => {
				if (
					lastAccount == self.lastAccount &&
					lastUser == self.lastUser
				) {
					self.events = events;
					let clonedEvents = _.cloneDeep(events);
					clonedEvents.total = events.total;
					return clonedEvents;
				} else {
					return [];
				}
			});
	}

	getEventsWithQuery(query) {
		let self = this;
		return this.Restangular.all("events")
			.customGET("", query)
			.then((events) => {
				return _.cloneDeep(events);
			});
	}

	getEventSummaryWithQuery(query) {
		let self = this;
		return this.Restangular.all("events")
			.all("summary")
			.customGET("", query);
	}

	getEventAggregationWithQuery(query) {
		let self = this;
		return this.Restangular.all("events")
			.all("aggregate")
			.customGET("", query);
	}

	getEventAggregationPopulatedWithQuery(query) {
		let self = this;
		return this.Restangular.all("events")
			.all("aggregatePopulated")
			.customGET("", query);
	}

	/**
	 * To retrieve our list of eventTypes.
	 * Currently employing this function at NewSopComponent
	 */
	getEventTypesSync() {
		let self = this;
		return _.cloneDeep(self.appConfig.eventTypes);
	}

	getEventTypes() {
		let self = this;
		return self.getEventDetails.then((eventDetails) => {
			return Object.keys(eventDetails);
		});
	}

	getEventByID(eventId) {
		let self = this;
		if (self.events && self.events.length > 0) {
			let index = _.findIndex(self.events, (storedEvent) => {
				return storedEvent._id === eventId;
			});

			if (index !== -1) {
				return Promise.resolve(_.cloneDeep(self.events[index]));
			} else {
				return Promise.reject("No such event!");
			}
		} else {
			return self.Restangular.one("events", eventId)
				.get()
				.then((event) => {
					if (event) {
						return event;
					} else {
						return "No such event!";
					}
				});
		}
	}

	getEventByIDSync(eventId) {
		let self = this;
		if (self.events && self.events.length > 0) {
			let index = _.findIndex(self.events, (storedEvent) => {
				return storedEvent._id === eventId;
			});

			if (index !== -1) {
				return _.cloneDeep(self.events[index]);
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	/**
	 * We are currently setting eventService's eventStack from DashboardComponent, but consider creating it HERE
	 * @param {[type]} eventStack [description]
	 */
	setEventStack(eventStack) {
		let self = this;
		self.eventStack = eventStack;
	}

	clearEventStack() {
		let self = this;
		_.forEach(self.eventStack, (events, eventType) => {
			self.eventStack[eventType] = [];
		});
		_.forEach(self.eventDetails, (eventDetail) => {
			eventDetail.count = 0;
		});
		self.closedEventStack = {};
	}

	clearOpenEventStack() {
		let self = this;
		_.forEach(self.eventStack, (events, eventType) => {
			if (eventType === "online" || eventType === "offline") return null;
			self.eventStack[eventType] = [];
		});
		_.forEach(self.eventDetails, (eventDetail) => {
			eventDetail.count = 0;
		});
	}

	removeEvent(eventType, eventID) {
		let self = this;
		let index = _.findIndex(self.eventStack[eventType], (storedEvent) => {
			return storedEvent._id === eventID;
		});
		if (index !== -1) {
			self.eventStack[eventType].splice(index, 1);
		}
	}

	/**
	 * EventCarouselService is calling this function when a user clicks on one of the Mainheadings.
	 * Example:
	 * We click on panics. We get all panic Layers.
	 * We pass a layer here and find that layer's actual event and return it
	 */
	getEventFromMarker(marker) {
		let self = this;
		let eventID = marker.eventID;
		let eventType = marker.eventType;

		let correspondingEvent = _.find(self.eventStack[eventType], (event) => {
			return event._id === eventID;
		});

		if (correspondingEvent !== undefined) {
			return correspondingEvent;
		} else {
			console.error(
				`Cannot find an associated event from marker with ID: ${eventID} and type: ${eventType}`,
				marker
			);
		}
	}

	/**
	 * Receives an eventID and iterates through entire eventStack to retrieve that event
	 */
	getEventFromID(eventID) {
		let self = this;

		let event = _.find(self.eventStack, (event) => {
			return event._id === eventID;
		});

		if (event !== undefined) {
			return event;
		} else {
			console.error(`Cannot find event with ID: ${eventID}`);
		}
	}

	/** NOTE:
	 * We're calling this function from @see TabContentComponent
	 * We're probably going to have to change this function to rather
	 * use the Unit's ID, and not it's unitNumber. But I'm not too sure
	 * about all the data-structures yet so I'm going to focus on functionality
	 * first
	 */
	getEventFromUnitNumber(unitNumber) {
		let self = this;
		let foundEvent = _.find(self.eventStack, (eventType) => {
			if (eventType !== []) {
				return _.find(eventType, (event) => {
					return event.unitNumber === unitNumber;
				});
			}
		});

		if (foundEvent !== undefined) {
			return foundEvent[0];
		} else {
			console.error(`Cannot find event with unitNumber: ${unitNumber}`);
		}
	}

	/**
	 * Event Service receives a unitID and scans
	 * through our list of events and grabs each
	 * one associated with this unitID
	 * @param  {[type]} givenEvent [description]
	 * @return {[type]}            [description]
	 */
	getEventRelatedEventsByUnit(unitID) {
		let self = this;
		let events = [];
		let eventTypes = Object.keys(self.eventStack);

		_.forEach(eventTypes, (eventType) => {
			if (eventType !== "online" && eventType.length !== 0) {
				_.forEach(self.eventStack[eventType], (storedEvent) => {
					if (storedEvent.unit === unitID) {
						events.push(storedEvent);
					}
				});
			}
		});
		return events;
	}

	/**
	 * Event Service receives an assetID and scans
	 * through our list of events and grabs each
	 * one associated with thisn assetID
	 * @param  {String|Number} assetID  [The unitID]
	 * @return {Array} All events with this assetID
	 */
	getEventRelatedEventsByHumanAsset(assetID) {
		let self = this;
		let events = [];
		let eventTypes = Object.keys(self.eventStack);

		self.events.forEach((event) => {
			if (event.asset === assetID) {
				events.push(_.cloneDeep(event));
			}
		});
		return events;
	}

	/**
	 * Called from TabContentComponent.
	 * Receives an array of events, and orders them
	 * from OLDEST to NEWEST
	 * @return {Array} - The ordered events
	 */
	orderEventsChronologically(events) {
		let self = this;
		let result = _.cloneDeep(events);
		result.sort((a, b) => {
			if (a.createdAt > b.createdAt) {
				return -1;
			} else if (a.createdAt < b.createdAt) {
				return 1;
			} else {
				return 0;
			}
		});
		return result;
	}

	getPhotosForEvent(event) {
		let self = this;
		let query = {
			event: _.pick(event, [
				"_id",
				"account",
				"unit",
				"asset",
				"createdAt",
				"photos",
			]),
		};
		if (query.event.unit == "unassigned") {
			delete query.event.unit;
		}
		return this.Restangular.all("photos")
			.getList(query)
			.then((photos) => {
				if (photos) {
					photos.forEach((o) => {
						o.eventType = event.eventType;
						o.eventId = event._id;
						o.eventActive = event.active;
					});
					return photos;
				} else {
					console.error("No photos");
					return null;
				}
			});
	}

	getAllOpenPhotosForUnit(unitId, limit, skip, onlyOpen) {
		let self = this;
		if (onlyOpen) {
			let relevantEventTypes = [
				"Panic",
				"Photo",
				"Photo Request",
				"Photo - Line Trip",
				"Photo - Motion",
				"Photo - External Trigger",
				"Photo - Scheduled",
			];
			let events = [];
			relevantEventTypes.forEach((type) => {
				events = _.concat(events, self.eventStack[type]);
			});
			let relatedEvents = _.filter(events, (o) => {
				return (
					relevantEventTypes.includes(o.eventType) && o.unit == unitId
				);
			});
			let promises = [];
			relatedEvents.sort((a, b) => {
				if (a.createdAt > b.createdAt) {
					return -1;
				} else if (a.createdAt < b.createdAt) {
					return 1;
				} else {
					return 0;
				}
			});
			if (limit >= 0 && skip >= 0) {
				relatedEvents = relatedEvents.slice(skip, skip + limit);
			}
			relatedEvents.forEach((o) => {
				promises.push(self.getPhotosForEvent(o));
			});
			return Promise.all(promises).then((results) => {
				let allPhotos = _.flatten(results);
				// let photos = _.sortBy(allPhotos,['ts']);
				allPhotos.sort((a, b) => {
					if (a.ts > b.ts) {
						return -1;
					} else if (a.ts < b.ts) {
						return 1;
					} else {
						return 0;
					}
				});
				return allPhotos;
			});
		} else {
			return self.photoService
				.getPhotosForUnit(
					unitId,
					onlyOpen,
					skip,
					limit,
					"createdAt",
					-1
				)
				.catch((err) => {
					console.error(err);
				});
		}
	}

	getVideoUrl(eventID) {
		let self = this;
		return self.Restangular.all("videos")
			.getList({
				eventId: eventID,
			})
			.then((videos) => {
				if (videos && videos.length > 0) {
					return videos[0];
				} else {
					console.error("No videos");
					return null;
				}
			});
	}

	assignEventAndSaveSOP(tempEvent, user) {
		let self = this;
		if (tempEvent.unit == "unassigned") {
			delete tempEvent.unit;
		}
		return self.Restangular.one("events", tempEvent._id)
			.customPATCH(tempEvent, `updateActiveUser`, {
				activeUserId: user._id,
				activeUsername: `${user.firstname} ${user.lastname}`,
			})
			.then((response) => {
				return response;
			});
	}

	unassignEvent(tempEvent) {
		let self = this;
		return self.Restangular.one("events", tempEvent._id)
			.customPATCH(tempEvent, `unassignEvent`)
			.then((response) => {
				return response;
			});
	}

	allowLogin(tempEvent) {
		let self = this;
		if (tempEvent.eventType !== "Failed Login")
			throw Error(
				"Can not override failed login on event other than failed login events"
			);
		return self.Restangular.one("events", tempEvent._id)
			.customPATCH(tempEvent, `allowLogin`)
			.then((response) => {
				return response;
			});
	}

	/**
	 * Receives an event and formats a string
	 * to show how long ago this event was raised
	 */
	getEventRaisedAgo(event) {
		let self = this;

		let minutes = Math.abs(
			self.moment.utc().diff(self.moment.utc(event.createdAt), "minutes")
		);
		if (minutes === 1) {
			return "1 minute ago";
		}
		if (minutes > 1 && minutes < 60) {
			return `${minutes.toString()} minutes ago`;
		} else if (minutes >= 60) {
			let hours = Math.floor(minutes / 60);
			minutes = minutes % 60;
			if (hours < 24) {
				if (hours === 1) {
					if (minutes === 1) {
						return `1 hour, 1 minute ago`;
					}
					return `1 hour, ${minutes} minutes ago`;
				}
				return `${hours} hours, ${minutes} minutes ago`;
			} else {
				let days = Math.floor(hours / 24);
				hours = hours % 24;

				if (days < 365) {
					if (days === 1) {
						if (hours === 1) {
							return `1 day, 1 hour, ${minutes} minute(s) ago`;
						}
						return `1 day, ${hours} hours, ${minutes} minute(s) ago`;
					}
					return `${days} days, ${hours} hours, ${minutes} minute(s) ago`;
				} else {
					let years = Math.floor(days / 365);
					days = days % 365;

					if (years === 1) {
						return `1 year, ${days} day(s) ago`;
					}

					return `${years} years, ${days} day(s) ago`;
				}
				return `${days} day(s), ${hours} hour(s) ago`;
			}
		}
		return "less than a minute ago";
	}

	updateEvent(event) {
		let self = this;
		// if(typeof event.patch === 'function'){
		//     event.patch().then((response) => {
		//     })
		//
		// }else{
		let tempEvent = _.cloneDeep(event);
		if (tempEvent.unit == "unassigned") {
			delete tempEvent.unit;
		}
		return self.Restangular.one("events", tempEvent._id)
			.customPATCH(tempEvent)
			.then((response) => {
				return response;
			});
		// }
	}

	closeMultiple(sop, ids, queries) {
		let self = this;
		return self.Restangular.all("events").customPATCH(
			{
				sop,
				eventIds: ids,
				queries
			},
			"closeMultiple"
		);
	}

	escalateEvent(eventId, contactId) {
		let self = this;
		return self.Restangular.one("events", eventId)
			.one("escalateTo", contactId)
			.customPOST({
				timeZone: self.moment.tz.guess(),
			});
	}

	reloadEvents() {
		let self = this;
		if (self.reloadConfirm && self.reloadConfirm.isOpen()) {
			return false;
		}
		self.reloadConfirm = self.$ngConfirm({
			title: `Reload Event Data`,
			theme: "modern",
			animation: "top",
			scope: self.$scope,
			closeAnimation: "bottom",
			content: `<span style="margin:auto;">A large amount of events have been closed.  Please reload the event stack to ensure you have the latest events.</span>`,
			escapeKey: true,
			backgroundDismiss: true,
			buttons: {
				// long hand button definition
				ok: {
					text: "Reload Now",
					btnClass: "btn-primary",
					keys: ["enter"], // will trigger when enter is pressed
					action(scope, button) {
						self.destroyService();
						self.$state.reload();
					},
				},
				cancel: {
					text: "Not Now",
					btnClass: "btn-warning",
				},
			},
		});
	}

	async showEventSOP(event) {
		let self = this;
		let modal = self.$uibModal.open({
			component: "eventActionModal",
			backdrop: "static",
			size: "lg",
			keyboard: true,
			resolve: {
				events() {
					return [event];
				},
				sop() {
					return event.sop;
				},
			},
		});
		return modal.result;
	}

	async actionEvents(events) {
		let self = this;
		//Assign all sops for each event
		events = await self.assignAllSOPs(events);

		//Group by sop
		let groupedEvents = _.groupBy(events, "sop._id");

		//Action each group of events
		for await (const sopId of Object.keys(groupedEvents)) {
			const events = groupedEvents[sopId];

			if (sopId == "undefined") {
				//Action events without SOP
				await self.actionEventsWithoutSOP(events);
			} else {
				//Action events using SOP
				await self.actionEventsWithSOP(events, events[0].sop);
			}
		}
	}

	actionEventsWithSOP(events, sop) {
		let self = this;
		let modal = self.$uibModal.open({
			component: "eventActionModal",
			backdrop: "static",
			size: "lg",
			keyboard: true,
			resolve: {
				events() {
					return events;
				},
				sop() {
					return sop;
				},
			},
		});
		return modal.result;
	}

	actionEventsWithoutSOP(events) {
		let self = this;
		let promise = new Promise((resolve, reject) => {
			try {
				let groupedEvents = _.groupBy(events, "eventType");
				let content = "";
				let total = 0;
				_.forEach(groupedEvents, (events, eventType) => {
					if (_.some(events, 'query')) {
						let count = _.reduce(events, (count, event) => count + event.count, 0);
						total += count;
						content += `<div>${count} ${eventType}(s)</div>`;
					} else {
						content += `<div>${events.length} ${eventType}(s)</div>`;
					}
				});
				let settings = {
					title: `<span style="margin:auto;">Action ${total ? total : events.length} Events?</span>`,
					theme: "modern",
					animation: "top",
					scope: self.$scope,
					closeAnimation: "bottom",
					content,
					escapeKey: false,
					backgroundDismiss: false,
					buttons: {
						// long hand button definition
						ok: {
							text: "Action",
							btnClass: "btn-primary",
							keys: ["enter"], // will trigger when enter is pressed
							action(scope, button) {
								if (total > 0) {
									//This means these are grouped events in the list
									resolve(
										self.closeMultiple(
											undefined,
											undefined,
											_.map(events, "query")
										)
									);
								} else {
									resolve(
										self.closeMultiple(
											undefined,
											_.map(events, "_id")
										)
									);

								}
							},
						},
						close: {
							text: "Cancel",
							action(scope) {
								resolve();
							},
						},
					},
				};
				self.$ngConfirm(settings);
			} catch (error) {
				reject(error);
			}
		});
		return promise;
	}

	async assignAllSOPs(events) {
		let self = this;
		let sops = await self.accountService.getCurrentAccountSOPs();

		let promises = [];
		let siteIds = _.uniq(_.map(events, "site"));
		siteIds.forEach((siteId) => {
			let promise = self.siteService.getSOPs(siteId).then((sopIds) => {
				sopIds.forEach((sopId) => {
					let sop = _.find(sops, ["_id", sopId]);
					if (sop) {
						if (!sop.sites) sop.sites = [];
						sop.sites.push(siteId);
					}
				});
			});
			promises.push(promise);
		});
		//Site ids assigned to sop
		await Promise.all(promises);
		let groupedSOPs = _.groupBy(sops, "eventType");

		//Assign sops to events
		events.forEach((event) => {
			let eventSOPs = groupedSOPs[event.eventType];
			if (eventSOPs) {
				if (eventSOPs.length == 1) {
					event.sop = eventSOPs[0];
				} else {
					let defaultSOP = _.find(eventSOPs, "default");
					let siteSOP = _.find(
						eventSOPs,
						(sop) => sop.sites && sop.sites?.includes(event.site)
					);
					if (siteSOP) {
						event.sop = siteSOP;
					} else {
						event.sop = defaultSOP;
					}
				}
			} else {
				//NOP - Event has no SOPs
			}
		});

		return events;
	}
}

export default angular
	.module("secutraqApp.dashboard")
	.service("eventService", EventService);
