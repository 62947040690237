'use strict';

import * as _ from 'lodash-es';
import angular from 'angular';
import io from 'socket.io-client';

function Socket(socketFactory, Auth, $log) {
	'ngInject';
	// socket.io now auto-configures its connection when we ommit a connection url



	var ioSocket = io('', {
		// Send auth token on connection, you will need to DI the Auth service above
		// 'query': 'token=' + Auth.getToken()
		path: '/socket.io-client',
		reconnection: true,
		reconnectionAttempts: 10,
		reconnectionDelay: 1000
	});


	var	rooms = {};
	var socket = socketFactory({
		ioSocket
	});

	socket.ioSocket = ioSocket;
	var listenerMap = new Map();
	socket.on('connect', function() {
		console.debug("Socket connected");
	});
	socket.on('error', function(error) {
		if (error.type === 'UnauthorizedError' || error.code == 'invalid_token') {
			// redirect user to login page perhaps?
			$log.error('User\'s token has expired');
		} else {
			$log.error('Unknown socket error: ', error);
		}
	});
	socket.on('reconnect', attempt => {
		console.debug("Reconnected");
		for(var roomName in rooms) {
			socket.emit('join', {room: roomName});
		}
	});

	return {
		socket,
		rooms,

		syncUpdates(modelName, array, cb, context, merge, append) {
			cb = cb || angular.noop;
			append = append !== false;

			/**
			 * Syncs item creation/updates on 'model:save'
			 */
			let saveFunc = function(item) {
				var oldItem = _.find(array, {
					_id: item._id
				});
				var index = array.indexOf(oldItem);
				var event = 'created';

				if(merge === true && index>=0){
					_.mergeWith(item, oldItem, (objValue, srcValue) => {
						if (_.isArray(objValue)) {
							return _.unionBy(objValue, srcValue, 'accountId');
						}
					});

				}

				// replace oldItem if it exists
				// otherwise just add item to the collection
				if (oldItem) {
					array.splice(index, 1, item);
					event = 'updated';
				} else if(append) {
						array.push(item);
					} else {
						array.unshift(item);
				}

				cb(event, item, array);
			};
			socket.on(`${modelName}:save`, saveFunc);

			let removeFunc = function(item) {
				var oldItem = _.find(array, {
					_id: item._id
				});
				var index = array.indexOf(oldItem);
				var event = 'deleted';
				if (merge==true && index >= 0){

					_.mergeWith(item, oldItem, (objValue, srcValue) => {
						if (_.isArray(objValue)) {
							let tempIn = _.findIndex(srcValue,(o)=>{
return o.accountId==objValue[0].accountId;
});
							srcValue.splice(tempIn,1);
							return srcValue;
						}
					});
					if(item.accounts && item.accounts.length > 0){
						event = 'updated';
						if (oldItem) {
							array.splice(index, 1, item);
						}
					}else{
						_.remove(array, {
							_id: item._id
						});
					}

				}else{
					_.remove(array, {
						_id: item._id
					});
				}
				cb(event, item, array);
			};
			/**
			 * Syncs removed items on 'model:remove'
			 */
			socket.on(`${modelName}:remove`, removeFunc);

			if(typeof context !== 'undefined') {
				let listeners = listenerMap.get(context);
				if(listeners) {
					socket.removeListener(`${modelName}:save`, listeners.save);
					socket.removeListener(`${modelName}:remove`, listeners.remove);
				}
				listenerMap.set(context, {save: saveFunc, remove: removeFunc});
			}
		},

		/**
		 * Removes listeners for a models updates on the socket
		 *
		 * @param modelName
		 */
		unsyncUpdates(modelName, context) {

			if(typeof context !== 'undefined') {
				let listeners = listenerMap.get(context);
				if(listeners) {
					socket.removeListener(`${modelName}:save`, listeners.save);
					socket.removeListener(`${modelName}:remove`, listeners.remove);
					listenerMap.delete(context);
					return;

				}
			}
			listenerMap.clear();
			socket.removeAllListeners(`${modelName}:save`);
			socket.removeAllListeners(`${modelName}:remove`);

		},

		reAuth() {
			let onclose = () => {
				socket.connect();
				socket.removeListener("close", onclose);
			};
			socket.on('close', onclose);
			if(socket.connected) {
				socket.disconnect();
			} else {
				socket.connect();
			}
		},

		/**
		 * Joins a room
		 *
		 * @param roomName
		 */
		joinRoom(roomName) {
			rooms[roomName] = rooms[roomName] ? rooms[roomName] + 1 : 1;
			socket.emit('join', {room: roomName});
		},
		leaveAll() {
			return new Promise((ful,rej)=>{
				let roomArr = Object.keys(rooms);
				for (let iterator = 0; iterator<roomArr.length; iterator++){
					let count = rooms[roomArr[iterator]];
					for (let i=0; i<count; i++){
						socket.emit('leave', {room: roomArr[iterator]});
					}
				}
				return ful(true);
			});

		},
		/**
		 * Leaves a room
		 *
		 * @param roomName
		 */
		leaveRoom(roomName) {
			rooms[roomName] = rooms[roomName] ? rooms[roomName] - 1 : 0;
			if(!rooms[roomName]) {
				socket.emit('leave', {room: roomName});
				delete rooms[roomName];
			}
		}
	};
}

export default angular.module('secutraqApp.socket', [])
	.factory('socket', Socket)
	.name;
