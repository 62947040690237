import angular from 'angular';
import uiRouter from 'angular-ui-router';
import routes from './group.routes';
import uiBootstrap from 'angular-ui-bootstrap';
import NgTable from 'ng-table/bundles/ng-table';
import ngSanitize from 'angular-sanitize';


export default angular.module('secutraqApp.groups', [uiRouter, 'ngTable', uiBootstrap])
  .config(routes)
  .name;
require('./group.component');
require('./group-list/group-list.component');
require('./group-list/new-group/new-group.component');
