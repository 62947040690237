export class NewSopComponent {
    /*@ngInject*/
    constructor($timeout, $ngConfirm, $uibModal, $scope, eventService, siteService, moment, Auth, Restangular) {
        this.$timeout = $timeout;
        this.$ngConfirm = $ngConfirm;
        this.$uibModal = $uibModal;
        this.$scope = $scope;
        this.eventService = eventService;
        this.siteService = siteService;
        this.moment = moment;
        this.Auth = Auth;
		this.Restangular = Restangular;

    }

    $onInit() {
        let self = this;

        /**
         * NPM-installed angular-carousel.
         * See the .html for an idea how the directive works.
         * The index of the carousel assumes an ng-model on this variable
         * @type {Number}
         */
        self.stepsCarouselIndex = 0;
        self.sites = [];

        self.autoCloseTime = self.moment().startOf('day');
		self.availableTimeValues = ['minutes','hours','days'];
		self.times = {};
		self.availableContacts = [];
		self.Contacts = self.Restangular.all('contacts');
        if(self.resolve.edit) {
            self.sopDefinition = _.cloneDeep(self.resolve.sop);
            if(self.sopDefinition && self.sopDefinition.procedures && self.sopDefinition.procedures.length > 0){
                self.sopDefinition.procedures.pop();
            }
            self.sopDefinition.appliedSites = [];
            self.originalSites = [];
            self.removedSites = [];

            if(self.sopDefinition.autoClose >= 0){
                self.sopDefinition.autoCloseSelector = true;
                self.autoCloseTime = self.moment().startOf('day').add(self.sopDefinition.autoClose,'seconds');
            }


            self.siteService.getSitesWithQuery({query:self.sopDefinition._id, field:'sops'}).then((results) => {
                _.forEach(results, (site) => {
                    self.sopDefinition.appliedSites.push(site);
                    self.originalSites.push(site._id);
                });
            });
            self.sopDefinition.escalations = self.sopDefinition.escalations || [];
        }
        else {
            self.sopDefinition = {};
            self.sopDefinition.appliedSites = [];
            self.sopDefinition.escalations = [];
            self.sopDefinition.procedures = [{compulsory: false, label: '', procedureType: ''}];
        }

        self.procedureTypes = [{alias:'Text',key:'Text'}, {alias:'Yes/No',key:'Checkbox'},{alias:'None',key:'None'}];

        self.eventService.getEventTypes().then( (eventTypes) => {
        	self.eventTypes = eventTypes;
        } );

        self.siteService.registerListener(self, (event, item, array) => {
            if (event === 'deleted') {
                self.onRemoveSite(item);
            } else {
                self.onReceiptOfSite(item);
            }
        }).then((sites) => {
            _.forEach(sites, (site) => {
                self.onReceiptOfSite(site);
            });
        });
        // $onInit end
    }

    newStep() {
        let self = this;
        if(self.stepsCarouselIndex + 1 === self.sopDefinition.procedures.length) {
            self.sopDefinition.procedures.push({compulsory: false, label: '', procedureType: ''});
            self.stepsCarouselIndex++;
        }
        else {
            self.sopDefinition.procedures.push({compulsory: false, label: '', procedureType: ''});
        }
    }

	refreshContacts(searchVal) {
		let self = this;
		self.Contacts.getList({
			filter : searchVal,
			limit : 20,
		}).then((contacts) => {
			self.availableContacts = contacts;
		}).catch((err) => {
			console.error(err);
		})
	}

    removeStep(index) {
        let self = this;
        if(index !== -1) {
            let first = index === 0 ? true : false;
            let last = index === self.sopDefinition.procedures.length - 1 ? true : false;
            self.sopDefinition.procedures.splice(index, 1);
            if(!first) {
                self.stepsCarouselIndex--;
            }
            if(first && last) {
                self.newStep();
            }
        }
    }

    updateSiteList() {
        let self = this;
        if(self.resolve.edit) {
            let originalList = self.originalSites;

            _.forEach(originalList, (originalSiteID) => {

                let index = _.findIndex(self.sopDefinition.appliedSites, (appliedSite) => {
                    return appliedSite._id === originalSiteID;
                });

                if(index === -1) {
                    self.removedSites.push(originalSiteID);
                }
                else {
                    let removedIdx = _.findIndex(self.removedSites, (siteID) => {
                        return siteID === originalSiteID;
                    });

                    if(removedIdx !== -1) {
                        self.removedSites.splice(removedIdx, 1);
                    }
                }
            });
        }
    }

    onToggleIsDefault(){
        let self = this;
        if(self.sopDefinition.default){
            self.sopDefinition.appliedSites = [];
        }
    }

    closeModal() {
        let self = this;
        let result = {};
        if(self.sopDefinition.autoCloseSelector){
            result.autoClose = self.sopDefinition.autoClose;
        }else{
            result.autoClose = -1;
        }
        if(!self.resolve.edit) {
            result.name = self.sopDefinition.name;
            result.eventType = self.sopDefinition.eventType;
            result.default = self.sopDefinition.default;
			result.escalations = self.sopDefinition.escalations;
            result.appliedSites = [];

            _.forEach(self.sopDefinition.appliedSites, (site) => {
                result.appliedSites.push(site._id);
            });

            result.createdAt = self.moment.utc().toDate();
            result.createdBy = self.Auth.getCurrentUserSync()._id;
            result.active = true; // TODO: THIS

            result.procedures = [];
            _.forEach(self.sopDefinition.procedures, (step) => {
                //Could've used _.omit?? my bad
                result.procedures.push({compulsory: step.compulsory, label: step.label, instructions: step.instructions, procedureType: step.procedureType});
            });

            //adding the last, classification step
            result.procedures.push(
                {
                    label: "Classification",
                    instructions: "Please classify the event being actioned by selecting one of the available 'classification-tags'",
                    procedureType: "Selection",
                    compulsory: false
                }
            );
        }
        else {
            result._id = self.sopDefinition._id;
            result.name = self.sopDefinition.name;
            result.eventType = self.sopDefinition.eventType;
            result.default = self.sopDefinition.default;
			result.escalations = self.sopDefinition.escalations;
            result.appliedSites = [];
            _.forEach(self.sopDefinition.appliedSites, (site) => {
                if(!self.originalSites.includes(site._id)) {
                    result.appliedSites.push(site._id);
                }
            });
            result.removedSites = self.removedSites;
            result.createdAt = self.sopDefinition.createdAt;
            result.createdBy = self.sopDefinition.createdBy;
            result.active = self.sopDefinition.active;
            result.procedures = self.sopDefinition.procedures;
            result.editedAt = self.moment.utc().toDate();
            result.editedBy = self.Auth.getCurrentUserSync()._id;
            result.autoCloseOnResolve = self.sopDefinition.autoCloseOnResolve;
            result.procedures.push(
                {
                    label: "Classification",
                    instructions: "Please classify the event being actioned by selecting one of the available 'classification-tags'",
                    procedureType: "Selection",
                    compulsory: false
                }
            );
        }
		if(result.escalations){
			result.escalations.forEach( (escalation) => {
				escalation.contact = escalation.contact._id;
			} )
		}
		self.modalInstance.close(result);
    }

    /**
     * We registerListener for sites during $onInit
     */
    onReceiptOfSite(site){
        let self = this;
        let index = _.findIndex(self.sites, (storedSite) => {
            return storedSite._id === site._id;
        });
        if(index === -1) {
            self.sites.push(site);
        }
        else {
            self.sites.splice(index, 1, site);
        }
    }

    onRemoveSite(site) {
        let self = this;
        let index = _.findIndex(self.sites, (storedSite) => {
            return storedSite._id === site._id;
        });
        self.sites.splice(index, 1);
    }


    doLog() {
        console.debug(this);
    }

    dismissModal() {
        let self = this;
        self.modalInstance.dismiss();
    }

    generateNumber(min,max) {
        return Math.floor(Math.random()*(max-min+1)+min);
    }

    changeAutoClose(){
        let self = this;
        self.sopDefinition.autoClose = self.moment(self.autoCloseTime).diff(self.moment().startOf('day'),'seconds');
    }

}
export default angular.module('secutraqApp.sops')
.component('newSOP', {
    template: require('./new-sop.html'),
    controller: NewSopComponent,
    controllerAs: "$ctrl",
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
})
.directive('converttime',['moment', (moment) => {
	return {
		restrict: 'A',
		require: 'ngModel',
		link: (scope,element,attrs,ngModel) => {
			ngModel.$formatters.push( (value) => {
				return moment().startOf('day').add(value,'seconds');
			} );
			ngModel.$parsers.push( (value) => {
				let startOfDay = moment().startOf('day');
				return moment(value).diff(startOfDay,'seconds');
			} );
		}
	}
}])
.name;
