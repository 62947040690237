'use strict';
import {rrulestr} from 'rrule';
// @flow
export class ScheduleListComponent {
	/*@ngInject*/
	constructor(Auth, NgTableParams, $scope, $ngConfirm, $stateParams, $timeout, routeService, scheduleService, $uibModal,toastr, moment) {
		this.Auth = Auth;
		this.NgTableParams = NgTableParams;
		this.$scope = $scope;
		this.$ngConfirm = $ngConfirm;
		this.$timeout = $timeout;
		this.routeService = routeService;
		this.scheduleService = scheduleService;
		this.$uibModal = $uibModal;
		this.$stateParams = $stateParams;
		this.toastr = toastr;
		this.moment = moment;
	}

	$onInit() {
		let self = this;
	}

	onSelectedSchedule(schedule) {
		let self = this;
		self.activeScheduleId = schedule._id;
		self.activeSchedule = {};
		self.activeSchedule.rruleSet = rrulestr(schedule.rruleSet.join('\n'),{forceset: true});
		let rules = self.activeSchedule.rruleSet._rrule;
		self.activeSchedule.includedDates = self.activeSchedule.rruleSet._rdate;
		self.activeSchedule.excludedDates = self.activeSchedule.rruleSet._exdate;
		if(rules && rules.length > 0) {
			self.activeSchedule.rule = rules[0];
			if(self.activeSchedule.rule.origOptions && (self.activeSchedule.rule.origOptions.count > 0 || self.activeSchedule.rule.origOptions.until)) {
				self.activeSchedule.allDates = self.activeSchedule.rruleSet.all();
				self.activeSchedule.end = self.activeSchedule.allDates[self.activeSchedule.allDates.length-1];
			}else{
				self.activeSchedule.allDates = undefined;
			}
		}
		let shifts = _.groupBy(schedule.slots,'priority');
		let assets = [];
		_.forEach(shifts, (shiftItems,shiftKey) => {
			//Get uniq assets {unit, asset, group}
			let singleSlot = _.filter(shiftItems,(a) => {
				return a.startTime == shiftItems[0].startTime && a.duration == shiftItems[0].duration && a.type == shiftItems[0].type;
			});
			singleSlot.forEach((slot) => {
				let asset = {};
				if(slot.unit) {
					asset.unit = slot.unit;
				}
				if(slot.asset) {
					asset.asset = slot.asset;
				}
				if(slot.group) {
					asset.group = slot.group;
				}
				assets.push(asset);
			});
		});

		self.activeSchedule.assets = assets;

	}

	removeSchedule(schedule) {
		let self = this;
		self.scheduleService.updateSchedule({_id:schedule._id,active:false}).then((status) => {
			self.toastr.info(`Schedule has been removed.`);
		})
.catch((err) => {
			console.error(err);
		});
	}

	callEditFunction(schedule) {
		let self = this;
		self.editSchedule(schedule);
	}

	doLog() {
		console.debug(this);
	}
}

export default angular.module('secutraqApp.scheduling')
	.component('scheduleList', {
		template: require('./schedule-list.html'),
		controller: ScheduleListComponent,
		controllerAs: '$ctrl',
		bindings: {
			schedules: '=',
			editSchedule : '=',
			sites:'=',
			routes : '='
		}
	})
	.name;
