import PhotoModalController from '../../../photoAnalytics/imageCollection/modal.controller';

export class ViewReportComponent {
    /*@ngInject*/
    constructor($timeout, toastr, $ngConfirm, $uibModal, $scope, moment, Auth, Restangular, photoService, reportService, eventService, $state) {
        this.$timeout = $timeout;
        this.$ngConfirm = $ngConfirm;
        this.$uibModal = $uibModal;
        this.$scope = $scope;
        this.moment = moment;
        this.Auth = Auth;
		this.Restangular = Restangular;
		this.toastr = toastr;
		this.photoService = photoService;
		this.reportService = reportService;
		this.eventService = eventService;
		this.$state = $state;
    }

    $onInit() {
        let self = this;

        /**
         * NPM-installed angular-carousel.
         * See the .html for an idea how the directive works.
         * The index of the carousel assumes an ng-model on this variable
         * @type {Number}
         */
        self.fieldsCarouselIndex = 0;
		self.report = self.formatReport(_.cloneDeep(self.resolve.report));
	}

	formatReport(report) {
		report.fields.forEach( (field) => {
			switch (field.type) {
				case 'Check List':
					field.value = _.reduce(field.value, (obj, val) => {
						obj[val] = true;
						return obj;
					}, {});

					break;

				default:

			}

		});

		return report;
	}

    closeModal() {
        let self = this;
		self.modalInstance.close(true);
    }

    doLog() {
        console.debug(this);
    }

    dismissModal() {
        let self = this;
        self.modalInstance.dismiss();
    }

    openLink(id, photos) {
        let self = this;
        self.photoService.getPhotoByID(id).then(photo => {
            self.openFootage(photo, photos);
        });
    }

    openFootage(snapshot, photos) {
        let self = this;
        self.$uibModal.open({
            animation: true,
            backdrop: 'static',
            keyboard: true,
            template: require('../../../photoAnalytics/imageCollection/modal.html'),
            controller: PhotoModalController,
            controllerAs: '$ctrl',
            size: 'lg',
            resolve: {
                snapshot() {
                    return snapshot;
                },
                snapPlaceholder() {
                    return [snapshot];
                }
            }
        }).result.then(function(result) {
            if(result && result.newSnap) {
                self.openFootage(result.newSnap, photos);
            } else if(result && result.next) {
                //self.openNext();
            } else if(result && result.previous) {
                //self.openPrevious();
            }
        }, function() {});
    }

	printReport() {
		let self = this;
		self.eventService.getEventByID(self.report.eventId)
			.then( (event) => {
				return self.reportService.generateFieldReportReport(event);
			})
			.then( (task) => {
				if(task) {
					//Navigate to report page
					self.closeModal();
					self.$state.go('main.reports.adhoc',{task:task._id});
				}
			})
			.catch( (err) => console.error(err));
	}
}
export default angular.module('secutraqApp.fieldReports')
.component('viewFieldReport', {
    template: require('./view-report.modal.html'),
    controller: ViewReportComponent,
    controllerAs: "$ctrl",
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
})
.name;
