export class TabContentComponent {

    /*@ngInject*/
    constructor( moment, mapService, $timeout, currentEventService, eventService, $uibModal, $scope, Auth, $ngConfirm, Restangular, toastr, $state, $localStorage, liveStreamService) {
        this.moment = moment;
        this.mapService = mapService;
        this.$timeout = $timeout;
        this.currentEventService = currentEventService;
        this.eventService = eventService;
        this.$uibModal = $uibModal;
        this.$scope = $scope;
        this.$ngConfirm = $ngConfirm;
        this.Restangular = Restangular;
        this.Auth = Auth;
        this.toastr = toastr;
		this.$state = $state;
		this.$localStorage = $localStorage;
        this.closedEventStack = this.eventService.closedEventStack;
		this.liveStreamService = liveStreamService;
    }

    $onInit() {
        let self = this;

		let user = self.Auth.getCurrentUserSync();
		let account = self.Auth.getCurrentAccountSync();
		self.closedEventStack = this.eventService.closedEventStack;
        // **********************ActiveAccordionEvent************* //
        this.mapService.getMetaData().then((metaData) => {
            self.mapMetaData = metaData; //see NOTE at mapService
            self.mapMetaData.eventsAccordion = {};
            self.mapMetaData.eventsAccordion.inbox = true; //makes inbox the default opened
            self.mapMetaData.inboxAccordion = {};
            self.mapMetaData.eventsHistory = {};
            self.mapMetaData.eventsHistory.activeLayers = {};
            self.mapMetaData.eventsHistoryWatches = {};


			if(self.$localStorage.openEventSettings && self.$localStorage.openEventSettings[user._id] && self.$localStorage.openEventSettings[user._id][account.ref] && self.$localStorage.openEventSettings[user._id][account.ref].groupBy) {
				self.mapMetaData.openEventsGroupBy = self.$localStorage.openEventSettings[user._id][account.ref].groupBy;
			}else{
				self.mapMetaData.openEventsGroupBy = 'eventType';
			}
			if(self.$localStorage.openEventSettings && self.$localStorage.openEventSettings[user._id] && self.$localStorage.openEventSettings[user._id][account.ref] && self.$localStorage.openEventSettings[user._id][account.ref].orderBy) {
				self.mapMetaData.openEventsOrderBy = self.$localStorage.openEventSettings[user._id][account.ref].orderBy;
			}else{
				self.mapMetaData.openEventsOrderBy = 'createdAt';
			}
            // self.closedEventStack = metaData.closedEventStack;
            //NOTE: we also set .activeTab @ SubtabsComponent
            //NOTE: I'm using so much metaData here, because it allows me to manipulate some values
            //      from here as well as from the mapFactory.
            //      Like, stopping the $watches, the ng-show in html,
        });
        self.Auth.getCurrentUser().then((user) => {
            self.currentUser = user;
        });
        // **********************ActiveAccordionEvent********** //
        // **********************Material-Switches************* //
        this.totalEventCount = 0;
        this.showSites = false;
        this.showRoutes = false;
        this.showDrawingTools = false;
		this.availableEventLimits = [
			{alias:'50',id:50},
			{alias:'100',id:100},
			{alias:'200',id:200},
			{alias:'500',id:500}
		];
		//if(!this.eventLimit) this.eventLimit = 200;
		this.groupByOptions = [
			{
				id:'eventType',
				alias:'Event Type',
			},
			{
				alias:'Site, Event Type',
				id:'siteEventType'
			},
			{
				alias:'Unit, Event Type',
				id:'unitEventType'

			}
		];

		this.orderByOptions = [
			{
				id:'-createdAt',
				alias:'Newest First',
			},
			{
				id:'createdAt',
				alias:'Oldest First',
			},
		];

        // **********************datePicker******************** //
        self.datePickerDate;
        self.datePickerOpen = false;
        self.openDatePicker = function($event) {
        self.datePickerOpen = true;
        };
        // **********************datePicker******************** //

        self.currentEventService.getMetaData().then((metaData) => {
            self.currentEventMetaData = metaData;
        });

        self.throttledFunctions = {};

        self.lastAdditionalBreadcrum;
    }

    flyToEvent(event, $event, zoomScale) {
        let self = this;

        if($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }
        self.mapService.flyToEvent(event, $event, zoomScale);
    }

    flyToUnit(unitID, $event, zoomScale) {
    	let self = this;
    	if ($event) {
    		$event.preventDefault();
    		$event.stopPropagation();
    	}
    	self.mapService.flyToUnit(unitID);
    	self.selectedUnit = unitID;
    	// self.flyToEvent(event, null, zoomScale);
    }

    flyFromEvent(event, $event, zoomScale) {
        let self = this;
        $event.preventDefault();
        $event.stopPropagation();
        self.mapService.zoomToAll();
    }

    /**
     * Whenever we want to visually show related events, in some way or another (ng-click),
     * preferably taking chronology into account,
     * TODO: We still need some major functionality in terms of breadcrumbs. I'm currently just
     * taking all the breadcrumbs there are available, but we're going to choose a time/interval
     * to limit the amounts we're displaying
     *
     */
     toggleBreadcrumbMode(unitId, $event) {
         let self = this;
         if($event) {
             $event.preventDefault();
             $event.stopPropagation();
         }

         //For now I'm retrieving information of where this unit has been
         if(self.unitsData && self.unitsData[unitId] && self.unitsData[unitId].lastLocation && self.unitsData[unitId].lastLocation.length > 0) {

             if(!self.mapMetaData.eventsHistory[unitId]) {
                 //We're making a slot for this event's data.
                 //The mapService will recognize that the slot is empty and draw details
                 let events = self.eventService.getEventRelatedEventsByUnit(unitId);

                 self.mapMetaData.eventsHistory[unitId] = {};
                 self.mapService.enableBreadcrumbMode(events, unitId);

                 self.mapMetaData.eventsHistoryWatches[unitId] = self.$scope.$watch(() => {
                     return self.unitsData[unitId].lastLocation[0].location;
                 }, (newValue, oldValue) => {
                     if(!self.mapService.compareGeoLocation(newValue,oldValue)) {
                         let index = _.findIndex(self.unitsData[unitId].lastLocation,(location)=>{
                             return self.mapService.compareGeoLocation(oldValue,location.location);
                         });
                         let layer = self.mapMetaData.eventsHistory[unitId].mapLayer;
                         if(index == 1) {
                             let firstLocation = self.unitsData[unitId].lastLocation[0];
                             let secondLocation = self.unitsData[unitId].lastLocation[1];
                             self.mapService.drawLocationLine(secondLocation, firstLocation, layer);
                         }else if(index > 1) {
                             let newLocations = self.unitsData[unitId].lastLocation.slice(0,index+1);
                             self.mapService.drawBreadcrumbLines(layer,newLocations);
                         }else if(index == -1) {
                             //TODO redraw entire breadcrums. more than 50 locations have been updated
                         }
                     }
                 });
             }else {
                 self.lastAdditionalBreadcrum = undefined;
                 self.mapService.disableBreadcrumbMode(unitId);
             }
         }else {
            self.toastr.warning('Unit has no locations.');
         }

     }

    loadMoreBreadcrums(unitId, $event) {
        let self = this;
        if($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }
		let lastLocation;
        if(self.lastAdditionalBreadcrum) {
            lastLocation = self.lastAdditionalBreadcrum;
        }else {
            lastLocation = self.unitsData[unitId].lastLocation.slice(-1)[0];
        }
        let layer = self.mapMetaData.eventsHistory[unitId].mapLayer;
        self.Restangular.all('locations').getList({
            query:unitId,
            field:'unit',
            before:lastLocation.ts,
            limit:50,
            by:'ts',
            order:-1
        })
.then((locations) => {
			if(locations && locations.length > 0) {
				self.lastAdditionalBreadcrum = locations.slice(-1)[0];
				self.mapService.drawBreadcrumbLines(layer,locations);
				self.toastr.info('Locations loaded.');
			}else{
				self.toastr.warning('No more locations available.');
			}
        })
.catch((err) => {
            self.toastr.warning('No more locations available.');
            console.error(err);
        });
    }

    openMarkerPopup(event, $event) {

        let self = this;
        $event.preventDefault();
        $event.stopPropagation();
        self.mapService.openMarkerPopup(event, $event);
    }


    getTotalEventCount() {
        let self = this;
        let result = 0;
        let keys = Object.keys(self.eventStack);
        _.forEach(keys, (key) => {
            if(key !== 'online') {
                result += self.eventStack[key].length;
            }
        });
        return result;
    }

    getTotalAssingEvents() {
        let self = this;
        let result = 0;
        let keys = Object.keys(self.reassignEventStack);
        _.forEach(keys, (key) => {
            if(key !== 'online') {
                result += self.reassignEventStack[key].length;
            }
        });
        return result;
    }

    onSwivelMarker(event, $event) {
        let self = this;
        $event.preventDefault();
        $event.stopPropagation();
        self.mapService.swivelMarker(event, $event);
    }


    getTimeAgo(event) {
        let self = this;
        if(self.throttledFunctions[event._id]) {
            return self.throttledFunctions[event._id](event);
        }else{
            self.throttledFunctions[event._id] = _.throttle((event)=>{
                return self.eventService.getEventRaisedAgo(event);
            },60000);
            return self.eventService.getEventRaisedAgo(event);
        }
    }

    onSetCurrentEvent(event, $event) {
        let self = this;
        if($event) {
            $event.stopPropagation();
            $event.preventDefault();
        }

        self.setCurrentEvent({eventID: event._id, eventType: event.eventType});
    }

    onSetCurrentClosedEvent(event, $event) {
        let self = this;
        if($event) {
            $event.stopPropagation();
            $event.preventDefault();
        }

        self.setCurrentClosedEvent({event, eventType: event.eventType});
    }


    getClosedEvents($event,first) {
        let self = this;
        if($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }
        let count = _.reduce(self.closedEventStack,(counter,events) => {
            return counter += events.length;
        },0);
        if(first && count !== 0) {
            return null;
        }
        let limit = 50;
        if(self.closedEventsOpenStatus) {
            self.eventService.getEventsWithQuery({query:false,field:'active', limit:50,skip:count,order:-1,by:'createdAt'}).then((results) => {
                try {

                	results.data.forEach((event) => {

                		let index = _.indexOf(self.eventTypes, event.eventType); //to make sure this eventType is an acknowledged type
                		if (index !== -1) { //currentEvent is an acknowledged type
                            let eventType = event.eventType;
                			let insertionArea;
                			if (self.closedEventStack[eventType]) {
                				insertionArea = self.closedEventStack[eventType]; //just a shorthand for referring to self.eventStack[eventType]
                			} else {
                				self.closedEventStack[eventType] = [];
                				insertionArea = self.closedEventStack[eventType]; //just a shorthand for referring to self.eventStack[eventType]
                			}
                			let idx = _.findIndex(self.closedEventStack[eventType], {
                				_id: event._id
                			});
                			if (idx !== -1) { //there aready is an event of this type WITH this _id
                				insertionArea[idx] = _.mergeWith(insertionArea[idx], event, (objValue, srcValue) => {
                					if (_.isArray(objValue)) {
                						return srcValue;
                					}
                				});
                			} else { //there isn't another event of this type with this _id [ie. Unique event]
                				insertionArea.push(event); //we add this event to the stack
                			}
                		} else {
                			// console.error("Unsupported event Type");
                		}
                	});
                } catch (e) {
                    console.error(e);
                }
            });
        }
    }

    acceptEvent(event) {
        let self = this;
        event.reassignTo = null;
        event.activeUser = self.Auth.getCurrentUserSync()._id;
        event.activeUsername = `${self.Auth.getCurrentUserSync().firstname} ${self.Auth.getCurrentUserSync().lastname}`;
        event.assignments.push({
            assignedTo: self.Auth.getCurrentUserSync()._id,
            assignedToName : `${self.Auth.getCurrentUserSync().firstname} ${self.Auth.getCurrentUserSync().lastname}`,
            assignedAt: self.moment.utc().toDate()
        });

        self.eventService.updateEvent(event);
    }

    declineEvent(event) {
        let self = this;
        event.reassignTo = null;
        self.eventService.updateEvent(event);
    }


    closeAllEvents(events, query) {
        if(query && query !== 'all'){
            if(query.sitename){
                query.site = events[0].site;
            }
            if(query.unitname){
                query.unit = events[0].unit;
            }
        }

        let self = this;
        let confirmOptions = {
            title: `<span style="margin:auto;">Action all multiple events.</span>`,
            theme: 'modern',
            animation: 'top',
            scope: self.$scope,
            closeAnimation: 'bottom',
            content: `<div style="text-align: center;">Would you like to close </br><b>all open events</></br>OR</br> the <b>${events.length} oldest events?</br><div>`,
            escapeKey: true,
            backgroundDismiss: true,
            buttons: {
                // long hand button definition
                ok: {
                    text: `Close ${events.length} oldest events.`,
                    btnClass: 'btn-primary',
                    keys: ['enter'], // will trigger when enter is pressed
                    action(scope, button) {
                        let eventIds = _.map(events, '_id');
                        self.Restangular.all('events').customPATCH({ eventIds }, 'closeAll')
                            .then((response) => {
                                // TODO: toastr
                                // self.clearCurrentEvent();
                            })
                            .catch((err) => {
                                console.error(err);
                            });
                    }
                },
            },
        }
        if (query) {
            confirmOptions.buttons.all = {
                text: `Close all relevant events.`,
                btnClass: 'btn-primary',
                keys: ['enter'], // will trigger when enter is pressed
                action(scope, button) {
                    self.Restangular.all('events').customPATCH(query, 'closeAllOpen')
                        .then((response) => {
                            // TODO: toastr
                            // self.clearCurrentEvent();
                            //self.eventService.destroyService();
                            //self.$state.reload();
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                }
            };
        }
        self.$ngConfirm(confirmOptions);
    }

	stopProp($event) {
		let self = this;
		$event.preventDefault();
		$event.stopPropagation();
	}


	loadPrevEventsPage() {
		let self = this;
		let eventPageBefore = self.eventPage;
		self.eventPage -= 1;
		self.eventPage = self.eventPage < 1 ? 1 : self.eventPage;
		if(eventPageBefore != self.eventPage) {
			self.refreshInbox = true;
			self.$timeout( () => {
				self.loadMoreEvents();
				self.refreshInbox = false;
			} );
		}
	}

	loadNextEventsPage() {
		let self = this;
		let eventPageBefore = self.eventPage;
		self.eventPage += 1;
		let maxPage = Math.ceil(self.openEventsCount/self.eventLimit);
		self.eventPage = self.eventPage > maxPage ? maxPage : self.eventPage;
		if(eventPageBefore != self.eventPage) {
			self.refreshInbox = true;
			self.$timeout( () => {
				self.loadMoreEvents();
				self.refreshInbox = false;
			} );
		}
	}

    doLog() {
		console.debug(this);
    }

	toggleEventShowInHeader(eventType, eventDetail) {
		let self = this;
		let user = self.Auth.getCurrentUserSync();
		let account = self.Auth.getCurrentAccountSync();
		self.$localStorage.hiddenEvents[user._id][account.ref][eventType] = eventDetail.showInHeader;
	}

	updateOpenEventGroupBy() {
		let self = this;
		let user = self.Auth.getCurrentUserSync();
		let account = self.Auth.getCurrentAccountSync();
		if(!self.$localStorage.openEventSettings) {
			self.$localStorage.openEventSettings = {};
		}
		if(!self.$localStorage.openEventSettings[user._id]) {
			self.$localStorage.openEventSettings[user._id] = {};
		}
		if(!self.$localStorage.openEventSettings[user._id][account.ref]) {
			self.$localStorage.openEventSettings[user._id][account.ref] = {};
		}
		self.$localStorage.openEventSettings[user._id][account.ref].groupBy = self.mapMetaData.openEventsGroupBy;

	}

	updateOpenEventOrderBy() {
		let self = this;
		let user = self.Auth.getCurrentUserSync();
		let account = self.Auth.getCurrentAccountSync();
		if(!self.$localStorage.openEventSettings) {
			self.$localStorage.openEventSettings = {};
		}
		if(!self.$localStorage.openEventSettings[user._id]) {
			self.$localStorage.openEventSettings[user._id] = {};
		}
		if(!self.$localStorage.openEventSettings[user._id][account.ref]) {
			self.$localStorage.openEventSettings[user._id][account.ref] = {};
		}
		self.$localStorage.openEventSettings[user._id][account.ref].orderBy = self.mapMetaData.openEventsOrderBy;
		self.refreshInbox = true;
		self.$timeout( () => {
			self.loadMoreEvents();
			self.refreshInbox = false;
		} );
	}

	updateOpenEventLimit() {
		let self = this;
		let user = self.Auth.getCurrentUserSync();
		let account = self.Auth.getCurrentAccountSync();
		if(!self.$localStorage.openEventSettings) {
			self.$localStorage.openEventSettings = {};
		}
		if(!self.$localStorage.openEventSettings[user._id]) {
			self.$localStorage.openEventSettings[user._id] = {};
		}
		if(!self.$localStorage.openEventSettings[user._id][account.ref]) {
			self.$localStorage.openEventSettings[user._id][account.ref] = {};
		}
		self.$localStorage.openEventSettings[user._id][account.ref].limit = self.eventLimit;
		self.refreshInbox = true;
		self.$timeout( () => {
			self.loadMoreEvents();
			self.refreshInbox = false;
		} );
	}

	startLiveStream(unit) {
		let self = this;
        self.liveStreamService.addStream(unit);
	}


}

export default angular.module('secutraqApp.dashboard')
.component('appTabcontent', {
    template: require('./tabcontent.component.html'),
    controller: TabContentComponent,
    controllerAs: '$ctrl',
    bindings: {
        currentEvent: '=',
        eventStack: '=',
        openEvents: '=',
        reassignEventStack: '=',
        eventTypes: '=',
        eventDetails: '=',
        generateEvent: '&',
        preliminarilySetFootages: '&',
        setCurrentEvent: '&',
        setCurrentClosedEvent: '&',
        photoStack: '=',
        units: "=",
        unitsData: "=",
        unitsOnlineStatus: '=',
        selectedUnit: '=',
        lastHeartbeat: '&',
		openEventsCount: '=',
		eventPage: '=',
		eventLimit : '=',
		loadMoreEvents: '&',
		eventsLoading: '='

    }
})
.filter('batteryPercentage', () => {
    return (mV) => {
        let min = 3400;
        let max = 4200;
        let per = (mV-min)/(max-min)*100;
        if(per < 1) {
            per = 1;
        }
        if(per > 100) {
            per = 100;
        }
        return Math.round(per);
    };
})
.filter('formatEventType', function() {
	return function(eventType, event, eventDetails) {
		if(eventDetails && eventDetails.splitEvents) {
			let returnVal = eventType;
			_.some(eventDetails.splitEvents, (split) => {
				if(typeof split.filterFunction === 'function') {
					if(split.filterFunction(event)) {
						returnVal = split.alias;
						return true;
					}else{
						return false;
					}
				}else{
					return false;
				}
			});
			return returnVal;
		}else{
			return eventType;
		}


	};
})
.filter('showSubEvents', function() {
	return function(events, eventDetails) {
		let returnVal = true;
		if(eventDetails && eventDetails.subEvents && eventDetails.defaultEventType) {
			returnVal = false;
			eventDetails.subEvents.forEach( (eventType) => {
				if(eventType !== eventDetails.defaultEventType) {
					if(_.find(events,['eventType',eventType])) {
						returnVal = true;
					}
				}
			} );
			return returnVal;
		}else{
			return returnVal;
		}
	};
})
.filter('escalatedEvents', function() {
	return function(events) {
		return _.filter(events, (o) => {
			let hasEscalations = false;
			if(o.escalations && o.escalations.length > 0) {
				hasEscalations = true;
			}
			return hasEscalations;
		});
	};
})
.filter('pendingReassingments', function(Auth) {
	return function(events) {
		return _.filter(events, (o) => {
			return o.reassignTo && o.activeUser == Auth.getCurrentUserSync()._id;
		});
	};
})
.filter('customEventGrouping', function(Auth, eventService) {
	return function(events, eventDetails, groupId) {

		let groupKey = `${groupId}events`;
		let groupEvents = function(events, eventDetails) {
			return _.groupBy(events, function(o) {
				let key = o.eventType;
				let eventDetail = eventDetails[o.eventType];
				if(eventDetail && eventDetail.groupUnder) {
					key = eventDetails[eventDetail.groupUnder].description;
				}else{
					key = o.eventType;
				}
				return key;
			});
		};
		let cacheKey = events.length + _.reduce(events, (key,event) => {
			return key += event._id;
		} ,'');
		if(!eventService.customEventGroupingCache[groupKey]) {
			eventService.customEventGroupingCache[groupKey] = {
				groupedEvents : groupEvents(events, eventDetails),
				cacheKey
			};
		}
		if(eventService.customEventGroupingCache[groupKey].cacheKey !== cacheKey) {
			eventService.customEventGroupingCache[groupKey].groupedEvents = groupEvents(events, eventDetails);
			eventService.customEventGroupingCache[groupKey].cacheKey = cacheKey;
		}
		return eventService.customEventGroupingCache[groupKey].groupedEvents;
	};
})
.filter('customGroupBy', function(Auth, eventService) {
	return function(events, groupKey) {

		if(!eventService.customEventGroupingCache[groupKey]) {
			eventService.customEventGroupingCache[groupKey] = {
				groupedEvents : _.groupBy(events, groupKey),
				cacheKey : events.length
			};
		}
		if(eventService.customEventGroupingCache[groupKey].cacheKey !== events.length) {
			eventService.customEventGroupingCache[groupKey].groupedEvents = _.groupBy(events, groupKey);
			eventService.customEventGroupingCache[groupKey].cacheKey = events.length;
		}
		return eventService.customEventGroupingCache[groupKey].groupedEvents;
	};
})
	.directive('eventGrouping', function() {
		return {
			template: require('./eventGrouping.html')
		};
	})
	.directive('eventBadge', function() {
		return {
			template: require('./subEventAccordion.html')
		};
	})
	.directive('closedEventBadge', function() {
		return {
			template: require('./closedEventBadge.html')
		};
	})
	.directive('openEventsDirective', function() {
		return {
			template: require('./openEvents.html')
		};
	})
.name;
