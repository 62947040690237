import momentTZ from 'moment-timezone';

export class ReportService {
	/*@ngInject*/
	constructor($uibModal, moment, Restangular, socket, Auth, toastr) {
		let self = this;
		this.$uibModal = $uibModal;

		this.reports = [];
		this.moment = moment;
		this.Restangular = Restangular;
		this.listenerMap = new Map();
		this.socket = socket;
		this.Auth = Auth;
		this.cacheTimeout = undefined;
		this.lastAccount = undefined;
		this.lastUser = undefined;
		this.joined = false;
		this.toastr = toastr;

		// self.generateRoute();


	}

	onSocketEvent(event, item, array) {
		let self = this;
		self.reports = array;
		self.listenerMap.forEach((value, key) => {
			setTimeout(() => {
				value(event, item, array);
			}, 0);
		});
	}

	// TODO: Figure out specific room joining before new tasks types are added.

	registerListener(context, cb, noGet, room) {
		let self = this;
		if (!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
		if (!self.lastUser) {
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		} else if (self.lastUser !== self.Auth.getCurrentUserSync()._id) {
			self.destroyService();
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		}
		let count = self.listenerMap.size;
		self.listenerMap.set(context, cb);
		// If listenermap was empty, (TODO and timer isn't running), join room, syncupdates
		if (!self.joined) {
			if (self.cacheTimeout) {
				clearTimeout(self.cacheTimeout);
				self.cacheTimeout = undefined;
			}
			if (noGet) {
				self.socket.syncUpdates('report', self.reports, self.onSocketEvent.bind(self), self);
				self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().ref}:*:reports`);
				return null;
			} else {
				return self.getReports().then((reports) => {
					self.socket.syncUpdates('report', self.reports, self.onSocketEvent.bind(self), self);
					self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().ref}:*:reports`);
					self.joined = true;
					return reports;
				});
			}
		} else {
			if (self.cacheTimeout) {
				clearTimeout(self.cacheTimeout);
				self.cacheTimeout = undefined;
			}
			if (noGet) {
				return null;
			} else {
				return self.getReports();
			}
		}
	}

	destroyService() {
		let self = this;
		if (self.joined) {
			self.socket.unsyncUpdates('report', self);
			self.socket.leaveRoom(`${self.lastAccount}:*:reports`);
		}
		self.joined = false;
		self.reports = [];
		self.listenerMap.clear();
		clearTimeout(self.cacheTimeout);
		self.cacheTimeout = undefined;
	}

	unregisterListener(context, cb) {
		let self = this;
		if (!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
		if (!self.lastUser) {
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		} else if (self.lastUser !== self.Auth.getCurrentUserSync()._id) {
			self.destroyService();
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		}
		self.listenerMap.delete(context);
		if (self.listenerMap.size === 0) {
			self.cacheTimeout = setTimeout(self.destroyService.bind(self), 5000);
		}
	}

	unregisterAll() {
		let self = this;
		if (!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
		if (!self.lastUser) {
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		} else if (self.lastUser !== self.Auth.getCurrentUserSync()._id) {
			self.destroyService();
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		}
		self.listenerMap.clear();
		if (self.listenerMap.size === 0) {
			self.cacheTimeout = setTimeout(self.destroyService.bind(self), 5000);
		}
	}

	getReports() {
		let self = this;
		if (self.reports.length > 0) {
			return new Promise(function(resolve) {
				resolve(_.cloneDeep(self.reports));
			});
		}
        let lastAccount = _.cloneDeep(self.lastAccount);
        let lastUser = _.cloneDeep(self.lastUser);
		return this.Restangular.all('reports').getList()
			.then((reports) => {
				if(lastAccount == self.lastAccount && lastUser == self.lastUser) {
					self.reports = reports;
					return _.cloneDeep(reports);
				}else {
					return [];
				}
			});
	}

	getReportsWithQuery(query) {
		let self = this;
		return this.Restangular.all('reports').getList(query)
			.then((response) => {
				return _.cloneDeep(response);
			})
.catch((err) => {
				console.error(err);
			});
	}
	removeReport(report) {
		let self = this;
		if (typeof report.remove === 'function') {
			return report.remove().then((response) => {
				// self.toastr.info('Report removed.');
				return response;
			})
.catch((err) => {
				console.error(err);
				self.toastr.error('Report remove failed.');
				return err;
			});
		} else {
			return self.Restangular.one('reports', report._id).remove()
.then((response) => {
				// self.toastr.info('Site removed.');
				return response;
			})
.catch((err) => {
				console.error(err);
				self.toastr.error('Report remove failed.');
				return err;
			});
		}
	}
	saveReport(report) {
		let self = this;
		return self.Restangular.all('reports').post(report)
.then((response) => {
			if (response._id) {
				self.toastr.info('Report template saved.');
			} else {
				self.toastr.error('Report template save failed.');
				console.error(response);
			}
			return response;
		})
.catch((err) => {
			console.error(err);
			return err;
		});
	}
	deleteReport(reportId) {
		let self = this;
		return self.Restangular.one('reports',reportId).remove()
.then((response) => {
			self.toastr.info('Report template deleted.');
			return response;
		})
.catch((err) => {
			console.error(err);
			if(err.data == 'Report template used in schedule') {
				self.toastr.error('Report template used in scheduled reports, remove before deleting.');
			}else{
				self.toastr.error('Report template could not be deleted.');
			}
			return err;
		});
	}


	generateFieldReportReport(event) {
		let self = this;
		let reportData = {
			filterFrom: self.moment.utc(event.createdAt).valueOf(),
			filterTo: self.moment.utc(event.createdAt).valueOf(),
			name: "Event Summary",
			filter: [
				{
					key: "eventType",
					collection: "Event",
					values: [
						event.eventType
					]
				},
				{
					key: "_id",
					collection: "Unit",
					values: [
						event.unit
					]
				}
			],
			content: [
				{
					key: "Field Report Information"
				}
			],
			timeZone : momentTZ.tz.guess(),
		};
		return self.Restangular.one('reports').customPOST(reportData,'generateReport');
	}
}

export default angular.module('secutraqApp.dashboard')
	.service('reportService', ReportService);
