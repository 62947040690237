export class CustomEventEditModalComponent {

	/*@ngInject*/
	constructor(customEventTypeService, toastr) {
		this.customEventTypeService = customEventTypeService;
		this.toastr = toastr;
	}

	$onInit() {
		let self = this;
		self.event = self.resolve.event;
		self.customEventOptions = {
			edit: true,
			disableTypeEdit: true,
			customEventType: self.event
		};
	}

	// Modal accept: send resulting object back to promise in parent controller
	ok() {
		let self = this;
		let savePromise;
		if(self.event._id) {
			savePromise = self.customEventTypeService.updateCustomEventType(self.event);
		}else{
			savePromise = self.customEventTypeService.saveNewCustomEventType(self.event);
		}
		savePromise
			.then( () => {
				self.modalInstance.close();
			} )
			.catch( (err) => {
				self.toastr.error('Could not save event setting');
			} );
	}

	// Modal cancelled: either cancel pressed or clicked off screen
	cancel() {
		this.modalInstance.dismiss('Cancel pressed');
	}

}
export default angular.module('secutraqApp.settings')
	.component('customEventEditModal', {
		template: require('./custom-event-edit.modal.html'),
		bindings: {
			resolve: '<',
			close: '&',
			dismiss: '&',
			modalInstance: '<',
			settings: '<'
		},
		controller: CustomEventEditModalComponent,
		controllerAs: '$ctrl'
	})
	.name;
