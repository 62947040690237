export class ContactsListComponent {
	/*@ngInject*/
	constructor(Restangular, Auth, $stateParams, NgTableParams, $uibModal, $log, $timeout) {
		this.Auth = Auth;
		this.$stateParams = $stateParams;
		this.NgTableParams = NgTableParams;
		this.$uibModal = $uibModal;
		this.$log = $uibModal;
		this.Restangular = Restangular;
		this.getCurrentUser = Auth.getCurrentUserSync;
		this.$timeout = $timeout;
	}

	$onInit() {
		let self = this;

		// this.$stateParams.accountID = this.Auth.getCurrentAccountSync().ref;
		self.filter = '';
		self.Contacts = self.Restangular.all('contacts');
		// let dummyContacts = [
		// 	{firstname: "Pieter", lastname: "le Roux", description: "Employee", phone: "083 262 1493", email: "pgleroux@jerichosystems.co.za", groups: ['a','d']},
		// 	{firstname: "Marco", lastname: "Barnard", description: "Employee", phone: "083 121 0153", email: "marco.barnard9@gmail.com", groups: ['2']},
		// 	{firstname: "Michael", lastname: "Smith", description: "Site Manager", phone: "079 465 0588", email: "ms889@pcsolutions.co.za", groups: ['21']},
		// ];

		self.tableParams = new self.NgTableParams({

			page: 1, // start with first page
			count: 10, // count per page
			sorting: {
				firstname: 'asc' // initial sorting
			}
		},

		 {
			total: 0,
			getData: function(params) {
				let order;
				if(params && params.sorting) {
					order = params.sorting();
					return self.Contacts.getList({
						accountID: self.$stateParams.accountID,
						filter: (self.filter.length) ? self.filter : undefined,
						skip: (params.page()-1)*params.count(),
						limit: params.count(),
						by: Object.keys(order)[0],
						order: order[Object.keys(order)[0]]})
						.then(function(contacts) {
							if(self.definingSite) {
								//This means we've started to edit a site and want to indicate which contacts
								//are already listed as sitecontacts
								if(self.siteContacts && self.siteContacts.length > 0) {
									_.forEach(self.siteContacts, (siteContact) => {
										//I am getting the data that we reference in HTML with our ng-table
										let tableContact = _.find(contacts, (contact) => {
											return contact._id === siteContact._id;
										});
										if(tableContact !== undefined) {
											tableContact.siteContact = true;
										}
									});
								}
							}
							self.contacts = contacts;
							self.total = contacts.total;
							params.total(contacts.total);
							return contacts;
						})
						.catch(err => {
							console.error("Error when retrieving contacts:" , err.data.err);
						})
					}
				}
			}
		);
		self.tableParams.reload();

		/**
		 * If we are pulling this component as a modal from NewSiteComponent, we want some other
		 * elements to be shown (for instance, a close button)
		 */
		if(self.definingSite) {
			self.definingSite = true;
		}
		else if(self.resolve) {
			if(self.resolve.definingSite) {
				self.definingSite = true;
			}
			if(self.resolve.isModal) {
				self.isModal = true;
			}
		}

	}

	getAccountName(accountID) {
		let self = this;
		let user = self.getCurrentUser();
		if(!user.accounts || typeof user.accounts === Array) {
			return 'No Account';
		}
		let account;
		for(let i = 0; i < user.accounts.length; ++i) {
			if(user.accounts[i].ref === accountID) {
				account = user.accounts[i];
				break;
			}
		}
		if(account !== undefined) {
			return account.name;
		}
		return 'No Account';
	}

	// TODO add child views for modals, for allowing href links to alert settings
	openCreateContact() {
		let self = this;
		let modalInstance = self.$uibModal.open({
			component: 'contactmodal',
			bindToController: true,
			size: 'md',
			backdrop: 'static',
			resolve: {
				settings: function() {
					return {
						edit: false,
						create: true,
						account: {ref: self.$stateParams.accountID, name: self.getAccountName(self.$stateParams.accountID)}
					};
				}
			}
		});

        modalInstance.result.then(function(result){
			self.tableParams.reload();
        });
	};

	openEditContact(contact) {
		let self = this;
		let modalInstance = self.$uibModal.open({
			component: 'contactmodal',
			bindToController: true,
			size: 'md',
			backdrop: 'static',
			resolve: {
				settings: function() {
					return {
						edit: true,
						create: false,
						account: {ref: self.$stateParams.accountID, name: self.getAccountName(self.$stateParams.accountID)},
						item: contact
					};
				}
			}
		});

    modalInstance.result.then(function(result){
		self.tableParams.reload();
    });
	};

    onDeleteContact(contact) {
		let self = this;
		let index = _.findIndex(self.siteContacts, (siteContact) => {
			return siteContact._id === contact._id;
		});

		if(index !== -1) {
			self.siteContacts.splice(index, 1);
		}
        contact.remove().then(function() {
			self.tableParams.reload();
        });
    };

	/**
	 * When we are creating a site and are choosing a contact(s), I am receiving self.siteContacts
	 * via bindings. Whenever the user selects a contact with the tickbox I add the contact
	 * to this list
	 */
	toggleSiteContact(contact) {
		let self = this;

		if(contact.siteContact) {
			self.siteContacts.push(contact);
		}
		else {
			let index = _.findIndex(self.siteContacts, (siteContact) => {
				return siteContact._id === contact._id;
			});

			if(index !== -1) {
				self.siteContacts.splice(index, 1);
			}
		}
	}

	/**
	 * A function that can only be invoked if this instance of ContactsListComponent
	 * is being called from NewSiteComponent to be displayed in a modal.
	 */
	exportContact(contact) {
		let self = this;
		self.closeMe(contact);
	}

	applyFilter() {
		this.tableParams.page(1);
		this.tableParams.reload();
	}

	/**
	 * A function that can only be invoked if this instance of ContactsListComponent
	 * is being called from NewSiteComponent to be displayed in a modal.
	 */
	closeMe(contact) {
		let self = this;
		let result = "";

		if(contact) {
			result = contact
		}
		self.modalInstance.close(result);
	}
}

export default angular.module('secutraqApp.contacts')
.component('contactslist', {
	template: require('./contacts.list.html'),
	controller: ContactsListComponent,
	controllerAs: '$ctrl',
	bindings: {
		resolve: '<',
		modalInstance: '<',
		definingSite: '<',
		siteContacts: '='
	}
})
.name;
