export default function($stateProvider) {
  'ngInject';
  $stateProvider
    .state('main.settings', {
      abstract: true,
      url: '/settings',
      authenticate: true,
      data: {
        title: 'Settings'
      }
    })
    .state('main.settings.manageaad', {
        url: '/manageazuread',
        views: {
            'content@main': {
                template: '<manageazuread></manageazuread>'
            }
        },
        authenticate: true,
        data: {
            title: "Manage Azure AD Tenants"
        }
    })

    .state('main.settings.user', {
      url: '/user',
      views: {
        'content@main' : {
          template: '<usersettings></usersettings>'
        }
      },
      authenticate: true,
      data: {
        title: 'User'
      }
    })

    .state('main.settings.user.changepassword', {
      url: '/changepassword',
      views: {
        userTabView : {
          template: '<changepassword></changepassword>'
        }
      },
      authenticate: true
    })
    .state('main.settings.user.manageusers', {
      url: '/manageusers',
      views: {
        userTabView : {
          template: '<manageusers></manageusers>'
        }
      },
      authenticate: 'user'
    })
    .state('main.settings.user.mfasettings', {
      url: '/mfa',
      views: {
        userTabView : {
          template: '<mfa-settings></mfa-settings>'
        }
      },
      authenticate: true
    })
    .state('main.settings.account', {
      url: '/accounts',
      views: {
        'content@main' : {
          template: '<accountsettings></accountsettings>'
        }
      },
      authenticate: true,
      data: {
        title: 'Account'
      }
    })

    .state('main.settings.apikeys', {
      url: '/apikeys',
      views: {
        'content@main' : {
          template: '<apikeys></apikeys>'
        }
      },
      authenticate: 'apiKeys',
      data: {
        title: 'Api Keys'
      }
    })
    .state('main.settings.appversions', {
      url: '/appversions',
      views: {
        'content@main' : {
          template: '<app-version-management></app-version-management>'
        }
      },
      authenticate: true,
      data: {
        title: 'App Management'
      }
    })
    .state('main.settings.events', {
      url: '/events',
      views: {
        'content@main' : {
          template: '<event-settings></event-settings>'
        }
      },
      authenticate: 'edit',
      data: {
        title: 'Event Settings'
      }
    });

}
