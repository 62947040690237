import angular from 'angular';
import uiRouter from 'angular-ui-router';

// import routing from './account.routes'; this won't be necessary
import login from './login';
import reset from './reset';
// import settings from './settings'; NOTE: Moved settings to app
import signup from './signup';
// import projects from './projects'; NOTE: changed to account
// import verify from './verify';

export default angular.module('secutraqApp.account', [uiRouter, login, signup, reset])
  .run(function($rootScope) {
    'ngInject';

    $rootScope.$on('$stateChangeStart', function(event, next, nextParams, current) {
      if(next.name === 'logout' && current && current.name && !current.authenticate) {
        next.referrer = current.name;
      }
    });
  })
  .name;
