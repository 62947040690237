export class DummyEventComponent {
    /*@ngInject*/
    constructor(moment) {
        this.moment = moment;

    }

    $onInit() {
        let self = this;

        self._id = '';
        self.unit = {};
        // self.unitID = '';
        self.eventNum = '';
        self.eventType = '';
        self.loc = {};
        self.site = {};
        self.asset = {};
        self.state = 'Unseen';


        self.eventTypes = self.resolve.settings.eventTypes;
        self.eventTypeInfo = {
            panic: { description: "Panic", },
            callme: { description: "Call Me", },
            geofence: { description: "Geo-Fence", },
            photo: { description: "Photo", },
            photoRequest: { description: "Photo Request", },
            online: { description: "Video", },
            lowbattery: { description: "Battery Low", },
            nocomms: { description: "No Communication", },
            latearrival: { description: "Late Arrival", }
        };
        self.units = self.resolve.settings.units;
        self.sites = [
            {
                sitename: 'Ghanzi District',
                loc: {
                    lat: -26.71452,
                    lng: 27.09745
                },
                address: ['26 Fernleigh Place', 'Hillside Cresent', '2531']
            }, {
                sitename: 'Sub-Depot',
                loc: {
                    lat: -26.71452,
                    lng: 27.09745
                },
                address: ['15 Westernarea', 'Bobweave Midland', '2611']
            }, {
                sitename: 'Orange-Square',
                loc: {
                    lat: -26.71452,
                    lng: 27.09745
                },
                address: ['15 Westernarea', 'Bobweave Midland', '2611']
            }, {
                sitename: 'Cape-harvest-square',
                loc: {
                    lat: -26.71452,
                    lng: 27.09745
                },
                address: ['15 Harvest-square', 'Cubevalley', '3605']
            }, {
                sitename: 'Graaff-Reinet',
                loc: {
                    lat: -26.71452,
                    lng: 27.09745
                },
                address: ['15 Westernarea', 'PadleyPlace', '5016'],

            }
        ];

        self.states = ['Resolved', 'Pending', 'Unseen', 'Touched'];
        self.assets = [
            { _id: 1, type: 'human', name: 'John Doe', cell: '083 262 6366' } ,
            { _id: 2, type: 'human', name: 'Steve Butler', cell: '083 123 7456' } ,
            { _id: 3, type: 'human', name: 'Mark Windlell', cell: '083 234 2534' } ,
            { _id: 4, type: 'human', name: 'Mark Dever', cell: '082 322 3416' } ,
            { _id: 5, type: 'human', name: 'AW Tozer', cell: '083 312 9956' } ,
            { _id: 6, type: 'human', name: 'Martin Holdt', cell: '016 666 4655' } ,
            { _id: 7, type: 'human', name: 'Andre Basson', cell: '016 666 4655' } ,
            { _id: 8, type: 'human', name: 'Tsepo Mamabolo', cell: '016 666 4655' } ,
            { _id: 9, type: 'human', name: 'Clive Given', cell: '016 666 4655' } ,
            { _id: 10, type: 'human', name: 'Mark Ranger', cell: '016 666 4655' } ,
            { _id: 11, type: 'human', name: 'James Brother', cell: '016 666 4655' } ,
            { _id: 12, type: 'human', name: 'Jakob du Toit', cell: '016 666 4655' } ,
            { _id: 13, type: 'human', name: 'Marco Barnard', cell: '016 666 4655' } ,
            { _id: 14, type: 'human', name: 'Leroy Jenkins', cell: '016 666 4655' } ,
            { _id: 15, type: 'human', name: 'Albert Louwrens', cell: '016 666 4655' } ,
            { _id: 16, type: 'human', name: 'John Creeve', cell: '016 666 4655' } ,
            { _id: 17, type: 'human', name: 'Margaret Thatcher', cell: '016 666 4655' } ,
        ];

        self.snapshot = {
            site: self.site,
            data: `assets/images/dummyfootages/${self.eventNum}.jpg`,
            dataThumbnail: "http://lorempixel.com/200/100",
            storage: 'lorem'
        };

    }

    doLog() {
        let self = this;
		console.debug(this);

    }

    unitSelected(unit) {
        let self = this;
        self.asset = unit.asset;
        self.loc = unit.loc;
    }

    generateNumber(min,max) {
        return Math.floor(Math.random()*(max-min+1)+min);
    }

    generateAndClose() {
        let self = this;
        let event
        = {
            _id: self.generateNumber(1,20000),
            unitNumber: self.unit.unitNumber,
            eventType: self.eventType,
            description: self.eventTypeInfo[self.eventType].description,
            site: self.site,
            sitename: self.site.sitename,
            state: self.state,
            createdAt: self.moment.utc(),
            closedAt: undefined,
            closer: {
                type: "ObjectID",
                ref: "User"
            },
            closername: "",
            loc: self.loc,
            account: {
                type: "ObjectID",
                ref: "Account"
            },
            unit: {
                _id: self.unit._id,
                unitNumber: self.unit.unitNumber,
                online: '' //this is implicitly assumed to be true
            },
            accountname: "JerichoSystems",
            active: true,
            asset: self.asset,
            snapshots: [{
                site: self.site,
                data: `assets/images/dummyfootages/${self.generateNumber(1,8)}.jpg`,
                // data: "http://lorempixel.com/640/480",
                dataThumbnail: "http://lorempixel.com/200/100",
                storage: 'lorem'
            }
        ]};

        self.modalInstance.close(event);
    }


}

export default angular.module('secutraqApp.dashboard')
.component('newDummyEvent', {
    template: require('./dummy-event.html'),
    controller: DummyEventComponent,
    controllerAs: "$ctrl",
    bindings: {
        resolve: '<',
        modalInstance: '<'
    }
})
.name;
