'use strict';
// @flow

export class ManageAzureADComponent {
	Auth;

	/*@ngInject*/
	constructor(Auth, $scope, $stateParams, Restangular, NgTableParams, $uibModal, socket, $log, moment, toastr, appConfig, $ngConfirm, $http) {
		this.Auth = Auth;
		this.$scope = $scope;
		this.$stateParams = $stateParams;
		this.Restangular = Restangular;
		this.NgTableParams = NgTableParams;
		this.$uibModal = $uibModal;
		this.socket = socket;
		this.$log = $log;
		this.moment = moment;
		this.filter = '';
		this.isColumnsCollapsed = false;
		this.getCurrentUser = Auth.getCurrentUserSync;
		this.toastr = toastr;
		this.appConfig = appConfig;
		this.$ngConfirm = $ngConfirm;
		this.$http = $http;
	}

	$onInit() {
		let self = this;
		self.$stateParams.accountID = self.Auth.getCurrentAccountSync().ref;

		self.tenants = [];

		/**
		 * An array of columns that are showed by default.
		 * We iterate through all the columns and mark those with VIEW set to true
		 */
		self.selectedColumns = [];

		self.cols = [{
			title: "Alias",
			field: "alias",
			show: true,
			sortable: 'alias',
			context: self
        }, {
            title: 'ID',
            field: 'tid',
            show: true,
            sortable: 'tid',
            context: self
        },{
            title: 'Accounts',
            field: 'accounts',
            show: true,
            sortable: 'accounts',
            context: self
        }];

        /**
         * The purpose of colValues is to contain the HTML-code that we want
         * to be assumed in NgTable.
         * TODO: This still needs to be implemented, but I think this should stop
         * angular from calling handleDisplay() function continuously
         * We can then use $interval to handle update-intervals.
         * @type {Object}
         */
        self.colValues = {};

		_.forEach(self.cols, (col) => {
			if(col.show) {
				self.selectedColumns.push(col.title);
			}
			self.colValues[col.field] = '';
		});

        self.tableParams = new self.NgTableParams({
            page: 1, // start with first page
            count: 10, // count per page
            sorting: {
                name: 'asc' // initial sorting
            }
        }, {
            total: 0,
            getData(params) {
                let order;
                if (params && params.sorting) {
                    order = params.sorting();
                    return self.$http.get('/api/adTenant/', {
                        params: {
                            filter: self.filter.length ? self.filter : undefined,
                            skip: (params.page() - 1) * params.count(),
                            allAccounts: true,
                            //accountID: self.$stateParams.accountID,
                            limit: params.count(),
                            by: Object.keys(order)[0],
                            order: order[Object.keys(order)[0]]
                        }
                    }).then(function(tenants) {
                        self.tenants = tenants.data.data;
                        self.total = tenants.data.total;
                        params.total(tenants.data.total);
                        return tenants.data.data;
                    })
                        .catch(err => {
                            console.error("Error caught when getting data for tenants: ", err.data.err);
                        });
                }
            }
        });
		self.tableParams.reload();
	}


	$onDestroy() {
		let self = this;
		//self.tenantService.unregisterListener(self);
	}


	onReceiptOfTenant(event,tenant) {
		let self = this;

		let index = _.findIndex(self.tenants, (storedTenant) => {
			return storedUser._id === user._id;
		});

		if(index !== -1) {
			if(event == 'deleted') {
				self.users.splice(index, 1);
			}else {
				self.users.splice(index, 1, user);
			}
		}
	}


	onColumnSelected($item, $model) {
		$item.show = true;
	}

	onColumnRemoved($item, $model) {
		$item.show = false;
	}

	applyFilter() {
		this.tableParams.page(1);
		this.tableParams.reload();
	}

	reloader() {
		let self = this;
		self.tableParams.reload();
	}

	/**
	 * Upon desiring to add a new tennant we execute this function from HTML.
	 * in fact tell the server about the new tenant, and then retrieve the new tenant from there
	 */
	createTenant() {
		let self = this;

		let modalInstance = self.$uibModal.open({
			component: 'createtenant',
			size: 'm',
			backdrop: 'static',
			resolve: {
				settings() {
					return {
						edit: false,
						create: true,
						account: {ref: self.$stateParams.accountID, name: self.getAccountName(self.$stateParams.accountID)}
					};
				}
			}
		});

		modalInstance.result.then((tenant) => {
			// TODO: toast
			self.reloader();
		});
	}

	/**
	 * Upon desiring to edit a tenant we execute this function from HTML.
	 */
	editTenant(tenant) {
		let self = this;
		let tenantCopy = _.clone(tenant);

		let modalInstance = self.$uibModal.open({
			component: 'createtenant',
			size: 'm',
			backdrop: 'static',
			resolve: {
				settings() {
					return {
						edit: true,
						tenant: tenantCopy
					};
				}
			}
		});

		modalInstance.result.then((tenant) => {
			//NOTE We should send the new, edited tenant to the server
			// TODO: toast
			self.reloader();
		});
	}

    doLog() {
        let self = this;
        console.debug(self);
    }

    getAccountName(accountID) {
        let self = this;
        let user = self.getCurrentUser();
        if(!user.accounts || typeof user.accounts === Array) {
            return 'No Account';
        }
        let account;
        for(let i = 0; i < user.accounts.length; ++i) {
            if(user.accounts[i].ref === accountID) {
                account = user.accounts[i];
                break;
            }
        }
        if(account !== undefined) {
            return account.name;
        }
        return 'No Account';
    }
}

export default angular.module('secutraqApp.settings')
	.component('manageazuread', {
		template: require('./settings.azureAD.html'),
		controller: ManageAzureADComponent,
		controllerAs: '$ctrl'
	})
	.name;
