
export default class FilterController {
    $uibModalInstance;
    $scope;
    $http;
    sites;
    startDate;
    startTime;
    endDate;
    endTime;
    onlyFaces = false;
    onlyRecog = false;
    lastFilter;
    Auth;
    availableUnits = [];
    units;
    assets;
    availableSites = [];
    availableAssets = [];
    toastr;
    quickPickFromOptions = ["Last 10 minutes", "Last 30 minutes", "Last hour", "Last 6 hours", "Last 24 hours", "Last 7 days", "Last 30 days"];

    /*@ngInject*/
    constructor($scope, unitService, siteService, $uibModalInstance, $http, lastFilter, Auth, toastr, moment) {
        this.$uibModalInstance = $uibModalInstance;
        this.unitService = unitService;
        this.siteService = siteService;
        this.$scope = $scope;
        this.$http = $http;
        this.Auth = Auth;
        this.sites = lastFilter.sites;
        this.startDate = lastFilter.startDate;
        this.endDate = lastFilter.endDate;
        this.startTime = lastFilter.startTime;
        this.endTime = lastFilter.endTime;
        this.assets = lastFilter.assets;
        this.units = lastFilter.units;
        this.onlyFaces = lastFilter.onlyFaces;
        this.onlyRecog = lastFilter.onlyRecog;
        this.toastr = toastr;
        this.moment = moment;
    }

    $onInit() {
        this.ok = function(e) {
            this.$uibModalInstance.close({
                sites: this.sites,
                assets: this.assets,
                units: this.units,
                startDate: this.startDate,
                startTime: this.startTime,
                endDate: this.endDate,
                endTime: this.endTime,
                onlyRecog: this.onlyRecog,
                onlyFaces: this.onlyFaces
            });
        };

        this.clearFilter = function(e) {
            this.$uibModalInstance.close({
                sites: undefined,
                units: undefined,
                assets: undefined,
                startDate: undefined,
                startTime: undefined,
                endDate: undefined,
                endTime: undefined,
                onlyRecog: undefined,
                onlyFaces: undefined
            });
        };

        this.cancel = function(e) {
            this.$uibModalInstance.close();
        };


        let self = this;
        //if (this.Auth.hasPrivilegeSync('secuvue.camera')) {
        if (true) {
            //TODO: Find the units
            self.unitService.getUnitsWithQuery().then(unitList => {
                self.availableUnits = [];
                unitList.forEach(unit => {
                    self.availableUnits.push({
                        name: unit.name,
                        id: unit._id
                    });
                });
            });
            self.siteService.getSites().then(siteList => {
                siteList.forEach(site => {
                    self.availableSites.push({
                        name: site.name,
                        id: site._id
                    });
                });
            });
            self.$http.get('/api/contacts/basic').then(assetList => {
                assetList.data.forEach(asset => {
                    self.availableAssets.push({
                        name:`${asset.firstname} ${asset.lastname}`,
                        id:asset._id
                    });
                });
            });
        } else {
            //this.toastr.info(`You require secuvue.camera privileges`, 'Unprivileged : ', {
            //preventOpenDuplicates: true
            //});
        }
    }

    filterChanged() {}

    doLog() {
		console.debug(this);
    }

    quickPickFrom(index, option) {
        let self = this;
        let to = this.moment();
        let from = this.moment();
        self.endDate = to.toDate();
        self.endTime = to.toDate();
        switch (option) {
            case "Last 10 minutes":
                self.startTime = from.subtract(10, "minutes").toDate();
                break;
            case "Last 30 minutes":
                self.startTime = from.subtract(30, "minutes").toDate();
                break;
            case "Last hour":
                self.startTime = from.subtract(1, "hours").toDate();
                break;
            case "Last 6 hours":
                self.startTime = from.subtract(6, "hours").toDate();
                break;
            case "Last 24 hours":
                self.startTime = from.subtract(1, "days").toDate();
                break;
            case "Last 7 days":
                self.startTime = from.subtract(7, "days").toDate();
                break;
            case "Last 30 days":
                self.startTime = from.subtract(30, "days").toDate();
                break;
            default:
        }
        self.startDate = self.startTime;
    }

}
