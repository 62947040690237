import angular from 'angular';
import { Timeline } from 'vis-timeline';
import { DataSet } from 'vis-data';

// TODO: rRules -> We started talking about rather using excluding instead of including types of rules.
// Before we could start implementing this we had to switch over to cameraviewer

export class VisEventTimelineComponent {
	/*@ngInject*/
	constructor(moment, $scope, $timeout, $uibModal, siteService, routeService, eventService, currentEventService, NgTableParams, $filter, $sanitize) {
		this.moment = moment;
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.$uibModal = $uibModal;
		this.$filter = $filter;
		this.$sanitize = $sanitize;
		this.siteService = siteService;
		this.eventService = eventService;
		this.routeService = routeService;
		this.calendarView = 'hour';
		this.currentEventService = currentEventService;
		this.NgTableParams = NgTableParams;
		this.filter = '';
	}

	$onInit() {
		let self = this;
		self.events = [];
		self.eventsTimeline;
		self.throtledRefresh = _.throttle(self.refreshEvents, 2000);
		self.$timeout(function() {
			self.$scope.$watch(() => {
				return self.showEvents;
			}, (show) => {
				if (show) {
					self.initTimeline();
				}
			});
		});
		self.$scope.$watchGroup([
			'$ctrl.calendarView',
		], () => {
			self.setRange();
		});
		self.$scope.$watch('$ctrl.currentEvent',() => {
			if(self.currentEvent && self.currentEvent._id) {
				self.tableParams.reload();
			}
		});
		// self.include = {
		// 	unit:true,
		// 	asset:false,
		// 	site: false,
		// 	shift : false
		// }
		self.cols = [{
				title: "Type",
				field: "eventType",
				show: true,
			}, {
				title: "Unit",
				field: "unitname",
				show: true,
			},
			{
				title: "Asset",
				field: "assetname",
				show: true,
			},
			{
				title: "Site",
				field: "sitename",
				show: true,
			},
			{
				title: "Created At",
				field: "createdAt",
				show: true,
				sortable: "createdAt",
			},
			{
				title: "Closed At",
				field: "closedAt",
				show: true,
			},
		];
		self.tableParams = new self.NgTableParams({
			page: 1, // start with first page
			count: 10, // count per page
			sorting: {
				createdAt: 'asc' // initial sorting
			}
		}, {
			total: 0,
			getData(params) {
				let order;
				if (params && params.sorting && self.showEvents) {
					order = params.sorting();
					let site;
					let unit;
					let asset;
					unit = self.currentEvent.unit;
					if (!self.currentEvent.unit) {
						unit = self.currentEvent._id;
						if (self.currentEvent.lastAsset && self.currentEvent.lastAsset.length > 0) {
							asset = self.currentEvent.lastAsset[0]._id;
						}
					} else {
						if (self.currentEvent.unit !== 'unassigned') {
							unit = self.currentEvent.unit;
						}
						site = self.currentEvent.site;
						asset = self.currentEvent.asset;
					}
					let addOrMatch = [];
					if (unit && unit !== 'unassigned') {
						addOrMatch.push({
							field: 'unit',
							query: unit
						});
					}
					if (site) {
						addOrMatch.push({
							field: 'site',
							query: site
						});
					}
					if (asset) {
						addOrMatch.push({
							field: 'asset',
							query: asset
						});
					}
					if (!asset || !site || !unit) {

						if (self.currentEvent.shif) {
							addOrMatch.push({
								field: 'shift',
								query: self.currentEvent.shift
							});
						}
					}
					return self.eventService.getEventsWithQuery({
							filter: self.filter.length ? self.filter : undefined,
							skip: (params.page() - 1) * params.count(),
							limit: params.count(),
							by: Object.keys(order)[0],
							order: order[Object.keys(order)[0]],
							addOrMatch : addOrMatch.length > 0 ? addOrMatch : undefined,
						}).then(function(events) {
							self.tableEvents = events.data;
							self.total = events.total;
							params.total(events.total);
							return events.data;
						})
						.catch(err => {
							console.error("Error caught when getting data for units: ", err);
						});
				}else{
					self.tableEvents = [];
					self.total = 0;
					params.total(0);
					return [];
				}
			}
		});

	}

	$onDestroy() {

	}

	refreshEvents() {
		let self = this;
		let query = {};
		let window = self.eventsTimeline.getWindow();
		let site;
		let unit;
		let asset;
		unit = self.currentEvent.unit;
		if (!self.currentEvent.unit) {
			unit = self.currentEvent._id;
			if (self.currentEvent.lastAsset && self.currentEvent.lastAsset.length > 0) {
				asset = self.currentEvent.lastAsset[0]._id;
			}
		} else {
			if (self.currentEvent.unit !== 'unassigned') {
				unit = self.currentEvent.unit;
			}
			site = self.currentEvent.site;
			asset = self.currentEvent.asset;
		}
		query.query = {
			$gte: window.start,
			$lte: window.end
		};
		query.field = 'createdAt';
		query.addOrMatch = [];
		if (unit && unit !== 'unassigned') {
			query.addOrMatch.push({
				field: 'unit',
				query: unit
			});
		}
		if (site) {
			query.addOrMatch.push({
				field: 'site',
				query: site
			});
		}
		if (asset) {
			query.addOrMatch.push({
				field: 'asset',
				query: asset
			});
		}
		if (!asset || !site || !unit) {

			if (self.currentEvent.shif) {
				query.addOrMatch.push({
					field: 'shift',
					query: self.currentEvent.shift
				});
			}
		}
		return self.eventService.getEventsWithQuery(query).then((results) => {
			self.setEvents(results.data);
			return results;
		});
	}

	setEvents(events) {
		let self = this;
		self.events = events;
		self.events.forEach((event) => {
			let oldEvents = self.data.get({
				filter: (o) => {
					return o._id == event._id;
				}
			});
			if (oldEvents && oldEvents.length > 0) {
				oldEvents.forEach((o) => {
					o.active = event.active;
					o.activeUsername = event.activeUsername;
					o.title = self.createTooltip(o);
				});

			} else {

				event.start = event.createdAt;
				if (event.unit && event.asset) {
					event.group = [`${event.unit}:${event.asset}`];
				} else if (event.unit) {
					event.group = [`${event.unit}:u`];
				} else if (event.shift && event.asset) {
					event.group = [`${event.shift}:${event.asset}`];
				} else if (event.shift) {
					event.group = [`${event.shift}:u`];
				} else {
					event.group = ['N/A'];
				}
				if (event._id == self.currentEvent._id) {
					event.current = true;
				}
				event.title = self.createTooltip(event);
				let eventCopy = _.cloneDeep(event);
				eventCopy.group = [eventCopy.group[0].split(':')[0]];
				self.data.add(event);
				self.data.add(eventCopy);
				// self.events.push(eventCopy);

			}
		});
		// self.data.clear();
		// self.groups.clear();
		let groupsNames = {};
		self.events.forEach((event) => {
			try {
				if (event.unit) {
					if (!groupsNames[event.unit]) {
						groupsNames[event.unit] = {
							nestedGroups: [],
							id: event.unit,
							content: event.unitname,
							title: event.unitname
						};
					}
					if (event.asset) {
						groupsNames[event.unit].nestedGroups.push({
							id: `${event.unit}:${event.asset}`,
							content: event.assetname,
							title: event.assetname
						});
						groupsNames[event.unit].nestedGroups = _.uniqBy(groupsNames[event.unit].nestedGroups, 'id');
					} else {
						groupsNames[event.unit].nestedGroups.push({
							id: `${event.unit}:u`,
							content: 'UNASSIGNED',
							title: 'UNASSIGNED'
						});
						groupsNames[event.unit].nestedGroups = _.uniqBy(groupsNames[event.unit].nestedGroups, 'id');
					}
				} else if (event.shift) {
					if (!groupsNames[event.shift]) {
						groupsNames[event.shift] = {
							nestedGroups: [],
							id: event.shift,
							content: event.shiftname,
							title: event.shiftname
						};
					}
					if (event.asset) {
						groupsNames[event.shift].nestedGroups.push({
							id: `${event.shift}:${event.asset}`,
							content: event.assetname,
							title: event.assetname
						});
						groupsNames[event.shift].nestedGroups = _.uniqBy(groupsNames[event.shift].nestedGroups, 'id');
					} else {
						groupsNames[event.shift].nestedGroups.push({
							id: `${event.shift}:u`,
							content: 'UNASSIGNED',
							title: 'UNASSIGNED'
						});
						groupsNames[event.shift].nestedGroups = _.uniqBy(groupsNames[event.shift].nestedGroups, 'id');
					}
				}

			} catch (e) {

			}
		});
		// self.data.add(self.events);
		// let unitnames = _.uniq(_.map(self.events,(event) => {
		// 	if(event.unit){
		// 		return event.asset ? event.unitname+'  / '+event.assetname : event.unitname;
		// 	}else if (event.shift) {
		// 		return event.shiftname;
		// 	}else{
		// 		return 'N/A';
		// 	}
		// }));
		_.forEach(groupsNames, (group) => {
			self.groups.add(group.nestedGroups);
			group.nestedGroups = _.map(group.nestedGroups, 'id');
			self.groups.add(group);
		});

		self.$timeout(() => {
			self.eventsTimeline.redraw();
		},1000);


	}

	initTimeline() {
		let self = this;
		if (!self.eventsTimeline) {
			let options = {
				template: self.formatItems.bind(self),
				clickToUse: true,
				stack: false,
				start: self.moment.utc(self.currentEvent.createdAt || self.moment()).subtract(0.3, 'hour'),
				end: self.moment.utc(self.currentEvent.createdAt || self.moment()).add(0.3, 'hour'),
				groupTemplate: (data) => {
					return `<div class="event-timeline-group">${self.$sanitize(data.content)}</div>`;
				}
			};

			let container = document.getElementById(self.containerId);

			self.dummyEvent = {
				id: 'dummyEvent',
				content: "Dummy event",
				start: self.moment.utc(self.currentEvent.createdAt || self.moment()),
				group: ['dummyGroup']
			};

			self.dummyGroup = {
				id: 'dummyGroup',
				content: 'Dummy Group',
				visible: false
			};

			self.data = new DataSet([self.dummyEvent]);
			self.groups = new DataSet([self.dummyGroup]);
			self.eventsTimeline = new Timeline(container, self.data, self.groups, options);
			self.eventsTimeline.moveTo(self.currentEvent.createdAt || self.moment(), {
				animation: false
			});
			self.throtledRefresh();
			self.eventsTimeline.on('rangechanged', () => {
				self.throtledRefresh();
			});
			self.eventsTimeline.on('doubleClick', (event) => {
				let m_event = self.data.get(event.item);
				if (m_event && m_event._id) {
					self.currentEventService.setCurrentEvent(m_event);
				}
			});
		} else {
			self.data.clear();
			self.groups.clear();
			self.eventsTimeline.moveTo(self.currentEvent.createdAt || self.moment(), {
				animation: false
			});
			self.throtledRefresh();
			self.eventsTimeline.setData({
				groups: self.groups,
				data: self.data
			});
		}
	}

	formatItems(item, element, data) {
		let self = this;
		let eventIcon = 'latearrival';
		switch (data.eventType) {
			case 'Panic':
				eventIcon = 'panic';
				break;
			case 'Call Me':

				eventIcon = 'callme';
				break;
			case 'No-comms':
				eventIcon = 'nocomms';
				break;
			case 'Geo-fence In':
				eventIcon = 'geofencein';
				break;
			case 'Geo-fence Out':
				eventIcon = 'geofenceout';
				break;
			case 'Photo':
			case 'Photo - Line Trip':
			case 'Photo - Motion':
			case 'Photo - External Trigger':
			case 'Photo - Scheduled':
				eventIcon = 'photo';
				break;
			case 'Photo Request':
				eventIcon = 'photorequest';
				break;
			case 'Video':
			case 'Video Request':
				eventIcon = 'video';
				break;
			case 'Late Arrival':
				eventIcon = 'latearrival';
				break;
			case 'Low Battery':
				eventIcon = 'lowbattery';
				break;
			case 'Login':
				eventIcon = 'login';
				break;
			default:
				eventIcon = data.eventType.replace(/ |-/g, '').toLowerCase();
		}
		let size = 3;

		if (data.current) {
			size = 4;
		}
		if(self.eventDetails[data.eventType] && self.eventDetails[data.eventType].icon) {

			let eventDetail = self.eventDetails[data,data.eventType];
			let html = `
			<div style='height:${size}em;'>
			<svg viewBox="0 0 100 160" style="height:100%;">
			<path d="M 10 50 A 1 1 0 0 1 90 50 C 90 70 70 70 50 150 C 30 70 10 70 10 50" fill="${self.$sanitize(eventDetail.color)}"/>
			</svg>
			<div class="custom-marker-icon-center-div">
			<i class="${self.$sanitize(eventDetail.icon)} custom-marker-icon" style="font-size:90%;"></i>
			</div>
			</div>
			`;
			self.$timeout( () => {
				let el = angular.element(element);
				el.html(html);
			} )
			return '';
		}else{
			let html = `<img style='height:${size}em;' src='assets/images/markers/${self.$sanitize(eventIcon)}.png'></img>`;
			self.$timeout( () => {
				let el = angular.element(element);
				el.html(html);
			} )
			return '';
		}
	}

	doLog() {
		let self = this;
		console.debug(this);
	}

	createTooltip(event) {
		let self = this;
		let html = `
		<div class='event-timeline-tooltip' style='font-size:smaller;'>
		<div class='row'>
		Event Type : ${self.$filter('formatEventType')(event.eventType,event,self.eventDetails[event.eventType])}
		</div>
		`;
		if (event.unit) {
			html += `
			<div class='row'>
			Unit : ${event.unitname}
			</div>
			`;
		}
		if (event.shift) {

			html += `
			<div class='row'>
				Shift : ${event.shiftname}
			</div>
			`;
		}
		if (event.asset) {
			html += `
			<div class='row'>
			Asset : ${event.assetname || 'UNASSIGNED'}
			</div>
			`;
		}
		if (event.slottype) {
			html += `
			<div class='row'>
			Slot : ${event.slottype}
			</div>
			`;
		}
		if (event.waypointname) {
			html += `
			<div class='row'>
			Waypoint : ${event.waypointname}
			</div>`;
		}
		if (event.metaData && event.metaData.geoZone && event.metaData.geoZone.waypointname) {
			html += `
			<div class='row'>
			Waypoint : ${event.metaData.geoZone.waypointname}
			</div>`;
			html += `
			<div class='row'>
			Route Collection : ${event.metaData.geoZone.routename}
			</div>`;
		} else if (event.site) {

			html += `
			<div class='row'>
			Site : ${event.sitename}
			</div>`;
		}
		if (event.activeUsername) {
			html += `
			<div class='row'>
			Assigned to : ${event.activeUsername}
			</div>`;
		}
		html += `
		<div class='row'>
		${event.active ? 'Open' : 'Closed'}
		</div>
		`;
		html += '</div>';
		return self.$sanitize(html);
	}

	incrementView() {
		let self = this;
		let time = new self.moment(self.getFocusTime());
		time.add(1, self.calendarView);
		self.eventsTimeline.moveTo(time);
	}

	decrementView() {
		let self = this;
		let time = new self.moment(self.getFocusTime());
		time.subtract(1, self.calendarView);
		self.eventsTimeline.moveTo(time);

	}

	setToNow() {
		let self = this;
		self.eventsTimeline.moveTo(self.moment());
	}

	setRange() {
		let self = this;
		if (!self.eventsTimeline) {
			return null;
		}
		let stime = new self.moment(self.getFocusTime());
		let etime = new self.moment(self.getFocusTime());
		stime.subtract(0.5, self.calendarView);
		etime.add(0.5, self.calendarView);
		self.eventsTimeline.setWindow(stime, etime);
	}

	getFocusTime() {
		let self = this;
		let window = self.eventsTimeline.getWindow();
		let stime = new self.moment(window.start);
		let etime = new self.moment(window.end);

		let diff = etime.diff(stime);
		stime.add(diff / 2, 'ms');
		return stime;
	}


	applyFilter() {
		this.tableParams.page(1);
		this.tableParams.reload();
	}

	toggleTimelineView(val) {
		let self = this;
		self.$timeout(function() {
			val = !val;
			if(!val) {
				self.throtledRefresh();
			}
		}, 100);
	}

	loadTable() {
		let self = this;
		self.$timeout(function() {
			self.tableParams.reload();
		});
	}
}

export default angular.module('directives.visEventTimeline', [])
	.component('visEventTimeline', {
		template: require('./vis-events-timeline.html'),
		controller: VisEventTimelineComponent,
		controllerAs: "$ctrl",
		bindings: {
			currentEvent: '<',
			eventDetails: '=',
			// view: '<',
			// viewDate: '<',
			// viewTitle: '<',
			// gridsterOptions: '<',
			// startTime: '<',
			// tickLenght: '<', //Minutes
			// tickAmount: '<',
			// definingSchedule: '<',
			// scheduleDefinition: '<',
			// minimumDurationSeconds: '<',
			showEvents: '=',
			gridView: '=',
			containerId: '<'
		}
	}).name;
