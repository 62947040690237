import angular from 'angular';
import uiRouter from 'angular-ui-router';
import uiBootstrap from 'angular-ui-bootstrap';
import moment from 'moment';
import uiScroll from 'angular-ui-scroll';
import uiScrollGrid from 'angular-ui-scroll/dist/ui-scroll-grid';
import _ from 'lodash-es';
import ngSticky from 'ngsticky';
import angularMoment from 'angular-moment';
import uiSelectInfinity from 'ui-select-infinity';
import uiSelect from 'ui-select';
//import Dropzone from 'dropzone';
//import 'ngdropzone';
import routes from './photoAnalytics.routes';

export default angular.module('secutraqApp.photoAnalytics', [uiRouter,
'thatisuday.dropzone',
uiRouter, uiBootstrap,
'ui.scroll',
'ui.scroll.grid',
'sticky',
uiSelect,
'ui-select-infinity',
'angularMoment'])
    .config(routes)
    .filter('escape', function(){
        return function(input) {
            if (input) {
                return window.encodeURIComponent(input);
            }
            return "";
        };
    })
    .name;

require('./faceCollection/faceCollection.component');
require('./plateCollection/plateCollection.component');
require('./imageCollection/imageCollection.component');
