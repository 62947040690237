export class ListRoutesComponent {
    /*@ngInject*/
    constructor($uibModal, $scope, routeService, numbersService, $ngConfirm, Auth, toastr, scheduleService, shiftService, moment) {
        this.$uibModal = $uibModal;
        this.$scope = $scope;
        this.routeService = routeService;
        this.numbersService = numbersService;
        this.$ngConfirm = $ngConfirm;
        this.Auth = Auth;
        this.toastr = toastr;
        this.scheduleService = scheduleService;
        this.shiftService = shiftService;
        this.moment = moment;

    }

    $onInit() {
        let self = this;

        // self.$scope.$on('routeDelivery', ($event, data) => {
        //     self.routeCollections = data.routeCollections;
        // });


    }

    /**
     * Handles the creation of a new route. NOTE that we are actually using our routeService for this and that it seems
     * to be working quite nicely
     */
    newRoute() {
        let self = this;
        self.routeService.createNewRoute()
        .then((data) => {
            // self.$scope.$emit('routeArrived', {routeCollection: result});
        });
    }

    /**
     * Handles the editing of an existing route. NOTE that we are actually using our routeService for this and that it seems
     * to be working quite nicely
     */
    editRoute(routeCollection, route) {
        let self = this;
        self.routeService.editRoute(routeCollection, route)
        .then((data) => {
            //TODO: rethink the use of an internal _id
            // self.$scope.$emit('routeArrived', {routeCollection: data});
        })
        .catch((error) => {
            console.error(error);
        });
    }

    /**
     * Handles the removal of an existing route.
     */
     removeRoute(routeCollection, route) {
         let self = this;
         let promisses = [];
         let schedules = self.scheduleService.getScheduleWithQuery({query:route._id,field:'slots.typeId', activeOnly:'true'});
         let shifts = self.shiftService.getShiftWithQuery({activeOnly:'true',query:route._id,field:'slots.typeId', between:{start:self.moment().valueOf(), end:self.moment().add(1,'hour')
.valueOf()}});
         promisses.push(schedules);
         promisses.push(shifts);

         Promise.all(promisses).then((results) => {
             let disabled = false;
             let content = '';
             if(results) {
                 if(results[0] && results[0].total > 0) {
                     content += `<br>This route is linked to ${results[0].total} schedule${results[0].total > 1 ? 's' : ''}. Unlink schedule${results[0].total > 1 ? 's' : ''} before removing.`;
                     disabled = true;
                 }
                 if(results[1] && results[1].total > 0) {
                     disabled = true;
                     content = `<br>An active shift is linked to this route.  The shift needs to finish before the route can be removed.`;
                 }
             }
             if(!disabled) {
                 content += `Clicking <b>OK</b> would remove this route`;
             }
             self.$ngConfirm(
                 {
                     title: `Remove <b>${route.name}</b>?`,
                     theme: 'supervan',
                     content,
                     escapeKey: true,
                     backgroundDismiss: true,
                     buttons: {
                         ok: {
                             text: "Ok",
                             btnClass: `btn-primary ${disabled ? 'hidden' : ''}`,
                             keys: ['enter'], // will trigger when enter is pressed
                             action(scope) {
                                 self.routeService.removeRoute(routeCollection, route);
                             }
                         },
                         close(scope) {

                         }
                     },
                 }
             );
         });
    }

    /**
     * Does a log, yo
     */
    doLog() {
		console.debug(this);
    }

}

export default angular.module('secutraqApp.routes')
.component('listRoutes', {
    template: require('./route-list.html'),
    controller: ListRoutesComponent,
    controllerAs: "$ctrl",
    bindings: {
        routeCollections: '<',
    }
});
