'use strict';

export default function($stateProvider) {
  'ngInject';
  $stateProvider
    .state('main.units', {
  		abstract: true,
  		url: '/units',
        authenticate: 'units',
        data: {
            title: 'Units'
        }
    })

    .state('main.units.list', {
		url: '/',
		views: {
			'content@main' : {
				template: '<unitlist></unitlist>'
			}
		},
        authenticate: true,
        data: {
            title: 'List'
        }
    })

    // .state('main.units.detail', {
	// 	url: '/:unitID',
	// 	views: {
	// 		'content@main' : {
    //             template: '<unitdetail></unitdetail>'
	// 		}
	// 	},
    //     authenticate: 'units.detail',
    //     abstract: true,
    //     data: {
    //         title: 'Detail'
    //     }
    // })

    // .state('main.units.detail.charts', {
	// 	url: '',
	// 	views: {
	// 		'tabView' : {
	// 			template: '<unitdetailcharts></unitdetailcharts>'
	// 		}
	// 	},
    //     authenticate: 'units.charts',
    // })
    //
    // .state('main.units.detail.heartbeats', {
	// 	url: '/heartbeats',
	// 	views: {
	// 		'tabView' : {
	// 			template: '<unitheartbeats></unitheartbeats>'
	// 		}
	// 	},
    //     authenticate: 'units.heartbeats'
    // })
    //
    // .state('main.units.detail.clients', {
	// 	url: '/clients',
	// 	views: {
	// 		'tabView' : {
	// 			template: '<unitclients></unitclients>'
    //         }
    //     },
    //     authenticate: 'units.clients'
    // })
    //
    // .state('main.units.detail.manage', {
	// 	url: '/manage',
	// 	views: {
	// 		'tabView' : {
	// 			template: '<unitmanage></unitmanage>'
	// 		}
	// 	},
    // authenticate: 'units.manage'
    // })
	;
}
