require('@tensorflow/tfjs-backend-cpu');
const blazeface = require('@tensorflow-models/blazeface');
const QRCode = require('qrcode');

export class ContactModalController {
	/*@ngInject*/
	constructor($stateParams, Restangular,toastr, privGroupService, Auth, $timeout, $sce) {
		this.$stateParams = $stateParams;
		this.Restangular = Restangular;
		this.toastr = toastr;

		//this.availableIds = ['iButton', 'RFID', 'QR', 'Custom'];
		this.availableIds = ['iButton', 'QR', 'Custom'];
		this.valid = true;
		this.privGroupService = privGroupService;
		this.Auth = Auth;
		this.$timeout = $timeout;

		this.$sce = $sce;
		this.qrCodePopoverHtml = this.$sce.trustAsHtml('<canvas id="qrCodeCanvas"></canvas>');
	}

	$onInit() {
		let self = this;
		self.settings = self.resolve.settings;
		self.$uibModalInstance = self.modalInstance;
		self.Contacts = self.Restangular.all('contacts');
		self.currentAccount = self.Auth.getCurrentAccountSync().ref;
		self.blazefaceModel = blazeface.load();
		self.popovers = {};
		self.$timeout(()=>{
			let file = document.getElementById("referenceImage");
			if(file) {
				file.addEventListener("change", self.add.bind(self));
			}
		},0);
		// Add alert data to controller
		if (self.settings.item) {
			let item = self.settings.item;
			self.contact = self.Restangular.copy(item);
			self.edit = true;
			// if(self.contact.ids && self.contact.ids.length > 0){
			// 	_.forEach(self.contact.ids,(id)=>{
			// 		id.id = id.id.toString('hex');
			// 	})
			// }
			if(!self.contact.biometricLogin) {
				self.contact.biometricLogin = {};
			}
			if(!self.contact.biometricLogin.facialVerification) {
				self.contact.biometricLogin.facialVerification = {enabled:false};
			}
		} else {
			self.edit = false;
			self.contact = {
				account: self.settings.account,
				groups: [],
				biometricLogin:{
					facialVerification:{
						enabled: false
					}
				}
				// assetIds: []
			};
		}
		self.Contacts = self.Restangular.all('contacts');
		self.Groups = self.Contacts.all('groups');
		self.availableGroups = [];

	}


	refreshGroups() {
		let self = this;
		self.privGroupService.getGroupWithQuery().then( (groups) => {
			self.availableGroups = groups;
		} )
			.catch( (err) => {
				console.error(err);
			} );
	}
	// Setup all the models for display
	// Modal accept: send resulting object back to promise in parent controller
	ok() {
		let self = this;
		let result = self.contact;
		// _.forEach(result.ids,(id)=>{
		// 	id.id = new Buffer.from(id.id,'hex');
		// })
		if(self.edit) {
			result.patch()
				.then(function(result) {
					self.toastr.info('Contact saved.');
					self.$uibModalInstance.close(result);
					// self.toastr.error(`Contact create failed.`);
				})
				.catch((err) => {
					console.error(err);
					try {
						err.data = JSON.parse(err.data);
					} catch(e) {

					}
					if(err.data && err.data.code == 11000 && err.data.errmsg && err.data.errmsg.includes('account_1_assetIds.id_1') || err.data.includes('E11000') && err.data.includes('account_1_assetIds.id_1')) {
						self.toastr.error(`Contact create failed.`,'Asset Id already in use.');
					}else if(err.data.includes('already assigned')) {
						self.toastr.error(`Tag ID already in use.`);
					}else {
						self.toastr.error(`Contact create failed.`);
					}
				});

		}else {
			self.Contacts.post(result, {accountID: self.$stateParams.accountID})
				.then(function(result) {
					self.toastr.info('Contact saved.');
					self.$uibModalInstance.close(result);
					// self.toastr.error(`Contact create failed.`);
				})
				.catch((err) => {
					console.error(err);
					try {
						err.data = JSON.parse(err.data);
					} catch(e) {

					}
					if(err.data && err.data.code == 11000 && err.data.errmsg && err.data.errmsg.includes('account_1_assetIds.id_1') || err.data.includes('E11000') && err.data.includes('account_1_assetIds.id_1')) {
						self.toastr.error(`Contact create failed.`,'Asset Id already in use.');
					}else if(err.data == 'iButton already assigned') {
						self.toastr.error(`iButton already in use.`);
					}else {
						self.toastr.error(`Contact create failed.`);
					}
				});
		}
	}

	onGroupAdded() {
		let self = this;
		let label = " (create new group)";
		let tempGroups = [];
		_.forEach(self.contact.groups, (group) => {
			if (group.slice(-label.length) === label) {
				group = group.slice(0, -label.length);
			}
			tempGroups.push(group);
		});
		self.contact.groups = tempGroups;
	}

	onIdAdded() {
		let self = this;
		if(!self.contact.assetIds) {
			self.contact.assetIds = [];
		}
		self.contact.assetIds.push({idType:self.availableIds[0],id:''});
	}

	removeId(index) {
		let self = this;
		self.contact.assetIds.splice(index,1);
	}

	validateId(index) {
		let self = this;
		let thisValid = true;
		let id = self.contact.assetIds[index];
		if(typeof id.id === 'string') {
			if(id.id == '') {
				thisValid = false;
			}
			switch (id.idType) {
				case 'iButton':
					let matchArray = id.id.match(/^(0x|0X)?[a-fA-F0-9]{16}$/);
					if(!id.id == "") {
						if(!matchArray || matchArray.length == 0) {
							thisValid = false;
						}
					}
					break;
			}
		}
		let duplicate = _.find(self.contact.assetIds, (otherId, otherIndex) => {
			return id.idType == otherId.idType && id.id == otherId.id && index !== otherIndex;
		} );
		if(duplicate) {
			return false;
		}
		return thisValid;
	}

	toggleAssetTracking() {
		let self = this;
		if(!self.contact.asset) {
			delete self.contact.assetIds;
		}else {
			self.contact.assetIds = [];
		}
	}

	// Modal cancelled: either cancel pressed or clicked off screen
	cancel() {
		this.$uibModalInstance.dismiss('Cancel pressed');
	}

	validateReferenceImage(file) {
		let self = this;
		let promise = new Promise( (res,rej) => {

			let reader = new FileReader();
			reader.addEventListener('load', (e) => {
				res(e.target.result);
			});
			reader.readAsDataURL(file);
		} );
		if(file.size >= 5242880) {
			self.toastr.error('Photo too large, please use a smaller photo');
			return Promise.resolve(false);
		}
		return promise
			.then( (dataUrl) => {
				return self.convertURIToImageData(dataUrl);
			} )
			.then( (image) => {
				return Promise.all([self.blazefaceModel,image]);
			} )
			.then( (data) => {
				let model = data[0];
				let image = data[1];
				return model.estimateFaces(image);
			} )
			.then( (faces) => {
				// TODO: Check size <05-02-21, liaan> //
				if(faces.length == 1) {
					return self.checkAmazonValid(file);
				}else if(faces.length > 1) {
					self.toastr.error("Photo contains multiple faces");
					return false;
				}else {
					self.toastr.error("No face detected in photo, please use a better quality photo");
					return false;
				}
			} );
	}

	checkAmazonValid(file) {
		let self = this;
		let formData = new FormData();
		formData.append('file', file);
		return self.Restangular.one('contacts/validateReference')
			.withHttpConfig({transformRequest: angular.identity})
			.customPOST(formData, '', undefined, {'Content-Type': undefined})
			.then( (result) => {
				return result;
			} );
	}

	convertURIToImageData(URI) {
		return new Promise(function(resolve, reject) {
			if (URI == null) return reject();
			var canvas = document.createElement('canvas');
			var context = canvas.getContext('2d');
			var image = new Image();
			image.addEventListener('load', function() {
				canvas.width = image.width;
				canvas.height = image.height;
				context.drawImage(image, 0, 0, canvas.width, canvas.height);
				resolve(context.getImageData(0, 0, canvas.width, canvas.height));
			}, false);
			image.src = URI;
		});
	}

	add(ev) {
		let self = this;
		self.checkingFace = true;

		let f = ev.target.files[0];

		self.validateReferenceImage(f)
			.then( (valid) => {
				if(valid) {
					self.contact.biometricLogin.facialVerification.referencePhoto = {
						_id:valid._id,
						data: valid.data,
						dataThumbnail: valid.dataThumbnail
					};
				}
			})
			.catch( (err) => {
				if(err.data == 'No face detected') {
					self.toastr.error("No face detected in photo, please use a better quality photo");
				}else if(err.data == 'Multiple faces') {
					self.toastr.error("Photo contains multiple faces");
				}else{
					self.toastr.error('Photo not valid');
					console.error(err);
				}
			} )
			.finally( () => {
				self.checkingFace = false;
			} );
	}

	uploadImage() {
		let file = document.getElementById("referenceImage");
		file.click();
	}

	doLog() {
		console.debug(this);
	}

	showQR(id, index) {
		let self = this;
		if(self.popovers[index]) {
			self.popovers[index] = false;
			return null;
		}
		self.$timeout( () => {
			_.forEach(self.popovers, (popover, index) => {
				self.popovers[index] = false;
			});
		} );
		if(id) {
			self.$timeout( () => {
				self.popovers[index] = true;
			} , 100);
			self.$timeout( () => {
				let canvas = document.getElementById('qrCodeCanvas');
				QRCode.toCanvas(canvas,id)
					.catch(err => {
						console.error(err);
					});
			} ,200);
		}
	}
}

export default angular.module('secutraqApp.contacts')
	.component('contactmodal', {
		template: require('./contacts.modal.html'),
		bindings: {
			resolve: '<',
			close: '&',
			dismiss: '&',
			modalInstance: '<'
		},
		controller: ContactModalController,
		controllerAs: '$ctrl'
	})
	.name;
// .controller(ContactModalController).name;
