import angular from 'angular';
import uiRouter from 'angular-ui-router';
import routes from './sops.routes';
import uiBootstrap from 'angular-ui-bootstrap';
import NgTable from 'ng-table/bundles/ng-table';
import ngSanitize from 'angular-sanitize';


export default angular.module('secutraqApp.sops', [uiRouter, 'ngTable', uiBootstrap])
  .config(routes)
  .name;
require('./sops.component');
require('./sop-list/sop-list.component');
require('./sop-list/new-sop/new-sop.component');
require('./sop-list/manage-sop-tags/manage-sop-tags.component');
require('./custom-event-type/custom-event-type.component');
// require('./site-detail/site-detail.component');
