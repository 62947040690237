import angular from 'angular';
import uiRouter from 'angular-ui-router';
import uiBootstrap from 'angular-ui-bootstrap';

import uiSelect from 'ui-select';
import Restangular from 'restangular';
import NgTable from 'ng-table/bundles/ng-table'; // TODO figure out why this is needed
// import NgTable from 'ng-table';

import routes from './privGroups.routes';

export default angular.module('secutraqApp.privGroups',
    [uiRouter, uiBootstrap, Restangular, 'ngTable', uiSelect])
.config(routes)
.name;
require('./privGroups.component');
