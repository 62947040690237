export class SubtabsComponent {

    /*@ngInject*/
    constructor(mapService, $timeout, currentEventService) {
        this.mapService = mapService;
        this.$timeout = $timeout;
        this.currentEventService = currentEventService;


    }

    $onInit() {
        let self = this;

        this.mapService.getMetaData().then((metaData) => {
            self.mapMetaData = metaData;

            self.$timeout(() => {
                self.mapMetaData.activeTab = 'Events'; //different tabs' content are displayed, based on this value
            }, 1500);
        });

        this.currentEventService.getMetaData().then((metaData) => {
            self.currentEventMetaData = metaData;
        });
        self.tabs = [
            {
                title: "Events",
                icon: "fa-fw fa fa-caret-square-o-right"
            },
            {
                title: "Settings",
                icon: "fa-fw far fa-cog"
            },

            // {
            //     title: "Attendance",
            //     icon: "fa-fw fa fa-child"
            // },

            // {
            //     title: "Schedule",
            //     icon: "fa-fw fa fa-calendar-o"
            // },

            // {
            //     title: "Routes",
            //     icon: "fa-fw fa fa-road"
            // },

            // {
            //     title: "Photos",
            //     icon: "fa-fw fa fa-camera"
            // },
            //
            // {
            //     title: "Videos",
            //     icon: "fa-fw fa fa-video-camera"
            // },
            //
            // {
            //     title: "Filter",
            //     icon: "fa-fw fa fa-map-marker"
            // }
        ];


    }

    /**
     * When a tab gets clicked it is bound to the variable, 'mapMetaData.activeTab'.
	 * When the active tab is selected the tab content should be hidden
     */
	tabSelected(hide) {
		let self = this;
		if(hide) {
			self.mapMetaData.activeTab = '';
		}
	}

}
export default angular.module('secutraqApp.dashboard')
.component('appSubtabs', {
    template: require('./subtabs.component.html'),
    controller: SubtabsComponent,
    controllerAs: '$ctrl',
    bindings: {
        eventDetails: '=',
        currentEvent: '='

    }
})
.name;
