import angular from 'angular';
import uiRouter from 'angular-ui-router';
import routing from './main.routes';

export class MainController {
	/*@ngInject*/
	constructor($http, $scope, socket,Auth, eventService, customEventTypeService, $localStorage, mapService, $uibModal, appConfig) {
		this.$http = $http;
		this.socket = socket;
		this.Auth = Auth;
		this.customEventTypeService = customEventTypeService;
		this.eventService = eventService;
		this.mapService = mapService;
		this.$localStorage = $localStorage;
		this.$uibModal = $uibModal;
		this.appConfig = appConfig;
	}

	$onInit() {
		let self = this;
		self.allEventDetails = {
			Panic: {
				description: "Panics",
				icon: "fa-fw fas fa-exclamation",
				color: "#e61610",
				colorlight: "rgba(230, 22, 16, 0.5)",
				number: 2,
				filtered : false,
				notification: 'assets/sounds/notifications/13.wav'
			},
			'Call Me': {
				description: "Call Me",
				icon: "fa-fw fas fa-phone",
				color: "#00a4d3",
				colorlight: "rgba(0, 164, 211, 0.5)" ,
				number: 2,
				filtered : false,
			},
			'Geo-fence Out': {
				description: "Geo-Fence Out",
				// icon: "fa-fw fa fa-map-marker",
				color: "#92C933",
				colorlight: "rgba(146, 201, 51,0.5)",
				number: 1,
				filtered : false,
				splitEvents:[
					{
						alias:"Site Geo-fence Out (GPS)",
						filterFunction: (event,events,index) => {
							if(event.metaData) {
								return false;
							}else{
								return true;
							}
						},
					},
					{
						alias:"Site Geo-fence Out (BLE)",
						filterFunction: (event,events,index) => {
							if(event.metaData) {
								if(event.metaData.tagType == "Bluetooth") {
									return true;
								}else{
									return false;
								}
							}else{
								return false;
							}
						},
					},
					{
						alias:"Waypoint Geo-fence Out (GPS)",
						filterFunction: (event,events,index) => {
							if(event.metaData) {
								if(event.metaData && event.metaData.geoZone && !event.metaData.geoZone.tagType) {
									return true;
								}else{
									return false;
								}
							}else{
								return false;
							}
						},
					},
					{
						alias:"Waypoint Geo-fence Out (BLE)",
						filterFunction: (event,events,index) => {
							if(event.metaData) {
								if(event.metaData && event.metaData.geoZone && event.metaData.geoZone.tagType == "Bluetooth") {
									return true;
								}else{
									return false;
								}
							}else{
								return false;
							}
						},
					},
				]
			},
			'Geo-fence In': {
				description: "Geo-Fence In",
				// icon: "fa-fw fa fa-map-marker",
				color: "rgb(116, 148, 75)",
				colorlight: "rgb(116, 148, 75, 0.5)",
				number: 1,
				filtered : false,
				splitEvents:[
					{
						alias:"Site Geo-fence In (GPS)",
						filterFunction: (event,events,index) => {
							if(event.metaData) {
								return false;
							}else{
								return true;
							}
						},
					},
					{
						alias:"Site Geo-fence In (BLE)",
						filterFunction: (event,events,index) => {
							if(event.metaData) {
								if(event.metaData.tagType === "Bluetooth") {
									return true;
								}else{
									return false;
								}
							}else{
								return false;
							}
						},
					},
					{
						alias:"Waypoint Geo-fence In (GPS)",
						filterFunction: (event,events,index) => {
							if(event.metaData) {
								if(event.metaData && event.metaData.geoZone && !event.metaData.geoZone.tagType) {
									return true;
								}else{
									return false;
								}
							}else{
								return false;
							}
						},
					},
					{
						alias:"Waypoint Geo-fence In (BLE)",
						filterFunction: (event,events,index) => {
							if(event.metaData) {
								if(event.metaData && event.metaData.geoZone && event.metaData.geoZone.tagType == "Bluetooth") {
									return true;
								}else{
									return false;
								}
							}else{
								return false;
							}
						},
					},
				]
			},
			Photos:{
				description: "Photos",
				defaultEventType:"Photo",
				icon: "fa-fw fa fa-camera",
				color: "rgb(32, 131, 110)",
				colorlight: "rgb(32, 131, 110,0.5)",
				number: 3,
				count: 0,
				subEvents: [
					'Photo',
					'Photo - Line Trip',
					'Photo - Motion',
					'Photo - External Trigger',
					'Photo - Scheduled',
				],
				filtered : false

			},
			Photo: {
				description: "Photo",
				icon: "fa-fw fa fa-camera",
				color: "rgb(32, 131, 110)",
				colorlight: "rgb(32, 131, 110,0.5)",
				number: 18,
				groupUnder : 'Photos',
				filtered : false,
			},
			'Photo - Line Trip': {
				description: "Photo - Line Trip",
				icon: "fa-fw fa fa-camera",
				color: "rgb(32, 131, 110)",
				colorlight: "rgb(32, 131, 110,0.5)",
				number: 18,
				groupUnder : 'Photos',
				filtered : false,
			},
			'Photo - Motion': {
				description: "Photo - Motion",
				icon: "fa-fw fa fa-camera",
				color: "rgb(32, 131, 110)",
				colorlight: "rgb(32, 131, 110,0.5)",
				number: 18,
				groupUnder : 'Photos',
				filtered : false,
			},
			'Photo - External Trigger': {
				description: "Photo - External Trigger",
				icon: "fa-fw fa fa-camera",
				color: "rgb(32, 131, 110)",
				colorlight: "rgb(32, 131, 110,0.5)",
				number: 18,
				groupUnder : 'Photos',
				filtered : false,
			},
			'Photo - Scheduled': {
				description: "Photo - Scheduled",
				icon: "fa-fw fa fa-camera",
				color: "rgb(32, 131, 110)",
				colorlight: "rgb(32, 131, 110,0.5)",
				number: 18,
				groupUnder : 'Photos',
				filtered : false,
			},
			'Photo Request': {
				description: "Photo Requests",
				icon: "fa-fw fa fa-camera",
				color: "rgb(6, 77, 54)",
				colorlight: "rgb(6, 77, 54, 0.5)",
				number: 18,
				filtered : false,
			},
			Videos: {
				description: "Videos",
				icon: "fa-fw fa fa-video-camera",
				defaultEventType:"Video",
				color: "#E5C627",
				colorlight: "rgba(229, 198, 39, 0.5)",
				number: 18,
				count: 0,
				subEvents: [
					'Video',
					'Video Request',
				],
				filtered : false,
			},
			Video: {
				description: "Video",
				icon: "fa-fw fa fa-video-camera",
				color: "#E5C627",
				colorlight: "rgba(229, 198, 39, 0.5)",
				number: 18,
				groupUnder:'Videos',
				filtered : false,
			},
			'Video Request': {
				description: "Video Request",
				icon: "fa-fw fa fa-video-camera",
				color: "#E5C627",
				colorlight: "rgba(229, 198, 39, 0.5)",
				number: 18,
				groupUnder:'Videos',
				filtered : false,
			},
			online: {
				description: "Online",
				// icon: "fa-fw fa fa-signal",
				color: "#72bb53",
				colorlight: "rgba(114, 187, 83, 0.5)",
				number: 75,
				filtered : false,
			},
			offline: {
				description: "Offline",
				// icon: "fa-fw fa fa-signal",
				color: "#505050",
				colorlight: "rgba(80, 80, 80, 0.5)",
				number: 75,
				hidden:true,
				filtered : false,
			},
			Login: {
				description: "Login",
				icon: "fa-fw fa fa-sign-in",
				color: "#2C5374",
				colorlight: "rgba(44, 83, 116, 0.5)",
				number: 3,
				count: 0,
				filtered : false,
			},
			Logout: {
				description: "Logout",
				icon: "fa-fw fa fa-sign-out",
				color: "#2C5374",
				colorlight: "rgba(44, 83, 116, 0.5)",
				number: 3,
				count: 0,
				filtered : false,
			},
			'Failed Login': {
				description: "Failed Login",
				icon: "fa-fw fa fa-sign-in",
				color: "#e61610",
				colorlight: "rgba(230, 22, 16, 0.5)",
				number: 3,
				count: 0,
				filtered : false,
			},
			'No-comms': {
				description: "No Comms",
				// icon:"custom-no-comms",
				color: "#6B2868",
				colorlight: "rgba(107, 40, 104, 0.5)",
				number: 2,
				filtered : false,
				// imgBg:'assets/images/icons/nocomms.png',
				// img:'assets/images/icons/nocomms-nobg.png'
			},
			'Maintenance Events':{
				description: "Maintenance Events",
				icon: "fa-fw fas fa-wrench",
				color: "#632912",
				colorlight: "rgba(99, 41, 18, 0.5)",
				number: 3,
				count: 0,
				subEvents: [
					'Low Battery',
					'Camera Down',
					'HDD Fail',
					'Calibration Fail',
					'Power Removed',
					'Electronics Failed',
					'Fuel Stolen',
					'Fuel Detect Failed',
					'Temperature Detect Failed',
					'SD Failed'
				],
				filtered : false

			},
			'Low Battery': {
				description: "Battery Low",
				icon: "fa-fw fa fa-battery-quarter",
				color: "rgb(253, 117, 58)",
				colorlight: "rgba(253, 117, 58,0.5)",
				number: 0,
				groupUnder : 'Maintenance Events',
				filtered : false
			},
			'Camera Down': {
				description: "Camera Down",
				// icon:"custom-no-comms",
				color: "#632912",
				colorlight: "rgba(99, 41, 18, 0.5)",
				number: 2,
				groupUnder : 'Maintenance Events',
				filtered : false,
				// imgBg:'assets/images/icons/nocomms.png',
				// img:'assets/images/icons/nocomms-nobg.png'
			},
			'HDD Fail': {
				description: "HDD Fail",
				// icon:"custom-no-comms",
				color: "#632912",
				colorlight: "rgba(99, 41, 18, 0.5)",
				number: 2,
				groupUnder : 'Maintenance Events',
				filtered : false,
				// imgBg:'assets/images/icons/nocomms.png',
				// img:'assets/images/icons/nocomms-nobg.png'
			},
			"Calibration Fail": {
				description: "Calibration Fail",
				icon: "fa-fw fas fa-wrench",
				color: "rgb(253, 117, 58)",
				colorlight: "rgba(253, 117, 58,0.5)",
				number: 0,
				groupUnder : 'Maintenance Events',
				filtered : false
			},
			"Power Removed": {
				description: "Power Removed",
				icon: "fa-fw fas fa-wrench",
				color: "rgb(253, 117, 58)",
				colorlight: "rgba(253, 117, 58,0.5)",
				number: 0,
				groupUnder : 'Maintenance Events',
				filtered : false
			},
			"Electronics Failed": {
				description: "Electronics Failed",
				icon: "fa-fw fas fa-wrench",
				color: "rgb(253, 117, 58)",
				colorlight: "rgba(253, 117, 58,0.5)",
				number: 0,
				groupUnder : 'Maintenance Events',
				filtered : false
			},
			"Fuel Stolen": {
				description: "Fuel Stolen",
				icon: "fa-fw fas fa-wrench",
				color: "rgb(253, 117, 58)",
				colorlight: "rgba(253, 117, 58,0.5)",
				number: 0,
				groupUnder : 'Maintenance Events',
				filtered : false
			},
			"Fuel Detect Failed": {
				description: "Fuel Detect Failed",
				icon: "fa-fw fas fa-wrench",
				color: "rgb(253, 117, 58)",
				colorlight: "rgba(253, 117, 58,0.5)",
				number: 0,
				groupUnder : 'Maintenance Events',
				filtered : false
			},
			"Temperature Detect Failed": {
				description: "Temperature Detect Failed",
				icon: "fa-fw fas fa-wrench",
				color: "rgb(253, 117, 58)",
				colorlight: "rgba(253, 117, 58,0.5)",
				number: 0,
				groupUnder : 'Maintenance Events',
				filtered : false
			},
			"SD Failed": {
				description: "SD Failed",
				icon: "fa-fw fas fa-wrench",
				color: "rgb(253, 117, 58)",
				colorlight: "rgba(253, 117, 58,0.5)",
				number: 0,
				groupUnder : 'Maintenance Events',
				filtered : false
			},
			'Shift Events': {
				description: "Shift Events",
				icon: "fa-fw fa fa-eraser",
				color: "#1464f6",
				colorlight: "rgba(20, 100, 246, 0.5)",
				number: 3,
				count: 0,
				subEvents: [
					'Late Arrival',
					'Early Abscondment',
					'Waypoint Late',
					'Asset Not On Site',
					'Asset On Site',
					'No Arrival',
					'Route Not Started',
					'Route Started Late',
					'Route Missed',
					'Route Started',
					'Route Complete',
					'Route Incomplete',
					'Roaming Complete',
					'Roaming Incomplete',
				],
				filtered : false
			},
			'Late Arrival': {
				description: "Late Arrival",
				// icon: "fa-fw fa fa-eraser",
				color: "rgb(43, 17, 65)",
				colorlight: "rgba(43, 17, 65, 0.5)",
				number: 3,
				groupUnder : 'Shift Events',
				filtered : false
			},
			'Early Abscondment': {
				description: "Early Abscondment",
				// icon: "fa-fw fa fa-eraser",
				color: "rgb(242, 117, 133)",
				colorlight: "rgba(242, 117, 133,0.5)",
				number: 3,
				groupUnder : 'Shift Events',
				filtered : false
			},
			'Waypoint Late': {
				description: "Waypoint Late",
				// icon: "fa-fw fa fa-eraser",
				color: "rgb(155, 26, 31)",
				colorlight: "rgba(155, 26, 31,0.5)",
				number: 3,
				groupUnder:'Shift Events',
				filtered : false
			},
			'Asset Not On Site': {
				description: "Asset Not On Site",
				// icon: "fa-fw fa fa-eraser",
				color: "rgb(189, 26, 87)",
				colorlight: "rgba(189, 26, 87, 0.5)",
				number: 3,
				groupUnder:'Shift Events',
				filtered : false
			},
			'Asset On Site': {
				description: "Asset On Site",
				// icon: "fa-fw fa fa-eraser",
				color: "rgb(187, 191, 214)",
				colorlight: "rgba(187, 191, 214,0.5)",
				number: 3,
				groupUnder:'Shift Events',
				filtered : false
			},
			'Route Not Started': {
				description: "Route Not Started",
				// icon: "fa-fw fa fa-eraser",
				color: "rgb(100, 41, 20)",
				colorlight: "rgba(100, 41, 20,0.5)",
				number: 3,
				groupUnder:'Shift Events',
				filtered : false
			},
			'Route Started Late': {
				description: "Route Started Late",
				// icon: "fa-fw fa fa-eraser",
				color: "rgb(175, 95, 142)",
				colorlight: "rgba(175, 95, 142,0.5)",
				number: 3,
				groupUnder:'Shift Events',
				filtered : false
			},
			'Route Missed': {
				description: "Route Missed",
				// icon: "fa-fw fa fa-eraser",
				color: "rgb(189, 57, 61)",
				colorlight: "rgba(189, 57, 61,0.5)",
				number: 3,
				groupUnder:'Shift Events',
				filtered : false
			},
			'Route Started': {
				description: "Route Started",
				// icon: "fa-fw fa fa-eraser",
				color: "rgb(126, 204, 187)",
				colorlight: "rgba(126, 204, 187,0.5)",
				number: 3,
				groupUnder:'Shift Events',
				filtered : false
			},
			'Route Complete': {
				description: "Route Complete",
				// icon: "fa-fw fa fa-eraser",
				color: "rgb(0, 88, 102)",
				colorlight: "rgba(0, 88, 102,0.5)",
				number: 3,
				groupUnder:'Shift Events',
				filtered : false
			},
			'Route Incomplete': {
				description: "Route Incomplete",
				// icon: "fa-fw fa fa-eraser",
				color: "rgb(198, 50, 51)",
				colorlight: "rgba(198, 50, 51,0.5)",
				number: 3,
				groupUnder:'Shift Events',
				filtered : false
			},
			'Roaming Complete': {
				description: "Roaming Complete",
				// icon: "fa-fw fa fa-eraser",
				color: "rgb(129, 198, 119)",
				colorlight: "rgba(129, 198, 119,0.5)",
				number: 3,
				groupUnder:'Shift Events',
				filtered : false
			},
			'Roaming Incomplete': {
				description: "Roaming Incomplete",
				// icon: "fa-fw fa fa-eraser",
				color: "rgb(228, 146, 86)",
				colorlight: "rgbs(228, 146, 86,0.5)",
				number: 3,
				groupUnder:'Shift Events',
				filtered : false
			},
			'No Arrival': {
				description: "No Arrival",
				// icon: "fa-fw fa fa-eraser",
				color: "rgb(248, 150, 51)",
				colorlight: "rgba(248, 150, 51,0.5)",
				number: 3,
				groupUnder:'Shift Events',
				filtered : false
			},
			Detections: {
				description: "Detections",
				icon: "fa-fw fa fa-eye",
				color: "#e28743",
				colorlight: "rgba(226,135,67,0.5)",
				number: 3,
				count: 0,
				subEvents: [
					'Detection - Person',
					'Detection - Face',
					'Detection - Vehicle',
					'Detection - Pose Exception',
					"Detection - Eye Closed",
					"Detection - Distracted",
					"Detection - Yawn",
					"Detection - Smoking",
					"Detection - Phone Calling",
					"Detection - Missing Face",
					"Detection - Monitoring Shelter",
					"Detection - Look Down",
					"Detection - Over Speed",
					"Detection - Harsh Acceleration",
					"Detection - Harsh Deceleration",
					"Detection - Harsh Turning",
					"Detection - Crash",
					"Detection - Vibration"
				],
				filtered : false
			},
			'Detection - Person':{
				description: 'Detection - Person',
				icon: "fa-fw fa fa-male",
				color: "#e28743",
				colorlight: "rgba(226,135,67,0.5)",
				number: 3,
				count: 0,
				groupUnder: "Detections",
				filtered : false
			},
			'Detection - Face':{
				description: 'Detection - Face',
				icon: "fa-fw fa fa-user",
				color: "#e28743",
				colorlight: "rgba(226,135,67,0.5)",
				number: 3,
				count: 0,
				groupUnder: "Detections",
				filtered : false
			},
			'Detection - Vehicle':{
				description: 'Detection - Vehicle',
				icon: "fa-fw fa fa-car-side",
				color: "#e28743",
				colorlight: "rgba(226,135,67,0.5)",
				number: 3,
				count: 0,
				groupUnder: "Detections",
				filtered : false
			},
			'Detection - Pose Exception':{
			    description: 'Detection - Pose Exception',
				icon: "fa-fw fa fa-child",
				color: "#e28743",
				colorlight: "rgba(226,135,67,0.5)",
				number: 3,
				count: 0,
				groupUnder: "Detections",
				filtered : false
			},
			"Detection - Eye Closed":{
				description: 'Detection - Eye Closed',
				icon: "fa-fw fas fa-low-vision",
				color: "#e28743",
				colorlight: "rgba(226,135,67,0.5)",
				number: 3,
				count: 0,
				groupUnder: "Detections",
				filtered : false
			},
			"Detection - Distracted":{
				description: 'Detection - Distracted',
				icon: "fa-fw fas fa-user-slash",
				color: "#e28743",
				colorlight: "rgba(226,135,67,0.5)",
				number: 3,
				count: 0,
				groupUnder: "Detections",
				filtered : false
			},
			"Detection - Yawn":{
				description: 'Detection - Yawn',
				icon: "fa-fw fas fa-bed",
				color: "#e28743",
				colorlight: "rgba(226,135,67,0.5)",
				number: 3,
				count: 0,
				groupUnder: "Detections",
				filtered : false
			},
			"Detection - Smoking":{
				description: 'Detection - Smoking',
				icon: "fa-fw fas fa-smoking",
				color: "#e28743",
				colorlight: "rgba(226,135,67,0.5)",
				number: 3,
				count: 0,
				groupUnder: "Detections",
				filtered : false
			},
			"Detection - Phone Calling":{
				description: 'Detection - Phone Calling',
				icon: "fa-fw fas fa-phone-volume",
				color: "#e28743",
				colorlight: "rgba(226,135,67,0.5)",
				number: 3,
				count: 0,
				groupUnder: "Detections",
				filtered : false
			},
			"Detection - Missing Face":{
				description: 'Detection - Missing Face',
				icon: "fa-fw fas fa-user-slash",
				color: "#e28743",
				colorlight: "rgba(226,135,67,0.5)",
				number: 3,
				count: 0,
				groupUnder: "Detections",
				filtered : false
			},
			"Detection - Monitoring Shelter":{
				description: 'Detection - Monitoring Shelter',
				icon: "fa-fw fas fa-user-slash",
				color: "#e28743",
				colorlight: "rgba(226,135,67,0.5)",
				number: 3,
				count: 0,
				groupUnder: "Detections",
				filtered : false
			},
			"Detection - Look Down":{
				description: 'Detection - Look Down',
				icon: "fa-fw fas fa-low-vision",
				color: "#e28743",
				colorlight: "rgba(226,135,67,0.5)",
				number: 3,
				count: 0,
				groupUnder: "Detections",
				filtered : false
			},
			"Detection - Over Speed":{
				description: 'Detection - Over Speed',
				icon: "fa-fw fas fa-car-side",
				color: "#e28743",
				colorlight: "rgba(226,135,67,0.5)",
				number: 3,
				count: 0,
				groupUnder: "Detections",
				filtered : false
			},
			"Detection - Harsh Acceleration":{
				description: 'Detection - Harsh Acceleration',
				icon: "fa-fw fas fa-car-side",
				color: "#e28743",
				colorlight: "rgba(226,135,67,0.5)",
				number: 3,
				count: 0,
				groupUnder: "Detections",
				filtered : false
			},
			"Detection - Harsh Deceleration":{
				description: 'Detection - Harsh Deceleration',
				icon: "fa-fw fas fa-car-side",
				color: "#e28743",
				colorlight: "rgba(226,135,67,0.5)",
				number: 3,
				count: 0,
				groupUnder: "Detections",
				filtered : false
			},
			"Detection - Harsh Turning":{
				description: 'Detection - Harsh Turning',
				icon: "fa-fw fas fa-car-side",
				color: "#e28743",
				colorlight: "rgba(226,135,67,0.5)",
				number: 3,
				count: 0,
				groupUnder: "Detections",
				filtered : false
			},
			"Detection - Crash":{
				description: 'Detection - Crash',
				icon: "fa-fw fas fa-car-crash",
				color: "#e28743",
				colorlight: "rgba(226,135,67,0.5)",
				number: 3,
				count: 0,
				groupUnder: "Detections",
				filtered : false
			},
			"Detection - Vibration":{
				description: 'Detection - Vibration',
				icon: "fa-fw fas fa-car-side",
				color: "#e28743",
				colorlight: "rgba(226,135,67,0.5)",
				number: 3,
				count: 0,
				groupUnder: "Detections",
				filtered : false
			},
		};
		self.eventService.getEventDetails = new Promise( (res,rej) => {
			self.Auth.getCurrentUser().then((user) => {
				self.user = user;
				self.checkConsent(user);
				self.currentAccount = self.Auth.getCurrentAccountSync();
				if(self.Auth.hasPrivilegeSync('faceRecall')) {
					this.allEventDetails.Recognitions = {
						description: "Recognitions",
						icon: "fa-fw fa fa-search",
						color: "#F13A00",
						colorlight: "rgba(241, 58, 0, 0.5)",
						number: 2,
						count: 0,
						subEvents: [
							'Recognition',
							'LPR Recognition',
							'LPR VOI'
						],
						filtered : false
					};
					this.allEventDetails.Recognition = {
						description: "Recognition",
						color: "#F13A00",
						colorlight: "rgba(241, 58, 0, 0.5)",
						number: 2,
						groupUnder:'Recognitions',
						filtered : false,
					};
					this.allEventDetails['LPR Recognition'] = {
						description: "LPR Recognition",
						color: "#f65f00",
						colorlight: "rgba(246, 95, 0, 0.5)",
						number: 2,
						filtered : false,
						groupUnder:'Recognitions',
					};
					this.allEventDetails['LPR VOI'] = {
						description: "LPR VOI",
						color: "#f65f00",
						colorlight: "rgba(246, 95, 0, 0.5)",
						number: 2,
						filtered : false,
						groupUnder:'Recognitions',
					};
				}
				_.forEach(this.allEventDetails,(eventDetail,key) => {
					//if(!['Shift Events','Recognitions','Maintenance Events'].includes(key)){
					eventDetail.eventType = key;
					if(!eventDetail.icon) {
						eventDetail.imgBg = `assets/images/icons/${key.replace(/ |-/g,'').toLowerCase()}.png`;
						eventDetail.img = `assets/images/icons/${key.replace(/ |-/g,'').toLowerCase()}-nobg.png`;
					}

					eventDetail.showOnMap = true;
					eventDetail.showInList = true;
				});


				self.Auth.refreshToken();
				self.Auth.startIdle(user.idleDuration, user.tokenTTL/2 );

				// TODO: Remove this when the privilege is provided for all users <21-05-20, Liaan> //
				if(self.Auth.hasPrivilegeSync('fieldReport')) {
					self.customEventTypeService.registerListener(self, (event, item, array) => {
						return self.onReceiptOfCustomEventType(item, true);
					}).then((customEventTypes) => {
						_.forEach(customEventTypes, (customEventType) => {
							self.onReceiptOfCustomEventType(customEventType);
						} );
						self.setHiddenEvents(self.allEventDetails);
						res(self.allEventDetails);
					})
						.catch(err => {
							console.error('Error getting custom events', err);
						});
				}else{
					self.setHiddenEvents(self.allEventDetails);
					res(self.allEventDetails);
				}
			});
		} );
	}


	$onDestroy() {
		let self = this;
		self.customEventTypeService.unregisterListener(self);
	}

	onReceiptOfCustomEventType(customEventType, socketEvent) {
		let self = this;
		customEventType.description = customEventType.eventType;
		let color = self.hexToRgb(customEventType.color);
		customEventType.colorlight = `rgb(${color.r}, ${color.g}, ${color.b},0.5)`;
		customEventType.filter = false;
		customEventType.showOnMap = true;
		customEventType.showInHeader = true;
		customEventType.showInList = true;
		self.allEventDetails[customEventType.eventType] = customEventType;

		//Change map icons
		if(socketEvent) {
			self.mapService.updateMarker(customEventType);
		}
	}

	hexToRgb(hex) {
		var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result ? {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16)
		} : null;
	}

	setHiddenEvents(eventDetails) {
		let self = this;
		_.forEach(eventDetails,(eventDetail,key) => {
			if(!self.$localStorage.hiddenEvents) {
				self.$localStorage.hiddenEvents = {};
			}
			let hiddenEventsObj = self.$localStorage.hiddenEvents;
			if(!hiddenEventsObj[self.user._id]) {
				hiddenEventsObj[self.user._id] = {};
			}
			if(!hiddenEventsObj[self.user._id][self.currentAccount.ref]) {
				hiddenEventsObj[self.user._id][self.currentAccount.ref] = {};
			}
			const hiddenEvents = hiddenEventsObj[self.user._id][self.currentAccount.ref];
			if(hiddenEvents[key] === undefined) {
				if(eventDetail.groupUnder || eventDetail.hidden) {
					eventDetail.showInHeader = false;
				}else{
					eventDetail.showInHeader = true;
				}
				hiddenEvents[key] = eventDetail.showInHeader;
			}else{
				eventDetail.showInHeader = hiddenEvents[key];
			}
		});

	}

	checkConsent(user) {
		let self = this;
		let privacyDate = this.appConfig.policyUpdated.privacy;
		let termsDate = this.appConfig.policyUpdated.terms;
		let disclaimerDate = this.appConfig.policyUpdated.disclaimer;
		if(user.consent && user.consent.privacy && user.consent.terms && new Date(user.consent.privacy) > privacyDate && new Date(user.consent.terms) > termsDate && new Date(user.consent.disclaimer) > disclaimerDate) {
			//NOP
		}else{
			//Open privacy modal
			let modalInstance = self.$uibModal.open({
				component: 'consent',
				backdrop: 'static',
				//size:'lg',
				keyboard: false,
				resolve: {
					user,
				}
			});
		}
	}
}

export default angular.module('secutraqApp.main', [uiRouter])
	.config(routing)
	.component('main', {
		template: require('./main.html'),
		controller: MainController,
		controllerAs: '$ctrl'
	})
	.directive('httpSrc', [
		'$http', function($http) {
			var directive = {
				link,
				restrict: 'A'
			};
			return directive;
			function link(scope, element, attrs) {
				var requestConfig = {
					method: 'Get',
					url: attrs.httpSrc,
					responseType: 'arraybuffer',
					cache: 'true'
				};
				$http(requestConfig)
					.then(function(response) {
						let buff = new Buffer.from(response.data);
						let b64 = buff.toString('base64');
						attrs.$set('src', `data:image/jpeg;base64,${b64}`);
					});
			}
		}
	])
	.name;
