'use strict';
import angular from 'angular';

export class ToggleSwitchComponent {
    /*@ngInject*/
    constructor($scope) {
        this.$scope = $scope;
        this.identifier = '';
        this.standalone = false;
    }

    $onInit() {
        let self = this;

        if (self.unique !== undefined) self.identifier = self.name + "_" + self.unique;
        else self.identifier = self.name;

        // Checks if standalone toggle
        if ((self.leftlabel == undefined) && (self.rightlabel == undefined)) {
            self.standalone = true;
        }

        if (self.model == undefined) self.model = false;
        if (self.active == undefined) self.active = true;
    }

    $onDestroy() {

    }
}

export default angular.module('directives.toggleSwitch', [])
    .component('toggleSwitch', {
        template: require('./toggle-switch.html'),
        controller: ToggleSwitchComponent,
        controllerAs: '$ctrl',
        bindings: {
            model: '=',
            active: '=',
            leftlabel: '<',
            rightlabel: '<',
            name: '<',
            unique: '<',
            toggle: '<',
            onChange: '&'
        }
    })
    .name;
