export class UserCreateModalController {
	$stateParams;
	Restangular;
	$uibModalInstance;
	settings;
	contact;
	modalInstance;

	/*@ngInject*/
	constructor($stateParams, Restangular,toastr,$scope, userService) {
		this.$stateParams = $stateParams;
		this.Restangular = Restangular;
		this.toastr = toastr;
		this.valid = true;
		this.$scope = $scope;
		this.userService = userService;
	}

	$onInit() {
		let self = this;
		self.settings = self.resolve.settings;
		self.$uibModalInstance = self.modalInstance;
		self.availableUsers = [];
		self.refreshUsers();
	}

	refreshUsers(searchVal) {
		let self = this;
		self.userService.getUsersWithQuery({
			filter : searchVal,
			limit : 20,
			allAccounts: true,
		}).then((response) => {
			self.availableUsers = _.filter(response.data,(user) => {
				let index = _.findIndex(user.accounts,(o) => {
					return o.ref == self.settings.account.ref;
				});
				if(index == -1){
					return true;
				}else {
					return false;
				}
			});
		}).catch((err) => {
			console.error(err);
		})
	}


	// Setup all the models for display
	// Modal accept: send resulting object back to promise in parent controller
	ok() {
		let self = this;
		self.Restangular.one('accounts',self.settings.account.ref).customPOST({userIds:self.selectedUsers},'importUsers').then((response) => {
			self.$uibModalInstance.close(response);
		}).catch((err) => {
			console.error(err);
			self.toastr.error(`User import failed.`);
		});
	};

	doLog(){
		console.debug(this);
	}

	// Modal cancelled: either cancel pressed or clicked off screen
	cancel() {
		this.$uibModalInstance.dismiss('Cancel pressed');
	};
}

export default angular.module('secutraqApp.settings')
	.component('importusers', {
		template: require('./importusers.modal.html'),
		bindings: {
			resolve: '<',
			close: '&',
			dismiss: '&',
			modalInstance: '<'
		},
		controller: UserCreateModalController,
		controllerAs: '$ctrl'
	})
	.name;
// .controller(ContactModalController).name;
