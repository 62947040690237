import angular from 'angular';
import zxcvbn from 'zxcvbn';

export class PasswordStrengthMeter {
	/*@ngInject*/

	constructor($scope) {
		this.$scope = $scope;

		//Defaults
		this.minPasswordLength = 8;
		this.requiredStrength = 2;
		this.passwordStrength = null;
		this.passwordStrengthClass = '';

		this.disableFeedback = false;
		this.valid = false;

		this.colors = [
			'darkred',
			'orangered',
			'orange',
			'yellowgreen',
			'green'
		];
	}

	$onInit() {
		this.$scope.$watch( () => this.password, (newPass) => {
			this.updatePassword(newPass);
		});
	}

	updatePassword(password) {

		// TODO validation logic optimization
		if (!this.password) {
			this.passwordStrength = null;
			this.feedback = null;
		} else if (this.password && this.password.length < this.minPasswordLength) {
			this.passwordStrength = 0;
			this.feedback = {
				warning: 'Password too short.'
			};
		} else {
			const result = zxcvbn(this.password);
			this.passwordStrength = result.score;
			this.feedback = result.feedback;
		}

		this.passwordStrengthColor = this.getMeterFillColor(this.score);

		if(this.passwordStrength != undefined) {
			this.passwordStrengthClass = `strength-${this.passwordStrength}`;
			if(this.passwordStrength >= this.requiredStrength) {
				this.valid = true;
			}else{
				this.valid = false;
			}
		}else{
			this.passwordStrengthClass = '';
			this.valid = false;
		}
	}

	getMeterFillColor(strength) {
		if (!strength || strength < 0 || strength > 5) {
			return this.colors[0] ? this.colors[0] : this.defaultColours[0];
		}

		return this.colors[strength]
			? this.colors[strength]
			: this.defaultColours[strength];
	}

}

export default angular.module('directives.password', [])
	.component('passwordStrengthMeter', {
		template: require('./password-strength-meter.html'),
		controller: PasswordStrengthMeter,
		controllerAs: "$ctrl",
		bindings: {
			password: "=",
			valid: "="

		}
	})
	.directive('passwordValidator', function($timeout) {
		return {
			require: 'ngModel',
			link(scope, elem, attr, ngModel) {
                let passValidFunc = function(value) {
                    $timeout(function() {
                        let valid = scope.$eval(attr.passwordValidator);
						ngModel.$setValidity('passwordValid', valid);
                    });
                    return value;
                };

                ngModel.$parsers.unshift(passValidFunc);
                ngModel.$formatters.unshift(passValidFunc);
			}
		};
	})
	.name;
