import angular from 'angular';

export class LiveStreamPanel {
	/*@ngInject*/
	constructor($scope,$element,liveStreamService, $timeout) {
		this.$scope = $scope;
		this.$element = $element;
		this.$timeout = $timeout;
		this.liveStreamService = liveStreamService;
		this.minimized = false;
	}

	$onInit() {
		let self = this;
		self.$scope.$watchGroup(['$ctrl.stream.stats.frameWidth', '$ctrl.stream.stats.frameHeight'], (newVal) => {
			let width = newVal[0];
			let height = newVal[1];
			if(width && height) {
				self.resizePanel();
			}
		});
		self.$timeout( () => {
			let videoElement = document.getElementById(`${self.stream.guid}`);
			videoElement.addEventListener("enterpictureinpicture", (event) => {
				self.minimize();
			});

			videoElement.addEventListener("leavepictureinpicture", () => {
				self.maximize();
			});

		} );

	}

	$onDestroy() {
		let self = this;
		self.liveStreamService.removeStream(self.stream);
	}

	resizePanel() {
		let self = this;
		if(!self.minimized) {
			let aspect = 3/2;
			if(self.stream && self.stream.stats && self.stream.stats.frameWidth && self.stream.stats.frameHeight && !self.minimized) {
				aspect = self.stream.stats.frameWidth / self.stream.stats.frameHeight;
			}
			let videoElement = self.$element.find(`#${self.stream.guid}`);
			let panel = videoElement.parent().parent();
			let panelWidth = panel[0].clientWidth;
			let newHeight = panelWidth/aspect;
			panel.css('height',newHeight);
		}
	}

	close() {
		let self = this;
		self.liveStreamService.removeStream(self.stream);
	}

	requestFullscreen() {
		let self = this;
		self.liveStreamService.requestFullscreen(self.stream);
	}

	minimize() {
		let self = this;
		self.minimized = true;
		let videoElement = self.$element.find(`#${self.stream.guid}`);
		let panel = videoElement.parent().parent();
		panel.css('height',0);
	}

	maximize() {
		let self = this;
		self.minimized = false;
		self.resizePanel();
	}

	resetStream() {
		let self = this;
		self.liveStreamService.resetStream(self.stream);
	}
}

export default angular.module('directives.liveStreamPanel', [])
	.component('liveStreamPanel', {
		template: require('./live-stream-panel.html'),
		controller: LiveStreamPanel,
		controllerAs: "$ctrl",
		bindings: {
			stream: '='
		}
	})
	.name;
