'use strict';
angular.module('secutraqApp.directives')
.directive('myMap', ['$document',  '$timeout','$window',
		   function($document, $timeout, $window) {
			   return {
				   restrict: 'A',
				   link: function(scope, el, attrs) {
					   $timeout(() => {

						   if(attrs && attrs.myOptions){
							   /**
								* In HTML: <div my-map my-options="['remove-attribution']"
								* To remove the bottom leaflet segment for smaller maps
								*/
							   if(attrs.myOptions.includes('remove-attribution')) {
								   let elements = el.find('.leaflet-control-attribution.leaflet-control');
								   if(elements[0] !== undefined) {
									   elements[0].remove();
								   }
							   }
							   /**
								* In HTML: <div my-map my-options="['resize-map']"
								* To reduze the size of the map by 20px so that the carousel fits
								*/
							   if(attrs.myOptions.includes('resize-map')) {
								   let height = el[0].offsetHeight;
								   el.css('height', height - 37 + 'px')
								   angular.element($window).bind('resize', function(){
									   let height = el[0].offsetHeight;
									   el.css('height', height - 25 + 'px')
								   });
							   }

						   }

					   });



				   }
			   };
		   }]);
