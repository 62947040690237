
'use strict';

export default function($stateProvider) {
  'ngInject';
  $stateProvider
  .state('main.photoAnalytics',{
      abstract:true,
      url: '/photoAnalytics',
      data: {
          title: 'Photo Analytics'
      },
      authenticate: 'faceRecall'
  })
  .state('main.photoAnalytics.faceCollection', {
      url: '/faceCollection',
      views: {
          'content@main': {
              template: '<face-collection></face-collection>'
          }
      },
      authenticate: true,
      data: {
          title: "Face Collection"
      }
  })
  .state('main.photoAnalytics.plateCollection', {
      url: '/plateCollection',
      views: {
          'content@main': {
              template: '<plate-collection></plate-collection>'
          }
      },
      authenticate: true,
      data: {
          title: "Plate Collection"
      }
  })
  .state('main.photoAnalytics.imageCollection', {
      url: '/imageCollection',
      views: {
          'content@main': {
              template: '<image-collection></image-collection>'
          }
      },
      authenticate: true,
      data: {
          title: "Image Collection"
      }
  });
}
