import _ from 'lodash-es';
import adapter from 'webrtc-adapter';

const RETRY_COUNT = 5;

export class WebRTCService {

	/*@ngInject*/
	constructor(moment, Restangular, socket, Auth, $timeout, $interval, toastr) {
		this.moment = moment;
		this.Restangular = Restangular;
		this.socket = socket;
		this.Auth = Auth;
		this.ssConnected = false;
		this.rtc_configuration = {iceServers: [
			{urls: ["stun:stun.jerichosystems.co.za", "turn:stun.jerichosystems.co.za"], username: "secuvue", credential: "jerichoturn"}
		]};
		this.peers = {};
		this.sessions = {};
		this.rooms = {};
		this.stream;
		this.pendingAnswerTimeoutDuration = 1000*30; //30 seconds
		this.removeTimeoutDuration = 1000*60; //60 seconds
		this.checkAudioIntervalTime = 1000*5; //60 seconds
		this.bytesCounter = {};
		this.$timeout = $timeout;
		this.$interval = $interval;
		this.toastr = toastr;
		this.retryCount = 0;

		this.ringtone = new Audio('assets/sounds/ringtone.wav');
		this.ringtone.loop = true;
		let self = this;
		//this.pc = new RTCPeerConnection(configuration);
		//navigator.mediaDevices.getUserMedia({audio:true}).then( (stream) => {
		//self.stream = stream;
		//} );
	}


	//Connect to the signalling server.
	//
	//
	//To create a call
	//A session needs to be created or a room joined
	//The relevant peers needs to be connected in the following manner
	//Create a peer connection RTCPeerConnection
	//The relevant input streams needs to be requested (Audio tracks)
	//The streams,track need to be set on the peer connection
	//SDP Offer and ICE negotiation needs to be done //oniceneeded and onnegotiationneeded
	//
	//To Receive a call
	//A session will be created or a room message will be received
	//Create a peer connection
	//The relevant input streams needs to be requested (Audio tracks)
	//The streams,track need to be set on the peer connection
	//SDP Offer and ICE negotiation needs to be done //oniceneeded and onnegotiationneeded


	startupSession(peerId) {
		let self = this;
		let peer = self.peers[peerId];
		if(peer && peer.sessions && peer.sessions.length > 0) {
			peer.sessions.forEach( (sessionId) => {
				self.removeTimeout(sessionId);

			} );
		}
		self.ws_conn.send(`SESSION ${peerId}`);
	}

	//This will create a session
	createCall(peerId,reason, initiator) {
		let self = this;
		let peer = self.peers[peerId];
		if(!peer) {
			console.error('Peer not found when creating call');
			return Promise.resolve(null);
		}

		let peer_connection = peer.pc;
		let newPeerConnection = false;
		if(!peer.pc) {
			peer_connection = new RTCPeerConnection(self.rtc_configuration);
			peer.pc = peer_connection;
			//peer.checkAudioInterval = self.$interval( self.checkAudioValid.bind(self),self.checkAudioIntervalTime,0,true,peerId);
			newPeerConnection = true;
		}

		if(reason.type == "session") {
			let session = self.sessions[reason.id];
			session.state = 'callAccepted';
			self.checkPendingAnswer();
			if(session.stateTimeout) {
				self.$timeout.cancel(session.stateTimeout);
				delete session.stateTimeout;
			}
			peer.sessions.push(reason.id);
			peer.sessions = _.uniq(peer.sessions);
		}else if(reason.type == "room") {
			peer.rooms.push(reason.id);
			peer.rooms = _.uniq(peer.rooms);
		}


		if(newPeerConnection) {

			peer.offered = initiator;

			peer_connection.ontrack = (event) => {
				self.onRemoteTrack(peerId, event.streams[0]);
			};

			peer_connection.onicecandidate = (event) => {
				// We have a candidate, send it to the remote party with the
				// same uuid
				if (event.candidate == null) {
					return;
				}

				// TODO: Ensure this works with rooms and multiple sessions  <08-10-19, Liaan> //
				self.sendForwardMessage(peerId, {ice: event.candidate});
			};

			peer_connection.ondatachannel = (event) => {
				peer.dataChannel = event.channel;
				self.connectDataChannel(event.channel);
			};


			return navigator.mediaDevices.getUserMedia({audio:true}).then( (stream) => {
				peer_connection.addStream(stream);
				peer.stream = stream;
				if(self.canMute(peerId)) {
					self.setPeerMic(peerId, false);
				}else{
					self.setPeerMic(peerId, true);
				}
				if(initiator) {

					//Data Channel
					let data_channel = peer_connection.createDataChannel(`${peerId} Data Channel`, null);
					peer.dataChannel = data_channel;
					self.connectDataChannel(data_channel);

					peer_connection.onnegotiationneeded = () => {
						peer_connection.createOffer().then( (offer) => {
							return peer_connection.setLocalDescription(offer);
						} )
.then( () => {
							let sdp = {sdp: peer_connection.localDescription};
							self.sendForwardMessage(peerId, sdp);
						} );
					};
				}
				return peer_connection;
			} )
.catch( (err) => {
				console.error(err);
				self.toastr.error('Could not access microphone, call will only recieve media.');
			} );
		}else{
			// TODO: Add track to audio element  <08-11-19, Liaan> //
			self.onRemoteTrack(peerId, peer.incommingStream);
			if(self.canMute(peerId)) {
				self.setPeerMic(peerId, false);
			}else{
				self.setPeerMic(peerId, true);
			}
			return Promise.resolve(peer_connection);

		}
	}

	onRemoteTrack(peerId, stream) {
		let self = this;
		let audioIds = [];
		let peer = self.peers[peerId];
		peer.incommingStream = stream;
		if(peer.sessions && peer.sessions.length > 0) {
			let session = self.sessions[peer.sessions[0]];
			session.state = 'connected';
			self.checkPendingAnswer();
			if(session.stateTimeout) {
				self.$timeout.cancel(session.stateTimeout);
				delete session.stateTimeout;
			}
		}

		//if(peer.sessions.length > 0){
			//audioIds.push(peerId+"audio");
		//}
		//peer.rooms.forEach( (roomId) => {
			//audioIds.push(`${peerId}_${roomId}_audio`);
		//} )
		//audioIds.forEach( (audioId) => {
			//let interval = self.$interval( () => {

				//let audioElement = document.getElementById(audioId);
				//if(audioElement){
					//audioElement.ontimeupdate = (a,b) => {
					//};
					//audioElement.srcObject = stream;
					//if(peer.sessions && peer.sessions.length > 0){
						//let session = self.sessions[peer.sessions[0]];
						//session.state = 'connected';
						//self.checkPendingAnswer();
						//if(session.stateTimeout){
							//self.$timeout.cancel(session.stateTimeout);
							//delete session.stateTimeout;
						//}
					//}
					//if(audioId.split('_').length > 1){
						//let roomId = audioId.split('_')[1];
						//if(!self.rooms[roomId].volumeEnabled){
							//audioElement.muted = true;
						//}
					//}
					//self.$interval.cancel(interval);
				//}
			//},100,10 );
		//} )
	}

	connectToSignallingServer() {
		let self = this;
		self.disconnectCalled = false;
		return self.Auth.getCurrentUser().then( (user) => {

			if(!user) {
				ssConnected = false;
				throw new Error('User id not found');
				return null;
			}
			let peerId = user._id;
			self.myId = peerId;
			let ws_port = '8444';
			let ws_server = 'signal2.jerichosystems.co.za';
			if (window.location.protocol.startsWith("file")) {
				ws_server = "localhost";
			} else if (window.location.hostname == "localhost") {
				ws_server = "localhost";
			} else if (window.location.protocol.startsWith("http") && !window.location.protocol.startsWith("https") ) {
				ws_server = window.location.hostname;
			} else if (window.location.protocol.startsWith("https") ) {
				//NOP
			} else {
				throw new Error(`Don't know how to connect to the signalling server with uri${window.location}`);
			}
			let wsUrlPrefix = 'ws';
			if(window.location.protocol.startsWith("https")) {
				wsUrlPrefix = 'wss';
			}

			var ws_url = `${wsUrlPrefix}://${ws_server}:${ws_port}`;
			self.ws_conn = new WebSocket(ws_url);
			/* When connected, immediately register with the serverjj */
			self.ws_conn.addEventListener('open', (event) => {
				self.ws_conn.send(`HELLO ${peerId} user`);
				clearInterval(self.reconnectInterval);
				self.retryCount = 0;
				delete self.reconnectInterval;
			});
			self.ws_conn.addEventListener('error', self.onServerError.bind(self));
			self.ws_conn.addEventListener('message', self.onServerMessage.bind(self));
			self.ws_conn.addEventListener('close', self.onServerClose.bind(self));

		} );
	}

	onServerError(event) {
		let self = this;
		self.ssConnected = false;
		console.error("Could not connect to server : ", event);
		self.ws_conn.close();
	}

	onServerMessage(event) {
		let self = this;
		switch (event.data) {
			case "HELLO": {
				self.ssConnected = true;
			}
			break;
			default: {
				if (event.data.startsWith("ERROR")) {
					self.handleIncomingError(event.data);
				}else if(event.data.startsWith('SESSION')) {
					self.handleIncommingSessionMessages(event);
				}else if(event.data.startsWith('ROOM')) {
					self.handleIncommingRoomMessages(event);
				}else{
					self.handleIncomingError(`Unknown message : ${event.data}`);
				}
				return;
			}
		}
	}

	onServerClose(event) {
		let self = this;
		self.ssConnected = false;

		_.forEach(self.peers, (peer) => {
			peer.pc.close();
		} );
		//if (peer_connection) {
		//peer_connection.close();
		//peer_connection = null;
		//}

		//// Reset after a second
		//window.setTimeout(websocketServerConnect, 1000);
		if(!self.reconnectInterval && !self.disconnectCalled) {
			self.reconnectInterval = setInterval( () => {
				self.retryCount ++;
				if(self.retryCount <= RETRY_COUNT) {
					self.connectToSignallingServer();
				} else {
					clearInterval(self.reconnectInterval);
					self.retryCount = 0;
					console.error("Abandon PTT signalling server connection");
				}
			},5000 );
		}

	}


	handleIncomingError(error) {
		let self = this;
		console.error(error);
	}

	handleIncommingSessionMessages(event) {
		let self = this;
		let splitMessage = event.data.split(' ');
		let command = splitMessage[0];
		let sessionId = splitMessage[1];

		switch (command) {
			case 'SESSION_FMSG': {
				let peerId = splitMessage[2];
				let msg = splitMessage.slice(3,splitMessage.length).join(' ');
				let session = self.sessions[sessionId];
				// Handle incoming JSON SDP and ICE messages
				try {
					msg = JSON.parse(msg);
				} catch (e) {
					if (e instanceof SyntaxError) {
						console.error(`Error parsing incoming JSON: ${event.data}`);
					} else {
						console.error(`Unknown error parsing response: ${event.data}`);
					}
					return;
				}
				if(msg.call === true || msg.callme === true) {
					//Call request received
					//Enable answer button
					if(!self.peers[peerId]) {
						self.peers[peerId] = {pc:undefined, rooms:[], sessions:[],type:self.getPeerType(peerId)};

					}
					if(session.stateTimeout) {
						self.$timeout.cancel(session.stateTimeout);
					}
					session.stateTimeout = self.$timeout( self.failTimeout.bind(self) , self.pendingAnswerTimeoutDuration, true, sessionId);
					self.peers[peerId].sessions.push(sessionId);
					self.peers[peerId].sessions = _.uniq(self.peers[peerId].sessions);
					session.state = 'pendingAnswer';
					self.checkPendingAnswer();

				}else if(msg.renegotiate === true) {
					//Call answer received
					//Create call (sdp,ice,pc)
					self.renegotiate(peerId);
				}else if(msg.answer === true) {
					//Call answer received
					//Create call (sdp,ice,pc)
					self.createCall(peerId, {type:"session", id:sessionId}, true);
				}else if(msg.endcall === true) {
					//Call answer received
					//Create call (sdp,ice,pc)
					if(session.stateTimeout) {
						self.$timeout.cancel(session.stateTimeout);
					}
					self.ws_conn.send(`SESSION_END ${sessionId}`);
					self.failTimeout(sessionId);
				}else if(msg.answer === false) {
					//Call decline received
					//End session

					if(session.stateTimeout) {
						self.$timeout.cancel(session.stateTimeout);
					}
					self.ws_conn.send(`SESSION_END ${sessionId}`);
					self.failTimeout(sessionId);
					//self.closeSession(sessionId);
				}else if (msg.sdp != null) {
					let pcCreated = Promise.resolve(true);
					if(!self.peers[peerId].pc) {
						pcCreated = self.createCall(peerId, {type:"session", id:sessionId});
					}
					pcCreated.then( () => {
						self.onIncomingSDP(peerId,msg.sdp,{type:"session", id:sessionId});
					} );
				} else if (msg.ice != null) {
					self.onIncomingICE(peerId,msg.ice,{type:"session", id:sessionId});
				} else {
					console.error(`Unknown incoming JSON: ${msg}`);
				}
			}
			break;
			case 'SESSION_OK': {
				let sessionId = splitMessage[1];
				let peerId = splitMessage[2];
				if(!self.sessions[sessionId]) {
					self.sessions[sessionId] = {peerId, micMuted : false};
				}
				let session = self.sessions[sessionId];

				//Send create call msg

				//callme method
				//self.ws_conn.send("SESSION_MSG "+sessionId+" "+JSON.stringify({callme:true}));

				//call method
				self.ws_conn.send(`SESSION_MSG ${sessionId} ${JSON.stringify({call:true})}`);


				if (!self.peers[peerId]) {
					self.peers[peerId] = {pc:undefined, rooms:[], sessions:[sessionId],type:self.getPeerType(peerId)};
				}

				self.peers[peerId].sessions.push(sessionId);
				self.peers[peerId].sessions = _.uniq(self.peers[peerId].sessions);
				if(session.stateTimeout) {
					self.$timeout.cancel(session.stateTimeout);
				}
				session.stateTimeout = self.$timeout(self.failTimeout.bind(self), self.pendingAnswerTimeoutDuration, true, sessionId);

				session.state = 'calling';
				self.checkPendingAnswer();
			}
			break;
			case 'SESSION_REQUEST': {
				let sessionId = splitMessage[1];
				let peerId = splitMessage[2];
				if(!self.sessions[sessionId]) {
					self.sessions[sessionId] = {peerId, micMuted:false};
				}
				let session = self.sessions[sessionId];
				if (!self.peers[peerId]) {
					self.peers[peerId] = {pc:undefined, rooms:[], sessions:[],type:self.getPeerType(peerId)};
				}
				session.state = 'pendingAnswer';
				self.peers[peerId].sessions.push(sessionId);
				self.peers[peerId].sessions = _.uniq(self.peers[peerId].sessions);
				self.checkPendingAnswer();
			}
			break;
			case 'SESSION_END': {
				let sessionId = splitMessage[1];

				if (self.sessions[sessionId]) {
					let session = self.sessions[sessionId];
					let peer;
					let peerId = session.peerId;
					//delete self.sessions[sessionId];
					if(self.peers[peerId]) {

						session.state = 'callFailed';
						if(session.stateTimeout) {
							self.$timeout.cancel(session.stateTimeout);
						}
						session.stateTimeout = self.$timeout(self.removeTimeout.bind(self),self.removeTimeoutDuration,true,sessionId);
					}else{
					}
					self.checkPendingAnswer();
				}else{
					console.warn('Session not found msg : ', event.data );
				}
			}
			break;
			default:
				console.warn(`Unknown command : ${event.data}`);
		}
	}

	handleIncommingRoomMessages(event) {
		let self = this;
		let splitMessage = event.data.split(' ');
		let command = splitMessage[0];
		let roomId = splitMessage[1];

		switch (command) {
			case 'ROOM_PEER_MSG': {
				let peerId = splitMessage[2];
				let msg = splitMessage.slice(3,splitMessage.length).join(' ');
				// Handle incoming JSON SDP and ICE messages
				try {
					msg = JSON.parse(msg);
				} catch (e) {
					if (e instanceof SyntaxError) {
						console.error(`Error parsing incoming JSON: ${event.data}`);
					} else {
						console.error(`Unknown error parsing response: ${event.data}`);
					}
					return;
				}
				if (msg.sdp != null) {
					// TODO: Create peer if not defined  <05-11-19, Liaan> //
					if(!self.peers[peerId]) {
						self.peers[peerId] = {pc:undefined, rooms:[], sessions:[],type:self.getPeerType(peerId)};
						self.peers[peerId].rooms.push(roomId);
						self.peers[peerId].rooms = _.uniq(self.peers[peerId].rooms);
						self.rooms[roomId].peers.push(peerId);
						self.rooms[roomId].peers = _.uniq(self.rooms[roomId].peers);
					}
					let pcCreated = Promise.resolve(true);
					if(!self.peers[peerId].pc) {
						pcCreated = self.createCall(peerId, {type:"room", id:roomId});
					}
					pcCreated.then( () => {
						self.onIncomingSDP(peerId,msg.sdp,{type:"room", id:roomId});
					} );
				} else if (msg.ice != null) {
					self.onIncomingICE(peerId,msg.ice,{type:"room", id:roomId});
				} else {
					console.error(`Unknown incoming JSON: ${msg}`);
				}
			}
			break;
			case 'ROOM_OK': {
				let roomId = splitMessage[1];
				let peers = splitMessage.slice(2);
				if(!self.rooms[roomId]) {
					self.rooms[roomId] = {uid : roomId, peers:[], micMuted: true, volumeEnabled : true};
				}


				peers.forEach( (peerId) => {
					if(peerId !== "") {
						if (!self.peers[peerId]) {
							self.peers[peerId] = {pc:undefined, rooms:[], sessions:[],type:self.getPeerType(peerId)};
						}
						self.peers[peerId].rooms.push(roomId);
						self.peers[peerId].rooms = _.uniq(self.peers[peerId].rooms);
						self.rooms[roomId].peers.push(peerId);
						self.createCall(peerId, {type:"room", id:roomId}, true);

					}
				} );

			}
			break;
			case 'ROOM_PEER_JOINED': {
				let roomId = splitMessage[1];
				let peerId = splitMessage[2];
				if (!self.peers[peerId]) {
					self.peers[peerId] = {pc:undefined, rooms:[], sessions:[],type:self.getPeerType(peerId)};
				}
				self.peers[peerId].rooms.push(roomId);
				self.peers[peerId].rooms = _.uniq(self.peers[peerId].rooms);
				self.rooms[roomId].peers.push(peerId);
				self.rooms[roomId].peers = _.uniq(self.rooms[roomId].peers);

				//self.createCall(peerId, {type:"room", id:roomId});
			}
			case 'ROOM_PEER_LEFT': {
				let roomId = splitMessage[1];
				let peerId = splitMessage[2];
				// TODO: Remove peer from room  <05-11-19, Liaan> //
				self.removePeerFromRoom(roomId, peerId);
			}
			break;
			default:
				console.warn(`Unknown command : ${event.data}`);
		}
	}


	onIncomingSDP(peerId,sdp,reason) {
		let self = this;
		let pc = self.peers[peerId].pc;
		if(reason.type == 'session') {
			self.sessions[reason.id].state = 'connected';
		}
		self.checkPendingAnswer();
		pc.setRemoteDescription(sdp).then(() => {
			if (sdp.type == "offer") {
				return pc.createAnswer()
				.then( (desc) => {
					pc.setLocalDescription(desc).then( () => {
						let localSdp = {sdp: pc.localDescription};
						self.sendForwardMessage(peerId, localSdp);
					} );
				});
			}else{
				return null;
			}
		})
.catch( (err) => {
			console.error(err);
		} );
	}

	// Local description was set, send it to peer
	//onLocalDescription(sessionId,peerId,desc) {
	//let self = this;
	//self.peers[peerId].setLocalDescription(desc).then(function() {
	//sdp = {'sdp': self.peers[peerId].localDescription}
	//self.ws_conn.send("SESSION_MSG "+sessionId+" "+JSON.stringify(sdp));
	//});
	//}

	// ICE candidate received from peer, add it to the peer connection
	onIncomingICE(peerId,ice) {
		let self = this;
		var candidate = new RTCIceCandidate(ice);
		let pc = self.peers[peerId].pc;
		if(ice.candidate == "") {
			console.warn('Invalid ice candidate received : ', ice);
			return null;
		}
		pc.addIceCandidate(candidate).catch( (err) => {
			console.error(err);
			console.warn('ICE Candidate : ', ice);
		});
	}


	endCall(sessionId) {
		//When rooms are supported on front-end the end call should probably end the session only.  A popup could maby ask the user if they want to leave the room (if peer in room as well).
		let self = this;
		let session = self.sessions[sessionId];
		if(!session) {
			console.error('No session in end call : ', sessionId);
			return null;
		}
		let peer = self.peers[session.peerId];
		if(peer) {
			peer.sessions.forEach( (sessionId) => {
				self.closeSession(sessionId);
			} );
			if(self.canMute(session.peerId)) {
				self.setPeerMic(session.peerId, false);
			}
		}else{
			console.error('Peer not found when ending call');
		}
	}

	closeSession(sessionId) {
		let self = this;
		self.ws_conn.send(`SESSION_MSG ${sessionId} ${JSON.stringify({endcall: true})}`);
		self.ws_conn.send(`SESSION_END ${sessionId}`);
		self.removeSession(sessionId);
	}

	removeSession(sessionId) {
		let self = this;
		let session = self.sessions[sessionId];
		let peer;
		let peerId = session.peerId;
		if(self.peers[peerId]) {
			peer = self.peers[peerId];
			_.remove(peer.sessions, (o) => {
				return o == sessionId;
			});
			self.removePeer(peerId);
			self.checkPendingAnswer();
		}else{
			console.warn('Peer not found when ending session : ', session);
		}
		delete self.sessions[sessionId];

	}

	removePeerFromRoom(roomId,peerId) {
		let self = this;
		let peer = self.peers[peerId];
		let room = self.rooms[roomId];
		if(room) {
			_.remove(room.peers, (o) => {
				return o == peerId;
			});
		}
		if(peer) {
			_.remove(peer.rooms, (o) => {
				return o == roomId;
			});
			self.removePeer(peerId);
		}
	}

	leaveRoom(roomId) {
		let self = this;
		let room = self.rooms[roomId];
		self.ws_conn.send(`ROOM_LEAVE ${roomId}`);
		let peersClone = _.cloneDeep(room.peers);
		peersClone.forEach( (peerId) => {
			self.removePeerFromRoom(roomId,peerId);
		} );
		delete self.rooms[roomId];

	}


	joinRoom(roomId) {
		let self = this;
		if(self.rooms[roomId]) {
			console.warn('Room already joined.');
			return null;
		}
		self.ws_conn.send(`ROOM ${roomId}`);
	}

	removePeer(peerId) {
		let self = this;
		let peer = self.peers[peerId];
		if(peer && peer.sessions.length == 0 && peer.rooms.length == 0) {
			if(peer.stream) {
				peer.stream.getTracks().forEach(function(track) {
 track.stop();
});
			}
			if(peer.pc) {
				peer.pc.close();
			}
			//self.$interval.cancel(peer.checkAudioInterval);
			delete self.peers[peerId];
		}
	}

	disconnect() {
		let self = this;
		self.disconnectCalled = true;
		_.forEach(self.sessions, (session,sessionId) => {
			self.closeSession(sessionId);
		} );
		_.forEach(self.rooms, (room,roomId) => {
			self.leaveRoom(roomId);
		} );
		self.ws_conn.close();
	}

	answerCall(sessionId) {
		let self = this;
		let session = self.sessions[sessionId];
		let peer;
		let peerId = session.peerId;


		//callme method
		//self.createCall(peerId, {type:"session", id:sessionId}, true);

		//call method
		self.ws_conn.send(`SESSION_MSG ${sessionId} ${JSON.stringify({answer: true})}`);
		self.createCall(peerId, {type:"session", id:sessionId});
	}

	declineCall(sessionId) {
		let self = this;

		let session = self.sessions[sessionId];
		let peer;
		let peerId = session.peerId;
		self.ws_conn.send(`SESSION_MSG ${sessionId} ${JSON.stringify({answer: false})}`);
		if(session.stateTimeout) {
			self.$timeout.cancel(session.stateTimeout);
			delete session.stateTimeout;
		}
		self.closeSession(sessionId);
	}

	removeTimeout(sessionId) {
		let self = this;
		let session = self.sessions[sessionId];
		if(!session) {
			console.warn('Session not found in remove session timeout');
			return null;
		}
		// TODO: Refactor this to include the session <08-11-19, Liaan> //
		if(session.stateTimeout) {
			self.$timeout.cancel(session.stateTimeout);
		}
		if(session.state == 'callFailed') {
			self.endCall(sessionId);
		}else{
			console.warn('Remove timeout called while call not in failed/ended state');
		}
	}

	failTimeout(sessionId) {
		let self = this;
		let session = self.sessions[sessionId];
		if(!session) {
			console.warn('Session not found in fail session timeout');
			return null;
		}

		self.setSessionMic(sessionId, false);
		session.state = 'callFailed';
		if(session.stateTimeout) {
			self.$timeout.cancel(session.stateTimeout);
		}
		session.stateTimeout = self.$timeout(self.removeTimeout.bind(self),self.removeTimeoutDuration,true,sessionId);
		self.checkPendingAnswer();
	}

	checkPendingAnswer() {
		let self = this;
		let pending = _.some(self.sessions, (o) => {
			return o.state == 'pendingAnswer';
		});
		if(pending) {
			if(self.ringtone.duration > 0 && !self.ringtone.paused) {
				//Already playing
			}else{
				self.ringtone.play();
			}
		}else{
			self.ringtone.pause();
			self.ringtone.currentTime = 0;
		}
	}

	connectDataChannel(data_channel, peerId) {
		let self = this;
		data_channel.onopen = (event) => {
			self.handleDataChannelOpen(event,peerId);
		};
		data_channel.onmessage = (event) => {
			self.handleDataChannelMessageReceived(event,peerId);
		};
		data_channel.onerror = (error) => {
			self.handleDataChannelError(error,peerId);
		};
		data_channel.onclose = (event) => {
			self.handleDataChannelClose(event,peerId);
		};
	}


	handleDataChannelOpen(event, peerId) {

	}

	handleDataChannelMessageReceived(event, peerId) {

		if (typeof event.data === 'string' || event.data instanceof String) {
			try {
				msg = JSON.parse(msg);
			} catch (e) {
				if (e instanceof SyntaxError) {
					console.error(`Error parsing incoming Data channel message: ${event.data}`);
				} else {
					console.error(`Unknown error parsing data channel message: ${event.data}`);
				}
				return;
			}
			self.handleParsedDataChannelMessage(msg,peerId);

		} else {
			console.debug('Incoming data message');
		}
	}

	handleDataChannelError(error, peerId) {
		self.handleIncomingError(error);
	}

	handleDataChannelClose(event, peerId) {
		//console.debug("dataChannel.OnClose", event);
	}

	handleParsedDataChannelMessage(msg,peerId) {

		if(!self.peers[peerId]) {
			console.error(`Unknown peerId ${peerId} in data channel recieve message : ${JSON.stringify(msg)}`);
			return null;
		}
		return null;
		let ts = self.moment.utc();
		if(msg.ts) {
			ts = self.moment.utc(msg.ts);
		}
		if(msg.mute === true) {
			//Check that the tracks exists
			//We should mute the audio channel connected to peerId
			self.peers[peerId].stream.getTracks().forEach( (t) => {
				t.enabled = false;
			} );

		}
		if(msg.mute === false) {
			//Check that the tracks exists
			//We should unmute the audio channel connected to peerId
			self.peers[peerId].stream.getTracks().forEach( (t) => {
				t.enabled = true;
			} );
		}

	}

	sendDataChannelMessage(peerId, msg) {
		let self = this;
		if(!self.peers[peerId]) {
			console.error(`Unknown peerId ${peerId} in data channel send message : ${JSON.stringify(msg)}`);
			return null;
		}
		if(self.peers[peerId].dataChannel && self.peers[peerId].dataChannel.readyState == 'open') {
			self.peers[peerId].dataChannel.send(JSON.stringify(msg));
		}else{
			console.error(`Peer ${peerId} data channel not ready or closed`);
			return null;
		}

	}

	renegotiate(peerId) {
		let self = this;
		let peer = self.peers[peerId];
		if(!peer) {
			return null;
		}
		if(peer.sessions.length <= 0) {
			return null;
		}
		if(!peer.pc) {
			return null;
		}
		let sessionId = peer.sessions[0];

		if(peer.offered) {
			peer.pc.createOffer({iceRestart:true}).then( (offer) => {
				return peer.pc.setLocalDescription(offer);
			} )
.then( () => {
				let sdp = {sdp: peer.pc.localDescription};
				self.sendForwardMessage(peerId, sdp);
				// TODO: Implement room <01-11-19, Liaan> //
				//if(reason.type == 'session'){
				//}else if(reason.type == 'room'){
				//self.ws_conn.send("ROOM_PEER_MSG "+sessionId+" "+peerId+" "+JSON.stringify(sdp));
				//}
			} )
.catch( (err) => {
				console.error(err);
			} );
		}else{
			peer.ws.send(`SESSION_MSG ${sessionId} ${JSON.stringify({renegotiate:true})}`);
		}
	}

	checkAudioValid(peerId) {
		//check if correct state
		//Check if browser

		let self = this;
		// TODO: Ensure this works  <08-11-19, Liaan> //
		return null;

		let peer = self.peers[peerId];
		let audioIssues = false;
		if(peer && peer.type == 'user' && peer.state == 'connected') {

			var selector = peer.pc.getReceivers().find( (a) => {
				return a.track && a.track.kind == 'audio';
			} );
			if(selector) {
				peer.pc.getStats(selector.track).then( (report) => {
					let found = false;
					report.forEach( (o) => {
						if(o.type == 'inbound-rtp') {
							found = true;
							if(self.bytesCounter[o.id] !== undefined && self.bytesCounter[o.id] >= o.bytesReceived) {
								audioIssues = true;
							}
							self.bytesCounter[o.id] = o.bytesReceived;
						}
					} );
					if(found == false) {
						audioIssues = true;
					}
				} );
			}else{
				audioIssues = true;
			}

			let sendSelector = peer.pc.getSenders().find( (a) => {
				return a.track && a.track.kind == 'audio';
			} );
			if(selector) {
				peer.pc.getStats(sendSelector.track).then( (report) => {
					let found = false;
					report.forEach( (o) => {
						if(o.type == 'outbound-rtp') {
							found = true;
							if(self.bytesCounter[o.id] !== undefined && self.bytesCounter[o.id] >= o.bytesSent) {
								audioIssues = true;
							}
							self.bytesCounter[o.id] = o.bytesSent;
						}
					} );
					if(found == false) {
						audioIssues = true;
					}
				} );
			}else{
				audioIssues = true;
			}
		}
		if(audioIssues) {
			self.renegotiate(peerId);
		}
	}

	getPeerType(peerId) {
		let self = this;
		let user = _.find(self.users, (user) => {
			return peerId == user._id;
		} );
		if(user) {
			return 'user';
		}else{
			return 'unit';
		}

	}


	setRoomMic(roomId,enabled) {
		let self = this;
		let room = self.rooms[roomId];
		if(!room) {
			console.warn('Room not found in setting mic mute : ', roomId);
			return null;
		}
		room.micMuted = !enabled;
		room.peers.forEach( (peerId) => {
			let peer = self.peers[peerId];
			if(peer) {
				if(!enabled && self.canMute(peerId)) {
					self.setPeerMic(peerId, false);
				}else{
					self.setPeerMic(peerId, true);
				}
			}
		} );
	}

	setSessionMic(sessionId, enabled) {
		let self = this;
		let session = self.sessions[sessionId];
		let peer;

		if(!session) {
			console.warn('Session not found in setting mic mute : ', sessionId);
			return null;
		}
		peer = self.peers[session.peerId];
		if(!peer) {
			console.warn('Peer not found in setting mic mute : ', session.peerId);
			return null;
		}

		session.micMuted = !enabled;
		if(!enabled && self.canMute(session.peerId)) {
			self.setPeerMic(session.peerId, false);
		}else{
			self.setPeerMic(session.peerId, true);
		}

	}

	canMute(peerId) {
		let self = this;
		let peer = self.peers[peerId];
		let allow = true;
		if(!peer) {
			//This could be expected
			return null;
		}
		if(peer.sessions && peer.sessions.length > 0) {
			peer.sessions.forEach( (sessionId) => {
				let session = self.sessions[sessionId];
				if(!session.micMuted) {
					allow = false;
				}
			} );
		}
		if(peer.rooms && peer.rooms.length > 0) {
			peer.rooms.forEach( (roomId) => {
				let room = self.rooms[roomId];
				if(!room.micMuted) {
					allow = false;
				}
			} );
		}
		return allow;
	}

	setPeerMic(peerId, enabled) {
		let self = this;
		let peer = self.peers[peerId];
		if(!peer) {
			console.error(`Peer ${peerId} not found, could not set mic to : ${enabled}`);
			return null;
		}
		peer.stream.getTracks().forEach( (t) => {
			t.enabled = enabled;
		} );
	}

	sendForwardMessage(peerId, msg) {
		let self = this;
		let peer = self.peers[peerId];
		if(typeof msg !== 'string') {
			msg = JSON.stringify(msg);
		}
		if(peer) {
			if(peer.sessions && peer.sessions.length > 0) {
				self.ws_conn.send(`SESSION_MSG ${peer.sessions[0]} ${msg}`);
			}else if(peer.rooms && peer.rooms.length > 0) {
				self.ws_conn.send(`ROOM_PEER_MSG ${peer.rooms[0]} ${peerId} ${msg}`);
			}
		}else{
			console.error('Peer not found when sending message : ', peerId);
		}

	}

	setRoomVolume(roomId, enabled) {
		let self = this;
		let room = self.rooms[roomId];
		if(!room) {
			console.warn('Room not found in setting mic mute : ', roomId);
			return null;
		}
		room.volumeEnabled = enabled;
		room.peers.forEach( (peerId) => {
			let audioId = `${peerId}_${roomId}_audio`;
			let audioElement = document.getElementById(audioId);
			if(enabled) {
				audioElement.muted = false;
			}else{
				audioElement.muted = true;
			}
		} );
	}

}

export default angular.module('secutraqApp.dashboard')
.service('webRTCService', WebRTCService);
