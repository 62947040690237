export class ApiKeySettingsComponent {
	/*@ngInject*/
	constructor(Auth, userService, $uibModal, toastr, socket, appConfig, $ngConfirm, $scope, apiKeyService) {
		this.Auth = Auth;
		this.userService = userService;
		this.$uibModal = $uibModal;
		this.toastr = toastr;
		this.socket = socket;
		this.availableRoles = appConfig.apiRoles;
		this.$scope = $scope;
		this.$ngConfirm = $ngConfirm;
		this.apiKeyService = apiKeyService;
		this.apiKeys = [];
	}

	$onInit() {
		let self = this;
		self.apiKeyService.registerListener(self,(event,item)=>{
			self.onReceiptOfKey(item, event);
		}).then((keys) => {
			keys.forEach((key) => self.onReceiptOfKey(key));
		});
	}

	$onDestroy() {
		this.apiKeyService.unregisterListener(this);
	}

	onReceiptOfKey(key, event) {
		let self = this;
		let index = _.findIndex(self.apiKeys, (o) => {
			return o._id === key._id;
		});
		if (index > -1) {
			if (event === 'deleted') {
				self.apiKeys.splice(index, 1);
			} else {
				self.apiKeys.splice(index, 1, key);
			}
		} else {
			self.apiKeys.push(key);
		}
	}
	onEditApi(apiKey) {
		let self = this;
		this.modalInstance = this.$uibModal.open({
			//template: "<alertsmodals></alertsmodals>",
			component: 'createapikeymodal',
			bindToController: true,
			size: 'lg',
			backdrop: 'static',
			resolve: {
				settings() {
					return {
						edit: true,
						create: false,
						item : _.cloneDeep(apiKey),
					};
				}
			}
		});
		this.modalInstance.result.then((result) => {
			return self.apiKeyService.updateApiKey(result)
				.then( () => {
					self.toastr.info('API Key updated');
				} )
				.catch(err=>{
					self.toastr.error('Failed to update API Key');
					console.error(err);
				});
		}).catch((err) => {
			console.error(err);
		});

	}

	onCreateApi(type) {
		let self = this;
		this.modalInstance = this.$uibModal.open({
			//template: "<alertsmodals></alertsmodals>",
			component: 'createapikeymodal',
			bindToController: true,
			size: 'lg',
			backdrop: 'static',
			resolve: {
				settings() {
					return {
						edit: false,
						type,
						create: true,
					};
				}
			}
		});
		this.modalInstance.result.then((result) => {
			self.apiKeyService.saveNewApiKey(result)
				.then( () => {
					self.toastr.info('API Key created');
				} )
				.catch(err=>{
					self.toastr.error('Failed to create API Key');
					console.error(err);
				});
		}).catch((err) => {
			console.error(err);
		});
	}


	onDisableApi(apiKey) {
		let self = this;
		let apiKeyClone = _.clone(apiKey);
		apiKeyClone.active = false;
		self.apiKeyService.updateApiKey(apiKeyClone)
			.then( () => {
				self.toastr.info('API Key disabled');
			} )
			.catch(err=>{
				self.toastr.error('Failed to create API Key');
				console.error(err);
			});
	}

	onEnableApi(apiKey) {
		let self = this;
		let apiKeyClone = _.clone(apiKey);
		apiKeyClone.active = true;
		self.apiKeyService.updateApiKey(apiKeyClone)
			.then( () => {
				self.toastr.info('API Key enabled');
			} )
			.catch(err=>{
				self.toastr.error('Failed to create API Key');
				console.error(err);
			});
	}

	removeAccountFromKey(apiKey,index) {
		let self = this;
		self.apiKeyService.removeAccountFromApiKey(apiKey,apiKey.accounts[index])
			.then( () => {
				self.toastr.info('API Key enabled');
			} )
			.catch(err=>{
				self.toastr.error('Failed to create API Key');
				console.error(err);
			});
	}

	addAccountsToApi(apiKey) {
		let self = this;
		let user = self.Auth.getCurrentUserSync();
		self.availableAccounts = _.filter(user.accounts, (acc) => {
			let apiAccount = _.find(apiKey.accounts,['ref',acc.ref]);
			return !apiAccount && self.Auth.hasPrivilegeSyncAccount('api',acc.ref);
		});

		self.selectedAccounts = [];
		self.$ngConfirm({
			title: `Select Accounts`,
			theme: 'modern',
			scope: self.$scope,
			content: `

		  <ui-select multiple append-to-body="true" theme="select2" style="width:100%;" ng-model="$ctrl.selectedAccounts" required>
		  <ui-select-match placeholder="Select accounts">{{$item.name}}</ui-select-match>
		  <ui-select-choices repeat="item in $ctrl.availableAccounts | filter:$select.search">
		  <div ng-bind-html="item.name | highlight: $select.search"></div>
		  </ui-select-choices>
		  </ui-select>
		  `,
			escapeKey: true,
			backgroundDismiss: true,
			buttons: {
				// long hand button definition
				ok: {
					text: "Add",
					btnClass: 'btn-primary',
					keys: ['enter'], // will trigger when enter is pressed
					action() {
						self.apiKeyService.addAccountsToApi(apiKey,_.map(self.selectedAccounts,'ref'))
							.then( () => {
								self.toastr.info('API Key enabled');
							} )
							.catch(err=>{
								self.toastr.error('Failed to create API Key');
								console.error(err);
							});
					}
				},
				// short hand button definition
				close: {
					text: 'Cancel',
					action() {}
				}
			},
		});
	}

	copyKey(key) {
		let self = this;
		var text_to_share = key;

		// create temp element
		var copyElement = document.createElement("span");
		copyElement.appendChild(document.createTextNode(text_to_share));
		copyElement.id = 'tempCopyToClipboard';
		angular.element(document.body.append(copyElement));

		// select the text
		var range = document.createRange();
		range.selectNode(copyElement);
		window.getSelection().removeAllRanges();
		window.getSelection().addRange(range);

		// copy & cleanup
		document.execCommand('copy');
		window.getSelection().removeAllRanges();
		copyElement.remove();
	}
}

export default angular.module('secutraqApp.settings')
	.component('apikeys', {
		template: require('./settings.apikeys.html'),
		controller: ApiKeySettingsComponent,
		controllerAs: '$ctrl'
	})
	.filter('formatKeyType', function() {
		return function(input) {
			switch (input) {
				case 'secuvue':
					return 'Secuvue Key';
				case 'signalTower':
					return 'Third Party Key';
				default:
					return 'Secuvue Key';
			}
		};
	})
	.name;
