
export class EventActionComponent {

    /*@ngInject*/
    constructor($uibModal, $ngConfirm, toastr, eventService, currentEventService, accountService, Auth) {
        this.$uibModal = $uibModal;
        this.$ngConfirm = $ngConfirm;
        this.toastr = toastr;
        this.eventService = eventService;
        this.currentEventService = currentEventService;
        this.accountService = accountService;
        this.Auth = Auth;
    }

    $onInit() {
        let self = this;
        self.$uibModalInstance = self.modalInstance;
        self.eventDetails = self.resolve.eventDetails;
        self.events = self.resolve.events;
        self.sop = self.resolve.sop;
        self.activeStep = 0;
        self.stepUserInteraction = {};
        self.accountService.getAccountByID(self.Auth.getCurrentAccountSync().ref).then((account) => {
            self.sopClassificationTags = account.sopClassificationTags;
        });

        if (self.events?.length === 1) {
            self.closed = !self.events[0].active
        }

    }

    // Modal cancelled: either cancel pressed or clicked off screen
    close() {
        this.$uibModalInstance.close();
    }

    action() {
        let self = this;
        let proceed = true;
        if (self.sop) {
            _.forEach(self.sop.procedures, (userStep) => {
                if (userStep.compulsory) {
                    if (!userStep.done) {
                        proceed = false;
                    }
                }
            });
        }


        if (!proceed) {
            self.$ngConfirm(`Not all <span style="text-decoration:underline;">compulsory</span> steps have been completed. <br />Please complete all steps that are marked as compulsory`, `Complete all <b>compulsory</b> steps`);
            return false;
        } else {
            let promise;
            if (_.some(self.events, 'query')) {

                promise = self.eventService.closeMultiple(self.sop, undefined, _.map(self.events, 'query'))
            } else {

                promise = self.eventService.closeMultiple(self.sop, _.map(self.events, '_id'))
            }

            promise.then((response) => {
                self.close();
            })
                .catch((err) => {
                    console.error(err);
                    self.toastr('Error closing multiple event!');
                });
        }
    }

    setActiveStep(index) {
        let self = this;
        self.activeStep = index;
    }

}
export default angular.module('secutraqApp.events')
    .component('eventActionModal', {
        template: require('./action-event.html'),
        controller: EventActionComponent,
        controllerAs: "$ctrl",
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&',
            modalInstance: '<'
        }
    });
