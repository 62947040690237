import angular from 'angular';

export class AudioElement {
	/*@ngInject*/
	constructor(moment, $scope, $timeout, $interval) {
		this.moment = moment;
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.$interval = $interval;
		this.status = 'connecting';
	}

	$onInit() {
		let self = this;

		self.durationUpdateInterval = self.$interval( self.updateDuration.bind(self), 500 );
		self.connectedCheckInterval = self.$interval( self.checkConnection.bind(self), 15000 );

		self.peerConnection.ontrack = (event) => {
			self.onRemoteTrack(event.streams[0]);
		};

		if(self.stream) {
			self.onRemoteTrack(self.stream);
		}
		self.audioElement = document.getElementById(self.trackId);

	}

	onRemoteTrack(stream) {
		let self = this;
		self.stream = stream;

		let audioElementFound = new Promise( (res,rej) => {
			if(!self.audioElement) {
				let interval = self.$interval( () => {
					self.audioElement = document.getElementById(self.trackId);
					if(self.audioElement) {
						self.$interval.cancel(interval);
						return res();
					}
				},100,10 );
				interval.then( () => {
					rej('Element not found');

				} );
			}else{
				return res();
			}
		} );
		audioElementFound.then( () => {
			self.audioElement.srcObject = stream;
		} ).catch( (err) => {
			console.error(err);
		} );
		// TODO: Mute logic  <26-11-19, Liaan> //

	}

	updateDuration() {
		let self = this;
		if(self.stream && typeof self.stream.getAudioTracks === 'function') {
			let audioTracks = self.stream.getAudioTracks();
			if(audioTracks && audioTracks.length > 0) {
				let audioTrack = audioTracks[0];
				if(!audioTrack.muted) {
					if(self.status == 'connecting') {
						self.status = 'connected';
					}
					if(!self.startTime) {
						self.startTime = new Date();
					}
					self.duration = self.formatDuration(self.startTime);
				}
			}
		}
	}

	doLog() {
		let self = this;
		console.debug('Audio element : ', self);

	}

	$onDestroy() {
		let self = this;
		self.$interval.cancel(self.durationUpdateInterval);
		self.$interval.cancel(self.connectedCheckInterval);
	}


	formatDuration(date) {

		let self = this;
		let seconds = self.moment().diff(self.moment(date),'seconds');
		if(!seconds) {
			return '00:00';
		}
		var hrs = Math.floor(seconds / 3600);
		seconds -= hrs*3600;
		var mnts = Math.floor(seconds / 60);
		seconds -= mnts*60;
		var str = '';
		if(hrs) {
			str += `${hrs}:`;
		}
		if(mnts) {
			mnts = mnts.toString();
			if(mnts.length == 1) {
				mnts = `0${mnts}`;
			}
			if(str == '') {
				str += `${mnts}:`;
			}else{
				str += `:${mnts}:`;
			}
		}else{
			str += `00:`;
		}
		if(seconds) {
			seconds = seconds.toString();
			if(seconds.length == 1) {
				str += '0';
			}
			str += `${seconds}`;
		}else{
			str += `00`;
		}
		return str;
	}


	toggleVolumeMute() {
		let self = this;
		if(!self.audioElement) {
			self.audioElement = document.getElementById(self.trackId);
		}
		if(self.audioElement) {
			if(self.volumeMuted) {
				self.volumeMuted = false;
				self.audioElement.muted = false;
			}else{
				self.volumeMuted = true;
				self.audioElement.muted = true;
			}
		}
	}

	checkConnection() {
		let self = this;
		if(!self.peerConnection) {
			return null;
		}
		if(self.status == 'connecting') {
			return null;
		}
		var selector = self.peerConnection.getReceivers().find( (a) => {
			return a.track && a.track.kind == 'audio';
		} );
		let promise = Promise.resolve(false);
		if(selector) {
			promise = self.peerConnection.getStats(selector.track).then( (report) => {
				let found = false;
				let audioIssues = false;
				report.forEach( (o) => {
					if(o.type == 'transport') {
						found = true;
						if(self.bytesCounter !== undefined && self.bytesCounter >= o.bytesReceived) {
							audioIssues = true;
						}
						self.bytesCounter = o.bytesReceived;
					}
				} );
				if(found == false) {
					audioIssues = true;
				}
				return audioIssues;
			} );
		}
		promise.then( (audioIssues) => {
			if(audioIssues) {
				self.status = 'dropped';
			}else{
				self.status = 'connected';
			}

		} );
	}
}


export default angular.module('directives.audioElement', [])
	.component('audioElement', {
		template: require('./audio-element.html'),
		controller: AudioElement,
		controllerAs: "$ctrl",
		bindings: {
			trackId: '=',
			stream: '=',
			peerConnection: '='
		}
	})
	.name;
