export class ScheduleService {
	/*@ngInject*/
	constructor($uibModal, moment, Restangular, socket, Auth) {
		let self = this;
		this.$uibModal = $uibModal;

		/**
		 * A routeCollection is an array of {Objects} which represent a
		 * 'collection of routes'. If a user is defining multiple routes, he'll be
		 * defining all those routes linked to a certain site.
		 * The combination of a route + its site is what forms the routeCollection.
		 * Does this make sense?
		 * @type {Array}
		 */
		this.schedules = [];
		this.moment = moment;
		this.Restangular = Restangular;
		this.listenerMap = new Map();
		this.socket = socket;
		this.Auth = Auth;
		this.cacheTimeout = undefined;
		this.lastAccount = undefined;
		this.lastUser = undefined;
		this.joined = false;
		this.rooms = [];

		// self.generateRoute();


	}

	onSocketEvent(event, item, array) {
		let self = this;
		self.schedules = array;
		self.listenerMap.forEach((value, key) => {
			setTimeout(() => {
				value(event, item, array);
			}, 0);
		});
	}

	registerListener(context, cb) {
		let self = this;
		if (!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
		if(!self.lastUser) {
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		} else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
			self.destroyService();
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		}
		let count = self.listenerMap.size;
		self.listenerMap.set(context, cb);
		// If listenermap was empty, (TODO and timer isn't running), join room, syncupdates
		if (!self.joined) {
			if (self.cacheTimeout) {
				clearTimeout(self.cacheTimeout);
				self.cacheTimeout = undefined;
			}
			return self.getSchedules().then((schedules) => {
				self.socket.syncUpdates('schedule', self.schedules, self.onSocketEvent.bind(self), self);
				let filter = {};
				let hasPrivilege = self.Auth.hasPrivilegeSync('schedule.index',undefined,filter);
				if(hasPrivilege && filter && filter.filterParam && filter.filterParam.field == 'groups') {
					filter.filterParam.data.forEach( (group) => {
						self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().ref}:*:active:schedules:${group}`);
						self.rooms.push(`${self.Auth.getCurrentAccountSync().ref}:*:active:schedules:${group}`);
					} );
				}else{
					self.socket.joinRoom(`${self.Auth.getCurrentAccountSync().ref}:*:active:schedules`);
					self.rooms.push(`${self.Auth.getCurrentAccountSync().ref}:*:active:schedules`);
				}
				self.joined = true;
				return schedules;
			});
		} else {
			if(self.cacheTimeout) {
				clearTimeout(self.cacheTimeout);
				self.cacheTimeout = undefined;
			}
			return self.getSchedules();
		}
	}

	destroyService() {
		let self = this;
		if (self.joined) {
			self.socket.unsyncUpdates('schedule', self);
			if(self.rooms && self.rooms.length > 0) {
				self.rooms.forEach( (room) => {
					self.socket.leaveRoom(room);
				} );
				self.rooms = [];
			}
		}
		self.joined = false;
		self.schedules = [];
		self.listenerMap.clear();
		clearTimeout(self.cacheTimeout);
		self.cacheTimeout = undefined;
	}

	unregisterListener(context, cb) {
		let self = this;
		if (!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
		if(!self.lastUser) {
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		} else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
			self.destroyService();
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		}
		self.listenerMap.delete(context);
		if (self.listenerMap.size === 0) {
			self.cacheTimeout = setTimeout(self.destroyService.bind(self), 5000);
		}
	}

	unregisterAll() {
		let self = this;
		if (!self.lastAccount) {
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		} else if (self.lastAccount !== self.Auth.getCurrentAccountSync().ref) {
			self.destroyService();
			self.lastAccount = self.Auth.getCurrentAccountSync().ref;
		}
		if(!self.lastUser) {
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		} else if(self.lastUser !== self.Auth.getCurrentUserSync()._id) {
			self.destroyService();
			self.lastUser = self.Auth.getCurrentUserSync()._id;
		}
		self.listenerMap.clear();
		if (self.listenerMap.size === 0) {
			self.cacheTimeout = setTimeout(self.destroyService.bind(self), 5000);
		}
	}

	getSchedules() {
		let self = this;
		if (self.schedules.length > 0) {
			return new Promise(function(resolve) {
				resolve(_.cloneDeep(self.schedules));
			});
		}
        let lastAccount = _.cloneDeep(self.lastAccount);
        let lastUser = _.cloneDeep(self.lastUser);
		return this.Restangular.all('schedules').customGET('',{activeOnly:true})
			.then((result) => {
				if(lastAccount == self.lastAccount && lastUser == self.lastUser) {
					self.schedules = result.data;
					return _.cloneDeep(result.data);
				}else {
					return [];
				}
			});
	}


    getScheduleWithQuery(query) {
        let self = this;
        return this.Restangular.all('schedules').customGET('', query)
        .then((response) => {
            return _.cloneDeep(response);
        })
.catch((err) => {
            console.error(err);
        });
    }

	createNewSchedule() {
		// let self = this;
		// let modalInstance = self.$uibModal.open({
		// 	component: 'newRoute',
		// 	size: 'xlg',
		// 	backdrop: 'static',
		// 	keyboard: false
		// });
		//
		// // TODO: We must notify the server of the new route
		// return modalInstance.result.then((result) => {
		// 	let promise = new Promise((resolve, reject) => {
		// 		resolve(result)
		// 	});
		// 	return promise;
		// });
		//
		// return modalInstance.dismiss.then((reason) => {
		// 	let promise = new Promise((resolve, reject) => {
		// 		reject(reason);
		// 	});
		// })
	}

	// TODO: We must notify the server of the edited route
	editSchedule() {
		// let self = this;
		//
		// //Whilst editing we are saving to the original document, which we want to avoid
		// let newRouteDefinition = _.cloneDeep(routeDefinition);
		// let newRoute = _.cloneDeep(route);
		//
		// let routeToEdit = newRoute;
		// let routes = newRouteDefinition.routes;
		// let linkedToSite = newRouteDefinition.linkedToSite;
		// let waypoints = newRouteDefinition.waypoints;
		//
		// let modalInstance = self.$uibModal.open({
		// 	component: 'newRoute',
		// 	size: 'xlg',
		// 	backdrop: 'static',
		// 	keyboard: false,
		// 	resolve: {
		// 		edit: true,
		// 		settings: {
		// 			routeToEdit: routeToEdit,
		// 			routes: routes,
		// 			linkedToSite: linkedToSite,
		// 			waypoints: waypoints,
		// 			routeCollectionID: newRouteDefinition._id
		// 		}
		// 	}
		// });
		//
		// return modalInstance.result.then((result) => {
		// 	let promise = new Promise((resolve, reject) => {
		// 		resolve(result)
		// 	});
		// 	return promise;
		// });
		//
		// return modalInstance.dismiss.then((reason) => {
		// 	let promise = new Promise((resolve, reject) => {
		// 		reject(reason);
		// 	});
		// })
	}

	saveNewSchedule(schedule) {
		let self = this;
		return self.Restangular.all('schedules').post(schedule)
.then((response) => {
			return response;
		});
	}

	updateSchedule(schedule) {
		let self = this;
		let patch = self.Restangular.one('schedules',schedule._id).customPATCH(schedule)
			.then((response)=>{
				return response;
			})
			.catch((e) => {
				console.error(e);
			});
		return patch;
	}

	removeSchedule(schedule) {
		let self = this;
		return self.Restangular.one('schedules',schedule._id).remove()
.then((response)=>{
			return response;
		});
	}

}

export default angular.module('secutraqApp.dashboard')
	.service('scheduleService', ScheduleService);
