'use strict';
// @flow
export class ConfigureGroupComponent {
	/*@ngInject*/
	constructor(Auth, NgTableParams, $uibModal, $ngConfirm, $stateParams, groupService, $timeout, moment) {
		this.Auth = Auth;
		this.NgTableParams = NgTableParams;
		this.$uibModal = $uibModal;
		this.$ngConfirm = $ngConfirm;
		this.$stateParams = $stateParams;
		this.groupService = groupService;
		this.$timeout = $timeout;
		this.moment = moment;

	}

	$onInit() {
		let self = this;
	}


	/**
	 * We confirm whether the user wants to remove  the site, and calls self.removeSite, a function
	 * that we bind to that comes from SiteComponent
	 */
	onRemoveGroup($event, group) {
		let self = this;
		if ($event) {
			$event.stopPropagation();
			$event.preventDefault();
		}

		self.$ngConfirm({
			title: `Remove <b>${group.name}</b>?`,
			theme: 'supervan',
			content: `Are you sure you want to remove this interactive group.`,
			escapeKey: true,
			backgroundDismiss: true,
			buttons: {
				// long hand button definition
				ok: {
					text: "Ok",
					btnClass: 'btn-primary',
					keys: ['enter'], // will trigger when enter is pressed
					action(scope) {
						self.removeGroup({
							group
						});
					}
				},
				// short hand button definition
				Close(scope) {

				}
			},
		});
	}

	newGroup() {
		let self = this;
		self.openNewGroupModal();
	}

	editGroup($event, group) {
		let self = this;
		if ($event) {
			$event.stopPropagation();
			$event.preventDefault();
		}
		self.openEditGroupModal(group);
	}

	/**
	 * Responsible for creating a new site, but consider using a service for opening this modal like we did with routes
	 * @return {[type]} [description]
	 */
	openNewGroupModal() {
		let self = this;
		let modalInstance = self.$uibModal.open({
			component: 'newGroup',
			backdrop: 'static',
			size: 'xlg',
			windowTopClass: 'newGroupModal',
			keyboard: false
		});

	}

	openEditGroupModal(group) {
		let self = this;
		let groupCopy = _.cloneDeep(group);
		let modalInstance = self.$uibModal.open({
			component: 'newGroup',
			backdrop: 'static',
			size: 'xlg',
			windowTopClass: 'newGroupModal',
			keyboard: false,
			resolve: {
				group: groupCopy
			}

		});

	}

	doLog() {
		console.debug(this);
	}

}


export default angular.module('secutraqApp.groups')
	.component('listGroups', {
		template: require('./group-list.html'),
		controller: ConfigureGroupComponent,
		controllerAs: '$ctrl',
		bindings: {
			groups: '=',
			removeGroup: '&'
		}
	})
	.filter('humanizeDuration', (moment) => {
		return (seconds) => {
			if(!seconds) {
				return 'Never';
			}
			var days = Math.floor(seconds / (3600*24));
			seconds -= days*3600*24;
			var hrs = Math.floor(seconds / 3600);
			seconds -= hrs*3600;
			var mnts = Math.floor(seconds / 60);
			seconds -= mnts*60;
			let str = 'Every ';
			if(days) {
				str += `${days} Days,`;
			}
			if(hrs) {
				str += `${hrs} Hours,`;
			}
			if(mnts) {
				str += `${mnts} Minutes,`;
			}
			if(seconds) {
				str += `${seconds} Seconds`;
			}
			return str;
		};
	})
	.name;
