export class GroupComponent {

    /*@ngInject*/
    constructor($scope, $timeout, $state, groupService, $stateParams, Restangular, unitService) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.groupService = groupService;
        this.unitService = unitService;
        this.$stateParams = $stateParams;
        this.Restangular = Restangular;
		this.limitGroups = 10;
		this.currentPage = 1;
		this.totalGroups = 0;

    }

    $onInit() {
        let self = this;
        self.groups = [];

        self.groupService.registerListener(self,(event,item,array)=>{
            if (event === 'deleted') {
                self.onRemoveGroup(item);
            } else {
                self.onReceiptOfGroup(item);
            }
        }, true);
		self.updateGroups();

    }

	updateGroups() {

		let self = this;
		self.groups = [];
		self.loadingGroups = true;
		// TODO: Add paging  <08-02-21, Liaan> //
		let query = {
			//filter: self.filter.length ? self.filter : undefined,
			skip: (self.currentPage-1)*self.limitGroups,
			limit: self.limitGroups,
			//by: 'createdAt',
			//order: 'desc',
			//params:[{
				//field:'type',
				//value:'Generate Report',
				//type:'string',
				//operator:'$eq'
			//}],
		};
		let promises = [
			self.groupService.getGroupsWithQuery(query),
			self.groupService.countGroupsWithQuery(query)
		];
		Promise.all(promises)
			.then( (data) => {
				self.loadingGroups = false;
				let groups = data[0];
				for (const group of groups) {
					self.insertUnitsInGroup(group);
				}
				self.groups = groups;
				self.totalGroups = data[1];
			})
			.catch(err=>{
				self.loadingGroups = false;
				console.error(err);
			});
	}

	$onDestroy() {
		this.groupService.unregisterListener(this);
	}

    insertUnitsInGroup(group) {
        let self = this;
        let promises = [];
        group.units.forEach((unit) => {
            let p = self.unitService.getUnitByID(unit.unit, ['name','cname','description']);
            promises.push(p);
        });
        return Promise.all(promises).then((units) => {
			units.forEach( (unit) => {
				let unitInGroup = _.find(group.units,['unit',unit._id]);
				if(unitInGroup) {
					unitInGroup.data = unit;
				}else{
					console.error("Unit not found : ", unit._id, group.units);
				}
			});
            return group;
        });
    }

    onReceiptOfGroup(tempGroup) {


		let newGroup = _.cloneDeep(tempGroup);

        let self = this;

        let index = _.findIndex(self.groups, (group) => {
            return newGroup._id === group._id;
        });

		if(index === -1) {
			if(self.totalGroups < self.limitGroups) {
				self.insertUnitsInGroup(newGroup)
					.then((groupWithUnits) => {
						self.groups.unshift(groupWithUnits);
						self.totalGroups ++;
					})
					.catch((err) => {
						console.error(err);
					});
			}else{
				self.totalGroups ++;
			}
		} else {
			self.insertUnitsInGroup(newGroup)
				.then((groupWithUnits) => {
					self.groups.splice(index, 1, groupWithUnits);
				})
				.catch((err) => {
					console.error(err);
				});
		}
    }


    /**
     * At SiteListComponent we have onRemoveSite() which then calls to this removeSite() (via bindings)
     */
    onRemoveGroup(group) {
        let self = this;
        let index = _.findIndex(self.groups, (o) => {
            return group._id === o._id;
        });
        if(index !== -1) {
            self.groups.splice(index, 1);
        }
    }

    removeGroup(group) {
        let self = this;
        self.groupService.removeGroup(group);
    }

    doLog() {
		console.debug(this);
    }
}
export default angular.module('secutraqApp.groups')
.component('group', {
    template: require('./group.component.html'),
    controller: GroupComponent,
    controllerAs: "$ctrl"
});
