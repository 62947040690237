export default function($stateProvider) {
  'ngInject';
  $stateProvider
  .state('signup' , {
    url: '/signup',
    views: {
      'root' : {
        template: '<signup></signup>'
      }
    }
  })

}
