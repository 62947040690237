'use strict';

export function routerDecorator($rootScope, $state, Auth, $q, $transitions, socket, $stateParams, $localStorage) {
	'ngInject';
	// Redirect to login if route requires auth and the user is not logged in, or doesn't have required role

	$rootScope.$on('$stateChangeError', function(e, toState, toParams, fromState, fromParams, error) {
		   console.error("State Changed Error ", error);
	});
	$state.defaultErrorHandler(function(err) {
		   console.error("State Error : ", err);
	});

	$transitions.onBefore({}, function(trans) {
		var $state = trans.router.stateService;
		let socketPromise = function() {

			return $q((resolve, reject) => {
				if (socket.socket.ioSocket.connected) {
					resolve(true);
				} else {
					socket.socket.once('connect', (socket) => {
						resolve(true);
					});
				}
			});
        };

		if (trans.to().authenticate && typeof trans.to().authenticate === 'string') {

            return Auth.hasRole(trans.to().authenticate).then((has) => {
				if (has) {
					if (!trans.params().accountID) {
						return $state.target(trans.to().name, {
							accountID: Auth.getCurrentAccountSync().ref
						});
					} else {
						return socketPromise();
					}
				} else {
					return $state.target('main.dashboard');
				}
			});
		} else if (trans.to().authenticate) {
            if(trans.params('to').accountID) {
				$localStorage.currentAccount = trans.params('to').accountID;
			}
			return Auth.isLoggedIn()
				.then(is => {
					if (is) {
						if (!trans.params().accountID) {
							return $state.target(trans.to().name, {
								accountID: Auth.getCurrentAccountSync().ref
							});
						} else {
							return socketPromise();
						}
					} else {
						if (trans.to().name !== 'login') {
							Auth.saveRedirectState({name:trans.to().name,params:trans.params('to')});
						}
						return $state.target('login');
					}
				});
		} else {
            return true;
		}

	});

}
