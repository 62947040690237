export default function($stateProvider) {
    'ngInject';
    $stateProvider
    .state('main.groups', {
        url: '/group',
        views: {
            'content@main' : {
                template: '<group></group>'
            }
        },
        authenticate: true,
        data: {
            title: 'Interactive Groups'
        }
    })
}
