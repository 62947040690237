// TODO: Carousel is really funky sometimes. I guess I should really take time to work through this
export class EventCarouselService {
    /*@ngInject*/
    constructor($timeout, currentEventService, eventService, toastr) {
        this.$timeout = $timeout;
        this.metaData = {};
        this.currentEventService = currentEventService;
        this.eventService = eventService;
        this.widgetLocked = false;
        this.showOnlyOpen = true;
		this.toastr = toastr;
    }

    preliminarilySetFootages(event, openWidget) {
        let self = this;
        self.clearFootages(true);
        self.setFootages(event, openWidget);
    }

    preliminarilySetFootagesUnit(unit, openWidget) {
        let self = this;
        self.clearFootages(true);
        self.setFootages({_id:unit._id,unit:unit._id}, openWidget);
    }

    setFootagesUnit(unit,openWidget) {
        let self = this;
        if(self.metaData.footagesId == unit._id) {
            self.metaData.slickLoaded = true;
            if(openWidget) {
                self.openCarouselWidget();
            }
        }else{
            if(self.metaData.footages.length > 0) {
                self.clearFootages();
            }
            self.$timeout(() => {
                //Give Slick widget time to close
                self.metaData.footagesId = unit._id;
                self.metaData.footages = unit.lastPhoto;
                self.metaData.slickLoaded = true;
                if(openWidget) {
                    self.openCarouselWidget();
                }

            },300);

        }
    }

    /**@see DashboardComponent
     * This function gets called at the end of setCurrentEvent
     * Its purpose is to determine the footages to be loaded.
     * TODO: This will need further refinement when (if) we load all
     *       footages for the specific PERSON
     */
    setFootages(currentEvent, openWidget) {
        let self = this;
        self.metaData.slickLoaded = false;

		if(!currentEvent) {
			return null;
		}
        if(currentEvent && self.metaData.footagesId == currentEvent._id) {
            self.metaData.slickLoaded = true;
            if(openWidget) {
                self.openCarouselWidget();
            }
        }else{
            if(self.metaData.footages.length > 0) {
                self.clearFootages();
            }
            if(currentEvent.unit) {
                self.eventService.getAllOpenPhotosForUnit(currentEvent.unit,10,0,self.showOnlyOpen)
                .then((photos)=>{
					self.metaData.footagesId = currentEvent._id;
                    if(photos.length>0) {
                        self.metaData.footages = photos;
                        //slick depends on ng-if. This way, slick won't init until footages are loaded
                        self.$timeout(function() {
                            self.metaData.slickLoaded = true;
                            if(openWidget) {
                                self.openCarouselWidget();
                            }
                        }, 100);
                    }else{
                        //TODO: Handel correctely
						if(self.showOnlyOpen) {
							self.toastr.warning("No open photos for unit.");
						}else{
							self.toastr.warning("No photos for unit.");
						}
						self.metaData.slickLoaded = false;
                        console.error("No photos");
                    }
                });
            }else if(currentEvent.lastPhoto && currentEvent.lastPhoto.length > 0) {
                self.metaData.footagesId = currentEvent._id;
                self.metaData.footages = currentEvent.lastPhoto;
                //slick depends on ng-if. This way, slick won't init until footages are loaded
                self.$timeout(function() {
                    self.metaData.slickLoaded = true;
                    if(openWidget) {
                        self.openCarouselWidget();
                    }
                }, 100);
            }
        }
    }

    loadMoreImages(currentEvent) {

        let self = this;
        if(currentEvent.unit) {
			let eventIds = _.map(self.metaData.footages,'eventId');
			let eventCount = _.uniq(eventIds).length;
            self.eventService.getAllOpenPhotosForUnit(currentEvent.unit,10,eventCount,self.showOnlyOpen)
            .then((photos)=>{
                self.metaData.slickLoaded = false;
                if(photos && photos.length>0) {
                    self.metaData.footages = _.concat(self.metaData.footages,photos);

                    self.metaData.footages.sort((a, b)=>{
                        if (a.ts > b.ts) {
                            return -1;
                        } else if (a.ts < b.ts) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });
                    //slick depends on ng-if. This way, slick won't init until footages are loaded

                }else{
                    //TODO: Handel correctely
                    console.error("No photos");
                }
                self.$timeout(() => {
                    self.metaData.slickLoaded = true;
                },400);
            });
        }
    }

    clearFootages(closeWidget) {
        let self = this;
        if(closeWidget) {
            self.closeCarouselWidget();
        }
        // self.$timeout(() => {
        self.metaData.footagesId = undefined;
        self.metaData.slickLoaded = false;
        self.metaData.footages = {};
            // self.cleanObject(self.metaData.footages);
        // }, 200);

    }

    /**
     * This function currently gets invoked from mapService.
     * This happens when a users clicks on one of the Mainheadings
     * and we wish to display a collection of marker's footages in the
     * carousel
     */
    loadFootagesFromMarkers(markers) {
        let self = this;
        let footageCollection = [];

        _.forEach(markers, (marker) => {
            let event = self.eventService.getEventFromMarker(marker);
            if(event && event.snapshots) {

                let markerFootages = self.eventService.getEventFromMarker(marker).snapshots;
                _.forEach(markerFootages, (footage) => {
                    footageCollection.push(footage);
                });
            }
        });

        self.clearFootages(true);
        self.$timeout(() => {
            self.metaData.footages = footageCollection;
            self.metaData.slickLoaded = true;
        }, 400);


    }

    /**
     * NOTE: I'm questioning everything I've written here: (I'm also not using this function anymore)
     * This function cleans an object. We do this because we don't simply want to assign another
     * empty reference to self.metaData.footages = {} and leave the rest to garbageCollection.
     * This is because we're passing around REFERENCES between services, etc and things get messy
     * when we create new references instead of simply cleaning the existing ones.
     */
    cleanObject(objectToClean) {
        for (let x in objectToClean) {
if (objectToClean.hasOwnProperty(x)) {
delete objectToClean[x];
}
}
    }

    getMetaData() {
        let self = this;
        return new Promise((resolve, reject) => {
            resolve(self.metaData);
        });
    }

    /**
     * To close our SmartAdmin jarvisWidget
     * @return {[type]} [description]
     */
    closeCarouselWidget() {
        let self = this;
        if (!$("#event-carousel-widget").hasClass("jarviswidget-collapsed") && !self.widgetLocked) {
            $("#event-carousel-widget a.jarviswidget-toggle-btn").trigger("click");
        }
    }
    /**
    * To open our SmartAdmin jarvisWidget
    * @return {[type]} [description]
    */
    openCarouselWidget() {
        let self = this;
        if ($("#event-carousel-widget").hasClass("jarviswidget-collapsed") && !self.widgetLocked) {
            $("#event-carousel-widget a.jarviswidget-toggle-btn").trigger("click");
        }
    }

    lockCarouselWidget() {
        let self = this;
        self.widgetLocked = true;
        $("#event-carousel-widget a.jarviswidget-toggle-btn").addClass("disabled");
    }
    unlockCarouselWidget() {
        let self = this;
        self.widgetLocked = false;
        $("#event-carousel-widget a.jarviswidget-toggle-btn").removeClass("disabled");
    }


    setShowOpenAndClosed() {
        let self = this;
        self.showOnlyOpen = false;
    }

    setShowOnlyOpen() {
        let self = this;
        self.showOnlyOpen = true;
    }

    /**
     * @return {Boolean} [Whether we have an open widget]
     */
    widgetIsOpen() {
        let self = this;
        if ($("#event-carousel-widget").hasClass("jarviswidget-collapsed")) {
            return false;
        } else {
            return true;
        }
    }


}
export default angular.module('secutraqApp.dashboard')
.service('eventCarouselService', EventCarouselService);
