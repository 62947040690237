export class ResetPasswordComponent {
	/*@ngInject*/
	constructor($stateParams, $http, $log, $window) {
		this.$stateParams = $stateParams;
		this.$http = $http;
		this.$window = $window;
		this.$log = $log;
		this.url = this.$stateParams.next;
		this.user = {password: ''};
		this.token = this.$stateParams.token;
		this.uid = this.$stateParams.id;
		this.verifying = false;
		this.verified = false;
		this.changing = false;
		this.submitted = false;

		//if(this.token && this.uid) {
		//this.verifying = true;
		//this.User.resetPasswordTest(this.uid, this.token).then(() => {
		//this.verified = true;
		//this.verifying = false;
		//});
		//}
	}

	$onInit() {
		this.hostnameIcon = null;

		this.$http.get(`assets/images/domainicons/${this.$window.location.hostname}.png`)
			.then(data => {
				this.hostnameIcon = `assets/images/domainicons/${this.$window.location.hostname}.png`;
			})
.catch( (err) => {
				this.hostnameIcon = null;
			} );
	}

	changePassword(form) {
		this.submitted = true;
		this.changing = true;
		if(form.$valid) {
			this.$http.put(`api/users/${this.uid}/forgot/${this.token}`, {password: this.user.password})
				.then(() => {
					this.changed = true;
					this.changing = false;
				})
				.catch( (err) => {
					console.error(err);
				} );
		}
	}
}

export default angular.module('secutraqApp.reset')
	.component('resetpassword', {
		template: require('./reset.password.html'),
		controller: ResetPasswordComponent,
		controllerAs: '$ctrl'
	})
	.name;
