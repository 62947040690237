'use strict';

import fabricJS from 'fabric';
import async from 'async-es';
import OptionsController from './options.controller';
import UploadController from './upload.controller';
import FilterController from './filter.controller';
import SnapshotModalController from '../imageCollection/modal.controller';
import LookupController from './lookup.controller';

export class FaceCollectionComponent {
    $http;
    $document;
    $state;
    $scope;
    moment;
    Auth;
    $uibModal;
    faceRefs;
    tempCrop={};
    newUser;
    filter={};
    socket;
    faceDataSource={}
    faceAdapter={
        adapter: {
            remain: true
        }
    };
    tempFilter={};
    tempWanted={};
    tempsnaps={};
    currentRoom = "";
    lastFilter={};


    /*@ngInject*/
    constructor($http, $window, socket, $uibModal, moment, $document, $state, $scope, Auth, $timeout) {
        this.$http = $http;
        this.moment = moment;
        this.socket = socket;
        this.$uibModal = $uibModal;
        this.$document = $document;
        this.$timeout = $timeout;
        this.$window = $window;
        this.$state = $state;
        this.$scope = $scope;
        this.Auth = Auth;
        this.hasPrivilege = Auth.hasPrivilegeSync;
        this.custClass = 'hidden-panel';
    }

    doLog() {
		console.debug(this);
    }

    $onInit() {
        let self = this;
        // self.faceDataSource={};
        // self.faceDataSource.minIndex=0;
        self.faceDataSource.get = self.getFaces.bind(self);
        if(self.$state.params.filter) {
            self.lastFilter.alias = self.$state.params.filter;
            self.applyFilter(self.lastFilter);
        }
        let room = `${self.Auth.getCurrentAccountSync().ref}:*:references`;
        self.$http.get('/api/references/refCount', {params: {alias: self.filter.alias, tags: self.filter.tags, flag: self.filter.flag, description: self.filter.description}}).then(response => {
            self.faceRefs = response.data;
        });
    }


    getFaces(descriptor, success) {
        let self = this;
        if(!descriptor.append && !descriptor.prepend) {
            self.$http.get('/api/references/', {
                params: {
                    pagesize: descriptor.count,
                    alias: self.filter.alias,
                    tags: self.filter.tags,
                    flag: self.filter.flag,
                    description: self.filter.description
                }
            })
                .then(response => {
                    async.each(response.data, (face, callback) => {
                        if(face.dataCropped) {
                            self.tempCrop[`${face.faceId}`] = face.dataCropped;
                        } else if(!self.tempCrop[`${face.faceId}`]) {
                            self.cropFace(face);
                        }
                        callback();
                    }, err => {
                        if(err) {
                            console.error(err);
                        }
                        success(response.data);
                    });
                });
        } else if(descriptor.append) {
            //let timestamp = +self.moment(+descriptor.append.timestamp).utc();
            let timestamp = new Date(descriptor.append.timestamp).getTime();
            self.$http.get('/api/references/', {
                params: {
                    pagesize: descriptor.count,
                    alias: self.filter.alias,
                    tags: self.filter.tags,
                    flag: self.filter.flag,
                    description: self.filter.description,
                    after: timestamp,
                    sort: {timestamp:-1}
                }
            })
                .then(response => {
                    async.each(response.data, (face, callback) => {
                        if(face.dataCropped) {
                            self.tempCrop[`${face.faceId}`] = face.dataCropped;
                        } else if(!self.tempCrop[`${face.faceId}`]) {
                            self.cropFace(face);
                        }
                        callback();
                    }, err => {
                        success(response.data);
                    });
                });
        //} else if(false) {
        } else {
            //let timestamp = +self.moment(+descriptor.prepend.timestamp).utc();
            let timestamp = new Date(descriptor.prepend.timestamp).getTime();
            self.$http.get('/api/references/', {
                params: {
                    pagesize: descriptor.count,
                    alias: self.filter.alias,
                    tags: self.filter.tags,
                    flag: self.filter.flag,
                    description: self.filter.description,
                    before: timestamp,
                    sort: {timestamp: 1}
                }
            })
                .then(response => {
                async.each(response.data, (face, callback) => {
                    if(face.dataCropped) {
                        self.tempCrop[`${face.faceId}`] = face.dataCropped;
                    } else if(!self.tempCrop[`${face.faceId}`]) {
                        self.cropFace(face);
                    }
                    callback();
                }, err => {
                    success(response.data);
                });
            });
        }
    }

    applyFilter(filter) {
        let self = this;
        self.filter = _.clone(filter);
        self.$http.get('/api/references/refCount', {params: {alias: self.filter.alias, tags: self.filter.tags, flag: self.filter.flag, description: self.filter.description}}).then(response => {
            self.faceRefs = response.data;
        });
        self.faceAdapter.reload();
    }

    update(id, value) {
        let self = this;
        self.$http.patch(`/api/references/${id}`, _.pick(value, ['alias', 'flag', 'description', 'tags'])).then(response => {
        });
    }

    reverseArray(array) {
        let tempArr = [];
        for(let i = array.length - 1; i >= 0; i--) {
            tempArr.push(array[i]);
        }
        return tempArr;
    }

    cropFace(face) {
        if(fabricJS.hasOwnProperty('fabric')) {
            let fabric = fabricJS.fabric;
        }
        let self = this;
		self.$http({url:face.data,method:"GET",responseType:'arraybuffer'}).then( (res) => {
			let imageB64 = self.arrayBufferToBase64(res.data);
			let dataUrl = `data:image/jpg;base64,${imageB64}`;

			return dataUrl;
		} )
.then( (dataUrl) => {
			let im = new fabric.Image.fromURL(dataUrl, function(image) {
				image.setOptions({
					left: 0,
					top: 0,
					opacity: 1,
					width: face.width,
					height: face.height
				});
				self.$timeout(function() {
					self.tempCrop[face.faceId] = image.toDataURL({multiplier: 3, left: face.boundingBox.Left * face.width, top: face.boundingBox.Top * face.height, width: face.boundingBox.Width * face.width, height: face.boundingBox.Height * face.height});
				});
			});
		});
    }

    //clearCollection(){
    //this.$http.get('/api/references/clearCollection').then((response)=>{
    //});
    //}

    editRef(ref) {
        let self = this;
        self.$uibModal.open({
            animation: true,
            backdrop: true,
            template: require('./options.html'),
            controller: OptionsController,
            controllerAs: '$ctrl',
            size: 'lg',
            resolve: {
                reference() {
                    return ref;
                },
                smallFace() {
                    return self.tempCrop[ref.faceId];
                }
            }
        }).result.then(result => {
            if(result.reference) {
                return self.update(result.reference.faceId, result.reference);
            } else if(result.deleting) {
                return self.deleteRef(result.deleting);
            } else if(result.snap) {
                return self.openSnap(result.snap);
            }
        }, function() {});
    }

    openSnap(snapshot) {
        let self = this;
        self.$uibModal.open({
            animation: true,
            backdrop: true,
            template: require('../imageCollection/modal.html'),
            controller: SnapshotModalController,
            controllerAs: '$ctrl',
            size: 'lg',
            resolve: {
                snapshot() {
                    return snapshot;
                },
                snapPlaceholder() {
                    return [];
                }
            }
        }).result.then(function(result) {
            if(result && result.newSnap) {
                self.openSnap(result.newSnap);
            }
        }, function() {});
    }

    deleteRef(reference) {
        let self = this;
        self.$http.delete(`/api/references/${reference}`).then(response => {
            self.faceRefs = self.faceRefs-1;
            self.faceAdapter.reload();
        });
    }

    lookup() {
        let self = this;
        self.$uibModal.open({
            animation: true,
            backdrop: true,
            template: require('./lookup.html'),
            controller: LookupController,
            controllerAs: '$ctrl',
            size: 'xlg',
            resolve: {

            }
        }).result.then(result => {
            if(result.face) {
                self.editRef(result.face);
            }
        }, function() {});
    }

    uploadWanted() {
        let self = this;
        self.$uibModal.open({
            animation: true,
            backdrop: true,
            template: require('./upload.html'),
            controller: UploadController,
            controllerAs: '$ctrl',
            size: 'lg',
            resolve: {
            }
        }).result.then(result => {
        }, function() {});
    }

    clearFilter() {
        let self = this;
        self.lastFilter = {};
        self.applyFilter(self.lastFilter);
    }

    openFilter() {
        let self = this;
        self.$uibModal.open({
            animation: true,
            backdrop: true,
            template: require('./filter.html'),
            controller: FilterController,
            controllerAs: '$ctrl',
            size: 'lg',
            resolve: {
                lastFilter() {
                    return self.lastFilter;
                }
            }
        }).result.then(result => {
            if(result.filter) {
                self.lastFilter = result.filter;
                return self.applyFilter(result.filter);
            }
        }, function() {});
    }

	arrayBufferToBase64(buffer) {
		var binary = '';
		var bytes = new Uint8Array(buffer);
		var len = bytes.byteLength;
		for (var i = 0; i < len; i++) {
			binary += String.fromCharCode(bytes[i]);
		}
		return window.btoa(binary);
	}
}


export default angular.module('secutraqApp.photoAnalytics')
  .component('faceCollection', {
    template: require('./faceCollection.html'),
    controller: FaceCollectionComponent,
    controllerAs: '$ctrl'
  })
  .name;
