import leafletPip from '@mapbox/leaflet-pip';
import turfPip from '@turf/boolean-point-in-polygon';

export class NewRouteComponent {
    /*@ngInject*/
    constructor($uibModal, genericMapService, siteService, $timeout, $interval, $ngConfirm, $scope, toastr, uuid, numbersService, routeService, scheduleService, shiftService, moment, privGroupService, Auth) {
        this.$uibModal = $uibModal;
        this.genericMapService = genericMapService;
        this.siteService = siteService;
        this.$timeout = $timeout;
        this.$interval = $interval;
        this.$ngConfirm = $ngConfirm;
        this.$scope = $scope;
        this.toastr = toastr;
        this.uuid = uuid;
        this.numbersService = numbersService;
		this.routeService = routeService;
		this.scheduleService = scheduleService;
		this.shiftService = shiftService;
		this.moment = moment;
		this.privGroupService = privGroupService;
		this.Auth = Auth;
    }

    $onInit() {
        let self = this;

        /**
         * This object contains all the waypoint-markers that we place on the map
         * NOTE: I don't think I'm using this anywhere and might as well remove it
         * @type {Object}
         */
        self.waypointLayers = {};

        /**
         * The default color used for drawing all sites on the map
         * @see drawAllSites()
         * @type {String}
         */
        self.defaultDrawingColor = "#3388ff";

        /**
         * The radius-circle of the waypoint that we select changes to this color
         * @type {String}
         */
        self.selectedWaypointCircleColor = "#ff8b33";

        /**
         * This is the color of all waypoints placed.
         * @type {String}
         */
        self.plantedWaypointCircleColor = "#33ff74";

        /**
         * This array contains details concerning each waypoint that we create
         * @type {Array}
         */
        self.waypoints = [];

        /**
         * This array contains all the available waypoint-types
         * @type {Array}
         */
        self.availableWaypointTypes = ['GPS','iButton', 'Bluetooth'];

        /**
         * Using this variable for the fade-animation when selecting another waypoint
         * @see handleWaypointFadeIn()
         * @see handleWaypointFadeOut()
         */
        self.waypointFadingTime = 300;

        /**
         * This number represents the index in self.waypoints from which we are to handle
         * adding waypoints.
         * It's important for smaller details, such as highlighting the appropriate
         * waypoint when we have finished planting
         * @see startDrawingMarkers()
         * @type {Number}
         */
        self.indexToSelect = null;

        /**
         * Merely an object that gets assigned the currently selected waypoint
         * @type {Object}
         */
        self.selectedWaypoint = {};

        /**
         * We use this object to keep track of the last committed waypoint as we are drawing.
         * When we finish drawing we clear it again.
         * We make use of this object with the UNDO-button
         * @see undoLastAddedWaypoint()
         * @type {Object}
         */
        self.lastDrawnWaypoint = {};

        /**
         * We have a ui-select that uses this variable as its model.
         * NOTE That we are also adding the leaflet layer of the drawn polygon to
         * this object
         * @type {Object}
         */
        self.boundedSite = {};

        /**
         * When the user explicitly states that he wishes to link his waypoints
         * to the site that he has chosen.
         * @type {Boolean}
         */
        self.linkWaypointsToChosenSite = false;

        /**
         * In order to add markers by clicking, we make use of this variable
         * @type {Boolean}
         */
        self.drawingMarkers = false;

        /**
         * We have an advanced-settings-container that has an ng-class on this Boolean.
         * This value only gets changed by @see onShowAdvancedSettings();
         * @type {Boolean}
         */
        self.showAdvancedSettings = false;

        /**
         * Don't think I'm using this and can consider removing it.
         * @type {Boolean}
         */
        self.moveAdvancedSettingsIcon = false;

        /**
         * The same principle as showAdvancedSettings above
         * @type {Boolean}
         */
        self.showAdvancedFind = false;

        /**
         * Whether the map ought to display additional details.
         * It gets toggled from the advanced advanced settings
         * After a user has defind his waypoints, it's very possible that he would
         * want to hide all other details
         * @see toggleShowMapLayer()
         * @type {Boolean}
         */
        self.showMapLayer = true;

        /**
         * Upon initial load we are showing all the sites.
         * We use the material-switch to turn this on or off
         * @see toggleShowSites()
         * @type {Boolean}
         */
        self.showSites = true;

        /**
         * Whether we want to display all sitenames on popups
         * @see toggleShowSitenames()
         * @type {Boolean}
         */
        self.showSitenames = false;

        /**
         * Whether we want to display distance-lines between waypoints
         * that was placed.
         * NOTE that we are currently showing it between the ordered
         * waypoints.
         * @see toggleShowDistances()
         * @type {Boolean}
         */
        self.showDistanceLines = true;

        /**
         * Whether we want to display the distances in tooltips placed on distance-lines
         * NOTE that if this value has a default of false, I have to change the initial
         * behaviour when we define our routes.
         * @type {Boolean}
         */
        self.showDistances = true;

        /************************************************
         * * * * * *PERTAINING TO ROUTES(start)* * * * *
         ***********************************************/

        /**
         * [advancedRouteTiming description]
         * @see toggleAdvancedRouteTiming()
         */
        self.advancedRouteTiming = false;
        /**
         * [sequenced description]
         * @see toggleSequencedRoute()
         */
        self.sequenced = false;
        /**
         * [ordered description]
         * @see toggleOrderedRoute()
         */
        self.ordered = false;

        /**
         * Once we get to step 2 we now have (we should have) our waypoints defined.
         * Once there we define a SINGLE ROUTE, which gets pushed into this array
         * @type {Array}
         */
        self.routes = [

        ];

        /**
         * We're simply using this object to contain the selectedRoute in order
         * to display routes ng-show on this.
         * @type {Object}
         */
        self.selectedRoute = {};

        self.routeCollection = {};

        /**
         * The second map this component has to render. Once we get to step 2 we wish
         * to have another map that'll contain our waypoints so that we can click on a waypoint
         * to quickly add it to our route
         */
        //  L.mapbox.accessToken = 'pk.eyJ1Ijoicm9tcGVsc3RvbXBlbCIsImEiOiJjamNoaHJmOWkxaDFqMnlydnV1d202MTA2In0.vaZnBgwNagSx6wAljOUEMQ';
        //  self.routeMap = L.mapbox
        //      .map(`routeMap`,'mapbox.streets', {
        //          zoomControl:false,
        //          drawControl: false,
        //          editable: true
        //      });
         //
        //  self.routeMap = L.map('routeMap', {
        //          zoomControl: false,
        //          drawControl:false,
        //          editable: true,
        //      });
         //
        //  L.tileLayer('https://api.mapbox.com/v4/{map_id}/{z}/{x}/{y}.png?access_token={accessToken}',{
        //      attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>',
        //      map_id:'mapbox.streets',
        //      accessToken:'pk.eyJ1Ijoicm9tcGVsc3RvbXBlbCIsImEiOiJjamNoaHJmOWkxaDFqMnlydnV1d202MTA2In0.vaZnBgwNagSx6wAljOUEMQ'
        //  }).addTo(self.routeMap);
         self.routeMap = self.genericMapService.generateMap('routeMap',{
                 zoomControl: false,
                 satellite : {position:'bottomright'},
                 drawControl:false,
                 editable: true,
         });
        // self.routeMap = L.map('routeMap', {
        //     zoomControl: false,
        //     drawControl: false,
        //     editable: true
        // });

        /**
         * Just for the sake of not adding directly to the map and being able to call
         * routeMapLayer.eachLayer().
         */
        self.routeMapLayer = L.layerGroup().addTo(self.routeMap);
        self.routeMap.setView([-26.6145, 27.0950], 5);

        // self.$timeout(() => {
        //     /**
        //      * NOTE that in order to get a second map running, we should make sure to create
        //      * a new layer.
        //      */
        //     let newLayer = Tangram.leafletLayer({
        //             scene: 'cinnabar-style-more-labels.zip',
        //             attribution: '<a href="https://mapzen.com/tangram" target="_blank">Tangram</a> | &copy; OSM contributors | <a href="https://mapzen.com/" target="_blank">Mapzen</a>'
        //         }
        //     );
        //     newLayer.addTo(self.routeMap);
        // });


        /**
         * This object is the ng-model for the ui-select when a user
         * chooses to add another point to his route's path.
         * @see addPointToRoute()
         * @type {Object}
         */
        self.chosenPoint = {};

        /**
         * When a user gets to step 2, they have to define a starting point
         * Using this on an HTML ng-if
         * @type {Boolean}
         */
        self.startingPointDefined = false;

        /**
         * At step 2 we show this in the summary-box.
         * @see setUniqueVisits()
         * @type {Object}
         */
        self.uniqueVisitedWaypoints = {};

        /**
         * To keep track of all routes' total distances
         * @see calculateRouteDistance(route)
         * @type {Object}
         */
        self.routeDistances = {};

        /**
         * To keep track of all routes' estimated durations.
         * @see calculateRouteDuration(route)
         * @type {Object}
         */
        self.routeLingerDuration = {};

        /**
         * To keep track of all routes' estimated maxiumum durations.
         * @see calculateRouteDuration(route)
         * @see advancedRouteTiming
         * @type {Object}
         */
        self.routeMaxTravelDuration = {};

        /**
         * To keep track of all routes' estimated minimum durations.
         * @see calculateRouteDuration(route)
         * @see advancedRouteTiming
         * @type {Object}
         */
        self.routeMinTravelDuration = {};

        /**
         * To keep track of all routes' estimated durations.
         * @see calculateRouteDuration(route)
         * @type {Object}
         */
        self.routeTravelDuration = {};

        /**
         * To keep track of all routes' estimated maxiumum durations.
         * @see calculateRouteDuration(route)
         * @see advancedRouteTiming
         * @type {Object}
         */
        self.routeMaxDurations = {};

        /**
         * To keep track of all routes' estimated minimum durations.
         * @see calculateRouteDuration(route)
         * @see advancedRouteTiming
         * @type {Object}
         */
        self.routeMinDurations = {};

        /**
         * To keep track of all routes' estimated durations.
         * @see calculateRouteDuration(route)
         * @type {Object}
         */
        self.routeDurations = {};

        /**
         * We keep track of the last POINT that we choose for our PATH when
         * we are busy defining a ROUTE at step 2.
         * @see setLastChosenPoint($item)
         * @type {Object}
         */
        self.lastChosenPoint = {};

        /************************************************
         * * * * * *PERTAINING TO ROUTES(end)* * * * *
         ***********************************************/
         self.map = self.genericMapService.generateMap('routeDefinitionMap',{
             satellite : {position:'bottomright'},
             geocoder:{position:'topleft'},
             zoomControl: false,
             drawControl:false,
             editable: true,
         });

        self.flagMarker = self.genericMapService.getSpecifiedMarkerSync("flag");
        self.mapMarker = self.genericMapService.getSpecifiedMarkerSync("site");
        // L.control.geocoder('mapzen-E4UsooF').addTo(self.map);

        new L.Control.Zoom({position: 'bottomright'}).addTo(self.map);
        self.map.setView([-26.6145, 27.0950], 5);

        self.siteService.getSites()
            .then((sites) => {
                self.sites = sites;
                self.drawAllSites(sites);
            })
            .catch((error) => {
                console.error("NewRouteComponent could not retrieve a list of sites because:");
                console.error(error);
            });

        // self.map.on('editable:created', ($event) => {
        // });
        //

        // self.map.on('editable:drawing:start', ($event) => {
        //
        // });


        /**
         * All sites are added to this Leaflet-layer.
         * This allows us to easily show/hide/do manipulations
         * on each layer
         */
        self.allSitesLayer = L.layerGroup().addTo(self.map);

        /**
         * The marker placed at the center of each sites' bounds
         * are added to this layer
         */
        self.allSitesMarkerLayer = L.layerGroup().addTo(self.map);

        /**
         * All distance lines between waypoints get added here
         */
        self.waypointDistanceLineLayer = L.layerGroup().addTo(self.map);

        /**
         * NOTE Because leaflet-editable placed the marker directly onto
         * self.map, I am keeping it there for now
         */
        self.waypointMarkerLayer = L.layerGroup();
        self.waypointCircleLayer = L.layerGroup().addTo(self.map);

        self.genericMapService.showLayerWithZoom(self.map, self.allSitesLayer, 14.5);
        self.genericMapService.hideLayerWithZoom(self.map, self.allSitesMarkerLayer, 14.5);
        // self.genericMapService.

        /**
         * Once we have clicked to place a marker on the map we
         * want to 'colour' create radiusCircle, labels, etc.
         * @type {[type]}
         */
        self.map.on('editable:drawing:commit', ($event) => {
            let layer = $event.layer;
            let waypoint = self.waypoints[layer.waypointNumber-1];
            layer.isPlanted = true;
            if(layer.editEnabled()) {
                layer.disableEdit();
            }
            self.handleNewWaypoint(layer);
            self.addWaypointLabel(layer);
            self.addRadiusCircle(layer, waypoint.radius);

            self.addWaypointDivIcon(layer);

            self.drawWaypointDistance(layer);

            if(self.drawingMarkers) {
                self.lastDrawnWaypoint = waypoint;
                self.drawMarker();
            } else {
            }

            waypoint.loc = layer._latlng;

        });

        self.map.on('editable:drag', ($event) => {
            let layer = $event.layer;
            let waypoint = self.waypoints[layer.waypointNumber-1];
            if(layer.isWaypoint) {
                self.circleFollowWaypoint(layer);
                self.distanceLineFollowWaypoint(layer);
                self.updateWaypointLoc(layer);
                self.divIconFollowWaypoint(layer);
            }

        });

        self.map.on('editable:dragstart', ($event) => {
            let layer = $event.layer;


        });

        self.map.on('editable:dragend', ($event) => {
            let layer = $event.layer;

            if(layer.drawnOnRouteMap) {
                /**
                 * this layer has been drawn on our routeMap and we are now dragging
                 * it to another location. We must therefore somehow notify the routeMap
                 * that the position has changed and that it should
                 *   1) Remove the previous layer
                 *   2) Redraw the newer one.
                 *   NOTE that this will mean updating circle, distanceLine, etc
                 */

                layer.drawnOnRouteMap = false;
                layer.changed = true;
            }

            self.updateAllDistances();
        });

        self.$timeout(() => {
            /**
             * This window.resize is involved with the placement of buttons on our map.
             * I am using this because I stuggle to otherwise place the buttons on the map
             * and have it STILL DISPLAYING CORRECTLY if the window gets resized.
             * I think this is because I messed around with position:absolute; and might have
             * achieved it easier with margins
             */
            $(window).on("resize.newRoute", function() {
                let map = L.DomUtil.get('routeDefinitionMap');
                if(map) {
                    let mapTop = map.offsetTop;
                    let mapLeft = map.offsetLeft;
                    let mapRight = map.offsetLeft + map.clientWidth;

                    //the button we press to search for sites
                    let findSites = L.DomUtil.get('routeFindSitesButton');
                    //the button that contains some advanced settings
                    let advancedSettings = L.DomUtil.get('routeAdvancedSettings');
                    //The right-bar is a DIV that contains our start-drawing and stop-drawing buttons (and perhaps more)
                    let rightBar = L.DomUtil.get('routeRightBar');

                    findSites.style.display = 'block';
                    findSites.style.left = `${mapLeft + 10}px`;

                    advancedSettings.style.display = 'block';
                    advancedSettings.style.left = `${mapLeft + 10}px`;

                    rightBar.style.display = 'block';
                    rightBar.style.left = `${mapRight - 40}px`;
                    rightBar.style.top = `${mapTop}px`;
                }
                // self.map.invalidateSize();
            })
.trigger("resize");
        });


        /**
         * The segment of code below handles the display of our movingTooltip.
         * NOTE that we have a div with this ID in the HTML.
         * We define a function for
         * -adding
         * -removing
         * -moving
         * -updating
         */
        let tooltip = L.DomUtil.get('movingTooltip');
        function addTooltip(e) {
            if(!tooltip.isAdded) {
                tooltip.isAdded = true;
                L.DomEvent.on(document, 'mousemove', moveTooltip);
                tooltip.innerHTML = 'Click on the map to place a waypoint.';
                tooltip.style.display = 'block';
            }
        }
        function removeTooltip(e) {
            tooltip.isAdded = false;
            tooltip.innerHTML = '';
            tooltip.style.display = 'none';
            L.DomEvent.off(document, 'mousemove', moveTooltip);
        }
        function moveTooltip(e) {
            let mapLeft = $("#routeDefinitionMap").offset().left;
            let mapTop = $("#newRouteForm").offset().top;
            tooltip.style.left = `${e.clientX - mapLeft + 85}px`;
            tooltip.style.top = `${e.clientY - mapTop - 25}px`;
        }
        function updateTooltip(e) {
            tooltip.innerHTML = 'Place another waypoint or press to top-right button to leave this mode';
        }
        self.map.on('editable:drawing:start', ($event) => {
            addTooltip($event);
        });
        self.map.on('editable:drawing:end', ($event) => {
            if(!self.drawingMarkers) {
                removeTooltip($event);
            } else {
                updateTooltip($event);
            }
        });
        // self.map.on('editable:drawing:start', addTooltip);
        // self.map.on('editable:drawing:end', removeTooltip);
        // self.map.on('editable:drawing:click', updateTooltip);


        self.checkIfEditing();

		self.availableGroups = [];
		self.refreshGroups();

		//$onInit() end
    }

    $onDestroy() {
        let self = this;
        $(window).off('resize.mapView');
        self.map.remove();
        self.routeMap.remove();
    }

    /**
     * As the name implies we ascertain whether this route is being made or being edited.
     * In order to have all the logic in one place I have decided to experiment with a function
     * handling all the necessary logic for me after a base structure has already been deployed
     */
    checkIfEditing() {
        let self = this;
        if(self.resolve.edit) {
            self.isEdit = true;
            self.editPreparationComplete = false;

            //waypoints of an edit
            _.forEach(self.resolve.settings.waypoints, (waypoint) => {
                self.waypoints.push({_id: waypoint._id, name: waypoint.name, loc: waypoint.loc, notes: waypoint.notes, radius: waypoint.radius, type: waypoint.type, tagId : waypoint.tagId});
                // self.drawWaypointFromLoc(waypoint.loc);
            });

            let index = 0;
            _.forEach(self.resolve.settings.routeCollection.routes, (route) => {
                self.routes.push({_id: route._id, name: route.name, description: route.description, path: [],ordered: route.ordered,sequenced: route.sequenced});

                _.forEach(route.path, (point) => {
                    let routeWaypoint = _.find(self.waypoints, (storedWaypoint) => {
                        return storedWaypoint._id === point.waypoint;
                    });

                    // self.addPointToRoute(routeWaypoint, point._id);
                    self.routes[index].path.push({_id: point._id, waypoint: routeWaypoint, distance: point.distance, formattedDistance: point.formattedDistance, lingerTime: point.lingerTime/60 || 0, travelTime : point.travelTime/60 || 0, maxTravelTime:point.maxTravelTime/60 || 0});
                });

                index ++;
                //TODO push the paths and then calculateRouteVariables
            });

            _.forEach(self.routes, (route) => {
                self.calculateRouteVariables(route);
            });

            if(self.resolve.settings.routeCollection.site) {
                self.boundedSite = self.resolve.settings.routeCollection.site;
            }
            self.linkWaypointsToChosenSite = self.resolve.settings.linkedToSite;
            self.routeCollection = self.resolve.settings.routeCollection;

            let selectedRoute = _.find(self.routes, (storedRoute) => {
                return storedRoute._id === self.resolve.settings.routeToEdit._id;
            });
            self.selectedRoute = selectedRoute;
            self.readyMap();
            // self.calculateRouteVariables(self.selectedRoute);
        } else {
            self.readyMap();
        }
    }

    /**
     * We call readyMap from:
     *   i) The navigation-circle at the top of the wizard (ng-click)
     *  ii) Step 2 previous button that takes us to step 1 (ng-click)
     * iii) When we are editing a route and want to handle the initial drawings
     *      @see checkIfEditing()
     */
    readyMap() {
        let self = this;
        self.$timeout(() => {
            self.map.invalidateSize();
        });

        let promises = [];

        if(self.isEdit && !self.editPreparationComplete) {
            _.forEach(self.waypoints, (waypoint) => {
                //we draw and place the marker
                let marker = self.drawWaypoint(waypoint);
                //we add its click-event
                marker.on('click', ($event) => {
                    self.handleWaypointClicked(marker);
                });

                //we update its layer
                waypoint.layer = marker;

                self.addWaypointLabel(waypoint.layer);
                self.addRadiusCircle(waypoint.layer, marker.radius);
                self.addWaypointDivIcon(waypoint.layer);
                self.drawWaypointDistance(waypoint.layer);
            });
            self.$timeout(function() {
                self.fitMapToWaypoints();
                if(self.waypoints.length >= 1) {
                    self.selectWaypointFromIndex(0);
                }
            }, 0);
            self.editPreparationComplete = true;
        }
    }

    /**
     * We currently only use this function when EDITING an existing route.
     * We need to immediately draw all the waypoints on the map.
     * NOTE that we need to make use of promises here, otherwise the marker
     * gets drawn before we've received our flagMarker from genericMapService
     */
    drawWaypoint(waypoint) {
        let self = this;

        let index = _.findIndex(self.waypoints, (storedWaypoint) => {
            return storedWaypoint._id === waypoint._id;
        });
        let id = waypoint._id;
        let waypointNumber = index + 1;

        if(self.flagMarker) {

            let marker = L.marker(waypoint.loc, {icon: self.flagMarker, draggable: false}).addTo(self.map);
            marker.isWaypoint = true;
            marker.isPlanted = true;
            marker.waypointNumber = waypointNumber;
            marker.name = waypoint.name;
            marker.radius = waypoint.radius;
            marker.type = waypoint.type;
            return marker;
        }else {
            // console.error('Flag marker not found');
        }
    }

    /**
     * We might be trying to plant waypoints (with a flagMarker icon) before we have received the
     * flag-marker from our genericMapService.
     * TODO: I was stupid here. I should just get the flagMarker synchronously!!
     * @return {Boolean} [description]
     */
    hasFlagMarker(waypoint) {
        let self = this;
        return new Promise((resolve, reject) => {
            if(self.flagMarker !== undefined) {
                resolve(self.flagMarker);
            } else {
                let promise = self.$interval(() => {
                    if(self.flagMarker !== undefined) {
                        resolve(self.flagMarker);
                        self.$interval.cancel(promise);
                    }
                }, 500, 10);

                promise.then((result) => {
                    reject("No flag-marker is found in NewRouteComponent");
                });
            }
        });
    }


    /**
     * This function is going to 'grab' whatever waypoints are available and redraw them on
     * our second map.
     */
    readyRouteMap() {
        let self = this;
        self.$timeout(() => {
            self.routeMap.invalidateSize();

            if(self.waypoints.length > 0) {
                //There are waypoints defined, so we quickly draw them on our map
                let icon = self.flagMarker;
                let bounds = [];
                _.forEach(self.waypoints, (waypoint) => {
                    // NOTE so that we don't draw the waypoints twice
                    if(!waypoint.layer.drawnOnRouteMap) {


                        /**
                         * When a waypoint gets changed in STEP 1 and it has been planted on the routeMap before,
                         * we will mark it as NOT being drawn on the routeMap.
                         * The code below will then recognize that it is actually marked as changed and
                         * will handle implementing these changes
                         */
                        if(waypoint.layer.changed) {
                            self.routeMapLayer.removeLayer(waypoint.routeMapLayer.marker);
                            self.routeMapLayer.removeLayer(waypoint.routeMapLayer.circle);
                            self.routeMapLayer.removeLayer(waypoint.routeMapLayer.divIcon);

                            if(waypoint.layer.waypointNumber !== 1) {
                                //we are drawing distanceLines only from the 2nd waypoint, and we give THAT waypoint the distanceLineLayer
                                self.routeMapLayer.removeLayer(waypoint.routeMapLayer.distanceLine);
                            } else {
                                let next = self.waypoints[waypoint.layer.waypointNumber];
                                let hasNext = next !== undefined;

                                if(hasNext) {
                                    //If the first waypoint was moved, we actually need to redraw
                                    //the line connecting it to its next waypoint.
                                    //But that line is located on its successor's object. Therefore,
                                    //we mark it to be redrawn
                                    next.layer.drawnOnRouteMap = false;
                                    next.layer.changed = true;
                                }
                            }

                        }

                        waypoint.routeMapLayer = {};

                        // This is simply the marker
                        let marker = self.genericMapService.placeMarkerAtCoordinates(self.routeMapLayer, self.flagMarker, waypoint.loc);
                        marker.waypoint = waypoint;
                        waypoint.routeMapLayer.marker = marker;

                        // This is the tooltip for the marker
                        marker.bindTooltip(`${waypoint.layer.waypointNumber}`, {permanent: true, direction: 'center', offset:[10,0]});
                        let tooltip = marker._tooltip._container;
                        L.DomUtil.addClass(tooltip, "waypoint-small-label");

                        // We use the divIcon to make the blue circles around the markers TODO: Get ngClick working
                        let divIcon = L.divIcon({className:"waypoint-div-icon", html: `<div id="waypoint${waypoint._id}Clicker" class="waypoint-route-clickable-shape"></div>`});
                        let divIconLayer = self.genericMapService.placeMarkerAtCoordinates(self.routeMapLayer, divIcon, waypoint.loc);
                        // After having placed the divIcon, I grab that very object again in order to add the 'click'-event
                        let icon = angular.element(document.getElementById(`waypoint${waypoint._id}Clicker`));
                        waypoint.routeMapLayer.divIcon = divIconLayer;

                        icon.on('click', ($event) => {
                            self.addPointToRoute(waypoint);
                        });

                        //stuff for circles
                        let circle = self.genericMapService.drawCircleFromLoc(self.routeMapLayer, waypoint.loc, {color: self.plantedWaypointCircleColor, radius: waypoint.radius});
                        circle.isCircle = true;
                        L.DomUtil.addClass(circle._path, "waypoint-circle");
                        waypoint.routeMapLayer.circle = circle;

                        //stuff for distanceLine
                        let waypointNum = waypoint.layer.waypointNumber;
                        if(waypointNum !== 1) {
                            let prevWaypoint = self.waypoints[waypointNum - 2].layer.getLatLng();
                            let thisWaypoint = waypoint.layer.getLatLng();
                            let distanceLine = L.polyline([prevWaypoint, thisWaypoint], {className:"distance-line"}).addTo(self.routeMapLayer);
                            let distance = self.routeMap.distance(prevWaypoint, thisWaypoint);
                            distanceLine.bindTooltip(`${distance.toFixed(2)} meters`, {permanent: true, direction: 'center'});
                            self.addTooltipClass(distanceLine, "distance-line-tooltip");
                            waypoint.routeMapLayer.distanceLine = distanceLine;
                        }
                        waypoint.layer.drawnOnRouteMap = true;
                    }
                    bounds.push(waypoint.loc);
                });
                self.genericMapService.fitMapToLoc(self.routeMap, bounds);
            }
            if(self.routes.length === 0 ) {
                //This is the first time we visit this step
                self.newRouteInArray();
            }
        });
    }

    /**
     * We're calling siteService's getSites(). Once we have the sites, we pass them
     * along to drawAllSites(sites)
     */
    drawAllSites(sites) {
        let self = this;
        _.forEach(sites, (site) => {
            let siteLayer = self.genericMapService.drawPolygonFromLoc(self.allSitesLayer, site.locs[0].loc, {color: self.defaultDrawingColor});
            siteLayer.isPolygon = true;
            let siteTooltip = self.genericMapService.addTooltipToLayer(siteLayer, site.name);
            siteTooltip.isTooltip = true;
            site.siteLayer = siteLayer;
            site.siteTooltip = siteTooltip;

            let centerCoorinates = siteLayer.getBounds().getCenter();
            let siteMarker = self.genericMapService.placeMarkerAtCoordinates(self.allSitesMarkerLayer, self.mapMarker, centerCoorinates);
        });
    }

    /**
     * If we want to display the entire layer, that is, all the details of the map.
     * It could be possible that some advanced user wants to do this after having drawn his routes and is forming
     * some complicated shapes. I do, however, doubt it.
     */
    toggleShowMapLayer() {
        let self = this;
        let shouldShow = self.showMapLayer;

        if(shouldShow) {
            self.mapLayer.addTo(self.map);
        } else {
            self.mapLayer.remove();
        }
    }

    /**
     * Whether we want to display sites on the map
     */
    toggleShowSites() {
        let self = this;
        let shouldShow = self.showSites;

        if(shouldShow) {
            self.allSitesLayer.addTo(self.map);
        } else {
            self.allSitesLayer.remove();
        }
    }

    /**
     * Whether to display a tooltip over a site with its name
     */
    toggleShowSitenames() {
        let self = this;
        let shouldShow = self.showSitenames;

        if(shouldShow) {
            self.allSitesLayer.eachLayer((layer) => {
                self.genericMapService.setLayerTooltipOptions(layer, "permanent");
            });
        } else {
            self.allSitesLayer.eachLayer((layer) => {
                self.genericMapService.setLayerTooltipOptions(layer, "disable");
            });
        }
    }

    /**
     * Whether we want to display distances between waypoints
     */
    toggleShowDistanceLines() {
        let self = this;
        let shouldShow = self.showDistanceLines;

        if(shouldShow) {
            self.waypointDistanceLineLayer.eachLayer((layer) => {
                L.DomUtil.removeClass(layer._path, "distance-line-hide");
            });
        } else {
            self.waypointDistanceLineLayer.eachLayer((layer) => {
                L.DomUtil.addClass(layer._path, "distance-line-hide");
            });
        }
    }

    /**
     * Whether we want to show the tooltip to the distance lines
     * @return {[type]} [description]
     */
    toggleShowDistances() {
        let self = this;
        let shouldShow = self.showDistances;

        if(shouldShow) {
            self.waypointDistanceLineLayer.eachLayer((layer) => {
                L.DomUtil.removeClass(layer._tooltip._container, "distance-line-tooltip-hide");
            });
        } else {
            self.waypointDistanceLineLayer.eachLayer((layer) => {
                L.DomUtil.addClass(layer._tooltip._container, "distance-line-tooltip-hide");
            });
        }
    }

    /**
     * This function gets called when our ui-select has opened or closed.
     * I have a strange issue with the ui-select not growing bigger to display its
     * content, so I have an ng-class on the ui-select-container to grow when uisIsOpen
     * @param  {Boolean} isOpen [description]
     * @return {[type]}         [description]
     */
    onOpenClose(isOpen) {
        let self = this;
        self.uisIsOpen = isOpen;
    }

    /**
     * Used to validate the iButton id and possibly other
     * The id field needs to be converted to a buffer before saving
     * @param {Object} id {type:String,id:String}
     * @return {Boolean} Return true by default,  if id not valid return false
     */
    validateId(waypoint) {
        let self = this;
        let thisValid = true;
        let id = {type:waypoint.type,id:waypoint.tagId};
        if(typeof id.id === 'string') {
            switch (id.type) {
                case 'iButton':
                let matchArray = id.id.match(/^(0x|0X)?[a-fA-F0-9]{16}$/);
                if(!id.id == "") {
                    if(!matchArray || matchArray.length == 0) {
                        thisValid = false;
                    }
                }
                case 'Bluetooth':
                if(!id.id || id.id == "") {
					thisValid = false;
                }
                break;
            }
        }
        return thisValid;
    }

    /**
     * Used to change radius to 0 if type is not GPS
     * @param {Object} waypoint
     */
    typeOfWaypointChanged(waypoint) {
        let self = this;
        if(waypoint.type !== 'GPS') {
            waypoint.radius = 0;
            self.circleRadiusChange(waypoint);
        }else{
            waypoint.radius = 10;
            self.circleRadiusChange(waypoint);
        }
    }

    /**
     * When a user goes to advanced settings and sets that he wants to link to a site
     * @return {[type]} [description]
     */
    toggleLinkWaypointsToSite() {
        //TODO Just make sure you can't select nothing
        let self = this;
        let shouldLink = self.linkWaypointsToChosenSite;

        if(shouldLink) {
            // TODO: Check the escapeKey. If we want that on, we should catch a few stuff
            // and just change it back
            self.$ngConfirm(
                {
                    title: `Linking of Waypoints`,
                    theme: 'light',
                    animation: 'top',
                    scope: self.$scope,
                    closeAnimation: 'bottom',
                    content: `
                    <div ng-class="{'ui-select-site-open' : $ctrl.uisIsOpen }">
                        Please select a site you wish to link to: {{$ctrl.showSites}} <br />
						<ui-select uis-open-close="$ctrl.onOpenClose(isOpen)" on-select="$ctrl.visitSelectedSite($item)"
								   ng-model="$ctrl.boundedSite" theme="select2" style="min-width:300px;" title="Choose a site">
							<ui-select-match placeholder="Select a site in the list or search by sitename">{{$select.selected.name + $select.selected.description}}</ui-select-match>
							<ui-select-choices repeat="site in $ctrl.sites | filter: $select.search">
								<div ng-bind-html="site.name | highlight: $select.search"></div>
								<small>
									<span ng-bind-html="' '+ site.description | highlight: $select.search"></span>
								</small>
							</ui-select-choices>
						</ui-select>
                    </div>
                    `,
                    escapeKey: true,
                    backgroundDismiss: true,
                    buttons: {
                        // long hand button definition
                        ok: {
                            text: "Link",
                            btnClass: 'btn-primary',
                            keys: ['enter'], // will trigger when enter is pressed
                            action(scope) {

                            }
                        },
                        close: {
                            text: "Cancel",
                            action(scope) {
                                self.linkWaypointsToChosenSite = false;

                            }
                        }
                    },
                }
            );
        } else {
            self.boundedSite = null;
        }
    }
    /**
     * This function gets executed on-select of a site.
     * @see toggleLinkWaypointsToSite()
     * @see HTML in new-route.html
     *
     */
    visitSelectedSite(site) {
        let self = this;
        self.genericMapService.fitMapToLoc(self.map, site.locs[0].loc);

        if(self.showAdvancedFind) {
            self.onShowAdvancedFind();
        }
    }

    clearSelectedSite() {
        let self = this;
        if(self.boundedSite.siteLayer) {
            self.boundedSite.siteLayer.remove();
            delete self.boundedSite.siteLayer;
        }
    }

    /**
     * The function that we execute when we want to
     * add another waypoint
     * NOTE: Consider whether this function wouldn't have
     * to be named drawWaypoint()
     */
    drawMarker() {
        let self = this;
        if(self.drawingMarkers) {
            self.$timeout(() => {
                self.newWaypointInArray();
                let length = self.waypoints.length;
                let marker = self.map.editTools.startMarker(null, {icon:self.flagMarker, draggable: false});
                // NOTE: Only after the marker has been placed we add the tooltip, circle, etc to it.
                // See the leaflet-editable events
                marker.isWaypoint = true;
                marker.waypointNumber = length;

                self.waypoints[length - 1].layer = marker;
                self.waypoints[length - 1].name = `Waypoint ${marker.waypointNumber}`;

                marker.on('click', ($event) => {
                    self.handleWaypointClicked(marker);
                });
            });
        }
    }

    /**
     * The function that receives a LAYER (self.waypoints[i].layer)
     * and draws the correct distanceline, adding the tooltip and
     * calculating distance
     */
    drawWaypointDistance(waypoint) {
        let self = this;
        let waypointNum = waypoint.waypointNumber;
        if(waypointNum !== 1) {
            let prevWaypoint = self.waypoints[waypointNum - 2].layer.getLatLng();
            let thisWaypoint = waypoint.getLatLng();
            let distanceLine = L.polyline([prevWaypoint, thisWaypoint], {className:"distance-line"}).addTo(self.waypointDistanceLineLayer);

            self.waypoints[waypoint.waypointNumber-1].distanceLineLayer = distanceLine;

            let distance = self.map.distance(prevWaypoint, thisWaypoint);
            let formattedDistance = self.numbersService.handleDistanceConversion(distance);
            distanceLine.bindTooltip(`${formattedDistance}`, {permanent: true, direction: 'center'});
            self.addTooltipClass(distanceLine, "distance-line-tooltip");

        }
    }

    /**
     * We add a label and adjust its class so that it is smaller and displayed a bid better.
     * NOTE taht we actually receive a layer (self.waypoints[i].layer)
     */
    addWaypointLabel(waypoint) {
        let self = this;
        waypoint.bindTooltip(`${waypoint.waypointNumber}`, {permanent: true, direction: 'center', offset:[10,0]});
        let tooltip = waypoint._tooltip._container;

        L.DomUtil.addClass(tooltip, "waypoint-small-label");
    }

    /**
     * NOTE: I have started using genericMapService to contain these types of functions
     */
    addTooltipClass(layer, className) {
        let self = this;
        L.DomUtil.addClass(layer._tooltip._container, className);
    }

    /**
     * This function occurs on a ng-change when the user
     * will edit the coordinates of a planted waypoint.
     * We also have to make sure the circle follows the waypoint.
     */
    waypointLatlngChange(waypoint) {
        let self = this;
        waypoint.layer.setLatLng(waypoint.loc);
        self.circleFollowWaypoint(waypoint.layer);
        self.updateAllDistances();
    }

    /**
     * This function gets exected on editable:drawing:commit
     * We have planted our marker and immediately want to planted
     * a circle at its coordinates.
     */
    addRadiusCircle(waypoint, radius) {
        let self = this;
        if(radius == undefined) {
            radius = 10;
        }
        let latlng = waypoint._latlng;
        let circle = self.genericMapService.drawCircleFromLoc(self.waypointCircleLayer, latlng, {color: self.plantedWaypointCircleColor, radius});
        circle.isCircle = true;

        L.DomUtil.addClass(circle._path, "waypoint-circle");

        self.waypoints[waypoint.waypointNumber-1].circleLayer = circle;
        self.waypoints[waypoint.waypointNumber-1].radius = radius;
        // self.waypoints[waypoint.waypointNumber-1].type = waypoint.type;
    }

    /**
     * We have an editable:drag event. When we drag a waypoint, we are calling
     * this function to move the circleLayer along with it
     * NOTE: Is this the behaviour that we desire? It is also possible to
     * simply fade the circle away on drag, and then redraw it on drag:end
     */
    circleFollowWaypoint(waypoint) {
        let self = this;
        let latlng = waypoint._latlng;
        let circle = self.waypoints[waypoint.waypointNumber-1].circleLayer;
        circle.setLatLng(latlng);
    }

    divIconFollowWaypoint(waypointLayer) {
        let self = this;
        self.waypoints[waypointLayer.waypointNumber-1].divIconLayer.setLatLng(waypointLayer._latlng);
    }

    circleRadiusChange(waypoint) {
        let self = this;

        if(waypoint.circleLayer) {
            waypoint.circleLayer.setRadius(waypoint.radius||10);
        }
    }

    /**
     * We are adding this divIcon to a waypoint that has just been placed.
     * Why? So that we can indicate when the waypoint is active by using the
     * ciontent of this divIcon
     */
    addWaypointDivIcon(waypointLayer) {
        let self = this;
        let latlng = waypointLayer._latlng;
        let divIcon = L.divIcon({className:"indicate-active-waypoint",
            html: `<div id="waypoint${waypointLayer.waypointNumber}Active"></div>`});
        let divIconLayer = self.genericMapService.placeMarkerAtCoordinates(self.map, divIcon, latlng);
        self.waypoints[waypointLayer.waypointNumber-1].divIconLayer = divIconLayer;
    }

    /**
     * We have an editable:drag event. When we drag a waypoint, we wish to update
     * the defind distance-line to reflect the new distances
     */
    distanceLineFollowWaypoint(waypointLayer) {
        let self = this;
        let waypointNumber = waypointLayer.waypointNumber;
        let first; let last; let between; let hasNext; let hasPrev; let next; let prev;
        if(waypointNumber === 1) {
            first = true;
        } else if(waypointNumber === self.waypoints.length) {
            last = true;
        } else {
            between = true;
        }

        next = self.waypoints[waypointNumber];
        hasNext = next !== undefined;

        prev = self.waypoints[waypointNumber-2];
        hasPrev = prev !== undefined;


        if(first && hasNext) {
            self.genericMapService.removeLayerFromMap(self.waypointDistanceLineLayer, next.distanceLineLayer);
            self.drawWaypointDistance(next.layer);
        } else if(last && hasPrev) {
            //removing and updating this layer
            self.genericMapService.removeLayerFromMap(self.waypointDistanceLineLayer, self.waypoints[waypointNumber-1].distanceLineLayer);
            self.drawWaypointDistance(waypointLayer);
        } else {
            //if only one marker is placed and dragged we'll also arrive here.
            //Therefore check between
            if(between) {
                if(hasPrev) {
                    //removing and updating this layer
                    self.genericMapService.removeLayerFromMap(self.waypointDistanceLineLayer, self.waypoints[waypointNumber-1].distanceLineLayer);
                    self.drawWaypointDistance(waypointLayer);
                }

                if(hasNext) {
                    self.genericMapService.removeLayerFromMap(self.waypointDistanceLineLayer, next.distanceLineLayer);
                    self.drawWaypointDistance(next.layer);
                }

            }
        }
    }

    /**
     * On dragging our waypoints we need to keep
     * the coordinates stored in self.waypoints[i].loc updated
     */
    updateWaypointLoc(waypointLayer) {
        let self = this;
        let waypoint = self.waypoints[waypointLayer.waypointNumber-1];
        waypoint.loc = waypointLayer._latlng;
    }

    startDrawingMarkers() {
        let self = this;
        if(self.boundedSite && self.boundedSite._id && !self.linkWaypointsToChosenSite) {
            self.$ngConfirm(
                {
                    title: `Link to ${self.boundedSite.name}`,
                    theme: 'light',
                    animation: 'top',
                    scope: self.$scope,
                    closeAnimation: 'bottom',
                    content: `You have selected <b>${self.boundedSite.name}</b>.
                              Do you wish to have these waypoints linked to it?`,
                    escapeKey: true,
                    backgroundDismiss: true,
                    buttons: {
                        // long hand button definition
                        ok: {
                            text: "Yes",
                            btnClass: 'btn-primary',
                            keys: ['enter'], // will trigger when enter is pressed
                            action(scope) {
                                self.linkWaypointsToChosenSite = true;
                                self.drawingMarkers = true;
                                self.indexToSelect = self.waypoints.length === 0 ? 0 : self.waypoints.length-1;
                                self.drawMarker();
                            }
                        },
                        close: {
                            text: "No",
                            action(scope) {
                                self.linkWaypointsToChosenSite = false;
                                self.drawingMarkers = true;
                                self.indexToSelect = self.waypoints.length === 0 ? 0 : self.waypoints.length-1;
                                self.drawMarker();
                            }

                        }
                    },
                }
            );
        } else {
            self.drawingMarkers = true;
            self.indexToSelect = self.waypoints.length === 0 ? 0 : self.waypoints.length-1;
            self.drawMarker();
        }
    }

    /**
     * We click on the stop button, indicating that we are done with placing our waypoints on the map
     * @return {[type]} [description]
     */
    stopDrawingMarkers() {
        let self = this;
        self.drawingMarkers = false;

        //We clear the object
        self.lastDrawnWaypoint = {};

        self.map.editTools.stopDrawing();
        let firstDroppedIdx = self.indexToSelect;
        if(self.waypoints && self.waypoints[firstDroppedIdx] && self.waypoints[firstDroppedIdx].layer.isPlanted) {
            //We remove the additional slot that gets created
            self.deleteWaypointInArray();
            //We make the first planted waypoint the active one
            self.selectWaypointFromIndex(self.indexToSelect);
            //We fit the map to all waypoints
            self.fitMapToWaypoints();
        } else {
            //We started to draw, did NOT plant anything, and then stopped
            //We remove the slot we created
            self.deleteWaypointInArray();
        }
        self.indexToSelect = null;
    }

    selectWaypointFromIndex(index) {
        let self = this;
        // self.selectedWaypoint = self.waypoints[index].layer;
        self.waypoints[index].layer.fire('click');
    }

    fitMapToWaypoints() {
        let self = this;
        let loc = [];
        _.forEach(self.waypoints, (waypoint) => {
            loc.push(waypoint.layer._latlng);
        });
        self.genericMapService.fitMapToLoc(self.map, loc, {padding: [40, 40]});
    }

    /**
     * I think I've created this in the beginning, but never found any use for it. Don't think
     * I'm currently adding this layer when editing a route
     */
    handleNewWaypoint(layer) {
        let self = this;
        self.waypointLayers[layer._leaflet_id] = layer;
    }

    /**
     * Whenever we physically click on a waypoint on the map
     * NOTE: We sometimes fire a 'click' event on wapoints as if though
     * the user has clicked on them. That also calls this function to ensure
     * things get done properly
     */
    handleWaypointClicked(waypoint) {
        let self = this;
        let id = waypoint._leaflet_id;
        let selectedID = self.selectedWaypoint._leaflet_id;

        if(selectedID && selectedID !== id) {
            //Another waypoint is currently selected
            if(self.selectedWaypoint.editEnabled()) {
                //we disable that waypoint
                self.selectedWaypoint.disableEdit();
                // self.selectedWaypoint.shouldShow = false;
                self.handleWaypointFadeOut(self.selectedWaypoint);
            }
            //we toggle its class
            self.toggleWaypointClass(self.selectedWaypoint);
            // we make selectedWaypoint empty
            self.selectedWaypoint = {};
        }

        //now we turn our focus to the waypoint that was clicked
        if(waypoint.editEnabled()) {
            //we're currently editing this waypoint
            //so we disableEdit and all related
            waypoint.disableEdit();
            // waypoint.shouldShow = false; // NOTE: shouldn't this be waypoint.shouldShow?
            self.handleWaypointFadeOut(waypoint);
            self.selectedWaypoint = {};
        } else {
            //we're not currently editing this waypoint
            //so we enableEdit and all related
            waypoint.enableEdit();
            // waypoint.shouldShow = true;
            self.handleWaypointFadeIn(waypoint);
            self.selectedWaypoint = waypoint;
        }
        self.toggleWaypointClass(waypoint);

    }

    /**
     * When we are scrolling between the details of our waypoints I'm using this
     * to handle some of the animation timings
     */
    handleWaypointFadeOut(waypoint) {
        let self = this;
        let thisWaypoint = _.find(self.waypoints, (storedWaypoint) => {
            return storedWaypoint.layer._leaflet_id === waypoint._leaflet_id;
        });

        thisWaypoint.layer.fadingOut = true;
        self.fadingWaypointDetailsOut = true;
        self.$timeout(() => {
            thisWaypoint.layer.shouldShow = false;
            thisWaypoint.layer.fadingOut = false;
            self.fadingWaypointDetailsOut = false;
        },self.waypointFadingTime);

    }

    /**
     * When we are scrolling between the details of our waypoints I'm using this
     * to handle some of the animation timings
     */
    handleWaypointFadeIn(waypoint) {
        let self = this;
        let thisWaypoint = _.find(self.waypoints, (storedWaypoint) => {
            return storedWaypoint.layer._leaflet_id === waypoint._leaflet_id;
        });
        let wait = self.fadingWaypointDetailsOut === true;

        if(!wait) {
            thisWaypoint.layer.fadingIn = true;
            self.$timeout(() => {
                thisWaypoint.layer.shouldShow = true;
                thisWaypoint.layer.fadingIn = false;
            },self.waypointFadingTime);
        } else {
            self.$timeout(() => {
                thisWaypoint.layer.fadingIn = true;
                self.$timeout(() => {
                    thisWaypoint.layer.shouldShow = true;
                    thisWaypoint.layer.fadingIn = false;
                },self.waypointFadingTime);
            },self.waypointFadingTime);
        }
    }

    /**
     * Currently we are only adding one class - selected-waypoint
     */
    toggleWaypointClass(waypointLayer) {
        let self = this;
        let waypoint = self.waypoints[waypointLayer.waypointNumber-1];
        let circle = waypoint.circleLayer;
        let divIcon = waypoint.divIconLayer;
        if(L.DomUtil.hasClass(waypointLayer._icon, 'selected-waypoint')) {
            L.DomUtil.removeClass(waypointLayer._icon, 'selected-waypoint');
            circle.setStyle({color: self.plantedWaypointCircleColor});
            L.DomUtil.removeClass(divIcon._icon.firstChild, 'waypoint-route-show-active');
        } else {
            L.DomUtil.addClass(waypointLayer._icon, 'selected-waypoint');
            circle.setStyle({color: self.selectedWaypointCircleColor});
            L.DomUtil.addClass(divIcon._icon.firstChild, 'waypoint-route-show-active');
        }
    }


    /**
     * When we have a new waypoint that we should draw,
     * we first create a new slot for it within our self.waypoints array.
     */
    newWaypointInArray() {
        let self = this;
        let id = self.uuid.v1().replace(/-/g, '');
        self.waypoints.push({_id: id, name: '', radius: 10, notes: '', layer: '', type:'GPS'});
    }

    /**
     * Calling this function by clicking the undo button on the map
     */
    undoLastAddedWaypoint() {
        let self = this;
        //We get the layer that we would want to be removed (-2 because we've already created a new slot for another waypoint)
        let waypoint = self.waypoints[self.waypoints.length-2];
        if(!waypoint) {
            return null;
        }
        if(self.selectedWaypoint && waypoint.layer && self.selectedWaypoint._leaflet_id === waypoint.layer._leaflet_id) {
            waypoint.layer.fire('click');
            //this beautifully handles deselect of waypoint so no funky business occurs.
        }
        //we grab the waypointNumber of the layer we're removing
        let waypointNumber = waypoint.layer.waypointNumber;
        //we grab the name of the layer we're removing
        let waypointName = waypoint.name;
        //we're removing this layer from the map
        self.removeWaypointLayersFromMap(waypoint);
        //we're removing this layer from our array
        self.waypoints.splice(self.waypoints.length-2, 1);
        //we're giving the extra-slot that has already been created the correct name and waypointNumber
        self.waypoints[self.waypoints.length-1].name = waypointName;
        self.waypoints[self.waypoints.length-1].layer.waypointNumber = waypointNumber;
    }

    /**
     * When we decide to stop drawing markers we have already created a new slot
     * that particular marker in our self.waypoints. We now simply clear the last one
     */
    deleteWaypointInArray() {
        let self = this;
        self.waypoints.pop();
    }

    /**
     * When we click on the delete button top-right of the map
     */
    deleteSelectedWaypoint() {
        let self = this;
        let waypoint = _.find(self.waypoints, (storedWaypoint) => {
            return storedWaypoint.layer._leaflet_id === self.selectedWaypoint._leaflet_id;
        });
        self.deleteWaypoint(waypoint);
    }

    /**
     * When we click on the button at the individual waypoint's detail-box
     * When we want to totally remove a defined waypoint, this is the function that contains
     * references to all the necessary logic, (that is, removing layers, removing arrays, handling
     * logical consequences, etc)
     * @param waypoint - Not the layer, but the entire waypoint object
     */
    deleteWaypoint(waypoint) {
        let self = this;
        let index = _.findIndex(self.waypoints, (storedWaypoint) => {
            return storedWaypoint._id === waypoint._id;
        });
        let inRoute = _.some(self.routes,(route) => {
            return _.find(route.path,(path) => {
                return path.waypoint && path.waypoint._id == waypoint._id;
            });
        });
        if(inRoute) {
            self.toastr.warning('Remove from all routes before removing waypoint.','Waypoint still in use.');
            return null;
        }

        if(self.selectedWaypoint._leaflet_id === waypoint.layer._leaflet_id) {
            //this beautifully handles deselect of waypoint so no funky business occurs.
            waypoint.layer.fire('click');
        }

        let first; let last; let between;

        if(index !== -1) {
            if(index === 0) {
                first = true;
            } else if(index === self.waypoints.length-1) {
                last = true;
            } else {
                between = true;
            }

            //this handles all leaflet-related stuff
            self.removeWaypointLayersFromMap(waypoint);

            //removing the waypoint from our array
            self.waypoints.splice(index, 1);

            //updating waypoint.layer.waypointNumber
            self.reorderWaypointNumbers();

            if(between) {
                //draw a new distanceline if need be
                self.drawWaypointDistance(self.waypoints[index].layer);
            }

            for (let i = index; i < self.waypoints.length; i++) {
                //removing old tooltip
                self.waypoints[i].layer.unbindTooltip();
                //binding a new one
                self.addWaypointLabel(self.waypoints[i].layer);
            }
        }
        if(waypoint.routeMapLayer) {

            self.routeMapLayer.removeLayer(waypoint.routeMapLayer.marker);
            self.routeMapLayer.removeLayer(waypoint.routeMapLayer.circle);
            self.routeMapLayer.removeLayer(waypoint.routeMapLayer.divIcon);

            if(waypoint.layer.waypointNumber !== 1) {
                //we are drawing distanceLines only from the 2nd waypoint, and we give THAT waypoint the distanceLineLayer
                self.routeMapLayer.removeLayer(waypoint.routeMapLayer.distanceLine);
            } else {
                let next = self.waypoints[waypoint.layer.waypointNumber];
                let hasNext = next !== undefined;

                if(hasNext) {
                    //If the first waypoint was moved, we actually need to redraw
                    //the line connecting it to its next waypoint.
                    //But that line is located on its successor's object. Therefore,
                    //we mark it to be redrawn
                    next.layer.drawnOnRouteMap = false;
                    next.layer.changed = true;
                }
            }
        }
    }

    /**
     * When we want to remove all the leaflet-related information pertaining to a waypoint
     * Currently only calling this from @see deleteWaypoint()
     */
    removeWaypointLayersFromMap(waypoint) {
        let self = this;
        //removing the flag
        self.genericMapService.removeLayerFromMap(self.map, waypoint.layer);

        //removing the radiusCircle
        self.genericMapService.removeLayerFromMap(self.waypointCircleLayer, waypoint.circleLayer);

        //removing distance lines
        let firstWaypoint = 1;
        let lastWaypoint = self.waypoints.length;
        if(waypoint.layer.waypointNumber === lastWaypoint) {
            self.genericMapService.removeLayerFromMap(self.waypointDistanceLineLayer, waypoint.distanceLineLayer);
        } else if(waypoint.layer.waypointNumber === firstWaypoint) {
            let secondWaypoint = self.waypoints[1];

            if(secondWaypoint.layer.isPlanted) {
                self.genericMapService.removeLayerFromMap(self.waypointDistanceLineLayer, secondWaypoint.distanceLineLayer);
            }
        } else {
            let nextWaypoint = self.waypoints[waypoint.layer.waypointNumber];
            if(nextWaypoint.layer.isPlanted) {
                self.genericMapService.removeLayerFromMap(self.waypointDistanceLineLayer, nextWaypoint.distanceLineLayer);
            }
            self.genericMapService.removeLayerFromMap(self.waypointDistanceLineLayer, waypoint.distanceLineLayer);
        }
    }

    /**
     * When we are removing a waypoint we make use of
     * self.waypoints[i].layer.waypointNumber.
     * It's important that we update these numbers after removal of
     * waypoint yet before drawing new ones
     */
    reorderWaypointNumbers() {
        let self = this;
        for (let i = 0; i < self.waypoints.length; i++) {
            let newWaypointNumber = i + 1;

            /**
             * If a custom name hasn't been given to following waypoints
             * @type {[type]}
             */
            if(self.waypoints[i].name === `Waypoint ${newWaypointNumber+1}`) {
                self.waypoints[i].name = `Waypoint ${newWaypointNumber}`;
            }

            self.waypoints[i].layer.waypointNumber = newWaypointNumber;
        }
    }

    /**
     * This function is called from HTML when we desire
     * to manually move to the next marker
     */
    selectNextWaypoint() {
        let self = this;
        let nextIndex = _.findIndex(self.waypoints, (waypoint) => {
            return waypoint.layer._leaflet_id === self.selectedWaypoint._leaflet_id;
        });
        let oldIndex = nextIndex;
        // self.waypoints[oldIndex].shouldExit = true;

        nextIndex ++;
        self.waypoints[nextIndex].layer.fire('click');
        // self.waypoints[nextIndex].shouldEnter = true;
        self.$timeout(() => {
            // self.waypoints[oldIndex].shouldExit = false;
            // self.waypoints[nextIndex].shouldEnter = false;
        }, 300);

    }

    /**
     * This function is called from HTML when we desire
     * to manually move to the prev marker
     */
    selectPrevWaypoint() {
        let self = this;
        let prevIndex = _.findIndex(self.waypoints, (waypoint) => {
            return waypoint.layer._leaflet_id === self.selectedWaypoint._leaflet_id;
        });
        prevIndex --;
        self.waypoints[prevIndex].layer.fire('click');
    }

    /**
     * This function is called from HTML when we desire
     * to manually move to a specific marker
     */
    selectSpecificWaypoint(waypoint) {
        let self = this;
        waypoint.layer.fire('click');
    }

    /**
     * We call this function at STEP1 of the wizard, via ng-click
     */
    onShowAdvancedSettings() {
        let self = this;

        if(!self.showAdvancedSettings) {
            self.showAdvancedSettings = !self.showAdvancedSettings;

            self.$timeout(() => {
                self.moveAdvancedSettingsIcon = true;
            }, 200);
        } else {
            self.moveAdvancedSettingsIcon = false;
            self.$timeout(() => {
                self.showAdvancedSettings = !self.showAdvancedSettings;
            });
        }
    }

    /**
     * We call this function at STEP1 of the wizard, via ng-click
     */
    onShowAdvancedFind() {
        let self = this;
        self.showAdvancedFind = !self.showAdvancedFind;
    }

    /************************************************
     * * *PERTAINING TO ROUTE FUNCTIONS(start)* * * *
     ***********************************************/
    //  chooseStartingPoint() {
    //     let self = this;
    //     self.$ngConfirm(
    //         {
    //             title: `Available Waypoints`,
    //             onReady: function(scope) {
     //
    //             },
    //             theme: 'light',
    //             animation: 'top',
    //             animationSpeed: 250,
    //             scope: self.$scope,
    //             closeAnimation: 'bottom',
    //             content: `
    //             <div ng-class="{'ui-select-site-open' : $ctrl.uisIsOpen }">
	// 				<ui-select my-ui-select auto-open uis-open-close="$ctrl.onOpenClose(isOpen)" on-select="$ctrl.setLastChosenPoint($item)"
	// 						   ng-model="$ctrl.startingPoint" theme="select2" style="min-width:300px;" title="Choose a Starting waypoint">
	// 					<ui-select-match placeholder="Select a waypoint in the list or search by name or location">{{$select.selected.name}}</ui-select-match>
	// 					<ui-select-choices repeat="waypoint in $ctrl.waypoints | filter: $select.search">
	// 						<div ng-bind-html="waypoint.name | highlight: $select.search"></div>
	// 						<small>
	// 							<span ng-bind-html="'Lat: ' + waypoint.loc.lat | highlight: $select.search"></span> <br />
	// 							<span ng-bind-html="'Lng: ' + waypoint.loc.lng | highlight: $select.search"></span>
	// 						</small>
	// 					</ui-select-choices>
	// 				</ui-select>
    //             </div>
    //             `,
    //             escapeKey: true,
    //             backgroundDismiss: true,
    //             buttons: {
    //                 ok: {
    //                     text: "Choose",
    //                     btnClass: 'btn-primary',
    //                     keys: ['enter'], // will trigger when enter is pressed
    //                     action: function(scope){
    //                         self.startingPointDefined = true;
    //                         self.newRouteInArray();
    //                         let firstPoint = self.startingPoint;
    //                         _.merge(self.routes[0].path[0], {_id: firstPoint._id, name: firstPoint.name, number: firstPoint.layer.waypointNumber, notes: firstPoint.notes, loc: firstPoint.loc });
     //
    //                         self.setUniqueVisits(self.routes[0]);
    //                     }
    //                 },
    //                 close: {
    //                     text: "Cancel",
    //                     action: function(scope) {
     //
    //                     }
    //                 }
    //             },
    //         }
    //     );
    //  }


    /**
     * We can have many routes, but on a SINGLE route we have many wayPOINTS.
     * When we click on the waypointCircle in Step 2, we add waypoints to our routes
     * with this function
     */
    addPointToRoute(waypoint, pointID) {
        let self = this;
        // let index = self.routes.length -1;
        let _id = pointID ? pointID : self.generateNumber(1, 20000);
        //TODO: We're going to consider adding multiple routes from this one page, Therefore
        //TODO: we want to be able to deduct which route this waypoint-path should be added to
        let route = self.selectedRoute;
        let routeLength = route.path.length;
        let lastWaypointAdded = routeLength === 0 ? false : route.path[routeLength-1].waypoint;

        if(lastWaypointAdded) {
            //this is not the first waypoint
            if(waypoint._id === lastWaypointAdded._id) {
                //we are visiting the same waypoint twice in a row, so we just warn the user
                self.toastr.warning(`Consider adding a different waypoint first`, {
                    closeButton: true,
                    tapToDismiss: true,
                });
                return null;
            }
            route.path.push({_id,
                waypoint
            });
            self.updatePointDistance(route, route.path[route.path.length-1]);
            self.calculateRouteVariables(route);
            //Update first wp
        } else {
            //this is the first waypoint being added
            route.path.push({_id,
                waypoint
            });
            self.updatePointDistance(route, route.path[route.path.length-1]);
            self.calculateRouteVariables(route);
        }
    }

    /**
     * We are adding a point to an existing path.
     * We are doing so by clicking on the PLUS in HTML (step 2)
     */
     selectAddPointToRoute(route) {
        let self = this;

        if(self.waypoints.length === 0) {
            self.toastr.warning(`Consider defining waypoints first`,'No waypoints found', {
                closeButton: true,
                tapToDismiss: true,
            });

            return;
        }

        self.$ngConfirm(
            {
                title: `Available Waypoints`,
                onReady(scope) {

                },
                theme: 'light',
                animation: 'top',
                animationSpeed: 250,
                scope: self.$scope,
                closeAnimation: 'bottom',
                content: `
                <div ng-class="{'ui-select-site-open' : $ctrl.uisIsOpen }">
					<ui-select my-ui-select auto-open uis-open-close="$ctrl.onOpenClose(isOpen)" on-select="$ctrl.setLastChosenPoint($item)"
							   ng-model="$ctrl.chosenPoint" theme="select2" style="min-width:300px;" title="Choose a Starting waypoint">
						<ui-select-match placeholder="Select a waypoint in the list or search by name">{{$select.selected.name}}</ui-select-match>
						<ui-select-choices ui-disable-choice="waypoint._id === $ctrl.lastChosenPoint._id" repeat="waypoint in $ctrl.waypoints | filter: $select.search">
							<div ng-bind-html="waypoint.name | highlight: $select.search"></div>
							<small>
								<span ng-bind-html="'Lat: ' + waypoint.loc.lat | highlight: $select.search"></span> <br />
                                <span ng-bind-html="'Lng: ' + waypoint.loc.lng | highlight: $select.search"></span> <br />
                                <span ng-if="waypoint.type == 'GPS'" ng-bind-html="'Radius: ' + waypoint.radius | highlight: $select.search"></span>
								<span ng-if="waypoint.type == 'iButton' || waypoint.type == 'Bluetooth'" ng-bind-html="'ID: ' + waypoint.tagId | highlight: $select.search"></span>
							</small>
						</ui-select-choices>
					</ui-select>
                </div>
                `,
                escapeKey: true,
                backgroundDismiss: true,
                buttons: {
                    ok: {
                        text: "Choose",
                        btnClass: 'btn-primary',
                        keys: ['enter'], // will trigger when enter is pressed
                        action(scope) {
                            self.addPointToRoute(self.chosenPoint);
                        }
                    },
                    close: {
                        text: "Cancel",
                        action(scope) {

                        }
                    }
                },
            }
        );
    }

    /**
     * Whenever we want to remove a waypoint from a given route
     */
    removePointInRoute(route, point) {
        let self = this;
        let pointIndex = _.findIndex(route.path, (storedPoint) => {
            return storedPoint._id === point._id;
        });
        route.path.splice(pointIndex, 1);

        if(pointIndex < route.path.length) {
            //We have just spliced an item out of the array, which means that
            //pointIndex now refers to the next point (should one exist)
            self.updatePointDistance(route, route.path[pointIndex]);
            //Update first wp

        } else {
            self.calculateRouteVariables(route);
        }
    }

    /**
     * When we are removing waypoints from our routes, we need to update the values
     * of those points that are effected by the removal
     */
    updatePointDistance(route, point) {
        let self = this;

        let pointIndex = _.findIndex(route.path, (storedPoint) => {
            return storedPoint._id === point._id;
        });

        if(pointIndex !== 0) {
            //the first point will NOT have a predecessor
            let predecessor = route.path[pointIndex - 1];

            let distance = self.routeMap.distance(point.waypoint.layer._latlng, predecessor.waypoint.layer._latlng);
            // let formattedDistance = self.handleDistanceConversion(distance);

            point.distance = distance;
            // point.formattedDistance = formattedDistance;

            /**
            * Since we've just altered some values, we need to recalculate the total route:
            * distance
            * duration and
            * waypoints utilized
            */
            self.calculateRouteVariables(route);
        } else {
            point.distance = 0;
            // point.formattedDistance = "Begin";
            self.calculateRouteVariables(route);
        }
    }

    /**
     * In order for the ui-select at step 2 to disable
     * the previous selected waypoint, we use this function
     * to keep track of lastChosenPoint
     * @see addPointToRoute(route)
     * @see chooseStartingPoint()
     * @see lastChosenPoint
     */
    setLastChosenPoint(waypoint) {
        let self = this;
        self.lastChosenPoint = waypoint;
    }


    removeRoute(routeIndex) {

        let self = this;

		if(self.isEdit) {
			self.removeRouteEdit(routeIndex);
		}else{
			self.routes.splice(routeIndex,1);

			if(self.routes.length == 0) {
				self.newRouteInArray();
			}else{
				let prevIndex = Math.max(0,routeIndex-1);
				self.selectedRoute = self.routes[prevIndex];
			}

		}


    }
     removeRouteEdit(routeIndex) {
         let self = this;
         let promisses = [];
		 let route = self.routes[routeIndex];
         let schedules = self.scheduleService.getScheduleWithQuery({query:route._id,field:'slots.typeId', activeOnly:'true'});
         let shifts = self.shiftService.getShiftWithQuery({activeOnly:true,query:route._id,field:'slots.typeId', between:{start:self.moment().valueOf(), end:self.moment().add(1,'hour')
.valueOf()}});
         promisses.push(schedules);
         promisses.push(shifts);

         Promise.all(promisses).then((results) => {
             let disabled = false;
             let content = '';
             if(results) {
                 if(results[0] && results[0].total > 0) {
                     content += `<br>This route is linked to ${results[0].total} schedule${results[0].total > 1 ? 's' : ''}. Unlink schedule${results[0].total > 1 ? 's' : ''} before removing.`;
                     disabled = true;
                 }
                 if(results[1] && results[1].total > 0) {
                     disabled = true;
                     content = `<br>An active shift is linked to this route.  The shift needs to finish before the route can be removed.`;
                 }
             }
             if(!disabled) {
                 content += `Clicking <b>OK</b> would remove this route`;
             }
             self.$ngConfirm(
                 {
                     title: `Remove <b>${route.name}</b>?`,
                     content,
                     escapeKey: true,
                     backgroundDismiss: true,
                     buttons: {
                         ok: {
                             text: "Ok",
                             btnClass: `btn-primary ${disabled ? 'hidden' : ''}`,
                             keys: ['enter'], // will trigger when enter is pressed
							 action(scope) {
								 self.routes.splice(routeIndex,1);

								 if(self.routes.length == 0) {
									 self.newRouteInArray();
								 }else{
									 let prevIndex = Math.max(0,routeIndex-1);
									 self.selectedRoute = self.routes[prevIndex];
								 }
							 }
                         },
                         close(scope) {

                         }
                     },
                 }
             );
         });
    }

    /**
     * We're making a new slot for a new route.
     * We call this when:
     * Calling readyRouteMap() for the first time (@see readyRouteMap())
     * making any additional routes (which isn't currently happening)
     */
    newRouteInArray() {
        let self = this;
        let routeNum = self.routes.length + 1;
        let id = self.generateNumber(1, 20000);

        //upon creating the new route we want to display it via the ng-show
        self.routes.push({_id: id, name: `Route ${routeNum}`, description: "", path: [], totalTime: 0 ,sequenced:false,ordered:false});
        self.selectedRoute = self.routes[self.routes.length-1];

        self.routeDistances[id] = "0 km";
        self.routeMaxDurations[id] = "0 minutes";
        self.routeMinDurations[id] = "0 minutes";
        self.uniqueVisitedWaypoints[id] = 0;
    }

    onNewRouteInArray() {
        let self = this;
        self.newRouteInArray();
    }

    selectSpecificRoute(route) {
        let self = this;
        self.selectedRoute = route;
    }

    /**
     * In the summary-box of step 2 we wish to show utilization of waypoints.
     */
    setUniqueVisits(route) {
        let self = this;
        let found = [];
        let result = 0;

        _.forEach(route.path, (point) => {
            if(!found.includes(point.waypoint._id)) {
                found.push(point.waypoint._id);
            }
        });
        result = found.length;
        self.uniqueVisitedWaypoints[route._id] = result;
    }

    calculateRouteVariables(route) {
        let self = this;
        self.calculateRouteDistance(route);
        self.calculateRouteDuration(route);
        self.setUniqueVisits(route);
    }

    calculateRouteDistance(route) {
        let self = this;
        let result = 0;
        let unit = "m";

        _.forEach(route.path, (point) => {
            if(point.distance) {
                result += point.distance;
            }
        });

        if(result >= 1000) {
            result = result / 1000;
            unit = "km";
        }

        self.routeDistances[route._id] = `${result.toFixed(2)} ${unit}`;
    }

    /**
     * When the user opts to be a bit more advanced and supply max-min values
     * This will probably be revisited though
     * @see calculateRouteDuration()
     * @see HTML step 2
     */
    toggleAdvancedRouteTiming(route) {
        let self = this;
        //recalculate the values to make sure our route.totalTime gets updated
        self.calculateRouteDuration(route);

    }

    toggleSequenced(route) {
        let self = this;
        if(!route.sequenced) {
            route.ordered = false;
        }
        self.calculateRouteDuration(route);
    }

    /**
     * Whenever the user adjusts traveltime, lingertime, etc we update all the summary-values.
     * NOTE that the method below is quite arbitrary and feels extremely redundant
     */
    calculateRouteDuration(route, changedTotal) {
        let self = this;
        let id = route._id;
        let lingerTime = 0;

        //advancedRouteTiming//
        let maxTotalTime = 0;
        let maxTravelTime = 0;
        let minTotalTime = 0;
        let minTravelTime = 0;
        //advancedRouteTiming//

        let travelTime = 0;
        let totalTime = 0;

        if(changedTotal) {
            self.distributeTotalTimeEvenly(route);
        }

        _.forEach(route.path, (point) => {
            if(point.lingerTime) {
                maxTotalTime += point.lingerTime;
                minTotalTime += point.lingerTime;
                totalTime += point.lingerTime;
                lingerTime += point.lingerTime;
            }
            if(point.maxTravelTime) {
                maxTotalTime += point.maxTravelTime;
                maxTravelTime += point.maxTravelTime;
            }
            if(point.minTravelTime) {
                minTotalTime += point.minTravelTime;
                minTravelTime += point.minTravelTime;
            }
            if(point.travelTime) {
                travelTime += point.travelTime;
                totalTime += point.travelTime;
            }
        });

        if(!changedTotal) {
            route.totalDuration = Math.max(totalTime,0);
        }
        let maxTotalResult = self.handleDurationConversion(maxTotalTime);
        let minTotalResult = self.handleDurationConversion(minTotalTime);
        let totalResult = self.handleDurationConversion(totalTime);

        let lingerResult = self.handleDurationConversion(lingerTime);
        let maxTravelResult = self.handleDurationConversion(maxTravelTime);
        let minTravelResult = self.handleDurationConversion(minTravelTime);
        let travelResult = self.handleDurationConversion(travelTime);

        self.routeMaxDurations[id] = maxTotalResult;
        self.routeMinDurations[id] = minTotalResult;
        self.routeDurations[id] = totalResult;

        self.routeLingerDuration[id] = lingerResult;

        self.routeMaxTravelDuration[id] = maxTravelResult;
        self.routeMinTravelDuration[id] = minTravelResult;
        self.routeTravelDuration[id] = travelResult;

        if(self.advancedRouteTiming) {
            route.totalTime = maxTotalTime * 60;
        } else {
            route.totalTime = totalTime * 60;
        }
    }


    distributeTotalTimeEvenly(route) {
        let self = this;
        let totalDistance = _.reduce(route.path,(sum,n) => {
            return sum += n.distance;
        },0);
        let totalDuration = route.totalDuration;
        let endDuration = 0;
        route.path.forEach((path, index) => {
            if(index == 0) {
                if(!(path.lingerTime && path.lingerTime <= 0)) {
                    path.lingerTime = 5;
                }
                totalDuration -= path.lingerTime;
                endDuration += path.lingerTime;
            }else {
                let per = path.distance/totalDistance;
                path.travelTime = Math.max(Math.round(per*totalDuration*0.8),0);
                path.lingerTime = Math.max(Math.round(per*totalDuration*0.2),0);
                endDuration += path.lingerTime;
                endDuration += path.travelTime;
            }
        });
        let diff = route.totalDuration - endDuration;
        route.path[0].lingerTime += diff;
    }

    /**
     * @param duration in minutes
     */
    handleDurationConversion(duration) {
        let self = this;

        if(duration >= 60) {
            let hours = Math.floor(duration/60);
            let hoursUnit = hours === 1 ? "hour" : "hours";

            let minutes = duration%60;
            let minutesUnit = minutes === 1 ? "minute" : "minutes";

            return `${hours} ${hoursUnit}, ${minutes} ${minutesUnit}`;

        } else {
            let minutes = duration;
            let minutesUnit = minutes === 1 ? "minute" : "minutes";
            return `${minutes} ${minutesUnit}`;
        }
    }

    /**
     * NOTE: Due to this function I created a STRING property named formattedDistance on each point
     * This is because I already have written other functions that require the actual, full distance
     * as a number, not a string.
     */
    handleDistanceConversion(distance) {
        let self = this;
        let meters = distance;
        let decimals = 2;
        let kilometers;
        let result = '';

        if(distance > 1000) {
            kilometers = distance / 1000;
            kilometers = kilometers.toFixed(decimals);
            return `${kilometers} km`;
        } else {
            meters = meters.toFixed(decimals);
            return `${meters} m`;
        }
    }

    /**
     * On the final step we can press the 'edit' button that will allow us
     * to make the proper route active and to review it in Step 2
     */
    reviewRoute(route) {
        let self = this;
        self.$timeout(() => {
            //We have to put this in a $timeout in order to break out of the current
            //$apply cycle.
            let prevButton = angular.element(document.querySelector('#tosteptwo'));
            prevButton[0].click();
            self.selectedRoute = route;
        });
    }

    /**
     * When the user scrolls away and quickly wants to find his waypoints again.
     */
    fitWaypoints() {
        let self = this;
        let loc = [];
        _.forEach(self.waypoints, (waypoint) => {
            loc.push(waypoint.loc);
        });
        self.genericMapService.fitMapToLoc(self.routeMap, loc);
    }


    /************************************************
     * * *PERTAINING TO ROUTE FUNCTIONS(end)* * * *
     ***********************************************/

    closeModal() {
        let self = this;
		let waypointsCopy = _.cloneDeep(self.waypoints);
		let routesCopy = _.cloneDeep(self.routes);

        let waypoints = [];

        //Doing some clean-up that we can be sure to provide a clean object, without all the layers, etc.
        _.forEach(waypointsCopy, (waypoint) => {
            // TODO: Convert the circle to a octigon-polygon in/out-boundaries thingy and add to geometries
            let wp = {_id: waypoint._id, tagId: waypoint.tagId,shape: {geometries: [{type: "Point", coordinates: L.GeoJSON.latLngToCoords(waypoint.loc)}]}, name: waypoint.name, notes: waypoint.notes, radius: waypoint.radius, type: waypoint.type};
			if(wp.type == 'GPS') {
				delete wp.tagId;
			}
            waypoints.push(wp);
        });

        _.forEach(routesCopy, (route) => {
            _.forEach(route.path, (point) => {
                point.waypoint = point.waypoint._id;
            });
        });


        if(self.isEdit) {
            self.routeCollection.waypoints = waypoints;
            self.routeCollection.routes = routesCopy;
            if(self.boundedSite && self.boundedSite._id) {
                self.routeCollection.site = self.boundedSite._id;
            }else {
                self.routeCollection.site = undefined;
            }
			let routeCollectionCopy = _.cloneDeep(self.routeCollection);
            routeCollectionCopy.routes.forEach((route)=>{
                if(typeof route._id === "number") {
                    delete route._id;
                }
                route.path.forEach((point)=>{
                    if(typeof point._id === "number") {
                        delete point._id;
                    }
                    if(point.travelTime) {
                        point.travelTime = point.travelTime*60;
                    }
                    if(point.lingerTime) {
                        point.lingerTime = point.lingerTime*60;
                    }
                    if(point.maxTravelTime) {
                        point.maxTravelTime = point.maxTravelTime*60;
                    }
                });
                if(route.path[0].waypoint == route.path[route.path.length-1].waypoint) {
                    route.repeatable = true;
                }else {
                    route.repeatable = false;
                }
            });
            self.routeService.updateRouteCollection(routeCollectionCopy).then((result) => {
                self.toastr.info('Route saved successfully.');
				self.modalInstance.close(result);
            })
.catch((err) => {
				if(err.data.includes("already assigned")) {
					self.toastr.error('Waypoint tag/beacon ID already in use.');
				}else{
					self.toastr.error('Failed to save route.');
				}
            });
        } else {
            self.routeCollection.waypoints = waypoints;
            self.routeCollection.routes = routesCopy;
            if(self.boundedSite && self.boundedSite._id) {
                self.routeCollection.site = self.boundedSite._id;
            }else {
                self.routeCollection.site = undefined;
            }
            //TODO : Estimate as polygon
            // result.waypoints.forEach((waypoint) => {
            // 	if (waypoint.type == 'GPS') {
            // 		self.numbersService.createPolygonFromCircle(waypoint.shape.geometries[0], waypoint.radius)
            // 	}
            // })

			let routeCollectionCopy = _.cloneDeep(self.routeCollection);
            routeCollectionCopy.routes.forEach((route)=>{
                delete route._id;
                route.path.forEach((point)=>{
                    delete point._id;
                    if(point.travelTime) {
                        point.travelTime = point.travelTime*60;
                    }
                    if(point.lingerTime) {
                        point.lingerTime = point.lingerTime*60;
                    }
                    if(point.maxTravelTime) {
                        point.maxTravelTime = point.maxTravelTime*60;
                    }
                });
                if(route.path[0].waypoint == route.path[route.path.length-1].waypoint) {
                    route.repeatable = true;
                }else {
                    route.repeatable = false;
                }
            });
            //This result is a routeCollection, it may contain a few routes that were defined just now
            routeCollectionCopy.account = self.Auth.getCurrentAccountSync().ref;
            self.routeService.saveNewRouteCollection(routeCollectionCopy).then((result) => {
                self.toastr.info('Route created successfully.');
				self.modalInstance.close(result);
            })
.catch((err) => {
				if(err.data == "iButton already assigned") {
					self.toastr.error('Waypoint iButton already in use.');
				}else{
					self.toastr.error('Failed to save route.');
				}
            });
        }
    }
    dismissModal() {
        let self = this;
        self.modalInstance.dismiss("Modal was dismissed");
    }

    validateLoc(wp) {
        let self = this;
        if(!self.boundedSite || !self.boundedSite._id) {
            return true;
        }else if(wp && wp.loc) {
                let inBounds = turfPip([wp.loc.lng,wp.loc.lat],self.boundedSite.geoZone);
                return inBounds;
            }else {
                return false;
            }
    }

    doLog() {
		console.debug(this);
    }

    generateNumber(min,max) {
        return Math.floor(Math.random()*(max-min+1)+min);
    }


    updateAllDistances() {
        let self = this;
        if(self.routes) {
            self.routes.forEach((route) => {
                if(route) {
                    route.path.forEach((path) => {
                        self.updatePointDistance(route, path);
                    });
                }
            });
        }
    }

	refreshGroups() {
		let self = this;
		self.privGroupService.getGroupWithQuery().then( (groups) => {
			self.availableGroups = groups;
		})
.catch(err=>{
			console.error(err);
		});
	}

	onGroupAdded() {
		let self = this;
		let label = " (create new group)";
		let tempGroups = [];
		_.forEach(self.routeCollection.groups, (group) => {
			if (group.slice(-label.length) === label) {
				group = group.slice(0, -label.length);
			}
			tempGroups.push(group);
		});
		self.routeCollection.groups = tempGroups;
	}
}

angular.module('secutraqApp.routes').filter('formatDistance', function() {
    return function(distance) {
        let meters = distance;
        let decimals = 2;
        let kilometers;
        let result = '';
        if(distance == 0) {
            return 'Begin';
        }
        if(distance > 1000) {
            kilometers = distance / 1000;
            kilometers = kilometers.toFixed(decimals);
            return `${kilometers} km`;
        } else {
            meters = meters.toFixed(decimals);
            return `${meters} m`;
        }
    };
});


export default angular.module('secutraqApp.routes')
.component('newRoute', {
    template: require('./new-route.html'),
    controller: NewRouteComponent,
    controllerAs: "$ctrl",
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
});
