'use strict';
angular.module('secutraqApp.directives')
  .directive('myUiSelect', ['$document', '$timeout',
    function($document, $timeout) {
        return {
      require:'uiSelect',
      restrict: 'A',
      link: function(scope, elm, attrs, $select) {

          if(Object.keys(attrs).includes('autoOpen')) {
              let waiting = attrs.autoOpen === '' ? 200 : +attrs.autoOpen;
              $timeout(() => {
                  $select.toggle();
              }, waiting);
          }
      }
    };
  }]);
