export class PrivGroupsComponent {
	Auth;
	$stateParams;
	Restangular;
	NgTableParams;
	$uibModal;
	$log;
	filter;
	groups;
	selectedGroup;
	Contacts;
	Groups;

	/*@ngInject*/
	constructor(Auth, $stateParams, Restangular, NgTableParams, $uibModal, $log, unitService, siteService, routeService, privGroupService) {
		this.Auth = Auth;
		this.$stateParams = $stateParams;
		this.Restangular = Restangular;
		this.NgTableParams = NgTableParams;
		this.$uibModal = $uibModal;
		this.$log = $log;
		this.getCurrentUser = Auth.getCurrentUserSync;
		this.Contacts = this.Restangular.all('contacts');
		this.Groups = this.Contacts.all('groups');
		this.unitService = unitService;
		this.siteService = siteService;
		this.routeService = routeService;
		this.privGroupService = privGroupService;


	}

	$onInit() {
		let self = this;
		this.filter = '';

		this.data = [];
		this.groups = [];
		this.selectedGroup;

		this.privGroupService.getGroupWithQuery().then( (groups) => {
			self.groups = groups;
			self.selectedGroup = self.groups[0];
			self.applyFilter();
		} );


		this.tableParams = new this.NgTableParams({
			count: 1000, // count per page
			sorting: {
				group: 'asc' // initial sorting
			}
		}, {
			counts:[],
			total: 0,
			getData: self.refreshData.bind(self)
		});
	}

	refreshData(params) {
		let self = this;
		let order;
		if(!self.selectedGroup) {
			return [];
		}
		let query = {query:self.selectedGroup, field: 'groups'};
		if(params && params.sorting) {
			order = params.sorting();
			let promises = [];
			promises.push(self.siteService.getSitesWithQuery(query).then( (data) => {
				data.forEach( (val) => {
					val.objType = 'Site';
				} );
				return data;
			} ));
			promises.push(self.unitService.getUnitsWithQuery(query).then( (data) => {
				data.forEach( (val) => {
					val.objType = 'Unit';
				} );
				return data;
			} ));
			promises.push(self.routeService.getRoutesWithQuery(query).then( (data) => {
				data.forEach( (val) => {
					val.objType = 'Route';
				} );
				return data;
			} ));
			promises.push(self.Contacts.getList(query).then( (data) => {
				data.forEach( (val) => {
					val.objType = 'Contact';
				} );
				return data;
			} ));
			return Promise.all(promises).then( (results) => {
				let data = _.flatten(results);
				params.total(data.length);
				self.data = data;
				return data;
			} );
		}

	}

	onGroupSelected(item, model) {
		this.applyFilter();
	}


	applyFilter() {
		this.tableParams.page(1);
		this.tableParams.reload();
	}

}

export default angular.module('secutraqApp.privGroups')
.component('privGroups', {
	template: require('./privGroups.html'),
	controller: PrivGroupsComponent,
	controllerAs: '$ctrl'
})
.name;
