export class SiteDetailComponent {

    /*@ngInject*/
    constructor($timeout, $scope, $stateParams, siteService, genericMapService, $sanitize) {
        this.$timeout = $timeout;
        this.$scope = $scope;
        this.$stateParams = $stateParams;
		this.$sanitize = $sanitize;
        this.siteService = siteService;
        this.genericMapService = genericMapService;
    }

    $onInit() {
        let self = this;
        self.siteID = self.selectedSite._id;

        /**
         * Once again I'm storing leaflet-layers within an object.
         * Because we are now at SiteDetailComponent, this is pertaining to
         * storage of site-related layers.
         * self.layers[siteID]['layers'] --> The actual bounds of the site. Primary, secondary, etc
         * self.layers[siteID]['circles'] --> Placed on the edges to contain tooltip
         * self.layers[siteID]['marker'] --> Placed on the center of our site for when we zoom out
         *
         * @type {Object}
         */
        self.layers = {};


        self.$timeout(() => {

            self.map = self.genericMapService.generateMap(`${self.siteID}`,{
                geocoder:false,
                zoomControl:false
            });
            /**
             * The Leaflet layer that will contain only our sites
             */
            self.allSitesLayer = new L.layerGroup().addTo(self.map);

            /**
             * The leafletLayer that will contain the markers to display when we zoom
             * out far enough to remove allSitesLayer
             */
            self.allSitesMarkers = new L.layerGroup();

            self.siteCircleLayers = new L.layerGroup();

            /**
             * The leafletLayer that will contain our edge-circles used to
             * show tooltips for every edge's coordinates
             */
            self.allSitesCircles = new L.layerGroup().addTo(self.map);

            self.map.on('zoomend', ($event) => {
                let zoom = self.map.getZoom();
                if(zoom < 13 && self.map.hasLayer(self.allSitesLayer)) {
                    self.map.removeLayer(self.allSitesLayer);
                    self.map.removeLayer(self.allSitesCircles);
                    self.map.removeLayer(self.siteCircleLayers);
                    self.map.addLayer(self.allSitesMarkers);
                } else if(zoom >= 13 && self.map.hasLayer(self.allSitesMarkers)) {
                    self.map.removeLayer(self.allSitesMarkers);
                    self.map.addLayer(self.allSitesLayer);
                    self.map.addLayer(self.allSitesCircles);
                    self.map.addLayer(self.siteCircleLayers);

                    self.allSitesLayer.eachLayer((layer) => {
                        let mapBounds = self.map.getBounds();
                        if(typeof layer.getBounds === "function" && layer.options && layer.options.id == self.siteID) {
                            let latlng = layer.getBounds().getCenter();
                            //If the site is on screen, we open its popup
                            if(mapBounds.contains(latlng)) {
                                //For some reason when the component gets loaded, this code executes, as if though
                                //self.allSitesMarkers is then added to the map
                                layer.openPopup();
                            }
                        }
                    });
                }
            });


            new L.Control.Zoom({position: 'bottomright'}).addTo(self.map);

            self.map.setView([-26.6145, 27.0950], 5);

            self.mapMarker= self.genericMapService.getSpecifiedMarkerSync('site');

            /**
             * This object comes from SiteListComponent (we're binding to it).
             * Is related to displaying of coordinates of on map. Go read
             * the comment there for more info on this variable
             */
            self.toggledCoordinates[self.siteID] = true;

            self.readyMap();
        });

        /**
         * Listening for SiteListComponent informing me of toggle-button pressed
         */
        self.$scope.$on('toggleCoordinates', ($event, data) => {
            let siteID = data.siteID;
            if(siteID === self.siteID) {
                self.handleToggleCoordinates(siteID);
            }
        });

        self.$scope.$on('siteEdited', ($event, data) => {
            let siteDefinition = data;
            self.removeSite(siteDefinition);
            self.drawSite(siteDefinition);
            self.drawPointCircles(siteDefinition);
        });
    }

    /**
     * The LatLng button has been pressed in this site's accordion-group. We now want
     * to handle the display.
     * If true, we simply want to number our edges, "1", "2", "3",.. and show
     * its longtitude/latitude table-counterpart
     */
    handleToggleCoordinates(siteID) {
        let self = this;
        self.toggledCoordinates[siteID] = !self.toggledCoordinates[siteID];
        let shouldShow = self.toggledCoordinates[siteID];
        if(shouldShow) {
            _.forEach(self.layers[siteID].circles, (circle) => {
                if(circle.isCircle) {
                    self.genericMapService.removeClassFromLayer(circle._tooltip._container, 'tooltip-faded');
                }
            });
        } else {
            _.forEach(self.layers[siteID].circles, (circle) => {
                if(circle.isCircle) {
                    self.genericMapService.addClassToLayer(circle._tooltip._container, 'tooltip-faded');
                }
            });
        }
    }

    readyMap() {
        let self = this;
        self.map.invalidateSize();
        self.drawAllSitesOnMap();
        self.drawPointCircles(self.selectedSite);
        self.setMapView(self.selectedSite);
    }

    /**
     * On each point of the polygon we draw a very small circle and apply a tooltip to it.
     * We make use of this when showing coordinates
     * @see onToggleCoordinates()
     * @see handleToggleCoordinates()
     * @see readyMap()
     */
    drawPointCircles(site) {
        let self = this;
        let point = 1;
        _.forEach(site.locs[0].loc, (latlng) => {
			let tooltip = L.tooltip({direction:"center", permanent: true, className:'tiny-tooltip'}).setContent(`${point}`);
            let circle = L.circle(latlng, {radius:1}).addTo(self.allSitesCircles);
            circle.bindTooltip(tooltip);
            circle.isCircle = true;
            self.layers[site._id].circles[point] = circle;

            // self.$timeout(() => {
            //     if(tooltip._container){
            //         self.genericMapService.addClassToLayer(tooltip._container, 'tiny-tooltip');
            //     }
            // })
            point ++;
        });
    }

    /**
     * Gets called during initialization. Retrieves all sites and draws them onto the map.
     */
    drawAllSitesOnMap() {
        let self = this;
        _.forEach(self.sites, (site) => {
            self.drawSite(site);
        });
    }

    /**
     * We're calling this from two scenarios, namely
     *  1) On initial load we draw all the sites in our list of sites
     *  2) When a site has been edited or created, we are called to redraw/draw it
     */
    drawSite(site) {
        let self = this;
        let siteID = site._id;
        let primaryIsDrawn = false;
        self.layers[siteID] = {layers: {}, marker: {}, circles: {}};
        // self.drawSiteCircle(site);

        _.forEach(site.locs, (shape) => {

            if(shape.loc.length !== 0) {
                if(shape.loc.length === 1) {
                    //If the site only has one location, therefore it is a ..dot.
                    //This won't be able to happen and I should remove this
                    //TODO: The above comment
                    self.placeMarkerAtCoordinates(shape.loc[0], site);
                } else {
                    let polygon = self.drawPolygonFromBounds(shape.loc, site, shape.color, shape.weight);

                    //Only giving the center-marker to the primary shape
                    if(!primaryIsDrawn) {
                        let center = polygon.getBounds().getCenter();
                        self.layers[siteID].marker = L.marker(center, {icon: self.mapMarker}).addTo(self.allSitesMarkers);
                        let centerMarker = self.layers[siteID].marker;
						let name = self.$sanitize(site.name);
                        let popup = L.popup().setContent(`<h4>${name}</h4>`);
                        centerMarker.bindPopup(popup);

                        primaryIsDrawn = true;
                    }
                }
            }
        });


        //adding the notes/labels
        _.forEach(site.notes, (note) => {
            let marker = L.marker(note.loc, {opacity: 0, draggable: false, icon: self.mapMarker});
            marker.bindTooltip(note.content, {direction: 'center', permanent: true, className: 'site-data-label', offset: [10,-5]}).addTo(self.allSitesLayer);
            //Have to put this in a timeout, otherwise when Leaflet tries to add the class, it gets a classlist of undefined
            self.$timeout(() => {
                L.DomUtil.addClass(marker._icon, 'leaflet-note-class');
            });
        });
    }

    drawSiteCircle(site) {
        let self = this;
        if(!site || !site.centerPoint || !site.radius) {
            return;
        }
        let id = site._id;
        let drawingColor = '#9B1A1F';
        let drawingWeight = 3;
        // TODO: color  from siteDefinition
        let circle = L.circle(L.latLng(site.centerPoint.coordinates[1],site.centerPoint.coordinates[0]), {radius: site.radius,color: drawingColor, weight: drawingWeight}).addTo(self.siteCircleLayers);
        site.locs.forEach(() => {
            circle.bringToBack();
        });
        return circle;
    }

    /**
     * This function is being called from @see drawSite()
     * when it receives a site to draw
     */
    drawPolygonFromBounds(bounds, site, color, weight) {
        let self = this;
        let id = site._id;
        let drawingColor = color ? color : '#2c09DE';
        let drawingWeight = weight ? weight : 6;
        // TODO: color  from siteDefinition
        self.layers[id].layers[id] = L.polygon(bounds, {color: drawingColor, weight: drawingWeight, id}).addTo(self.allSitesLayer);

        let polygon = self.layers[id].layers[id];
        let popup = L.popup().setContent(`<h4>${self.$sanitize(site.name)}</h4>`);
        polygon.bindPopup(popup);

        return polygon;
    }

    /**
     * We currently calling this function when a site has been
     * updated --> @see $on('drawSiteEdited')
     */
    removeSite(site) {
        let self = this;
        let id = site._id;
        let siteLayers = self.layers[id].layers;

        _.forEach(siteLayers, (layer) => {
            if(self.allSitesLayer.hasLayer(layer)) {
                self.allSitesLayer.removeLayer(layer);
            }
        });

        _.forEach(self.layers[id].circles, (layer) => {
            if(self.allSitesCircles.hasLayer(layer)) {
                layer.unbindTooltip();
                self.allSitesLayer.removeLayer(layer);
            }
        });

        //TODO: be able to remove this if
        if(Object.keys(self.layers[id].marker).length !== 0) {
            if(self.allSitesMarkers.hasLayer(self.layers[id].marker)) {
                self.allSitesMarkers.removeLayer(self.layers[id].marker);
            }
        }
        delete self.layers[id];
    }

    /**
     * Our map in SiteDetailComponent loads all the sites, but focuses our screen
     * on the selected one
     * @param {[type]} selectedSite [description]
     */
    setMapView(selectedSite) {
        let self = this;
        let bounds = self.getBoundsFromLoc(selectedSite.locs[0].loc);
        if(bounds.length !== 0) {
            if(bounds.length === 1) {
                self.map.setView(bounds[0], 15);
            } else {
                self.map.fitBounds(bounds, {padding: [50, 50], maxZoom: 17});
            }
        }
    }

    getBoundsFromLoc(loc) {
        let self = this;
        let bounds = [];
        _.forEach(loc, (point) => {
            let temp = L.latLng(point.lat, point.lng);
            bounds.push(temp);
        });
        return bounds;
    }

    /**
     * This function should be removed soon. It's purpose was to cater for sites that
     * are defined to only be a single lat-lng coordinate.
     */
    placeMarkerAtCoordinates(coordinates, site) {
        let self = this;
        self.layers[site._id].marker = L.marker(coordinates, {icon: self.mapMarker}).addTo(self.map);
        let marker = self.layers[site._id].marker;
        let popup = L.popup().setContent(`<h4>${self.$sanitize(site.name)}</h4>`);
        marker.bindPopup(popup);
    }

    doLog() {
        let self = this;
		console.debug(this);
    }

}
export default angular.module('secutraqApp.sites')
.component('siteDetail', {
    template: require('./site-detail.component.html'),
    controller: SiteDetailComponent,
    controllerAs: "$ctrl",
    bindings: {
        sites: '=',
        selectedSite: '<',
        toggledCoordinates: '='
    }
});
