import angular from 'angular';
import uiRouter from 'angular-ui-router';
import uiBootstrap from 'angular-ui-bootstrap';

import routes from './units.routes';
import Restangular from 'restangular';
import socket from '../../components/socket/socket.service';
import uiSelect from 'ui-select';
import uiGrid from 'angular-ui-grid';
import ngSanitize from 'angular-sanitize';
import NgTable from 'ng-table/bundles/ng-table';

export default angular.module('secutraqApp.units', [uiRouter, uiBootstrap, Restangular,'btford.socket-io',
                                                      uiSelect, 'ngSanitize', 'ngTable', 'ui.grid'])
  .config(routes)
  .name;

require('./units-list/units.list.component');
require('./units-list/new-unit/new-unit-modal.component');
require('./units-list/change-unit-account/change-unit-account-modal.component');
require('./units-list/minimap-modal/minimap-modal.component');
// require('./units.detail.component');
// require('./units.detail.chart.component');
// require('./units.detail.charts.component');
// require('./units.detail.clients.component');
// require('./units.detail.manage.component');
// require('./units.detail.heartbeats.component');
