export class ManageSOPTagsComponent {
    /*@ngInject*/


	/*@ngInject*/
	constructor($stateParams, Restangular) {
		this.$stateParams = $stateParams;
		this.Restangular = Restangular;

		this.availableIds = ['iButton'];
		this.valid = true;
	}

	$onInit() {
		let self = this;
		self.account = self.resolve.account;
		self.account.sopClassificationTags = self.formatTags(self.account.sopClassificationTags);
        self.$uibModalInstance = self.modalInstance;
		// Add alert data to controller
	}

	formatTags(tags) {
		let self = this;
		let tempTags = [];
		tags.forEach((tag) => {
			tempTags.push({val:tag});
		});
		return tempTags;
	}

	// Setup all the models for display
	// Modal accept: send resulting object back to promise in parent controller
	ok() {
		let self = this;
		// _.forEach(result.ids,(id)=>{
		// 	id.id = new Buffer.from(id.id,'hex');
		// })
		self.account.sopClassificationTags = _.map(self.account.sopClassificationTags,'val');
		self.$uibModalInstance.close(self.account);
	}


	addTag() {
		let self = this;
		if(!self.account.sopClassificationTags) {
			self.account.sopClassificationTags = [];
		}
		self.account.sopClassificationTags.push({val:''});
	}

	removeTag(index) {
		let self = this;
		self.account.sopClassificationTags.splice(index,1);
	}
    log() {
        console.debug(this);
    }

	// Modal cancelled: either cancel pressed or clicked off screen
	cancel() {
		this.$uibModalInstance.dismiss('Cancel pressed');
	}

}
export default angular.module('secutraqApp.sops')
.component('manageSOPTags', {
    template: require('./manage-sop-tags.html'),
    controller: ManageSOPTagsComponent,
    controllerAs: "$ctrl",
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
})
.name;
