'use strict';

export default function routes($stateProvider) {
	'ngInject';

	$stateProvider.state('main', {
		url: '^/:accountID',
		abstract: true,
		params: {
			accountID: {
				dynamic: true
			}
		},
		views: {
			root: {
				template: '<main></main>'
			}
		},
	});
}
