'use strict';

const __webpack_nonce__ = window.__CSP_NONCE;
import angular from 'angular';
import ngAnimate from 'angular-animate';
import ngCookies from 'angular-cookies';
import ngResource from 'angular-resource';
import ngSanitize from 'angular-sanitize';
import 'angular-socket-io';
import uiGrid from 'angular-ui-grid';
import angulartics from 'angulartics';
import angularticsGA from 'angulartics-google-analytics';

import uiRouter from 'angular-ui-router';
import 'angular-ui-router/lib/legacy/stateEvents';
import uiBootstrap from 'angular-ui-bootstrap';
import uiSelect from 'ui-select';
import ngMessages from 'angular-messages';
import ngValidationMatch from 'angular-validation-match';
import uiValidate from 'angular-ui-validate';

import Restangular from 'restangular';
// import ngTable from 'ng-table';
import NgTable from 'ng-table/bundles/ng-table';
import ngDragDrop from 'angular-dragdrop';
import ngTouch from 'angular-touch';
import angularFilter from 'angular-filter';
import angularGridster from 'angular-gridster';
import carousel from 'angular-carousel';
import ngclipboard from 'ngclipboard';
import httpSrc from 'angular-img-http-src';

import MsalAngular from "@azure/msal-angularjs";
import 'jquery';

//jquery-ui-bundle was necessary to save me from problems I've experienced with angular-dragdrop
import 'jquery-ui-bundle';
import 'jquery-ui-touch-punch';

import 'interactjs';

import {
	routeConfig
} from './app.config';

import smartAdminConfig from './smartadmin.config';
window.appConfig = smartAdminConfig;

import _Auth from '../components/auth/auth.module';
import admin from './admin';
import navbar from '../components/navbar/navbar.component';
import footer from '../components/footer/footer.component';
import header from '../components/header/header.component';
import toggleSwitch from '../components/toggle-switch/toggle-switch.component';
import totp from '../components/totp/totp.component';
import eventsTimeline from '../components/vis-events-timeline/vis-events-timeline.component';
import PTTPanel from '../components/ptt-panel/ptt-panel.component';
import PasswordStrengthMeter from '../components/password-strength-meter/password-strength-meter.component';
import LiveStreamContainer from '../components/live-stream/container/live-stream-container.component';
import LiveStreamPanel from '../components/live-stream/panel/live-stream-panel.component';
import DMSTerminal from '../components/dms-terminal/dms-terminal.component';
import AudioElement from '../components/audio-element/audio-element.component';
import main from './main/main.component';
import account from './account';
import constants from './app.constants';
import util from '../components/util/util.module';
import socket from '../components/socket/socket.service';
import consent from '../components/consent/consent.component';
import smartAdmin from './_common/module';
import Dashboard from './dashboard';
import Contacts from './contacts';
import Routes from './routes';
import Sites from './sites';
import Groups from './groups';
import Events from './events';
import PhotoAnalytics from './photoAnalytics';
import PrivGroups from './privGroups';
// import ImageCollection from './imageCollection';
// import FaceCollection from './faceCollection';
import Reports from './reports';
import Scheduling from './scheduling';
import Sops from './sops';
import Units from './units';
// import Configure from './configure';
import Settings from './settings';
import FieldReports from './fieldReports';
import './app.scss';
import ngIdle from 'ng-idle';
import ngStorage from 'ngstorage';
import angularMoment from 'angular-moment';
import d3 from 'd3';
import c3 from 'c3';
import angularSpinner from 'angular-spinner';
import leaflet from 'leaflet';
import leafletPolylineDecorator from 'leaflet-polylinedecorator';
// import mapboxjs from 'mapbox.js';
// import leafletGeocoderMapzen from 'leaflet-geocoder-mapzen';
import leafletToolbar from 'leaflet-toolbar/dist/leaflet.toolbar.js';
// import leafletRMachine from 'leaflet-routing-machine';
// import lrmMapzen from 'lrm-mapzen/dist/lrm-mapzen.min.js';
import leafletBasemaps from 'leaflet-basemaps';
import markerCluster from 'leaflet.markercluster';
import markerClusterLayerSupport from 'leaflet.markercluster.layersupport';
import markerClusterFreezable from 'leaflet.markercluster.freezable';
import gridLayerGoogleMutant from 'leaflet.gridlayer.googlemutant';
import tileLayerFallback from 'leaflet.tilelayer.fallback';
import leafletEditable from 'leaflet-editable';
import leafletPathDrag from 'leaflet.path.drag';
import leafletGeocoder from 'leaflet-control-geocoder';
// import leafletVectorGrid from 'leaflet.vectorgrid';
import leafletMovingMarker from 'Leaflet-MovingMaker/MovingMarker.js';
// import leafletImage from 'leaflet-image/leaflet-image.js';
import slick from 'slick-carousel/slick/slick';
import angularSlick from 'angular-slick-carousel/dist/angular-slick.min.js';
// import c3Angular from 'c3-angularjs';
import leafletDraw from 'leaflet-draw';
import leafletPIP from '@mapbox/leaflet-pip';
import directives from './shared/directives';
import angularConfirm from 'angular-confirm1/dist/angular-confirm.min.js';
import toastr from 'angular-toastr';
import angularBootstrapCalender from 'angular-bootstrap-calendar';
import uuID from 'angular-uuid';
import vTabs from 'v-tabs';
import clockpicker from 'clockpicker/dist/bootstrap-clockpicker.min.js';
//import uiCalendar from 'angular-ui-calendar';
//import fullcalendar from 'fullcalendar';
import chart from 'chart.js';
import chartjs from 'angular-chart.js';
import angularXEditable from 'angular-xeditable';
import tinycolor from 'tinycolor2/dist/tinycolor-min.js';
import angularjsColorPicker from 'angularjs-color-picker/dist/angularjs-color-picker.min.js';
import angularMomentPicker from 'angular-moment-picker';

let tokenCallback = function(errorDesc, token, error, tokenType) {
};

require('cookieconsent');

const cc = window.cookieconsent.initialise({
    palette: {
        popup: {
          background: "#252e39"
        },
        button: {
          background: "#14a7d0"
        }
      },
      content: {
        href: "/assets/policy/privacy.html"
      }
});

angular.module('secutraqApp', [
		ngCookies,
		ngResource,
		ngAnimate,
		'ngSanitize',
		'btford.socket-io',
		uiRouter,
		'ui.router.state.events',
		uiBootstrap,
		uiSelect,
		Restangular,
		'ui.grid',
		'angulartics',
		angularticsGA,
		_Auth,
		account,
		admin,
		Dashboard,
		Contacts,
		Routes,
    Sites,
	Groups,
	Events,
    Reports,
	PhotoAnalytics,
	PrivGroups,
    // ImageCollection,
    // FaceCollection,
		Scheduling,
    "MsalAngular",
		Sops,
		Units,
		// Configure,
		Settings,
		FieldReports,
		uiValidate,
		'validation.match',
		//'ui.calendar',
		navbar, footer, header, main, constants, socket, util, eventsTimeline,toggleSwitch,
		PTTPanel,AudioElement,totp,LiveStreamPanel, DMSTerminal ,LiveStreamContainer, PasswordStrengthMeter,
		'SmartAdmin', 'SmartAdmin.Layout', 'SmartAdmin.UI', 'SmartAdmin.Forms',
		ngIdle, 'ngStorage', 'ngMessages', 'angularMoment', 'mwl.calendar', 'ngTable',
		'angularSpinner','chart.js','ngclipboard','xeditable','angular.img',
		'ngTouch', angularFilter, 'angular-carousel', 'ngDragDrop', 'slickCarousel',
		directives, 'cp.ngConfirm', 'toastr', 'angular-uuid', 'vTabs', 'gridster',
		'color.picker', 'moment-picker', consent
	])
	.config(routeConfig)
	.constant('APP_CONFIG', smartAdminConfig)
	.constant('moment', require('moment-timezone'))
	// .config(['calendarConfig', function(calendarConfig) {
	// 	// Use either moment or angular to format dates on the calendar. Default angular. Setting this will override any date formats you have already set.
	// 	calendarConfig.dateFormatter = 'moment';
	// 	// This will display all events on a month view even if they're not in the current month. Default false.
	// 	calendarConfig.displayAllMonthEvents = true;
	// 	// calendarConfig.templates.calendarHourList = require('./scheduling/dayView.html');
	// }])
	.run(function($rootScope, $location, Auth, $state, $stateParams, $templateCache) {
		'ngInject';
		// Redirect to login if route requires auth and you're not logged in

		$rootScope.$state = $state;
		$rootScope.$stateParams = $stateParams;
		/**
		 * Commented this out when trying to fix the double-load thingy.
		 */
		// $rootScope.$on('$stateChangeStart', function(event, next) {
		//   Auth.isLoggedIn(function(loggedIn) {
		//     if(next.authenticate && !loggedIn) {
		//       $location.path('/login');
		//     }
		//   });
		// });

	})
	.filter('readableBytes', function() { // TODO: Include trailing zeroes
		return function(input) {
			let precision = 1;
			if (input === 0) {
				return '0 bytes';
			}
			if (isNaN(parseFloat(input)) || !isFinite(input)) return '-';
			if (typeof precision === 'undefined') precision = 1;

			var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
				var number = Math.floor(Math.log(input) / Math.log(1024));
				var val = (input / Math.pow(1024, Math.floor(number))).toFixed(precision);

			return `${val.match(/\.0*$/) ? val.substr(0, val.indexOf('.')) : val} ${units[number]}`;
		};
	})
    .config(['msalAuthenticationServiceProvider', function(msalProvider) {
        msalProvider.init({
            clientID: 'd66e1210-7293-44fe-b2af-c97991017777',
            tokenReceivedCallback: tokenCallback,
            consentScopes:[],
            optionalParams:{
                //TODO: Replace this with whatever redirect URI is currently in play
                redirectUri:`${window.location.protocol}//${window.location.hostname}${window.location.port !== '' ? `:${window.location.port}`: ''}/login`
            }
	});
    }]);


angular.element(document)
	.ready(() => {
		angular.bootstrap(document, ['secutraqApp'], {
			strictDi: true
		});
	});
