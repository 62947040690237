'use strict';
// @flow


export class UserSettingsComponent {
  Auth;

  /*@ngInject*/
  constructor(Auth) {
    this.Auth = Auth;
  }

  $onInit() {
      let self = this;
      self.hasUserPriv = false;
      self.Auth.hasRole('user').then((has) => {
         self.hasUserPriv = has;
     });
  }


}

export default angular.module('secutraqApp.settings')
  .component('usersettings', {
    template: require('./settings.user.html'),
    controller: UserSettingsComponent,
    controllerAs: '$ctrl'
  })
  .name;
