import PhotoModalController from "../../../photoAnalytics/imageCollection/modal.controller";
export class EventPhotosComponent {
	/*@ngInject*/
	constructor(
		photoService,
		$scope,
		$timeout,
		eventCarouselService,
		$uibModal
	) {
		this.photoService = photoService;
		this.eventCarouselService = eventCarouselService;
		this.$uibModal = $uibModal;
		this.$scope = $scope;
		this.photos = [];
		//this.showSlick = false;
		this.loading = false;
		this.$timeout = $timeout;
		this.currentImageIndex = 0;
		this.photosPerSlide = 2;
	}

	$onInit() {
		this.$scope.$watch(
			() => this.event,
			(newVal, oldVal) => {
				let photoIds = this.getRelevantPhotoIds(newVal);
				if (!_.isEqual(_.map(this.photos, "_id"), photoIds)) {
					this.updateEventPhotos(photoIds);
				}
			}
		);
	}

	getRelevantPhotoIds(event) {
		if (!event) return [];
		if (event.eventType == "Recognition") {
			if (event.metaData && event.metaData.photoId) {
				return [event.metaData.photoId];
			} else {
				return [];
			}
		}
		if (event.eventType == "LPR Recognition") {
			if (
				event.metaData &&
				event.metaData.plate &&
				event.metaData.plate.lastActiveSnap &&
				event.metaData.plate.lastActiveSnap.id
			) {
				return [event.metaData.plate.lastActiveSnap.id];
			} else {
				return [];
			}
		}
		return event.photos || [];
	}

	updateEventPhotos(photoIds) {
		let self = this;
		//this.showSlick = false;
		this.loading = true;
		let photoPromises = Promise.all(
			_.map(photoIds, (photoId) =>
				this.photoService.getPhotoByID(photoId)
			)
		);
		return photoPromises
			.then((photos) => {
				this.photos = photos;
				self.loading = false;
			})
			.catch((err) => {
				this.loading = false;
				console.error(err);
			});
	}

	openLink(id) {
		let self = this;
		self.photoService
			.getPhotoByID(id)
			.then((photo) => {
				return self.openFootage(photo);
			})
			.catch((err) => console.error(err));
	}

	openFootage(snapshot) {
		let self = this;
		if (
			typeof self.eventCarouselService?.closeCarouselWidget === "function"
		) {
			self.eventCarouselService.closeCarouselWidget();
		}
		return self.$uibModal
			.open({
				animation: true,
				backdrop: "static",
				keyboard: true,
				template: require("../../../photoAnalytics/imageCollection/modal.html"),
				controller: PhotoModalController,
				controllerAs: "$ctrl",
				size: "lg",
				resolve: {
					snapshot() {
						return snapshot;
					},
					snapPlaceholder() {
						return self.eventCarouselMetaData?.footages || [];
					},
				},
			})
			.result.then(function (result) {
				if (result && result.newSnap) {
					return self.openFootage(result.newSnap);
				}
			});
	}
}
export default angular
	.module("secutraqApp.dashboard")
	.component("appEventPhotos", {
		template: require("./event-photos.component.html"),
		controller: EventPhotosComponent,
		controllerAs: "$ctrl",
		bindings: {
			event: "=",
		},
	}).name;
