'use strict';
import uiBootstrap from 'angular-ui-bootstrap';
import fabricJS from 'fabric';

export default class OptionsController {
    $state;
    $http;
    $scope;
    $uibModal;
    $uibModalInstance;
    reference;
    moment;
    more;
    canvas;
    canvasContext;
    toastr;
    otherSightings;
    Auth;
    canEdit;
    smallFace;
    allTags;
    /*@ngInject*/

    constructor($uibModalInstance, moment, Auth, toastr, $http, smallFace, $state, $scope, $timeout, $uibModal, reference) {
        this.$uibModalInstance = $uibModalInstance;
        this.$state = $state;
        this.$timeout = $timeout;
        this.moment = moment;
        this.toastr = toastr;
        this.canEdit = false;
        this.Auth = Auth;
        this.$http = $http;
        this.$scope = $scope;
        this.otherSightings = [];
        this.more = true;
        this.$uibModal = $uibModal;
        this.reference = reference;
        this.hasPrivilege = Auth.hasPrivilegeSync;
        this.smallFace = smallFace;
    }

    doLog() {
		console.debug(this);
    }

    $onInit() {
        let self = this;
        //self.canEdit = self.Auth.hasPrivilegeSync("secuvue.references.edit");
        self.canEdit = true;
        self.$http.get('api/references/findTags').then(res => {
            self.allTags = res.data;
            self.$timeout(function() {
                self.drawCanvas();
            }, 0);
        });

        self.$http.get(`api/photos/snapFaces/${self.reference.faceId}`, {
            params: {timestamp: +self.moment().utc()}
        }).then(response => {
            if(response.data.length > 0) {
                if(response.data.length < 4) {
                    self.more = false;
                }
                //
                self.otherSightings = response.data;
                return self.otherSightings;
            } else {
                self.more = false;
            }
        });
    }

    //testSVGStuff() {
        //this.$http.get(`/api/references/testSVGStuff/${this.reference._id}`).then(response => {
            //this.reference.data = response;
        //});
    //}

    drawCanvas() {
        if(fabricJS.hasOwnProperty('fabric')) {
            let fabric = fabricJS.fabric;
        }
        let self = this;
        if(!self.canvas) {
            self.canvas = new fabric.Canvas('snapshotCanvas');
        }
        self.canvas.clear();
        self.canvas.containerClass = 'snapshot-wrapper';
		self.$http({url:self.reference.data,method:"GET",responseType:'arraybuffer'}).then( (res) => {
			let imageB64 = self.arrayBufferToBase64(res.data);
			let dataUrl = `data:image/jpg;base64,${imageB64}`;

			return dataUrl;
		} )
.then( (dataUrl) => {
			let image = new fabric.Image.fromURL(dataUrl, function(image) {
				image.setOptions({left: 0, top: 0, opacity: 1, width: self.reference.width, height: self.reference.height});
				self.canvas.setBackgroundImage(image, self.canvas.renderAll.bind(self.canvas), {
					originX: 'left',
					originY: 'top'
				});
				self.canvas.setWidth(image.width, {backstoreOnly: true});
				self.canvas.setHeight(image.height, {backstoreOnly: true});
				self.canvas.setWidth('100%', {cssOnly: true});
				self.canvas.setHeight('auto', {cssOnly: true});
				self.canvas.selection = false;

				let rect = new fabric.Rect({
					fill: 'rgba(0,0,0,0)',
					width: self.reference.boundingBox.Width * self.reference.width,
					height: self.reference.boundingBox.Height * self.reference.height,
					left: self.reference.boundingBox.Left * self.reference.width,
					top: self.reference.boundingBox.Top * self.reference.height,
					stroke: 'rgba(0,255,0, 0.5)',
					selectable: false,
					strokeWidth: 4
				});
				self.canvas.add(rect);
				rect.moveTo(30);
				rect.bringToFront();
				self.canvas.renderAll();
			});
        });
    }

    close() {
        let self = this;
        self.$uibModalInstance.dismiss();
    }

    openSnapshot(snap) {
        let self = this;
        self.$uibModalInstance.close({snap});
    }

    update() {
        let self = this;
        self.$uibModalInstance.close({reference: self.reference});
    }

    delete() {
        let self = this;
        let r = confirm("Are you sure you want to delete?");
        if(r == true) {
            self.$uibModalInstance.close({deleting: self.reference.faceId});
        }
    }

    loadMore() {
        let self = this;
        let timestamp = new Date(self.otherSightings[self.otherSightings.length-1].ts).getTime();
        self.$http.get(`api/photos/snapFaces/${self.reference.faceId}`, {
            params: {
                timestamp
            }
        }).then(response => {
            if(response.data.length > 0) {
                self.otherSightings = self.otherSightings.concat(response.data);
            }
            if(response.data.length < 4) {
                self.toastr.warning("There are no more photos to be loaded", {
                    preventOpenDuplicates: true
                });
                self.more = false;
            }
        });
    }

    removeTags(tags) {
        let label = " (create new tag)";
        tags.forEach((tag, index) => {
            if(tag.slice(-label.length) === label) {
                tags[index] = tag.slice(0, -label.length);
            }
        });
    }

	arrayBufferToBase64(buffer) {
		var binary = '';
		var bytes = new Uint8Array(buffer);
		var len = bytes.byteLength;
		for (var i = 0; i < len; i++) {
			binary += String.fromCharCode(bytes[i]);
		}
		return window.btoa(binary);
	}
}
