'use strict';
// @flow
import hasprivileges from 'hasprivileges';

class _User {
    _id: string = '';
    name: string = '';
    email: string = '';
    role: string = '';
    $promise = undefined;
}

class _Account {
    name: string = '';
    // accountID: string = '';
    accountName: string = '';
    privileges: Object = {};
    $promise = undefined;
}

export function AuthService($location, $http, $q, appConfig, Util, User, $state, $stateParams, Idle, Keepalive, $uibModal, $uibModalStack, $rootScope, $localStorage, Restangular,msalAuthenticationService) {
    'ngInject';

    let safeCb = Util.safeCb;
    let currentUser: _User = new _User();
    let currentAccount: _Account = new _Account();
    let userRoles = appConfig.userRoles || [];
	//this.Restangular = Restangular;

    let warning;
    let timedout;
    let started = false;

	if ($location.path().includes('logout') || $location.path().includes('reset')) {
		//NOP
	}else{
		currentUser = User.get();
	}

    ///////
    let idleDuration = 5*60;
    //  How long before a warning-dialog should show? NOTE: This is a default value incase none gets specified in the DB
    //////

    ///////
    let timeoutDuration = 30;
    //  How many seconds before logged off. NOTE: The value for timeoutDuration gets stored here and not in the DB.
    //////

    //////
    let keepaliveInterval = 15*60;
    //  How regular tokens are refreshed NOTE: This is a default value incase none gets specified in the DB
    //////


    /**
    * Check if userRole is >= role
    * @param {String} userRole - role of current user
    * @param {String} role - role to check against
    */
    //var hasRole = function(userRole, role) {
        //return userRoles.indexOf(userRole) >= userRoles.indexOf(role);
    //};

    /** TODO: This method can possibly be structured more effectively.
    * [Sets the user's current active account]
    * @param {[type]} newCurrentAccount [description]
    */
    function setCurrentAccountFn(newCurrentAccount) {
		let oldAccount = currentAccount;
        if (newCurrentAccount) {
            currentAccount = findAccountById(currentUser,newCurrentAccount);
            $localStorage.currentAccount=currentAccount.ref;
            $localStorage.bounds = 'accountChanged';
        } else if(!!$stateParams.accountID && findAccountById(currentUser, $stateParams.accountID)) {
                currentAccount = findAccountById(currentUser, $stateParams.accountID);
                $localStorage.currentAccount = currentAccount.ref;
                $localStorage.bounds = 'accountChanged';
            } else if ($localStorage.currentAccount && findAccountById(currentUser, $localStorage.currentAccount)) {
                currentAccount = findAccountById(currentUser, $localStorage.currentAccount);
            } else if (currentUser.accounts && currentUser.accounts.length > 0) {
                currentAccount = currentUser.accounts[0];
                $localStorage.currentAccount=currentUser.accounts[0].ref;
                $localStorage.bounds = 'accountChanged';
				if(oldAccount && oldAccount.ref == currentAccount.ref && !$state.$current.abstract && $state.$current.name !== 'login' && $state.$current.name !== '') {
					$state.reload();
				}else{
					$state.go('main.dashboard', {accountID: currentAccount.ref}, {reload: true})
					.catch((err) => {
						console.error("State.go(.) catch error", err);
					});
				}
            }
        if(!$state.$current.abstract && $state.$current.name !== 'login' && $state.$current.name !== '') {

			if(oldAccount && oldAccount.ref == currentAccount.ref) {
				$state.reload();
			}else{
				$state.go('main.dashboard', {accountID: currentAccount.ref}, {reload: true})
				.catch((err) => {
					console.error("State.go(.) catch error", err);
				});
			}
        }
        Restangular.one('users','me').customPATCH({activeAccount:currentAccount.ref})
			.catch( (err) => {
				console.error('Active account update error : ', err);
			} );

        $rootScope.$emit("accountChanged");
    }

    /**
    * Finds and returns a specified account object of a user
    * @param  {Object} user  [The user from whom we retrieve the account]
    * @param  {String} accId [The ID of the account to retrieve]
    * @return {Object}       [The account-object after found]
    */
    function findAccountById(user,accId) {
        if(user) {
            let index = user.accounts.findIndex(account => account.ref == accId);
            return user.accounts[index];
        }
    }

    /**
    * Starts the Idle-service that watches for inactivity
    * @param  {Number} userIdle User's idleDuration
    * @param  {Number} userKeep User's Keepalive
    */
    function startIdleFn(userIdle, userKeep) {
        if(userIdle) {
            Idle.setIdle(userIdle);
        } else {
Idle.setIdle(idleDuration);
}
        if(userKeep) {
Keepalive.setInterval(userKeep);
} else {
Keepalive.setInterval(keepaliveInterval);
}
        Idle.setTimeout(timeoutDuration);
        Auth.closeModals();
        Idle.watch(); //also starts Keepalive
        started = true;
    }

    function stopIdle() {
        Auth.closeModals();
        Idle.unwatch();
        started = false;
    }

    var Auth = {
		/**
		 * Authenticate user and save token
		 *
		 * @param  {Object}   user     - login info
		 * @param  {Function} callback - function(error, user)
		 * @return {Promise}
		 */
		login({ email, password }, callback ? : Function) {
			return $http
				.post("/auth/local", {
					email,
					password,
				})
				.then((res) => {
					if (res.data.mfa) {
						return { mfaRequired: true, mfa: res.data.mfa };
					} else {
						currentUser = User.get();
						return currentUser.$promise;
					}
				})
				.then((data) => {
					// startIdleFn(user.idleDuration, user.tokenTTL/2 );
					if (data.mfaRequired) {
						return data;
					} else {
						let user = data;
						setCurrentAccountFn();
						safeCb(callback)(null, user);
						return user;
					}
				})
				.catch((err) => {
					console.error("login error at auth.service: ", err);
					Auth.logout();
					safeCb(callback)(err.data);
					return $q.reject(err.data);
				});
		},

		mfaLogin({ code }, callback ? : Function) {
			return $http
				.post("/auth/mfa", {
					code,
				})
				.then((res) => {
					if (res.data.mfa) {
						return { mfaRequired: true, mfa: res.data.mfa };
					} else {
						currentUser = User.get();
						return currentUser.$promise;
					}
				})
				.then((data) => {
					if (data.mfaRequired) {
						return data;
					} else {
						let user = data;
						setCurrentAccountFn();
						safeCb(callback)(null, user);
						return user;
					}
				})
				.catch((err) => {
					//Auth.logout();
					console.error('MFA Login error : ', err);
					safeCb(callback)(err.data);
					return $q.reject(err.data);
				});
		},
        loginStep2() {
            return function(postResponse) {
                //$cookies.put('token', postResponse.data.token);
                if(postResponse.data.mfa) {
                    return {mfaRequired:true,mfa:postResponse.data.mfa};
                }else{
                    currentUser = User.get();
                    return currentUser.$promise;
                }
            };
        },
        loginStep3() {
            return function(data) {
                setCurrentAccountFn();
                return data;
            };
        },
        azureLoginStep2() {
            return function(res) {
                return $http.post("/auth/azureAD/openid/returnSPA", {access_token:res}).then(response => {
                    return response;
                });
            };
        },
        /**
         * Function to handle Azure AD login
        */
        azureADLogin() {
            return msalAuthenticationService.loginPopup()
            .then(this.azureLoginStep2())
            .then(this.loginStep2())
            .then(this.loginStep3())
                .catch(err => {
                    console.error("Azure Login Error at auth.service: ", err);
					$rootScope.$emit("azureError", err);
                    Auth.logout();
                    return $q.reject(err);
                });
        },

		/**
		 * Delete access token and user info
		 */
		logout(callback ? : Function) {
			stopIdle();
			$http
				.post("/auth/logout", {
					_id: currentUser._id,
				})
				.then((res) => {
				})
				.catch((err) => {
					//Auth.logout();
					console.error('Logout error : ',err);
					//safeCb(callback)(err.data);
					//return $q.reject(err.data);
				});
			currentUser = new _User();
			currentAccount = new _Account();

			if ($state.current.name !== "login") {
				$state.go("login").catch((err) => {});
			}
		},

		/**
		 * Create a new user
		 *
		 * @param  {Object}   user     - user info
		 * @param  {Function} callback - function(error, user)
		 * @return {Promise}
		 */
		createUser(user, callback ? : Function) {
			return User.save(
				user,
				function(data) {
					currentUser = User.get();
					currentAccount.$promise = currentUser.$promise; //note -> The Secuvue had 'currentAcc'
					return safeCb(callback)(null, user);
				},
				function(err) {
					Auth.logout();
					return safeCb(callback)(err);
				}
			).$promise;
		},

		/**
		 * Change password
		 *
		 * @param  {String}   oldPassword
		 * @param  {String}   newPassword
		 * @param  {Function} callback    - function(error, user)
		 * @return {Promise}
		 */
		changePassword(oldPassword, newPassword, callback ? : Function) {
			return User.changePassword(
				{
					id: currentUser._id,
				},
				{
					oldPassword,
					newPassword,
				},
				function() {
					return safeCb(callback)(null);
				},
				function(err) {
					return safeCb(callback)(err);
				}
			).$promise;
		},

		/**
		 * Gets all available info on a user
		 *
		 * @param  {Function} [callback] - function(user)
		 * @return {Promise}
		 */
		getCurrentUser(callback ? : Function) {
			var value = _.get(currentUser, "$promise")
				? currentUser.$promise
				: currentUser;

			return $q.when(value).then(
				(user) => {
					safeCb(callback)(user);
					return user;
				},
				() => {
					safeCb(callback)({});
					return {};
				}
			);
		},

		/**
		 *
		 */
		getCurrentAccount(callback ? : Function) {
			var value = _.get(currentAccount, "$promise")
				? currentAccount.$promise
				: currentAccount;

			return $q.when(value).then(
				(user) => {
					//TODO: use localstorage/url state param...
					//TODO: Secure localstorage(store id, get privileges from mongo)..
					setCurrentAccountFn();

					safeCb(callback)(currentUser.accounts[0]);
					return currentAccount;
				},
				() => {
					safeCb(callback)({});
					return {};
				}
			);
		},

		/**
		 * Gets all available info on a user
		 *
		 * @return {Object}
		 */
		getCurrentUserSync() {
			return currentUser;
		},

		/**
		 * Check if a user is logged in
		 *
		 * @param  {Function} [callback] - function(is)
		 * @return {Promise}
		 */
		isLoggedIn(callback ? : Function) {
			return Auth.getCurrentUser(undefined).then((user) => {
				let is = false;
				if (user._id) {
					is = true;
					if (currentAccount.ref) {
						safeCb(callback)(is);
						return is;
					} else {
						return Auth.getCurrentAccount().then(
							(currentAccount) => {
								safeCb(callback)(is);
								return is;
							}
						);
					}
				} else {
					safeCb(callback)(is);
					return is;
				}
			});
		},

		/**
		 * Check if a user is logged in
		 *
		 * @return {Bool}
		 */
		isLoggedInSync() {
			return !!_.get(currentUser, "role");
		},

		/**
		 * Check if a user has a specified role or higher
		 *
		 * @param  {String}     role     - the role to check against
		 * @param  {Function} [callback] - function(has)
		 * @return {Promise}
		 */
		//hasRole(role, callback ? : Function) {
		//return Auth.getCurrentUser(undefined)
		//.then(user => {
		//let has = hasRole(_.get(user, 'role'), role);

		//safeCb(callback)(has);
		//return has;
		//});
		//},

		hasRole(path, test) {
			return Auth.getCurrentUser().then((user) => {
				return Auth.hasRoleSync(path, test);
			});
		},

		/**
		 * Check if a user has a specified privilege for a desired action
		 *
		 * @param  {String}     desiredPrivilege     - the privilege to check against
		 */
		hasPrivilege(path, test, callback ? : Function) {
			return Auth.getCurrentUser(undefined).then((user) => {
				let privArr = [];
				let has = false;
				if (currentAccount.ref) {
					if (typeof path === "string") {
						privArr.push(path);
					} else if (Array.isArray(path)) {
						privArr = path;
					}
					has = privArr.some((priv) => {
						return hasprivileges.hasPrivilegeFunc(
							priv,
							currentAccount.privileges,
							test
						);
					});
					safeCb(callback)(has);
					return has;
				} else {
					return Auth.getCurrentAccount().then((currAccount) => {
						if (typeof path === "string") {
							privArr.push(path);
						} else if (Array.isArray(path)) {
							privArr = path;
						}
						has = privArr.some((priv) => {
							return hasprivileges.hasPrivilegeFunc(
								priv,
								currAccount.privileges,
								test
							);
						});
						safeCb(callback)(has);
						return has;
					});
				}
			});
		},

		hasPrivilegeSync(path, test, filter) {
			return Auth.hasPrivilegeSyncAccount(
				path,
				currentAccount.ref,
				test,
				filter
			);
		},

		hasPrivilegeSyncAccount(path, accountId, test, filter) {
			let privArr = [];
			let has = false;
			if (typeof path === "string") {
				privArr.push(path);
			} else if (Array.isArray(path)) {
				privArr = path;
			}
			let account = _.find(currentUser.accounts, ["ref", accountId]);
			if (!account) {
				return false;
			}

			has = privArr.some((priv) => {
				return hasprivileges.hasPrivilegeFunc(
					priv,
					account.privileges,
					test,
					filter
				);
			});
			return has;
		},

		hasUserPrivilege(path, test, callback ? : Function) {
			return Auth.getCurrentUser(undefined).then((user) => {
				let has = hasprivileges.hasPrivilegeFunc(
					path,
					user.privileges,
					test
				);
				safeCb(callback)(has);
				return has;
			});
		},

		hasUserPrivilegeSync(path, test) {
			let has = hasprivileges.hasPrivilegeFunc(
				path,
				currentUser.privileges,
				test
			);

			return has;
		},

		/**
		 * Check if a user has a specified role
		 *
		 * @param  {String} path - the role to check against
		 * @param  {Function} test - custom check function
		 * @return {Bool}
		 */
		hasRoleSync(path, test, account) {
			let roleArr = [];
			let has = false;
			if (typeof path === "string") {
				roleArr.push(path);
			} else if (_.isArray(path)) {
				roleArr = path;
			}
			let testAccount = account || currentAccount;

			has = roleArr.some((role) => {
				return hasprivileges.hasPrivilegeFunc(
					role,
					testAccount.roles,
					test
				);
			});
			return has;
		},
		/**
		 * Check if a user is an admin
		 *   (synchronous|asynchronous)
		 *
		 * @param  {Function|*} callback - optional, function(is)
		 * @return {Bool|Promise}
		 */
		isAdmin(...args) {
			return Auth.hasRole(...Reflect.apply([].concat, ["admin"], args));
		},

		/**
		 * Check if a user has been verified
		 */
		isVerified() {
			return currentUser.verified === true;
		},

		/**
		 * Check if a user is an admin
		 *
		 * @return {Bool}
		 */
		isAdminSync() {
			// eslint-disable-next-line no-sync
			return Auth.hasRoleSync("admin");
		},

		/**
		 * Get auth token
		 *
		 * @return {String} - a token string used for authenticating
		 */
		getToken() {
            return '';
		},

		getCurrentAccountSync() {
			return currentAccount;
		},

		/**
		 * Refreshes the user's current token
		 */
		refreshToken() {
            //TODO: Acquire token from here, kick if failed?
            //msalAuthenticationService.acquireTokenSilent({scopes:['openid','offline_access']}).then((res,res2) => {
            //if(currentUser.azureADUser) {
                //msalAuthenticationService.acquireTokenSilent(["openid","offline_access"]).then((res,res2) => {
                //});
            //}
			return $http
				.post(
					"/api/users/refreshToken",
					{
						_id: currentUser._id,
					},
					{ headers: { "X-JS-ACCOUNT": currentAccount.ref } }
				)
				.then((res) => {
				})
				.catch((err) => {
					console.error("Error with refreshing token:", err.data);
				});
		},

		refreshUser() {
			currentUser = User.get();
			return currentUser.$promise;
		},


		saveRedirectState(state) {
			let self = this;
			self.redirectState = state;
		},

		redirectToSavedState() {
			let self = this;
			if (self.redirectState && self.redirectState.name) {
				$state.go(self.redirectState.name, self.redirectState.params);
				self.redirectState = undefined;
			} else {
				console.error("No saved state");
			}
		},

		closeModals() {
			if (warning) {
				warning.close();
				warning = null;
			}

			if (timedout) {
				timedout.close();
				timedout = null;
			}
		},
		startIdle: startIdleFn,
		stopIdle,
		setCurrentAccount: setCurrentAccountFn,
	};

$rootScope.$on('IdleStart', function() {
    Auth.closeModals();

    warning = $uibModal.open({
        template: require("./inactive/warning-dialog.html"),
        windowClass: 'modal-info',
        resolve : {
            data() {
                return timeoutDuration;
            }
        },
        controller($uibModalInstance, data) {
            this.data=data;
            this.countdown = 0;
        },
        controllerAs: "$ctrl"
    });
});

$rootScope.$on('Keepalive', function() {
    Auth.refreshToken();
});

$rootScope.$on('IdleEnd', function() {
    Auth.closeModals();
});

$rootScope.$on('IdleTimeout', function() {
    $uibModalStack.dismissAll();

    timedout = $uibModal.open({
        template: require("./inactive/timedout-dialog.html"),
        windowClass: 'modal-info',
        backdrop: 'static',
        controller($uibModalInstance) {
            this.expanded = false;
            this.close = function() {
                $uibModalInstance.close();
            };
            this.expand = function() {
                this.expanded = !this.expanded;
            };

        },
        controllerAs: "$ctrl"

    });
    timedout.result.then(result => {
        this.$uibModalInstance.close(result);
    });
    Auth.logout();
});

$rootScope.$on("msal:loginSuccess", function(token, payload) {
});

$rootScope.$on("msal:loginFailure", function(token, payload ) {
});

$rootScope.$on("msal:notAuthorized", function(ev, rejection, forResource) {
});

$rootScope.$on("msal:acquireTokenFailure", function(ev, errorDesc, error) {

});

$rootScope.$on("msal:acquireTokenSuccess", function(ev, tokenOut) {
});

return Auth;
}
