export class CurrentEventService {
    /*@ngInject*/

    //NOTE the currentEventService revolves not around all events, but only the one selected by the user.
    //NOTE Needless to say, the currentEventService would employ eventService as well

    constructor($timeout, eventService, Auth, accountService, siteService, $ngConfirm) {
        this.$timeout = $timeout;
        this.eventService = eventService;
        this.accountService = accountService;
        this.siteService = siteService;
        this.currentEvent;
        this.Auth = Auth;
        this.$ngConfirm = $ngConfirm;


        this.metaData = {};
        ///////////////////////////////////////////////////
        //           this.metaData                       //
        // showCurrentEvent  --> SelectedEventComponent  //
        ///////////////////////////////////////////////////

    }


    $onDestroy() {
        let self = this;
        self.clearCurrentEvent();
    }

    getCurrentEvent() {
        let self = this;
        return new Promise((resolve, reject) => {
            resolve(self.currentEvent);
        });
    }

    /**
     * I am not currently using this, nor do I understand why I chose this name
     */
    getShowStatus() {
        let self = this;
        return self.showCurrentEvent;
    }

    /**
     * I am not currently using this, nor do I understand why I chose this name
     */
    setShowStatus(toShow) {
        let self = this;
        self.showCurrentEvent = toShow;
    }

    getMetaData() {
        let self = this;
        return new Promise((resolve, reject) => {
            resolve(self.metaData);
        });
    }

    setEventStack(eventStack) {
        let self = this;
        self.eventStack = eventStack;
    }

    /**
    * currentEvent gets set when a user VIEWS it.
    * NOTE: We do self.currentEvent.viewed = true; but this must be uploaded to server.
    * TODO: Consider the best way of going about with the above.
    */
    setCurrentEvent(currentEvent) {
        let self = this;
        if(self.metaData.currentEvent._id === undefined) { //there isn't a currentEvent active
            if(!currentEvent.activeUser && currentEvent.unit) {
                let promise = new Promise((resolve,reject) => {

                    if(!currentEvent.sop) {
                        self.getSOP(currentEvent).then((sop) => {
                            resolve(sop);
                        });
                    }else{
                        resolve();
                    }
                });
                let tempCurrentEvent = _.cloneDeep(currentEvent);
                if(tempCurrentEvent.status === 'UNSEEN' || tempCurrentEvent.status === 'SEEN') {
                    tempCurrentEvent.status = 'ASSIGNED';
                }
                return promise.then((sop) => {
                    if(sop) {
                        tempCurrentEvent.sop = sop;
                    }
                    return self.eventService.assignEventAndSaveSOP(tempCurrentEvent,self.Auth.getCurrentUserSync()).then((response) => {
                        return self.setMetaData(response);
                    });
                });
            }else{

                return Promise.resolve(self.setMetaData(currentEvent));
            }
        } else { //there is another currentEvent active
            if(self.metaData.currentEvent._id === currentEvent._id) { //this is the same event
                return self.clearCurrentEvent().then(() => {
                    return true;
                }); //so we close it
            } else { //this is a new event, but the previous one is still open
                return self.clearCurrentEvent().then(() => {
                    return self.setCurrentEvent(currentEvent);
                }); //we close the previous event
            }
        }
    }

    getSOP(event) {
        let self = this;
        let promisses = [];
        promisses.push(self.accountService.getCurrentAccountSOPs().then((accountSOPs) => {
            //TODO: Check if event has site and get site sops
            return accountSOPs;
        }));
        if(event.site) {
            promisses.push(self.siteService.getSOPs(event.site).then((siteSOPs) => {
                return siteSOPs;
            }));
        }
        return Promise.all(promisses).then((allSOPs) => {
            let sop;
            let siteSOPId;
            if(allSOPs.length == 2) {
                if(allSOPs[1] && allSOPs[1].length > 0) {
                    let siteSpesificSOPs = _.filter(allSOPs[0],(accSOP) => {
                        let index = _.findIndex(allSOPs[1],(storedSiteSOPId) => {
                            return storedSiteSOPId == accSOP._id;
                        });
                        if(index > -1) {
                            return true;
                        }
                    });
                    let index = _.findIndex(siteSpesificSOPs, (o) => {
            			return o.eventType == event.eventType;
                    });
                    if(index > -1) {
                        siteSOPId = siteSpesificSOPs[index]._id;
                    }
                }
            }
            if(allSOPs[0] && allSOPs[0].length > 0) {
                let index = _.findIndex(allSOPs[0],(accSOP) => {
                    if(siteSOPId) {
                        return accSOP._id == siteSOPId;
                    }else{
                        return accSOP.default && accSOP.eventType == event.eventType;
                    }
                });
                if(index !== -1) {
                    sop = allSOPs[0][index];
                }else {
                    console.error(`No default ${event.eventType} SOPs in this account!`);
                }
            }else{
                console.error("No SOPs in this account!");
            }
            return sop;
        });
    }

    setMetaData(event) {
        let self = this;
        self.metaData.currentEvent= event; //we make this event the active event
        self.metaData.showCurrentEvent = true; //HTML ng-show looks to this
        self.metaData.currentEvent.viewed = true; //If an event is viewed, the text is no longer displayed bold
        return true;
    }

    /**
     * As the name implies, we're clearing the currentEvent.
     * NOTE that "selected-event.component.html" gets shown only when
     * a currentEvent has been set [showCurrentEvent = true].
     * (because we're not using a modal,
     * we're using an ng-if on the DIV.)
     */
    clearCurrentEvent(closeDueToAction) {
        let self = this;

        let unassingPromise = new Promise((resolve,reject) => {
			if(!self.metaData) {
                return resolve();
			}
			if(!self.metaData.currentEvent) {
                return resolve();
			}
            if(!self.metaData.currentEvent.active || closeDueToAction || self.metaData.currentEvent.activeUser != self.Auth.getCurrentUserSync()._id || self.metaData.currentEvent.reassignTo) {
                return resolve();
            }

            self.$ngConfirm(
                {
                    title: `<span style="margin:auto;">Unassign Event?</span>`,
                    theme: 'modern',
                    animation: 'top',
                    scope: self.$scope,
                    closeAnimation: 'bottom',
                    content: `Would you like to unassign this event and reassign it to the event stack?`,
                    escapeKey: false,
                    backgroundDismiss: false,
                    buttons: {
                        // long hand button definition
                        ok: {
                            text: "Unassign",
                            btnClass: 'btn-primary',
                            // keys: ['enter'], // will trigger when enter is pressed
                            action(scope, button) {
                                self.eventService.unassignEvent(self.metaData.currentEvent).then((results) => {
                                    resolve();
                                });
                            }
                        },
                        close: {
                            text: "Keep Assigned",
                            action(scope) {
                                resolve();
                            }
                        }
                    },
                }
            );
        });
        return unassingPromise.then(() => {

            self.metaData.showCurrentEvent = false;
            // self.activeSecondaryTab = '';
            return self.$timeout(() => {
                //As showCurrentEvent is false, we have a DIV that is fading away. Only when the div has faded completely
                //do we clear the data that the div was reflecting
                self.metaData.currentEvent = {};
                self.metaData.photoData = '';
                return null;
                // self.slickLoaded = false; //that slick would have to reload next time
            }, 200);
        });
    }

    /**
     * When a user VIEWS an event's details, that event becomes the current event.
     * We want to show all events pertaining to that unit in the table there
     * Calling this when a watch gets triggered at SelectedEventComponent
     * @return {Array} All the events' layer-objects
     */
    getCurrentEventRelatedEvents() {
        let self = this;
        let events = self.eventService.getEventRelatedEventsByUnit(self.metaData.currentEvent.unit._id);
        return events;
    }

    /**
     * When a user VIEWS a unit's details, that unit becomes the current selected unit.
     * We want to show all events pertaining to that unit in the table there
     * Calling this when a watch gets triggered at SelectedEventComponent
     * @return {Array} All the events' layer-objects for this unit
     */
    getSelectedUnitRelatedEvents() {
        let self = this;
        //NOTE because it's a unit in currentEvent, we pass its _id directly
        let events = self.eventService.getEventRelatedEventsByUnit(self.metaData.currentEvent._id);
        return events;
    }

    /**
     * When a user VIEWS an event's details, that event becomes the current event.
     * We want to show all events pertaining to that PERSON in the table there
     * Calling this when a watch gets triggered at SelectedEventComponent
     * @return {Array} All the events' layer-objects
     */
    getRelatedEventsByHumanAsset() {
        let self = this;
        let events = self.eventService.getEventRelatedEventsByHumanAsset(self.metaData.currentEvent.asset._id);
        return events;
    }

}
export default angular.module('secutraqApp.dashboard')
.service('currentEventService', CurrentEventService);
