import angular from 'angular';
import uiRouter from 'angular-ui-router';
import routes from './site.routes';
import uiBootstrap from 'angular-ui-bootstrap';
import NgTable from 'ng-table/bundles/ng-table';
import ngSanitize from 'angular-sanitize';


export default angular.module('secutraqApp.sites', [uiRouter, 'ngTable', uiBootstrap])
  .config(routes)
  .name;
require('./site.component');
require('./site-list/site-list.component');
require('./site-list/new-site/new-site.component');
require('./site-detail/site-detail.component');
