'use strict';

exports = module.exports = {
    // List of user roles
    userRoles: ['guest', 'user', 'admin'],
    eventTypes : [
        'Call Me', 'Photo', 'Video', 'Panic', 'Photo Request', 'Video Request',
        'Geo-fence Out', 'Geo-fence In',
        'No-comms', 'Low Battery', 'Login', 'Logout', 'Failed Login',
		//Shift Events
			//Site Related Events
		'Early Abscondment', 'Asset Not On Site', 'Late Arrival', 'No Arrival',
		'Asset On Site', 'Roaming Complete', 'Roaming Incomplete',
		// 'Waypoint Missed', 'Waypoint Reached',
			// Route Related Events
		'Waypoint Late',
		'Route Incomplete', 'Route Complete',
		'Route Not Started', 'Route Started Late', 'Route Started', 'Route Missed',
		'Recognition','LPR Recognition', 'LPR VOI',
		'Camera Down', 'HDD Fail',
		'Photo - Line Trip',
		'Photo - Motion',
		'Photo - External Trigger',
		'Photo - Scheduled',

		'Detection - Person',
		'Detection - Face',
		'Detection - Vehicle',
		'Detection - Pose Exception',

		//DMS Events
		"Detection - Eye Closed",
		"Detection - Distracted",
		"Detection - Yawn",
		"Detection - Smoking",
		"Detection - Phone Calling",
		"Detection - Missing Face",
		"Detection - Monitoring Shelter",
		"Detection - Look Down",
		"Detection - Over Speed",
		"Detection - Harsh Acceleration",
		"Detection - Harsh Deceleration",
		"Detection - Harsh Turning",
		"Detection - Crash",
		"Detection - Fatigue Driving",
		"Detection - Vibration",

		"Calibration Fail",
		"Power Removed",
		"Electronics Failed",
		"Fuel Stolen",
		"Fuel Detect Failed",
		"Temperature Detect Failed",
		"SD Failed"
	],
	apiRoles:[

		{
			name: 'Default',
			description: 'Allows creating events and photos with external api.',
			key : 'secuvue',
			privileges: {
				event:{
					ative:true,
					index:{
						active:true,
						filter:{
							op: "$in",
							field: "groups",
							data: ['API']
						}
					},
					edit:{
						active:true,
						filter:{
							op: "$in",
							field: "groups",
							data: ['API']
						}
					},
					create:{
						active:true,
						filter:{
							op: "$in",
							field: "groups",
							data: ['API']
						}
					},
				},
				photo:{
					ative:true,
					index:{
						active:true,
						filter:{
							op: "$in",
							field: "groups",
							data: ['API']
						}
					},
					create:{
						active:true,
						filter:{
							op: "$in",
							field: "groups",
							data: ['API']
						}
					},
				},
				unit:{
					active:true,
					edit:{
						active:true,
						filter:{
							op: "$in",
							field: "groups",
							data: ['API']
						}
					},
					create:{
						active:true,
						filter:{
							op: "$in",
							field: "groups",
							data: ['API']
						}
					},
					index:{
						active:true,
						filter:{
							op: "$in",
							field: "groups",
							data: ['API']
						}
					},
				},
				account:{
					active:true,
					index:{
						active:true,
					},
				},
				video:{
					active:true,
					edit:{
						active:true,
						filter:{
							op: "$in",
							field: "groups",
							data: ['API']
						}
					},
					create:{
						active:true,
						filter:{
							op: "$in",
							field: "groups",
							data: ['API']
						}
					},
					index:{
						active:true,
						filter:{
							op: "$in",
							field: "groups",
							data: ['API']
						}
					},
				},
			}
		},
		{
			name: 'Signal Tower Role',
			description: 'Allows viewing accounts, units, events and photos. Allows requesting photos.',
			key : 'signalTower',
			privileges: {
				event:{
					ative:true,
					index:{
						active:true,
					},
				},
				photo:{
					ative:true,
					index:{
						active:true,
					},
				},
				video:{
					ative:true,
					index:{
						active:true,
					},
				},
				unit:{
					active:true,
					request:true,
					index:{
						active:true,
						request: true
					},
				},
				account:{
					active:true,
					index:{
						active:true,
					},
				},
			}
		}
	],
	roles: [
		{
			name : 'Default',
			description: 'This is the default privileges including viewing rights of all data, closing events, reports, requesting photos.',
			key:'default',
			privileges:{
				account:{
					index:true
				},
				unit:{
					active:true,
					index:true,
					request: true
				},
				contact:{
					active:true,
					index:true,
				},
				site:{
					active:true,
					index:true,
				},
				event:{
					active:true,
					edit:true,
					index:true,
				},
				route:{
					active:true,
					index:true,
				},
				schedule:{
					active:true,
					index:true,
				},
				shift:{
					active:true,
					index:true,
				},
				task:{
					active:true,
					index:true,
				},
				location:{
					active:true,
					index:true,
				},
				user:{
					active:true,
					index:true
				},
				customEventType:{
					active:true,
					index:true
				},
				photo:{
					active:true,
					index:true
				},
				video:{
					active:true,
					index:true
				},
				report:{
					active:true,
					index:true
				}
			}
		},
		{
			name : 'Add/Edit Content',
			description: 'This grants a user privileges to edit or create content (sites/routes/units/contacts/schedules).',
			key:'edit',
			privileges:{
				unit:{
					active:true,
					edit:true,
					index:true,
				},
				contact:{
					active:true,
					edit:true,
					index:true,
				},
				site:{
					active:true,
					edit:true,
					index:true,
				},
				event:{
					active:true,
					edit:true,
					index:true,
				},
				route:{
					active:true,
					edit:true,
					index:true,
				},
				schedule:{
					active:true,
					edit:true,
					index:true,
				},
				shift:{
					active:true,
					edit:true,
					index:true,
				},
				task:{
					active:true,
					index:true,
				},
				customEventType:{
					active:true,
					edit:true,
					index:true,
				},
				sop:{
					active:true,
					edit:true,
					index:true,
				},
			}
		},
        {
            name:'Add/Edit Accounts',
            description:'Allow user to add or edit accounts and move units between accounts.',
			key:'account',
			privileges: {
				account:{
					active:true,
					edit:true,
					index:true,
				}
			}
        },
        {
            name:'Add/Edit Users',
            description:'Allow user to view, add and edit users and user privileges.',
			key:'user',
            privileges: {
				user:{
					active:true,
					edit:true,
					index:true
				}
			}
        },
        {
            name:'Photo Analytics',
            description:'Allow user to view and enable photo analytics.',
			key:'faceRecall',
			privileges: { faceRecall :true, lprDetect: true}
        },
		{
			name:'First Responders',
			description:'Allow user to add or edit first responder groups.',
			key:'notificationGroup',
			privileges: {notificationGroup:true}
		},
		{
			name:'Close All Events',
			description:'Allow user to close all events without an SOP.',
			key:'closeAll',
			privileges: {event:{closeAll:true}}
		},
		{
			name:'Manage API Keys',
			description:'Allow user to create, revoke and view Secutraq API Keys.',
			key:'apiKeys',
			privileges: {api:true}
		},
		{
			name:'PTT',
			description:'Allow calling users and devices from the browser.',
			key:'ptt',
			privileges:{ptt:true}
		},
		{
			name:'Unit Version Management',
			description:'Allow updating Secutraq Units.',
			key:'softwareUpdates',
			privileges:{
				unit:{
					softwareUpdates:true
				},
				appVersion:{
					active:true,
					index:true
				}
			}
		},
		{
			name:'Manage App Versions',
			description:'Allow user to upload and edit avaialble Secutraq Apps.',
			key:'manageAppVersion',
			adminOnly:true,
			privileges:{
				unit:{
					softwareUpdates:true
				},
				appVersion:{
					active:true,
					index:true,
					create:true
				}
			}
		},
		{
			name:'Manage/View Field Reports',
			description:'Allow user to edit and view field report templates and events.',
			key:'fieldReport',
			privileges:{
				fieldReport:{
					active:true,
					edit:true,
					index:true,
				},
			}
		},
		{
			name:'Filter Content',
			description:'Only allow users to view content in certain groups. This will automatically disable Add/Edit Content, Photo Analytics and First Responders.',
			key:'filter',
			removeRoles:['notificationGroup','faceRecall','edit','apiKeys'],
			privileges: {
				unit:{
					active:true,
					edit:{
						active:true,
						filter:{
							op: "$in",
							field: "groups",
						}
					},
					index:{
						active:true,
						filter:{
							op: "$in",
							field: "groups",
						}
					},
					request: {
						active:true,
						filter:{
							op: "$in",
							field: "groups",
						}
					},
				},
				contact:{
					active:true,
					edit:{
						active:true,
						filter:{
							op: "$in",
							field: "groups",
						}
					},
					index:{
						active:true,
						filter:{
							op: "$in",
							field: "groups",
						}
					}

				},
				site:{
					active:true,
					edit:{
						active:true,
						filter:{
							op: "$in",
							field: "groups",
						}
					},
					index:{
						active:true,
						filter:{
							op: "$in",
							field: "groups",
						}
					}

				},
				event:{
					active:true,
					edit:{
						active:true,
						filter:{
							op: "$in",
							field: "groups",
						}
					},
					closeAll:{
						active:true,
						filter:{
							op: "$in",
							field: "groups",
						}
					},
					index:{
						active:true,
						filter:{
							op: "$in",
							field: "groups",
						}
					}

				},
				route:{
					active:true,
					edit:{
						active:true,
						filter:{
							op: "$in",
							field: "groups",
						}
					},
					index:{
						active:true,
						filter:{
							op: "$in",
							field: "groups",
						}
					}

				},
				schedule:{
					active:true,
					edit:{
						active:true,
						filter:{
							op: "$in",
							field: "groups",
						}
					},
					index:{
						active:true,
						filter:{
							op: "$in",
							field: "groups",
						}
					}

				},
				shift:{
					active:true,
					edit:{
						active:true,
						filter:{
							op: "$in",
							field: "groups",
						}
					},
					index:{
						active:true,
						filter:{
							op: "$in",
							field: "groups",
						}
					}
				},
				task:{
					active:true,
					index:{
						active:true,
						filter:{
							op: "$eq",
							field: "user",
						}
					}
				},
				// TODO: Add groups to photos and videos <21-08-20, Liaan> //
				photo:{
					active:true,
					index:{
						active:true,
					}
				},
				video:{
					active:true,
					index:{
						active:true,
					}
				},
			}
		},
    ],
	privileges:{
		account: {
			active: true,
			index: true,
			edit: true,
		},
		faceRecall: {
			active:true,
		},
		lprDetect: {
			active:true,
		},
		user: {
			active:true,
			index:true,
			edit:true
		},
		report: {
			active: true,
			index: true
		},
		notificationGroup: true,
		unit:{
			active:true,
			edit:{
				active:true,
			},
			changeAccount:{
				active:true,
			},
			index:{
				active:true,
			},
			request: true,
			create:true,
			softwareUpdates:true
		},
		contact:{
			active:true,
			edit:{
				active:true,
			},
			index:{
				active:true,
			}
		},
		site:{
			active:true,
			edit:{
				active:true,
			},
			index:{
				active:true,
			},
			create:true
		},
		event:{
			active:true,
			edit:{
				active:true,
			},
			closeAll:{
				active:true,
			},
			index:{
				active:true,
			},
			create:{
				active:true
			}
		},
		route:{
			active:true,
			edit:{
				active:true,
			},
			index:{
				active:true,
			}
		},
		schedule:{
			active:true,
			edit:{
				active:true,
			},
			index:{
				active:true,
			}
		},
		shift:{
			active:true,
			edit:{
				active:true,
			},
			index:{
				active:true,
			}
		},
		task:{
			active:true,
			index:{
				active:true,
			}

		},
		api:{
			active:true,
			index:{
				active:true,
		}

		},
		photo:{
			active:true,
			edit:{
				active:true,
			},
			index:{
				active:true,
			},
			create:{
				active:true
			}
		},
		video:{
			active:true,
			edit:{
				active:true,
			},
			index:{
				active:true,
			},
			create:{
				active:true
			}
		},
		ptt:{
			active:true
		},
		appVersion:{
			active:true,
			index:true,
			create:true
		},
		fieldReport:{
			active:true,
			edit:{
				active:true,
			},
			index:{
				active:true,
			},
			create:{
				active:true
			}
		},
		customEventType:{
			active:true,
			edit:{
				active:true,
			},
			index:{
				active:true,
			},
			create:{
				active:true
			}
		},
		sop: {
			active: true,
			index: {
				active: true
			},
			edit: {
				active: true
			}
		}
	},
	adminUserId:'5afe80add8d949d44bfdbacc',
	policyUpdated:{
		privacy: new Date("2021-06-28T00:00:00.000Z"),
		disclaimer: new Date("2021-06-28T00:00:00.000Z"),
		terms: new Date("2021-06-28T00:00:00.000Z"),
	}
};
