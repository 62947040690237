export class DashboardComponent {


    /*@ngInject*/
    constructor(
		$scope, NgTableParams, $uibModal, $timeout,
        moment, mapService, eventCarouselService, currentEventService, eventService,
        $interval, $ngConfirm, unitService, Auth, toastr, appConfig, $localStorage,
		liveStreamService, $sanitize
	) {
        this.$scope = $scope;
        this.NgTableParams = NgTableParams;
        this.$uibModal = $uibModal;
        this.$timeout = $timeout;
        this.moment = moment;
        this.mapService = mapService;
        this.eventCarouselService = eventCarouselService;
        this.currentEventService = currentEventService;
        this.eventService = eventService;
        this.$interval = $interval;
		this.liveStreamService = liveStreamService;
        this.$ngConfirm = $ngConfirm;
        this.unitService = unitService;
        this.Auth = Auth;
		this.openEvents = [];
        this.toastr = toastr;
        this.appConfig = appConfig;
        this.$localStorage = $localStorage;
		this.eventsLoading = true;

		this.$sanitize = $sanitize;
        //constructor end
    }

    $onInit() {
        let self = this;

        self.currentEventMetaData;
        self.eventCarouselMetaData;
		self.openEventsCount;

		self.eventPage = 1;

        // **********************Information Pertaining to UNITS******************** //

        self.units = [];

        /**
         * We are literally using this object as a quick way to reference any unit if we know its ID.
         * Also, for example very useful for the popup that shows when we click a unit
         * @type {Object}
         */
        self.unitsData = {};


        /**
         * We set a selectedUnit if we select it in the 'units' tab or
         * when we click on "View Details" from the map popup
         * @type {Object}
         */
        self.selectedUnit = '';

        /**
         * NOTE -- I've replaced TabContentComponent's behavior by instead pointing to
         *         self.unitsData[unitID].online
         * We are using unitsOnlineStatus in TabContentComponent.
         * We want each event to indicate whether its unit is offline or not.
         * When a unit goes offline, we can either:
         *   1) Find each event that it has emitted with a _.find,
         *   2) Or we can simply reference this object.
         *
         * So if we have unit X, and this unit has 4 events. Unit X goes offline.
         * We want the 4 events to remain on the map, but they should now give some
         * indication that it's UNIT has went offline.
         * In order to do so, I have thought it best to have each event reference this
         * object with their unitID and have a ng-if attached to it
         * @type {Object}
         */
        self.unitsOnlineStatus = {};
        // **********************Information Pertaining to UNITS******************** //
        // **********************Information Pertaining to Events******************** //

        // self.currentEvent = {currentEvent: {}, showCurrentEvent : false};
        // self.showCurrentEvent = self.currentEvent.showCurrentEvent;
        // self.currentEventService.setActiveEvent(self.currentEvent)

        // self.showCurrentEvent = false;
        self.currentEventService.getMetaData().then((metaData) => {
            self.currentEventMetaData = metaData;
        });

        self.eventCarouselService.getMetaData().then((metaData) => {
            self.eventCarouselMetaData = metaData;
            self.eventCarouselMetaData.footages = {};
            self.eventCarouselMetaData.slickLoaded = false;
            self.eventCarouselMetaData.showSlickWidget = false;
        });

        self.mapMetaData = self.mapService.getMetaDataSync();


        // **********************Information Pertaining to Events******************** //
        // **********************Slick Carousel******************** //
        // self.footages = {};
        // self.slickLoaded = false;
        // self.showSlickWidget = false;
        // **********************Slick Carousel******************** //
        // **********************Photos******************** //
           //We're adding snapshots to this stack as each event
           //arrives
        self.photoStack = [];
        // **********************Photos******************** //
        // self.eventService.setEventStack(self.eventStack);
        //self.unitService.setUnits(self.units);

        self.watchUnitsPromise = self.$interval(() => {
            self.watchUnits();
        }, 10000);

		let user = self.Auth.getCurrentUserSync();
		let account = self.Auth.getCurrentAccountSync();

		if(self.$localStorage.openEventSettings && self.$localStorage.openEventSettings[user._id] && self.$localStorage.openEventSettings[user._id][account.ref] && self.$localStorage.openEventSettings[user._id][account.ref].groupBy) {
			self.mapMetaData.openEventsGroupBy = self.$localStorage.openEventSettings[user._id][account.ref].groupBy;
		}else{
			self.mapMetaData.openEventsGroupBy = 'eventType';
		}
		if(self.$localStorage.openEventSettings && self.$localStorage.openEventSettings[user._id] && self.$localStorage.openEventSettings[user._id][account.ref] && self.$localStorage.openEventSettings[user._id][account.ref].orderBy) {
			self.mapMetaData.openEventsOrderBy = self.$localStorage.openEventSettings[user._id][account.ref].orderBy;
		}else{
			self.mapMetaData.openEventsOrderBy = 'createdAt';
		}
		if(self.$localStorage.openEventSettings && self.$localStorage.openEventSettings[user._id] && self.$localStorage.openEventSettings[user._id][account.ref] && self.$localStorage.openEventSettings[user._id][account.ref].limit) {
			self.mapMetaData.openEventsLimit = self.$localStorage.openEventSettings[user._id][account.ref].limit;
		}else{
			self.mapMetaData.openEventsLimit = 200;
		}

		self.eventStack = {};
		self.reassignEventStack = {};
		self.closedEventStack = {};

		self.eventService.getEventDetails.then( (eventDetails) => {
			self.eventDetails = eventDetails;
			self.eventTypes = Object.keys(self.eventDetails);
			_.forEach(self.eventTypes, (eventType) => {
				self.eventStack[eventType] = [];
				self.reassignEventStack[eventType] = [];
				self.closedEventStack[eventType] = [];
			});
			self.$timeout(function() {
				$(window).trigger('resize.mapView');
				// window.dispatchEvent(new Event('resize'));
			},1000);

			self.hasFaceRecallPriv = false;
			if(self.Auth.hasPrivilegeSync('faceRecall')) {
				self.hasFaceRecallPriv = true;
			}

			//Set watch for lenght of nested events
			_.forEach(self.eventDetails,(eventDetail,key) => {
				if(eventDetail.subEvents && eventDetail.subEvents.length > 0) {
					eventDetail.subEvents.forEach((subEvent) => {
						self.$scope.$watch(() => {
							return self.eventStack[subEvent];
						},(newVal, oldVal) => {
							self.eventDetails[key].count = 0;
							self.eventDetails[key].subEvents.forEach( (subEventKey) => {
								self.eventDetails[key].count += self.eventStack[subEventKey].length;
							} );
						},true);
					});
				}

			});
			self.mapService.setEventDetails(self.eventDetails);


			return null;
		} ).then( () => {

			//NOTE: This $timeout is important because:
			// At this point of time, the map hasn't been initialized by
			// mapview.component yet (because it is a child of this dashboard.component)
			self.getPromise = self.$timeout(() => {
				self.unitService.registerListener(self, (event, item, array) => {
					return self.onReceiptOfUnit(item,event);
				}).then((units) => {
					units.forEach(unit => {
						self.onReceiptOfUnit(unit);
					});
					let order = 'asc';
					if(self.mapMetaData && self.mapMetaData.openEventsOrderBy && self.mapMetaData.openEventsOrderBy.includes('-')) {
						order = 'desc';
					}
					self.eventService.registerListener(self, (event, item, array) => {
						return self.onReceiptOfEvent(item, true);
					},{limit:self.mapMetaData.openEventsLimit, by:'createdAt',order}).then((events) => {
						self.setEvents(events);
					})
.catch(err => {
						console.error('Error getting events', err);
					});
				})
.catch(err => {
					console.error('Error getting units', err);
				});


			},0);
		} );

		//Register photo carousel open/close click to fire resize (resize tab content)
		self.$timeout(function() {
			let carouselButton = $("#event-carousel-widget a.jarviswidget-toggle-btn");
			if(carouselButton) {
				carouselButton.click(() => {
					self.$timeout(function() {
						$(window).trigger('resize.mapView');
					}, 300);
				});
			}
		},500);
        //onInit end
    }

    $onDestroy() {
        let self = this;
        // self.mapService.clearMapMarkers();
        if(self.$localStorage.bounds == 'accountChanged') {
            self.$localStorage.bounds = undefined;
        }else {
            self.$localStorage.bounds = self.mapService.map.getBounds();
        }
        self.$localStorage.enableClustering = self.mapService.map.enableClustering;
        self.$localStorage.showSites = self.mapService.map.showSites;
        self.mapService.clearService();
        self.clearPhotoStack();
        self.clearEventStack();
        self.clearUnits();
        self.$interval.cancel(self.watchUnitsPromise);
        self.$timeout.cancel(self.getPromise);
        self.unitService.unregisterListener(self);
		self.eventService.unregisterListener(self);
    }

    /**
     * currentEvent gets set when a user VIEWS it.
     * NOTE: We do self.currentEvent.viewed = true; but this must be uploaded to server.
     * TODO: Consider the best way of going about with the above.
     */
    setCurrentEvent(eventID, eventType) {
        let self = this;
        let currentEvent = self.getEventByID(eventID, eventType);
        if(currentEvent !== undefined) { //we found event with this _id. It actually exists
            self.currentEventService.setCurrentEvent(currentEvent).then(() => {
                self.setFootages();
                self.openCarouselWidget();
            });
            // self.activateEventHistory(currentEvent); // TODO: consider
        } else {
            console.error("Cannot find the event with ID: ", eventID);
        }
    }

    setCurrentClosedEvent(event, eventType) {
        let self = this;
        self.currentEventService.setCurrentEvent(event);
        self.setFootages();
        self.openCarouselWidget();
        // self.activateEventHistory(currentEvent); // TODO: consider
    }

    /**
     * Called by opening a unit's popup on the map and clicking View Details.
     * NOTE that we are still calling eventService's setCurrentEvent, but
     * that we are actually NOT providing it with an EVENT, but with a UNIT
     */
    viewUnitDetails(unitID) {
        let self = this;
        let unit = _.cloneDeep(_.find(self.units, (storedUnit) => {
            return storedUnit._id === unitID;
        }));

        if(unit !== undefined) {
            unit.eventType = 'online';
            self.currentEventService.setCurrentEvent(unit);
            self.setFootages();
            self.openCarouselWidget();
            // self.activateEventHistory(currentEvent); // TODO: consider
        }

    }

    requestSnapshot(unitId) {
        let self = this;
        self.unitService.requestSnapshot(unitId).then((result) => {
			if(result) {
				self.toastr.info('Snapshot requested.');
			}else{
				self.toastr.warning('Snapshot request already pending.');
			}
        })
.catch((err) => {
            self.toastr.error('Snapshot request failed.');
            console.error(err);
        });
    }

	openDMSTerminal(unit) {
        let self = this;
        self.$uibModal.open({
            component: "dmsTerminal",
            backdrop: 'static',
            size: 'lg',
            keyboard: true,
            resolve: {
                unit() {
                    return unit;
                }
            }
        }).result.then(result => {
        });
    }


    /**
     * When viewing details for an event, we make it the current-event.
     * We also then activate that event's 'breadcrumb-mode'
     * @param  {[type]} currentEvent [description]
     */
    activateEventHistory(currentEvent) {
        let self = this;
        //TODO activate it, yo


    }

    closeCarouselWidget() {
        let self = this;
        self.eventCarouselService.closeCarouselWidget();
    }

    /**
     * When we click "View Details" on a unit's map popup.
     * At TabContentComponent there is an ng-class on this variable to simply
     * indicate which unit is selected
     */
    selectUnit(unitID) {
        let self = this;
        self.mapService.openSubtabsTo('Events');
        self.mapService.openEventsAccordionTo('units');
        self.selectedUnit = unitID;
    }


    openCarouselWidget() {
        let self = this;
        self.eventCarouselService.openCarouselWidget();
    }

    /**
     * This function gets called at the end of setCurrentEvent
     * It's purpose is to determine the footages to be loaded.
     * TODO: This will need further refinement when (if) we load all
     *       footages for the specific PERSON
     */
    setFootages() {
        let self = this;
        self.eventCarouselService.setFootages(self.currentEventMetaData.currentEvent);

    }

    preliminarilySetFootages(event) {
        let self = this;
        self.eventCarouselService.preliminarilySetFootages(event, true);

    }

    /**
     * As the name implies, we're clearing the currentEvent.
     * NOTE that "selected-event.component.html" gets shown only when
     * a currentEvent has been set [showCurrentEvent = true].
     * (because we're not using a modal,
     * we're using an ng-if on the DIV.)
     */
    clearCurrentEvent(closeDueToAction) {
        let self = this;
        self.currentEventService.clearCurrentEvent(closeDueToAction);
        // self.showCurrentEvent = false;
        self.$timeout(() => {
            // self.currentEvent = {};
            // self.eventCarouselMetaData.slickLoaded = false; //that slick would have to reload next time
        }, 200);
    }


    getEventByID(eventID, eventType) {
        let self = this;
        return _.find(self.eventStack[eventType], {_id : eventID});
    }

    /**
     * All events received by socket or via HTTP-requests get handled by this function
     */
    onReceiptOfEvent(event, fromSocket) {
        let self = this;
		//Check if event details has event (this should not be possible, could happen if a unit creates a report event for a field report that does not exist)
		if(!self.eventDetails[event.eventType]) {
			console.error("Event has no event details document : ", event);
			return null;
		}

        // if(event.eventType == 'Late Arrival' || event.eventType == "Early Abscondment"){
        //     return;
        // }
		//
        if(event && (event.eventType == 'Recognition' || event.eventType == 'LPR Recognition') && !self.hasFaceRecallPriv) {
            return null;
        }


		//This is changes to enable dynamic grouping of events.  The rest is to support all other functionality
		let findEvent = _.find(self.openEvents, ['_id',event._id]);
		if(findEvent) {
			if(event.active) {
				findEvent = _.mergeWith(findEvent, event, (objValue, srcValue, key) => {
					if (_.isArray(objValue)) {
						return srcValue;
					}
				});
			}else{
				_.remove(self.openEvents,['_id',event._id]);
				//This is for the animation (Not Working)
				//findEvent.leave = true;
			}
		}else if(event.active) {
			self.openEvents.push(event);
		}


        self.categorizeEvents(event, fromSocket);
        // self.addSnapshotsToStack(event);
        if(!event.unit) {
            event.unit = 'unassigned';
        }

        if(event.active) {
            let someFiltered = _.some(self.eventDetails,'filtered');
            self.mapService.addToMap(event, self.$scope, someFiltered && !self.eventDetails[event.eventType].filtered);
        }
        if(self.currentEventMetaData.currentEvent && self.currentEventMetaData.currentEvent._id === event._id) {
            // _.merge(self.currentEventMetaData.currentEvent, event);
            self.currentEventMetaData.currentEvent = event;
        }

        // self.addToMap(event);

        //NOTE How should photos/snapshots be handled if we want a 'photo-display' that
        //     shows all the photos in a grid view.
        //     Should all photos be loaded on init with HTTP, and then added to an array
        //     as events are caught?

        // self.addSnapshots(event);
    }

    /**
     * Places an event in the appropriate position within the eventStack.
     * NOTE that we do not make privision for child-events due to tracking
     *      by the PERSON and not the site.
     */
    categorizeEvents(event, fromSocket) {
        let self = this;
        let index = _.indexOf(self.eventTypes, event.eventType); //to make sure this eventType is an acknowledged type
        if(index !== -1) { //currentEvent is an acknowledged type
            let eventType = event.eventType;
            let insertionArea = self.eventStack[eventType]; //just a shorthand for referring to self.eventStack[eventType]
            let reassignInsertionArea = self.reassignEventStack[eventType]; //just a shorthand for referring to self.reassignEventStack[eventType]
            let idx = _.findIndex(self.eventStack[eventType],{_id:event._id});
            let eventDetail = self.eventDetails[eventType];
            if(idx !== -1) { //there aready is an event of this type WITH this _id
                if(event.active) { //the event is still active, so we update the details
                    if(!event.activeUser) {
                        delete insertionArea[idx].activeUser;
                        delete insertionArea[idx].activeUsername;
                    }
                    insertionArea[idx] = _.mergeWith(insertionArea[idx], event, (objValue, srcValue, key) => {
                        if (_.isArray(objValue)) {
                            return srcValue;
                        }
                    });
                } else { //the event is now inactive
                    if(insertionArea[idx].active) { //old event was active
                        self.removeActionedEvent(insertionArea[idx]);
                    }
                }
                let reassignIdx = _.findIndex(reassignInsertionArea,{_id:event._id});
                if(reassignIdx > -1) {
                    if(event.reassignTo !== self.Auth.getCurrentUserSync()._id) {
                        reassignInsertionArea.splice(reassignIdx,1);
                    }
                }else if(event.reassignTo === self.Auth.getCurrentUserSync()._id) {
                        reassignInsertionArea.push(event);
                    }
            } else { //there isn't another event of this type with this _id [ie. Unique event]
				if(event.active) { //we make sure this event is active
					if(eventDetail.notification && fromSocket) {
						var snd = new Audio(eventDetail.notification);
						snd.play();
					}
					insertionArea.push(event); //we add this event to the stack
				}
				if(event.reassignTo === self.Auth.getCurrentUserSync()._id) {
					reassignInsertionArea.push(event);
				}
			}
            if(!event.active) {

                let insertionArea;
                if (self.closedEventStack[eventType]) {
                    insertionArea = self.closedEventStack[eventType]; //just a shorthand for referring to self.eventStack[eventType]
                } else {
                    self.closedEventStack[eventType] = [];
                    insertionArea = self.closedEventStack[eventType]; //just a shorthand for referring to self.eventStack[eventType]
                }
                let idx = _.findIndex(self.closedEventStack[eventType], {
                    _id: event._id
                });
                if (idx !== -1) { //there aready is an event of this type WITH this _id
                    if(!event.activeUser) {
                        delete insertionArea[idx].activeUser;
                        delete insertionArea[idx].activeUsername;
                    }
                    insertionArea[idx] = _.mergeWith(insertionArea[idx], event, (objValue, srcValue,key) => {
                        if (_.isArray(objValue)) {
                            return srcValue;
                        }
                    });
                } else { //there isn't another event of this type with this _id [ie. Unique event]
                    insertionArea.push(event); //we add this event to the stack
                }
            }
        } else{
			console.error("Unsupported event Type : ", event);
        }
    }

    /**
     * @see categorizeEvents
     */
    removeActionedEvent(event) {
        let self = this;
        event.leave = true;
        self.mapService.removeMarkerFromMap(event.unit, event._id);

        self.$timeout(() => {
            self.eventService.removeEvent(event.eventType, event._id);
        }, 250);
    }

    /**
     * Once an event gets received, we take each snapshot (although it will probably only have one)
     * and we put it at the FRONT of photoStack to have the newest snapshots first.
     * When we go to dashboard of photos, this photoStack gets reflectected there.
     * We're also embedding some other information with the snapshot (rethink).
     */
    addSnapshotsToStack(event) {
        let self = this;
        let snapshotObject = {asset: event.asset.name,
            unitNumber: event.unitNumber,
            sitename: event.sitename,
            createdAt: event.createdAt,
            _id: event._id,
            originalEvent: event};

        if(event.snapshots) {
            _.forEach(event.snapshots, (snapshot) => {
                _.merge(snapshotObject, snapshot);
                // snapshotObject.snapshots.push(snapshot)
                self.photoStack.unshift(snapshotObject);
            });
        }
    }

    clearPhotoStack() {
        let self = this;
        self.photoStack = [];
    }

    clearEventStack() {
        let self = this;
        _.forEach(self.eventStack, (eventType) => {
            eventType = [];
        });
    }

    clearUnits() {
        let self = this;
        delete self.units;
        self.units = [];
    }


    //We invoke this function after GETTING our list of units. We need this function
    //to update the value of .message, which gets displayed in tabcontent.html
    //We're doing this to prevent $digest cycles from going crazy.
    watchUnits() {
        let self = this;
        _.forEach(self.units, (unit) => {
			if (unit.lastHB && unit.lastHB.length > 0) {
				unit.lastHB[0].message = self.lastHeartbeat(unit);
			}
            let isOnline = unit.online;
            self.unitsOnlineStatus[unit._id] = isOnline;
            // if(!isOnline && wasOnline) {
            //     unit.lastSeen = unit.loc;
            //     // unit.loc = {};
            //
            //     /**
            //      * I'm mimicking that I'm receiving an update from
            //      * the server that tells me a unit is offline
            //      */
            //     let newUnit = _.cloneDeep(unit);
            //     newUnit.online = false;
            //     self.onReceiptOfUnit(newUnit);
            // }
        });
    }

    //Does Exactly the same as watchUnits but only on 1 unit.
    //This is done when a unit is updated from the server
    watchUnitOnce(unit) {
    	let self = this;
        if(unit.lastHB && unit.lastHB.length > 0) {
            unit.lastHB[0].message = self.lastHeartbeat(unit);
        }
    	let isOnline = unit.online;
    	self.unitsOnlineStatus[unit._id] = isOnline;
    	// if (!isOnline) {
    	// 	unit.lastSeen = unit.loc;
    	// 	// unit.loc = {};
        //
    	// 	/**
    	// 	 * I'm mimicking that I'm receiving an update from
    	// 	 * the server that tells me a unit is offline
    	// 	 */
    	// 	let newUnit = _.cloneDeep(unit);
    	// 	newUnit.online = false;
    	// 	self.onReceiptOfUnit(newUnit);
    	// }
    }

    isOnline(unit) {
        let self = this;
  		if(unit.lastHB && unit.lastHB.length > 0) {
return self.moment().diff(self.moment.utc(unit.lastHB[0].ts), 'minutes') < 10;
}
  		return false;
  	}

    lastHeartbeat(unit) {
        let self = this;
  		if(unit.lastHB && unit.lastHB.length > 0) {
  			let minutes = Math.abs(self.moment().diff(self.moment.utc(unit.lastHB[0].ts), 'minutes'));
            if(minutes < 1) {
                return 'less than 1 minute ago';
            }
  			if(minutes === 1) {
                return '1 minute ago';
  			}
  			if(minutes > 1 && minutes < 60) {
  				return `${minutes.toString()} minutes ago`;
  			} else if (minutes >= 60 ) {
                let hours = Math.floor(minutes / 60);
                minutes = minutes % 60;
                if(hours < 24) {
                    if(hours === 1) {
                        if(minutes === 1) {
                            return `1 hour, 1 minute ago`;
                        }
                        return `1 hour, ${minutes} minutes ago`;
                    }
                    return `${hours} hours, ${minutes} minutes ago`;
                } else {
                    let days = Math.floor(hours / 24);
                    hours = hours % 24;

                    if(days < 365) {
                        if(days === 1) {
                            if(hours === 1) {
                                return `1 day, 1 hour, ${minutes} minute(s) ago`;
                            }
                            return `1 day, ${hours} hours, ${minutes} minute(s) ago`;
                        }
                        return `${days} days, ${hours} hours, ${minutes} minute(s) ago`;
                    } else {
                        let years = Math.floor(days / 365);
                        days = days % 365;

                        if(years === 1) {
                            return `1 year, ${days} day(s) ago`;
                        }

                        return `${years} years, ${days} day(s) ago`;
                    }
                    return `${days} day(s), ${hours} hour(s) ago`;
                }
            }
  			return 'less than a minute ago';
  		}
  		return 'no last heartbeat';
  	}

    /**
     * testing
     */
    chooseMapLoc() {
        let self = this;
        let map = L.DomUtil.get('map');


    }


    onReceiptOfUnit(unit,event) {
        let self = this;
		if(event == 'deleted') {
			let index = _.findIndex(self.units,(o) => {
				return o._id == unit._id;
			});
			if(index >= 0) {
				self.units.splice(index,1);
			}
			delete self.unitsData[unit._id];
			delete self.unitsOnlineStatus[unit._id];
			self.mapService.removeUnit(unit);
			if(self.currentEventMetaData.currentEvent && self.currentEventMetaData.currentEvent._id === unit._id) {
				self.currentEventService.clearCurrentEvent();
			}
		}else {
			self.addUnitToUnits(unit);
			self.watchUnitOnce(unit);
			self.mapService.addUnitToMap(unit, self.$scope);
			if(self.currentEventMetaData.currentEvent && self.currentEventMetaData.currentEvent._id === unit._id) {
				_.merge(self.currentEventMetaData.currentEvent, unit);
			}
		}
    }

    /**
     * Reasoning here is as follows:
     * We are going to be receiving a GET-list of units, but any other unit that comes online
     * would emit a heartbeat indicating so.
     *
     * We check the lastHB of this unit to see if it is online.
     * Only units that are online get added to the eventStack['online']
     *  - because if a unit goes offline, the server would send us something indicating so
     *
     * We have self.units = [] that contain the entire list of units.
     * Once we get net information concerning a unit, we update it, for example
     *     -who the current human asset is
     *     -whether it is assigned
     *     -TODO Focus on updating its location, because leaflet layers make this messy
     *
     * NOTE: We're pushing this UNIT to the EVENT-stack. This means that
     *       eventStack.online does not contain the typical format
     * TODO: Consider whether this note above's implementation should be reconsidered.
     */
    addUnitToUnits(unitToAdd) {
        let self = this;

        let foundIndex = _.findIndex(self.units, (unit) => {
            return unit._id === unitToAdd._id;
        });

        if(foundIndex === -1) {
            //because I'm generating units with random numbers, there is a slight chance of adding
            //a unit that has been given the same random number of a different unit.

            /**
             * The server will tell me whether this guy is online or not
             */
            self.units.push(unitToAdd);
            self.unitsData[unitToAdd._id] = unitToAdd;
            self.unitsOnlineStatus[unitToAdd._id] = unitToAdd.online;

            if(unitToAdd.online) {
                //so that any event can easily find its unit's onlinestatus
                self.unitsOnlineStatus[unitToAdd._id] = true;
                //so that our counter at the top reflects this online unit
                let index = _.findIndex(self.eventStack.online,{_id:unitToAdd._id});
                if(index >= 0) {
                    self.eventStack.online.splice(index,1,unitToAdd);
                }else{
                    self.eventStack.online.push(unitToAdd);
                }
            }
        } else {
            //Let us now assume that this is an update, and not a faulty random-duplicate
            let storedUnit = self.units[foundIndex];
            let wasOnline = storedUnit.online;
            let isOnline = unitToAdd.online;

            if(wasOnline && !isOnline) {
                self.unitsOnlineStatus[unitToAdd._id] = false;

                let eventStackIndex = _.findIndex(self.eventStack.online, (onlineEvent) => {
                    return onlineEvent._id === unitToAdd._id;
                });

                if(eventStackIndex !== -1) {
                    self.eventStack.online.splice(eventStackIndex, 1);
                    //NOTE self.mapService.handleUnitWentOffline(unit);
                }

                _.mergeWith(self.units[foundIndex], unitToAdd, (objValue, srcValue) => {
                    if(_.isArray(objValue)) {
                        return srcValue;
                    }
                });
            } else if(!wasOnline && isOnline) {
                let eventStackIndex = _.findIndex(self.eventStack.online, (onlineEvent) => {
                    return onlineEvent._id === unitToAdd._id;
                });

                if(eventStackIndex !== -1) {
                    self.eventStack.online.splice(eventStackIndex, 1, unitToAdd);
                    //NOTE self.mapService.handleUnitWentOffline(unit);
                }else{
                    self.eventStack.online.push(unitToAdd);
                }
                self.unitsOnlineStatus[unitToAdd._id] = true;

                _.mergeWith(self.units[foundIndex], unitToAdd, (objValue, srcValue) => {
                    if(_.isArray(objValue)) {
                        return srcValue;
                    }
                });
            } else {
                //stayed in its previous state

                //this should remain the same, actually
                self.unitsOnlineStatus[unitToAdd._id] = unitToAdd.online;
                // We update the older object in self.units
                _.mergeWith(self.units[foundIndex], unitToAdd, (objValue, srcValue) => {
                    if(_.isArray(objValue)) {
                        return srcValue;
                    }
                });

                // We also update the one in our eventStack
                // TODO Really consider the use of eventStack['online']
                if(isOnline) {
                    let idx = _.findIndex(self.eventStack.online, (unit) => {
                        return unit._id === unitToAdd._id;
                    });
                    _.mergeWith(self.eventStack.online[idx], unitToAdd, (objValue, srcValue) => {
                        if(_.isArray(objValue)) {
                            return srcValue;
                        }
                    });
                }
            }


            // TODO: I should still consider the possible changes and whether I should do something
            // about that over here. Like, for example, if the unit gets assigned to a new person,
            // I could reach out to mapService and change the 'green' (online) marker to reflect this
            // new information. BUT... I think, that we should leave that to mapService. Let IT receive
            // the new unit, it'll recognize that it's an update. Let's do that logic over there.


        }
    }

    doLog() {
        let self = this;
        console.debug(self);
    }

	loadMoreEvents() {
		let self = this;
		self.eventsLoading = true;
		self.clearEvents();
		let order = 'asc';
		if(self.mapMetaData && self.mapMetaData.openEventsOrderBy && self.mapMetaData.openEventsOrderBy.includes('-')) {
			order = 'desc';
		}
		let limit = 200;
		if(self.mapMetaData && self.mapMetaData.openEventsLimit) {
			limit = self.mapMetaData.openEventsLimit;
		}
		self.eventService.getEventsWithQuery({field:'active',query:true,limit, by:'createdAt',order, skip:(self.eventPage-1)*limit}).then( (data) => {
			data.data.total = data.total;
			self.setEvents(data.data);
		} );
	}

	setEvents(events) {
		let self = this;
		this.eventsLoading = false;
		// TODO: Reconsider whether it'll still be necessary to set eventService's eventStack
		self.eventService.setEventStack(self.eventStack);
		events.forEach(event => {
			self.onReceiptOfEvent(event);
		});
		self.openEventsCount = events.total;
		self.$timeout(function() {
			if(self.$localStorage && self.$localStorage.bounds && typeof self.$localStorage.bounds.isValid === 'function' && self.$localStorage.bounds.isValid()) {
				// self.mapService.fitMapToBounds(self.$localStorage.bounds);
				self.mapService.map.flyToBounds(self.$localStorage.bounds, {
					maxZoom: 17,
					duration: 1
				});
			}else {
				self.mapService.zoomToAll();
			}
		}, 0);

	}

	clearEvents() {
		let self = this;
		//Clear Event stack
		self.eventService.clearOpenEventStack();
		//Clear current open event
		self.currentEventService.clearCurrentEvent(true);
		//Clear filters
		_.forEach(self.eventDetails,(e) => {
			e.filtered = false;
		});
		self.mapService.showAllMarkers();
		//Clear open events
		self.openEvents = [];
		//Clear map events
		self.mapService.clearEventMarkers();
        self.clearPhotoStack();
		self.eventService.events = [];
	}

}
export default angular.module('secutraqApp.dashboard')
.component('dashboard', {
    template: require('./dashboard.html'),
    controller: DashboardComponent,
    controllerAs: '$ctrl'
})
.name;
