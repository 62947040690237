import angular from 'angular';
import uiRouter from 'angular-ui-router';
import uiBootstrap from 'angular-ui-bootstrap';
import routes from './reports.routes';


export default angular.module('secutraqApp.reports', [uiRouter, uiBootstrap])
  .config(routes)
  .name;

require('./reports.adhoc.component');
require('./reports.scheduled.component');
require('./new-scheduled-report/new-scheduled-report.modal.component');
