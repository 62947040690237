'use strict';

export default function($stateProvider) {
  'ngInject';
  $stateProvider
  .state('main.privGroups', {
      url: '/privGroups',
      data: {
          title: 'Privilege Groups'
      },
      views: {
          'content@main': {
              template: '<priv-groups></priv-groups>'
          }
      },
	  authenticate:true
  })
}
