import {RRuleSet, RRule} from 'rrule';

export class NewUnitComponent {
	/*@ngInject*/
	constructor($stateParams, Restangular, unitService, privGroupService, $uibModal, genericMapService, $timeout, mapService, moment) {
		this.$stateParams = $stateParams;
		this.Restangular = Restangular;
		this.unitService = unitService;
		this.privGroupService = privGroupService;
		this.$uibModal = $uibModal;
		this.genericMapService = genericMapService;
		this.$timeout = $timeout;
		this.moment = moment;
		this.mapService = mapService;
		this.availableActions = ['Enable', 'Disable'];
        this.countryOptions = [
            {
                country: "South Africa",
                region: "za"
            },
            {
                country: "Australia",
                region: "au"
            },
            {
                country: "Argentina",
                region: "ar"
            },
            {
                country: "Brazil",
                region: "br"
            },
            {
                country: "Great Britain",
                region: "gb"
            },
            {
                country: "Europe",
                region: "eu"
            },
            {
                country: "China",
                region: "cn"
            },
            {
                country: "Gulf Cooperation Council",
                region: "gcc"
            },
            {
                country: "India",
                region: "in"
            },
            {
                country: "Japan",
                region: "jp"
            },
            {
                country: "Indonesia",
                region: "id"
            },
            {
                country: "Korea",
                region: "kr"
            },
            {
                country: "Malaysia",
                region: "my"
            },
            {
                country: "Middle East",
                region: "middleeast"
            },
            {
                country: "New Zealand",
                region: "nz"
            },
            {
                country: "North America",
                region: "us"
            },
            {
                country: "Thailand",
                region: "th"
            },
            {
                country: "Russia",
                region: "ru"
            },
            {
                country: "Singapore",
                region: "sg"
            }
        ];

		this.scheduledConfig = {};
		let allDays = [{
			id:0,
			alias:'Monday'
		},{
			id:1,
			alias:'Tuesday'
		},{
			id:2,
			alias:'Wednesday'
		},{
			id:3,
			alias:'Thursday'
		},{
			id:4,
			alias:'Friday'
		},{
			id:5,
			alias:'Saturday'
		},{
			id:6,
			alias:'Sunday'
		}];
		this.availableDays = {
			scheduledPhotos:allDays,
			motionPhotos:allDays,
		};
	}

	$onInit() {
		let self = this;
		self.submitted = false;
		self.unitStatusOptions = [
			{ id: 0, description: 'Test' },
			{ id: 1, description: 'Active' },
		];
		self.availableUnitTypes = ['Secuvue Camera', 'Secuvue Unit'];
		if(self.resolve.create) {
			self.unit = {};
			self.unit.name = '';
			self.unit.imei = '';
			self.unit.description = '';
			self.unit.type = 'API';
			self.unit.stationaryUnit = true;
			self.unit.lastLocation = [{
				location :{type:"Point",coordinates:[27.909356,-26.081073]},
				source:'Manual'
			}];
			self.unit.groups = [];
		} else if(self.resolve.edit) {
			self.unit = self.resolve.unit;
			self.unitstatus = [self.unitstatus];
		} else {
			console.error("It is not certain whether a unit is being created or edited");
		}

		if(!self.unit.config) {
			self.unit.config = {};
		}
		if(!self.unit.config.loginTimeout) {
			self.unit.config.loginTimeout = {
				disable: false,
				override: false,
				minutes: 0
			};
		}

		if (self.unit.stationaryUnit && self.unit.type !== 'API') {
			if (!(self.unit.lastLocation && self.unit.lastLocation.length > 0)) {
				if (self.unit.defaultLocation) {
					self.unit.lastLocation = [self.unit.defaultLocation];
				} else {
					self.unit.defaultLocation = {
						location:{
							type:'Point',
							coordinates:[27.909356,-26.081073]
						},
						source:"Manual"
					};
					self.unit.lastLocation = [self.unit.defaultLocation];
				}
			}
			self.$timeout( () => {
				let location = {
					coordinates:[27.909356,-26.081073]
				};
				if (self.unit.lastLocation && self.unit.lastLocation.length > 0) {
					location = self.unit.lastLocation[0].location;
				}
				let loc = {
					lat:location.coordinates[1],
					lng:location.coordinates[0]
				};
				self.map = self.genericMapService.generateMap('selectUnitLocation',{
					geocoder:{position:'topleft'},
					zoomControl:true,
					satellite : true,
					drawControl:false,
				});
				self.marker = self.genericMapService.placeMarkerAtCoordinates(self.map, self.mapService.markerIcons.online, loc, {draggable: true});
				self.map.setView(loc, 15);

				self.marker.on('moveend', (e) => {
					let location = self.marker.getLatLng();
					self.unit.defaultLocation.location.coordinates = [location.lng,location.lat];
					self.unit.lastLocation = [self.unit.defaultLocation];
				});
				self.map.on('click', (e) => {
					self.unit.defaultLocation.location = {
						type:"Point",
						coordinates:[e.latlng.lng,e.latlng.lat]
					};
					self.unit.lastLocation = [self.unit.defaultLocation];
					if (self.marker) {
						self.marker.setLatLng(e.latlng);
					}
					self.map.setView(e.latlng, 15);
				});

			});
		}

		if(self.unit.type == "SecutraqStationaryApp") {
			//Find scheduled config options
			let enableScheduledPhoto = _.find(self.unit.scheduledConfigChanges, {config:'config.photoTriggerConfig.enableSchedule'});
			if(enableScheduledPhoto) {
				enableScheduledPhoto.schedules.forEach( (schedule) => {
					schedule.utcOffset = self.moment(schedule.utcOffset);
					//schedule.disableUTCOffset = self.moment(schedule.disableUTCOffset);
				} );
				self.scheduledConfig.scheduledPhotos = enableScheduledPhoto;

			}else{
				let enableScheduledPhoto = {
					enable:false,
					config:'config.photoTriggerConfig.enableSchedule',
					schedules:[],
				};
				self.unit.scheduledConfigChanges.push(enableScheduledPhoto);
				self.scheduledConfig.scheduledPhotos = enableScheduledPhoto;
			}

			//Find motion config options
			let enableMotionPhoto = _.find(self.unit.scheduledConfigChanges, {config:'config.photoTriggerConfig.enableMotion'});
			if(enableMotionPhoto) {
				enableMotionPhoto.schedules.forEach( (schedule) => {
					schedule.utcOffset = self.moment(schedule.utcOffset);
					//schedule.disableUTCOffset = self.moment(schedule.disableUTCOffset);
				} );
				self.scheduledConfig.motionPhotos = enableMotionPhoto;

			}else{
				let enableMotionPhoto = {
					enable:false,
					config:'config.photoTriggerConfig.enableMotion',
					schedules:[],
				};
				self.unit.scheduledConfigChanges.push(enableMotionPhoto);
				self.scheduledConfig.motionPhotos = enableMotionPhoto;
			}
		}
		// self.refreshGroups();
	}


	// refreshGroups() {
	// 	let self = this;
	// 	if(self.$stateParams.accountID){
	// 		self.Groups.getList({
	// 			accountID: self.$stateParams.accountID
	// 		}).then(function(groups) {
	// 			self.availableGroups = groups;
	// 		});
	// 	}
	// }
	onGroupAdded() {
		let self = this;
		let label = " (create new group)";
		let tempGroups = [];
		_.forEach(self.unit.groups, (group) => {
			if (group.slice(-label.length) === label) {
				group = group.slice(0, -label.length);
			}
			tempGroups.push(group);
		});
		self.unit.groups = tempGroups;
	}

	ok(form) {
		let self = this;
		//Save next cheduled config change time <28-04-21, Liaan> //
		if(self.unit.scheduledConfigChanges && self.unit.scheduledConfigChanges.length > 0) {
			self.calculateAllScheduledConfigs();
		}

		if(form.$valid) {
			let unit = self.unit;
			self.modalInstance.close(unit);
		} else {
			self.submitted = true;
		}
	}

    refreshGroups() {
		let self = this;
        self.privGroupService.getGroupWithQuery().then(function(groups) {
            self.availableGroups = groups;
        })
.catch((err) => {
            console.error(err);
        });
    }

	// Modal cancelled: either cancel pressed or clicked off screen
	cancel() {
		let self = this;
		self.modalInstance.dismiss('Cancel pressed');
	}

    generateNumber(min,max) {
        return Math.floor(Math.random()*(max-min+1)+min);
    }

	selectLocationOnMap() {
		let self = this;
		let locationModal = self.$uibModal.open({
			component: 'minimapmodal',
			size: 'sm',
			// backdrop: 'static',
			resolve: {
				select:true,
				lastLocation: self.unit.lastLocation[0]
			}
		});

		locationModal.result.then((result) => {
		});

	}

	updateUnitLocation() {
		let self = this;
		let loc = {
			lat:self.unit.defaultLocation.location.coordinates[1],
			lng:self.unit.defaultLocation.location.coordinates[0],
		};
		self.unit.lastLocation = [self.unit.defaultLocation];
		self.marker.setLatLng(loc);
		self.map.setView(loc, 15);
	}

	refreshDays(key, schedules) {
		let self = this;

		let allDays = [{
			id:0,
			alias:'Monday'
		},{
			id:1,
			alias:'Tuesday'
		},{
			id:2,
			alias:'Wednesday'
		},{
			id:3,
			alias:'Thursday'
		},{
			id:4,
			alias:'Friday'
		},{
			id:5,
			alias:'Saturday'
		},{
			id:6,
			alias:'Sunday'
		}];

		//Removing this to allow multiple schedules for the same day
		//schedules.forEach( (schedule) => {
			//schedule.days.forEach( (isoDay) => {
				//_.remove(allDays, {id:isoDay});
			//} );
		//});
		self.availableDays[key] = allDays;
		return null;
	}

	formatUTCMoment(moment) {
		moment.startOf('minute');
	}

	removeSchedule(schedules, index) {
		schedules.splice(index,1);
	}

	calculateAllScheduledConfigs() {
		let self = this;
		self.unit.scheduledConfigChanges.forEach( (changes) => {
			self.calculateNextDate(changes);
		} );
	}

	calculateNextDate(configChange) {
		let self = this;
		let set = new RRuleSet();
		configChange.schedules.forEach( (schedule) => {
			let ruleEnable = new RRule({
				dtstart:schedule.utcOffset.utc().toDate(),
				byweekday:schedule.days,
				freq:3
			});
			set.rrule(ruleEnable);
		});
		let date = set.after(self.moment().toDate());
		configChange.nextTriggerDate = date;
	}

	doLog() {
		let self = this;
		console.debug(this);
	}
}

export default angular.module('secutraqApp.units')
	.component('newunit', {
		template: require('./new-unit-modal.html'),
		bindings: {
			resolve: '<',
			modalInstance: '<'
		},
		controller: NewUnitComponent,
		controllerAs: '$ctrl'
	})
.name;
