'use strict';
import angular from 'angular';

export class TOTPComponent {
    /*@ngInject*/
    constructor($scope,Auth,$http,$state,socket,$timeout,$window) {
        this.$scope = $scope;
		this.Auth = Auth;
		this.$http = $http;
		this.$state = $state;
		this.$timeout = $timeout;
		this.socket = socket;
		this.$window = $window;
    }

    $onInit() {
        let self = this;
		this.errorStates = {
			totpFail:false,
			totpEnrollFail:false
		};
		if(self.resolve) {
			self.$timeout( () => {
				let targetInput;

				if(self.resolve.mfa.enrolled) {
					targetInput = angular.element(document.querySelector("#OTP"));
				}else{
					targetInput = angular.element(document.querySelector("#verifyOTP"));
				}
				targetInput.focus();

			} );
		}
    }

    $onDestroy() {

    }

	requestSetup() {
		let self = this;
		if(!self.resolve.mfa.enrolled) {
			self.$http.get('/api/users/mfasetup').then( (res) => {
				if(res.data && res.data.qrImage && res.data.key) {
					self.qrImage = res.data.qrImage;
					self.key = res.data.key;
				}else{
					throw new Error('Setup failed');
				}
			} )
.catch( (err) => {
				cosnole.error(err);
			} );
		}
	}

	verifyCode() {
		let self = this;
		// TODO: Validate code is valid  <22-01-20, Liaan> //
		return self.$http.post('/api/users/mfasetup',{code:self.verifyOTP}).then( (res) => {
			if(res) {
				return self.mfaLogin(self.verifyOTP);
			}
		} )
.catch( (err) => {

			self.errorStates.totpEnrollFail = true;

		} );
	}

	mfaLogin(code) {
		let self = this;
		return self.Auth.mfaLogin({code}).then( () => {
			self.socket.reAuth();
			let account = self.Auth.getCurrentAccountSync();
			if(self.modalInstance && !self.resolve.forced) {
				return self.$state.go('main.dashboard', {accountID:account.accountId});
			}else{
				//return self.$state.go(self.$state.current, {}, {reload: true});
				//return self.$state.reload();
				return self.$window.location.reload();
			}
		} )
.then( () => {
			if(self.modalInstance) {
				self.modalInstance.close();
			}
		} )
		.catch( (err) => {
			console.error(err);
			self.errorStates.totpFail = true;
			return null;
		} );
	}

	copyKey() {
		let self = this;
		var text_to_share = self.key;

		// create temp element
		var copyElement = document.createElement("span");
		copyElement.appendChild(document.createTextNode(text_to_share));
		copyElement.id = 'tempCopyToClipboard';
		angular.element(document.body.append(copyElement));

		// select the text
		var range = document.createRange();
		range.selectNode(copyElement);
		window.getSelection().removeAllRanges();
		window.getSelection().addRange(range);

		// copy & cleanup
		document.execCommand('copy');
		window.getSelection().removeAllRanges();
		copyElement.remove();
	}

	ok() {
		let self = this;

	}

	doLog() {
		let self = this;
		console.debug(this);
	}

	cancel() {
		let self = this;
		self.modalInstance.close();

	}

}

export default angular.module('directives.totp', [])
    .component('totp', {
        template: require('./totp.html'),
        controller: TOTPComponent,
        controllerAs: '$ctrl',
		bindings: {
			modalInstance: '=',
			resolve: '='
		}
    })
    .name;
