'use strict';
import uiBootstrap from 'angular-ui-bootstrap';
import Dropzone from 'dropzone';
import 'dropzone/dist/dropzone.css';
import 'ngdropzone';

Dropzone.autoDiscover = false;

export default class UploadController {
    $state;
    $http;
    Auth;
    $scope;
    $uibModal;
    $uibModalInstance;
    allTags;
    plate = {};
    currentAccount;
    buttonClass = '';


    /*@ngInject*/
    constructor($uibModalInstance, Auth, $http, $state, $scope, toastr, $uibModal) {
        this.$uibModalInstance = $uibModalInstance;
        this.$state = $state;
        this.toastr = toastr;
        this.provinceOptions = [
            {
                province: "KwaZulu Natal",
                region: "za-nl"
            },
            {
                province: "Gauteng",
                region: "za-gt"
            },
            {
                province: "North West Province",
                region: "za-nw"
            },
            {
                province: "Mpumalanga",
                region: "za-mp"
            },
            {
                province: "Limpopo",
                region: "za-lp"
            },
            {
                province: "Eastern Cape",
                region: "za-ec"
            },
            {
                province: "Western Cape",
                region: "za-wc"
            },
            {
                province: "Free State",
                region: "za-fs"
            },
            {
                province: "Northern Cape",
                region: "za-nc"
            },
            {
                province: "South Africa",
                region: "za"
            },
        ];
        this.Auth = Auth;
        this.$http = $http;
        this.$scope = $scope;
        this.$uibModal = $uibModal;
    }

    doLog() {
		console.debug(this);
    }

    $onInit() {
        let self = this;
        self.currentAccount = self.Auth.getCurrentAccountSync().accountId;

        self.dzMethods = {};
        self.$http.get('api/plates/findTags').then((response) => {
            self.allTags = response.data;
        });
    }

    upload() {
        let self = this;
        self.plate.upload = true;
        self.plate.plate = self.plate.plate.replace(/\s/g,'').toUpperCase();
        self.$http.post("api/plates/", self.plate).then(response => {
            self.toastr.info("Licence plate has been uploaded");
        });
        self.$uibModalInstance.dismiss();
    }

    close() {
        let self = this;
        self.$uibModalInstance.dismiss();
    }

    removeTags(tags) {
        let label = " (create new tag)";
        tags.forEach((tag, index)=>{
            if (tag.slice(-label.length) === label) {
                tags[index] = tag.slice(0, -label.length);
            }
        });
    }
}
