'use strict';
import _ from 'lodash-es';
import uuid from 'uuid/v1';
import {MediaPlayer} from 'dashjs';

const INACTIVE_STREAM_SECONDS = 3; //Mark stream as inactive if no buffers have bee received for this amount of seconds

export class LiveStreamService {

	/*@ngInject*/
	constructor(Auth, toastr, socket, $window, unitService, $ngConfirm) {
		this.Auth = Auth;
		this.$window = $window;
        this.$ngConfirm = $ngConfirm;
		this.toastr = toastr;
		this.unitService = unitService;
		this.socket = socket;
        this.rooms = [];

        this.socket.socket.on('dmspublish', this.handleDMSPublish);
        this.socket.socket.on('dmspublishdone', this.handleDMSPublishDone);
        /********WEBRTC SPECIFIC VARIABLES********/
        this.rtc_configuration = {
            iceServers: [
                {urls: ["stun:stun.jerichosystems.co.za", "turn:stun.jerichosystems.co.za"], username: "secuvue", credential: "jerichoturn"}
            ]};
        /*
         *{
             peerId: Signalling server Id,
             ws: websocket connection,
             pc: peer connection,
             status: status of connection,
             connect_attempts: number of attemptts to connect to signalling server
         } */
        this.ws_conns = [];
		this.streams = [];
        /*****************************************/
	}

    $onDestroy() {
        let self = this;
		self.streams.forEach( (stream) => {
			self.closeStream(stream);
		});
        if (self.rooms && self.rooms.length > 0) {
            self.rooms.forEach((room) => {
                self.socket.leaveRoom(room);
            });
            self.rooms = [];
        }
    }

    $onInit() {
        var self = this;
    }

    initiateStream(stream) {
        let self = this;
        if(stream.unit.type === 'DMS') {
            let peer = {
                guid: stream.guid,
                imei: stream.unit.imei,
                channel: stream.channel,
                ws: null,
                pc: null,
                debugStatus: "Connecting to server",
                connect_attempts: 0
            };
            let room = `${stream.unit.account.ref}:${stream.unit._id}:dms:units`;
            self.rooms.push(room);
            self.socket.joinRoom(room);
            let player = MediaPlayer().create();
            player.initialize();
            setTimeout(() => {
                let video = self.getVideoElement(peer.guid);
                player.updateSettings({
                    'debug': {
                        'logLevel': dashjs.Debug.LOG_LEVEL_NONE  /* turns off console logging */
                    },
                    streaming: {
                        retryAttempts: {
                            MPD: 15,
                        },
                        retryIntervals: {
                            MPD: 5000,
                        },
                        utcSynchronization: {
                            backgroundAttempts: 2,
                            timeBetweenSyncAttempts: 30,
                            maximumTimeBetweenSyncAttempts: 600,
                            minimumTimeBetweenSyncAttempts: 2,
                            timeBetweenSyncAttemptsAdjustmentFactor: 2,
                            maximumAllowedDrift: 100,
                            enableBackgroundSyncAfterSegmentDownloadError: true,
                            defaultTimingSource: {
                                scheme: 'urn:mpeg:dash:utc:http-xsdate:2014',
                                value: 'https://time.akamai.com/?iso&ms'
                            }
                        }
                    }
                });
                player.on(MediaPlayer.events.CAN_PLAY, (e) => {
                    stream.active = true;
                });
                player.setAutoPlay(true);
                player.attachView(video);
                let source = `https://dms.jerichosystems.co.za/dash${stream.channel}/${stream.unit.imei}.mpd`;
                //player.attachSource(); // TODO authentication...
                player.attachSource(source); // TODO authentication...
            }, 0);
            self.setStatus("Initiating Stream", peer);
            stream.player = player;
            // this.$ngConfirm({
            //     title: 'Live Stream',
            //     content: 'Which camera would you like to stream?',
            //     buttons: {
            //         in: {
            //             text: 'IN',
            //             btnClass: 'btn-blue',
            //             action: function(scope, button) {
            //             }
            //         },
            //         out: {
            //             text: 'OUT',
            //             btnClass: 'btn-blue',
            //             action: function(scope, button) {
            //                 self.unitService.requestInitiateStream(stream.unit._id, stream.unit.imei, 'OUT');
            //                 self.setStatus("Initiating Stream", peer);
            //                 let room = `dms:livestream:${stream.unit.imei}`;
            //                 self.rooms.push(room);
            //                 self.socket.joinRoom(room);
            //             },
            //         },
            //         inout: {
            //             text: 'BOTH',
            //             btnClass: 'btn-blue',
            //             action: function(scope, button) {
            //                 self.unitService.requestInitiateStream(stream.unit._id, stream.unit.imei, 'INOUT');
            //                 self.setStatus("Initiating Stream", peer);
            //                 let room = `dms:livestream:${stream.unit.imei}`;
            //                 self.rooms.push(room);
            //                 self.socket.joinRoom(room);
            //             }
            //         },
            //     },
            // });
        } else {
            let peer = {
                guid: stream.guid,
                ws: null,
                pc: null,
                debugStatus: "Connecting to server",
                connect_attempts: 0
            };
            self.ws_conns.push(peer);
            self.setStatus("Initiating Stream", peer);
            this.websocketServerConnect(stream.guid);
        }
    }

    closeStream(stream) {
        let self = this;
		if(stream.statsUpdater) {
			clearInterval(stream.statsUpdater);
			Reflect.deleteProperty(stream,'statsUpdater');
		}
        let peer = _.find(self.ws_conns, o => {
            return o.guid === stream.guid;
        });
        if(peer) {
            if(peer.pc !== null) {
                peer.pc.close();
                peer.pc = null;
                self.resetVideoElement(peer.guid);
            }
            if(peer.ws !== null) {
                peer.ws.close();
                peer.ws = null;
            }

            self.removeConn(peer.guid, stream);
        } else if(stream.unit.type === 'DMS') {
            if(stream.player) {
                stream.player.destroy();
            }
        }
    }

	addStream(unit) {
		let self = this;

		if(!unit) {
			console.error('No unit provided when adding stream');
			return null;
		}
		if(!unit.initiateStreamCallback && unit.type !== 'DMS') {
			console.error('Unit does not have a initiate stream callback');
			self.toastr.warning('Live stream not supported for this device.');
			return null;
		}
		if(!_.find(self.streams,['unit._id', unit._id])) {
			let stream = {active: false, guid: self.getOurId(unit, 0), debugStatus: "Idle", unit};
			self.streams.push(stream);
			self.initiateStream(stream);
            if (unit.type === 'DMS') { // Initiate INOUT...
                stream.channel = 0;
                stream = { active: false, guid: self.getOurId(unit, 1), debugStatus: "Idle", unit, channel: 1 };
                self.streams.push(stream);
                self.initiateStream(stream);
                self.unitService.requestInitiateStream(stream.unit._id, stream.unit.imei, 'INOUT');
            }
		}else{
			self.toastr.warning('Only one stream per device allowed.','Stream already open.');
			return null;
		}
	}

	removeStream(stream) {
		let self = this;
		let streamIndex = _.findIndex(self.streams, ['guid', stream.guid]);
		if(streamIndex >= 0) {
			self.closeStream(stream);
			self.streams.splice(streamIndex,1);
		}
	}

    getOurId(unit, number) {
        //TODO: Make a guid
        if(unit && unit.type === 'DMS') {
            return `dms_${unit.imei}_${number}`;
        }
        return uuid();
        //return Math.floor(Math.random() * (9000 - 10) + 10).toString();
        //return "7618";
    }

    clear() {
        let self = this;
		while(self.streams.length) {
			self.removeStream(self.streams[0]);
		}
    }

    doLog() {
        let self = this;
        console.debug("DEBUG: ", self);
    }

/************************************************WEBRTC MAGIC WILL HAPPEN HERE*************************************/

    resetState(peer) {
        peer.ws.close();
    }

    setStatus(msg, peer) {
        let self = this;
        let peerIndex = _.findIndex(self.streams, o => {
            return o.guid === peer.guid;
        });
        if (peerIndex !== -1) {
            self.streams[peerIndex].debugStatus = msg;
        }
        peer.debugStatus = msg;
    }

    handleIncomingError(error, peer) {
        let self = this;
        if(self.Auth.hasUserPrivilege("debug")) {
            this.setStatus(`ERROR: ${error}`, peer);
        }
        this.resetState(peer);
    }

    removeConn(peerId, stream) {
		_.remove(this.ws_conns, (o) => {
			return o.guid === peerId;
		});
		if(stream) {
			stream.active = false;
			stream.debugStatus = "Idle";
		}
    }

    requestFullscreen(stream) {
        let videoElement = this.getVideoElement(stream.guid);
        if (videoElement.requestFullscreen) {
            videoElement.requestFullscreen();
        } else if (videoElement.mozRequestFullScreen) {
            videoElement.mozRequestFullScreen();
        } else if (videoElement.webkitRequestFullscreen) {
            videoElement.webkitRequestFullscreen();
        } else if (videoElement.msRequestFullscreen) {
            videoElement.msRequestFullscreen();
        }
    }

    getVideoElement(peerId) {
        return document.getElementById(`${peerId}`);
    }

    resetVideoElement(peerId) {
        let videoElement = this.getVideoElement(peerId);
	//	this.drawCanvas(this.currentDash.widgets[peerIndex], true);
        if(videoElement) {
            videoElement.pause();
            videoElement.src = "";
            videoElement.load();
        }
    }

    onIncomingSDP(sdp, peer) {
        let self = this;
        peer.pc.setRemoteDescription(new RTCSessionDescription(sdp)).then(() => {
            if(self.Auth.hasUserPrivilege("debug")) {
                this.setStatus("Remote SDP set", peer);
            }
            if(sdp.type !== "offer") {
                return;
            }
            if(self.Auth.hasUserPrivilege("debug")) {
                this.setStatus("Got SDP offer, creating answer", peer);
            }
            peer.pc.createAnswer()
                .then(this.onLocalDescription.bind(this, peer))
                .catch(this.setStatus.bind(this, peer));
        });
    }

    onLocalDescription(peer, desc) {
        let self = this;
        peer.pc.setLocalDescription(desc).then(function() {
            if(self.Auth.hasUserPrivilege("debug")) {
                self.setStatus("Sending SDP answer", peer);
            }
            let sdp = {sdp: peer.pc.localDescription};
            peer.ws.send(JSON.stringify(sdp));
        });
    }

    onIncomingICE(ice, peer) {
        let candidate = new RTCIceCandidate(ice);
        peer.pc.addIceCandidate(candidate).catch(this.setStatus, peer);
    }

    onServerMessage(peerId, event) {
        let self = this;

        let peerIndex = _.findIndex(self.ws_conns, o => {
            return peerId === o.guid;
        });

        let peer = self.ws_conns[peerIndex];
        switch (event.data) {
            case "HELLO":
                self.setStatus("Waiting for incoming stream", peer);
                let streamIndex = _.findIndex(self.streams, o => {
                    return o.guid === peerId;
                });
                let stream = self.streams[streamIndex];
				// TODO: Check and call initiate stream <09-09-20, Liaan> //
				if(stream && stream.unit && stream.unit.initiateStreamCallback) {
					self.unitService.requestInitiateStream(stream.unit._id, peerId);
				} else {
					console.error('Unit stream initiating not supported');
				}
                return;
            default:
                if(event.data.startsWith("ERROR")) {
                    self.handleIncomingError(event.data, peer);
                    return;
                }
                // Handle incoming JSON SDP and ICE messages
                try {
                    var msg = JSON.parse(event.data);
                } catch(e) {
                    if(e instanceof SyntaxError) {
                        self.handleIncomingError(`Error parsing incoming JSON: ${e}`, peer);
                    } else {
                        self.handleIncomingError(`Unknown error parsing response: ${event.data}`, peer);
                    }
                    return;
                }
                // Incoming JSON signals the beginning of a call
                if(peer.pc === null) {
                    self.createCall(msg, peer);
                }

                if(msg.sdp) {
                    self.onIncomingSDP(msg.sdp, peer);
                } else if(msg.ice) {
                    self.onIncomingICE(msg.ice, peer);
                } else {
                    self.handleIncomingError(`Unknown incoming JSON: ${msg}`, peer);
                }
        }
    }

	resetStream(stream) {
		let self = this;
		let peer = _.find(this.ws_conns,['guid',stream.guid]);
		if(!peer) {
			return null;
		}
		if(peer.pc !== null) {
			peer.pc.close();
			peer.pc = null;
			self.resetVideoElement(peer.guid);
		}
		if(peer.ws !== null) {
			peer.ws.close();
			peer.ws = null;
		}

        peer.connect_attempts = 0;
		self.removeConn(peer.guid, stream);
		this.$window.setTimeout( () => {
			self.initiateStream(stream);
		}, 5000 );
	}

    onServerClose(peerId) {
        let peerIndex = _.findIndex(this.ws_conns, o => {
            return peerId === o.guid;
        });
        let peer = this.ws_conns[peerIndex];
		if(!peer) return null;
        this.resetVideoElement(peerId);
        if(peer) {
            if(peer.pc !== null) {
                peer.pc.close();
                peer.pc = null;
            }
			// Retry after 3 seconds
			this.$window.setTimeout(this.websocketServerConnect.bind(this, peerId), 3000);
        }
    }

    onServerError(event, peerId) {
        let self = this;
        if(self.Auth.hasUserPrivilege("debug")) {
            this.setStatus("Unable to connect to server, did you add an exception for the certificate?", {id: peerId});
        }
        // Retry after 3 seconds
        this.$window.setTimeout(this.websocketServerConnect.bind(this, peerId), 3000);
    }


    websocketServerConnect(peerId) {
        let self = this;
        let peerIndex = _.findIndex(self.ws_conns, o => {
            return o.guid === peerId;
        });
		if(peerIndex === -1) return null;
        let peer = this.ws_conns[peerIndex];
        peer.connect_attempts++;
        if(peer.connect_attempts > 5) {
            self.setStatus("Error Connecting. If problem persists, contact support.", peer);
            self.removeConn(peerId);
            return;
        }
        //let peerId = self.getOurId();
        let loc = null;
        if(self.$window.location.protocol.startsWith("file")) {
            loc = "127.0.0.1";
        } else if(self.$window.location.protocol.startsWith("http") || self.$window.location.protocol.startsWith("https")) {
            loc = "signal.jerichosystems.co.za";
            //loc = self.$window.location.hostname;
        } else {
            throw new Error(`Don't know how to connect to the signalling server with uri${window.location}`);
        }
        let webSock = `wss://${loc}:8443`;

        let ws_conn = new WebSocket(webSock);
        peer.ws = ws_conn;
        peer.debugStatus = "Registering with server";

        peer.ws.addEventListener('open', () => {
			if(self.ws_conns[peerIndex].ws.readyState === 1) {
				self.ws_conns[peerIndex].ws.send(`HELLO ${peerId}`);
				self.setStatus("Stream initialising", peer);
			}
        });

        self.ws_conns[peerIndex].ws.addEventListener('error', self.onServerError.bind(self, peerId));
        self.ws_conns[peerIndex].ws.addEventListener('message', self.onServerMessage.bind(self, peerId));
        self.ws_conns[peerIndex].ws.addEventListener('close', self.onServerClose.bind(self, peerId));
    }

    onRemoteStreamAdded(peerId, event) {
        let self = this;
        let videoTracks = event.stream.getVideoTracks();
        let peerIndex = _.findIndex(self.ws_conns, o => {
            return o.guid === peerId;
        });
        if(videoTracks.length > 0) {
			let peer = this.ws_conns[peerIndex];
            self.setStatus("Viewing Stream", peer);
            this.getVideoElement(peerId).srcObject = event.stream;
			let stream = _.find(self.streams, ['guid', peerId]);
			if(stream) {
				stream.statsUpdater = setInterval(self.updateStreamStats.bind(self,stream,videoTracks[0]),1000 );
			}
        } else {
            this.handleIncomingError('Stream with unknown tracks added, resetting');
        }
    }

    errorUserMediaHandler() {
        console.error("Browser doesn't support getUserMedia!");
    }

    createCall(msg, peer) {
        let self = this;
        // Reset connection attempts because we connected successfully
        peer.connect_attempts = 0;

        peer.pc = new RTCPeerConnection(this.rtc_configuration);

        peer.pc.onaddstream = this.onRemoteStreamAdded.bind(this, peer.guid);

        /* Send our video/audio to the other peer */

        if(!msg.sdp) {
            console.error("WARNING: First message wasn't an SDP message!?");
        }

        peer.pc.onicecandidate = event => {
            // We have a candidate, send it to the remote party with the
            // same uuid
            if(event.candidate !== null) {
                peer.ws.send(JSON.stringify({ice: event.candidate}));
            } else {
                return;
            }
        };

        if(self.Auth.hasUserPrivilege("debug")) {
            this.setStatus("Created peer connection for call, waiting for SDP", peer);
        }
    }

	updateStreamStats(stream, track) {
		let self = this;
		let peer = _.find(self.ws_conns,['guid',stream.guid]);
		if(peer && peer.pc) {
			peer.pc.getStats(track).then( (report) => {
				report.forEach( (o) => {
					if(o.type === 'track' && o.kind === 'video') {
						if(o.framesDecoded && (stream.stats && stream.stats.framesDecoded !== o.framesDecoded)) {
							stream.active = true;
							stream.inactiveCounter = 0;
						}else{
							if(!stream.inactiveCounter) {
								stream.inactiveCounter = 0;
							}
							stream.inactiveCounter++;
							if(stream.inactiveCounter >= INACTIVE_STREAM_SECONDS) {
								stream.active = false;
							}
						}
						stream.stats = o;
					}
				} );
			} );
		}else{
			//self.onServerClose(stream.guid);
			stream.active = false;
			if(stream.statsUpdater) {
				clearInterval(stream.statsUpdater);
				Reflect.deleteProperty(stream,'statsUpdater');
			}
		}
	}
    ///////////////////////DMS Section /////////////////////////////////
    handleDMSPublish(message) {
    }
    handleDMSPublishDone(message) {
    }
/*****************************************************************************************************************/
}

export default angular.module('secutraqApp.dashboard')
.service('liveStreamService', LiveStreamService);


